import React from "react";

import CreditOrDebitCard from "./CreditOrDebitCard";
import ECheckCard from "./ECheckCard";
import PaypalCard from "./PaypalCard";
import SepaCard from "./SepaCard";
import {formatPMDesc, isPayPalMethod, isWalletExpiring} from "../../../utils/payment-utils";
import * as R from "ramda";
import {maskIban, maskNumber, maskString} from "../../../utils/utils";
import {FormattedMessage} from "react-intl";
import SCBCard from "./SCBCard";

export default function PaymentMethodCard({paymentMethod, onEdit, onRemove, onDefault, permissions, intl}) {
    const props = {
        editHandler: onEdit,
        removeHandler: onRemove,
        makeDefaultHandler: onDefault,
        showCardActions: true,
        paymentMethod,
        permissions,
        intl
    };

    switch (paymentMethod.categoryCode) {
        case "CC":
        case "DC":
            return <CreditOrDebitCard {...props}/>;
        case "DD":
        case "VIPPS":
        case "TRUSTLY":
        case "MOMO_WALLET":
        case "TWINT":
        case "BACS":
            return <ECheckCard {...props}/>;
        case "SEPA":
            return <SepaCard {...props}/>;
        case "PAYPAL_ACCOUNT":
            return <PaypalCard {...props}/>;
        case "SCB":
            return <SCBCard {...props}/>;
        default:
            return null;
    }
}

const showDetails = R.ifElse(
    isPayPalMethod,
    R.prop('email'),
    R.compose(maskNumber, R.prop('accountNumber'))
);

const serializeDefault = (pm,intl)=>`${pm.cardNickName??''}\n${formatPMDesc(intl)(pm.methodType)}\n${showDetails(pm)}`
const serializeCreditDebit = (pm,intl) =>
    `${serializeDefault(pm,intl)}\n${pm.ccExpired ? intl.formatMessage({id: 'card.expired'}) : ''}\n${isWalletExpiring(pm) ? intl.formatMessage({id:`card.expiring`}) : ''}\n${pm.ccExpiry.month}/${pm.ccExpiry.year}\n${pm.cardHolderName}`
const serializeBank = (pm,intl)=>`${serializeDefault(pm, intl)}\n${pm.cardHolderName}\n${pm.fiName}\n${pm.routingNumber ? maskString(pm.routingNumber) : maskString(pm.bankId + pm.bankTransitNumber)}`
const serializeSEPA = (pm, intl)=>`${serializeDefault(pm, intl)}\n${pm.cardHolderName}\n${maskString(pm.bic)}`
const serializePaypal = serializeDefault
const serializeSCB = (pm, intl)=>`SCB\n${serializeDefault(pm, intl)}\n${pm.cardHolderName}\n${pm.bic}\n${pm.bankId??''}\n${pm.debtorId??''}\n${pm.tokenPending ? intl.formatMessage({id:'card.pending-token'}) : ''}`

//allows cards to be searched from the search query field

export const serializePaymentMethodCard = (intl)=>(pm)=>{
    switch (pm.categoryCode) {
        case "CC":
        case "DC":
            return serializeCreditDebit(pm,intl);
        case "DD":
        case "BACS":
            return serializeBank(pm,intl);
        case "SEPA":
            return serializeSEPA(pm,intl);
        case "PAYPAL_ACCOUNT":
            return serializePaypal(pm,intl);
        case "SCB":
            return serializeSCB(pm,intl);
        default:
            return null;
    }
}