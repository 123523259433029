import React, { useState, useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import PreferencesPromptModal from '../Modal/PreferencesPromptModal';
import {useValidator} from '../../../utils/validation';

export default function PreferencesPrompt({ preparePreferences }) {
    const history = useHistory();
    const [lastLocation, setLastLocation] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const validator = useValidator();

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
          history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation) {
          setModalVisible(true);
          setLastLocation(nextLocation);
          return false;
        }
        return true;
    };

    const promptCloseModal = (args) => {
        
        const validateErrorLength = validator.validateAll();
        setModalVisible(false);
        if(( args.value === "yes" && validateErrorLength.messages.length == 0 )){
            setConfirmedNavigation(true);             
            preparePreferences();
        }
        else if(args.value === "no"){
            setConfirmedNavigation(true);
        }
    }

    return (
        <>
            <Prompt message={handleBlockedNavigation} />
            {modalVisible && <PreferencesPromptModal
                isOpen={modalVisible}
                closeModal={promptCloseModal}
            />}
        </>
    );
}