/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {all, call, fork, put, takeEvery, cps, delay } from 'redux-saga/effects';
import * as scheduleApi from '../api/schedule-api';
import * as scheduleActions from '../actions/schedule-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';

// const getApiUrlPrefix = (state) => state.envProps.apiUrlPrefix;
// const getApiToken = (state) => state.apiToken;

function* getScheduleListByIdentity(action) {
    try {
        yield put(layoutActions.showSpinner());
        // const apiUrlPrefix = yield select(getApiUrlPrefix);
        // const apiToken = yield select(getApiToken);
        const apiUrlPrefix = null;
        const apiToken = null;
        const { identity, callBack } = action;
        const response = yield call(scheduleApi.getScheduleListByIdentity, identity, apiUrlPrefix, apiToken);
        // const {parsedBody} = response;

        // examples of error response bodies
        //{error: "400 - {"id":{"location":"params","param":"id","value":"undefined","msg":"ID is invalid"}}"}
        //{error: "400 - {"id":{"location":"params","param":"id","value":"5d97bc71ac243cb3a","msg":"ID is invalid"}}"}


        if (response) {
            //console.log('response', response);
            yield put(scheduleActions.getScheduleListByIdentitySuccess(response));
            if (callBack && callBack.history) {
                yield call(callBack.history.push({
                    pathname: callBack.forwardUrl, // (string) The path of the URL
                    // search: '', search - (string) The URL query string e.g. '?query=abc',
                    state: { backUrl: callBack.backUrl }
                }));
            } else if (callBack && callBack.toggleModal) {
                yield call(callBack.toggleModal(callBack.modalName));
            }
        } else {
            // yield put(scheduleActions.getIdentityFailure());
        }

    } catch (error) {
        // if (error.status === 403) {
        //     yield put(layoutActions.invalidSession());
        // } else {
        //     yield put(scheduleActions.getMerchantFailure());
        // }
    } finally {
        yield delay(1250); // demo purposes as there is no load time when loading the demo json local file
        yield put(layoutActions.hideSpinner());

    }
}

export function* getScheduleListByIdentitySaga() {
    yield takeEvery(actionTypes.GET_SCHEDULE_LIST_BY_IDENTITY, getScheduleListByIdentity);
}

export default function* scheduleSaga() {
    yield all([
        fork(getScheduleListByIdentitySaga)
    ]);
}