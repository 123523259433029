import React, {useState, useEffect} from "react";
import {
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn
} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";

import {handleDeletePayByTextById} from "../../../../api/pay-by-text-api";
import ReactPortalModal from "../../../ReactPortalModal";
import Error from "../../../Error";
import PayByTextConfirmation from "../../PayByTextConfirmation";
import {merchantIdToCountryCode}  from "../../../../utils/payByText-utils";

const STEPS = [
    {
        label: "modal.remove-paybytext.btn.remove-paybytext",
        msg: "modal.remove-paybytext.confirm.msg",
        btn1: "ups.btn.submit.label",
        btn2: "ups.btn.cancel.label"
    },
    {
        label: "modal.remove-paybytext.done",
        msg: "modal.remove-paybytext.done.msg",
        btn1: "close.label"
    }
];

export default function CancelPayByTextModal({modalId, toggleModal, isOpen, payByText, merchants}) {
    const [step, setStep] = useState(0);
    const [error, setError] = useState("");
    const intl = useIntl();

    useEffect(() => {
        if (!isOpen) return;
        setStep(0);
        setError("");
    }, [isOpen]);

    useEffect(() => {
        setError("");
    }, [step]);

    const closeModal = () => {
        toggleModal(modalId);
    };
    const submit = async () => {
        try {
            const payload = {
                id: payByText.id,
                accountNumber: payByText.accountNumber,
                type: 'ACCOUNT',
                countryCode: merchantIdToCountryCode(merchants, payByText.merchantId)
            }
            await handleDeletePayByTextById(payload);
            setStep(step + 1);
        } catch ({errorCode}) {
            setError(errorCode);
        }
    };
    const nextStep = () => {
        if (step >= STEPS.length - 1) return closeModal();

        if (step === 0) submit();
        else setStep(step + 1);
    };

    const modalHeading = STEPS[step].label;
    const modalLabel = "cancel-pay-by-text";

    return <ReactPortalModal isOpen={isOpen}  an_label={modalLabel}>
        <MDBModal isOpen={isOpen} toggle={closeModal} size="md" disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
            <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={closeModal}>
                <FormattedMessage id={modalHeading}/>
            </MDBModalHeader>

            <MDBModalBody aria-live="assertive">
                {!!error && <Error errors={[error]}/>}
                <p><FormattedMessage id={STEPS[step].msg} values={{id: payByText._id}}/></p>
                <PayByTextConfirmation payByText={payByText}/>
            </MDBModalBody>

            <MDBModalFooter className="mt-3" aria-live="polite">
                {STEPS[step].btn1 &&
                    <MDBBtn color="primary" onClick={nextStep}>
                        <FormattedMessage id={STEPS[step].btn1}/>
                    </MDBBtn>
                }
                {STEPS[step].btn2 &&
                    <MDBBtn color="cancel" onClick={closeModal}>
                        <FormattedMessage id={STEPS[step].btn2}/>
                    </MDBBtn>
                }
            </MDBModalFooter>
        </MDBModal>
    </ReactPortalModal>;
}
