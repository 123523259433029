import { fetchWrapper, fetchDownloadWrapper } from '../utils/utils';

import { toUrlNvps, getFullUrl, callApiGet
    , callApiPut, overIf, callApiPost
    , callApiDelete}                     from '../utils/utils';

export function getPaymentHistoryList(apiUrlPrefix, apiToken, filters) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/payment-history/list`, 'POST', headers, filters);
}

export function getPaymentHistoryFilters(apiUrlPrefix, apiToken, savedFilters) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/payment-history/filters`, 'POST', headers, savedFilters);
}


// export function getPaymentRecordsByParams({ invoiceNumber, planInvoiceNumber, parentReferenceId }, apiUrlPrefix, apiToken) {
//     const params = new URLSearchParams();
//     if(!!invoiceNumber){
//         params.append('invoiceNumber', invoiceNumber);
//     }
//     if(!!planInvoiceNumber){
//         params.append('planInvoiceNumber', planInvoiceNumber);
//     }
//     if(!!parentReferenceId){
//         params.append('parentReferenceId', parentReferenceId);
//     }
//     const headers = {
//         'x-api-token-sub': apiToken,
//         'Content-Type': 'application/json'
//     };

//     return fetchWrapper(`${apiUrlPrefix}/payment-history/byParams?${params.toString()}`, 'GET', headers);
// }

export const getPaymentHistoryForInvoice = invoice =>
    callApiPost( {url: getFullUrl('/payment-history/byInvoice'), body: invoice} );

export function getChargebackRecordsByReference(chargebackReqData, apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/payment-history/chargebackRecord/byReference`, 'POST', headers, chargebackReqData);
}