import React, {Component} from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as authActions from "../../actions/auth-action";

import {getQueryParams} from "../../utils/utils";
import * as configActions from "../../actions/config-action";
import { removeCookie } from "../../utils/cookie-utils";

/**
 * This component is the login page. If a token is passed in via query parameter we will treat it as an sso token and start the login process.
 */
class Login extends Component {

    constructor(props) {
        super(props);
        //FIXME call logout here temporarily to reset state when going into login flow
        this.props.authActions.init();
        this.state = {};
    }

    componentDidMount() {
        const query = getQueryParams(this.props.location.search);
        const {userId, loginId, firstName, lastName, emailAddress, companyName , code } = query;
       
        //clear csr cookie before starting session to avoid 400 error due to increased header size
        removeCookie({name: "csr"});
        
        if(userId) {
            /*This is to handle the exisitng way of signing in  */
            this.setState({existingLogin: true});
            const data = {
                userId, firstName, lastName, emailAddress, companyName, loginId: loginId || userId //FIXME: set to loginId only when usernames are no longer set to externalId by default
            };
            this.props.authActions.ssoCallback(data);
        } else {
            this.setState({ssoLogin: true});
            const path = this.props.location.originalPath ? decodeURIComponent(this.props.location.originalPath): "";
            this.props.authActions.login({lastClientRoute: path});
        }
        
        
     
    }

    componentDidUpdate(prevProps, prevState) {
        const {auth, enroll, merchants ,url} = this.props;
       
        // wait until merchants are in the state before redirecting
        if (url) {
            //this.props.history.push({pathname: url});
            
            window.location.href = url;
        }
        
        if (merchants) {
            //on completion of login, clear any billing center browser session data (at the moment just session filters)
            sessionStorage.removeItem("sessionFilters");
            if (enroll) {
                this.props.history.push({pathname: '/enrollment'});
            }
            if (auth.user && auth.merchants) { //wait until accessible merchants are loaded to avoid country undefined issue
                if(this.props.location.originalPath){
                    /* originalPath will be available only when user has selected a private route but store does not have 
                       complete information for session to start. After user and merchants are available in the store, user will
                       be redirected to the originally selected path  
                    */
                    this.props.history.push({pathname: this.props.location.originalPath});
                }
                else{
                    this.props.history.push({pathname: '/home'});
                }
            }
          
        }
    }

    render() {
        const {existingLogin} = this.state;
        return (
            <div className={"ups-landing-container"}>
                <div className="text-center mb-4 mt-5">
                    {existingLogin ? 'logging in...' : 'Redirecting to Login...'}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
   
    return {

        auth: state.auth,
        enroll: state.auth.enroll,
        merchants: state.config.merchants
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        configActions: bindActionCreators(configActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

