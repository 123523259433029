/*
 * Copyright (C) 2021 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 3/16/21 11:13 PM
 * Created by cmalhotra
 *
 */

import { getFullUrl, callApiGet, callApiPost, callApiDelete } from "../utils/utils";

const headers = {
    'Content-Type': 'application/json',
    'x-api-token-sub': 'UPS'
};

const preparePayByTextRecord = (data) => {
    return {
      "paymentMethod": {
          "walletId": data.paymentMethod._id,
          "methodType": data.paymentMethod.methodType,
          "accountNumber": data.paymentMethod.accountNumber,
          "cardNickName": data.paymentMethod.cardNickName,
          "email": data.paymentMethod.email,
          "active": typeof data.paymentMethod?.active==='undefined' ? true : data.paymentMethod.active
      },
      "issuer": data.issuer ? data.issuer : 'UPS',
      "active": data.active || false,
      "createdBy": data.createdBy ? data.createdBy : "",
      "modifiedBy": data.modifiedBy ? data.modifiedBy : "",
      "accountNumber": data.account?.accountNumber,
      "paymentType": data.account?.paymentType,
      "accountName": data.account?.accountName,
      "countryCode": data.countryCode,
      "type": 'ACCOUNT',
      ...( data.id ? {"id": data.id} : {})
  };
}

export function handleGetPayByTextByIdentityId() {
    return callApiGet({ url: getFullUrl(`/paybytext/list/byIdentity`), headers });
}

export function handleUpsertPayByText(data) {
    const body = preparePayByTextRecord(data);
    return callApiPost({url: getFullUrl(`/paybytext`), headers, body});
}

export function handleDeletePayByTextById(body) {
    return callApiDelete({url: getFullUrl(`/paybytext`), headers, body});
}

export function getPayByTextAccountCCEligibility(body) {
    return callApiPost({url: getFullUrl(`/paybytext/cceligibility`), headers, body});
}

export function getPayByTextEligibleAccounts() {
    return callApiGet({ url: getFullUrl('/paybytext/eligibleaccounts'), headers });
}