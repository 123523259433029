import produce, { applyPatches} from "immer";
import * as actionTypes from "../constants/action-types";

const changes = {};
let currentVersion = -1;
const noOfVersionsSupported = 100;
const undoableActions = null;

export default (state = {}, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_EBILL:
                draft.ebill = action.data
                return;
            case actionTypes.GET_EBILL_LIST:
                draft.ebillList = action.data;
                return;
            case actionTypes.CLEAR_EBILL:
                delete draft.ebill;
                return;
            case actionTypes.CLEAR_EBILL_LIST:
                delete draft.ebillList;
                return;
            case actionTypes.EBILL_LIST_BY_IDENTIY_SUCCESS:
                draft.ebillList = action.data;
                return;
            case actionTypes.EBILL_LIST_BY_IDENTIY_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.EBILL_LIST_BY_IDENTITY_EXTERNAL_SUCCESS:
                draft.ebillList = action.data;
                return;
            case actionTypes.EBILL_LIST_BY_IDENTITY_EXTERNAL_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_IDENTITY_SUCCESS:
                draft.ebillList = action.data;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_IDENTITY_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL_SUCCESS:
                draft.ebillList = action.data;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_ACCOUNT_SUCCESS:
                draft.ebillList = action.data;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_ACCOUNT_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL_SUCCESS:
                draft.ebillList = action.data;
                return;
            case actionTypes.EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.UNDO_EDIT_SCHEDULE:
                return applyPatches(state, changes[currentVersion--].undo);
        }
    }, (patches, inversePatches) => {
        // if (undoableActions.indexOf(action.type) !== -1) {
        //     currentVersion++;
        //     changes[currentVersion] = {
        //         // redo: patches,
        //         undo: inversePatches
        //     };
        //
        //     delete changes[currentVersion + 1];
        //     delete changes[currentVersion - noOfVersionsSupported];
        // };
    }
)
