import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import DatatableV3 from '../../DataTableV3/DataTableV3';
import Exception from '../../Exception/index'
import {FormattedMessage} from 'react-intl';
import {MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem} from "mdbreact";
import {get as lodashGet, pullAt as lodashPullAt, has as lodashHas, some as lodashSome, isEmpty, set as lodashSet} from 'lodash';
import {getDownloadRequestObject} from "../../../utils/invoice-utils";
import * as invoiceActions from "../../../actions/invoice-action";
import produce from "immer";
import moment from 'moment';
import * as invoiceApi from '../../../api/invoice-api';
import * as INVOICE_TYPES from '../../../constants/invoice-types';


class UPSDocumentTable extends Component {
  constructor(props) {
    super(props);
    this.datatableCols = this.createDatatableCols()
    this.state = {
      error: ''
    };
  }

  handleCheckBox = e => {
    const inputName = e.target.name;
    const inputChecked = e.target.checked;

    this.setState(
      produce(draft => {
        lodashSet(draft, inputName, inputChecked);
      })
    );
  };

  handleDownload = async (data) => {
    const {invoiceActions, isPlanInvoice, intl, businessUnit, user, envProps} = this.props;
    const {apiUrlPrefix, apiToken} = envProps;

    let scsStatement;
    if (businessUnit === 'SCS') {
      // for scs pdf downloads, we need to fetch the statement and modify some of the fields to match the statement
      if (data.type.toLowerCase() === 'pdf' && [INVOICE_TYPES.SCS_AIR, INVOICE_TYPES.SCS_BROKERAGE, INVOICE_TYPES.SCS_OCEAN].includes(data.invoice.invoiceType)) {
        const response = await invoiceApi.getPlanInvoiceList({
          merchantId: data.invoice.merchantId,
          businessUnit: data.invoice.businessUnit,
          accountNumber: data.invoice.accountNumber,
          planInvoiceNumber: data.invoice.planInvoiceNumber
        }, apiUrlPrefix, apiToken);

        if (response.ok) {
          scsStatement = response.parsedBody[0];
        }
      }
    }

    const searchCriteria = getDownloadRequestObject({
      ...data.invoice,
      ...(scsStatement ? {
        invoiceDate: scsStatement.invoiceDate,
        invoiceNumber: scsStatement.planInvoiceNumber,
        invoiceAmount: scsStatement.invoiceAmount,
      } : {})
    }, businessUnit, isPlanInvoice, intl);

    invoiceActions.getInvoiceDownload(data.url, {locale: user.locale, invoices: [searchCriteria]});
  };

  createDatatableCols = () => {
    const {isPlanInvoice, businessUnit, intl, countryCode} = this.props;
    let titleInvoiceNumber = '';
    if (businessUnit === 'FRT') {
      titleInvoiceNumber = isPlanInvoice ? 'invoice.download-documents.table.statement-number.label' : 'invoice.download-documents.table.pro-number.label';
    } else if (businessUnit === 'SCS') {
      titleInvoiceNumber = isPlanInvoice ? 'invoice.download-documents.table.statement-number.label' : 'invoice.download-documents.table.invoice-number.label';
    } else {
      titleInvoiceNumber = isPlanInvoice ? 'invoice.download-documents.table.plan-number.label' : 'invoice.download-documents.table.invoice-number.label';
    }

    const getBtnDownloadLabel = (docType) => {
      if(docType.toUpperCase() === 'PDF'){ return 'invoice.download-documents.table.btn-download.label-pdf'; }
      return 'invoice.download-documents.table.btn-download.label';
    };

    const cols = [
      {
        field: "name",
        label: intl.formatMessage({id: titleInvoiceNumber}),
      },
      {
        field: "type",
        label: intl.formatMessage({id: "invoice.download-documents.table.file-format.label"}),
        display:(data) => intl.formatMessage({id: `btn.download-option-short.${data.type?.toUpperCase()}`}, {var:['US','CA'].includes(countryCode.toUpperCase()) ? '32' : '28'})
      },
      {
        field: "download",
        label: intl.formatMessage({id: "invoice.download-documents.table.actions.label"}),
        tdClassName: "text-center datatable-action-col prevent-toggle",
        display: (rowData) => <MDBBtn id={`download-doc-${rowData.id}`} color={"text-link"} onClick={()=>this.handleDownload(rowData)}>
          <FormattedMessage id={getBtnDownloadLabel(rowData.type)} />
        </MDBBtn>,
        sortable: false
      },
    ];

    return cols;
  };

  render() {
    const {documentList} = this.props;
    const {error} = this.state;

    if (!documentList) {
      return null;
    } else {
      if (error instanceof TypeError) {
        return (<Exception error={error} />)
      } else {
        return (
            <DatatableV3
                className="table table-bordered-simple table-sm table-hover table-invoicesDownload"
                data={documentList}
                columns={this.datatableCols}
            />
        )
      }
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    identity: state.identity.identity,
    businessUnit: state.auth.user.selectedPaymentType,
    countryCode: state.config.sessionSelection.merchant.countryCode,
    countryLocale: state.settings.countryLocale,
    user: state.auth.user,
    envProps: state.envProps,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    invoiceActions: bindActionCreators(invoiceActions, dispatch)
  }
}
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSDocumentTable)));
