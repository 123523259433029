import {callApiGet, callApiPost, getFullUrl} from "../utils/utils";
import { paymentToBody }                     from '../utils/payment-utils';

import * as R                                from 'ramda';

export const submitPayment = (payment) => callApiPost
    ({ url: getFullUrl('/payment/pay')
    , body: paymentToBody(payment)
    });

export const checkRecentPayments = R.compose
    ( R.andThen( R.when
                    ( R.isEmpty
                    , R.always([]) // An array is expected here.
                    )
                )
    , body => callApiPost
        ({ url: getFullUrl('/payment/checkRecentPayments'), body})
    );

export const checkPaymentMethodEligibilty = body => callApiPost
    ({ url: getFullUrl('/payment/payMethodEligibility')
     , body
    });
     
export const getPaymentByReference = (referenceId) => callApiGet
    ({
        url:getFullUrl(`/payment/getPaymentByReference?referenceId=${referenceId}`)
    })

export const getAlternatePmIssuerList = (pmCategory, merchantId) => callApiGet
    ({
        url: getFullUrl(`/payment/getIssuerList/${merchantId}/${pmCategory}`)
    })

export const getClientIP = () => callApiGet
({
    url: getFullUrl(`/payment/getClientIP`)
})

export const complete3dsPayment = body =>
  callApiPost({
    url: getFullUrl('/payment/complete3ds'),
    body
  });
