import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import * as authActions from "../../../../actions/auth-action";
import {mapRole, nameToString} from '../../../../utils/utils';
import { sendGoogleAnalyticsEvent as ga, GA_CATEGORY } from "../../../../utils/google-analytics-utils";

export default function UserAccountDropdown() {
    const { user } = useSelector(s => s.auth);
    const reduxDispatch = useDispatch();
    return (
        <div className={"user-account-container"}>
            <MDBDropdown className={"user-account-dropdown"}>
                <MDBDropdownToggle nav className="mr-1 user-account-profile" >
                    {user.adUserId ? <i className="fas fa-user-secret float-left"></i> : <i className="fa fa-user float-left"></i>}
                    <span className="user-account-name">{user.firstName}</span><i className="fa fa-angle-down float-right"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu right basic>
                    <div className={"account-user-name"}>
                        {nameToString(user.firstName, user.lastName)}
                    </div>
                    {/*<div className={"account-user-company"}>*/}
                        {/*Currently backend doesn't return company name */}
                    {/*</div>*/}
                    {user.role &&
                    <div className={"account-user-role"}>
                        <FormattedMessage id={`ups.manage-user.role.${user.role}.label`}/>
                    </div>
                    }
                    {user.adUserId &&
                        <React.Fragment>
                            <MDBDropdownItem divider />
                            <div className={"impersonation-user-name"}>
                                <strong><FormattedMessage id="impersonation-note" /></strong><br/>
                                {user.adUserId}
                            </div>
                            {user.adUserRole &&
                            <div className={"impersonation-user-role"}>
                                {user.adUserRole}
                            </div>
                            }
                        </React.Fragment>
                    }
                    <MDBDropdownItem divider />
                    <MDBBtn color={"secondary"}
                        onClick={
                            () => {
                                ga(GA_CATEGORY.NAVIGATION, 'Clicked Log Out')
                                reduxDispatch(authActions.expireSessionCookie())
                            }
                        }
                    >
                        <FormattedMessage id='side-nav.log-out'/>
                    </MDBBtn>
                    {/*<MDBDropdownItem divider />*/}
                    {/*<div className={"account-user-last-login"}>*/}
                        {/*<FormattedMessage id={"side-nav.last-login"} />*/}
                        {/*<div className={"last-login-date"}>*/}
                            {/*Nov 6, 2020, 3:53:48 PM*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
    );
}
