import React, { useEffect, useState }       from "react";
import { useSelector}                       from "react-redux";
import supportLinks                         from '../constants/support-links';
import { FormattedMessage, useIntl }                 from 'react-intl';
import { Link }                             from 'react-router-dom';
import { upsHelpUrlFormatter } from "../utils/utils"; 


export default function UPSPrivacyPolicy() {
    const { supportBaseUrl, upsBaseUrl, upsPrivacyNoticeResource, privacyPolicyResource, upsCaliforniaResource, californiaResource } = supportLinks;
    const { countryLocale } = useSelector(s => s.settings);
    const { selectedCountry, locale } = useSelector(s => (s.auth.user || s.auth.userInfo));
    const { user } = useSelector(s => s.auth);
    const [ privacyNoticeUrl, updatePrivacyNoticeUrl ] = useState();
    const [ privacyUrl, updatePrivacyPolicyUrl ] = useState();
    const [ privacyRightsUrl, updatePrivacyRightsUrl ] = useState();
    const [ privacyNoticeCaliforniaUrl, updatePrivacyNoticeCaliforniaUrl ] = useState();
    const path = require('path');
    const [countryCode, setCountryCode] = useState("");
    const intl = useIntl();
    useEffect(() => {
        let language = locale ? locale.split("-")[0] : null;
        let country = selectedCountry ? selectedCountry.toLowerCase() : null;
        if(countryLocale){
            let arr = countryLocale.split("-");
            language = arr[0];
            country = arr[1].toLowerCase();
        }
        if(language && country){      
            setCountryCode(country);            
            const urlPrivacyNotice = upsBaseUrl + path.join(upsHelpUrlFormatter(country, language), upsPrivacyNoticeResource);
            const urlPrivacyPolicy = supportBaseUrl + path.join(country, language, privacyPolicyResource);
            const urlPrivacyRights = upsBaseUrl + path.join(country, language, upsCaliforniaResource);
            const urlPrivacyNoticeCalifornia = supportBaseUrl + path.join(country, language, californiaResource);
            
            updatePrivacyNoticeUrl(urlPrivacyNotice);
            updatePrivacyPolicyUrl(urlPrivacyPolicy);
            updatePrivacyRightsUrl(urlPrivacyRights);
            updatePrivacyNoticeCaliforniaUrl(urlPrivacyNoticeCalifornia);
        }
    }, [countryLocale]);

    return (
        <React.Fragment>
            <div role="main" className="container-fluid">
                <div className="row">
                    <div className="col"> 
                        <div className="card-body">
                            <div className={user? "align-body":"align-body-enroll"}>
                            {user? <div className="row">
                                    <div className="col-12">
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.add-account-breadcrumb-dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                <FormattedMessage id='ups.title.privacy-notices' />
                                                </li>
                                            </ol>
                                        </nav> 
                                    </div>
                                </div> : null
                            }
                                <div className="row">
                                    <div className="col-12">
                                       <h1 className={user? "has-breadcrumb":"help-support-header"}><FormattedMessage id="ups.title.privacy-notices" /></h1> 
                                    
                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <a role='link' href={privacyNoticeUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                <span className={user? "":"enroll-align"}><FormattedMessage id='ups.privacy-notice' /></span> 
                                                <span className="material-icons">open_in_new</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <a role='link' href={privacyUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                <span className={user? "":"enroll-align"}><FormattedMessage id='ups.privacy-policy' /></span> 
                                                <span className="material-icons">open_in_new</span>
                                                </a>
                                            </div>
                                        </div>
                                    {countryCode==="us"?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-12 mt-3">
                                                    <a role='link' href={privacyRightsUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                    <span className={user? "":"enroll-align"}><FormattedMessage id='ups.privacy-rights' /></span> 
                                                    <span className="material-icons">open_in_new</span>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 mt-3">
                                                    <a role='link' href={privacyNoticeCaliforniaUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                    <span className={user? "":"enroll-align"}><FormattedMessage id='ups.privacy-notice-California' /></span>
                                                    <span className="material-icons">open_in_new</span>
                                                    </a>
                                                </div>
                                            </div> 
                                        </React.Fragment>: null
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}