import language from '../locales/en_US/language.json'
import sideNav from '../locales/en_US/side-nav.json';
import generalMessage from '../locales/en_US/general-message.json';
import account from '../locales/en_US/account';
import plan from '../locales/en_US/plan';
import payment from '../locales/en_US/payment';
import paymentMethods from '../locales/en_US/payment-methods';
import schedule from '../locales/en_US/schedule.json';
import datatable from '../locales/en_US/datatable.json';
import icon from '../locales/en_US/icons.json';
import error from '../locales/en_US/error.json';
import termsAch from '../locales/en_US/terms-ach.json';
import sepaMandate from '../locales/en_US/sepa-mandate.json';
import calendar from '../locales/en_US/calendar.json';
import invoice from '../locales/en_US/invoice.json';
import ups from '../locales/en_US/ups.json';
import upsInvoice from '../locales/en_US/ups-invoice.json';
import upsManageUsers from '../locales/en_US/ups-manage-users.json';
import input from '../locales/en_US/input.json';
import loginRegister from '../locales/en_US/login-register.json';
import radioOptions from '../locales/en_US/radio-options.json';
import filterOption from '../locales/en_US/filter-options.json';
import upsPaymentHistory from '../locales/en_US/payment-history.json';
import upsWallet from '../locales/en_US/ups-wallet.json';
import dashboard from '../locales/en_US/dashboard.json';
import ie11 from '../locales/en_US/ie11.json';
import standardFormats from '../locales/en_US/standard-formats.json';
import payByText from '../locales/en_US/pay-by-text.json';
import domesticMessages from '../locales/en_US/domestic-messages.json';
import upsDispute from '../locales/en_US/ups-dispute.json';
import paymentAgreements from '../locales/en_US/payment-agreements.json'

const EnLang = {
    messages: {
        ...language,
        ...sideNav,
        ...generalMessage,
        ...account,
        ...plan,
        ...payment,
        ...paymentMethods,
        ...schedule,
        ...datatable,
        ...icon,
        ...error,
        ...termsAch,
        ...sepaMandate,
        ...calendar,
        ...invoice,
        ...ups,
        ...upsInvoice,
        ...upsManageUsers,
        ...input,
        ...radioOptions,
        ...loginRegister,
        ...filterOption,
        ...upsPaymentHistory,
        ...upsWallet,
        ...dashboard,
        ...ie11,
        ...standardFormats,
        ...payByText,
        ...domesticMessages,
        ...upsDispute,
        ...paymentAgreements
    },
    locale: 'en-US'
};
export default EnLang;


