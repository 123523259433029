import React, { Component } from 'react';
import { MDBPopover, MDBBtn, MDBPopoverBody } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';


class PopOver extends Component {
    render() {
        const { popoverHelp, placement } = this.props;
        return (
            <React.Fragment>
                <MDBPopover
                    placement={placement ? placement : "right"}
                    popover
                    clickable
                    id={popoverHelp.id ? `popover-help-${popoverHelp.id}` : `popover-help-1`}
                >
                    <MDBBtn color={"popover-link"} className={"btn-react-select-popover-link"}><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <title>questioncircle</title>
                        <path d="M15.875 9c-1.375 0-2.438 0.313-3.063 1.063-0.5 0.625-0.75 1.375-0.75 2.563v0.188c0 0.438 0.438 0.75 1.063 0.75 0.438 0 0.875 0 0.875-0.625v-0.125c0-1.25 0.5-1.688 1.688-1.688 1.25 0 1.688 0.438 1.688 1.688 0 1.375-1.25 2.125-2.125 2.875-0.75 0.438-1.25 0.938-1.25 1.25v2.25c0 0.5 0.5 0.75 1.063 0.75 0.5 0 0.938-0.25 1.063-0.563v-2l0.188-0.125 0.313-0.188c0.75-0.625 2.75-2.125 2.75-4.125 0.313-2.563-1.063-3.938-3.5-3.938zM16.313 23.188c0 0.563-0.5 1.063-1.063 1.063-0.625 0-1.063-0.5-1.063-1.063 0-0.625 0.438-1.063 1.063-1.063 0.563 0 1.063 0.438 1.063 1.063zM15.875 0c-8.688 0-15.875 7.188-15.875 15.875s7.188 15.813 15.875 15.813c8.688 0 15.813-7.125 15.813-15.813s-7.125-15.875-15.813-15.875zM15.875 28.813c-7.188 0-13-5.813-13-12.938 0-7.188 5.813-13 13-13 7.125 0 12.938 5.813 12.938 13 0 7.125-5.813 12.938-12.938 12.938z"></path>
                    </svg>
                    </MDBBtn>
                    <MDBPopoverBody>
                        {popoverHelp.intlHeadingId ?
                            <p className={"font-weight-bold"} key={"popover-heading"}>
                                <FormattedMessage
                                    id={popoverHelp.intlHeadingId}
                                    className={"font-weight-bold"}
                                    values={{
                                        b: (...chunks) => <b>{chunks}</b>,
                                        i: (...chunks) => <i>{chunks}</i>
                                    }}
                                /></p>
                            : null}
                        {popoverHelp.intlMsgId ?
                            <React.Fragment>
                                {Object.keys(popoverHelp.intlMsgId).map((index) => {
                                    return (
                                        <FormattedMessage
                                            key={`popover-paragraph-item-${index}`}
                                            id={popoverHelp.intlMsgId[index]}
                                            tagName={'p'}
                                            values={{
                                                b: (...chunks) => <b>{chunks}</b>,
                                                i: (...chunks) => <i>{chunks}</i>
                                            }}
                                        />
                                    )
                                })
                                }
                            </React.Fragment>
                            : null}
                        {popoverHelp.intlMsgListId ?
                            <React.Fragment>
                                <ul>
                                    {Object.keys(popoverHelp.intlMsgListId).map((index) => {
                                        return (
                                            <FormattedMessage
                                                key={`popover-list-item-${index}`}
                                                id={popoverHelp.intlMsgListId[index]}
                                                tagName={'li'}
                                                values={{
                                                    b: (...chunks) => <b>{chunks}</b>,
                                                    i: (...chunks) => <i>{chunks}</i>
                                                }}
                                            />
                                        )
                                    })
                                    }
                                </ul>
                            </React.Fragment> : null}
                    </MDBPopoverBody>
                </MDBPopover>
            </React.Fragment>
        )
    }
}

export default PopOver;