import { countryData } from "../constants/country-data";
import { countryDialCodeData } from "../constants/country-dialcode-data";
import _ from "lodash";
import { BUSINESS_UNIT } from "../constants/business-unit";
import { isPBTConfigEnabled } from './config-utils';
import store from '../index';
const PBT_SUPPORTED_BUSINESS_UNITS = [BUSINESS_UNIT.EBS];
const PBT_ACCOUNT_LIMIT_PER_MERCHANT = 5;

function getPBTLanguagesByCountryCode(currCountry) {
    const languages = countryData.find(e => e.code === currCountry.toUpperCase())?.languages;
    if (languages?.length) {
        return languages[0].id; // from id field, country code can also be extracted
    }
    else return null;
}

function getLanguagesForPBT(userLanguages) {
    const pbtSupportedCountries = getPBTSupportedCountries();
    const result = userLanguages.filter(language => pbtSupportedCountries.includes(language.country?.toLowerCase()));
    return result;
}

function isPBTPreferencesEnabled(identity){
    return (identity && identity.preferences && identity.preferences.payByTextSettings && identity.preferences.payByTextSettings.enabled);
}

function isPBTSupportedCountry(currCountry='', businessUnit=null) {
    const pbtSupportedCountries = getPBTSupportedCountries();
    if (pbtSupportedCountries.includes(currCountry.toLowerCase()) && (businessUnit == null || businessUnit == BUSINESS_UNIT.EBS)) {
        return true;
    }
    else {
        return false;
    }

}

function getMobileNumberCountryList(pbtUserLanguages) {
    let pbtMobileNumberCountryList = new Set();
    for (let i = 0; i < pbtUserLanguages.length; i++) {
        pbtMobileNumberCountryList.add(countryDialCodeData.find(e => e.countryCode.toLowerCase() === pbtUserLanguages[i].country.toLowerCase()));
    }
    return _.sortBy(Array.from(pbtMobileNumberCountryList), [(data) => data.countryCode]);
}

function getMobileNumberCountry(pbtUserLanguages,currCountry) {
    return getMobileNumberCountryList(pbtUserLanguages).find(e=>e.countryCode.toUpperCase() === currCountry.toUpperCase());
}

function getPBTSupportedCountries(){
    const allMerchants = store.getState().config.merchants;
    const pbtCountries = allMerchants.filter(merchant => isPBTConfigEnabled(merchant)).map(merchant  => merchant.countryCode.toLowerCase());
    return pbtCountries;
}

function getCountryDialCode(countryCode) {
    return countryDialCodeData.find(e => e.countryCode === countryCode)?.code;
}

function isPBTAccountLimitReached(payByTextList, merchants, countryCode){
    const merchantId = merchants.find(m => m.countryCode === countryCode).id;
    const selectedCountryAccounts = payByTextList.filter(x => x.merchantId === merchantId);
    return (selectedCountryAccounts?.length >= PBT_ACCOUNT_LIMIT_PER_MERCHANT);
}

function merchantIdToCountryCode(merchants, merchantId) {
    return merchants.find(m => m.id === merchantId).countryCode;
}

const defaultMobileNumberCountry = (languages, currCountry) => (getMobileNumberCountry(getLanguagesForPBT(languages), currCountry))?.countryCode;

export {
    defaultMobileNumberCountry,
    isPBTSupportedCountry,
    getPBTLanguagesByCountryCode,
    getLanguagesForPBT,
    getMobileNumberCountryList,
    getCountryDialCode,
    isPBTPreferencesEnabled,
    isPBTAccountLimitReached,
    merchantIdToCountryCode,
    PBT_SUPPORTED_BUSINESS_UNITS
}
