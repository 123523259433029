import React, {Component} from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow} from "mdbreact";
import {FormattedMessage, injectIntl} from 'react-intl';
import InputFieldIntl from '../../../components/InputFieldIntl';
import CheckboxWithValidation from "../../CheckboxWithValidation";
import PageError from '../../../components/PageError';

import classNames from "classnames";
import {bindActionCreators} from "redux";
import * as enrollmentActions from "../../../actions/enrollment-action";
import {connect} from "react-redux";
import produce from "immer";
import {set as lodashSet} from "lodash";
import attachValidator from "../../../utils/validation/attach-validator";
import * as validationActions from "../../../actions/validation-action";
import {StandardValidations as stdv} from "../../../utils/validation/standard-validations";
import {nameToString} from "../../../utils/utils";
import supportLinks from '../../../constants/support-links';
import  path from 'path';

class EnrollmentCompanyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        attachValidator.call(this)
    }

    render() {
        const {userInfo, user, intl} = this.props;
        const {firstName, lastName, emailAddress} = userInfo;
        const userCompanyName = userInfo.companyName;
        const {companyName, acceptPaymentTerms, postSubmit} = this.state;

        const { supportBaseUrl, termsResource  } = supportLinks;
        const {selectedCountry: countryCode, locale} = userInfo || user || {};
        const selectedCountryCode = countryCode?countryCode.toLowerCase():'';
        const language = locale? locale.split('-')[0].toLowerCase():''; 
        const urlPaymentusTerms = supportBaseUrl + path.join(selectedCountryCode, language, termsResource);
        
        return (
            <React.Fragment>
                <section id="enrollmentCompanyDetailsSection"
                         className={classNames({"ups-enroll-subsection mb-4 non-modal": true})}>
                    <MDBCard>
                        <MDBCardHeader tag="h1" className={"p-0 mb-3"}><FormattedMessage id="companyDetails.title"/></MDBCardHeader>
                        <MDBCardBody className={"p-0"}>
                            {postSubmit && <PageError/>}
                            <p><FormattedMessage id={"companyDetails.line1"}/>&nbsp;
                                {userCompanyName ?
                                    <FormattedMessage id={"companyDetails.line2a"}/> :
                                    <FormattedMessage id={"companyDetails.line2b"}/>
                                }
                            </p>
                            <p><FormattedMessage id={"companyDetails.line3-1"}/></p>

                            <section className={"section-preview"}>
                                <MDBRow className={"mb-3"}>
                                    <MDBCol size={"6"} className={"mt-3 mt-sm-0 ups-field-label"}>
                                        <FormattedMessage id={"name.label"}/>:
                                    </MDBCol>
                                    <MDBCol size={"6"} className={"mt-3 mt-sm-0 ups-field-value"}>
                                        {nameToString(firstName, lastName)}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className={"mb-3"}>
                                    <MDBCol size={"6"} className={"mt-3 mt-sm-0 ups-field-label"}>
                                        <FormattedMessage id={"email.label"}/>:
                                    </MDBCol>
                                    <MDBCol size={"6"} className={"mt-3 mt-sm-0 ups-field-value"}>
                                        {emailAddress}
                                    </MDBCol>
                                </MDBRow>
                                {userCompanyName ?
                                    <MDBRow className={"mb-3"}>
                                        <MDBCol size={"6"} className={"mt-3 mt-sm-0 ups-field-label"}>
                                            <FormattedMessage id={"companyName.label"}/>:
                                        </MDBCol>
                                        <MDBCol size={"6"} className={"mt-3 mt-sm-0 ups-field-value"}>
                                            {userCompanyName}
                                        </MDBCol>
                                    </MDBRow>
                                    : null}
                            </section>

                            <p><strong><FormattedMessage id={"note.label"}/>:</strong> <FormattedMessage
                                id={"companyDetails.line3-2"}/></p>

                            {!userCompanyName ?
                                <React.Fragment>
                                    <p className="ups-note-1">*<span className="font-italic"><FormattedMessage
                                        id={"ups.required-field.note"}/></span></p>
                                    <InputFieldIntl
                                        key={"companyName"}
                                        name={"companyName"}
                                        id={"companyName"}
                                        label={<FormattedMessage id={"companyName.label"}/>}
                                        value={companyName}
                                        onChange={this.handleInput}
                                        hint={"companyName.hint"}
                                        restrict={stdv.alphaNumericAndPunctuation}
                                        required
                                        validations={[
                                            ['required', "field.required"],
                                            ['alphaNumericAndPunctuation', "field.alphanumeric"]
                                        ]}
                                    />
                                </React.Fragment> : null}
                            <MDBRow>
                                <MDBCol size={"12"} className={classNames({"mt-3": userCompanyName})}>
                                    <CheckboxWithValidation
                                       name="acceptPaymentTerms"
                                       id="acceptPaymentTerms"
                                       label={<FormattedMessage id="payment-authorization-terms.authorize.label"/>}
                                       containerClass={"py-0 pl-0 m-0 "}
                                       labelClass="mr-0 font-weight-bold"
                                       value={acceptPaymentTerms || false}
                                       onChange={this.handleInput}
                                       required
                                       validations={[
                                           ['required', "field.authorizePaymentTerms"]
                                       ]}
                                    >
                                        <a role='link' href={urlPaymentusTerms} target={"_blank"} className={"text-blue"}><FormattedMessage id={"payment-authorization-terms.label"} /></a>
                                    </CheckboxWithValidation>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>

                    </MDBCard>
                </section>

                <section
                    className={classNames({"ups-section ups-enroll-subsection ups-btn-section mb-4 non-modal": true})}>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                            <MDBBtn color={"secondary"} id="enroll-company-details-btn-start-over" onClick={this.props.handleRestart}>
                                <FormattedMessage id={"ups.btn.start-over.label"}/>
                            </MDBBtn>
                            <MDBBtn color={"primary"} id="enroll-company-details-btn-submit" onClick={this.enroll}>
                                <FormattedMessage id={"ups.btn.submit.label"}/>
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                            <MDBBtn flat id="enroll-company-details-btn-cancel" className={classNames({"btn-flat-link": true})}
                                    onClick={this.props.handleCancel}>
                                <FormattedMessage id={"ups.btn.enroll.cancel.label"}/>
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </section>
            </React.Fragment>
        );
    }

    enroll = () => {
        this.setState({postSubmit: true});
        let result = this.validator.validateAll()
        if(result.messages.length > 0) return;
        const {account, userInfo, invoice} = this.props;
        const {companyName} = this.state;
        this.props.enrollmentActions.enroll({account, userInfo, companyName, invoice});
    };

    handleInput = e => {
        const {name, value, checked, type} = e.target;
        switch (type) {
            case 'text':
                this.setState(
                    produce(draft => {
                        draft.postSubmit = false;
                        lodashSet(draft, name, value);
                    })
                );
                break;
            case 'checkbox':
                this.setState(
                    produce(draft => {
                        draft.postSubmit = false;
                        lodashSet(draft, name, checked);
                    })
                );
                break;
            default: break;
        }
    };
}

function mapStateToProps(state) {
    return {
        account: state.account.account,
        invoice: state.account.invoice,
        userInfo: state.auth.userInfo,
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        enrollmentActions: bindActionCreators(enrollmentActions, dispatch),
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EnrollmentCompanyDetails));
