import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { isEmpty as lodashIsEmpty, get as lodashGet, isUndefined as lodashIsUndefined } from 'lodash';
import * as _ from 'lodash';
import Exception from "../../Exception";
import {MDBDropdown, MDBDropdownMenu, MDBDropdownToggle} from "mdbreact";
import {injectIntl} from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import produce from "immer";
import classNames from "classnames";

import FilterModal from '../FilterModal';
import MetadataDateRangePicker from "../MetadataDateRangePicker";
import ExportDataModal from '../../../components/UPS/Modal/ExportDataModal';
import DisputesModal from '../DisputesModal';

import * as invoiceActions from '../../../actions/invoice-action';
import moment from "moment";
import QuickFilterPane from '../QuickFilterPane';
import {
    getFormattedValue,
    evaluateConditionals,
    getLabel,
    formatDateRangeString
} from '../../../utils/invoice-utils';
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import {TableScreenExpandedRow} from "./TableScreenExpandedRow";
import {constructDateFilter, customActions, customColumns, defaultColumnProps} from "./TableScreen-utils";
import {defaultButtonProps, exportData} from "../../DataTableV3/DataTableV3-utils";
import {calendarIcon} from "../../../assets/images/icon/datatableIcons";
import {SuperPaging} from "./TableScreenSuperPaging";
import DisputeDetailsModal from '../../../components/UPS/Modal/DisputeDetailsModal';

class TableScreen extends React.Component {

    constructor(props) {
        super(props);

        const { intl, invoiceLiterals, invoiceMetadata, enableSuperPaging, merchant } = props;
        const currencyDisplay = merchant.currencyDisplay;
        this.formatValue = getFormattedValue.bind({ intl, invoiceLiterals, invoiceMetadata, currencyDisplay });
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });

        this.state = {
            calendarModal: false,
            filterModal: false,
            exportDataModal: false,
            disputesModal: false,
            loaded: false,
            disputeDetailsModal:false,
            isFilterEnable: false,
            error: '',
            data: [],
            filters: [],
            dateFilters: [],
            columns: [],
            quickFilters: {},
            page: 0,
            pages: [],
            isLoadMoreVisible: false,
            hasSeenLastPage: false,
            detailsReceived: 0,
            expandedRows: {},
            expandedData: {},
            enableSuperPaging: enableSuperPaging ?? false,
            tableState: INITIAL_TABLE_STATE
        };
    }

    toggleModal = (modalName, data) => {
        this.setState({
            modalData: data,
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    componentDidMount = async () => {
        const { screen, invoiceMetadata, invoiceDetailsFilters, isTopLevel } = this.props;
        if(isTopLevel) this.setPageTitle()
        const screenConfig = invoiceMetadata.screens[screen];

        if (!screenConfig) return null;

        const { table } = screenConfig;
        const { detailType } = table;

        const columnsAndFilters = this.constructColumnsAndFilters(table);

        let searchCriteria = {};
        if ( invoiceDetailsFilters && detailType && invoiceDetailsFilters[detailType] ) {
            searchCriteria = invoiceDetailsFilters[detailType];
        }

        this.requestInvoiceDetails({
            searchCriteria,
            ...columnsAndFilters,
            isFilterEnable: this.hasFiltersPresent(searchCriteria)
        })
    }
    updateDisputeStatus = (invoiceDetailId) => {
        let expandedData = {...this.state.expandedData};
        if(expandedData[invoiceDetailId] && expandedData[invoiceDetailId]["0"]){
            let aggregateInvoiceDetails = {...expandedData[invoiceDetailId]};
            let invoiceDetails = {...aggregateInvoiceDetails["0"], inDispute:true};
            aggregateInvoiceDetails = {...[invoiceDetails],countryCode:aggregateInvoiceDetails.countryCode};
            expandedData[invoiceDetailId] = aggregateInvoiceDetails;
        }
   
        this.setState((prevState) => ({
            ...prevState,
            expandedData,
            data: prevState.data.map((invDetail) => (invDetail?.id === invoiceDetailId) ? { ...invDetail, inDispute: true } : invDetail)
        }))
    }

    setPageTitle = () => {
        const {invoice, businessUnitMessages} = this.props
        document.title = `${businessUnitMessages('pageTitle')} - ${invoice.invoiceNumber}`
    }

    hasFiltersPresent = (filters, quickFilters = []) => {
        return _.chain(filters)
            .omit(['startDate', 'endDate', 'dateField', 'dateFilters']) // date filters are separately dealt with from their modal
            .keys()
            .difference(_.keys(quickFilters))
            .some(k => !_.isEmpty(filters[k]))
            .value();
    }

    clearFilters = () => {
        const {searchCriteria, tableState, quickFilters} = this.state;
        const {dateFilters, startDate, endDate, dateField} = searchCriteria;
        //filtering by date doesn't get cleared by clicking "clear filter"
        const newSearchCriteria = {dateFilters, startDate, endDate, dateField}
        const newTableState = {...tableState, searchQuery:''}
        if(this.hasFiltersPresent(searchCriteria, quickFilters)){
            this.requestInvoiceDetails({
                searchCriteria: newSearchCriteria,
                tableState: newTableState,
                isFilterEnable: this.hasFiltersPresent(newSearchCriteria, quickFilters)
            }, newSearchCriteria)
        } else {
            this.setState({tableState: newTableState}) // just clear the searchQuery if no filter is currently set
        }
    };

    tableRowRenderer = (rowData, columns, renderedRow, rowContents, mobileExpandedRow, trClassName) => {
        const {invoice, screen,envProps} = this.props
        const screenConfig = this.props.invoiceMetadata.screens[screen];
        const {expandedRows, expandedData} = this.state
        const {formatValue, formatLabel, toggleModal} = this
        const {id} = rowData
        if(id && expandedRows[id]){
            return <>
                <tr className={classNames(trClassName?.(rowData), 'shown')}>
                    {rowContents}
                </tr>
                {mobileExpandedRow}
                <TableScreenExpandedRow
                    envProps={envProps}
                    rowData={rowData}
                    columns={columns}
                    renderedRow={renderedRow}
                    screenConfig={screenConfig}
                    formatValue={formatValue}
                    formatLabel={formatLabel}
                    toggleModal={toggleModal}
                    invoice={invoice}
                    expandedData={expandedData[id]}
                    setExpandedData={(invoiceDetails)=>this.setState(produce(newState=>{
                        newState.expandedData[id] = invoiceDetails
                    }))}
                />
            </>
        } else return renderedRow
    };

    requestInvoiceDetails(initState, initFilters) {
        const { screen, invoiceDetailsFilters, invoiceActions, invoice, invoiceMetadata, data } = this.props;
        const { page } = this.state;

        if (!lodashIsUndefined(data)) {
            this.setState({
                ...initState,
                loaded: true,
                data: Array.isArray(data) ? data?.map(detail=>({
                    ...detail,
                    ...invoice?.countryCode ? {countryCode:invoice.countryCode} : {}
                })) : data
            })
            return;
        }

        const { table } = invoiceMetadata.screens[screen];
        const { detailType } = table;

        const conditionals = lodashGet(table, 'conditionals', []);

        let newSearchCriteria = {
            screen,
            ...(detailType ? { detailType } : {})
        };

        if(!initFilters) {
            const filters = lodashGet(invoiceDetailsFilters, detailType, {});
            let {dateFilters, ...searchCriteria} = filters;
            newSearchCriteria = {
                ...newSearchCriteria,
                ...searchCriteria,
                ...(!lodashIsEmpty(dateFilters) ? constructDateFilter(dateFilters) : {}),
                page
            }
        } else {
            newSearchCriteria = {
                ...newSearchCriteria,
                ..._.omit(initFilters, 'dateFilters')
            }
        }

        invoiceActions.getInvoiceDetails({invoice, searchCriteria: newSearchCriteria, conditionals}, (invoiceDetails) => {

            const { countryCode } = invoice;
            const invoiceDetailsWithCountryCode = invoiceDetails.map(detail=>({...detail,countryCode}));
            const { pages } = this.state;
            const pageSize = lodashGet(invoiceMetadata, ['invoiceDetailsPageSize']);
            const detailsReceived = invoiceDetails.length;

            const isLastPage = detailsReceived < pageSize;

            this.setState({
                ...initState,
                loaded: true,
                hasSeenLastPage: this.state.hasSeenLastPage || isLastPage,
                isLoadMoreVisible: detailsReceived === pageSize,
                ...(detailsReceived <= pageSize && !pages.includes(page) ? { pages: [...pages, page] } : {}),
                data: invoiceDetailsWithCountryCode
            });
        });
    }

    buildActionMenu = (actions) => (rowData) => {
        const id = rowData.id ?? uuidv4()
        let actionsList = [];

        actions.forEach(action => {
            const renderedAction = customActions[action]?.(this, id, rowData, actionsList)
            if (renderedAction) actionsList.push(renderedAction)
        });

        if (actionsList[actionsList.length - 1]?.key.includes('dropdown-divider')) actionsList.pop()

        return lodashIsEmpty(actionsList) ? null : (
            <MDBDropdown key={`dropdown-${rowData.id}`}>
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown">
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    {actionsList}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    }

    getActionColumn = (columnDef) => {
        const {msgId, actions} = columnDef
        const {intl} = this.props;
        return {
            field: 'actions',
            label: intl.formatMessage({ id: msgId  }),
            className: 'text-center datatable-action-col prevent-toggle no-export',
            sortable: false,
            responsivePriority: 2,
            display: this.buildActionMenu(actions)
        }
    }

    constructColumnsAndFilters(table) {
        const { intl, invoice, invoiceLiterals, data, merchant } = this.props;
        const { countryCode } = invoice;
        const filters = [];
        const dateFilters = [];
        const invoicesWithCountryCode = data?.map(detail=>({...detail,countryCode}));
        const columns = table.columns.filter(c => {
            return !c.hidden && evaluateConditionals(c.conditionals, lodashIsUndefined(invoicesWithCountryCode) ? invoice : invoicesWithCountryCode[0]);
        }).map(c => {
            if (c.type === 'actions') return this.getActionColumn(c)

            if (c.hasOwnProperty("filter")) {
                if (c.filter.type === 'date') {
                    if (dateFilters.length < 2) {
                        dateFilters.push(c);
                    }
                } else {
                    filters.push(c);
                }
            }

            return {
                ...defaultColumnProps(intl, c, true),
                ...(customColumns[c.type]?.(intl, c, this.formatValue, invoiceLiterals, this.expandRow, merchant.currencyDisplay)||{})
            }
        });

        columns[0].responsivePriority = 1 //first column has highest responsive priority by default

        return {
            filters,
            dateFilters,
            columns
        };
    }

    handleCalendarChange = (filters) => {
        const { calendarOptionType = 'AVAILABLE', startDate = null, endDate = null } = filters.dateFilters;
        this.setState({
            selectedStartDate: startDate ? moment(startDate) : null,
            selectedEndDate: endDate ? moment(endDate) : null,
            calendarOptionType
        }, ()=>this.applyFilter(filters));
    }

    applyFilter = (newFilters) => {
        const {searchCriteria, quickFilters} = this.state;
        let updatedFilters = {
            ...searchCriteria,
            ...newFilters,
        };
        const parsedDateFilter = updatedFilters.dateFilters ? constructDateFilter(updatedFilters.dateFilters) : {};
        updatedFilters = {
            ...updatedFilters,
            ...(parsedDateFilter ? { ...parsedDateFilter } : {}),
            page: 0,
            pages: []
        }
        this.requestInvoiceDetails({
                searchCriteria: updatedFilters,
                isFilterEnable: this.hasFiltersPresent(updatedFilters, quickFilters),
            },
            updatedFilters
        )
    };

    expandRow = (e, data) => {
        const {id} = data
        this.setState(produce(newState=>{
            if(newState.expandedRows[id]){
                newState.expandedRows[id] = undefined
                newState.expandedData[id] = undefined
            } else {
                newState.expandedRows[id] = true
            }
        }))
    }

    getDateRangeString(){
        const {intl} = this.props;
        const {calendarOptionType, selectedStartDate, selectedEndDate} = this.state;
        return formatDateRangeString(intl, calendarOptionType, selectedStartDate ? moment(selectedStartDate) : '', selectedEndDate ? moment(selectedEndDate) : '')
    }

    getCalendarButtonText(){
        return <><span><span id="datatable-date-selection" className="datatable-date-selection">{this.getDateRangeString()}</span> {calendarIcon}</span></>
    }

    render() {
        const { toggleModal, updateFiltersAndSearch, clearFilters } = this;
        const { loaded, data, error,  filters, dateFilters, calendarOptionType, modalData, searchCriteria, columns, tableState } = this.state;
        const { disputesModal, filterModal, calendarModal, exportDataModal, isFilterEnable, page, pages, isLoadMoreVisible, enableSuperPaging, hasSeenLastPage ,disputeDetailsModal} = this.state;
        const { screen, invoiceMetadata, intl, invoice, invoiceLiterals, fetchData, settings, defaultButtonProps, envProps } = this.props;
        const { filteredData } = tableState;
        const screenConfig = invoiceMetadata.screens[screen];

        if (!screenConfig && fetchData) return null;
        let table, detailType, tableConditionals;
        if (screenConfig) {
            table = screenConfig.table;
            detailType = table.detailType;
            tableConditionals = lodashGet(table, 'conditionals', []);
        }

        if (!loaded) return null;

        if (error instanceof TypeError) {
            return (<Exception error={error} />)
        } else {
            const { quickFilters } = table || {};

            return (
                <React.Fragment>
                    {!lodashIsEmpty(quickFilters) && (
                        <QuickFilterPane
                            formatValue={this.formatValue}
                            invoice={invoice}
                            screen={screen}
                            envProps={envProps}
                            invoiceMetadata={invoiceMetadata}
                            quickFiltersConfig={quickFilters}
                            screenConfig={screenConfig}
                            quickFiltersData={this.state.quickFilters}
                            invoiceLiterals={invoiceLiterals}
                            updateQuickFilter={this.applyFilter}
                            setQuickFiltersData={(quickFilterData)=>{
                                this.setState({
                                    quickFilters:quickFilterData,
                                    isFilterEnable: this.hasFiltersPresent(searchCriteria, quickFilters),
                                })
                            }}
                        />
                    )}
                    {dateFilters.length > 0 && (
                        <MetadataDateRangePicker
                            autoFocusEndDate={false}
                            calendarInfoPosition="after"
                            showDefaultInputIcon={true}
                            showDatePicker={()=>toggleModal('calendar')}
                            showCalendar={calendarModal}
                            intl={intl}
                            dateRangeString=""
                            dateFilterFields={dateFilters}
                            updateFiltersAndSearch={this.handleCalendarChange}
                            calendarOptionType={calendarOptionType}
                            updateDateRangeString={()=>{}}
                            existingFilters={searchCriteria}
                        />
                    )}
                    <FilteredDataTableV3
                        name={'tableScreen'}
                        data={data}
                        columns={columns}
                        defaultSorting={columns[0].field}
                        externalFilterActive={isFilterEnable}
                        onClearFilter={this.clearFilters}
                        buttons={[
                            {type: "filter", hide: filters.length === 0, action:()=>toggleModal('filter')},
                            {...defaultButtonProps.calendar, hide: dateFilters.length === 0, text: dateFilters.length > 0 ? this.getCalendarButtonText() : '', action:()=>toggleModal('calendar')},
                            {...defaultButtonProps.export, hide: (data.length === 0),action:()=>toggleModal('exportData')}
                        ]}
                        searchable
                        responsive
                        delayedSearch={data.length > 1000}
                        tableState={tableState}
                        tableStateAction={(action)=>this.setState(action)}
                        rowRender={this.tableRowRenderer}
                        rowHeaderField={columns[0].field}
                    />
                    {enableSuperPaging && (
                        <SuperPaging
                            formattedPageSize={new Intl.NumberFormat(settings.locale).format(lodashGet(invoiceMetadata, ['invoiceDetailsPageSize']))}
                            page={page}
                            pages={pages}
                            getNextPage={()=>{
                                this.setState({
                                    page: page + 1,
                                }, ()=>this.requestInvoiceDetails())
                            }}
                            hasSeenLastPage={hasSeenLastPage}
                            isLoadMoreVisible={isLoadMoreVisible}

                        />
                    )}
                    {filterModal && (
                        <FilterModal
                            key={`${screen}-filter-modal`}
                            isOpen={filterModal}
                            filters={filters}
                            toggleModal={()=>toggleModal('filter')}
                            runFilter={this.applyFilter}
                            clearFilters={clearFilters}
                            initialFilters={searchCriteria}
                            screen={screen}
                            invoice={invoice}
                            detailType={detailType}
                            invoiceLiterals={invoiceLiterals}
                            conditionals={tableConditionals}
                        />
                    )}
                    {exportDataModal && (
                        <ExportDataModal
                            isOpen={exportDataModal}
                            handleExport={(type)=>exportData(filteredData,columns,tableState,document.title,type)}
                            toggleModal={toggleModal}
                            an_label={`export-${screen}-data`}
                        />
                    )}
                    {disputesModal && (
                        <DisputesModal
                            isOpen={disputesModal}
                            data={modalData}
                            toggleModal={toggleModal}
                            updateData={updateFiltersAndSearch}
                            invoice={invoice}
                            updateDisputeStatus={this.updateDisputeStatus}
                        />
                    )}
                    {disputeDetailsModal &&
                    <DisputeDetailsModal
                        isOpen={disputeDetailsModal}
                        data={modalData}
                        businessUnit={this.props?.invoice?.businessUnit}
                        toggleModal={toggleModal}
                    />
                }
                </React.Fragment>
            );
        }
    }
}

function mapStateToProps(state, {intl}) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        invoiceDetailsFilters: state.invoice.invoiceDetailsFilters,
        envProps: state.envProps,
        permissions: state.auth.user.permissions,
        user: state.auth.user,
        sessionSelection: state.config.sessionSelection,
        settings: state.settings,
        defaultButtonProps: defaultButtonProps(intl),
        merchant: state.config.sessionSelection.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TableScreen));