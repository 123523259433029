import React from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBCardImage, MDBCardTitle, MDBCardFooter, MDBNavLink } from "mdbreact";
import { useHistory} from 'react-router-dom';

const NoticeTile = ({msg}) =>{
    const history = useHistory();
    const handleButtonAction = (url, openInNewTab) => {
        switch (openInNewTab) {
            case true:
                window.open(url, '_blank');
                break;
            case false:
            default:
                history.push(url)
                break;
        }
    }

    const createMarkup = (message) => {
        return { __html: message };
    }

    const checkIfButtonsPresent = (msg) => {
        return (msg.buttons && msg.buttons.length);
    }

    const displayButtons = (msg) => {
        return msg.buttons.map((btn) => <MDBBtn id={btn.uniqueId} key={btn.uniqueId} onClick={() => handleButtonAction(btn.buttonActionUrl, btn.openInNewTab)} color={btn.buttonColor ? btn.buttonColor : "primary"} className={`${btn.buttonClass ? btn.buttonClass : ""}`}>{btn.buttonTitle ? btn.buttonTitle : ""}</MDBBtn>)
    }


        return (
            <MDBCard className={"dashboard-widget-card marketing-widget-card h-100"}>
                {msg.imageUrl && <MDBCardImage className="img-fluid" src={msg.imageUrl} alt={msg.title} />}
                <MDBCardTitle  tag="h2">
                        {(msg.titleIcon || msg.titleIconClass) && <span className={msg.titleIconClass ? `${msg.titleIconClass} mr-2` : "material-icons align-top mr-2" }>{msg.titleIcon ? msg.titleIcon : ""}</span>}
                        <span dangerouslySetInnerHTML={createMarkup(msg && msg.title ? msg.title : "")}></span>
                    </MDBCardTitle>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size={"12"} dangerouslySetInnerHTML={createMarkup(msg && msg.message ? msg.message : "")}>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
                {checkIfButtonsPresent(msg) && <div className={"card-footer"}>
                    {displayButtons(msg)}
                </div>}
            </MDBCard>
        )
}

export default NoticeTile;
