import React from 'react';
import {MDBBtn, MDBCol, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBModal} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";

function XMLEnrollErrorModal({ isOpen, toggleModal }) {
    const intl = useIntl();
    const modalHeading = "ups.edit-xml-enroll.label";
        return (
            <MDBModal isOpen={isOpen} toggle={toggleModal} size="lg" disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
            <MDBModalHeader ><FormattedMessage id={modalHeading} /></MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <p><FormattedMessage id={"ups.edit-xml-enroll.error"} /></p>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <MDBBtn  onClick={toggleModal} color="primary" id="enroll-xml-modal-btn-close"><FormattedMessage id={"close.label"} /></MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBModalFooter>
        </MDBModal>
          );
}
 
export default XMLEnrollErrorModal;