import React, { useLayoutEffect, useState } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody } from "mdbreact";

import ManageUserTable from "../../../components/UPS/ManageUserTable";
import * as layoutActions from "../../../actions/layout-action";
import {produce} from "immer";
import {filterUnassignableAccounts} from "../../../utils/ups-utils";
import {getIdentityListByCompanyId} from "../../../api/identity-api";
import {getCompanyAccountList} from "../../../api/account-api";
import * as errorActions from "../../../actions/error-action";
import Error from "../../../components/Error";
import {isFreightActive} from "../../../utils/config-utils";

class ManageUsers extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            tableLoaded: false,
            errors: []
        }
        const {addUser, editUser, removeUser} = this
        this.userCallbackActions = {
            addUser: addUser.bind(this),
            editUser: editUser.bind(this),
            removeUser: removeUser.bind(this)
        }
    }

    componentDidMount() {
        const {currentUser, errorActions, layoutActions, merchant} = this.props;

        layoutActions.showSpinner()

        Promise.all([
            getCompanyAccountList(currentUser.companyId),
            getIdentityListByCompanyId(currentUser.companyId)
        ])
        .then(([companyAccounts, userList]) => {
            let myAccounts = []
            if(userList){
                let myId = currentUser && currentUser.id
                let myUserListData = userList.find((user)=>(user.id === myId))
                if(myUserListData?.accounts && companyAccounts) myAccounts = filterUnassignableAccounts(myUserListData.accounts, companyAccounts, isFreightActive(merchant))
            }
            this.setState({companyUserList:userList, tableLoaded: true, myAccounts})
        })
        .catch(({errorCode}) => {
            if(errorCode) this.setState({errors: [errorCode]})
        })
        .finally(()=>{
            layoutActions.hideSpinner()
        })
    }

    addUser(user, callback = ()=>{}){
        this.setState(produce(draft=>{
            if(draft.companyUserList.length > -1){
                draft.companyUserList.push(user)
            } else {
                draft.companyUserList = [user]
            }
        }), callback)
    }
    editUser(data, callback = ()=>{}){
        if(data.id) {
            this.setState(produce(draft=> {
                let user = draft.companyUserList.find((user) => (user.id === data.id))
                if (user) {
                    user.externalModifiedByInfo = data.externalModifiedByInfo
                    user.externalModifiedBy = data.externalModifiedBy
                    user.externalModifiedOn = data.externalModifiedOn
                    user.role = data.role
                    user.accounts = data.accounts
                }
            }), callback)
        }
    }
    removeUser(data, callback = ()=>{}){
        if(data && data.length) {
            this.setState(produce(draft=> {
                data.forEach((removed)=> {
                    let index = draft.companyUserList.findIndex((user) => (user.id === removed.id))
                    if (index > -1) draft.companyUserList.splice(index, 1)
                })
            }), callback)
        }
    }

    render() {
        const {intl} = this.props;
        const {tableLoaded, companyUserList, myAccounts, tableRedrawFlag, errors} = this.state;
        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <a role='link' className="ups-link"  href="#"><FormattedMessage id={"ups.title.admin"} /></a><span
                                                    aria-hidden="true"> &gt; </span></li>
                                                <li className="ups-breadcrumb-item"><FormattedMessage id={"ups.title.admin.manage-users"} /></li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}><FormattedMessage id={"ups.title.admin.manage-users"} /></h1>
                                {errors?.length > 0 && <MDBRow><Error errors={errors}/></MDBRow>}
                                {tableLoaded && (
                                    <ManageUserTable
                                    caption= "ups.title.admin.manage-users"
                                        key={'admin-manage-users-table'}
                                        companyUserList={companyUserList}
                                        myAccounts={myAccounts}
                                        userCallbackActions={this.userCallbackActions}
                                    />
                                )}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

function mapStateToProps(state, ownprops) {
    return {
        currentUser: state.auth?.user,
        errors: state.error?.errors,
        merchant: state.config?.sessionSelection?.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        errorActions: bindActionCreators(errorActions, dispatch),
        layoutActions: bindActionCreators(layoutActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManageUsers));


