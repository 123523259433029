import React, {useEffect, useState} from 'react'
import { MDBRow, MDBCol } from "mdbreact";
import { FormattedMessage, useIntl } from 'react-intl';
import ReactSelectAllGrouped from "../../../../ReactSelectAllGrouped";
import {uniquePropVals} from "../../../../../utils/utils";

const allOption = { value: "*", msgId: "filter.multi.option.dropdown.all.selector"}

export default function ManageUserFilterCriteria ({userList, filter, setFilter, defaultFilter, setDefaultFilter}) {
    const intl = useIntl();
    let [roleOptions, setRoleOptions]= useState([])
    useEffect(()=>{
        let roles = uniquePropVals(userList, 'role').map((role)=>{
            let text = `ups.manage-user.role.${role}.label`
            return {value: text, msgId: text}
        })
        roles.unshift(allOption)
        setRoleOptions([{
            msgId: "react-select.select-your-filters",
            options: roles
        }])
        setDefaultFilter({role: roles})
    }, [])

    return(
        <React.Fragment>
            <MDBRow>
                <MDBCol size={"12"} key={"field-user-role"}>
                    <ReactSelectAllGrouped
                        label={intl.formatMessage({id:"ups.manage-user-table.role"})}
                        inputId={"userRole"}
                        className={"react-select-container"}
                        classNamePrefix={"react-select"}
                        options={roleOptions}
                        isMulti intlLabel allowSelectAll
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(selected)=>setFilter('role', selected)}
                        isSearchable={false}
                        backspaceRemovesValue={false}
                        value={filter?.role ?? defaultFilter?.role}
                        placeholder={<FormattedMessage id="react-select.select-your-filters" />}
                    />
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    )
}