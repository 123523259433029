import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBInputGroup, MDBTable, MDBTableHead, MDBTableBody  } from 'mdbreact';
import { FormattedMessage, FormattedDate, injectIntl  } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import produce from "immer";
import { set as lodashSet,} from 'lodash';
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../../../actions/invoice-action";
import * as errorActions from "../../../../actions/error-action";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getDownloadEligibility, getInvoiceDownloadRecordType} from "../../../../utils/invoice-utils";
import DownloadMessageModal from '../DownloadMessageModal/DownloadMessageModal';
import RadioGroup from "../../../RadioGroup";

class DownloadMultipleInvoiceModal extends Component {

    constructor(props) {
        super(props);
        const isLocalCsv = props.data?.values().next().value.localCsvIr === "1" ? true : false;
        const downloadEligibility = getDownloadEligibility(props.businessUnit, props.data?.values().next().value.recordType, isLocalCsv)

        const downloadOptionList = Object.keys(props.invoiceMetadata.invoiceDownloads).filter(option => ((option !== 'xml') && downloadEligibility.includes(option)));
        this.state = {
            downloadOptionType: downloadOptionList[0],
            downloadOptionList
        };
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            })
        );
    };

    handleCheckBox = e => {
        const inputName = e.target.name;
        const inputChecked = e.target.checked;
        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputChecked);
            })
        );
    };

    handleDownload = async () => {
        const {downloadOptionType} = this.state
        const { invoiceDisplayData, invoiceMetadata, invoiceActions, getDownloadCriteria, isPlanInvoice, businessUnit, locale } = this.props
        const { invoiceDownloads } = invoiceMetadata;
        const url = invoiceDownloads ? invoiceDownloads[downloadOptionType.toLowerCase()] : null;

        if (!url) {
            return;
        }


        const searchCriteria = {
            ...(await getDownloadCriteria(downloadOptionType)),
            recordType: getInvoiceDownloadRecordType(invoiceDisplayData[0]),
            businessUnit,
            isPlanInvoice,
            locale
        };

        invoiceActions.getInvoiceDownload(url, searchCriteria);
    };

    getModalTitle = () => {
        const {businessUnit, isPlanInvoice} = this.props;
        if (businessUnit === 'EBS') {
            return isPlanInvoice ? 'invoice.download-multiple.plan.title' : 'invoice.download-multiple.title';
        } else if (businessUnit === 'FRT') {
            return isPlanInvoice ? 'invoice.download-multiple.statement.title' : 'invoice.download-multiple.pros.title';
        } else if (businessUnit === 'SCS') {
            return isPlanInvoice ? 'invoice.download-multiple.statement.title' : 'invoice.download-multiple.title';
        }
    };

    getModalMessages = () => {
        const {businessUnit, isPlanInvoice} = this.props;
        if (businessUnit === 'EBS') {
            if (isPlanInvoice) {
                return {
                    msgModalTitle: 'invoice.download-multiple.plan.title',
                    msgModalInvoiceNumber: 'invoice.download-multiple.invoice-number',
                    msgModalAccountNumber: 'invoice.download-multiple.plan-number'
                }
            } else {
                return {
                    msgModalTitle: 'invoice.download-multiple.title',
                    msgModalInvoiceNumber: 'invoice.download-multiple.invoice-number',
                    msgModalAccountNumber: 'invoice.download-multiple.account-number'
                }
            }
        } else if (businessUnit === 'FRT') {
            if (isPlanInvoice) {
                return {
                    msgModalTitle: 'invoice.download-multiple.statement.title',
                    msgModalInvoiceNumber: 'invoice.download-multiple.statement-number',
                    msgModalAccountNumber: 'invoice.download-multiple.account-number'
                }
            } else {
                return {
                    msgModalTitle: 'invoice.download-multiple.pros.title',
                    msgModalInvoiceNumber: 'invoice.download-multiple.statement-number',
                    msgModalAccountNumber: 'invoice.download-multiple.account-number'
                }
            }
        } else if (businessUnit === 'SCS') {
            if (isPlanInvoice) {
                return {
                    msgModalTitle: 'invoice.download-multiple.statement.title',
                    msgModalInvoiceNumber: 'invoice.download-multiple.statement-number',
                    msgModalAccountNumber: 'invoice.download-multiple.invoice-number'
                }
            } else {
                return {
                    msgModalTitle: 'invoice.download-multiple.title',
                    msgModalInvoiceNumber: 'invoice.download-multiple.invoice-number',
                    msgModalAccountNumber: 'invoice.download-multiple.account-number'
                }
            }
        }
    };

    componentDidMount() {
        const { errorActions } = this.props;

        errorActions.clearErrors();
    }

    render() {
        const { downloadOptionType, downloadOptionList } = this.state;
        const { isOpen, backdrop, modalName, invoiceDisplayData, toggleModal, isPlanInvoice, errors, businessUnit, invoiceMetadata, errorActions, intl, countryCode } = this.props;
        const {msgModalTitle, msgModalInvoiceNumber, msgModalAccountNumber} = this.getModalMessages();

        if ([403, 413].includes(errors?.status)) {
            return (
                <DownloadMessageModal
                    isOpen={true}
                    backdrop={backdrop}
                    toggleModal={toggleModal}
                    errorCode={errors?.status}
                    errorMessage={errors?.parsedBody?.msg}
                    invoiceMetadata={invoiceMetadata}
                    toggleParentModal={()=>{
                        errorActions.clearErrors();
                        toggleModal(modalName)
                    }}
                />
            )
        }

        return (
            <ReactPortalModal isOpen={isOpen} an_label="download-multiple-invoice">
                <MDBModal isOpen={isOpen} toggle={() => toggleModal(modalName)} size="lg" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:msgModalTitle})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: msgModalTitle }) })} toggle={() => toggleModal(modalName)}><FormattedMessage id={msgModalTitle} /></MDBModalHeader>
                    <MDBModalBody>
                        {errors && errors.length > 0 &&
                            <div className="alert alert-error" role="alert">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                                <title><FormattedMessage id="alert.label" /></title>
                                    <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                                </svg><span className="alert-msg"><FormattedMessage id={errors[0]} /></span>
                            </div>
                        }
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <p><FormattedMessage id={businessUnit !== "FRT" ? "invoice.download-multiple.format-selection" : "invoice.download-multiple.pros-format-selection"} /></p>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <div className={"form-radio-group"}>
                                    <fieldset>
                                        <RadioGroup
                                            name={"downloadOptionType"}
                                            radioProps={
                                                downloadOptionList.map(
                                                    downloadOption => ({
                                                        id: "downloadOptionType_" + downloadOption,
                                                        label: <FormattedMessage
                                                            id={"btn.download-option-short."+ (downloadOption === 'lcsv' ? "CSV_LITE" : downloadOption.toUpperCase())}
                                                            values={{var:['US','CA'].includes(countryCode.toUpperCase()) ? '32' : '28'}}
                                                        />,
                                                        name: 'downloadOptionType',
                                                        checkedValue: downloadOption,
                                                        labelClass: "mr-0 mt-3"
                                                    })
                                                )
                                            }
                                            value={downloadOptionType}
                                            onChange={this.handleInput}
                                        />

                                    </fieldset>
                                </div>
                            </MDBCol>
                            <MDBCol size={"12"} className={"mt-4"}>
                                <p><FormattedMessage id={businessUnit !== "FRT" ? "invoice.download-multiple.invoices-selected" : "invoice.download-multiple.pros-selected"} /></p>
                                <MDBTable className={"table-sm table-bordered-simple mb-5 mt-0"}>
                                    <MDBTableHead color={"light"}>
                                        <tr>
                                            { isPlanInvoice ?
                                                <>
                                                    <th className={"w-50"}><FormattedMessage id={msgModalAccountNumber} /></th>
                                                    <th className={"w-50"}><FormattedMessage id='invoice.download-multiple.invoice-date' /></th>
                                                </>
                                            :
                                                <>
                                                    <th className={"w-33"}><FormattedMessage id={msgModalInvoiceNumber} /></th>
                                                    <th className={"w-33"}><FormattedMessage id={msgModalAccountNumber} /></th>
                                                    <th className={"w-33"}><FormattedMessage id={businessUnit !== "FRT" ? 'invoice.download-multiple.invoice-date' : 'invoice.download-multiple.pros-date'} /></th>
                                                </>
                                            }
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                    {invoiceDisplayData && invoiceDisplayData.map((invoice) => (
                                        <tr key={invoice.invoiceNumber}>
                                            {!isPlanInvoice ? <td>{invoice.invoiceNumber}</td> : ""}
                                            <td>{invoice.accountNumber}</td>
                                            <td>
                                                <FormattedDate
                                                    value={invoice.invoiceDate}
                                                    year="numeric"
                                                    month="short"
                                                    day="2-digit" />
                                            </td>
                                        </tr>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn id={'download-multiple-invoice-btn-download'} color="primary" className={"block"} onClick={this.handleDownload}>
                                <FormattedMessage id={'invoice.download-multiple.btn-download.label'} /> </MDBBtn>
                        <MDBBtn id={'download-multiple-invoice-btn-cancel'} color="cancel" onClick={() => toggleModal(modalName)}>
                                <FormattedMessage id={'invoice.download-multiple.btn-cancel.label'} /> </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        countryCode: state.config.sessionSelection.merchant.countryCode,
        invoiceMetadata: state.invoice.invoiceMetadata,
        businessUnit: state.auth.user.selectedPaymentType,
        locale: state.auth.user.locale,
        errors: state.error.errors,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        errorActions: bindActionCreators(errorActions, dispatch),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DownloadMultipleInvoiceModal)));
