/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {fetchWrapper} from '../utils/utils';

import samplePaymentListByIdentity from "../static/json/response/payment_list_by_identity_id.json";

export function getPaymentListByIdentityId(identityId, apiUrlPrefix, apiToken) {
    console.log('payment api good');
    return samplePaymentListByIdentity;
    // export function updateIdentity(identity, apiUrlPrefix, apiToken) {
//     const body = {//
//     };
//     const url = `${apiUrlPrefix}/payment​/list​/byIdentity​/${identityId}`,
//         method = 'GET',
//         headers = {
//             'Content-Type': 'application/json',
//             'x-api-token': apiToken
//         };
//     return fetchWrapper(url, method, headers, body);
// }
}

export function cancelPaymentApi(data, apiUrlPrefix, apiToken) {
    const url = `${apiUrlPrefix}/payment/cancel`,
        method = 'POST',
        headers = {
            'x-api-token': apiToken
        };
    return fetchWrapper(url, method, headers, data);
}
// export function getPaymentMethodEligibility(body){
//     const url = `${REACT_APP_API_URL_PREFIX}/payment/payMethodEligibility`,
//     method = "POST",
//     headers = {
//         "Content-Type": "application/json"
//     };

//     const result = fetchWrapper(url, method, headers, body);
//     return result;
// }
