import React, { useState } from "react";
import {
    MDBRow, MDBCol, MDBBtn
} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";
import {displayWalletSelection} from "../../../../utils/payment-utils";
import SelectFieldIntl from "../../../SelectFieldIntl";
import SchedulePreview from "./SchedulePreview";
import AchAgreementConfirmation from "../../../UPS/AchAgreementConfirmation/index"
import {isAgreementOptionSelected} from "../../../../utils/payment-method-utils";
import Alert from "../../../Alert";

export const GetCategoryCodeFromWalletEntries = (paymentMethod, wallet) => {
    let categoryCode = paymentMethod?.categoryCode
    if(!categoryCode && paymentMethod?._id && wallet?.length > 0){
        return wallet?.find((pm)=>{
            return (pm._id === paymentMethod._id)
        })?.categoryCode
    }
    return categoryCode
}

export default function PaymentMethodInfo({wallet, schedule, onChange, onAddPM, authorizeDD, sessionSelection}) {
    const {businessUnit = {}, merchant = {}} = sessionSelection
    const intl = useIntl();
    const walletOptions = [{
        value: "",
        label: intl.formatMessage({id: "cancel-account-reason.select-one"})
    }].concat(wallet.map(pm => ({
        value: pm._id,
        label: displayWalletSelection(intl)(pm)
    })));
    const {paymentMethod} = schedule;
    const categoryCode = GetCategoryCodeFromWalletEntries(paymentMethod, wallet)
    return <MDBRow>
        <MDBCol size="12">
            <h3 className="mb-4">
                <FormattedMessage id="modal.view-schedule.subtitle.payment-method-information"/>
            </h3>
        </MDBCol>

        <MDBCol size="12">
            <SchedulePreview schedule={{...schedule, paymentMethod: null}}/>
        </MDBCol>

        <MDBCol size="12">
            <p className="ups-note-1 my-0">*<span className="font-italic">
                <FormattedMessage id="ups.required-field.note"/>
            </span></p>
        </MDBCol>

        <MDBCol size="12" md={"6"}>
            <SelectFieldIntl name="paymentMethod" id="paymentMethod"
                extraContainerClasses="mt-4"
                value={paymentMethod?._id || ""}
                selectOptions={walletOptions}
                label={intl.formatMessage({id: "modal.view-schedule.my-wallet"})}
                setLabelBefore required
                onChange={e => {
                    const {value} = e.target;
                    const paymentMethod = wallet.find(pm => pm._id === value);
                    return onChange({...schedule, paymentMethod, authorizeDD: false});
                }}
                validations={[["required", "field.required"]]}
            />
        </MDBCol>

        <MDBCol size="12" md={"6"}>
            <MDBBtn className="btn-block w-100 w-sm-auto mt-0 mt-md-4 mb-5 mb-md-0" color="text-link" onClick={onAddPM}>
                <FormattedMessage id="btn-add-payment-method"/>
            </MDBBtn>
        </MDBCol>

        {(categoryCode === "CC") && (businessUnit?.name === "EBS") && (merchant?.countryCode === "US") && (
            <MDBRow>
                <MDBCol size={"12"}>
                    <Alert
                        type={'warning'}
                        label={'payment-method.autopay-paybytext.credit-surcharge-warning'}
                        values={{link:<a href={"https://www.ups.com/rates"} target={"_blank"}>https://www.ups.com/rates</a>}}
                    />
                </MDBCol>
            </MDBRow>
        )}
        { isAgreementOptionSelected(paymentMethod) &&
            <AchAgreementConfirmation
                authorizeDD={authorizeDD}
                onAuthorizeACH={({target: {checked}}) => onChange({...schedule, paymentMethod, authorizeDD: checked})}
                type={paymentMethod?.categoryCode}
            />
        }
    </MDBRow>;
}
