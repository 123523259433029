import React from "react";
import {MDBRow, MDBCol} from "mdbreact";
import {FormattedMessage} from "react-intl";

import ScheduleView from "../../ScheduleView";

export default function Confirmation({confirmation, isNew = true}) {
    return <MDBRow>
        <MDBCol size="12">
            <h3 className="mb-4">
                <FormattedMessage id="modal.view-schedule.subtitle.confirmation"/>
            </h3>
            <p role="alert">
                {isNew
                    ? <FormattedMessage id="receipt.schedule.successfully-created"/>
                    : <FormattedMessage id="receipt.schedule.successfully-edited"/>
                }
            </p>
        </MDBCol>
        <MDBCol size="12">
            <ScheduleView schedule={confirmation}/>
        </MDBCol>
    </MDBRow>;
}
