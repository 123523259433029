/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {callApiGet, fetchWrapper, getFullUrl, callApiPost} from '../utils/utils';

export function getDisputeReasons({invoiceId, invoiceDetailId, accountNumber, planNumber, recordType}) {
    const body = {
        invoiceId,
        invoiceDetailId,
        accountNumber,
        planNumber,
        recordType,
    }

    return callApiPost({ url: getFullUrl('/dispute/codes'), body});
}

export function disputeFreeForm(body, apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/dispute/freeform`, 'POST', headers, body);
}

export function submitDispute(invoiceId, invoiceDetailId, body, apiUrlPrefix, apiToken) {
    if (!invoiceId && !invoiceDetailId) {return {parsedBody: []};}

    const params = new URLSearchParams();
    if (invoiceDetailId) params.append('invoiceDetailId', invoiceDetailId);
    if (invoiceId) params.append('invoiceId', invoiceId);

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/dispute?${params.toString()}`, 'POST', headers, body);
}

export function getDisputes(queryParams, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();
    const { createdDate, createdBy, statusCode, reasonCode } = queryParams;

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    if (createdBy) params.append('createdBy', createdBy);
    if (statusCode) params.append('statusCode', statusCode.join(','));
    if (reasonCode) params.append('reasonCode', reasonCode.join(','));
    if (createdDate) {
        params.append('createdDateBegin', createdDate.startDate);
        params.append('createdDateEnd', createdDate.endDate);
    }

    return fetchWrapper(`${apiUrlPrefix}/dispute/list?${params.toString()}`, 'GET', headers);
};

export function getDisputeStats() {
    return callApiGet({url: getFullUrl(`/dispute/stats`)});
}

export function getDisputeDetails(queryParams, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();
    const {trackingNumber, invoiceId} = queryParams;
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };
    if (trackingNumber) params.append('trackingNumber', trackingNumber);
    if (invoiceId) params.append('invoiceId', invoiceId);
  

    return fetchWrapper(`${apiUrlPrefix}/dispute/details?${params.toString()}`, 'GET', headers);
}

export function getDisputesByIdentifier(invoiceId, invoiceDetailRef, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();

    if (!invoiceId && !(invoiceDetailRef && invoiceDetailRef['invoiceDetailId'])) return {parsedBody: []};
    const {invoiceDetailId, trackingNumber, pickupRequestNumber} = invoiceDetailRef || {};

    if (invoiceId) params.append('invoiceId', invoiceId);
    if (invoiceDetailId) params.append('invoiceDetailId', invoiceDetailId);
    if (trackingNumber) params.append('trackingNumber', trackingNumber);
    if (pickupRequestNumber) params.append('pickupRequestNumber', pickupRequestNumber);


    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/dispute/list?${params.toString()}`, 'GET', headers);
}