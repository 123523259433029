import React, { Component } from 'react';
import { MDBInput } from "mdbreact";

// Modified MDBInput to support placeholder with react-intl
// Because <FormattedMessage /> is a component which cannot be placed in placeholder which expects a raw String.
class CheckboxIntl extends Component{
    componentDidMount() {
        const { id, ...rest } = this.props;
        const label = document.querySelector("label[for='" + id + "']")
        if(label?.getAttribute("aria-labelledBy") === ''){
            label.removeAttribute("aria-labelledBy");
            label.setAttribute("title", rest["aria-label"]);
        }
    }

    render(){
        const { name, id, label, value, onChange, tabIndex, containerClass, className, labelClass, ...rest } = this.props;
        return (
            <MDBInput
                name={name}
                id={id || name}
                type={'checkbox'}
                label={label}
                checked={value}
                onChange={onChange}
                containerClass={containerClass}
                className={className}
                tabIndex={tabIndex}
                labelClass={labelClass || ''}
                {...rest}
            />
        )
    }
}

export default CheckboxIntl;