import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'

// TODO FIXME use redux-persist and local storage for now for development purposes
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// reducers
import rootReducer from '../reducers';

// sagas
import rootSaga from '../sagas';

import { updateState } from '../utils/ups-utils';

// middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['layout', 'validation', 'error'] //the state of these reducers won't persist between refreshes
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configStore(initialState) {
    const store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(...middlewares))
    );

    let persistor = persistStore(store, null, () => updateState(store));

    sagaMiddleware.run(rootSaga);

    return {store, persistor};
}