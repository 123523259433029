import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl} from "react-intl";
import {MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBCardBody, MDBBtn} from "mdbreact";
import {formatCurrency} from "../../../utils/invoice-utils";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as disputeActions from '../../../actions/dispute-action';
import { DISPUTE_APPROVED, DISPUTE_DENIED, DISPUTE_PENDING } from '../../../utils/dispute-utils';
import * as _ from 'lodash';
import {FormattedMessage} from "react-intl";
import moment from "moment";
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

export default function DashboardDisputeTile({ gotoDisputes }) {
    const intl = useIntl();
    const [chartData, setChartData] = useState(null);
    const [disputeData, setDisputeData] = useState(null);
    const [totalCreditAmount, setTotalCreditAmount] = useState(0);
    const [disputeSummaryDateRange, setDisputeSummaryDateRange] =useState(null);

    const { user: { selectedCountry, selectedPaymentType } } = useSelector(s => s.auth);
    const selectedLanguage = useSelector(s => s.settings.locale);
    const merchant = useSelector(s => s.config.sessionSelection.merchant);

    const dispatch = useDispatch();

    useEffect(() => {
      const endDate = moment(new Date(), "YYYY-MM-DD");
      const startDate = moment(moment().subtract(30, 'days'), "YYYY-MM-DD");
      const dateRange = `${startDate.format(intl.formatMessage({ id: `date-format` }))} to ${endDate.format(intl.formatMessage({ id: `date-format` }))}`;
      setDisputeSummaryDateRange(dateRange);

    }, []);
    
    useEffect(() => {
        dispatch(disputeActions.getDisputeSummary(data => {
            setTotalCreditAmount(_.get(data, `${DISPUTE_APPROVED}.total`, 0));
            setDisputeData(data)
            setChartData({
                disputeSummaryData: {
                    chart: {
                        type: 'bar',
                        backgroundColor: '#F7F6F5',
                        spacingLeft: 1,
                        panning: false,
                        height: '228px',
                        followTouchMove: false,
                        zoomType: null,
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: ['Credited','Pending','Denied'],
                        lineWidth: 0,
                        tickWidth: 0,
                        minPadding: 0,
                        maxPadding: 0,
                        visible: false,
                    },
                    yAxis: {
                        min: 0,
                        minPadding: 0,
                        visible: false
                    },
                    tooltip: {
                        enabled: false,
                        followTouchMove: false
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        data: [
                            {y: _.get(data, `${DISPUTE_APPROVED}.count`, 0), color:'#6E7E1B', name: 'credited'},
                            {y: _.get(data, `${DISPUTE_PENDING}.count`, 0), color:'#AAAAAA', name: 'pending'},
                            {y: _.get(data, `${DISPUTE_DENIED}.count`, 0), color:'#330000', name: 'denied'} 
                        ],
                        enableMouseTracking: false,
                        pointWidth: 46,
                        minPointLength:5,
                        dataLabels: {
                            enabled: true,
                            align: 'left',
                            crop: false,
                            padding: 15,
                            style: {
                                fontFamily: 'Tahoma, helvetica, arial, sans-serif',
                                textOutline: 0
                            },
    
                            overflow: 'justify',
                            formatter: function () {
                                return intl.formatMessage({ id: `dashboard.dispute-summary.chart.${this.key}`},{count: this.y})
                            }
                        },
                        showInLegend:  false
                    }]
                }
            });
        }));
    }, [selectedCountry, selectedLanguage, selectedPaymentType]);

    if (!chartData) return null;
    const { AP, DN, PE } = disputeData
    const isNoDisuptes = !AP?.count && !DN?.count && !PE?.count
    return (
      <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-4"}>
        <MDBCard className={"dashboard-widget-card dispute-summary-card h-100"}>
          <MDBCardTitle tag="h2">{intl.formatMessage({ id: "dashboard.dispute-summary.title" })}</MDBCardTitle>
          <MDBCardBody>
            {isNoDisuptes ? (
              <MDBRow>
                <MDBCol size={"12"}>{intl.formatMessage({ id: "invoice.disputes.no-dispute-to-display" })}</MDBCol>
              </MDBRow>
            ) : (
              <MDBRow>
                <MDBCol size={"12"}>
                <div className="dispute-status-container">
                                    <ul className="custom-timeline">
                                        <li className="dispute-date-range">
                                            <span className="timeline-date date-range-title">
                                            <FormattedMessage id="dashboard.dispute-summary.tile.date-range.label"/>
                                                {disputeSummaryDateRange}
                                            </span>
                                          
                                        </li>
                                        <li>
                                            <span className="timeline-date">
                                                <FormattedMessage id="dashboard.dispute-summary.tile.credited"/>
                                            </span>
                                            <span className="timeline-desc">
                                                  {disputeData?.AP?.count}
                                            </span>
                                        </li>

                                        <li>
                                            <span className="timeline-date">
                                                <FormattedMessage id="dashboard.dispute-summary.tile.pending"/>
                                            </span>
                                            <span className="timeline-desc">
                                            {disputeData?.PE?.count}
                                            </span>
                                        </li>

                                        <li>
                                            <span className="timeline-date">
                                                <FormattedMessage id="dashboard.dispute-summary.tile.denied"/>
                                            </span>
                                            <span className="timeline-desc">
                                            {disputeData?.DN?.count}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                </MDBCol>
                {AP?.count !== 0 && (
                  <MDBCol size={"12"}>
                    <p className={"mt-2 mb-0"}>
                      <strong>{intl.formatMessage({ id: "dashboard.dispute-summary.total-credited" })}</strong>
                      {formatCurrency(intl, Math.abs(totalCreditAmount), AP?.currencyCode, merchant.currencyDisplay)}
                    </p>
                  </MDBCol>
                )}
              </MDBRow>
            )}
          </MDBCardBody>
          {gotoDisputes && !isNoDisuptes && (
            <div className={"card-footer"}>
              <MDBBtn id="btn-view-disputes" color={"text-link"} className={"btn-view-disputes m-0 pl-0"} onClick={() => gotoDisputes()}>
                {intl.formatMessage({ id: "dashboard.dispute-summary.view-disputes" })}
              </MDBBtn>
            </div>
          )}
        </MDBCard>
      </MDBCol>
    );
}