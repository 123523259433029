import XLSX from 'xlsx';
import moment from 'moment';
import * as R from "ramda";
import {columnSettingsIcon, exportIcon} from "../../assets/images/icon/datatableIcons";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs; // needed for csv/xlsx export

export const SORTED_ASC  = 1;
export const SORTED_DSC  = 2;
export const SORTED_NONE = 3;
export const ITEMS_PER_PAGE_ALL = -1;

const getSortBy = (fieldName, sortFunc) => R.compose(
    R.when(R.is(String), R.toUpper),
    sortFunc ?? R.prop(fieldName)
)

export function isColumnExportable(column){
    return (!column.noExport && (column.serialize || (!column.serialize && !column.display)))
}

export function sortData(data, tableState){
    const {sortedField, sortedDirection, sortFunc} = tableState;
    let sortedData = R.sortBy(getSortBy(sortedField, sortFunc),data)
    if(sortedDirection === 2) sortedData = R.reverse(sortedData)
    return sortedData;
}

function setFilename(prefix) {
    const timestamp = moment().format('YYYY-MM-DD_hmmss');
    return `${prefix}_${timestamp}`;
}

export const exportData = (data, columns, tableState, name, format) => {
    const sortedData = sortData(data, tableState);
    let fullData = []
    let columnWidths = {}
    fullData.push(columns.reduce((acc, column)=>{
        if(isColumnExportable(column)) {
            acc.push(column.label)
            columnWidths[column.field] = {wch: Math.max(columnWidths[column.field]?.wch??0, column.label.length)}
        }
        return acc
    }, []))
    const rowArray = sortedData.map((item)=>{
        return columns.reduce((acc, column)=>{
            if(isColumnExportable(column)) {
                const text = column.serialize?.(item) ?? item[column.field] ?? ''
                acc.push(text)
                columnWidths[column.field] = {wch: Math.max(columnWidths[column.field]?.wch??0, text.length)}
            }
            return acc
        },[])
    })
    fullData = fullData.concat(rowArray)
    let sheet = XLSX.utils.aoa_to_sheet(fullData)
    sheet['!cols'] = Object.values(columnWidths)
    //sheet['!rows'] = [{hpx:30}]
    let book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet);
    const file = setFilename(name) + '.' + (format.toLowerCase())
    XLSX.writeFile(book, file)
}

export const defaultButtonProps = (intl) => ({
    columnSettings: {type: 'iconButton', className: 'btn-column-settings', icon: columnSettingsIcon, title:intl.formatMessage({id:'datatable.btn-column-settings.tooltip'})},
    export: {type: 'iconButton', className: 'btn-export', icon: exportIcon, title:intl.formatMessage({id:'datatable.btn-export.tooltip'})},
    calendar : {type: "secondary", className:'btn-calendar', title:intl.formatMessage({id:'datatable.btn-calendar.tooltip'})}
})