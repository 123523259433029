import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import {set as lodashSet} from "lodash";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import produce from "immer";

import Details from "..";
import { MDBRow, MDBCol } from "mdbreact";
import SelectFieldIntl from '../../../../components/SelectFieldIntl';
import InputFieldIntl from '../../../../components/InputFieldIntl';
import DisplayOnlyField from '../../../../components/DisplayOnlyField';
import ACHCheckbox from './ACHCheckbox';
import RadioGroup from '../../../../components/RadioGroup';
import {maskNumber } from '../../../../utils/utils';



class PlaceholderPmDetails extends React.Component {
    constructor(props) {
        super(props);
        const pm = props.paymentMethod;
        if (!pm) this.state = {methodType: "SEPA", country: "AT"};
        else this.state = {
            cardNickName: pm.cardNickName,
            methodType: pm.methodType,
            country: pm.country,
            routingNumber: maskNumber(pm.routingNumber),
            accountHolderName: pm.accountHolderName
        };
    }

    render() {
        const {
            methodType, routingNumber, bankAccountNumber, bankName,
            accountHolderName, authorizeAch, cardNickName, country
        } = this.state;
        const {paymentMethod, isModal} = this.props;

        const form = <React.Fragment>
            <MDBRow>
                <MDBCol size="12" md={"6"}>
                <FormattedMessage id="placeholder.payment.detail"/> 
                </MDBCol>
            </MDBRow>
        </React.Fragment>;

        return <Details
            form={form}
            isModal={isModal}
            onSubmit={this.validatePM}
            onCancel={this.props.handleCancel}
            extraFooterCssClass={"mt-3"}
        />;
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value || e.target.checked;
        this.setState(produce(draft => {
            lodashSet(draft, inputName, inputValue);
        }));
    };

    validatePM = () => {
        const {
            methodType, routingNumber,
            accountHolderName, authorizeAch, cardNickName, country
        } = this.state;
        const data = {
            methodType: methodType,
            country: country,
            routingNumber,
            cardNickName,
            accountHolderName,
            authorizeAch
        };
        if (this.props.paymentMethod) data.id = this.props.paymentMethod.id;
        this.props.submitAction(data);
    }
}

export default PlaceholderPmDetails;
