import React, {useEffect} from 'react';
import {MDBCol} from 'mdbreact';
import {FormattedMessage, FormattedDate, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import moment from 'moment';
import * as R from 'ramda';

import DisplayOnlyField from '../DisplayOnlyField';
import {maskString} from '../../utils/utils';
import {formatCurrency} from '../../utils/ups-utils';
import {DATE_FORMAT as AP_DATE_FORMAT} from '../../constants/autopay';
import {getClientIP} from '../../api/otp-api';

const DATE_FORMAT = 'MM-DD-YYYY hh:mm:ss';

const formatAddress = intl =>
    R.compose(
        R.join(', '),
        R.props(['line1', 'line2', 'line3']),
        a => R.assoc('line2', `${a.zipCode} ${a.city}`)(a),
        a =>
            R.assoc(
                'line3',
                intl.formatMessage({id: `country-item.${a.country}`})
            )(a)
    );

const formatDate = R.when(R.complement(R.isNil), d =>
    moment(d, AP_DATE_FORMAT).format(DATE_FORMAT)
);

const formatNotification = intl =>
    R.cond([
        [
            R.pathEq(['preferences', 'notifications', 'defaultType'], 'email'),
            R.compose(
                values =>
                    intl.formatMessage(
                        {id: 'sepa-mandate.sec.schedule.notif.email'},
                        values
                    ),
                ([email, daysPrior]) => ({email, daysPrior}),
                R.paths([
                    ['email'],
                    [
                        'preferences',
                        'notifications',
                        'emailSettings',
                        'triggers',
                        'daysPrior'
                    ]
                ])
            )
        ],
        [R.T, R.always('')]
    ]);

const getCurrencyCode = countryCode =>
    R.compose(
        R.propOr('', 'currencyCode'),
        R.find(R.propEq('countryCode', countryCode))
    );

const getCurrencyDisplay = currencyDisplay =>
    R.compose(
        R.propOr('', 'currencyDisplay'),
        R.find(R.propEq('countryCode', currencyDisplay))
    );

const cleanString = input => R.map(char => char.charCodeAt(0) <= 127 && char.charCodeAt(0) >= 32? char : '', input).join('');

const maskName = name => `${name[0]}****${name.slice(-1)}`;

// There are different forms for schedule payment pending and payment complete
const getPaymentMethod = R.when
  ( R.has('paymentMethod')
  , R.prop('paymentMethod')
  );

export default function SepaMandatePreview({schedule, payment, onSepaMandateLoad, onError}) {
    const intl = useIntl();

    // Debtor
    // const {paymentMethod} = schedule || payment || {};
    // const {address} = payment || {};
    const {referenceId, sepaMandate} = payment || schedule;
    const paymentMethod = getPaymentMethod (payment || schedule);
    const {address} = paymentMethod || {};

    // Scheduling
    const {merchants} = useSelector(s => s.config);
    const {user} = useSelector(s => s.auth);
    const {type, payBeforeDueDate, scheduleDay, expiryDate, amount: limit} = schedule || {};
    const notification = formatNotification(intl)(user);
    const currency = getCurrencyCode(user.selectedCountry)(merchants);
    const currencyDisplay = getCurrencyDisplay(user.selectedCountry)(merchants);

    useEffect(() => {
      let closed = false;

      const hostname = sepaMandate?.sepaSignURL || window.location.href;
      const today = sepaMandate?.sepaSignDate || moment().format();

      if (!sepaMandate?.sepaSignIP && onSepaMandateLoad) {
        getClientIP()
          .then(ip => {
            if (!closed) {
              onSepaMandateLoad({sepaSignURL: hostname, sepaSignDate: today, sepaSignIP: ip});
            }
          })
        .catch(onError);
      }

     return () => closed = true;
    }, []);

    const paymentConfirmation = (
        <>
            <br />
            <FormattedMessage id='sepa-mandate.sec.creditor.confirmation-number' />
            {`: ${referenceId}`}
        </>
    )

    return (
        <>
            <MDBCol size='12'>
                {!!schedule && (
                    <h3>
                        <FormattedMessage id='sepa-mandate.card-header.ap.subtitle' />
                    </h3>
                )}
                {!!payment && (
                    <h3>
                        <FormattedMessage id='sepa-mandate.card-header.otp.subtitle' />
                    </h3>
                )}
            </MDBCol>

            <MDBCol size='12'>
                <DisplayOnlyField
                    wrapperMode
                    label={intl.formatMessage({
                        id: 'sepa-mandate.sec.creditor'
                    })}
                    value={
                        <>
                            <FormattedMessage id='sepa-mandate.sec.creditor.ups-address' />

                            <br />
                            <FormattedMessage id='sepa-mandate.sec.creditor.proc-address' />

                            <br />
                            <FormattedMessage id='sepa-mandate.sec.creditor.id' />

                            <br />
                            <FormattedMessage id='sepa-mandate.sec.creditor.name' />

                            {referenceId && paymentConfirmation}
                        </>
                    }
                />
            </MDBCol>

            <MDBCol size='12'>
                <DisplayOnlyField
                    wrapperMode
                    label={intl.formatMessage({
                        id: 'sepa-mandate.sec.debtor'
                    })}
                    value={
                        <>
                            <FormattedMessage id='sepa-mandate.sec.debtor.name' />
                            {`: ${maskName(paymentMethod.cardHolderName)}`}

                            {!!paymentMethod.bic && (
                                <>
                                    <br />
                                    <FormattedMessage id='sepa-mandate.sec.debtor.bic' />
                                    {`: ${maskString(paymentMethod.bic)}`}
                                </>
                            )}

                            <br />
                            <FormattedMessage id='sepa-mandate.sec.debtor.iban' />
                            {`: ${maskString(paymentMethod.accountNumber)}`}

                            {!!address && (
                                <>
                                    <br />
                                    <FormattedMessage id='sepa-mandate.sec.debtor.address' />
                                    {`: ${cleanString(formatAddress(intl)(address))}`}
                                </>
                            )}
                        </>
                    }
                />
            </MDBCol>

            {!!schedule && (
                <MDBCol size='12'>
                    <DisplayOnlyField
                        wrapperMode
                        label={intl.formatMessage({
                            id: 'sepa-mandate.sec.schedule'
                        })}
                        value={
                            <>
                                <FormattedMessage
                                    id='sepa-mandate.sec.schedule.freq'
                                    values={{
                                        type,
                                        payBeforeDueDate,
                                        scheduleDay,
                                        expiryDate: formatDate(expiryDate)
                                    }}
                                />

                                {!!notification && (
                                    <>
                                        <br />
                                        {notification}
                                    </>
                                )}

                                {!R.isNil(limit) && (
                                    <>
                                        <br />
                                        <FormattedMessage
                                            id='sepa-mandate.sec.schedule.limit'
                                            values={{
                                                limit: formatCurrency(
                                                    intl,
                                                    limit,
                                                    currency,
                                                    currencyDisplay
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        }
                    />
                </MDBCol>
            )}

            <MDBCol size='12'>
                <DisplayOnlyField
                    wrapperMode
                    label={intl.formatMessage({id: 'sepa-mandate.sec.auth'})}
                    value={
                        <>
                            <FormattedMessage id='sepa-mandate.sec.auth.url' />
                            {`: ${sepaMandate?.sepaSignURL}`}

                            <br />
                            <FormattedMessage id='sepa-mandate.sec.auth.date' />
                            <FormattedDate 
                              value={sepaMandate?.sepaSignDate}
                              year="numeric"
                              month="short"
                              day="2-digit"
                              hour="numeric"
                              minute="numeric"
                              second="numeric"
                            />

                            {!!sepaMandate?.sepaSignIP && (
                                <>
                                    <br />
                                    <FormattedMessage id='sepa-mandate.sec.auth.ip' />
                                    {`: ${sepaMandate?.sepaSignIP}`}
                                </>
                            )}
                        </>
                    }
                />
            </MDBCol>

            <MDBCol size='12'>
                <p>
                    <FormattedMessage id='sepa-mandate.sec.auth.terms' />
                </p>
            </MDBCol>
        </>
    );
}
