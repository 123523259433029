import moment from "moment";

export const constructDateFilter = (dateFilter) => {
    const {calendarOptionType, startDate, endDate, dateType} = dateFilter;
    let filterStartDate = null;
    let filterEndDate = null;

    if (calendarOptionType === 'AVAILABLE') {
        return {[dateType]:undefined};
    } else if (calendarOptionType === 'TODAY') {
        filterStartDate = moment();
        filterEndDate = moment();
    } else if (calendarOptionType.startsWith('LAST_')) {
        filterStartDate = moment().add(-1 * calendarOptionType.split('_')[1], calendarOptionType.split('_')[2]);
        filterEndDate = moment();
    } else if (calendarOptionType.startsWith('NEXT_')) {
        filterStartDate = moment();
        filterEndDate = moment().add(calendarOptionType.split('_')[1], calendarOptionType.split('_')[2]);
    } else if (calendarOptionType === 'custom') {
        filterStartDate = moment(startDate);
        filterEndDate = moment(endDate);
    }
    return {
        [dateType]: {
            startDate: filterStartDate.startOf('day').toISOString(),
            endDate: filterEndDate.endOf('day').toISOString()
        }
    };
}