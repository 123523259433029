/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/2/19 10:35 AM
 * Created by kxian
 *
 */


import {fetchWrapper, callApiGet, getFullUrl} from "../utils/utils";

const { REACT_APP_API_URL_PREFIX, REACT_APP_SIMULATE_API} = process.env;

export function getAllMerchants(apiUrlPrefix, simulateApi) {

    if (simulateApi) {
        return {
            ok: true,
            parsedBody: [{
                id: '5ee161845723752da007ec6e',
                "countryCode": "DE",
                "paymentTypes": [{
                    "id": 516,
                    "name": "EBS",
                    "description": "Small Package",
                    "acceptsPartialPay": true,
                    "accountLabel": "Account Number",
                    "accountTokens": {"token1": {"label": "ZIP Code:", "type": "ZIP"}}
                }]
            }]
        };
    }

    const url = `${apiUrlPrefix}/getAllMerchants`,
        method = 'GET',
        headers = {
        };
    return fetchWrapper(url, method, headers);
}

export function getClientToken(merchantId, payMethodType) {
    if (REACT_APP_SIMULATE_API === 'true') {
        return {
            clientToken: `sample token`
        };
    }

    const url = `${REACT_APP_API_URL_PREFIX}/getClientToken/${merchantId}?payMethodType=${payMethodType}`;
    return fetchWrapper(url, 'GET', {});
}
