import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import classNames from "classnames";
import {MDBBtn, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import {FormattedMessage} from "react-intl";
import {TYPE_ACCOUNT_TYPE_ACCOUNT, TYPE_ACCOUNT_TYPE_PLAN} from "../../../constants/paymentus-resources";
import { stripAccountNumber } from "../../../utils/ups-utils";

class AddAccountConfirmation extends Component {
    render() {
        const { toggle, account, invoice} = this.props;
        const { accountNumber: accNum, businessUnit } = account;
        const accountNumber = invoice?.planNumber ? stripAccountNumber(invoice.planNumber) : stripAccountNumber(accNum, businessUnit);
        return (
            <MDBContainer fluid className="">
                <section className={""}>
                    <p role="alert">{account?.accountType === TYPE_ACCOUNT_TYPE_PLAN
                            ? <FormattedMessage id="ups.add-plan.confirm.line1" values={{accountNumber}}/>
                            :<FormattedMessage id="ups.account.confirm.line1" values={{accountNumber}}/>
                        }</p>
                </section>
                <section
                    className={classNames({"ups-section ups-enroll-subsection ups-btn-section": true})}>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={classNames({"mt-3 mt-sm-0": true, "text-center": true})}>
                            <MDBBtn color="primary" id="enroll-confirmation-modal-btn-close" onClick={this.props.handleClose}>
                                <FormattedMessage id={"ups.btn.account.close.label"}/>
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </section>
            </MDBContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        account: state.account.account,
        invoice: state.account.invoice,
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAccountConfirmation));