import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBPopoverBody, MDBPopover } from 'mdbreact';

export const MDBPopoverWrapper = ({ id, buttonLabel, content, popoverType, ...rest }) => {
    const [visible, setVisible] = useState(false);

    if(!popoverType){
        popoverType = POPOVER_TYPE.HELP;
    }

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                const el = document.getElementsByClassName("popover")[0];
                if (el && el.nodeType && el.nodeType === 1) {
                    el.classList.add("show");
                }
            }, 0);
        }
    }, [visible])

    return <div className="popover-wrapper" aria-live='polite'>
        <div onFocus={() => setVisible(true)} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} onBlur={() => setVisible(false)}>
            <MDBPopover clickable placement="auto" id={`popover_${id}`} popover isVisible={visible} >
                <MDBBtn aria-label={buttonLabel} color={"label-link"}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <title>{buttonLabel}</title>
                        {
                            {
                                'HELP': <path d="M15.875 9c-1.375 0-2.438 0.313-3.063 1.063-0.5 0.625-0.75 1.375-0.75 2.563v0.188c0 0.438 0.438 0.75 1.063 0.75 0.438 0 0.875 0 0.875-0.625v-0.125c0-1.25 0.5-1.688 1.688-1.688 1.25 0 1.688 0.438 1.688 1.688 0 1.375-1.25 2.125-2.125 2.875-0.75 0.438-1.25 0.938-1.25 1.25v2.25c0 0.5 0.5 0.75 1.063 0.75 0.5 0 0.938-0.25 1.063-0.563v-2l0.188-0.125 0.313-0.188c0.75-0.625 2.75-2.125 2.75-4.125 0.313-2.563-1.063-3.938-3.5-3.938zM16.313 23.188c0 0.563-0.5 1.063-1.063 1.063-0.625 0-1.063-0.5-1.063-1.063 0-0.625 0.438-1.063 1.063-1.063 0.563 0 1.063 0.438 1.063 1.063zM15.875 0c-8.688 0-15.875 7.188-15.875 15.875s7.188 15.813 15.875 15.813c8.688 0 15.813-7.125 15.813-15.813s-7.125-15.875-15.813-15.875zM15.875 28.813c-7.188 0-13-5.813-13-12.938 0-7.188 5.813-13 13-13 7.125 0 12.938 5.813 12.938 13 0 7.125-5.813 12.938-12.938 12.938z"></path>,
                                'INFO': <path d="M15.875 0c-8.688 0-15.875 7.188-15.875 15.875s7.188 15.813 15.875 15.813c8.688 0 15.813-7.125 15.813-15.813s-7.125-15.875-15.813-15.875zM28.813 15.875c0 7.125-5.813 12.938-12.938 12.938-7.188 0-13-5.813-13-12.938 0-7.188 5.813-13 13-13 7.125 0 12.938 5.813 12.938 13zM18.125 12.063l-4.438 0.563-0.438 2h1.5l-2.25 7.313c-0.188 0.625-0.188 1.063 0.125 1.375s0.813 0.438 1.375 0.438c1.063 0 2.313-0.313 3.063-0.563h0.188l0.563-1.688h-1.813l2.75-9zM17.5 6.25c-0.875 0-1.813 0.75-1.813 1.813 0 0.938 0.75 1.563 1.688 1.563s1.813-0.813 1.813-1.875c0.188-0.75-0.625-1.5-1.688-1.5z"></path>
                              }[popoverType]
                        }
                    </svg>
                </MDBBtn>
                <div>
                    <MDBPopoverBody>
                        {content}
                    </MDBPopoverBody>
                </div>
            </MDBPopover>
        </div>
    </div>
}

export const POPOVER_TYPE = {
    INFO: "INFO",
    HELP: "HELP"
};
