import './css/jquery.dataTables.min.css';
import './css/fixedColumns.dataTables.min.css';

import React, {useEffect, useReducer, useRef} from 'react';
import { MDBTable, MDBTableBody, MDBTableHead
       , MDBRow }                                   from 'mdbreact';
import PropTypes                                    from 'prop-types';
import classnames                                   from 'classnames';
import { FormattedMessage, useIntl }                from 'react-intl';
import * as R                                       from 'ramda';
import tableReducer                                 from "./DataTableV3-reducer";
import {SORTED_ASC, SORTED_DSC} from "./DataTableV3-utils";
import {onSpaceBar, onEnterPress} from "../../utils/utils";
import {SortAnnounce} from "./DataTableV3Components/SortAnnounce";

const defaultItemsPerPage = [10, 20, 50, 100];

// State definition
const tableInitialState =
  { currentPage: 1
  , itemsPerPage: 0
  , pagedItems: [] // in memory paging
  , totalItems: 0
  , showingFrom: 0
  , showingTo: 0
  , itemsPerPageOptions: []
  , sortedField: 'baseSort'
  , sortedDirection: SORTED_ASC
  , expandedRows: {}
  // User defined column labels, fields, and optional display render function
  , columns: []
  };

const initState = ({defaultSorting, columns, defaultSortDirection})=>(initialState)=>({
    ...initialState,
    ...(defaultSorting && {
        sortedField: (typeof defaultSorting === 'function') ? 'sortFunc' : defaultSorting,
        sortFunc: (typeof defaultSorting === 'function') ? defaultSorting : columns?.find((c)=>(c.field === defaultSorting))?.sortFunc
    }),
    ...(defaultSortDirection && {sortedDirection: defaultSortDirection})
})

// Render Functions.
const getSortedClassName = fieldName => R.cond
    ( [ [ ({sortedField, sortedDirection}) =>
            sortedField === fieldName && sortedDirection === SORTED_ASC
        , R.always('sorting_asc')
        ]
      , [ ({sortedField, sortedDirection}) =>
            sortedField === fieldName && sortedDirection === SORTED_DSC
        , R.always('sorting_desc')
        ]
      , [ R.T, R.always ('sorting') ]
      ]
    );

const getAriaSort = (direction) => {
    switch(direction){
        case SORTED_ASC: return 'ascending'
        case SORTED_DSC: return 'descending'
    }
}

const RowExpander = ({expandRow,expandedRows,uid}) => {
    const intl = useIntl();
    const isExpanded = expandedRows[uid];
    const label = intl.formatMessage({id: isExpanded ? 'ups.table.row.collapse' : 'ups.table.row.expand' });

    return(
    <td className={'dtr-rowExpander'} onKeyUp={onEnterPress(() => expandRow(uid))} onClick={()=>expandRow(uid)}>
        <i tabIndex="0" aria-label={label} className={classnames('dtr-chevron fa fa-chevron-right', {'down': expandedRows[uid]})} />
    </td>);
}

const ExpandedRow = ({rowData, columns, collapsedColumns, intl}) => (
    <tr className='child'><td className="child" colSpan={columns.length + 1 - (collapsedColumns.size)}><ul className='dtr-details'>
        { R.map
        ( ({label, field, display}) => (collapsedColumns?.has(field)) ?
            <li className="text-right" key={field}>
                <span className="dtr-title">{label}</span>
                <span className="dtr-data">{formattedRow(display, field, rowData, intl)}</span>
            </li> : null
        ) (columns)
        }
    </ul></td></tr>
)

const Row = ({rowData, columns, trClassName, rowRender, collapsedColumns, responsive, expandRow, idField, rowHeaderField, expandedRows, intl}) => {
    const rowContents = <>
        {responsive && collapsedColumns && (
            <RowExpander expandRow={expandRow} expandedRows={expandedRows} uid={rowData[idField]}/>
        )}
        { R.map
        ( ({field, display, tdClassName = ''}) => (!responsive || !collapsedColumns?.has(field)) ?
            (rowHeaderField !== field ? <td key={'row-td-'+field} className={tdClassName}>
                {formattedRow(display, field, rowData, intl)}
                </td>:<th scope='row' key={field} className={tdClassName}>
                {formattedRow(display, field, rowData, intl)}
            </th>) : null
        ) (columns)
        }
    </>
    const expandedRow = <>
        {responsive && collapsedColumns && expandedRows[rowData[idField]] && (
            <ExpandedRow rowData={rowData} columns={columns} collapsedColumns={collapsedColumns} intl={intl}/>
        )}
    </>
    const row = <><tr className={trClassName?.(rowData)}>
        {rowContents}
    </tr>
        {expandedRow}
    </>
    return rowRender ? rowRender(rowData, columns, row, rowContents, expandedRow, trClassName) : row
}

const formattedRow = (display, field, rowData, intl) => (
    (display ? display(rowData) : R.prop(field) (rowData)) ?? <span className="sr-only">{intl.formatMessage({id: "ups.aria-label.blank"})}</span>
);

const renderRows = R.compose
    ( (props) => R.addIndex(R.map)
        ((rowData, i) =>
            <Row
                key={rowData[props.idField] ?? i}
                rowData={rowData}
                {...props}
            />
        ) (props.showingItems)
    , ({columns, currentPage, pagedItems, collapsedColumns, expandedRows}, rowRender, trClassName, responsive, idField, rowHeaderField, expandRow, intl) =>
        ({ columns
         , showingItems: (pagedItems[R.dec(currentPage)] ?? [])
         , responsive
         , collapsedColumns
         , expandedRows
         , trClassName
         , rowRender
         , idField
         , rowHeaderField
         , expandRow
         , intl
         })
    );

const TableHeader = ({sortTable, columns, sortedField, sortedDirection, tableSortable, responsive, collapsedColumns, intl}) =>
  ( <MDBTableHead>
      <tr className='thead-light'>
        {responsive && collapsedColumns && <th>&nbsp;</th>}
        { columns?.map(({label, field, sortable = true, sortFunc, thClassName = ''}, index) =>
            (!responsive || !collapsedColumns?.has(field)) && (
                <th key={field} scope='col' className={thClassName} aria-sort={sortedField === field ? getAriaSort(sortedDirection) : ''}>
                {((sortable && tableSortable) ?
                        <button className={classnames(getSortedClassName (field) ({sortedField, sortedDirection}) )}
                            onClick={() => {sortTable(field, sortFunc, typeof label === 'function' ? label() : label)}}
                            key={field}>
                            {typeof label === 'function' ? label() : label}
                        </button>:
                    <>{typeof label === 'function' ? label() : label}</>
                    )}
                </th>
            )
        )}
      </tr>
    </MDBTableHead>
  );  

const getPageNumberClassName = R.ifElse
    ( ({currentPage, n}) => currentPage === n
    , R.always('paginate_button current')
    , R.always('paginate_button')
    );

const inclRange = (x, y) => R.range(x, R.inc(y));

// 1 <= i <= n, prefer m odd
// (numPages:Number, maxSize:Number?) -> currentPage:Number -> [Number]
const collapsePageRange = (n, m = 7) => R.cond([
    [
        // [1, n]
        R.always(n <= m),
        R.always(inclRange(1, n))
    ],
    [
        // [1, m - 2] (...) n
        R.gt(m - 2),
        R.always(R.concat(inclRange(1, m - 2), [0, n]))
    ],
    [
        // 1 (...) [n - (m - 2 - 1), n]
        R.lt(n - m + 3),
        R.always(R.concat([1, 0], inclRange(n - m + 3, n)))
    ],
    [
        // 1 (...) [i - ⌊(m - 4 - 1)/2⌋, i + ⌈(m - 4 - 1)/2⌉] (...) n
        R.T,
        i => R.compose(
            R.concat([1, 0]),
            R.concat(inclRange(
                i - Math.floor((m - 5) / 2),
                i + Math.ceil((m - 5) / 2)
            ))
        ) ([0, n])
    ]
]);

const renderPageNumbers = ({currentPage, pagedItems}, setPage, intl) => R.addIndex(R.map)
    (
        (n, i) => n === 0
            ? <span key={`ellipsis-${i}`} className='ellipsis'>
                {'…'}
            </span>
            : <a role='link' key={n} tabIndex={0}
                className={getPageNumberClassName({currentPage, n})}
                aria-current={currentPage === n ? 'page' : ''}
                aria-label={intl.formatMessage({id:'datatable.pagination.number'}, {page:n})}
                onClick={() => setPage(n)}
                onKeyPress={onSpaceBar(() => setPage(n))}
            >
                {n}
            </a>
    ) (collapsePageRange(pagedItems.length)(currentPage));

const getPreviousButtonClassName = R.ifElse
    ( R.propEq('currentPage', 1)
    , R.always('paginate_button previous disabled')
    , R.always('paginate_button previous')
    );

const getNextButtonClassName = R.ifElse
    ( ({currentPage, pagedItems}) => currentPage === pagedItems.length
    , R.always('paginate_button next disabled')
    , R.always('paginate_button next')
    );

const PaginationFooter = ({tableState, itemsPerPage, setItemsPerPage, setPage, setNextPage, setBackPage, filterActive, totalRows}) => {
    const intl = useIntl()
    return (
      <MDBRow className='dt-footer-row'>
        <div className='col-lg-6 col-sm-12 dt-length'>
          {itemsPerPage.length > 1 &&
              <div className='dataTables_length'>
                <label>
                  <FormattedMessage id="datatable.showMenu"/>
                  <select onChange={({ target: { value } }) => setItemsPerPage(value)} aria-label={intl.formatMessage({id:'datatable.itemsperpage'})}>
                    {R.map(({ text, selected, value }) => (
                      <option selected={selected} value={value} key={value}>
                        {text}
                      </option>
                    ))(tableState.itemsPerPageOptions)}
                  </select>
                </label>
              </div>
          }
                <div className='dataTables_info' aria-live='polite'>
              <FormattedMessage
                  id='datatable.sRange'
                  values={{ start: tableState.showingFrom
                      , end: tableState.showingTo
                      , total: tableState.totalItems
                  }}
              />
              {(filterActive && tableState.totalItems < totalRows) &&
                  <span className='dataTables_filterInfo'>{' '}
                      <FormattedMessage
                        id='datatable.sRangeFiltered'
                        values={{total: totalRows}}
                      />
                  </span>
              }
          </div>
        </div>
          {tableState.pagedItems.length > 1 &&
            <div className='col-lg-6 col-sm-12 dt-pagination-col text-right'>
              <div className='dataTables_paginate paging_simple_numbers' aria-label={intl.formatMessage({id:"datatable.pagination"})}>
                <a  role='link' className={getPreviousButtonClassName(tableState)}
                   onKeyPress={onSpaceBar(setBackPage)}
                   onClick={setBackPage}
                   aria-label={intl.formatMessage({id:'datatable.pagination.previous'})}
                   tabIndex={0}
                >
                  <FormattedMessage id='datatable.sPrevious' />
                </a>
                <span>{renderPageNumbers (tableState, setPage, intl)}</span>
                <a  role='link' className={getNextButtonClassName(tableState)}
                   onClick={setNextPage}
                   onKeyPress={onSpaceBar(setNextPage)}
                   aria-label={intl.formatMessage({id:'datatable.pagination.next'})}
                   tabIndex={0}
                >
                  <FormattedMessage id='datatable.sNext' />
                </a>
              </div>
            </div>
          }
      </MDBRow>
)};

const wrapTable = (hasWrapper, table) => {
    return (hasWrapper ? table : <div className='dataTables_wrapper datatable-v3'>{table}</div>)
}

const adaptTable = (useThrottle, responsiveData, action) => {
    if(useThrottle){
        if(!responsiveData.current.throttled) {
            responsiveData.current.throttled = true
            action()
            setTimeout(()=>(responsiveData.current.throttled = false), 200)
        } else {
            clearTimeout(responsiveData.current.throttleEnd)
            responsiveData.current.throttleEnd = setTimeout(()=>action(), 200)
        }
    } else {
        action()
    }
}

const handleResize = ({current}, condenseTable, expandTable, useThrottle, responsiveData, expandLoop) => (e) => {
    const {offsetWidth, scrollWidth} = current;
    if(!R.isNil(offsetWidth) && !R.isNil(scrollWidth) && (offsetWidth < scrollWidth)){
        adaptTable(useThrottle, responsiveData, condenseTable)
    } else if(((responsiveData.current.prevCollapsedColumnCount > 0) && (responsiveData.current.previousWidth < window.innerWidth)) || expandLoop){
        adaptTable(useThrottle, responsiveData, expandTable)
    }
    responsiveData.current.previousWidth = window.innerWidth
}

function DataTable ({
  data, columns, hasWrapper, filterActive, clearFilter, totalRows,caption,
  itemsPerPage = defaultItemsPerPage, defaultSorting, sortable = true,
  enableShowAll = false, hidePaging, rowRender, responsive = false,
  className = '', trClassName = R.always(''), idField = 'id', rowHeaderField, storeSorting, defaultSortDirection
}) {
  const [tableState, dispatch] = useReducer(tableReducer, tableInitialState, initState({defaultSorting, defaultSortDirection, columns}));
  const intl = useIntl();

  const sortTable = (sortField, sortFunc, sortedLabel) => {
    dispatch({type: 'sort_data', sortField, sortFunc, sortedLabel});
  }
  const condenseTable = () => dispatch({type: 'collapse_columns', columns})
  const expandTable = () => dispatch({type: 'expand_columns', columns})
  const expandRow = (id) => dispatch({type: 'toggle_responsive_row', id})
  
  const tableRef = useRef(null)
  const responsiveData = useRef({prevCollapsedColumnCount: tableState.collapsedColumnCount, prevWidth: window.innerWidth, throttled: false, throttleEnd: null})
  useEffect (() => {
    dispatch({type: 'set_data', data, columns, itemsPerPage, enableShowAll});
  }, [data, columns, itemsPerPage, enableShowAll, intl.locale]);
  useEffect(()=>{
      if(responsive){
          responsiveData.current.previousWidth = window.innerWidth
          const resize = handleResize(tableRef, condenseTable, expandTable, true, responsiveData)
          window.addEventListener('resize', resize)
          return ()=>window.removeEventListener('resize', resize)
      }
  },[])
  useEffect(()=>{
      if(responsive){
          const expandLoop = responsiveData.current.prevCollapsedColumnCount > tableState.collapsedColumnCount
          responsiveData.current.prevCollapsedColumnCount = tableState.collapsedColumnCount
          handleResize(tableRef, condenseTable, expandTable, false, responsiveData, expandLoop)()
      }
  }, [tableState.collapsedColumnCount, tableState.currentPage])
  useEffect(()=>{
      if(sortable && tableState.sortedField !== 'baseSort' && storeSorting) {
          const {sortedField, sortedDirection, sortFunc} = tableState
          storeSorting({sortedField, sortedDirection, sortFunc})
      }
  }, [tableState.sortedField, tableState.sortedDirection])

  const setItemsPerPage = value => {
    if (tableState.itemsPerPage !== value) {
      dispatch({type: 'set_items_per_page', itemsPerPage: +value});
    }
  };

  // User interactions.
  const setPage = pageNumber => dispatch({type: 'set_page', pageNumber})
  const setNextPage = () => dispatch({type: 'next_page'});
  const setBackPage = () => dispatch({type: 'back_page'});

  return wrapTable(hasWrapper,(
        <>
            <SortAnnounce sortedLabel={tableState.sortedLabel} sortedDirection={tableState.sortedDirection} />
            <div ref={tableRef}>
        <MDBTable
            wrapperClassName='recent-payments-container'
            className={classnames('dataTable table-bordered-simple table-hover', {"responsive-table": responsive}, className)}>
          {caption && <caption className={"sr-only"}>{intl.formatMessage({id:caption})}</caption>}
          <TableHeader
            columns={tableState.columns}
            sortTable={sortTable}
            sortedField={tableState.sortedField}
            sortedDirection={tableState.sortedDirection}
            tableSortable={sortable}
            responsive={responsive}
            collapsedColumns={tableState.collapsedColumns}
            intl = {intl}
          />
          <MDBTableBody>
              {(data.length === 0)
                  ? renderEmptyTable(filterActive, clearFilter, trClassName, intl)
                  : renderRows (tableState, rowRender, trClassName, responsive, idField, rowHeaderField, expandRow, intl)
              }
          </MDBTableBody>
        </MDBTable>
            </div>
        {(data.length !== 0) && !hidePaging &&
            <PaginationFooter
                tableState={tableState}
                filterActive={filterActive}
                totalRows={totalRows}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                setPage={setPage}
                setNextPage={setNextPage}
                setBackPage={setBackPage}
            />
        }

      </>
    )
  );
}

const renderEmptyTable = (filterActive, clearFilter, trClassName, intl)=>{
    return <tr className={classnames("odd", trClassName?.({}))}>
        <td valign="top" colSpan='100' className="dataTables_empty" role={'alert'}>
            {(filterActive && clearFilter)
                ? <FormattedMessage id='datatable.sZeroRecordsClearFilter' values={{link:
                    <a  role='button' aria-label={intl.formatMessage({id: 'table.clear-filters.aria-label' })} id='clearFilterLink' tabIndex="0" onKeyPress={onEnterPress(() => clearFilter())} onClick={clearFilter} >
                        <FormattedMessage id="datatable.sZeroRecordsClearFilter.link"/>
                    </a>
                }}/>
                : <FormattedMessage id='datatable.sEmptyTable'/>
            }
        </td>
    </tr>
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // data the data will render
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
          PropTypes.element
      ]).isRequired, // column header label
      field: PropTypes.string.isRequired, // row field name (try to match raw data property name unless this column combines multiple properties)
      sortable: PropTypes.bool, // whether this specific column is sortable (default: true)
      thClassName: PropTypes.string, // optional class style for th
      tdClassName: PropTypes.string, // optional class style for td
      display: PropTypes.func, // optional render function for row otherwise raw property value
      sortFunc: PropTypes.func, // optional function that is used to sort the column instead of the raw property value
      responsivePriority: PropTypes.number // number that decides how soon a column will get collapsed in responsive view (lower number will keep it on the page longer)
      //more column properties are described in the filterwrapper code
    })
  ),
  rowRender: PropTypes.func, // option to override the standard row render function with a custom one
  defaultSorting: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
  ]), //which column field name or sorting function to sort with by default
  defaultSortDirection: PropTypes.number, //which direction to sort the default sorting
  sortable: PropTypes.bool, //whether the table has sortable columns by default (default: true)
  itemsPerPage: PropTypes.arrayOf(PropTypes.number), // selectable page limits
  enableShowAll: PropTypes.bool, // allow Show All as page limit
  hidePaging: PropTypes.bool, // force the table footer to be hidden
  className: PropTypes.string, // optional class style for table
  trClassName: PropTypes.func, // optional class style for tr, DataRow -> String
  hasWrapper: PropTypes.bool, // optional used if the table is being wrapped by more features like filtering
  filterActive: PropTypes.bool, // optional indicator that a filter is active on the table
  clearFilter: PropTypes.func, // optional function that clears any filter
  totalRows: PropTypes.number, // optional total amount of rows without any filter applied
  responsive: PropTypes.bool, // whether the table should be respond to window width changes (default: false)
  idField: PropTypes.string, // only needed if responsive view is enabled, what property from which to obtain datapoint unique ids from the input data list (default: 'id')
  rowHeaderField: PropTypes.string, // optional row header field which will act as primary cell and have header cell assigned to it as a part of ADA compliance
  caption: PropTypes.string // optional string that is used for ADA compliance to label the table
};

export default DataTable;