import React, {useState, useEffect}                   from 'react';
import {MDBRow, MDBCol}                                       from "mdbreact";
import ReactSelectAllGrouped                                  from '../../../../ReactSelectAllGrouped';
import {displayScheduleFrequency, displayWalletSelection} from '../../../../../utils/payment-utils';
import {uniquePropValsGetter} from "../../../../../utils/utils";
import {FormattedMessage, useIntl} from "react-intl"

const allOption = { value: "*", label: <FormattedMessage id={"filter.multi.option.dropdown.all.selector"}/>, msgId: "filter.multi.option.dropdown.all.selector"}

const multiSelectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    isMulti:true,
    closeMenuOnSelect:false,
    hideSelectedOptions:false,
    allowSelectAll:true,
    isSearchable:false,
    backspaceRemovesValue:false,
    intlLabel: true,
    placeholder:<FormattedMessage id={"react-select.select-your-filters"}/>
}

const frequencyToKey = ({type, scheduleDay, payBeforeDueDate})=>{
    return (type === 'LIABILITY') ? `${type}` : `${type}-${scheduleDay}-${payBeforeDueDate}`
}

export default function AutopayFilterCriteria({autopayList, filter, setFilter, defaultFilter, setDefaultFilter}) {
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const intl = useIntl()
    useEffect(() => {
        let paymentMethods = uniquePropValsGetter(
                autopayList,
            ({paymentMethod: pm})=>pm,
            ({paymentMethod: {_id}})=>_id
            ).map((pm)=>{
            return {value: pm._id, label: displayWalletSelection(intl)(pm)}
        })
        paymentMethods.unshift(allOption)
        setPaymentMethodOptions([{
            msgId: "react-select.select-your-filters",
            options: paymentMethods
        }])
        let frequencies = uniquePropValsGetter(
            autopayList,
            ({type, scheduleDay, payBeforeDueDate})=>({type, scheduleDay, payBeforeDueDate}),
                frequencyToKey
            ).map((freq)=>{
            return {value: JSON.stringify(freq), label: displayScheduleFrequency(intl)(freq)}
        })
        frequencies.unshift(allOption)
        setFrequencyOptions([{
            msgId: "react-select.select-your-filters",
            options: frequencies
        }])
        setDefaultFilter({paymentMethod: paymentMethods, frequency: frequencies})
    }, []);
    return (
        <React.Fragment>
            <MDBRow>
                <MDBCol size="12">
                    <ReactSelectAllGrouped
                        label={intl.formatMessage({id:"modal.schedule-table-filter.pm.label"})}
                        inputId={"paymentMethodFilter"}
                        options={paymentMethodOptions}
                        onChange={(selected)=>setFilter('paymentMethod', selected)}
                        value={filter?.paymentMethod ?? defaultFilter?.paymentMethod}
                        {...multiSelectProps}
                    />
                </MDBCol>
                <MDBCol size={"12"} key={"field-auto-pay-frequency"}>
                    <ReactSelectAllGrouped
                        label={intl.formatMessage({id:"modal.schedule-table-filter.frequency.label"})}
                        inputId={"frequency"}
                        options={frequencyOptions}
                        onChange={(selected)=>setFilter('frequency', selected)}
                        value={filter?.frequency ?? defaultFilter?.frequency}
                        {...multiSelectProps}
                    />
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    );
}