import { fetchWrapper } from '../utils/utils';

export async function login(data, apiUrlPrefix, simulateApi, apiToken) {

    const url = `${apiUrlPrefix}/auth/login`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json',
            'x-api-token-sub': apiToken,
        },

        body = data;

    return fetchWrapper(url, method, headers, body);
}

export async function logout(data, apiUrlPrefix, simulateApi) {

    if (simulateApi) {
        // simulate new user response
        return {
            ok: true,
            parsedBody: { message : 'User logged out successfully' }
        };
    }

    const url = `${apiUrlPrefix}/auth/logout`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json',
            'x-session-token': 'Test',
        },

        body = data;

    return fetchWrapper(url, method, headers, body);
}

export async function loginCallback(data, apiUrlPrefix, simulateApi, apiToken) {

    if (simulateApi) {
        // simulate new user response
        return {
            ok: true,
            parsedBody: { newUser: true }
        };
    }

    let url = `${apiUrlPrefix}/auth/loginCallback`;
    if (data.user && data.user.bd) {
        url = `${apiUrlPrefix}/auth/bdLogin`;
    }
    const method = 'POST',
        headers = {
            'Content-Type': 'application/json',
            'x-api-token-sub': apiToken
        },

        body = data;

    return fetchWrapper(url, method, headers, body);
}

export async function extendSession(data, apiUrlPrefix, simulateApi) {

    if (simulateApi) {
        // simulate new user response
        return {
            ok: true,
            parsedBody: {
                message: 'User session extended successfully'
            }
        };
    }

    const url = `${apiUrlPrefix}/auth/extendSession`,
        method = 'GET',
        headers = {
            'Content-Type': 'application/json',
        },

        body = data;

    return fetchWrapper(url, method, headers, body);
}

export async function csrLogin(data, apiUrlPrefix) {
    const url = `${apiUrlPrefix}/auth/csrLogin?JWT=` + data,
        method = 'POST';

    return fetchWrapper(url, method);
}

export async function csrCallback(data, apiUrlPrefix) {
    const url = `${apiUrlPrefix}/auth/csrCallback?code=` + data,
        method = 'POST';

    return fetchWrapper(url, method);
}



