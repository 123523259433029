export const countryData = [
    {
        code: "US", name: "United States",
        languages: [
            {code: "en-US", name: "English (United States)", title: "United States - English", id: "en-US"}
        ],
        currencyCode: "USD"
    },
    {
        code: "AT", name: "Austria",
        languages: [
            {code: "de-AT", name: "German (Austria)", title: "Austria - German", id: "de-AT"},
            {code: "en-GB", name: "English (Great Britain)", title: "Austria - English", id: "en-AT"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "AU", name: "Australia",
        languages: [
            {code: "en-GB", name: "English (Great Britain)", title: "Australia - English", id: "en-AU"}
        ],
        currencyCode: "AUD"
    },
    {
        code: "BE", name: "Belgium",
        languages: [
            {code: "nl-BE", name: "Dutch (Belgium)", title: "Belgium - Dutch", id: "nl-BE"},
            {code: "fr-BE", name: "French (Belgium)", title: "Belgium - French", id: "fr-BE"},
            {code: "en-GB", name: "English (Great Britain)", title: "Belgium - English", id: "en-BE"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "CA", name: "Canada",
        languages: [
            {code: "en-CA", name: "English (Canada)", title: "Canada - English", id: "en-CA"},
            {code: "fr-CA", name: "French (Canada)", title: "Canada - French", id: "fr-CA"}
        ],
        currencyCode: "CAD"
    },
    {
        code: "CH", name: "Switzerland",
        languages: [
            {code: "it-CH", name: "Italian (Switzerland)", title: "Switzerland - Italian", id: "it-CH"},
            {code: "de-CH", name: "German (Switzerland)", title: "Switzerland - German", id: "de-CH"},
            {code: "fr-CH", name: "French (Switzerland)", title: "Switzerland - French", id: "fr-CH"},
            {code: "en-GB", name: "English (Great Britain)", title: "Switzerland - English", id: "en-CH"}
        ],
        currencyCode: "CHF"
    },
    {
        code: "CN", name: "China",
        languages: [
            {code: "zh-CN", name: "Chinese (China)", title: "China - Chinese", id: "zh-CN"},
            {code: "en-GB", name: "English (Great Britain)", title: "China - English", id: "en-CN"}
        ],
        currencyCode: "CNY"
    },
    {
        code: "CZ", name: "Czech Republic",
        languages: [
            {code: "cs-CZ", name: "Czech (Czech Republic)", title: "Czech Republic - Czech", id: "cs-CZ"},
            {code: "en-GB", name: "English (Great Britain)", title: "Czech Republic - English", id: "en-CZ"}
        ],
        currencyCode: "CZK"
    },
    {
        code: "DE", name: "Germany",
        languages: [
            {code: "de-DE", name: "German (Germany)", title: "Germany - German", id: "de-DE"},
            {code: "en-GB", name: "English (Great Britain)", title: "Germany - English", id: "en-DE"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "DK", name: "Denmark",
        languages: [
            {code: "da-DK", name: "Danish (Denmark)", title: "Denmark - Danish", id: "da-DK"},
            {code: "en-GB", name: "English (Great Britain)", title: "Denmark - English", id: "en-DK"}
        ],
        currencyCode: "DKK"
    },
    {
        code: "ES", name: "Spain",
        languages: [
            {code: "es-ES", name: "Spanish (Spain)", title: "Spain - Spanish", id: "es-ES"},
            {code: "en-GB", name: "English (Great Britain)", title: "Spain - English", id: "en-ES"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "FI", name: "Finland",
        languages: [
            {code: "fi-FI", name: "Finnish (Finland)", title: "Finland - Finnish", id: "fi-FI"},
            {code: "en-GB", name: "English (Great Britain)", title: "Finland - English", id: "en-FI"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "FR", name: "France",
        languages: [
            {code: "fr-FR", name: "French (France)", title: "France - French", id: "fr-FR"},
            {code: "en-GB", name: "English (Great Britain)", title: "France - English", id: "en-FR"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "GB", name: "United Kingdom",
        languages: [
            {code: "en-GB", name: "English (Great Britain)", title: "United Kingdom - English", id: "en-GB"}
        ],
        currencyCode: "GBP"
    },
    {
        code: "HK", name: "Hong Kong SAR, China",
        languages: [
            {
                code: "zh-HK",
                name: "Chinese (Traditional Han, Hong Kong SAR, China)",
                title: "Hong Kong SAR, China - Chinese",
                id: "zh-HK"
            },
            {code: "en-HK", name: "English (Great Britain)", title: "Hong Kong SAR, China - English", id: "en-HK"}
        ],
        currencyCode: "HKD"
    },
    {
        code: "HU", name: "Hungary",
        languages: [
            {code: "hu-HU", name: "Hungarian (Hungary)", title: "Hungary - Hungarian", id: "hu-HU"},
            {code: "en-GB", name: "English (Great Britain)", title: "Hungary - English", id: "en-HU"}
        ],
        currencyCode: "HUF"
    },
    {
        code: "IE", name: "Ireland",
        languages: [
            {code: "en-GB", name: "English (Great Britain)", title: "Ireland - English", id: "en-IE"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "IT", name: "Italy",
        languages: [
            {code: "it-IT", name: "Italian (Italy)", title: "Italy - Italian", id: "it-IT"},
            {code: "en-GB", name: "English (Great Britain)", title: "Italy - English", id: "en-IT"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "LU", name: "Luxembourg",
        languages: [
            {code: "fr-LU", name: "French (Luxembourg)", title: "Luxembourg - French", id: "fr-LU"},
            {code: "de-LU", name: "German (Luxembourg)", title: "Luxembourg - German", id: "de-LU"},            
            {code: "en-GB", name: "English (Great Britain)", title: "Luxembourg - English", id: "en-LU"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "MX", name: "Mexico",
        languages: [
            {code: "es-MX", name: "Spanish (Mexico)", title: "Mexico - Spanish", id: "es-MX"},
            {code: "en-US", name: "English (United States)", title: "Mexico - English", id: "en-MX"}
        ],
        currencyCode: "MXN"
    },
    {
        code: "NL", name: "Netherlands",
        languages: [
            {code: "nl-NL", name: "Dutch (Netherlands)", title: "Netherlands - Dutch", id: "nl-NL"},
            {code: "en-GB", name: "English (Great Britain)", title: "Netherlands - English", id: "en-NL"}
        ],
        currencyCode: "EUR"
    },
    {
        code: "NO", name: "Norway",
        languages: [
            {code: "no-NO", name: "Norsk (Norway)", title: "Norway - Norwegian", id: "no-NO"},
            {code: "en-GB", name: "English (Great Britain)", title: "Norway - English", id: "en-NO"},
        ],
        currencyCode: "NOK"
    },
    {
        code: "PL", name: "Poland",
        languages: [
            {code: "pl-PL", name: "Polish (Poland)", title: "Poland - Polish", id: "pl-PL"},
            {code: "en-GB", name: "English (Great Britain)", title: "Poland - English", id: "en-PL"}
        ],
        currencyCode: "PLN"
    },
    {
        code: "SE", name: "Sweden",
        languages: [
            {code: "sv-SE", name: "Swedish (Sweden)", title: "Sweden - Swedish", id: "sv-SE"},
            {code: "en-GB", name: "English (Great Britain)", title: "Sweden - English", id: "en-SE"}
        ],
        currencyCode: "SEK"
    },
    {
        code: "SG", name: "Singapore",
        languages: [
            {code: "en-GB", name: "English (Great Britain)", title: "Singapore - English", id: "en-SG"}
        ],
        currencyCode: "SGD"
    },
    {
        code: "TH", name: "Thailand",
        languages: [
            {code: "th-TH", name: "Thailand - Thai", title: "Thailand - Thai", id: "th-TH"},
            {code: "en-GB", name: "English (Great Britain)", title: "Thailand - English", id: "en-TH"}
        ],
        currencyCode: "THB"
    },
    {
        code: "TW", name: "Taiwan",
        languages: [
            {code: "zh-TW", name: "Mandarin (Taiwan)", title: "Taiwan - Mandarin", id: "zh-TW", useCountryLangCodeForDisplay : true},
            {code: "en-GB", name: "English (Great Britain)", title: "Taiwan - English", id: "en-TW", useCountryLangCodeForDisplay : true}
        ],
        currencyCode: "TWD"
    },
    {
        code: "JP", name: "Japan",
        languages: [
            {code: "ja-JP", name: "Japan - Japanese", title: "Japan - Japanese", id: "ja-JP"},
            {code: "en-GB", name: "English (Great Britain)", title: "Japan- English", id: "en-JP"}
        ],
        currencyCode: "JPY", 
        maxCurrencyDigits: 0
    },
    {
        code: "PH", name: "Philippines",
        languages: [
            {code: "en-GB", name: "English (Great Britain)", title: "Philippines - English", id: "en-PH"}
        ],
        currencyCode: "PHP"
    },
    { 
        code: "VN", name: "Vietnam" ,
        languages: [ 
            {code: "vi-VN", name: "Vietnam - Vietnamese", title: "Vietnam - Vietnamese", id: "vi-VN"},
            {code: "en-GB", name: "English (Great Britain)", title: "Vietnam - English", id: "en-VN"}
        ], 
        currencyCode: "VND", 
        maxCurrencyDigits: 0
    },
    {
        code: "MY", name: "Malaysia" ,
        languages: [
            {code: "en-GB", name: "English (Great Britain)", title: "Malaysia - English", id: "en-MY"}
        ],
        currencyDisplay: "narrowSymbol",
        currencyCode: "MYR"
    },
    { 
        code: "PT", name: "Portugal" ,
        languages: [ 
            {code: "pt-PT", name: "Portugal - Portuguese", title: "Portugal - Portuguese", id: "pt-PT"},
            {code: "en-GB", name: "Portugal - English", title: "Portugal - English", id: "en-PT"}
        ], 
        currencyCode: "EUR"
    },
    {
        code: "KR", name: "South Korea" ,
        languages: [
            {code: "ko-KR", name: "South Korea - Korean", title: "South Korea - Korean", id: "ko-KR"},
            {code: "en-GB", name: "South Korea - English", title: "South Korea - English", id: "en-KR"}
        ], 
        currencyCode: "KRW",
        maxCurrencyDigits: 0
    }

];