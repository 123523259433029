import React, { Component } from 'react'
import {  MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { FormattedMessage } from 'react-intl';
import {nameToString} from "../../../utils/utils";

class PlayBackTable extends Component {
    render() {
        const { user, playBackTitleMsgId, playBackTextMsgId } = this.props;

        return(
            <React.Fragment>
                {playBackTitleMsgId ? <h3 className={"mb-4"}><FormattedMessage id={playBackTitleMsgId} /></h3> : null}
                {playBackTextMsgId ? <p><FormattedMessage id={playBackTextMsgId} /></p> : null}
                <MDBTable className={"table-sm table-bordered-simple mb-5"}>
                    <MDBTableHead color={"light"}>
                        <tr>
                            <th className={"w-33"}><FormattedMessage id={"ups.manage-user-table.name"} /></th>
                            <th className={"w-33"}><FormattedMessage id={"ups.manage-user-table.email"} /></th>
                            <th className={"w-33"}><FormattedMessage id={"ups.manage-user-table.role"} /></th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <tr>
                            <td>{nameToString(user.firstName, user.lastName)}</td>
                            <td>{user.email}</td>
                            <td><FormattedMessage id={`ups.manage-user.role.${user.role}.label`} /></td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </React.Fragment>
        )
    }
}


export default PlayBackTable;