import React from "react";
import { useIntl,FormattedMessage } from 'react-intl';
import { MDBCol, MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCloseIcon, MDBCardTitle } from "mdbreact";
import {formatDateTime} from "../../utils/ups-utils";
import { mapRole } from "../../utils/utils";

const DashboardAdminAlerts = ({ alerts, deleteMessage }) => {
    const intl = useIntl();
    return (
        <MDBCard className={"dashboard-widget-card admin-alert-widget h-100"}>
            <MDBCardTitle tag="h2">
                <span aria-hidden="true" className="fas fa-inbox mr-2"></span>
                <FormattedMessage id="dashboard.admin-alert.title"/>
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol size={"12"}>
                        {(alerts && alerts.length > 0) && alerts.map((alert) =>
                            <MDBCard key={alert._id} className={"admin-alert-widget-card dashboard-admin-alert-widget-card"}>
                                <MDBCardHeader className={"admin-alert-widget-title"}>
                                    <MDBCloseIcon className="admin-alert-widget-close" onClick={() => deleteMessage(alert._id)}/>
                                    <FormattedMessage
                                        id={`dashboard.admin-alert.${alert.type}.message`}
                                        values={alert.type == 'user-role-changed' ?
             {
                label: <span className="admin-alert-widget-name">{alert.message.displayText}</span>,
                role: <span className="admin-alert-widget-name">{intl.formatMessage({id: `ups.manage-user.role.${mapRole(alert.message.role.code)}.label`})}</span>
             }
             :
             {
                 label: <span className="admin-alert-widget-name">{alert.message.displayText}</span>
             }
}
                                />
                                </MDBCardHeader>
                            <MDBCardBody className={"p-0"}>
                                <MDBRow>
                                        <MDBCol size={"12"} className={"admin-alert-widget-date"}>
                                            <span aria-hidden="true" className="fas fa-clock mr-2"></span>
                                            <span>{formatDateTime(intl,alert.message.createdOn)}</span>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>                   
                        )}
                        {(!alerts || (alerts && alerts.length === 0)) &&
                            <span>
                                <br></br>
                                <FormattedMessage id={`dasboard.no-admin-alerts.title`} />
                            </span>
                        }
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default DashboardAdminAlerts;
