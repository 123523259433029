import * as actionTypes from '../constants/action-types';
import defaultSettings from '../constants/default-settings';
import sessionWidgetSettings from '../constants/session-widget-settings';
import { getLocale, getIssuer } from '../utils/local-storage';
import produce from "immer"
import moment from 'moment';

const INIT_STATE = {
    ...defaultSettings,
    issuer: getIssuer()
};

export default (state = INIT_STATE, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_LOCALE_SUCCESS:
                if (action.data) {
                    // delete draft.locale;
                    document.documentElement.setAttribute('lang', action.data);
                    moment.locale(action.data);
                    draft.locale = action.data;
                }
                return;
            case actionTypes.SET_ISSUER_SUCCESS:
                draft.issuer = action.data;
                return;
            case actionTypes.SIDE_MENU_COLLAPSE:
                draft.collapsedSideMenu = !state.collapsedSideMenu;
                return;
            case actionTypes.RESTORE_SETTINGS:
                draft = defaultSettings;
                return;
            //sessionWidget state is being updated
            case actionTypes.SET_SESSION_MODAL_STATE:
                if (draft.sessionWidgetSettings)
                    delete draft.sessionWidgetSettings
                let key = sessionWidgetSettings.key + new Date().getTime()
                draft.sessionWidgetSettings = {...sessionWidgetSettings,key};
                return;
            case actionTypes.SET_COUNTRY_LOCALE:
                if (action.data) {
                    draft.countryLocale = action.data;
                }
                return;
            case "persist/REHYDRATE":
                draft.storeLoaded = false; // prevent UI from loading until persistor rehydrate callback is complete
                break;
            case actionTypes.SET_STORE_STATE:
                draft.storeLoaded = action.data;
                break;
        }
    }
    );
