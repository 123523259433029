import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from 'react-router-dom';
import * as invoiceActions from "../../../actions/invoice-action";
import {
    areInvoiceDocumentsDownloadable,
    getFormattedValue,
    getLabel, getMerchantTimeZoneId,
    interpolateTemplate,
    isCreditInvoiceSupported,
    isOverpayment, isPayable,
    openTab
} from '../../../utils/invoice-utils';
import { injectIntl, FormattedMessage } from 'react-intl';
import { MDBCol, MDBRow, MDBBtn } from "mdbreact";
import { get as lodashGet } from 'lodash';
import UPSInvoiceSummaryModal from '../../InvoiceDetails/UPSInvoiceSummaryModal';
import DownloadAllDocumentModal from '../../../components/UPS/Modal/DownloadAllDocumentModal';
import * as R from "ramda";
import NotificationModal from "../Modal/NotificationModal";
import { onEnterPress } from "../../../utils/utils";
import {getKeyByBU} from "../../../utils/ups-utils";

class UPSInvoiceSummary extends React.Component {

    constructor(props) {
        super(props);

        const { intl, invoiceLiterals, merchant } = props;
        const currencyDisplay = merchant.currencyDisplay;

        this.formatValue = getFormattedValue.bind({ intl, invoiceLiterals, currencyDisplay });
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });

        this.state = {
            invoiceSummaryModal: false,
            downloadAllDocumentModal: false,
            notificationModalProps: {}
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    createSummaryField = (label, value, modal, url) => {
        let val = value;
        if (modal) {
            val = <span key={value} className="ups-link" tabIndex="0" onKeyPress={(e) => {
                e.persist();
                onEnterPress((e)=>this.toggleModal(modal))(e)
            }} onClick={(e) => {
                e.persist();
                this.toggleModal(modal);
            }}>{value}</span>;
        } else if (url) {
            val = <span key={value} className="ups-link" tabIndex="0" onClick={() => openTab(url)} onKeyPress={(e) => {
                onEnterPress((e)=>openTab(url))(e)
            }}>{value}</span>;
        }

        return (
            <div key={`summaryField-${value}`} className={'invoice-summary-item py-2'}>
                {label &&
                    <p className={'mb-0'}>
                        <strong>{label}</strong>
                    </p>
                }
                {val}
            </div>
        );
    };

    createSummaryButton = (invoice, label, modal, id, type='secondary', onClick) => {
      return (
          <div key={`summaryButton-${id}`} className={"invoice-summary-item-cta"}>
              <MDBBtn color={type} id={id} className={"ml-0 btn-mini"} onClick={onClick ?? (() => this.toggleModal(modal, invoice))}>
                {label}
              </MDBBtn>
          </div>
        )
    };

    createDownloadButtons = (invoice, label, modal, id, type='secondary', onClick, merchant) => {
        const {businessUnitKeys} = this.props;
        return (<React.Fragment key={`downloadButtons-${id}`}>
            <div className={"invoice-summary-item-cta"}>
                <MDBBtn color={type} id={'invoice-summary-download-invoice-btn'} className={"ml-0 btn-mini"} onClick={onClick ?? (() => this.toggleModal(modal, 'inv'))}>
                    {<FormattedMessage id={businessUnitKeys('downloadInvoice')}/>}
                </MDBBtn>
            </div>
            { areInvoiceDocumentsDownloadable(merchant, invoice) && (
                <div className={"invoice-summary-item-cta"}>
                    <MDBBtn color={type} id={id} className={"ml-0 btn-mini"} onClick={onClick ?? (() => this.toggleModal(modal, 'doc'))}>
                        {label}
                    </MDBBtn>
                </div>
            )}
            </React.Fragment>
        )
    };

    displaySummaryLines = (invoice, summaryLines) => {
        const {permissions, merchant} = this.props;
        return (summaryLines || [])
            // TODO: filter lines based on conditionals
            .map((summaryLine, index) => {
                const summaryFields = [];
                for (const fieldDefinition of summaryLine) {
                    const label = this.formatLabel(invoice, fieldDefinition);
                    const value = this.formatValue(invoice, fieldDefinition);
                    const modal = fieldDefinition.modal || null;

                    if (fieldDefinition.type === 'button') {
                        if( fieldDefinition.modal === "downloadAllDocument"){
                            if(permissions?.invoice_download) {
                                summaryFields.push(this.createDownloadButtons(invoice, label, modal, fieldDefinition.id, 'secondary', null, merchant));
                            }
                        } else {
                            summaryFields.push(this.createSummaryButton(invoice, label, modal, fieldDefinition.id));
                        }
                    } else {
                        if (typeof value === 'undefined') continue;
                        if (fieldDefinition.type === 'link' && fieldDefinition.url) {
                            const url = interpolateTemplate(fieldDefinition.url, invoice);
                            summaryFields.push(this.createSummaryField(label, value, null, url))
                        } else {
                            summaryFields.push(this.createSummaryField(label, value, modal, null));
                        }
                    }
                }
                return (
                    <MDBRow key={index} className={"mx-0"}>
                        {summaryFields}
                    </MDBRow>
                );
            });
    };

    toggleModal = (modalName, data) => {
        this.setState({
            data,
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    downloadAllDocument = (data) => {
        this.toggleModal('downloadAllDocument', data);
    };

    payAction = () => {
        const {invoice, history, sessionSelection} = this.props;
        const invoices = new Map([[invoice.id, invoice]]);
        if(isOverpayment(invoice)) {
            this.setState({notificationModalProps: {message:"invoice.refund-not-eligible.body",title:"invoice.refund-not-eligible.title"}})
            return
        }
        history.push('/ups/billing/otp', {invoices, isPlan:(sessionSelection.businessUnit.name === "EBS" && (!!invoice.planNumber && !invoice.isPayable))})
    }

    shouldShowPayButton = () => {
        const {permissions, invoice, planInvoice, sessionSelection} = this.props;
        const {merchant} = sessionSelection
        if(permissions.otp_payment){
            const creditInvoicesSupported = isCreditInvoiceSupported (sessionSelection);
            const timeZoneId = getMerchantTimeZoneId (sessionSelection);
            return isPayable (creditInvoicesSupported, timeZoneId, merchant) ({...invoice, parent: planInvoice })
        } else return false
    }

    render() {
        const { toggleModal } = this;
        const { invoice, invoiceLiterals, screenConfig, summaryData, merchant } = this.props;
        const { invoiceSummaryModal, downloadAllDocumentModal, notificationModalProps, data } = this.state;
        const components = [];
        const title = lodashGet(screenConfig, 'summary.msgId');
        const summaryLines = lodashGet(screenConfig, 'summary.lines', []);
        if (invoiceSummaryModal) {
            components.push(
                <UPSInvoiceSummaryModal
                    key={'invoice-summary-modal'}
                    isOpen={invoiceSummaryModal}
                    invoice={invoice}
                    summaryData={summaryData}
                    toggleModal={toggleModal}
                    invoiceLiterals={invoiceLiterals}
                    merchant={merchant}
                />
            );
        }

        if (downloadAllDocumentModal) {
            components.push(
                <DownloadAllDocumentModal
                    key={'download-all-document-modal'}
                    isOpen={downloadAllDocumentModal}
                    invoice={invoice}
                    downloadType={data}
                    toggleModal={toggleModal}
                    showToggleDisplayMode={true}
                />
            );
        }

        return (
            <React.Fragment>
                <div>
                    <MDBRow className={"mx-0 align-items-center"}>
                        <h1 className={"has-breadcrumb"}>
                            <FormattedMessage id={title} />
                        </h1>
                        {this.shouldShowPayButton() &&
                            <div className={"ml-auto"}>
                                <MDBBtn color={"primary"} id={'invoice-details-pay-button'} onClick={this.payAction}>
                                    <FormattedMessage id={'pay.label'}/>
                                </MDBBtn>
                            </div>
                        }
                    </MDBRow>
                    <div className={'invoice-summary-container'}>
                        {this.displaySummaryLines(invoice, summaryLines)}
                    </div>
                </div>
                {!R.isEmpty(notificationModalProps) && <NotificationModal
                    isOpen={!R.isEmpty(notificationModalProps)}
                    closeModal={() => this.setState({ notificationModalProps: {} })}
                    {...notificationModalProps}
                />}
                {components}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        businessUnitKeys: getKeyByBU('invoiceTable')(state.auth.user.selectedPaymentType),
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        permissions: state.auth.user.permissions,
        sessionSelection: state.config.sessionSelection,
        merchant: state.config.sessionSelection.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(UPSInvoiceSummary)));
