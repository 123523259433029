import React from 'react';
import { MDBRow } from "mdbreact";
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {DropdownOption, MultiSelectOption} from "../FilterOptions";
import {evaluateConditionals, getLabel, getTranslatedOption, interpolateTemplate} from '../../../utils/invoice-utils'
import {default as _, get as lodashGet} from "lodash";
import * as invoiceApi from "../../../api/invoice-api";

class QuickFilterPane extends React.Component {

    constructor(props) {
        super(props);
        const {invoiceLiterals, intl} = this.props;
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });
        this.formatOption = getTranslatedOption.bind({ intl, invoiceLiterals });

        this.state = {};
    }

    componentDidMount = async () => {
        const {invoiceMetadata, screen, quickFiltersConfig} = this.props
        const screenConfig = invoiceMetadata.screens[screen];
        if (!screenConfig) return null;
        const { table } = screenConfig;

        if (table.quickFilters) {
            const quickFiltersData = await this.getQuickFilterOptions(table.quickFilters)
            if(isEmpty(quickFiltersData)) return null;

            let components = []
            for (const quickFilter of quickFiltersConfig) {
                components.push(this.createQuickFilter(quickFilter, quickFiltersData));
            }

            this.setState({
                loaded: true,
                components
            }, ()=>this.props.setQuickFiltersData(quickFiltersData));

        }
    }

    getQuickFilterOptions = async (quickFilters) => {
        const { envProps, invoice, intl, invoiceMetadata, screen, formatValue } = this.props;
        const { apiUrlPrefix, apiToken } = envProps;
        const screenConfig = invoiceMetadata.screens[screen];

        if (!screenConfig) return null;

        const { table } = screenConfig;

        const filterOptions = {};
        for (const quickFilter of quickFilters) {
            if (quickFilter.conditionals && !evaluateConditionals(quickFilter.conditionals,invoice)) continue;

            if (quickFilter.api) {
                const url = interpolateTemplate(quickFilter.api, invoice);
                const tableConditionals = lodashGet(table, 'conditionals', []);
                const invoiceAccount = _.pick(invoice, ['accountNumber', 'planNumber', 'recordType'])
                const res = await invoiceApi.getQuickFilterAggregate(url, tableConditionals, apiUrlPrefix, apiToken, invoiceAccount);
                if (res.parsedBody) {
                    let val = [];
                    res.parsedBody.forEach(option => {
                        const value = option[quickFilter.field] || option;
                        if (quickFilter.format) {
                            quickFilter.format.forEach(field => {
                                option[field.field] = formatValue({
                                    ...option,
                                    ...(lodashGet(invoice, 'currencyCode') ? {currencyCode: invoice.currencyCode} : {})
                                }, field);
                            });
                        }

                        let text = option[quickFilter.field] || option;
                        if (quickFilter.template) {
                            text = interpolateTemplate(quickFilter.template, option);
                        }
                        val.push({text, value});
                    });

                    if (val.length === 0) continue;

                    if (quickFilter.type === 'dropdown') {
                        val.unshift({text: intl.formatMessage({id: "filter-options.dropdown.all-available"}), value: ""});
                    }
                    filterOptions[quickFilter.field] = {options: val};
                }
            } else {
                filterOptions[quickFilter.field] = {options: quickFilter.options};
            }
        }
        return filterOptions;
    }

    createQuickFilter = (quickFilter, quickFiltersData) => {
        const { intl } = this.props;
        const {handleFilter} = this;
        const filterObj = quickFiltersData[quickFilter.field];

        if (!filterObj) return (null);

        const {options, selectedValue} = filterObj;

        if (!Array.isArray(options) || options.length === 0) return (null);

        const onUpdateFilters = (key, value) => {
            handleFilter(quickFilter.field, quickFilter.type === 'multi-select', key, value);
        };

        let data = {
            msgId: quickFilter.msgId,
            filter: {options},
            field: quickFilter.field,
            intl,
            existingFilters: selectedValue,
            formatLabel: this.formatLabel,
            formatOption: this.formatOption,
            onUpdateFilters,
            colClassName: 'col-12 col-md-4 pt-0 mb-4'
        };

        if (quickFilter.type === 'dropdown') {
            return <DropdownOption {...data} getCheckedFilter={(existingFilters, field, filterOptions)=>lodashGet(existingFilters, field, filterOptions[0].value)}/>;
        } else if (quickFilter.type === 'multi-select') {
            return <MultiSelectOption {...data} />;
        }
    };

    handleFilter = (field, multiSelect, key, value) => {
        let parsedValue = value
        if(value?.length && value[0] === "") parsedValue = undefined
        this.props.updateQuickFilter({[field]: parsedValue});
    };

    render() {
        const {components, loaded} = this.state;

        if (!loaded) return (null);

        return (
            <MDBRow>
                {components}
            </MDBRow>
        );
    }
}

export default injectIntl(QuickFilterPane);