import * as R from 'ramda';
import enLang from './entries/en-US';

const AppLocale = {
    'en-US': enLang
};

const injectDefaultLocale = R.over(
    R.lensProp('messages'),
    R.mergeRight(enLang.messages)
);

export async function getLocaleFiles(locale) {
    if (!AppLocale[locale]) {
        await import('./entries/' + locale)
            .then(localeObj => {
                if (localeObj) {
                    AppLocale[locale] = injectDefaultLocale(localeObj.default);
                }
            })
            .catch(error => {
                /* Error handling */
            });
    }
}

export default AppLocale;
