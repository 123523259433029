import React, {useMemo} from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem} from "mdbreact";
import {useIntl} from "react-intl";

import {displayWalletSelection} from "../../utils/payment-utils";
import {stripAccountNumber, getAccNameLabel, getAccNumLabel} from "../../utils/ups-utils";
import {useSelector} from "react-redux";
import FilteredDataTableV3 from "../../components/DataTableV3/DataTableV3FilterWrapper";

function getDataTableColumns(intl, businessUnit, handleAction, permissions) {
    return [
        {
            field: 'accountDetail',
            label: getAccNumLabel(intl)(businessUnit), // do dynamically
            display: (data) => <AccountCell payByText={data} intl={intl}/>,
            serialize: (data)=>getAccountText(data, intl),
            sortFunc: (data)=>getAccountText(data, intl)
        },
        {
            field: 'accountName',
            label: getAccNameLabel(intl)(businessUnit),
            mobileCardPrimary: true,
            mobileDisplay: ({accountName, accountNumber, paymentType}) => (accountName || stripAccountNumber(accountNumber, paymentType))
        },
        {
            field: 'paymentMethod',
            label: intl.formatMessage({ id: 'modal.view-payByText.payment-method'}),
            display: ({paymentMethod})=>displayWalletSelection(intl)(paymentMethod),
            serialize: ({paymentMethod})=>displayWalletSelection(intl)(paymentMethod),
            sortFunc: ({paymentMethod})=>displayWalletSelection(intl)(paymentMethod),

        },
        {
            field: 'actions',
            label: intl.formatMessage({ id: 'modal.view-payByText.actions' }),
            className: 'no-export',
            sortable: false,
            display: (data) => PayByTextActions(data, handleAction, intl, permissions)
        }
    ]
}

function PayByTextActions(payByText, handleAction, intl, permissions) {
    const actionLabel  = intl.formatMessage({id: `more-actions.payByText-label`}, {accountNumber : stripAccountNumber(payByText.accountNumber, payByText.paymentType)});
    
    return <MDBDropdown>
        <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" aria-label={actionLabel}>
            <i className="fas fa-ellipsis-v"></i>
        </MDBDropdownToggle>

        <MDBDropdownMenu basic right>

            {permissions && permissions["otp_payment"] && (
             <React.Fragment>
                <MDBDropdownItem className="text-left" onClick={
                    () => handleAction("editPayByText", {...payByText, authorizePayByText: true})
                }>
                    {intl.formatMessage({id:"ups.btn.edit.label"})}
                </MDBDropdownItem>

                <MDBDropdownItem divider/>

                <MDBDropdownItem className="text-left" onClick={
                    () => handleAction("cancelPayByText", payByText)
                }>
                    {intl.formatMessage({id: "ups.btn.remove.label"})}
                </MDBDropdownItem>
            </React.Fragment>
            )}
        </MDBDropdownMenu>
    </MDBDropdown>;
}

function getAccountText(data, intl){
    return intl.formatMessage({id:"modal.view-pay-by-text.account.labeled"}, {account: stripAccountNumber(data.accountNumber, data.paymentType)})
}

function AccountCell({payByText, intl}) {
    return <div>{getAccountText({accountNumber: payByText.accountNumber, paymentType: payByText.paymentType}, intl)}</div>
}

export default function PayByTextTable({source, payByTextList, handleAction, tableState, tableStateAction, enablePBTAddBtn, caption}) {
    const intl = useIntl();
    const {permissions, selectedPaymentType: businessUnit} = useSelector(s => s.auth.user ?? {});

    const columns = useMemo(()=>{
        return getDataTableColumns(intl, businessUnit, handleAction, permissions)
    }, [intl.locale, businessUnit])

    return <FilteredDataTableV3
    caption={caption}
        name={source}
        className="table-card-mobile allow-default-wrap"
        data={payByTextList}
        columns={columns}
        defaultSorting={'accountDetail'}
        buttons={[{
            text: intl.formatMessage({id:"paybytext.btn.pay-by-text"}),
            hide: !permissions.otp_payment,
            action: ()=>(enablePBTAddBtn && handleAction('addPayByText')),
            otherProps: { disabled: !enablePBTAddBtn }
        }]}
        tableState={tableState}
        tableStateAction={tableStateAction}
        mobileCard
        searchable
        rowHeaderField={'accountDetail'}
    />
}
