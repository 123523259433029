/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/6/19 11:59 AM
 * Created by kxian
 *
 */

import * as actionTypes from '../constants/action-types';

export const setAccount = (data,index) => {
    return {
        type: actionTypes.SET_ACCOUNT,
        account: data,
        accountIndex: index
    }
};

export const editAccount = (data) => {
    return {
        type: actionTypes.EDIT_ACCOUNT,
        account: data
    }
};

export const clearAccount = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT
    }
};

export const changeAccount = () => {
    return {
        type: actionTypes.CHANGE_ACCOUNT
    }
};

export const getAccountList = (callback) => {
    return {
        type: actionTypes.GET_ACCOUNT_LIST,
        callback: callback
    }
};

export const getAccountListSuccess = (data) => {
    return {
        type: actionTypes.GET_ACCOUNT_LIST_SUCCESS,
        data: data
    }
};

export const getAccountListFailure = (data) => {
    return {
        type: actionTypes.GET_ACCOUNT_LIST_FAILURE,
        data: data
    }
};

export const getPlanList = (callback) => {
    return {
        type: actionTypes.GET_PLAN_LIST,
        callback: callback
    }
};

export const getPlanListSuccess = (data) => {
    return {
        type: actionTypes.GET_PLAN_LIST_SUCCESS,
        data: data
    }
};

export const getPlanListFailure = (data) => {
    return {
        type: actionTypes.GET_PLAN_LIST_FAILURE,
        data: data
    }
};

export const validateAccountEligibility = (data) => {
    return {
        type: actionTypes.VALIDATE_ACCOUNT_ELIGIBILITY,
        data: data
    }
};

export const validateAccountEligibilitySuccess = (data) => {
    return {
        type: actionTypes.VALIDATE_ACCOUNT_ELIGIBILITY_SUCCESS,
        data: data
    }
};


export const restartAddAccount = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_RESTART
    }
};

export const cancelAddAccount = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_CANCEL
    }
};

export const addAccount = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT,
        data: data
    }
};

export const initAddAccount = () => {
    return {
        type: actionTypes.INIT_ADD_ACCOUNT
    }
};

export const aiaRequired = (data) => {
    return {
        type: actionTypes.AIA_REQUIRED,
        data: data
    }
};

export const addAccountSuccess = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_SUCCESS,
        data: data
    }
};

export const addAccountComplete = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_COMPLETE
    }
}

export const validateInvoice = (data) => {
    return {
        type: actionTypes.VALIDATE_INVOICE,
        data: data
    }
};

export const validateInvoiceSuccess = (data) => {
    return {
        type: actionTypes.VALIDATE_INVOICE_SUCCESS,
        data: data
    }
};

export const addAccountToCompany = (data, callback) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_COMPANY,
        data: data,
        callback: callback
    }
};

export const addAccountToCompanySuccess = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_COMPANY_SUCCESS,
        data: data
    }
};

export const addAccountToCompanyFailure = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_COMPANY_FAILURE,
        data: data
    }
};

export const addAccountToIdentity = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_IDENTITY,
        data: data
    }
};

export const addAccountToIdentitySuccess = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_IDENTITY_SUCCESS,
        data: data
    }
};

export const addAccountToIdentityFailure = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_IDENTITY_FAILURE,
        data: data
    }
};

export const removeAccounts = (data) => {
    return {
        type: actionTypes.REMOVE_ACCOUNTS,
        data: data
    }
};

export const removeAccountSuccess = (data) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_SUCCESS,
        data: data
    }
};

export const removeAccountFailure = (data) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_FAILURE,
        data: data
    }
};

export const removeAccountComplete = () => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_COMPLETE
    }
}

export const getXmlEnrollment = (data) => {
    return {
        type: actionTypes.GET_XML_ENROLLMENT,
        data: data
    }
}

export const getXmlEnrollmentSuccess = () => {
    return {
        type: actionTypes.GET_XML_ENROLLMENT_SUCCESS,
    }
}

export const getXmlUnEnrollmentSuccess = () => {
    return {
        type: actionTypes.GET_XML_UNENROLLMENT_SUCCESS,
    }
}

export const getXmlEnrollmentFailure = (data) => {
    return {
        type: actionTypes.GET_XML_ENROLLMENT_FAILURE,
        data: data
    }
}

export const getXmlEnrollmentComplete = () => {
    return {
        type: actionTypes.GET_XML_ENROLLMENT_COMPLETE
    }
}

export const enrollXml = (data) => {
    return {
        type: actionTypes.ENROLL_XML,
        data: data
    }
}

export const enrollXmlSuccess = (data) => {
    return {
        type: actionTypes.ENROLL_XML_SUCCESS,
        data: data
    }    
}

export const enrollXmlFailure = (data) => {
    return {
        type: actionTypes.ENROLL_XML_FAILURE,
        data: data
    }    
}

export const unenrollXml = (data) => {
    return {
        type: actionTypes.UNENROLL_XML,
        data: data
    }
}

export const unenrollXmlSuccess = () => {
    return {
        type: actionTypes.UNENROLL_XML_SUCCESS,
    }    
}

export const unenrollXmlFailure = (data) => {
    return {
        type: actionTypes.UNENROLL_XML_FAILURE,
        data: data
    }    
}

export const enrollXmlComplete = () => {
    return {
        type: actionTypes.ENROLL_XML_COMPLETE
    }
};

export const unEnrollXmlComplete = () => {
    return {
        type: actionTypes.ENROLL_XML_COMPLETE
    }
};

