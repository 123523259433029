
import { isNonCCPaymentMethod, isSepa
       , anyInvoicesFullyPaid }                 from '../../utils/payment-utils';
import * as R                                   from 'ramda';
import { isRefund }                             from '../../utils/currency-utils';
import {isCredit}                               from "../../utils/invoice-utils";
import {MAX_DUPLICATE_CHECK_INVOICES}           from "../../constants/invoice-constants";
import { v4 as uuidv4 }                         from 'uuid';
import {produce} from "immer";

const STEPS = [
  {id: "invoice"},
  {id: "payment"},
  {id: "review"},
  {id: "receipt"}
];

const sepaMandateLens = R.lensPath(['paymentMethod', 'sepaMandate']);

const stepIdxById = id => R.compose(
  R.findIndex(R.propEq('id', id)),
  R.prop('steps')
);

const onStepId = id => R.converge(R.equals, [
  R.prop('step'),
  stepIdxById(id)
]);

const stateSetStepId = id => R.converge(R.assoc, [
  R.always('step'),
  stepIdxById(id),
  R.identity
]);

export const payInvoiceInitialState = {
  invoices: [],
  requestRecentPayments: false,
  error: '',
  threeDSecureFormData: {},
  paymentReceipt: null,
  wallet: [],
  effectiveDate: null,
  paymentMethod: null,
  steps: STEPS,
  step: 0,
  recentPayments: [],
  showRecentPaymentsAlert: false,
  paymentInProgress: false,
  requestCheckPayEligible: false,
  paymentBlocked: true,
  creditAllowed: true,
  isPlan: false,
  isSavePayMethod: false,
  isRefund: false,
  isOnlyCreditNotes: false,
  requestWallet: false,
  enableSepaMandate: false,
  showRecentPayLimitReached: false,
  paymentSubmitted: false,
  duplicatePayments: false,
  threeDsFlow: false,
  authorizeDD: false
};

// Mutable structures should not be in state.
function asInvoiceMap (invoices) {
  const invoiceMap = new Map();
  for (const invoice of invoices) {
    invoiceMap.set(invoice.id, invoice);
  }
  return invoiceMap;
}

const addSepaStep = R.unless
  ( R.any(R.propEq('id', 'sepa'))
  , R.insert(2, {id: "sepa"})
  );

const getDefaultOrFirst = R.ifElse
  ( R.anyPass([R.isEmpty, R.isNil])
  , R.always(null)
  , xs => R.find(R.prop('default')) (xs) || R.head (xs)
  );

export const getAllInvoices = R.compose
  ( R.map(invoice => ({...invoice, amount: invoice.paymentAmount})) // TODO Need to make this consistent
  , m => Array.from(m.values()) // We shouldn't use a mutable structure in state
  );

export const getSelectedInvoices = R.compose
  ( R.filter(R.complement (R.prop ('deselected')))
  , getAllInvoices
  );

const stateStepForward = R.ifElse
  ( onStepId('invoice')
  , R.ifElse
        ( R.compose
            ( R.flip(R.gt) (MAX_DUPLICATE_CHECK_INVOICES)
            , R.length
            , getSelectedInvoices
            , R.prop('invoices')
            )
        , R.set(R.lensProp('showRecentPayLimitReached'), true)
        , R.set(R.lensProp('requestRecentPayments'), true)
        )
  , R.when
      ( R.converge(R.lt,
          [ R.compose(R.inc, R.prop('step'))
          , R.compose(R.length, R.prop('steps'))
          ])
      , R.over(R.lensProp('step'), R.inc)
      )
  );

const stateReceiveRecentPayments = ({recentPayments}) => R.compose
  ( R.ifElse
      ( R.propSatisfies (R.isEmpty, 'recentPayments')
      , stateSetStepId('payment')
      , R.set(R.lensProp('showRecentPaymentsAlert'), true)
      )
  , state => ({ ...state
              , error: ''
              , requestRecentPayments: false, recentPayments
              , paymentBlocked: anyInvoicesFullyPaid (recentPayments)
              })
  );

const allSupportCredit = R.ifElse
  ( R.all(R.has('creditAllowed')) // make sure we have received a credit response
  , R.all(R.prop('creditAllowed'))
  , R.always(true)
  );

const debitOrBankingOnly = R.filter(isNonCCPaymentMethod);

const setFirstWalletPayment = R.compose
  ( (state)=>produce(newState=>{
      newState.steps = checkExtraStep(newState, newState)
    })(state)
  , state => ({ ...state
              , paymentMethod: getDefaultOrFirst (state.wallet)
              })
  );

const stateUpdateSelectionEligibility = R.compose
  ( R.when // Check if no payment is previously selected and set first wallet item
      ( R.propSatisfies(R.isNil, 'paymentMethod')
      , setFirstWalletPayment
      )
  , R.when
        ( R.complement(R.prop('creditAllowed'))
        , R.compose
            ( setFirstWalletPayment // Do this again ignoring previous selection
            , R.over(R.lensProp('wallet'), debitOrBankingOnly)
            )
        )
  , state => R.set( R.lensProp('creditAllowed')
                  , allSupportCredit(getSelectedInvoices(state.invoices))
                  )
                  (state)
  );

const mergeInvoices = invoicesMap => ({id, creditAllowed}) => ({...invoicesMap.get(id), creditAllowed});

const stateReceiveValidatedInvoices = ({invoices}) => R.compose
  ( stateUpdateSelectionEligibility
  , R.over(R.lensProp('invoices'), R.compose
                                    ( asInvoiceMap
                                    , invoicesMap =>
                                        R.map(mergeInvoices (invoicesMap)) (invoices)
                                    )
          )
  , state => ({...state
              , paymentBlocked: false
              , requestWallet: true
              , requestCheckPayEligible: false
              })
  );

const setWalletError = ({ walletResp }) =>
  walletResp && walletResp !== 'saved' ? walletResp : '';

const stateSelectInvoice = ({id, invoice}) => R.compose
  ( state => (b => ({ ...state
                    , isRefund: b
                    , requestWallet: b !== state.isRefund
                    })
            )
            (isRefund(getSelectedInvoices(state.invoices)))
  , R.over(R.lensProp('invoices'), m => new Map(m).set(id, invoice))
  );

const stateReceiveWallet = ({wallet}) => R.compose
  ( stateUpdateSelectionEligibility
  , state => ({ ...state
              , wallet
              , requestWallet: false
              , paymentMethod: null // reset payment method
              })
  );

const allCreditNotes = R.compose
  ( R.all(isCredit)
  , getAllInvoices
  );

const deSelect = R.map(R.set(R.lensProp('deselected'), true));

const limitPayable = (MAX_INVOICES)=>R.compose
  ( ([a, b]) => [...a, ...deSelect(b)]
  , R.juxt([R.take(MAX_INVOICES), R.drop(MAX_INVOICES)])
  );

const prepareInvoices = (MAX_INVOICES)=>R.compose
  ( asInvoiceMap
  , limitPayable(MAX_INVOICES)
  , R.map(invoice => R.assoc('paymentAmount', invoice.outstandingAmount) (invoice))
  , m => Array.from(m.values())
  );

const stateSetInvoices = ({invoices, isPlan, MAX_INVOICES}) => R.compose
  ( state => R.assoc('isRefund', isRefund (getSelectedInvoices (state.invoices))) (state)
  , R.assoc('invoices', prepareInvoices(MAX_INVOICES)(invoices))
  , state => ({ ...state
              , isPlan: isPlan
              , requestCheckPayEligible: true
              , isOnlyCreditNotes: allCreditNotes (invoices)
              })
  );

const checkExtraStep = (state, {paymentMethod}) => {
    const {steps} = state
    const id = paymentMethodExtraSteps[paymentMethod?.methodType]?.(state)
    return id ? addPaymentMethodExtraStep(id, steps) : STEPS
}

const addPaymentMethodExtraStep = (id, steps) => {
    if(steps.some((step)=>(step.id === id))) return steps
    const newSteps = [...STEPS]
    newSteps.splice(2,0,{id})
    return newSteps
}

const paymentMethodExtraSteps = { //if a payment method appears here it has an extra step
    SEPA: ({enableSepaMandate})=>enableSepaMandate?'sepa':undefined,
    BACS: ()=>'bacs'
}

export function payInvoiceReducer (state, action) {
  switch(action.type) {
      case 'ERROR': return {
          ...state,
          requestRecentPayments: false,
          error: action.error,
          paymentInProgress: false,
          paymentBlocked: action.blocked,
          requestCheckPayEligible: false,
          requestWallet: false
      }
      case 'RECEIVE_INVOICES': return stateSetInvoices (action) (state)
      case 'RECEIVE_VALIDATED_INVOICES': return stateReceiveValidatedInvoices (action) (state)
      case 'RECEIVE_RECENT_PAYMENTS': return stateReceiveRecentPayments (action) (state)
      case 'SKIP_RECENT_PAYMENTS': return {...state, error: '', requestRecentPayments: false}
      case 'RECEIVE_WALLET': return stateReceiveWallet (action) (state)
      case 'RECEIVE_RECEIPT': return {
          ...state,
          paymentReceipt: action.paymentResult,
          step: stepIdxById('receipt')(state),
          paymentInProgress: false,
          error: setWalletError(action.paymentResult)
      }
      case '3D_SECURE_COMPLETED': return {
        ...state,
        paymentReceipt: action.paymentResult,
        invoices: new Map(action.paymentResult.invoices
          .map(inv => [inv.id, { ...inv, paymentAmount: inv.amount }])),
        step: stepIdxById('receipt')(state),
        threeDSecureFormData: {},
        paymentInProgress: false,
        error: setWalletError(action.paymentResult)
      }
      case 'SET_EFFECTIVE_DATE': return {
          ...state,
          effectiveDate: action.effectiveDate
      }
      case 'SET_AUTH_SEPA': return R.over(sepaMandateLens, sepaMandate => ({...sepaMandate, authorizeSepa: action.authorizeSepa})) (state)
      case 'SET_AGREEMENT_PROPERTY': return produce(newState=>{
          newState[action.authorizeProperty] = action.authorized
      })(state)
      case 'SET_SEPA_MANDATE_INFO': return R.set(sepaMandateLens, action.sepaMandate) (state)
      case 'SET_PAYMENT_METHOD': return {
        ...state,
        paymentMethod: action.paymentMethod,
        steps: checkExtraStep(state, action),
        authorizeDD : false
      }
      case 'STEP_BACK': return {
          ...state,
          step: state.step > 0 ? state.step - 1 : state.step,
          error: ''
      }
      case 'STEP_FORWARD': return stateStepForward (state)
      case 'SELECT_INVOICE': return stateSelectInvoice (action) (state)

      case 'IGNORE_RECENT_PAYMENTS': return {
        ...state,
        showRecentPaymentsAlert: false,
        showRecentPayLimitReached: false,
        step: stepIdxById('payment')(state),
        duplicatePayments: true
      }
      case 'CLOSE_RECENT_PAYMENTS': return {
        ...state,
        showRecentPaymentsAlert: false,
        showRecentPayLimitReached: false,
        paymentBlocked: false
      }
      case 'SUBMIT_PAYMENT': return {
        ...state,
        paymentInProgress: true,
        paymentSubmitted: true,
        error: ''
      }
      case 'TOGGLE_SAVE_TO_WALLET': return {
        ...state,
        isSavePayMethod: !state.isSavePayMethod,
      }
      case 'SET_3D_SECURE_FORM_DATA': return {
        ...state,
        threeDSecureFormData: action.formData,
        step: stepIdxById('review')(state)
      }
      case 'SET_3DS_FLOW': return {
        ...state,
        threeDsFlow: true
      }
      case 'GENERATE_PAYMENT_SESSION_ID': return {
        ...state,
        paymentSessionId: uuidv4()
      }
      default: return state;
  }
}
