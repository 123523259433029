import {getChargebackTransactionType, returnCurrencyCode} from "../../../utils/payment-history-util";
import {CHANNEL_CODE_EXT} from "../../../constants/payment-history";
import {formatCurrency, formatDate, formatToLocalDate} from "../../../utils/ups-utils";

export const columnParsers = (intl) => {
    return {
        transactionType: (rowData)=>intl.formatMessage({ id: `chargeback-history.history-table.transaction-type.${getChargebackTransactionType(rowData)}` }),
        paymentStatus: ({paymentStatus})=>intl.formatMessage({ id: `payment-history.search-field.status.option.${paymentStatus}` }),
        paymentDate: ({channel, paymentDate = ""})=> (((channel === CHANNEL_CODE_EXT) ? formatDate(intl, paymentDate) : formatToLocalDate(intl, paymentDate)) || ''),
        paymentAmount: ({totalAmount="",currencyCode="USD", currencyDisplay="symbol"}) => formatCurrency(intl, totalAmount, currencyCode, currencyDisplay)
    }
}