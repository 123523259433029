import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as identityActions from "../../../actions/identity-action";
import * as validationActions from "../../../actions/validation-action";
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommonModalData from '../Modal/CommonModalData';
import { withRouter, Link } from 'react-router-dom';
import attachValidator from '../../../utils/validation/attach-validator';
import { set as lodashSet, get as lodashGet } from 'lodash';
import produce from "immer";
import { getSupportedLanguages } from "../../../utils/ups-utils.js"
import { sortCountryList, generateCountryList } from "../../../utils/utils";
import SaveModal from '../Modal/SaveModal';
import InvoicesSection from './OptOutNotificationPreferences/InvoicesSection';
import DisputesSection from './OptOutNotificationPreferences/DisputesSection';
import AdministrationSection from './OptOutNotificationPreferences/AdministrationSection';
import PaymentsSection from './OptOutNotificationPreferences/PaymentsSection';
import PreferencesPrompt from './PreferencesPrompt';
import {PREF_SECTION} from '../../../constants/preferences-constants';
import NotificationModal, { BUTTON_TYPES } from '../Modal/NotificationModal';
import Alert from "../../Alert";
import supportLinks from "../../../constants/support-links";
const path = require('path');

class NotificationPreferences extends Component {

    constructor(props) {
        super(props);
        this.state = this.getIntialState();

        this.childRef = {};
        this.updateNotificationPreferences = this.updateNotificationPreferences.bind(this);
        this.onSectionChange = this.onSectionChange.bind(this);
    }

    getIntialState() {
        const languages = this.getPrefLanguages("email");
        const userPreferences = this.getUserPreferences(languages);
        return {
            langCountryOption: userPreferences.language.id,
            languages: languages,
            defaultType: userPreferences.defaultType,
            triggers: userPreferences.triggers,
            openModal: false,
            saveButtonModalComp: '',
            saveButtonOpenModal: false,
            saveModalData: <CommonModalData />,
            isAnySectionDirty: false,
            showErrorMessage: false
        }
    }

    savePreferences = (preferences, commonSaveModalData) => {
        this.setState({
            saveModalData: commonSaveModalData
        })
        const { identityActions } = this.props;

        if (Object.keys(preferences).length) {
            identityActions.saveIdentityPreferences(preferences, 'notifications', (result) => {
                if(result.status === true){
                    const languages = this.getPrefLanguages("email");
                    const userPreferences = this.getUserPreferences(languages);
                    this.setState({
                        triggers: userPreferences.triggers,
                        saveButtonOpenModal: true,
                        saveButtonModalComp: <SaveModal />
                    });
                }
                else{
                    this.setState({ showErrorMessage: true, errorCode : result.errorCode ?? 'internalError'});
                }
            });
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false
        });
    }

    saveButtonCloseModal = () => {
        this.setState({
            saveButtonOpenModal: false
        });
    }

    getPrefLanguages(section) {
        const { merchants, preferences, auth } = this.props;
        let countries = [];
        let prefCountryInfo = null;

        if (merchants && merchants.length > 0) {
            countries = [...new Set(merchants.map(m => m.countryCode))];
        }

        if (preferences) {
            if (section === "email" && preferences.notifications && preferences.notifications.emailSettings && preferences.notifications.emailSettings.countryCode) {
                if (countries.findIndex(x => x === preferences.notifications.emailSettings.countryCode) === -1) {
                    countries.splice(0, 0, preferences.notifications.emailSettings.countryCode);
                    prefCountryInfo = {
                        country: preferences.notifications.emailSettings.countryCode,
                        language: preferences.notifications.emailSettings.languageCode
                    }
                }
            }
        }

        if (countries.findIndex(x => x === auth.user.selectedCountry) === -1) {
            countries.splice(0, 0, auth.user.selectedCountry)
        }

        /* This check will add the country-locale that user has chosen either via SSO or set as preference
            but that country account is no longer enrolled, so only that selected locale option will be added to list
            and not all the locales associated with that particular country
        */
        let supportedLocales = getSupportedLanguages(countries);

        if (prefCountryInfo) {
            for (let i = supportedLocales.length - 1; i >= 0; i--) {
                if (supportedLocales[i].country === prefCountryInfo.country && supportedLocales[i].code !== prefCountryInfo.language) {
                    supportedLocales.splice(i, 1);
                }
            }
        }
        return supportedLocales;
    }

    getNotificationPreferences(languages) {
        let notiPref = { triggers: {} };
        const { preferences } = this.props;
        notiPref.defaultType = preferences.notifications.defaultType;
        if (preferences.notifications.emailSettings) {
            const { emailSettings } = preferences.notifications;
            languages.forEach((item) => {
                if (item.country === emailSettings.countryCode && item.code === emailSettings.languageCode) {
                    notiPref.language = Object.assign({}, item);
                }
            });
            if (emailSettings.triggers) {
                const triggerKeys = Object.keys(emailSettings.triggers);
                triggerKeys.forEach((key) => {
                    notiPref.triggers[key] = emailSettings.triggers[key];
                });
            }
        }
        return notiPref;
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            })
        );
        const { preferences } = this.props;
        (preferences.notifications.emailSettings.languageCode !== inputValue) ?
            this.setState({ isAnySectionDirty: true }) : this.setState({ isAnySectionDirty: false })
    };

    getUserPreferences(languages) {
        const { preferences } = this.props;

        let notificationPreferences = {
            language: Object.assign({}, (languages.length ? languages[0] : {})),
            defaultType: 'email',
            triggers: {},
        };

        if (preferences) {
            if (preferences.notifications) {
                notificationPreferences = { ...notificationPreferences, ...(this.getNotificationPreferences(languages)) };
            }
        }
        return notificationPreferences;
    }

    updateNotificationPreferences = (childKey) => { 
        let notifPref = {};
        let { defaultType, langCountryOption, languages, triggers } = this.state;
        const selectedLang = languages.find(x => x.id === langCountryOption);

        if(childKey !== undefined) { // when user saves preferences for a specific section
            this.onSectionChange();
            triggers = {
                ...triggers,
                ...this.childRef[childKey]?.current?.getPreferences()
            }
        }
        else{ 
            // this section saves preferences for entire section - this case occurs when user navigates to another route and is prompted to save his changes
            const objKeys = Object.keys(this.childRef);
            objKeys.forEach((child) => {
                if(this.childRef[child]?.current?.isDirty()){
                    triggers = {
                        ...triggers,
                        ...this.childRef[child]?.current?.getPreferences()
                    }
                }
            });
        }
        
        notifPref = {
            notifications: {
                defaultType: defaultType,
                emailSettings: {
                    languageCode: selectedLang.code,
                    countryCode: selectedLang.country,
                    triggers
                }
            }
        }
        this.savePreferences(notifPref, <CommonModalData />);
    }

    onSectionChange() {
        let isDirty = false;

        const childRefs = Object.keys(this.childRef);
        
        childRefs.forEach((child) => {
            if(this.childRef[child]?.current?.isDirty()){
                isDirty = true;
            }
        });

        this.setState({
            isAnySectionDirty: isDirty
        })
    }

    setChildRef(refKey, element) {
        this.childRef[refKey] = React.createRef();
        this.childRef[refKey].current = element;
    }

    getHomeUrl(){
        const {auth} = this.props;
        const {locale, selectedCountry} = auth.user || auth.userInfo || {};

        let language = locale ? locale.split("-")[0] : null;
        let country = selectedCountry ? selectedCountry.toLowerCase() : null;
        const {upsBaseUrl, upsHomeResource } = supportLinks;
        if(language && country){
            return upsBaseUrl + path.join(country, language, upsHomeResource);
        }
        return upsBaseUrl;
    }

    render() {
        const { auth, intl, userRole } = this.props;
        const { langCountryOption, languages, triggers, saveModalData, saveButtonModalComp, saveButtonOpenModal, isAnySectionDirty, showErrorMessage, errorCode } = this.state;
        const sortedCountriesList = sortCountryList(generateCountryList(languages, null, intl));
        
        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.title.dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/ups/billing/profile"><FormattedMessage id={'ups.title.profile'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                    <FormattedMessage id="ups.preferences.notifications.header" />
                                                </li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}>
                                    <FormattedMessage id="ups.preferences.notifications.header" />
                                </h1>

                                <React.Fragment>
                                    {/* <div className="required-note">*&nbsp;<FormattedMessage id="ups.preferences.notifications.requiredNote" /></div> */}
                                    <div className="ups-breadcrumb preferences">
                                        <MDBRow>
                                            <MDBCol size="12" md="6" className="mb-4">
                                                <p><FormattedMessage id="ups.preferences.notifications.note2" values={{email: <strong>{auth.user.email}</strong>}}/>.</p>
                                            </MDBCol>

                                            <MDBCol size="12" className="mb-3">
                                                <Alert type="warning" label="ups.preferences.notifications.note3" />
                                            </MDBCol>

                                            {auth.user.adUserId ? null :
                                                <MDBCol size={"12"} md={"4"} className="mt-3 mb-3">
                                                    <div className="md-form md-browser-default-form browser-default-select">
                                                        <label htmlFor={"langCountryOption"} className="browser-default-label label-first">
                                                            <FormattedMessage id="ups.preferences.notifications.langPref" />
                                                        </label>
                                                        <select
                                                            key={"langCountryOption"}
                                                            name={"langCountryOption"}
                                                            id={"langCountryOption"}
                                                            className={"browser-default custom-select"}
                                                            required
                                                            onChange={this.handleInput}
                                                            value={langCountryOption}
                                                            disabled={auth.user.adUserId}
                                                        >
                                                            {sortedCountriesList.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {intl.formatMessage({ id: "country-language-item." + item.id })}
                                                                    </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </MDBCol>
                                            }

                                            <MDBCol size="12">
                                                <p className="mt-2 mb-4">
                                                    <i>
                                                        <FormattedMessage id="ups.preferences.notification.disabledNote.title" />
                                                    </i>
                                                </p>
                                            </MDBCol>
                                            
                                            <InvoicesSection setRef={(el) => this.setChildRef(PREF_SECTION.INVOICES, el)} onSectionChange={this.onSectionChange} updateNotificationPreferences={this.updateNotificationPreferences} />
                                            {userRole !== 'VIEW_ONLY' && 
                                                <React.Fragment>
                                                    <PaymentsSection onSectionChange={this.onSectionChange} setRef={(el) => this.setChildRef(PREF_SECTION.PAYMENTS, el)} updateNotificationPreferences={this.updateNotificationPreferences} />
                                                    <DisputesSection onSectionChange={this.onSectionChange} setRef={(el) => this.setChildRef(PREF_SECTION.DISPUTES, el)} updateNotificationPreferences={this.updateNotificationPreferences} />
                                                    <AdministrationSection onSectionChange={this.onSectionChange} setRef={(el) => this.setChildRef(PREF_SECTION.ADMIN, el)} updateNotificationPreferences={this.updateNotificationPreferences} /> 
                                                </React.Fragment>
                                            }
                                            
                                        </MDBRow>
                                    </div>
                                </React.Fragment>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    {saveButtonOpenModal ?
                        <MDBRow>
                            <MDBCol>
                                <SaveModal
                                    key={'SaveButton-modal'}
                                    isOpen={saveButtonOpenModal}
                                    closeModal={this.saveButtonCloseModal}
                                    component={saveButtonModalComp}
                                    modalData={saveModalData}
                                />
                            </MDBCol>
                        </MDBRow>
                        :
                        null
                    }
                   
                    {  isAnySectionDirty &&
                        <PreferencesPrompt preparePreferences={this.updateNotificationPreferences} /> 
                    }
                </MDBRow>
                {showErrorMessage && <NotificationModal isOpen={showErrorMessage} title=" " size="md" closeModal={() => { this.setState(this.getIntialState()) }} buttonTypes={BUTTON_TYPES.OK} message={errorCode ?? "internalError"} />}
            </MDBContainer>
        );
	}
}

function mapStateToProps(state, ownProps) {
    return {
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        auth: state.auth,
        merchants: state.auth.merchants,
        preferences: state.identity.preferences,
        userRole: state.auth.user.role
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationPreferences)));