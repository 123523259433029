import React, { Component } from 'react';

/* Components */
import UPSHeader from '../components/UPS/UPSHeader';
import UPSFooter from '../components/UPS/UPSFooter';


import '../assets/css/ups.scss';
import '../assets/css/fallback.scss';

class PublicLayout extends Component {
    componentDidMount() {
        document.body.className += ' ups-style';
    }

    componentWillUnmount() {
        document.body.classList.remove('ups-style');
    }

    render() {
        return (
            <React.Fragment>
                <UPSHeader key={"main-header"} />
                <main id="ups-main-container" className="ups-main">
                    {/*<PublicRoutes />*/}
                    {this.props.children}
                </main>
                <UPSFooter showLegalLinks={true} />
                
            </React.Fragment>
        )
    }
}

export default PublicLayout;
