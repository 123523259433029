import React, { Component } from 'react';
import {MDBInput} from "mdbreact";
import {injectIntl} from 'react-intl';
import classNames from "classnames";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ValidationErrors from "../ValidationErrors";
import attachValidator from "../../utils/validation/attach-validator.js"
import * as validationActions from "../../actions/validation-action";
import * as errorActions from "../../actions/error-action";
import {formatAmount, convertCurrencyToNumber} from '../../utils/ups-utils';

class CurrencyFieldIntl extends Component{
    constructor(props){
        super(props)        
        this.state = { inputValue : "" };
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        if(this.props.validations) attachValidator.call(this)
    }
    componentDidMount(){
        let {name, validations, value} = this.props;
        const inputValue = value ? this.formatAmount(value) : value;
        this.setState({
            inputValue :inputValue
        });
        if(validations) this.validator.register(name)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value && !this.props.value) {
            this.setState({inputValue: ""});
        }
    }
    componentWillUnmount(){
        let {name, validations, errorActions, id} = this.props;
        if(validations) this.validator.deregister(name)
        //clear backend error
        errorActions.clearFieldError({id})
    }
    onChange(e){
        let {onChange, validations, name, restrict, id, errorActions} = this.props;
        let val = e.target.value;

        if(restrict){
            //restrict enterable characters to those that return true from the restrict() function
            if(restrict(val)) {
                this.oldValue = val;
            } else {
                /* This step will ensure that invalid format is not entered into currency field input - was happening only for the first instance */
                let displayValue = this.oldValue ? this.oldValue : '';
                if(!val){
                    displayValue = val;
                    this.oldValue = val;
                }
                this.setState({
                    inputValue :  displayValue
                })
                e.preventDefault();
                e.stopPropagation();
                return;
            }
        }
        if(validations){
            clearTimeout(this.validationTimer)
            this.validationTimer = setTimeout(() => {
                this.validator.validate(name, val)
            }, 500)
        }

        this.setState({
            inputValue : val
        })
        //clear backend error
        errorActions.clearFieldError({id});

        /* Converting currency on change to show real-time formatted value for modals such as UPS payment invoice */
        const numericValue = val ? convertCurrencyToNumber(val) : val;
        if(onChange) onChange({ target : { value : numericValue, name : e.target.name, type: e.target.type } })
    }
    onBlur(e){
        const {onBlur, name} = this.props;
        var value = e.target.value;
        
        const numericValue = value ? convertCurrencyToNumber(value) : value;
        const inputValue = numericValue ? this.formatAmount(numericValue) : value;
        
        this.setState({ inputValue: inputValue });
        
        if(onBlur) 
            onBlur({value: numericValue, name: name});
    }

    onFocus() {
        const {value} = this.props;
        const inputValue = value ? this.formatAmount(value) : value;  
        this.setState({ inputValue: inputValue });
      }

    formatAmount(number) {
        const {intl, maxCurrencyDigits} = this.props;
        return formatAmount(number, maxCurrencyDigits, intl);
    }

    render(){
        const {name, id, label, labelRightElement, hint, value, onChange, onBlur, tabIndex, containerClass, className,
            intl, labelClass, maxLength, errors, ownVState, required, disabled, isNegative, hideErrorMessages = false} = this.props;
        
        const { inputValue } = this.state;
       
        const error = errors && errors.find(e => e.split('.')[0] === name );
        let messages = [];
        //backend error
        if(error) messages.push(error)
        //frontend validation errors
        if(ownVState && ownVState.messages) messages = messages.concat(ownVState.messages)
        
        return (
                <React.Fragment>
                    <MDBInput
                        name={name}
                        id={id || name}
                        type={'text'}
                        label={<span>
                            <span>{label}</span>
                            <span>{required && "*"}</span>
                            {labelRightElement && <span>&nbsp;&nbsp;{labelRightElement}</span>}
                        </span>}
                        value={inputValue}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus.bind(this)}
                        hint={hint ? intl.formatMessage({ id: hint }) : ''}
                        disabled={disabled}
                        icon={isNegative?'minus':undefined}
                        containerClass={containerClass}
                        className={classNames(className,  {'form-control':true, "is-invalid": (messages.length > 0), 'negative': isNegative})}
                        tabIndex={tabIndex}
                        labelClass={labelClass || ''}
                        maxLength={maxLength}
                        aria-required={!!required}
                    />
                    {!hideErrorMessages && <div className={containerClass}>
                        <ValidationErrors name={name} messages={messages} values={{label}} intl={intl}/>
                    </div>}
                </React.Fragment>
        )
    }
}
function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState:state.validation.vState[ownprops.name]
        }: {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CurrencyFieldIntl));