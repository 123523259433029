import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { get as lodashGet } from 'lodash';
import CustomDateRangePicker from "../../../components/UPS/CustomDateRangePicker";
import PaymentHistoryCalendarInfoPanel from "../../../components/UPS/PaymentHistoryCalendarInfoPanel";
import { FormattedMessage, injectIntl } from 'react-intl';
import PaymentHistoryCriteriaModal from '../Modal/UPSPaymentHistoryCriteriaModal';
import * as identityActions from "../../../actions/identity-action";
import * as paymentHistoryActions from "../../../actions/payment-history-action";
import { formatDateRangeString } from "../../../utils/invoice-utils";
import { getCalendarSavedPreferences, formatToLocalDate, formatDate, formatCurrency, getKeyByBU } from "../../../utils/ups-utils";
import produce from "immer";
import {
    formatPaymentMethod,
    returnAccountNumberType,
    getSearchableStringFromMultipleInvoices,
    returnInvoiceNumber,
    returnCurrencyCode,
    combinePlanOrAccountNumberStr,
    getReferenceNumber,
    isReturnedPayment,
    returnSchedule, isPaymentMadeByCurrentUser
} from "../../../utils/payment-history-util";

import ColumnSettingsModal from "../Modal/ColumnSettingsModal";
import ViewPaymentModal from "../Modal/ViewPaymentModal";
import ViewChargebackHistoryModal from "../Modal/ViewChargebackHistoryModal";
import moment from 'moment';
import ExportDataModal from '../../../components/UPS/Modal/ExportDataModal';
import { SCHEDULED, VIEW_PAYMENT, CANCEL_PAYMENT, SCHEDULED_PAYMENT, CHANNEL_CODE_EXT, VIEW_CHARGEBACK_HISTORY, PAGE_SIZE } from "../../../constants/payment-history";
import FilteredDataTableV3, { INITIAL_TABLE_STATE } from "../../DataTableV3/DataTableV3FilterWrapper";
import { defaultButtonProps, exportData, SORTED_DSC } from "../../DataTableV3/DataTableV3-utils";
import { calendarIcon } from "../../../assets/images/icon/datatableIcons";
import * as R from "ramda";
import {getSessionFilter, partiallyClearSessionFilter, setSessionFilter} from "../../../utils/utils";

const pickAccountDetailsForChargeback = R.map(R.pick(['accountNumber', 'recordType', 'planNumber']));

class PaymentHistoryTable extends Component {
    constructor(props) {
        super(props);
        const { preferences, businessUnit, country } = this.props;
        const filters = lodashGet(preferences, 'paymentHistorySearchCriteria', {});
        const sessionFilters = getSessionFilter('paymentHistorySearchCriteria', businessUnit, country)
        const { selectedStartDate, selectedEndDate, calendarOptionType } = getCalendarSavedPreferences(sessionFilters ? {paymentHistorySearchCriteria:sessionFilters} : preferences, 'paymentHistorySearchCriteria', 'paymentHistoryDate');

        const fullFilters = {
            calendarOptionType,
            paymentHistoryDate: { dateBegin: selectedStartDate, dateEnd: selectedEndDate },
            ...sessionFilters ?? filters
        }
        this.state = {
            loaded: false,
            data: null,
            exportDataModal: false,
            viewPaymentModal: false,
            filterModal: false,
            columnSettingsModal: false,
            showDatePickerModal: false,
            viewChargebackHistoryModal: false,
            selectedStartDate, //currently selected values within calendar modal
            selectedEndDate,
            selectedCalendarOptionType: calendarOptionType,
            calendarOptionType, //currently set calendar settings on the table
            calendarStartDate: { ...selectedStartDate },
            calendarEndDate: { ...selectedEndDate },
            paymentHistoryList: [],
            paymentHistoryFilters: fullFilters,
            pageSize: PAGE_SIZE,
            clearExisting: true,
            allRecordsLoaded: true,
            isFilterEnable: this.verifyIfFilterApplied(fullFilters),
            tableState: INITIAL_TABLE_STATE
        };
    }

    componentDidMount() {
        this.doPaymentHistorySearch(this.state.paymentHistoryFilters);
    }

    doPaymentHistorySearch = (filters = {}) => {
        const { paymentHistoryActions, merchant } = this.props;
        const { pageSize, paymentHistoryList, clearExisting } = this.state;
        const dataCount = paymentHistoryList.length;
        if (!clearExisting && dataCount > 0) {
            const lastRecord = paymentHistoryList[dataCount - 1];
            const paginationId = lastRecord.id;
            const paginationPaymentDate = lastRecord.paymentDate;
            filters = { ...filters, pageSize, paginationId, paginationPaymentDate };
        } else {
            filters = { ...filters, pageSize };
        }
        paymentHistoryActions.getPaymentHistoryList(filters, (paymentHistory) => {
            paymentHistory = paymentHistory.map(paymentObj=>{
                paymentObj.currencyDisplay = merchant.currencyDisplay;
                return paymentObj;
            });
            this.setState({
                paymentHistoryList: clearExisting ? paymentHistory: [...paymentHistoryList, ...paymentHistory],
                allRecordsLoaded: (pageSize === paymentHistory.length) ? false : true,
                loaded: true,
                clearExisting: true,
            })
        });
    }

    showDatePicker = () => {
        if(this.state.calendarOptionType === 'CUSTOM'){
        this.setState({ showDatePickerModal: !this.state.showDatePickerModal, selectedCalendarOptionType: this.state.calendarOptionType, selectedStartDate: moment(this.state.calendarStartDate), selectedEndDate:moment(this.state.calendarEndDate) });
        }else{
            this.setState({ showDatePickerModal: !this.state.showDatePickerModal, selectedCalendarOptionType: this.state.calendarOptionType, selectedStartDate: null, selectedEndDate:null });
        }
    }

    handlePaymentHistoryCalendarSearch = (filter) => {
        const { showDatePickerModal, paymentHistoryFilters, selectedStartDate, selectedEndDate, selectedCalendarOptionType } = this.state;
        const { identityActions, businessUnit, country} = this.props;
        const { saveSearchCriteria } = filter;

        if (selectedCalendarOptionType === 'CUSTOM' && !(selectedStartDate && selectedEndDate)) return;
        const newFilter = {
            ...paymentHistoryFilters,
            calendarOptionType: selectedCalendarOptionType,
            paymentHistoryDate: { dateBegin: selectedStartDate, dateEnd: selectedEndDate }
        }
        setSessionFilter('paymentHistorySearchCriteria', newFilter, businessUnit, country)
        this.setState(() => ({
            paymentHistoryFilters: newFilter,
            calendarStartDate: selectedStartDate,
            calendarEndDate: selectedEndDate,
            calendarOptionType: selectedCalendarOptionType
        }), () => {
            const { paymentHistoryFilters } = this.state;
            if (saveSearchCriteria) identityActions.saveIdentityPreferences({ paymentHistorySearchCriteria: paymentHistoryFilters }, 'tableSearchCriteria');
            this.doPaymentHistorySearch(paymentHistoryFilters);
        });
        if (showDatePickerModal) this.showDatePicker();
    }

    handleCalendarChange = (calendarOpts) => {
        const { calendarOptionType } = calendarOpts;
        this.setState({
            selectedStartDate: null,
            selectedEndDate: null,
            selectedCalendarOptionType: calendarOptionType ?? 'AVAILABLE'
        });
    }

    runFilter = (filter) => {
        const { paymentHistoryFilters } = this.state;
        const {businessUnit, country} = this.props;
        const newFilter = {
            ...paymentHistoryFilters,
            ...filter,
        }
        setSessionFilter('paymentHistorySearchCriteria', newFilter, businessUnit, country)
        this.setState(() => ({
            paymentHistoryFilters: newFilter,
            isFilterEnable: this.verifyIfFilterApplied(newFilter)
        }), () => {
            const { paymentHistoryFilters } = this.state;
            this.doPaymentHistorySearch(paymentHistoryFilters);
        })
    }

    clearFilter = () => {
        const { businessUnit, country } = this.props
        const { calendarOptionType, paymentHistoryDate } = this.state.paymentHistoryFilters
        partiallyClearSessionFilter('paymentHistorySearchCriteria',businessUnit, country,['calendarOptionType','paymentHistoryDate'])
        this.setState(produce(newState => {
            newState.tableState.searchQuery = ''
            newState.paymentHistoryFilters = { calendarOptionType, paymentHistoryDate } //only clear non-calendar filters
            newState.isFilterEnable = false
        }), () => {
            const { paymentHistoryFilters } = this.state;
            this.doPaymentHistorySearch(paymentHistoryFilters);
        })
    }

    verifyIfFilterApplied(filters = {}) {
        // Array - paymentStatus, paymentMethod, schedule, Object - planOrAccountNumber
        const { paymentStatus, paymentMethod, schedule, planOrAccountNumber } = filters;
        try {
            return (Array.isArray(paymentStatus) && paymentStatus.length > 0)
                || (Array.isArray(paymentMethod) && paymentMethod.length > 0)
                || (Array.isArray(schedule) && schedule.length > 0)
                || (planOrAccountNumber instanceof Object)
        } catch (e) {
            return false;
        }
    }

    getReturnedPaymentRecord = (payment) => {
        const { referenceId, invoices } = payment;   // only called for non Ext Channel Payments that have referenceId.
        const { paymentHistoryActions, merchant } = this.props;
        const accounts  = pickAccountDetailsForChargeback(invoices);

        paymentHistoryActions.getChargebackRecordsByReference({referenceId, accounts}, (records) => {
            let recordsData = JSON.parse(JSON.stringify(records)).map((recordObj)=>{
                recordObj.currencyDisplay = merchant.currencyDisplay;
                return recordObj;
            })
            this.toggleModal('viewChargebackHistory', recordsData, VIEW_CHARGEBACK_HISTORY);
        })
    }

    toggleModal = (modalName, data, modalType) => {
        this.setState({
            data,
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`],
            modalType
        })
    };

    handleUpdateColumnSettings = () => {
        const { preferences } = this.props;
        this.toggleModal('columnSettingsModal');
        let filters = lodashGet(preferences, 'paymentHistorySearchCriteria', {});
        this.doPaymentHistorySearch(filters);
    }

    handleCancelModalSuccess = (id) => {
        /*const index = this.state.paymentHistoryList.findIndex(item => item.id == id)
        this.setState(produce(newState=>{
            newState.paymentHistoryList[index].paymentStatus = 'VOID';
        }))*/
        this.doPaymentHistorySearch(this.state.paymentHistoryFilters)
    }

    getDateRangeString() {
        const { intl } = this.props;
        const { calendarOptionType, calendarStartDate, calendarEndDate } = this.state;
        return formatDateRangeString(intl, calendarOptionType, calendarStartDate ? moment(calendarStartDate) : '', calendarEndDate ? moment(calendarEndDate) : '')
    }

    getCalendarButtonText() {
        return <><span><span id="datatable-date-selection" className="datatable-date-selection">{this.getDateRangeString()}</span> {calendarIcon}</span></>
    }

    renderDateRangePicker = () => {
        const { intl } = this.props;
        const { handlePaymentHistoryCalendarSearch, handleCalendarChange, showDatePicker } = this;
        const { selectedStartDate, selectedEndDate, selectedCalendarOptionType, showDatePickerModal, paymentHistoryFilters } = this.state;
        return <CustomDateRangePicker
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            handleDateChange={({ startDate, endDate }) => {
                this.setState({ selectedStartDate: startDate, selectedEndDate: endDate, selectedCalendarOptionType: 'CUSTOM' })
            }}
            autoFocusEndDate={false}
            calendarInfoPosition="after"
            showDefaultInputIcon
            showDatePicker={showDatePicker}
            showDatePickerTest={showDatePickerModal}
            renderCalendarInfo={() => (
                <PaymentHistoryCalendarInfoPanel
                    handleSearch={(filter) => {
                        handlePaymentHistoryCalendarSearch(filter)
                    }}
                    showDatePicker={showDatePicker}
                    filters={paymentHistoryFilters}
                    intl={intl}
                    calendarOptionType={selectedCalendarOptionType}
                    handleCalendarChange={handleCalendarChange}
                />
            )}
            dateRangeString={this.getDateRangeString()}
        />
    }

    render() {
        const { showDatePicker, runFilter, clearFilter, toggleModal } = this;
        const { defaultButtonProps, settings, intl, caption } = this.props;
        const { exportDataModal, columnSettingsModal, viewPaymentModal, showDatePickerModal, viewChargebackHistoryModal } = this.state; //modals
        const { loaded, tableState, data, filterModal, paymentHistoryList, paymentHistoryFilters, modalType, isFilterEnable, pageSize, allRecordsLoaded } = this.state;
        const { filteredData } = tableState;
        const columns = this.createDatatableCols();
        const formattedPageSize = new Intl.NumberFormat(settings.locale).format((paymentHistoryList.length));

        return (
            <>
                {showDatePickerModal && this.renderDateRangePicker()}
                {loaded && <FilteredDataTableV3
                caption={caption}
                    name={`payment-history-table`}
                    data={paymentHistoryList}
                    columns={columns}
                    buttons={[
                        { type: "filter", action: () => toggleModal('filter') },
                        { ...defaultButtonProps.columnSettings, action: () => toggleModal('columnSettings') },
                        { ...defaultButtonProps.calendar, text: this.getCalendarButtonText(), action: showDatePicker },
                        { type: "print" },
                        { ...defaultButtonProps.export, action: () => toggleModal('exportData') },
                    ]}
                    defaultSorting={'paymentDate'}
                    defaultSortDirection={SORTED_DSC}
                    externalFilterActive={isFilterEnable}
                    onClearFilter={this.clearFilter}
                    tableState={tableState}
                    tableStateAction={(action) => this.setState(action)}
                    searchable
                    mobileCard
                    printable
                    responsive
                    rowHeaderField={'comfirmationNumber'}
                />}
                {filterModal && <PaymentHistoryCriteriaModal
                    key={'parent-history-search-criteria-modal'}
                    isOpen={filterModal}
                    toggleModal={() => toggleModal('filter')}
                    runFilter={runFilter}
                    paymentHistoryFilters={paymentHistoryFilters}
                    clearFilter={clearFilter}
                />}
                <ColumnSettingsModal
                    isOpen={columnSettingsModal}
                    columns={() => this.getDefaultPaymentHistoryColumns()}
                    toggleModal={toggleModal}
                    savedPreferenceType={'paymentHistoryColumnSettings'}
                    modalName={'columnSettings'}
                />
                {viewPaymentModal && <ViewPaymentModal
                caption= {caption}
                    key={'view-payment-modal'}
                    isOpen={viewPaymentModal}
                    data={data}
                    toggleModal={toggleModal}
                    handleSuccess={this.handleCancelModalSuccess}
                    modalName={'viewPayment'}
                    modalType={modalType}
                />}
                {viewChargebackHistoryModal && <ViewChargebackHistoryModal
                    key={'view-chargeback-history-modal'}
                    isOpen={viewChargebackHistoryModal}
                    data={data}
                    toggleModal={toggleModal}
                    handleSuccess={this.handleCancelModalSuccess}
                    modalName={'viewChargebackHistory'}
                />}
                <ExportDataModal
                    key={'export-data-modal'}
                    isOpen={exportDataModal}
                    toggleModal={toggleModal}
                    handleExport={(type) => exportData(filteredData, columns, tableState, document.title, type)}
                    an_label={'export-payment-history-data'}
                />
                {!allRecordsLoaded &&
                    <>
                        <p><FormattedMessage id={'payment-history.load-more.hint'} values={{
                            b: chunks => <b>{chunks}</b>,
                            formattedPageSize
                        }} /></p>
                        <MDBBtn color={'primary'} onClick={() => {
                            this.setState({ clearExisting: false }, () => {
                                const { paymentHistoryFilters } = this.state;
                                this.doPaymentHistorySearch(paymentHistoryFilters);
                            })
                        }}><FormattedMessage id={'invoice.details.load-more.button.label'} /></MDBBtn>
                    </>
                }
            </>
        )
    }

    getDefaultPaymentHistoryColumns = () => {
        const { intl, businessUnit, businessUnitKeys, merchant } = this.props;
        return {
            comfirmationNumber: {
                field: 'comfirmationNumber',
                tdClassName: 'datatable-relative-col',
                label: intl.formatMessage({ id: 'payment-history.history-table.confirmation_number' }),
                display: getReferenceNumber,
                serialize: (data) => {
                    const refNum = getReferenceNumber(data)
                    return refNum ? `${refNum}` : ""
                },
                sortFunc: getReferenceNumber,
                responsivePriority: 1,
                hideable: false,
            },
            planOrAccountNumber: {
                field: 'planOrAccountNumber',
                label: intl.formatMessage({ id: `payment-history.history-table.plan_or_account_number.${businessUnit}` }),
                display: (rowData) => {
                    const accountType = !rowData.isMulti && returnAccountNumberType(rowData?.invoices?.[0], intl);
                    const activityLabel = intl.formatMessage({id: businessUnitKeys('moreActionsLabel')}, {id: rowData.referenceId});
                    
                    return <>
                        {rowData.isMulti
                            ? <div>
                                <MDBBtn aria-label={activityLabel} color={"datatable-text-link"} onClick={() => this.toggleModal('viewPayment', rowData, VIEW_PAYMENT)}>
                                    {intl.formatMessage({ id: "payment-history.multiple" })}
                                </MDBBtn>
                            </div>
                            : <>
                                {!!accountType.planNumber && <div>{accountType.planNumber}</div>}
                                {!!accountType.accountNumber && <div>{accountType.accountNumber}</div>}
                            </>
                        }
                    </>
                },
                serialize: (data) => data.isMulti ? intl.formatMessage({ id: "payment-history.multiple" }) : combinePlanOrAccountNumberStr(returnAccountNumberType(data.invoices?.[0], intl)),
                sortFunc: (data) => data.isMulti ? intl.formatMessage({ id: "payment-history.multiple" }) : combinePlanOrAccountNumberStr(returnAccountNumberType(data.invoices?.[0], intl)),
                responsivePriority: 3,
                hideable: false
            },
            invoiceNumber: {
                field: 'invoiceNumber',
                label: intl.formatMessage({ id: `payment-history.history-table.invoice_number.${businessUnit}` }),
                display: (data) => data.isMulti ? "" : returnInvoiceNumber(data.invoices?.[0]),
                serialize: (data) => data.isMulti ? "" : returnInvoiceNumber(data.invoices?.[0]),
                sortFunc: (data) => data.isMulti ? "z" : returnInvoiceNumber(data.invoices?.[0]),
                responsivePriority: 4,
                hideable: true,
                mobileHidden: true
            },
            schedule: {
                field: 'schedule',
                label: intl.formatMessage({ id: 'payment-history.history-table.schedule' }),
                display: returnSchedule(intl),
                serialize: returnSchedule(intl),
                sortFunc: returnSchedule(intl),
                responsivePriority: 3,
                hideable: true
            },
            paymentAmount: {
                field: 'paymentAmount',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_amount' }),
                display: (data) => formatCurrency(intl, data.totalAmount ?? "", returnCurrencyCode(data), merchant.currencyDisplay),
                serialize: (data) => formatCurrency(intl, data.totalAmount ?? "", returnCurrencyCode(data), merchant.currencyDisplay),
                sortFunc: (data) => data.totalAmount,
                responsivePriority: 6,
                hideable: false,
                thClassName: 'text-right',
                tdClassName: 'text-right'
            },
            paymentMethod: {
                field: 'paymentMethod',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_method' }),
                display: ({ paymentMethod }) => formatPaymentMethod(paymentMethod ?? "", intl),
                serialize: ({ paymentMethod }) => formatPaymentMethod(paymentMethod ?? "", intl),
                sortFunc: ({ paymentMethod }) => formatPaymentMethod(paymentMethod ?? "", intl),
                responsivePriority: 11,
                hideable: true,
                thClassName: 'text-right',
                tdClassName: 'text-right'
            },
            paymentStatus: {
                field: 'paymentStatus',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_status' }),
                display: ({ paymentStatus }) => intl.formatMessage({ id: `payment-history.search-field.status.option.${paymentStatus}` }),
                serialize: ({ paymentStatus }) => intl.formatMessage({ id: `payment-history.search-field.status.option.${paymentStatus}` }),
                sortFunc: ({ paymentStatus }) => intl.formatMessage({ id: `payment-history.search-field.status.option.${paymentStatus}` }),
                responsivePriority: 4,
                hideable: false,
                mobileHidden: true
            },
            paymentDate: {
                field: 'paymentDate',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_date' }),
                display: (data) => data.channel === CHANNEL_CODE_EXT ? formatDate(intl, data.paymentDate || "") : formatToLocalDate(intl, data.paymentDate || ""),
                serialize: (data) => data.channel === CHANNEL_CODE_EXT ? formatDate(intl, data.paymentDate || "") : formatToLocalDate(intl, data.paymentDate || ""),
                responsivePriority: 5,
                hideable: false,
                mobileCardPrimary: true,
                mobileDisplay: (data) => <>
                    {intl.formatMessage({ id: `payment-history.search-field.status.option.${data.paymentStatus}` })} &ndash;
                    {data.channel === CHANNEL_CODE_EXT ? formatDate(intl, data.paymentDate || "") : formatToLocalDate(intl, data.paymentDate || "")}
                </>
            }
        };
    }

    showCancelButtonByChannel(channel) {
        return ![CHANNEL_CODE_EXT, SCHEDULED_PAYMENT].includes(channel);
    }

    createDatatableRowActions = (data, index) => {
        const { intl, permissions, externalId } = this.props;
        let components = [];
        
        const actionLabel  = intl.formatMessage({id: `more-actions.payment-history-label`}, {id : data.referenceId});

        components.push(
            <MDBDropdownItem key={`dropdown-view-payment-history-${data.id}`} id={`dropdown-view-payment-history-${data.id}`} className="text-left" onClick={() => this.toggleModal('viewPayment', data, VIEW_PAYMENT)}>
                {intl.formatMessage({ id: `payment-history.btn-view-payment-history.label` })}
            </MDBDropdownItem>
        );
        if (data.paymentStatus === SCHEDULED && permissions.hasOwnProperty("otp_payment") && this.showCancelButtonByChannel(data.channel) && isPaymentMadeByCurrentUser(externalId, data)) {
            components.push(
                <MDBDropdownItem key={`dropdown-cancel-payment-${data.id}`} id={`dropdown-cancel-payment-${data.id}`} className="text-left" onClick={() => this.toggleModal('viewPayment', data, CANCEL_PAYMENT)}>
                    {intl.formatMessage({ id: `payment-history.btn-cancel-payment.label` })}
                </MDBDropdownItem>
            );
        }
        if (isReturnedPayment(data.paymentStatus) && data.channel !== CHANNEL_CODE_EXT) {
            components.push(
                <MDBDropdownItem key={`dropdown-chargeback-history-${data.id}`} id={`dropdown-chargeback-history-${data.id}`} className="text-left" onClick={() => this.getReturnedPaymentRecord(data)}>
                    {intl.formatMessage({ id: `payment-history.btn-chargeback-history.label` })}
                </MDBDropdownItem>
            );
        }

        return (
            <MDBDropdown>
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" aria-label={actionLabel}>
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    {components}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    };

    getActionColumn = () => {
        const { intl, invoiceMetadata, merchant } = this.props;
        return {
            field: 'actions',
            label: intl.formatMessage({ id: `payment-history.history-table.payment_actions` }),
            className: 'text-center datatable-action-col prevent-toggle no-export',
            sortable: false,
            responsivePriority: 2,
            display: (rowData) => this.createDatatableRowActions(rowData),
            serialize: (data) => {
                //make multi-invoice searchable
                if (data.isMulti) return getSearchableStringFromMultipleInvoices(intl, data.invoices, invoiceMetadata, merchant.currencyDisplay);
                return data.isPlan ? "" : returnInvoiceNumber(data.invoices?.[0]);
            },
            noExport: true
        }
    }

    createDatatableCols = () => {
        const { preferences } = this.props;
        const defaultColumns = this.getDefaultPaymentHistoryColumns()
        const savedColumns = lodashGet(preferences, 'paymentHistoryColumnSettings.savedColumns');
        const customizableCols = savedColumns ? savedColumns.map(name => {
            return (typeof name === 'object') ? defaultColumns[name.name] : defaultColumns[name]
        }) : Object.values(defaultColumns)
        return [
            ...customizableCols,
            this.getActionColumn()
        ];
    }
}


function mapStateToProps(state, ownProps) {
    return {
        settings: state.settings,
        externalId: state.auth.user.externalId,
        preferences: state.identity.preferences,
        permissions: state.auth.user.permissions,
        invoiceMetadata: state.invoice.invoiceMetadata,
        businessUnit: state.config.sessionSelection.businessUnit.name,
        country: state.config.sessionSelection?.merchant?.countryCode,
        defaultButtonProps: defaultButtonProps(ownProps.intl),
        businessUnitKeys: getKeyByBU('paymentHistoryTable')(state.auth.user.selectedPaymentType),
        merchant: state.config.sessionSelection.merchant
    }
}


function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        paymentHistoryActions: bindActionCreators(paymentHistoryActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryTable)));