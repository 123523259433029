import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBSwitch  } from 'mdbreact';
import {FormattedMessage, FormattedDate, FormattedDateParts, FormattedNumber, injectIntl} from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import DisputePackageListTable from "../../DisputePackageListTable/DisputePackageListTable";
import {formatDate, formatCurrency} from '../../../../utils/ups-utils';
import {getStatusCodeLabel,mapErrCode} from '../../../../utils/dispute-utils';
import { zip as lodashZip, get as lodashGet, isUndefined as lodashIsUndefined, isEmpty as lodashIsEmpty} from 'lodash';
import { DISPUTE_DENIED, renderRefundAmountTableRow } from '../../../../utils/dispute-utils';
import {BUSINESS_UNIT} from '../../../../constants/business-unit';

class DisputeDetailsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    createDisputeMessages() {
        const {intl, data, invoiceMetadata:{disputeRules:{errorCodes}}} = this.props;
        const {response} = data;
        const disputeErrMessageArr = mapErrCode({errorCodes: response['disputeMsgCode'],errorMsg: response['disputeMsg']},errorCodes,intl)?.split(";")
        return (
            <tr className={"tr-mobile-stack"}>
                <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.resolution-notes.label'})}</th>
                <td className={"text-left"}>
                    <ul className="dispute-msg-error">
                        {
                            disputeErrMessageArr?.map((msg)=>{
                                return <li key={msg}>{msg}</li>
                            })
                        }
                    </ul>
                </td>
            </tr>
        );
    }

    onDataTableInit = (dataTable) => {
        this.setState({ $dataTable: dataTable });
    }

    createDisputePackageList() {
        const {intl, data} = this.props;
        const {request, response} = data;
        const {disputeDtl: packageList} = response;
        const titleKey = request.reasonCode === '899' ? 'invoice.dispute.view-disputes.refund.title'
        : 'invoice.dispute.view-disputes.dispute.title';
        return ( packageList?.length > 0 && (
                <DisputePackageListTable
                    caption= {titleKey}
                    key="package-list-table"
                    source="package-list-table"
                    packageList={packageList}
                    intl={intl}
                    onDataTableInit={this.onDataTableInit}
                />
            )
        )
    }

    handlePrint = (e) => {
        const { $dataTable } = this.state;

        if ($dataTable) {
            $dataTable.buttons(`.btn-print`).trigger();
        } else {
            window.print();
        }

        e.preventDefault();
    };

    renderCommentsTableRow = (request) => {
        const { intl } = this.props;
        const { reasonCode, customerComments, customerRepComments } = request;
        if (reasonCode === '899') return;

        const comments = customerComments || customerRepComments;

        return (
            <React.Fragment>
                <tr className={"tr-mobile-stack"}>
                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.comments.label'})}</th>
                    <td className={"text-right"}>
                        {comments}
                    </td>
                </tr>
                {this.createDisputeMessages()}
            </React.Fragment>
        )
    };

    renderDisputeDimensions = (disputeDimensions) => {
        if (lodashIsEmpty(disputeDimensions)) {
            return (null);
        }
        const { intl } = this.props;
        const { packageWidth, packageHeight, packageLength, dimensionsUom } = disputeDimensions;
        const uom = dimensionsUom === 'IN' ? '"' : 'cm';

        return (
             <React.Fragment>
                <tr>
                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.width.label'})}</th>
                    <td>{packageWidth ? `${packageWidth}${uom}` : ''}</td>
                </tr>
                <tr>
                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.height.label'})}</th>
                    <td>{packageHeight ? `${packageHeight}${uom}` : ''}</td>
                </tr>
                <tr>
                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.length.label'})}</th>
                    <td>{packageLength ? `${packageLength}${uom}` : ''}</td>
                </tr>
            </React.Fragment>
        )
    };

    renderDisputeWeight = (disputeWeight) => {
      if (lodashIsEmpty(disputeWeight)) {
          return (null);
      }
      const { intl } = this.props;
      const { packageWeight, weightUom } = disputeWeight;

      return (
          <React.Fragment>
            <tr>
                <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.weight.label'})}</th>
                <td>{`${packageWeight} ${weightUom}`}</td>
            </tr>
          </React.Fragment>
      )
    };

    render() {
        const { isOpen, toggleModal, backdrop, data, invoiceMetadata, intl,businessUnit, merchant } = this.props;
        const {request, response} = data;
        const isDataLoaded = Object.keys(data).length && data.hasOwnProperty('request') && data.hasOwnProperty('response') // this is the check we need if we are passing data to this modal is empty object
        const countyCode = lodashGet(request,'countryCode','US')
        const isInternational = !(countyCode === 'US' || countyCode === 'CA')
        const reasonCodesMap = invoiceMetadata?.disputeRules.reasonCodes;
        const header = request?.reasonCode === '899' ? intl.formatMessage({id: 'invoice.dispute.view-disputes.refund.title'})
            : intl.formatMessage({id: 'invoice.dispute.view-disputes.dispute.title'});
        const referenceNumber = lodashGet(data, 'request.trackingNbr') || lodashGet(data, 'request.pickupReqNbr') || lodashGet(data, 'request.invoiceNbr') || null;

        if (isOpen) {
            const modalHeading = `${header} #${data.disputeId}`;
            return (
                <ReactPortalModal isOpen={isOpen} an_label="dispute-details">
                    <MDBModal id="dispute-details-modal" isOpen={isOpen} toggle={() => toggleModal('disputeDetails')} size={'lg'} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={modalHeading}>
                        {isDataLoaded ? <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: header })} toggle={() => toggleModal('disputeDetails')}>{modalHeading} </MDBModalHeader>: null}
                        <MDBModalBody>
                                {!isDataLoaded ? <p className='dispute-msg-error error-page'>{intl.formatMessage({id: 'invoice.dispute.view-disputes.details-error.label'})}</p>:null} 
                                {isDataLoaded ? <div className={"dispute-summary-table-container"}>
                                {<h1 className={"d-print-block d-none"}>{intl.formatMessage({id: 'invoice.dispute.view-disputes.dispute.title'})} #{data.disputeId}</h1>}
                                {<MDBTable className={"table-two-col"} id={"dispute-summary-table"}>
                                <MDBTableBody>
                                <tr>
                                    <th>{intl.formatMessage({id: 'invoice.dispute.dispute-id.label'})}</th>
                                    <td>{data.disputeId}</td>
                                </tr>
                                <tr>
                                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.reference.label'})}</th>
                                    <td>{data.referenceType && data.referenceDate ?
                                            <React.Fragment>
                                            <FormattedDate
                                            value={data.referenceDate}
                                            year="numeric"
                                            month="short"
                                            day="2-digit"
                                            timeZone="utc"
                                        /> ({data.referenceType})
                                            </React.Fragment>
                                            : referenceNumber}

                                    </td>
                                </tr>
                                <tr>
                                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.created-by.label'})}</th>
                                    <td>{data.createdBy}</td>
                                </tr>
                                <tr>
                                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.created-date.label'})}</th>
                                    <td>{formatDate(intl, data.createdDate)}</td>
                                </tr>
                                <tr>
                                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.status.label'})}</th>
                                    <td>{getStatusCodeLabel(intl)[response.reqStatus]}</td>
                                </tr>
                                <tr>
                                    <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.reason-code.label'})}</th>
                                    <td>{intl.formatMessage({id: reasonCodesMap[request.reasonCode].msgId})}</td>
                                </tr>
                                {response.reqStatus !== DISPUTE_DENIED && businessUnit===BUSINESS_UNIT.EBS &&
                                    <tr>
                                        <th>{intl.formatMessage({id: 'invoice.dispute.view-disputes.refund-method.label'})}</th>
                                        <td>
                                            {isInternational ?
                                            intl.formatMessage({ id: "invoice.disputes.issue-credit-note.label" }) :
                                            intl.formatMessage({ id: "invoice.disputes.credit-next-invoice.label" })}
                                        </td>
                                    </tr>
                                }

                                {this.renderDisputeDimensions(request.disputeDimensions)}
                                {this.renderDisputeWeight(request.disputeWeight)}

                                {response.reqStatus !== DISPUTE_DENIED && renderRefundAmountTableRow(intl, response.refundAmount, response.refundCurrency, merchant.currencyDisplay)}
                                {this.renderCommentsTableRow(request)}

                                </MDBTableBody>
                            </MDBTable> }
                            </div> : null} 
                            {isDataLoaded ? this.createDisputePackageList() : null}
                        </MDBModalBody>
                        <MDBModalFooter>
                            {isDataLoaded ?  <MDBBtn color="primary" onClick={this.handlePrint}><FormattedMessage id={"print.label"}/></MDBBtn>:null }
                            <MDBBtn color="cancel" onClick={() => toggleModal('disputeDetails')}><FormattedMessage id={"btn-close"}/></MDBBtn>

                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        merchant: state.config.sessionSelection.merchant
    };
}

export default injectIntl(withRouter(connect(mapStateToProps, null)(DisputeDetailsModal)));