import * as actionTypes from '../constants/action-types';

export const enroll = (data) => {
    return {
        type: actionTypes.ENROLL,
        data: data
    }
};

export const enrollmentSuccess = (data) => {
    return {
        type: actionTypes.ENROLLMENT_SUCCESS,
        data: data
    }
};

export const cancelEnrollment = (redirectUrl) => {
    return {
        type: actionTypes.ENROLLMENT_CANCEL,
        redirectUrl: redirectUrl
    }
};

export const restartEnrollment = () => {
    return {
        type: actionTypes.ENROLLMENT_RESTART
    }
};