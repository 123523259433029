export const EXPORT = '100';
export const CREDIT_NOTE = '101';
export const DEBIT_NOTE = '102';
export const CORRECTIVE_ = '103';
export const DOMESTIC_EXPORT_CPP_PLAN = '110';
export const DOMESTIC_EXPORT_CPP_INVOICE = '111';
export const CBS_PLAN = '120';
export const CBS_INVOICE = '121';
export const IMPORT = '200';
export const SCS_STATEMENT = '500';
export const FREIGHT_PRO = '600';
export const FREIGHT_REGULAR = '601';
export const FREIGHT_BALANCE_DUE = '602';
export const FREIGHT_REINVOICE = '603';
export const FREIGHT_PAST_DUE = '604';
export const SCS_MAIL_INNOVATIONS = '400';
export const SCS_AIR = '700';
export const SCS_OCEAN = '800';
export const SCS_BROKERAGE = '900';
export const IMPORT_CPP_PLAN = '210';
export const IMPORT_CPP_INVOICE = '211';
export const EXPORT_PLAN_CREDIT_NOTE = '131';
export const EXPORT_PLAN_DEBIT_NOTE = '141';
export const EXPORT_PLAN_CORRECTIVE = '251';
export const SCS_MAIL_INNOVATIONS_LPF_INVOICE = '450';
export const SCS_AIR_LPF_INVOICE = '750';
export const SCS_OCEAN_LPF_INVOICE = '850';
export const SCS_BROKERAGE_LPF_INVOICE = '950';
