import React,{useState} from 'react';
import PropTypes from 'prop-types';
import ReactPortalModal from "../../../ReactPortalModal";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import { get as lodashGet } from 'lodash'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn } from 'mdbreact';
import { INVOICE_PENDING_DOWNLOAD_MSG } from "../../../../constants/invoice-constants"

const MessageHeaderBody = ({errorCode, expiryTime, isModalOpen, setIsModalOpen, toggleParentModal, errorMessage, intl}) => {
    if (errorCode === 403 && errorMessage === INVOICE_PENDING_DOWNLOAD_MSG) {
        return(
            <>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: 'invoice.downloadInvoices.pending-unable-title' }) })} toggle={() => {toggleParentModal(); setIsModalOpen(!isModalOpen);}}>
                    <FormattedMessage id={'invoice.downloadInvoices.pending-unable-title'} />
                </MDBModalHeader>
                <MDBModalBody>
                    <FormattedMessage id={'invoice.downloadInvoices.pending-unable-body'} values={{ expiryTime }} />
                </MDBModalBody>
            </>
        )     
    }

    if (errorCode === 413) {
        return(
            <>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: 'invoice.downloadInvoices.unable-title' }) })} toggle={() => {toggleParentModal(); setIsModalOpen(!isModalOpen);}}>
                    <FormattedMessage id={'invoice.downloadInvoices.unable-title'} />
                </MDBModalHeader>
                <MDBModalBody>
                    <FormattedMessage id={'invoice.downloadInvoices.unable-body'} />
                </MDBModalBody>
            </>
        )     
    }

    return(
        <>
            <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: 'invoice.downloadInvoices.unexpected-title' }) })} toggle={() => {toggleParentModal(); setIsModalOpen(!isModalOpen);}}>
                <FormattedMessage id={'invoice.downloadInvoices.unexpected-title'} />
            </MDBModalHeader>
            <MDBModalBody>
                <FormattedHTMLMessage tagName={'p'} id={"invoice.downloadInvoices.unexpected-body"} />
            </MDBModalBody>
        </>
    )
}

const DownloadMessageModal = ({ errorCode, invoiceMetadata, isOpen, toggleParentModal, backdrop, errorMessage }) => {
    const expiryTime = lodashGet(invoiceMetadata, 'pendingDownloadRules.downloadExpiry', 24)
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const intl = useIntl();

    let modalHeading = 'invoice.downloadInvoices.unexpected-title';
    if(errorCode === 403 && errorMessage === INVOICE_PENDING_DOWNLOAD_MSG){
        modalHeading = 'invoice.downloadInvoices.pending-unable-title';
    }
    else if(errorCode === 413){
        modalHeading = 'invoice.downloadInvoices.unable-title';
    }

    return (
        <ReactPortalModal isOpen={isModalOpen} an_label="download-pending">
            <MDBModal id={'invoice-documents-modal'} isOpen={isModalOpen} toggle={() => {toggleParentModal(); setIsModalOpen(!isModalOpen);}} size="lg" backdrop={backdrop} 
                disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                <MessageHeaderBody
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    errorCode={errorCode}
                    expiryTime={expiryTime}
                    toggleParentModal={toggleParentModal}
                    errorMessage={errorMessage}
                    intl={intl}
                />
                <MDBModalFooter>
                    <MDBBtn className="mt-3" id={'download-documents-btn-cancel'} color="primary" onClick={() => {toggleParentModal(); setIsModalOpen(!isModalOpen);}}>
                        <FormattedMessage id={'invoice.download-documents.btn-close.label'} />
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </ReactPortalModal>
    )
};

DownloadMessageModal.propTypes = {
    errorCode: PropTypes.number,
    invoiceMetadata: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleParentModal: PropTypes.func.isRequired,
};

export default DownloadMessageModal;