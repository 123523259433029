/*
 * Copyright (C) 2020 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 6/18/20, 2:47 PM
 * Created by kxian
 *
 */

import React, { Component } from 'react';

import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from "classnames";
import {connect} from "react-redux";
import {SingleDatePicker} from "react-dates";
import $ from 'jquery';
import moment from 'moment';
import attachValidator from "../../utils/validation/attach-validator";
import * as validationActions from "../../actions/validation-action";
import * as errorActions from "../../actions/error-action";
import {bindActionCreators} from "redux";
import {MDBInput} from "mdbreact";
import ValidationErrors from "../ValidationErrors";
import {formatDate} from '../../utils/ups-utils';

const CalendarIcon = (intl) => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className={"react-dates-custom-calendar-icon"} aria-label={intl.formatMessage({ id: 'calendar.icon.label' })}>
        <title><FormattedMessage id="calendar.icon.label" /></title>
        <path
            d="M10.25 14.375h-2.75c-0.313 0-0.625 0.375-0.625 0.688v2.563c0 0.313 0.313 0.625 0.625 0.625h2.563c0.313 0 0.688-0.313 0.688-0.625v-2.563c0.125-0.313-0.188-0.688-0.5-0.688zM17.438 20.938h-2.563c-0.313 0-0.625 0.313-0.625 0.688v2.563c0 0.313 0.313 0.625 0.625 0.625h2.563c0.313 0 0.625-0.313 0.625-0.625v-2.563c0-0.375-0.313-0.688-0.625-0.688zM10.25 20.938h-2.75c-0.313 0-0.625 0.313-0.625 0.688v2.563c0 0.313 0.313 0.625 0.625 0.625h2.563c0.313 0 0.688-0.313 0.688-0.625v-2.563c0.125-0.375-0.188-0.688-0.5-0.688zM24.625 14.375h-2.563c-0.313 0-0.625 0.375-0.625 0.688v2.563c0 0.313 0.313 0.625 0.625 0.625h2.563c0.313 0 0.625-0.313 0.625-0.625v-2.563c0-0.313-0.313-0.688-0.625-0.688zM24.625 20.938h-2.563c-0.313 0-0.625 0.313-0.625 0.688v2.563c0 0.313 0.313 0.625 0.625 0.625h2.563c0.313 0 0.625-0.313 0.625-0.625v-2.563c0-0.375-0.313-0.688-0.625-0.688zM17.438 14.375h-2.563c-0.313 0-0.625 0.375-0.625 0.688v2.563c0 0.313 0.313 0.625 0.625 0.625h2.563c0.313 0 0.625-0.313 0.625-0.625v-2.563c0-0.313-0.313-0.688-0.625-0.688zM30.563 0h-28.813c-0.938 0-1.75 0.813-1.75 1.75v28.5c0 0.938 0.813 1.75 1.75 1.75h28.813c0.938 0 1.75-0.813 1.75-1.75v-28.5c0-0.938-0.813-1.75-1.75-1.75zM2.375 9.25h27.688v20.375h-27.688zM8.313 5.25c0-1.063 0.938-2.063 2.063-2.063s2.125 1 2.125 2.063c0 1.125-1 2.125-2.125 2.125s-2.063-1-2.063-2.125zM19.813 5.25c0-1.063 1-2.063 2.125-2.063s2.063 1 2.063 2.063c0 1.125-0.938 2.125-2.063 2.125-1.313 0-2.125-1-2.125-2.125z"></path>
    </svg>
};

class DateField extends Component{
    constructor(props){
        super(props);
        this.state = { inputValue : null };
        this.onDateChange = this.onDateChange.bind(this)
        this.remountFlag = 0;
        if(this.props.validations) attachValidator.call(this)
    }
    componentDidMount(){
        let {name, validations, date} = this.props;
        const inputValue = date ? moment(date,"YYYY-MM-DD") : date;        
        this.setState({
            inputValue :inputValue
        });

        if(validations) this.validator.register(name, ()=>this.props.date)
    }
    componentWillUnmount(){
        let {name, validations, errorActions, id} = this.props;
        if(validations) this.validator.deregister(name)
        //clear backend error
        errorActions.clearFieldError({id})
    }
    componentDidUpdate(prevProps) {        
        const {date, id, focused, intl} = this.props;
        let formattedDate = moment(date,"YYYY-MM-DD");
        
        if ((!formattedDate || this.oldValue !== formattedDate.format(intl.formatMessage({id: `date-format`}))) && this.remountFlag > 0) {
            $(`#${id}`).val(this.oldValue || "");
            if (this.remount) $(`#${id}`).focus();
            this.remount = false;
        }
        if (prevProps.focused && !focused) this.onBlur();
    }
    onDateChange(date){
        const {intl} = this.props;
        let {restrict, name, validations, errorActions, id} = this.props;
        let inputDate = $(`#${this.props.id}`).val() //only way to get the input value when invalid from this library currently
        if(!date && restrict){
            //restrict enterable characters to those that return true from the restrict() function
            if(restrict(inputDate)) {
                this.oldValue = inputDate;
            } else {
                this.remountFlag++;
                this.remount = true;
            }
        } else {
            this.oldValue = date.format(intl.formatMessage({id: `date-format`}));
        }
        if(validations && !this.remount){
            if(moment(date, intl.formatMessage({id: `date-format`}), true).isValid() || moment(inputDate, intl.formatMessage({id: `date-format`}), true).isValid()){
                clearTimeout(this.validationTimer)
                this.validator.validate(name, date ? date.format(intl.formatMessage({id: `date-format`})) : inputDate)
            } else {
                //user is typing
                clearTimeout(this.validationTimer)
                this.validationTimer = setTimeout(() => {
                    this.validator.validate(name, date ? date.format(intl.formatMessage({id: `date-format`})) : inputDate)
                }, 500)
            }
        }

        this.setState({
            inputValue : date
        })
        //clear backend error
        errorActions.clearFieldError({id})
        this.props.onDateChange(date)
    }
    onBlur() {
        const {validations, name, id, date, errorActions, onBlur} = this.props;
        const inputDate = $(`#${id}`).val(); // only way to get the input value when invalid from this library currently
        
        const {inputValue} = this.state;
        const value = inputValue?.format("YYYY-MM-DD") || inputDate;
        
        if (validations) {
            clearTimeout(this.validationTimer);
            this.validator.validate(name, value);
        }
        // clear backend error
        errorActions.clearFieldError({id});
        return onBlur?.({name, value});
    }
    setInitialVisibleMonth = () => {
        const endOfMonth = moment().endOf('month');
        return moment().isSame(endOfMonth) ? moment().add(1,'d') : moment();
    };
    render(){
        const {name, label, ariaLabel, id, disabled, date, onDateChange, focused, onFocusChange, errors, range, required, ownVState, containerClass, anchorDirection, intl } = this.props;
        const { inputValue } = this.state;

        // as norway correct language-code is "nb-NO" but we are passing "no-NO"
        // needs to pass correct language code "nb-NO" so that calendar gets translated
        let currLocale = intl.locale;
        if (currLocale == "no-NO") {
            currLocale = "nb-NO";
        }

        const error = errors && errors.find(e => e.split('.')[0] === id );
        let messages = [];
        //backend error
        if(error) messages.push(error)
        //frontend validation errors
        if(ownVState && ownVState.messages) messages = messages.concat(ownVState.messages)
        // if type is radio or checkbox, please ensure they each have a unique ID
        return (
            <React.Fragment>
                <div className={containerClass ?? "md-form"}>
                    <label className={"active"}>{<span><span>{label}</span><span>{required&&"*"}</span></span>}</label>
                    <SingleDatePicker
                        key={this.remountFlag}
                        date={inputValue}
                        onDateChange={this.onDateChange} // PropTypes.func.isRequired
                        focused={focused} // PropTypes.bool
                        onFocusChange={onFocusChange} // PropTypes.func.isRequired
                        id={id}
                        disabled={disabled}
                        block={true}
                        numberOfMonths={1}
                        initialVisibleMonth={this.setInitialVisibleMonth}
                        customInputIcon={CalendarIcon(intl)}
                        inputIconPosition={"after"}
                        openDirection={"up"}
                        anchorDirection={anchorDirection || "left"}
                        noBorder={true}
                        hideKeyboardShortcutsPanel={true}
                        displayFormat={intl.formatMessage({id: `date-format` })}
                        placeholder={intl.formatMessage({id: `invoice.date.hint` })}
                        renderMonthElement={({ month }) => moment(month).locale(currLocale).format(intl.formatMessage({id: `calendar-format` }))}
                        ariaLabel={ariaLabel}
                        required={!!required}
                        {...(range? {isOutsideRange: range} : {})}
                    />
                </div>
                <div className={containerClass}>
                    <ValidationErrors name={name} messages={messages} values={{label}}/>
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState:state.validation.vState[ownprops.name]
        }: {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DateField));