import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl} from 'react-intl';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarBrand, MDBNavbarToggler, MDBNavLink, MDBIcon, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import * as settingActions from '../../../actions/setting-action';
import UPSlogo from '../../../assets/images/logo/UPS_logo.svg'

class UPSHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {


            const bgPink = {backgroundColor: '#e91e63'}
            const container = {height: 1300}
        const { layout, settings, settingActions, intl } = this.props;
        
        return (
            <React.Fragment>
                <MDBNavbar dark className={"ups-header-wrapper"} expand="md" scrolling fixed="top"  scrollingNavbarOffset={32}>
                    <div className={"ups-header"}>
                        <MDBNavbarBrand href="/">
                            <div className={"ups-header_logo"}>
                                <img src={UPSlogo} alt={intl.formatMessage({id: "ups.logo"})}/>
                            </div>
                            <div className={"ups-header_title"}><FormattedMessage id='ups.billing-center.label'/></div>
                        </MDBNavbarBrand>
                        {/* <MDBNavbarToggler onClick={ this.onClick } />
                        <MDBCollapse isOpen={this.state.collapse } navbar className={"ups-navItems"}>
                            <MDBNavbarNav left className={"ups-primary-nav"}>
                                <MDBNavItem>
                                    <MDBNavLink to="#"  className={"ups-primary-nav-item"}><FormattedMessage id='ups.home'/></MDBNavLink>
                                </MDBNavItem> */}
                                {/*
                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle color="dropdown-ups">
                                            Invoice
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu basic>
                                            <MDBDropdownItem href="/#/ups/invoice">Company Summary</MDBDropdownItem>
                                            <MDBDropdownItem>Archive History</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle color="dropdown-ups">
                                            Payment
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu basic>
                                            <MDBDropdownItem>One Time Payments</MDBDropdownItem>
                                            <MDBDropdownItem>Payment Activity</MDBDropdownItem>
                                            <MDBDropdownItem>Payment Amounts</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle color="dropdown-ups">
                                            Reporting
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu basic>
                                            <MDBDropdownItem>Standard</MDBDropdownItem>
                                            <MDBDropdownItem>My Customized Reports</MDBDropdownItem>
                                            <MDBDropdownItem>Batch Reports</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="#" className={"ups-primary-nav-item"}>Profile</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="#" className={"ups-primary-nav-item"}>Support</MDBNavLink>
                                </MDBNavItem>
                                
                                <MDBNavItem>
                                    <MDBNavLink to="/ups/enrollment" className={"ups-primary-nav-item"}>Enrollment</MDBNavLink>
                                </MDBNavItem>
                                */}
                                {/*<MDBNavItem>*/}
                                    {/*<MDBNavLink to="/ups/styleshowcase"  className={"ups-primary-nav-item"}>Style Showcase</MDBNavLink>*/}
                                {/*</MDBNavItem>*/}
                            {/* </MDBNavbarNav>
                        </MDBCollapse> */}
                    </div>
                </MDBNavbar>
            </React.Fragment>

        )
    }
}


function mapStateToProps(state, ownProps) {
    const { layout, settings} = state;
    return { layout, settings};
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch)
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSHeader));