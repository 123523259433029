import React, { Component } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

class UPSSideNavFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
           demoDate: ''
        };
    }

    componentDidMount() {
        const date = new Date();
        const demoLastLogin = randomDate(new Date(date.getFullYear(),date.getMonth(), date.getDay()-8), new Date())
        this.setState({
            demoDate: demoLastLogin
        })
    }
    render() {
       const { demoDate } = this.state;

        // const demoLastLogin = randomDate((date.getFullYear(),date.getMonth(), date.getDay()-8), date);
        // console.log(randomDate(new Date(date.getFullYear(),date.getMonth(), date.getDay()-8), new Date()));//

        //const demoLastLogin = 'Sep 27, 2019, 1:08:11 PM'; // this does not correlate with the auth.lastLogin. Just bad naming convention for demo purposes.
        return (
            <React.Fragment>
                <div className="nav-drawer-separator"></div>
                {/*<div className="poweredby">*/}
                    {/*<p className={"last-login"}>*/}
                        {/*<FormattedMessage id="side-nav.last-login" /><br/>*/}
                        {/*<FormattedDate*/}
                            {/*value={demoDate}*/}
                            {/*year="numeric"*/}
                            {/*month="short"*/}
                            {/*day="numeric"*/}
                            {/*hour="numeric"*/}
                            {/*minute="2-digit"*/}
                            {/*second="2-digit"*/}
                        {/*/>*/}
                    {/*</p>*/}
                {/*</div>*/}
            </React.Fragment>
        );
    }
}

export default UPSSideNavFooter;
