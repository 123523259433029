import React from "react";
import {useIntl} from "react-intl";
import {MDBRow, MDBCol} from "mdbreact";

import {formatInvoiceDate, formatCurrency} from "../../utils/invoice-utils";
import {formatPMDesc} from "../../utils/payment-utils";

import DisplayTable from "./DisplayTable";
import PayInvoiceTable from "./PayInvoiceTable";
import Details from "./Details";

// Step 3
export default function PaymentConfirmation({selectedInvoices, paymentMethod, date, onSubmit, onBack, isRefund, paymentSubmitted, caption, currencyDisplay}) {
    const intl = useIntl();

    const invoices = [...selectedInvoices].map(p => p[1]);
    const totalDue = invoices
        .reduce((acc, inv) => acc + (((inv.outstandingAmount > 0) && +inv.outstandingAmount) || 0), 0)
        .toString();
    const totalAmount = invoices
        .reduce((acc, inv) => acc + (+inv.paymentAmount || 0), 0)
        .toString();
    const totalCredit = Math.abs(invoices
        .reduce((acc,inv) => acc + (((inv.paymentAmount < 0) && +inv.paymentAmount) || 0), 0))
        .toString()

    const rows = [
        {
            label: isRefund ? "make-payment.refund.method" : "modal.payment.payment.method",
            value: formatPMDesc(intl)(paymentMethod.methodType)
                + (paymentMethod.cardNickName && ` | ${paymentMethod.cardNickName}` || "")
        },
        {
            label: "modal.payment.payment.date",
            value: date
                ? formatInvoiceDate(intl, date)
                : intl.formatMessage({id: "modal.payment.pay.now"}),
            hide: isRefund
        },
        {
            label: "make-payment.payment-components-table.total-due",
            value: formatCurrency(intl, totalDue, invoices[0].currencyCode, currencyDisplay)
        },
        {
            label: "make-payment.payment-components-table.credited-amount",
            value: formatCurrency(intl, totalCredit, invoices[0].currencyCode, currencyDisplay),
            hide: (totalCredit === "0")
        },
        {
            label: isRefund ? "make-payment.payment-components-table.total-refund" : "make-payment.payment-components-table.total-payment",
            value: formatCurrency(intl, totalAmount, invoices[0].currencyCode, currencyDisplay),
            className: "total-p-line"
        }
    ];

    return (
        <Details
            submitLabel="make-payment.btn.make-payment" onSubmit={onSubmit}
            cancelLabel={paymentSubmitted ? "close.label" : "ups.btn.back.label"}onCancel={onBack} canProceed={!paymentSubmitted}
        >
        <MDBRow>
            <MDBCol size="12">
                <DisplayTable rows={rows}/>
            </MDBCol>
        </MDBRow>

        <MDBRow>
            <MDBCol size="12">
                <PayInvoiceTable caption={caption} invoices={selectedInvoices}/>
            </MDBCol>
        </MDBRow>
    </Details>
    );
}
