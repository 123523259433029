/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/6/19 12:18 PM
 * Created by kxian
 *
 */

import {callApiGet, callApiPost, callApiDelete, fetchWrapper, getFullUrl} from "../utils/utils";
import * as R from "ramda";

const { REACT_APP_API_URL_PREFIX } = process.env;

const formatObj = (resp) => ({parsedBody: resp});

export function validateAccountEligibility(data, apiUrlPrefix, simulateApi) {
    if (simulateApi) {
        return {
            ok: true,
            parsedBody: {
                "validationStatus": "PASSED",
                "data": {
                    "resellerIndicator": "N",
                    "previousAuthenticatedIndicator": "Y",
                    "largeCustomerExclusionIndicator": " ",
                    "billingCenterEligibility": "1" // Set to 1 for force invoice validation, 2 to skip
                }
            }
        };
    }

    const body = {
        countryCode: data.account.countryCode,
        businessUnit: data.account.businessUnit,
        accountNumber: data.account.accountNumber,
        planNumber: data.account.planNumber,
        accountType: data.account.accountType
    };

    const path = body.accountType === 'PLAN'
        ? 'bcPlanEligible'
        : 'bcAccountEligible';

    const url = `${apiUrlPrefix}/accounts/${path}`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json',
            'x-api-token-sub': 'UPS'
        };
    return fetchWrapper(url, method, headers, body);
}

export function validateInvoice(data, apiUrlPrefix, simulateApi) {
    if (simulateApi) {
        return {
            ok: true,
            parsedBody:
                {"validationStatus": "PASSED", data: {}}
        };
    }

    const { accountType } = data.account;

    let body = {
        hid: data.account.hid,
        accountNumber: data.account.accountNumber,
        accountType: data.account.accountType,
        paymentType: data.account.businessUnit,
        countryCode: data.account.countryCode,
        currencyCode: data.account.currencyCode,
        invoiceNumber: data.invoice.invoiceNumber,
        invoiceAmount: data.invoice.invoiceAmountDue || data.invoice.invoiceAmount,
        invoiceDate: data.invoice.invoiceDate,
        ...(data.invoice.invoiceControlId ? { invoiceControlId: data.invoice.invoiceControlId } : {})
    };
    if (accountType === "PLAN") {
        body = {
            ...body,
            planNumber: data.invoice.planNumber,
            planTotalInvoice: data.invoice.planTotalInvoice
        }
    }

    const path = accountType === 'PLAN'
        ? 'planValidation'
        : 'accountValidation';

    const url = `${apiUrlPrefix}/accounts/${path}`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json',
        };
    return fetchWrapper(url, method, headers, body);
}

function formatAccountOrPlanBody (data) {
    let body = {
        accountNumber: data.account.accountNumber ?? data.account.planNumber,
        paymentType: data.account.businessUnit,
        countryCode: data.account.countryCode,
        currencyCode: data.account.currencyCode,
        accountType: data.account.accountType
    }
    if (data.invoice) {
        body = {
            ...body,
            invoiceNumber: data.invoice.invoiceNumber,
            invoiceAmount: data.invoice.invoiceAmountDue || data.invoice.invoiceAmount,
            invoiceDate: data.invoice.invoiceDate,
            planNumber: data.invoice.planNumber,
            planTotalInvoice: data.invoice.planTotalInvoice,
            ...(data.invoice.invoiceControlId ? { invoiceControlId: data.invoice.invoiceControlId } : {})

        }
    }
    return body;
}

const callAddAccount = data => callApiPost({ url: getFullUrl(`/accounts/addAccount`)
                                           , body: formatAccountOrPlanBody(data)});

 export const addAccount = R.compose
 ( R.andThen(formatObj)
   , callAddAccount
   //, R.tap(xs => console.log(`INPUT DATA: ${JSON.stringify(xs)}`))
 );

const callAddPlan = data => callApiPost({ url: getFullUrl(`/accounts/addPlan`)
                                        , body: formatAccountOrPlanBody(data)});

export const addPlan = R.compose
( R.andThen(formatObj)
  , callAddPlan
);

const callGetAccountList = () => callApiGet({ url: getFullUrl(`/accounts/accountList`) });

export const getAccountList = R.compose
(   R.andThen(formatObj)
    , callGetAccountList
);

const resToAccounts = R.filter(
    R.T //no filtering needed at the moment
);

const callGetCompanyAccounts = (companyId) => callApiGet({ url: getFullUrl(`/accounts/companyAccountList`) });

export const getCompanyAccountList = R.compose
( R.andThen(resToAccounts)
    , callGetCompanyAccounts
);

const callGetPlansList = () => callApiGet({ url: getFullUrl(`/accounts/planList`) });

export const getPlansList = R.compose
(   R.andThen(formatObj)
    , callGetPlansList
);

export function getCompanyAccountCount(companyId) {
    const url = getFullUrl(`/accounts/activeAccountsCount`),
    method = 'GET',
    headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };
    return fetchWrapper(url, method, headers);
}

export function addAccountToCompany(data, apiUrlPrefix, companyId, apiToken, simulateApi) {
    if (simulateApi) {
        return {
            // TODO: dunno what to return when we call add account to company
        };
    }

    const body = {
        accountNumber: data.account.accountNumber,
        paymentType: data.account.businessUnit,
        merchantId: data.merchantId,
        xmlFormat: data.xmlFormat,
        externalId: data.externalId,
        countryCode: data.account.countryCode
    };

    const url = `${apiUrlPrefix}/addAccountToCompany/${companyId}`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json',
            'x-api-token-sub': apiToken
        };
    return fetchWrapper(url, method, headers, body);
}

export function addAccountToIdentity(data, apiUrlPrefix, identityId, apiToken, simulateApi) {
    if (simulateApi) {
        return {
            // TODO: dunno what to return when we call add account to company
        };
    }

    const body = {
        id: data.account.id,
        accountNumber: data.account.accountNumber,
        paymentType: data.account.businessUnit,
        merchantId: data.merchantId,
        externalId: data.externalId,
        countryCode: data.account.countryCode
    };

    const url = `${apiUrlPrefix}/addAccountToIdentity/${identityId}`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json',
            'x-api-token-sub': apiToken
        };
    return fetchWrapper(url, method, headers, body);
}

const removeAccountBody = a => ({ accountIds: a.accountIds, deleteReasonCode: a.cancelReason, deleteReasonComments: a.cancelReasonComments, accountType: a.accountType});

const callRemoveAccounts = data => callApiDelete({ url: getFullUrl(`/accounts/removeAccounts`)
                                                , body: removeAccountBody(data)});

export const removeAccounts = R.compose
(   R.andThen(formatObj)
    , callRemoveAccounts
);

function constructParamsList(body) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(body)) {
        params.append(key, value);
    }
    return params;
}

const callGetXmlEnrollment = params => callApiGet({ url: getFullUrl(`/accounts/xmlenrollment?${params.toString()}`)});

export const getXmlEnrollment = R.compose
(   R.andThen(formatObj)
    , callGetXmlEnrollment
    , constructParamsList
)

const callEnrollXml = body => callApiPost({ url: getFullUrl(`/accounts/xmlenrollment`)
                                          , body});

export const enrollXml = R.compose
(   R.andThen(formatObj)
    , callEnrollXml
)

const callUnenrollXml = body => callApiDelete({ url: getFullUrl(`/accounts/xmlenrollment`)
                                            , body});

export const unenrollXml = R.compose
(
    R.andThen(formatObj)
    , callUnenrollXml
)