import {
    PROCESS_PAYMENT,
    GET_PAYMENT,
    GET_PAYMENT_LIST,
    CLEAR_PAYMENT,
    CLEAR_PAYMENT_LIST,
    CANCEL_PAYMENT,
    CANCEL_PAYMENT_SUCCESS,
    CANCEL_PAYMENT_FAILURE
} from "../constants/action-types";
import * as actionTypes from "../constants/action-types";

export const processPayment = (paymentItem) => {
    return {
        type: PROCESS_PAYMENT,
        data: paymentItem
    }
};

export const getPayment = (paymentList) => {
    return {
        type: GET_PAYMENT,
        data: paymentList
    }
};

export const getPaymentList = (paymentList) => {
    return {
        type: GET_PAYMENT_LIST,
        data: paymentList
    }
};

export const clearPayment = () => {
    return {
        type: CLEAR_PAYMENT
    }
};


export const clearPaymentList = () => {
    return {
        type: CLEAR_PAYMENT_LIST
    }
};

export const cancelPayment = (data, callback) => {
    return {
        type: CANCEL_PAYMENT,
        data,
        callback
    }
}

export const cancelPaymentSuccess = (data) => {
    return {
        type: CANCEL_PAYMENT_SUCCESS,
        data
    }
}

export const cancelPaymentFailure = (data) => {
    return {
        type: CANCEL_PAYMENT_FAILURE,
        data
    }
}


/*
 * GET /payment/{paymentId}
 */

export const getPaymentByPaymentId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_PAYMENT_ID,
        payment: data,
        callBack: callBack
    }
};

export const getPaymentByPaymentIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_PAYMENT_ID_SUCCESS,
        data: data
    }
};

export const getPaymentByPaymentIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_PAYMENT_ID_FAILURE,
        data: data
    }
};

/*
 * GET /payment/byReference/{referenceId}
 */

export const getPaymentByReferenceId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_REFERENCE_ID,
        reference: data,
        callBack: callBack
    }
};

export const getPaymentByReferenceIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_REFERENCE_ID_SUCCESS,
        data: data
    }
};

export const getPaymentByReferenceIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_REFERENCE_ID_FAILURE,
        data: data
    }
};

/*
 * GET /payment/byExternal/{externalId}
 * Not sure what External it refers to: e.g. External Identity Id or External Account Id or neither
 */

export const getPaymentByExternalId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_EXTERNAL_ID,
        external: data,
        callBack: callBack
    }
};

export const getPaymentByExternalIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_EXTERNAL_ID_SUCCESS,
        data: data
    }
};

export const getPaymentByExternalFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_BY_EXTERNAL_ID_FAILURE,
        data: data
    }
};


/*
 * GET /payment/list/byIdentity/${identityId}
 */

export const getPaymentListByIdentityId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_ID,
        identity: data,
        callBack: callBack
    }
};

export const getPaymentListByIdentityIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_ID_SUCCESS,
        data: data
    }
};

export const getPaymentListByIdentityIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_ID_FAILURE,
        data: data
    }
};

/*
 * GET /payment/list/byIdentityExternal/${externalId}
 */

export const getPaymentListByIdentityExternalId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID,
        identity: data,
        callBack: callBack
    }
};

export const getPaymentListByIdentityExternalIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID_SUCCESS,
        data: data
    }
};

export const getPaymentListByIdentityExternalIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID_FAILURE,
        data: data
    }
};

/*
 * GET /payment/list/byAccount/${accountId}
 */

export const getPaymentListByAccountId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_ACCOUNT_ID,
        identity: data,
        callBack: callBack
    }
};

export const getPaymentListByAccountIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_ACCOUNT_ID_SUCCESS,
        data: data
    }
};

export const getPaymentListByAccountIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_ACCOUNT_ID_FAILURE,
        data: data
    }
};

/*
 * GET ​/payment​/list​/byAccountExternal​/${externalId}
 */

export const getPaymentListByAccountExternalId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID,
        identity: data,
        callBack: callBack
    }
};

export const getPaymentListByAccountExternalIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID_SUCCESS,
        data: data
    }
};

export const getPaymentListByAccountExternalIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID_FAILURE,
        data: data
    }
};

/*
 * GET /payment/list/byMerchant/{merchantId}
 */

export const getPaymentListByMerchantId = (data,callBack) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_MERCHANT_ID,
        identity: data,
        callBack: callBack
    }
};

export const getPaymentListByMerchantIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_MERCHANT_ID_SUCCESS,
        data: data
    }
};

export const getPaymentListByMerchantIdFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LIST_BY_MERCHANT_ID_FAILURE,
        data: data
    }
};