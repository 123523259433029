import {enableES5, enablePatches} from 'immer';
import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import layoutReducer from './layout-reducer';
import identityReducer from './identity-reducer';
import accountsReducer from './accounts-reducer';
import accountReducer from './account-reducer';
import configReducer from './config-reducer';
import ebillReducer from './ebill-reducer';
import settingReducer from './setting-reducer';
import enrollmentReducer from './enrollment-reducer';
import invoiceReducer from './invoice-reducer';
import modalReducer from './modal-reducer';
import envReducer from './env-reducer';
import errorReducer from './error-reducer';
import storage from 'redux-persist/lib/storage';
import validationReducer from './validation-reducer';
import walletReducer from './wallet-reducer';
import { countryData } from "../constants/country-data";

// Do not move these to index, they will not run in time
enableES5();
enablePatches();


const appReducer = combineReducers({
    config: configReducer,
    identity: identityReducer,
    accounts: accountsReducer,
    account: accountReducer,
    layout: layoutReducer,
    auth: authReducer,
    ebill: ebillReducer,
    settings: settingReducer,
    enrollment: enrollmentReducer,
    invoice: invoiceReducer,
    modal: modalReducer,
    envProps: envReducer,
    error: errorReducer,
    validation: validationReducer,
    wallet: walletReducer
});


const rootReducer = (state, action) => {
    const actions = ['INIT', 'SESSION_TIMEOUT', 'LOGOUT', 'ENROLLMENT_CANCEL', 'REDIRECT_USER'];
    if(actions.indexOf(action.type) > -1){
            let country = {
                code: state.auth.user?.selectedCountry || state.auth.userInfo?.selectedCountry || 'us',
                language : state.auth.user?.locale || state.auth.userInfo?.locale || 'en'
            };
            const upsHostName = state.envProps.upsHostName;
    
            storage.removeItem('persist:root');
            storage.removeItem('csr_token');
            storage.removeItem('tokenStatus');
    
            state = {};
            // is this the right place to redirect?
            if (action.type === 'LOGOUT' || action.type === 'ENROLLMENT_CANCEL') {
                const countryInfo = countryData.find(x => x.code === country.code)
                const languageInfo = countryInfo?.languages.find(x => x.code === country.language);
                if(languageInfo){
                    country.language = languageInfo.id.split("-")[0];
                }
                else{
                    country.language = countryInfo?.languages[0].id.split("-")[0];
                }
    
                window.location = `https://${upsHostName}/lasso/logout?returnto=https%3A%2F%2F${upsHostName}%2F` + country.code.toLowerCase() + '%2F' + country.language + '%2FHome.page';
            }
            if (action.type === 'REDIRECT_USER') {
                //console.log('redirecting user');
                // FIXME http://www.ups.com should not be used as the fallback, is http even valid?
                window.location = action.data.redirectUrl || 'http://www.ups.com';
            }
            if(action.type === 'SESSION_TIMEOUT'){
                window.location =  window.location.origin + `/timeout?state=${window.location.pathname}`;
            }
    }
    return appReducer(state, action);
};

export default rootReducer;
