import React, { useEffect }                     from "react";
import { useHistory }                           from "react-router";
import { useSelector, useDispatch }             from "react-redux";

import { complete3dsWallet }                    from '../api/wallet-api';
import { complete3dsPayment}                    from "../api/otp-api";
import Spinner                                  from '../components/Spinner';
import {complete3ds as complete3dsAction}       from '../actions/config-action';

export default function UPS3DSecureReturn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const redirectResult = params.get('redirectResult');
  const threeDsState = useSelector(state => state.config?.threeDSecureDetails);

  useEffect(() => {
    let closed = false;

    const complete3ds = async () => {
      const completeFunc = threeDsState.type === 'wallet' ? complete3dsWallet : complete3dsPayment;

      const result = await completeFunc({
        redirectResult,
        referenceId: threeDsState.referenceId
      }).catch(e => {
        console.error('3ds return failed', e);
        dispatch(complete3dsAction());
        return e;
      });

      if (threeDsState.redirectLocation === '/ups/billing/wallet') {
        dispatch(complete3dsAction());
      }

      if (!closed) {
        history.replace(threeDsState.redirectLocation, result);
      }
    };
    complete3ds().catch(e => console.error('complete 3ds failed', e));
    return () => closed = true;
  }, []);

  return (
    <>
      <Spinner isSpinning={true}/>
    </>
  )
}
