import React, { Component } from 'react';
import { MDBBtn } from "mdbreact";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

/* Components */
import UPSHeaderSideNavWrapper from '../components/UPS/UPSHeaderSideNavWrapper';
import UPSFooter from '../components/UPS/UPSFooter';
import UPSHeader from '../components/UPS/UPSHeader';
import '../assets/css/ups.scss';
import '../assets/css/ups-table-card.scss';
import '../assets/css/ups-payment-history.scss';
import '../assets/css/ups-payment-method.scss';
import '../assets/css/ups-payment.scss';
import '../assets/css/ups-summary-widget.scss';
import '../assets/css/dashboard.scss'
import '../assets/css/datatable.scss'
import '../assets/css/fallback.scss';
import '../assets/css/advanced-search-only.scss';

import {FormattedMessage} from 'react-intl';
import WelcomeMessageModal from '../components/UPS/Modal/WelcomeMessageModal';
import * as chatbotActions from '../actions/chatbot-action';

class PrivateLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            showWelcomeMessage: false
        };

        this.mainContent = React.createRef();
    }

    componentDidMount() {
        document.body.className += ' ups-style';

        // incase user is not present in auth , dispatch action to initiate sso        
        if (!this.props.auth.user) {
            const pathname = this.props.location.pathname + this.props.location.search;
            // saving originalPath so that after getting data from the service, user gets redirected to the chosen route.
            this.props.history.push({pathname: '/login', originalPath: pathname});
        }
        else{
            if(this.props.storeLoaded){
                if(!this.props.auth.user.lastLogin){
                    this.setState({showWelcomeMessage : true});
                }
                
                // if user refreshes the page, then call this function to show/hide chat window based on the current path.
                this.props.chatbotActions?.updateChatbotTracking();
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('ups-style');
    }

    closeModal = () => {
        this.setState({showWelcomeMessage : false});
    }

    focusMainContent = () => {
        this.mainContent.current?.focus();
    }

    render() {
        const {showWelcomeMessage} = this.state;

        // Check user in auth , if not present redirect at the top level so that no other component is affected
        if (!this.props.auth.user || !this.props.storeLoaded) {
            return (
                <React.Fragment>
                    <UPSHeader key={"main-header"} />
                    <div className={"ups-landing-container"}>
                        <div className="text-center mb-4 mt-5">
                        {!this.props.storeLoaded ? <FormattedMessage id="ups.bc.access" /> : <FormattedMessage id="ups.sso.initiation"></FormattedMessage>}
                        </div>
                    </div>
                    <UPSFooter showLegalLinks={true} />
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <MDBBtn className="sr-skip sr-only sr-only-focusable" onClick={() => this.focusMainContent()} waves={"false"}><FormattedMessage id='skip-to-main-content' /></MDBBtn>
                <UPSHeaderSideNavWrapper key={"main-nav-header"} storeLoaded={this.props.storeLoaded}/>
                <main tabIndex="0" ref={this.mainContent} role="none" id="ups-main-container" className="ups-main logged-in">
                    {/*<UpsPrivateRoutes />*/}
                    {this.props.children}
                </main>
                <UPSFooter showLegalLinks={true} />
                {showWelcomeMessage && <WelcomeMessageModal isOpen={showWelcomeMessage} closeModal={this.closeModal} />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth } = state;

    return {
        auth,
        storeLoaded: state.settings.storeLoaded,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        chatbotActions: bindActionCreators(chatbotActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateLayout));
