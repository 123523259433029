//maximums if no config option for the current merchant is specified
export const MAX_INVOICES = 1500
export const MAX_DUPLICATE_CHECK_INVOICES = 150;

export const INVOICE_DOWNLOAD_CHANNEL_CODES = {
    BC: 'billing-center',
    INVOICE_READY_EMAIL: 'invoice-ready-email'
}

export const TOTAL_INVOICES = 'totals';
export const PAST_DUE_INVOICES = 'pastdue';

export const PAY_LATER_RANGE = '90';
export const INVOICE_PENDING_DOWNLOAD_MSG = "There is already a pending or active download for one of the invoices.";