import React, {useEffect, useMemo, useRef, useState} from "react";
import { default as ReactSelect } from "react-select";
import {useIntl} from "react-intl";
import {identifyAllMatchingCardTypes, PM_TYPE_METADATA} from "../../../utils/payment-utils";
import classNames from "classnames";
import {useValidator} from "../../../utils/validation";
import ValidationErrors from "../../ValidationErrors";
import {useDispatch, useSelector} from "react-redux";
import {clearFieldError} from "../../../actions/error-action";



export const checkShowCardTypeSelect = (e,validator,forcedCardType,cardNumber,pmCategory,supportedCards,setState) =>{
    if(validator.validateGetValue('cardNumber').length === 0 && forcedCardType === undefined){
        //if valid, on blur or tab press we will check to see if multiple card types match what the user entered
        const matchingCardTypes = identifyAllMatchingCardTypes(cardNumber, pmCategory, supportedCards)
        //forcedCardType = undefined = don't show select component, forcedCardType = null = show it, but nothing selected yet
        if(matchingCardTypes.length > 1) {
            e.preventDefault() // since we'll focus on the card type selector anyway when it shows up, we need to prevent the tab or blur event from propagating
            setState((s)=>({...s, matchingCards:matchingCardTypes, forcedCardType: null}))
        }
    }
}

const innerOnChange = (event, props, validator, dispatch, backendErrors) => {
    //performs validation then calls externally defined onchange if it exists
    let {name, id, onChange, validations} = props;
    let val = event.value.trim() === '' ? null : event.value
    if(validations) validator.validate(name, val)
    //clear backend error if present
    if(backendErrors) dispatch(clearFieldError({id}))
    if(onChange) onChange(event);
}

export function CardTypeSelect(props){
    const {cardTypeList, name, cardType: value, label, validations, onChange, autoFocus, required, ...selectProps} = props
    const intl = useIntl()
    const validator = useValidator()
    const backendErrors = useSelector((s)=>s.error?.errors)
    const dispatch = useDispatch();

    const inputRef = useRef()
    const validationData = useRef({})
    validationData.current = {value, validations, label}

    useEffect(()=>{
        if(validations) validator.register(name, validationData)
        if(autoFocus) inputRef.current.focus()
        return () => {
            if(validations) validator.deregister(name)
        }
    }, [])

    const cardTypeOptions = useMemo(()=>{
        return [{ label: selectProps.placeholder,
            options: cardTypeList.map((cardType)=>{
                return {value:cardType, label:
                        <div>
                            <div className={classNames("cardTypeSelectIcon pm-icon", PM_TYPE_METADATA[cardType]?.cardCSS)}/>
                            {intl.formatMessage({id:PM_TYPE_METADATA[cardType]?.label})}
                        </div>
                }
        })}]
    },[cardTypeList])

    return <div className={"cardTypeSelectWrapper"}>
        <ReactSelect
            ref={inputRef}
            className={"cardTypeSelect"}
            options={cardTypeOptions}
            isSearchable={false}
            onChange={(value)=>innerOnChange(value,props,validator,dispatch, backendErrors)}
            aria-required={!!required}
            formatGroupLabel={()=>value && <span>{selectProps.placeholder}</span>}
            styles={{
                groupHeading: (baseStyles) => ({
                   ...baseStyles,
                   textTransform: 'none',
                   color: '#242424',
                   fontSize: '0.875rem',
                   padding: '3px 12px'
                }),
                dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    color: "green",
                    ":hover": {color: "green"}
                }),
                indicatorSeparator: (baseStyles) => ({
                   ...baseStyles,
                    display: 'none'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    transition: 'all .3s',
                    borderRadius: 0,
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                    boxShadow: state.isFocused ? '0 1px 0px 0px #2170f2' : 'none'
                })
            }}
            {...selectProps}
        />
        <ValidationErrors name={name} messages={validator.getVState(name)?.messages ?? []} values={{label}} intl={intl}/>
    </div>
}