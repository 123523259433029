/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on: 05/24/2020
 * Created by: berestet
 *
 */
import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as enrollmentActions from '../actions/enrollment-action';
import * as identityActions from '../actions/identity-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';
import * as enrollmentApi from "../api/enrollment-api";
import * as errorActions from "../actions/error-action";
import * as settingsActions from "../actions/setting-action";
import * as invoiceActions from "../actions/invoice-action";

import {countryCodeToMerchantId, mapRole} from '../utils/utils';
import {checkPlanEnrolled} from '../utils/ups-utils';
import {getSessionSelection} from '../utils/config-utils';

const getEnvProps = (state) => state.envProps;
const getConfig = (state) => state.config;

function* enroll(action) {
    try {

        yield put(layoutActions.showSpinner());
        yield put(errorActions.clearErrors());
        const {data} = action;
        const envProps = yield select(getEnvProps);
        const {apiUrlPrefix, simulateApi} = envProps;
        const config = yield select(getConfig);
        const {merchants} = config;
        const merchantId = countryCodeToMerchantId(merchants, data.account.countryCode);

        console.log('enroll data', data);

        const fullData = {
            account: {...data.account, merchantId, invoiceNumber: data.invoice?.invoiceNumber, planNumber: data.invoice?.planNumber},
            userInfo: data.userInfo,
            companyName: data.companyName
        };

        const response = yield call(enrollmentApi.enroll, fullData, apiUrlPrefix, simulateApi);
        const {parsedBody} = response;

        if (parsedBody) {
            const {validationStatus} = parsedBody;
            if(parsedBody.user && parsedBody.user.role && parsedBody.user.role.code) parsedBody.user.role = mapRole(parsedBody.user.role.code)
            if (validationStatus === 'FAILED') {
                yield put(errorActions.setErrors([parsedBody.data.errorCode]));
                return;
            }
            // check if the account is enrolled in any plans, if so - add a flag to the identity
            const planEnrolled = checkPlanEnrolled(parsedBody.user.accounts);
            yield put(identityActions.setIdentityPlanEnrolled(planEnrolled));

            yield put(identityActions.getAccessibleMerchants());

            if(parsedBody.user.preferences){
                yield put(identityActions.setIdentityPreferences(parsedBody.user.preferences));
            }

            yield put(settingsActions.setLocale(parsedBody.user.locale));
            yield put(enrollmentActions.enrollmentSuccess(parsedBody));
            yield put(invoiceActions.getInvoiceMetadata());
            yield put({type: 'SESSION_SELECTION_CHANGED', sessionSelection: getSessionSelection(parsedBody.user, merchants)});
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}


export function* enrollSaga() {
    yield takeEvery(actionTypes.ENROLL, enroll);
}

export default function* enrollmentSaga() {
    yield all([
        fork(enrollSaga)
    ]);
}
