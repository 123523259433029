/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {
    fetchWrapper,
    fetchDownloadWrapper,
    callApiPost,
    getFullUrl,
    callApiGet,
    callApiDownload
} from '../utils/utils';
import { createInvoiceFilter, prepareInvoiceSummarizers } from '../utils/invoice-utils';

import sampleInvoiceListByIdentity from '../static/json/response/invoice_list_by_identity.json';
import * as R from "ramda";
const _ = require('lodash');

// export function getInvoice(invoiceId, apiUrlPrefix, apiToken) {
//     const headers = {
//         'x-api-token-sub': apiToken,
//         'Content-Type': 'application/json'
//     };

//     return fetchWrapper(`${apiUrlPrefix}/invoice/${invoiceId}`, 'GET', headers);
// }

export const getInvoice = body => callApiPost({ url: getFullUrl('/invoice/getInvoice'), body });

export function getInvoiceList(searchFields, apiUrlPrefix, apiToken) {
    const invoiceFilter = createInvoiceFilter(searchFields);
    delete invoiceFilter.businessUnit;

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/list`, 'POST', headers, invoiceFilter);
}

export function getInvoiceMetadata(apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/metadata`, 'GET', headers);
}

export function getInvoiceAggregate(queryParams, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();
    const { accountNumber, planNumber, planInvoiceNumber, isPlanPage, isDrillMode } = queryParams;
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };
    return fetchWrapper(`${apiUrlPrefix}/invoice/aggregate?${params.toString()}`, 'POST', headers, {
        ...(accountNumber ? { accountNumber } : {}),
        ...(planNumber ? { planNumber } : {}),
        ...(planInvoiceNumber ? { planInvoiceNumber } : {}),
        ...(isPlanPage ? { isPlanPage } : {}),
        ...(isDrillMode ? { isDrillMode } : {}),
    });
}

export const getInvoiceDetails = body => callApiPost({ url: getFullUrl('/invoice/details'), body });

// ({invoice, searchCritiera, conditionals}) {
// const params = new URLSearchParams();

// for (const [key, value] of Object.entries(queryParams)) {
//     params.append(key, value);
// }

// if (conditionals && conditionals.length > 0) {
//     params.append('conditionals', JSON.stringify(conditionals));
// }

// const headers = {
//     'x-api-token-sub': apiToken,
//     'Content-Type': 'application/json'
// }

// return fetchWrapper(`${apiUrlPrefix}/invoice/${invoiceId}/details?${params.toString()}`, 'GET', headers);
// }

export function getInvoiceListByIdentity(identityId, apiUrlPrefix, apiToken) {
    return sampleInvoiceListByIdentity;

    //     const body = {//
    //     };
    //     const url = `${apiUrlPrefix}/invoice/list/byIdentity/${identityId}`,
    //         method = 'GET',
    //         headers = {
    //             'Content-Type': 'application/json',
    //             'x-api-token': apiToken
    //         };
    //     return fetchWrapper(url, method, headers, body);
    //
}

export function getInvoiceDownload(url, searchFields, apiToken) {
    let body = searchFields ? searchFields : {};

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchDownloadWrapper(`${url}`, 'POST', headers, body);
}

const resToInvoiceDownload = response=>response // no parsing currently

const callGetInvoiceDownload = (url, searchFields = {}) => callApiDownload("POST")({
    url: `${url}`,
    body: searchFields
});

export const getInvoiceDownloadPromise = R.compose
( R.andThen(resToInvoiceDownload)
    , callGetInvoiceDownload
);

const resToPlanInvoiceList = response=>response // no parsing currently

const callGetPlanInvoiceList = (searchFields = {}) => callApiPost({
    url: getFullUrl(`/invoice/plan/list`),
    body: createInvoiceFilter(searchFields)
});

export const getPlanInvoiceListPromise = R.compose
( R.andThen(resToPlanInvoiceList)
    , callGetPlanInvoiceList
);

export function getInvoicePendingDownload(url, apiToken, body) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchDownloadWrapper(`${url}`, 'POST', headers, body);
}

export function getSupportingDocs(searchFields, apiUrlPrefix, apiToken) {
    let body = searchFields ? searchFields : {};

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/supportingDocs/list`, 'POST', headers, body);

}

export function getPlanInvoiceList(searchFields, apiUrlPrefix, apiToken) {
    const invoiceFilter = createInvoiceFilter(searchFields);

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/plan/list`, 'POST', headers, invoiceFilter);
}

export function getInvoiceLiterals(languageCode, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();

    if (!languageCode) return { parsedBody: [] };

    params.append('language', languageCode);


    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/literals?${params.toString()}`, 'GET', headers);
}

export function getFilterAggregate(detailType, fields, conditionals, invoiceId, apiUrlPrefix, apiToken,{ accountNumber, recordType, planNumber }) {
    if (!detailType || !fields || !invoiceId) return { parsedBody: [] };
    const body = {
        'detailType': detailType,
        'fields': fields,
        'invoiceId': invoiceId,
        'accountNumber': accountNumber,
        'recordType': recordType,
        'planNumber': planNumber

    };
    if (conditionals && conditionals.length > 0) {
        body.conditionals = JSON.stringify(conditionals);
    }

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/aggregate/field`, 'POST', headers, body);
}

export function getQuickFilterAggregate(url, conditionals, apiUrlPrefix, apiToken, invoiceAccount) {
    if (!url) return { parsedBody: [] };
    const body = {
        'accountNumber': invoiceAccount.accountNumber,
        'recordType': invoiceAccount.recordType,
        'planNumber': invoiceAccount.planNumber

    };
    if (conditionals && conditionals.length > 0) {
        body.conditionals = JSON.stringify(conditionals);
    }

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}${url}`, 'POST', headers, body);
}

const resToInvoiceTotals = (totals)=>totals // no filtering after the query at the moment

const callGetInvoiceTotals = (searchFields) => {
    const params = new URLSearchParams();

    const invoiceFilter = createInvoiceFilter(searchFields);
    const summarizerObj = { ...invoiceFilter, ...prepareInvoiceSummarizers(searchFields) };
    for (const [key, value] of Object.entries(summarizerObj)) {
        params.append(key, value);
    }
    return callApiGet({ url: getFullUrl(`/invoice/stats?${params.toString()}`) });
}

export const getInvoiceTotals = R.compose
( R.andThen(resToInvoiceTotals)
    , callGetInvoiceTotals
);

export function getPayableInvoices(searchFields, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();

    const invoiceFilter = createInvoiceFilter(searchFields);
    const summarizerObj = { ...invoiceFilter, ...prepareInvoiceSummarizers(searchFields) };
    for (const [key, value] of Object.entries(summarizerObj)) {
        params.append(key, value);
    }

    const headers = {
        'x-api-token-sub': apiToken,
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/payable?${params.toString()}`, 'GET', headers);
}



export function getAdvancedSearchList(searchType, searchFields, isBatch, localeSetting, currencyDisplay, apiUrlPrefix, apiToken) {
    let body = {
        queryParams: searchFields || {},
        searchType,
        ...(isBatch ? { isBatch } : {}),
        ...(localeSetting ? { localeSetting } : {}),
        ...(currencyDisplay ? { currencyDisplay } : {})
    };

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/search`, 'POST', headers, body);
}

export function getAdvancedSearchBatchReportFile(objectId, apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
    };
    return fetchDownloadWrapper(`${apiUrlPrefix}/invoice/search/downloads/${objectId}`, 'GET', headers);
}

export function getAdvancedSearchBatchReportList(apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };
    return fetchWrapper(`${apiUrlPrefix}/invoice/search/downloads`, 'GET', headers);
}

export function getDownloadInvoiceList(apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/invoice/pending/download`, 'GET', headers);
}