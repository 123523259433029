import * as actionTypes from '../constants/action-types';

export const setInvoice = (data, callBack) => {
    return {
        type: actionTypes.SET_INVOICE,
        data: data
    }
};

export const getInvoice = (invoice, callback) => {
    return {
        type: actionTypes.GET_INVOICE,
        invoice,
        callback
    }
};

export const getInvoiceSuccess = (data) => {
    return {
        type: actionTypes.GET_INVOICE_SUCCESS,
        data: data
    }
};

export const getInvoiceFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICE_FAILURE,
        data: data
    }
};

export const getInvoiceList = (filters, callback) => {
    return {
        type: actionTypes.GET_INVOICE_LIST,
        filters,
        callback
    };
};

export const getInvoiceListSuccess = (data) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_SUCCESS,
        data
    };
};

export const getInvoiceListFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_FAILURE,
        data
    };
};

export const getInvoiceDetails = ({invoice, searchCriteria, conditionals}, callback) => {
    return {
        type: actionTypes.GET_INVOICE_DETAILS,
        callback,
        invoice,
        searchCriteria,
        conditionals
    };
};

export const getInvoiceDetailsSuccess = (detailType, data) => {
    return {
        type: actionTypes.GET_INVOICE_DETAILS_SUCCESS,
        detailType,
        data
    };
};

export const getInvoiceDetailsFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICE_DETAILS_FAILURE,
        data
    };
};

export const getInvoiceMetadata = () => {
    return {
        type: actionTypes.GET_INVOICE_METADATA
    };
};

export const getInvoiceMetadataFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICE_METADATA_FAILURE,
        data
    };
};

export const getInvoiceMetadataSuccess = (data) => {
    return {
        type: actionTypes.GET_INVOICE_METADATA_SUCCESS,
        data
    };
};

export const getInvoiceListByIdentity = (identity,callBack) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_BY_IDENTITY,
        identity: identity,
        callBack: callBack
    }
};

export const getInvoiceListByIdentitySuccess = (data) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_BY_IDENTITY_SUCCESS,
        data: data
    }
};

export const getInvoiceListByIdentityFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_BY_IDENTITY_FAILURE,
        data: data
    }
};

export const setInvoiceFilters = (data) => {
    return {
        type: actionTypes.SET_INVOICE_FILTERS,
        data: data
    }
};

export const clearInvoiceFilters = () => {
    return {
        type: actionTypes.CLEAR_INVOICE_FILTERS
    }
};

export const getInvoiceDownload = (url, searchCriteria) => {
    return {
        type: actionTypes.GET_INVOICE_DOWNLOAD,
        url: url,
        searchCriteria
    }
}

export const getInvoicePendingDownload = (url, body) => {
    return {
        type: actionTypes.GET_INVOICE_PENDING_DOWNLOAD,
        url: url,
        body
    }
}

export const getInvoiceDownloadSuccess = (data) => {
    return {
        type: actionTypes.GET_INVOICE_DOWNLOAD_SUCCESS,
        data: data
    }
}

export const getInvoiceDownloadFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICE_DOWNLOAD_FAILURE,
        data: data
    }
}

export const getPastDueInvoices = () => {
    return {
        type: actionTypes.GET_PAST_DUE_INVOICES,
    }
}

export const getPastDueInvoicesSuccess = (data) => {
    return {
        type: actionTypes.GET_PAST_DUE_INVOICES_SUCCESS,
        data: data
    }
}

export const getPastDueInvoicesFailure = (data) => {
    return {
        type: actionTypes.GET_PAST_DUE_INVOICES_FAILURE,
        data: data
    }
}

export const getPastDuePlanInvoices = (isDrillMode, additionalFilters) => {
    return {
        type: actionTypes.GET_PAST_DUE_PLAN_INVOICES,
        isDrillMode,
        additionalFilters
    }
}

export const getPastDuePlanInvoicesSuccess = (data) => {
    return {
        type: actionTypes.GET_PAST_DUE_PLAN_INVOICES_SUCCESS,
        data: data
    }
}

export const getPastDuePlanInvoicesFailure = (data) => {
    return {
        type: actionTypes.GET_PAST_DUE_PLAN_INVOICES_FAILURE,
        data: data
    }
}

export const getPlanInvoiceList = (filters, callback) => {
    return {
        type: actionTypes.GET_PLAN_INVOICE_LIST,
        filters,
        callback
    };
};

export const getPlanInvoiceListSuccess = (data) => {
    return {
        type: actionTypes.GET_PLAN_INVOICE_LIST_SUCCESS,
        data
    };
};

export const getPlanInvoiceListFailure = (data) => {
    return {
        type: actionTypes.GET_PLAN_INVOICE_LIST_FAILURE,
        data
    };
};

export const setPlanInvoiceFilters = (data) => {
    return {
        type: actionTypes.SET_PLAN_INVOICE_FILTERS,
        data: data
    }
};

export const clearPlanInvoiceFilters = () => {
    return {
        type: actionTypes.CLEAR_PLAN_INVOICE_FILTERS
    }
};

export const setInvoiceDetailsFilters = (detailType, data) => {
    return {
        type: actionTypes.SET_INVOICE_DETAILS_FILTERS,
        detailType,
        data
    }
};

export const clearInvoiceDetailsFilters = (detailType, data) => {
    return {
        type: actionTypes.CLEAR_INVOICE_DETAILS_FILTERS,
        detailType,
        data
    }
};

export const clearInvoiceDetails = () => {
    return {
        type: actionTypes.CLEAR_INVOICE_DETAILS
    }
}
export const getInvoicesTotals = (filters, callback) => {
    return {
        type: actionTypes.GET_INVOICES_TOTALS,
		filters,
        callback
    };
};

export const getInvoicesTotalsSuccess = (data) => {
    return {
        type: actionTypes.GET_INVOICES_TOTALS_SUCCESS,
        data
    };
};

export const getInvoicesTotalsFailure = (data) => {
    return {
        type: actionTypes.GET_INVOICES_TOTALS_FAILURE,
        data
    };
};

export const getPayableInvoices = (filters, callback) => {
    return {
        type: actionTypes.GET_PAYABLE_INVOICES,
		filters,
        callback
    };
};

export const getPayableInvoicesSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYABLE_INVOICES_SUCCESS,
        data
    };
};

export const getPayableInvoicesFailure = (data) => {
    return {
        type: actionTypes.GET_PAYABLE_INVOICES_FAILURE,
        data
    };
};

export const getAdvanceSearchInvoices = (searchType, queryParams, callback) => {
    return {
        type: actionTypes.GET_ADVANCE_SEARCH_INVOICES,
        searchType,
        queryParams,
        callback
    };
};

export const getAdvanceSearchInvoicesSuccess = (data) => {
    return {
        type: actionTypes.GET_ADVANCE_SEARCH_INVOICES_SUCCESS,
        data
    };
};

export const getAdvanceSearchInvoicesFailure = (data) => {
    return {
        type: actionTypes.GET_ADVANCE_SEARCH_INVOICES_FAILURE,
        data
    };
};