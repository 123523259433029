/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {all, call, fork, put, takeEvery, select} from 'redux-saga/effects';
import * as disputeApi from '../api/dispute-api';
import * as disputeActions from '../actions/dispute-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';

const getEnvProps = (state) => state.envProps;

function* getDisputeReasons(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { invoiceId, invoiceDetailId, accountNumber, planNumber, recordType, callback } = action;

        const response = yield call(disputeApi.getDisputeReasons,
            {invoiceId, invoiceDetailId, accountNumber, planNumber, recordType}
            );

        if (response) {
            yield put(disputeActions.getDisputeReasonsSuccess(response));
            if (callback) {
                yield call(callback, response);
            }
        }
    } catch (err) {
        yield put(disputeActions.getDisputeReasonsFailure());
        console.error(err);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* disputeFreeForm(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);
        const { disputeData, callback } = action;

        const body = {
            disputeData,
        };

        const response = yield call(disputeApi.disputeFreeForm, body, apiUrlPrefix, apiToken);
        if (response) {
            yield put(disputeActions.disputeFreeFormSuccess(response));
            if (callback) {
                yield call(callback, response.parsedBody);
            }
        }
    } catch (err) {
        const { callback } = action;
        yield put(disputeActions.disputeFreeFormFailure());
        if (callback) {
            yield call(callback, err);
        }
        console.error(err);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getDisputeSummary(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { callback } = action;
        const response = yield call(disputeApi.getDisputeStats);
        if (response) {
            yield put(disputeActions.getDisputeSummarySuccess(response));
            if (callback) {
                yield call(callback, response);
            }
        }
    } catch (err) {
        yield put(disputeActions.getDisputeSummaryFailure());
        console.error(err);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* submitDispute(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);
        const { identifier, netAmount, disputeData, callback } = action;
        const {invoiceDetailId, invoiceId} = identifier;

        const body = {
            netAmount,
            disputeData
        };

        const response = yield call(disputeApi.submitDispute, invoiceId, invoiceDetailId, body, apiUrlPrefix, apiToken);
        if (response) {
            yield put(disputeActions.submitDisputeSuccess(response));
            if (callback) {
                yield call(callback, response.parsedBody);
            }
        }
    } catch (err) {
        yield put(disputeActions.submitDisputeFailure());
        console.error(err);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getDisputes(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { callback, queryParams } = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const response = yield call(disputeApi.getDisputes, queryParams, apiUrlPrefix, apiToken);

        if (response) {
            yield put(disputeActions.getDisputesSuccess(response));
            if (callback) {
                yield call(callback, response.parsedBody);
            }
        }
    } catch (err) {
        yield put(disputeActions.getDisputesFailure());
        console.error(err);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getDisputesByIdentifier(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { invoiceId, invoiceDetailRef, callback } = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const response = yield call(disputeApi.getDisputesByIdentifier, invoiceId, invoiceDetailRef, apiUrlPrefix, apiToken);

        if (response) {
            yield put(disputeActions.getDisputesByIdentifierSuccess(response));
            if (callback) {
                yield call(callback, response.parsedBody);
            }
        }
    } catch (err) {
        yield put(disputeActions.getDisputesByIdentifierFailure());
        console.error(err);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getDisputeReasonsSaga() {
    yield takeEvery(actionTypes.GET_DISPUTE_REASONS, getDisputeReasons);
}

export function* submitDisputeSaga() {
    yield takeEvery(actionTypes.SUBMIT_DISPUTE, submitDispute);
}

export function* disputeFreeFormSaga() {
    yield takeEvery(actionTypes.DISPUTE_FREE_FORM, disputeFreeForm);
}

export function* getDisputeSummarySaga() {
    yield takeEvery(actionTypes.GET_DISPUTE_SUMMARY, getDisputeSummary);
}

export function* getDisputesByIdentifierSaga() {
    yield takeEvery(actionTypes.GET_DISPUTES_BY_IDENTIFIER, getDisputesByIdentifier);
}

export function* getDisputesSaga() {
    yield takeEvery(actionTypes.GET_DISPUTES, getDisputes);
}

export default function* disputeSaga() {
    yield all([
        fork(getDisputeReasonsSaga),
        fork(submitDisputeSaga),
        fork(disputeFreeFormSaga),
        fork(getDisputeSummarySaga),
        fork(getDisputesByIdentifierSaga),
        fork(getDisputesSaga),
    ]);
}