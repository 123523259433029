import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";

class IE11DisableCacheModal extends Component {
    render() {
        const { isOpen, toggleModal, backdrop, intl} = this.props;
        let currentLocale = intl.locale;
        const modalHeading = "ie11.disable-cache.title";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="ie-11-disable-cache">
                    <MDBModal id="ie11-modal" isOpen={isOpen} toggle={() => toggleModal('ie11DisableCache')} size={'lg'} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('ie11DisableCache')}><FormattedMessage id={modalHeading}/></MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <p><FormattedMessage id={"ie11.disable-cache.may-appear"}/></p>
                                    <h3><FormattedMessage id={"ie11.disable-cache.how-do-i"}/></h3>
                                    <ol className={"ie11-list"}>
                                        <li><FormattedMessage id={"ie11.disable-cache.from-top-right"} values={{bold: msg => <strong>{msg}</strong>, icon: <React.Fragment><img className={"align-top"} aria-hidden="true" src={process.env.PUBLIC_URL + intl.formatMessage({ id: 'ie11.disable-cache.image.step-1' },{
                                                locale: currentLocale
                                            })} aria-hidden={"true"}/><span className="sr-only">gear icon</span></React.Fragment>}}/></li>
                                        <li><FormattedMessage id={"ie11.disable-cache.select-internet-options"} values={{bold: msg => <strong>{msg}</strong>}}/><br/><img className="img-fluid" aria-hidden="true" src={process.env.PUBLIC_URL + intl.formatMessage({ id: 'ie11.disable-cache.image.step-2' },{
                                            locale: currentLocale
                                        })} /><br/><br/>
                                        </li>
                                        <li><FormattedMessage id={"ie11.disable-cache.select-settings"} values={{bold: msg => <strong>{msg}</strong>}}/><br/><img className="img-fluid" aria-hidden="true" src={process.env.PUBLIC_URL + intl.formatMessage({ id: 'ie11.disable-cache.image.step-3' },{
                                            locale: currentLocale
                                        })} /><br/><br/></li>
                                        <li><FormattedMessage id={"ie11.disable-cache.select-everytime"} values={{bold: msg => <strong>{msg}</strong>}}/><br/><img className="img-fluid" aria-hidden="true" src={process.env.PUBLIC_URL + intl.formatMessage({ id: 'ie11.disable-cache.image.step-4' },{
                                            locale: currentLocale
                                        })} /><br/><br/></li>
                                        <li><FormattedMessage id={"ie11.disable-cache.deselect-allow"} values={{bold: msg => <strong>{msg}</strong>}}/><br/><img className="img-fluid" aria-hidden="true" src={process.env.PUBLIC_URL + intl.formatMessage({ id: 'ie11.disable-cache.image.step-5' },{
                                            locale: currentLocale
                                        })} /><br/><br/></li>
                                        <li><FormattedMessage id={"ie11.disable-cache.select-ok"} values={{bold: msg => <strong>{msg}</strong>}}/></li>
                                    </ol>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <MDBBtn color={"primary"} onClick={() => toggleModal('ie11DisableCache')}><FormattedMessage id={'btn-close'} /></MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

export default injectIntl(IE11DisableCacheModal);

