import React, {useEffect, useReducer}         from "react";
import { MDBModal, MDBModalHeader
       , MDBModalBody, MDBModalFooter
       , MDBCard, MDBRow, MDBCol, MDBBtn }    from "mdbreact";
import { FormattedMessage, useIntl }                   from "react-intl";
import { useSelector }                        from "react-redux";
import * as R                                 from "ramda";

import { useValidator }                       from "../../../../utils/validation";
import {
    isDebitCard, isSepa
    , isNonCCPaymentMethod
    , accountSupportsDebitOnly
    , isRealTimeOnlyCard
} from "../../../../utils/payment-utils";
import {overIf}      from "../../../../utils/utils";
import {getPayByTextAccountCCEligibility, handleUpsertPayByText, getPayByTextEligibleAccounts}          from "../../../../api/pay-by-text-api";
import {getSortedWalletPMs} from "../../../../api/wallet-api";
import ReactPortalModal                       from "../../../ReactPortalModal";
import Error                                  from "../../../Error";
import Spinner                                from '../../../Spinner';
import ProgressBar                            from "../../ProgressBar";
import AddPaymentMethodModal                  from "../AddPaymentMethodModal";

import AccountSelection                       from "./AccountSelection";
import PaymentMethodInfo                      from "../../../UPS/CommonPanel/PaymentMethodInfo";
import Confirmation                           from "./Confirmation";
import ThreeDSForm from "../../3dSecureForm";
import CheckboxWithValidation from "../../../CheckboxWithValidation";
import { merchantIdToCountryCode } from "../../../../utils/payByText-utils";
import {MDBModalStepWrapper} from "../../../MDBFix/MDBModalBodyWrapper";
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';

const STEPS =
  [ { id: "acc"
    , label: "modal.view-pay-by-text.subtitle.account-information"
    , btn2: "ups.btn.next.label"
    , btn3: "ups.btn.cancel.label"
    , an_label : "account-information"
    }
  , { id: "pm"
    , submitOnNext: true
    , label: "modal.view-pay-by-text.subtitle.payment-method-information"
    , btn1: "ups.btn.back.label"
    , btn2: "ups.btn.submit.label"
    , btn3: "ups.btn.cancel.label"
    , an_label : "payment-method-information"
    }
  , { id: "conf"
    , label: "modal.view-pay-by-text.subtitle.confirmation"
    , btn2: "close.label"
    , an_label : "confirmation"
    }
  ];

const NO_ACC_STEPS = R.compose
    ( overIf(R.lensIndex(0), R.omit(["btn1"]))
    , R.drop(1)
    ) (STEPS);

const INITIAL_STATE =
  { step: 0 // Modal step (0-indexed)
  , errors: []
  , payByText: {} // Active payByText being built
  , accounts: null // Bound to step 1
  , wallet: [] // Bound to step 2
  , walletModal: false // Step 2's sister modal
  , confirmation: {} // Bound to step 4
  , fetchingWallet: false // Wallet to use for new/updated payByText.
  , fetchingAccounts: false // Accounts for new payByText selection.
  , fetchingAccount: false // Refreshed account with ccEligible when updating a payByText.
  , submitting: false // Create or update an account.
  , threeDSecureFormData: {}
  , authorizePayByText: false
  };

const stepLens =     R.lensProp('step');
const errorsLens =    R.lensProp('errors');
const fetchingWalletLens = R.lensProp('fetchingWallet');
const fetchingAccountsLens = R.lensProp('fetchingAccounts');
const fetchingAccountLens = R.lensProp('fetchingAccount');
const submittingLens = R.lensProp('submitting');
const walletLens = R.lensProp('wallet');
const payByTextPayMethodLens = R.lensPath(['payByText', 'paymentMethod']);
const payByTextAccountLens = R.lensPath(['payByText', 'account']);
const payByTextAccountNumberLens = R.lensPath(['payByText', 'accountNumber']);
const payByTextMerchantIdLens = R.lensPath(['payByText', 'merchantId']);
const payByTextAccountCreditAllowedLens = R.lensPath(['payByText', 'account', 'creditAllowed']);
const walletModalLens = R.lensProp('walletModal');

const stateErrorClear =   R.set(errorsLens, []);
const stateStepForward =  R.over(stepLens, R.inc);
const stateStepBack =     R.over(stepLens, R.dec);

const isFetching = R.anyPass
  ([ R.view(fetchingAccountsLens)
   , R.view(fetchingWalletLens)
   , R.view(submittingLens)
   , R.view(fetchingAccountLens)
  ]);

const isCreatePayByText = R.pathSatisfies(R.isNil, ['payByText', 'id']);

const is3dSecureFlow = R.has('hostedCheckout');

const buildInitialState = R.ifElse
    ( R.propSatisfies(R.isEmpty, 'initialPayByText')
    , R.compose // new payByText
          ( R.set(fetchingAccountsLens, true)
          , R.always(INITIAL_STATE)
          )
    , R.compose // payByText edit
        ( R.set(fetchingAccountLens, true)
        , R.mergeDeepRight(INITIAL_STATE)
        , (payByText) => {return {payByText}}
        , R.prop('initialPayByText')
        )
    );

const filterCreditPayMethods = R.filter(isNonCCPaymentMethod);

const updateEligibleWallet = R.when
  ( R.compose(accountSupportsDebitOnly, R.view(payByTextAccountLens))
  , R.over(walletLens, filterCreditPayMethods)
  );

const defaultWallet = R.propEq('default', true);

const selectDefaultWallet = R.ifElse
    ( R.any(defaultWallet)
    , R.find(defaultWallet)
    , R.head
    );

const loadPayMethodFromWallet = state => R.set(
    payByTextPayMethodLens,
    selectDefaultWallet(state.wallet)
) (state);

// State action functions
const stateActionSubmitted = ({confirmation}) => R.compose
    ( R.over(R.lensProp('confirmation'), c => confirmation ?? c)
    , stateStepForward
    , stateErrorClear
    , R.set(submittingLens, false)
    );

const stateActionFetchFailed = ({error, fetchType}) => R.compose
    ( R.over(errorsLens, R.append (error))
    , state => {
          switch (fetchType) {
            case 'accounts': return R.set(fetchingAccountsLens, false, state);
            case 'account':  return R.set(fetchingAccountLens, false, state);
            case 'wallet':   return R.set(fetchingWalletLens, false, state);
            case 'submit':   return R.set(submittingLens, false, state);
            default: return state;
          }
      }
    );

const stateActionGoNext = R.when
    ( R.propSatisfies(errors => errors.length === 0, 'errors') // block
    , stateStepForward
    );

const stateActionGoPrev = R.compose
    ( stateErrorClear
    , R.when
        ( R.propSatisfies( n => n > 0, 'step')
        , stateStepBack
        )
    );

const stateActionReceiveWallet = ({wallet, merchant}) => R.compose
    ( R.when(isCreatePayByText, loadPayMethodFromWallet)
    , updateEligibleWallet
    , R.over(walletLens, R.filter(R.complement(isRealTimeOnlyCard(merchant))))
    , R.set(fetchingWalletLens, false)
    , R.set(walletLens, wallet)
    );

const stateActionReceiveAccounts = ({accounts}) => R.compose
    ( R.set(fetchingAccountsLens, false)
    , R.set(R.lensProp('accounts'), accounts)
    );

const stateActionFetchWallet = R.when
    ( R.compose
        ( R.complement (R.isNil)
        , R.view(payByTextAccountLens)
        )
    , R.set(fetchingWalletLens, true)
    );

const stateActionSubmit = R.compose
    ( stateErrorClear
    , R.set(submittingLens, true)
    );

const stateActionSelectAccount = ({account}) => R.compose
    ( stateActionFetchWallet
    , R.set(payByTextAccountLens, account)
    , R.set(fetchingAccountLens, false)
    );

const stateActionToggleWallet = R.over(walletModalLens, R.not);

function reducer(state, action) {
    switch (action.type) {
        case 'init':             return buildInitialState (action)
        case 'fetch_wallet':     return stateActionFetchWallet (state);
        case 'receive_wallet':   return stateActionReceiveWallet (action) (state);
        case 'receive_accounts': return stateActionReceiveAccounts (action) (state);
        case 'receive_account':  return stateActionSelectAccount (action) (state);
        case 'submit':           return stateActionSubmit (state)
        case 'submitted':        return stateActionSubmitted (action) (state);
        case 'fetchFailed':      return stateActionFetchFailed (action) (state);
        case 'goNext':           return stateActionGoNext (state);
        case 'goPrev':           return stateActionGoPrev (state);
        case 'selectAccount':    return stateActionSelectAccount (action) (state);
        case 'updatePayByText':   return R.set(R.lensProp('payByText'), action.payByText) (state);
        case 'toggleWallet':     return stateActionToggleWallet (state);
        case 'set_3ds_form':     return { ...state, threeDSecureFormData: action.formData };
        default:                 return state;
    }
}

function AuthCheckbox({data, onChange}) {
  const {authorizePayByText} = data;
  const intl = useIntl();

  return <MDBCol size="12">
      <CheckboxWithValidation id="authorizePayByText" name="authorizePayByText"
          label={intl.formatMessage({id: "pay-by-text.authorize"})}
          value={authorizePayByText || false}
          onChange={e => {
              const {checked} = e.target;
              onChange({...data, authorizePayByText: checked});
          }}
          validations={[["required", "field.authorizePayByText"]]}
      />
  </MDBCol>;
}

export default function AddPayByTextModal(
    { modalId, isOpen, toggleModal, initialPayByText = {}, preSelectedAccount, preSelectedWallet
    , clearPreSelection, addWalletHandler, submit3DSForm, merchants
    }) {
    let payByTextObj = (typeof initialPayByText==='object' && Object.keys(initialPayByText).length) ? { ...initialPayByText, paymentMethod: {...(initialPayByText.paymentMethod), _id: initialPayByText.paymentMethod?.walletId }} : initialPayByText;
    const [state, dispatch] = useReducer(reducer, buildInitialState ({initialPayByText: payByTextObj}));
    const showPrimaryModal = state.walletModal ? false : isOpen;
    const steps = R.isEmpty (initialPayByText) ? STEPS : NO_ACC_STEPS;
    const validator = useValidator();
    const {vState, vFields} = useSelector(s => s.validation);
    const {id: identityId, selectedCountry: country, externalId, companyId} = useSelector(s => s.auth.user);
    const {sessionSelection} = useSelector(s => s.config);
    const {merchant} = sessionSelection;
    const merchantId = merchant.id;
    const intl = useIntl();

    const dispatchError = (error, fetchType) => dispatch({type: "fetchFailed", error, fetchType});
    useEffect(()=>{
        //skip selection of an account if we've pre-selected one (3D secure flow)
        if(preSelectedAccount && !state.fetchingAccounts && state?.accounts && steps[state.step].id === "acc" && !submit3DSForm){
            const account = state.accounts.find((a)=>(a.id === preSelectedAccount))
            if(account) {
                dispatch({type: "selectAccount", account})
                dispatch({type: "goNext"})
            }
        }
    }, [preSelectedAccount, state.fetchingAccounts])
    useEffect(()=>{
        //auto-select wallet entry if we've pre-selected one (3D secure flow)
        if(preSelectedWallet && !state.fetchingWallet && state?.wallet && steps[state.step].id === "pm" && !submit3DSForm){
            const paymentMethod = state.wallet.find(pm => pm._id === preSelectedWallet);
            if(paymentMethod) dispatch({ type: 'updatePayByText', payByText: {...state.payByText, paymentMethod} })
            if(clearPreSelection) clearPreSelection()
        }
    }, [preSelectedWallet, state.fetchingWallet])
    useEffect(() => {
        if (state.fetchingWallet) {
            getSortedWalletPMs(merchant.paymentMethodCategories)({
              intent: 'payment', 
              ...state.payByText.merchantId ? {countryCode: merchantIdToCountryCode(merchants, state.payByText.merchantId)} : {}
            })
            .then(wallet => dispatch({type: 'receive_wallet', wallet, merchant}))
            .catch(({errorCode}) => dispatchError(errorCode, 'wallet'));
        }
    }, [state.fetchingWallet]);

    useEffect(() => {
        if (state.fetchingAccounts) {
          getPayByTextEligibleAccounts()
                .then(accounts =>  dispatch({type: 'receive_accounts', accounts: accounts}))
                .catch(({errorCode}) => dispatchError(errorCode, 'accounts'));
        }
    }, [state.fetchingAccounts]);

    useEffect(() => {
        if (state.fetchingAccount) {
            getPayByTextAccountCCEligibility({
              id: state.payByText.id, 
              countryCode: merchantIdToCountryCode(merchants, state.payByText.merchantId), 
              accountNumber: state.payByText.accountNumber, type: 'ACCOUNT'
            })
            .then(accounts => dispatch({type: 'receive_account', account: R.head(accounts)}))
            .catch((error) => dispatchError(error.errorCode, 'account'));
        }
    }, [state.fetchingAccount]);

    useEffect(() => {
      const submitPayByText = async () => {
        if(state.wallet && state.payByText?.paymentMethod){
          const wallet = state.wallet.find(x => x.id === state.payByText.paymentMethod.walletId);
    
          if(wallet){
            state.payByText.paymentMethod = wallet;
          }
        }
        const countryCode = merchantIdToCountryCode(merchants, state.payByText?.account?.merchantId);
        return await handleUpsertPayByText({...state.payByText, countryCode});
      }
      if (state.submitting) {
          submitPayByText()
            .then(confirmation => dispatch({type: 'submitted', confirmation}))
            .catch(({errorCode}) => dispatchError(errorCode, 'submit'));
      }
    }, [state.submitting])

    const closeModal = () => toggleModal(modalId);
    const submit = () => dispatch({type: 'submit'});

    const nextStep = () => {
        const result = validator.validateAll();
        if (result.messages.length > 0) return;
        if (state.step >= steps.length - 1) return closeModal();

        if (steps[state.step].submitOnNext) submit();
        else dispatch({type: "goNext"});
    };

    const prevStep = () => dispatch({ type: "goPrev" });
  
  const modalLabel = (R.isEmpty(initialPayByText) ? "add-pay-by-text" : "edit-pay-by-text") + `|${steps[state.step].an_label}`;
  const modalHeading = R.isEmpty(initialPayByText) ? "pay-by-text.btn.create-pay-by-text" : "modal.view-pay-by-text.btn.edit-pay-by-text";

    return (
      <>
          {
            !R.isEmpty(state.threeDSecureFormData) &&
              <ThreeDSForm
                  httpmethod={state.threeDSecureFormData.httpMethod}
                  hostedurl={state.threeDSecureFormData.hostedUrl}
                  pareq={state.threeDSecureFormData.PaReq}
                  md={state.threeDSecureFormData.MD}
                  termurl={state.threeDSecureFormData.TermUrl}
                  submit={submit3DSForm}
              />
          }
        <ReactPortalModal isOpen={showPrimaryModal} an_label={modalLabel }>
          <MDBModal isOpen={showPrimaryModal} toggle={closeModal} size="xl" disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
            <MDBModalStepWrapper currentStep={state.step} stepFocusInputIds={['','paymentMethod','']}>
            {isFetching(state) && <Spinner isSpinning={true} />}
            <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({ id: "close.dialog.btn" }, { name: intl.formatMessage({ id: modalHeading }) })} toggle={closeModal}>
              <FormattedMessage id={modalHeading} />
            </MDBModalHeader>
            <MDBModalBody >
              <MDBCard className="mb-4">
                <ProgressBar
                  stepNames={steps.map((s, key) => ({ ...s, key }))}
                  currentStep={state.step}
                  stepId={steps[state.step].id}
                  isShown
                />
              </MDBCard>
              <Error
                errors={state.errors}
                vFields={vFields}
                vState={vState}
              />
              {steps[state.step].id === "acc" && !preSelectedAccount && state.accounts && <AccountSelection
                accountList={state.accounts}
                payByText={state.payByText}
                onChange={account => dispatch({ type: "selectAccount", account })}
              />}
              {steps[state.step].id === "pm" &&
              <div>
                <PaymentMethodInfo
                  wallet={state.wallet}
                  payByText={state.payByText}
                  onChange={payByText => {return dispatch({ type: 'updatePayByText', payByText })}}
                  onAddPM={() => dispatch({ type: "toggleWallet" })}
                  sessionSelection={sessionSelection}
                />
                <div className="pbtauthcheck">
                  <AuthCheckbox data={state.payByText} onChange={payByText => {return dispatch({ type: 'updatePayByText', payByText })}}/>
                </div>
              </div>}

              {steps[state.step].id === "conf" && <Confirmation
                confirmation={{...state.payByText, ...state.payByText?.account, confirmation: state.confirmation}}
                isNew={Object.keys(initialPayByText).length ? false : true}
              />}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBRow>
                <MDBCol size="12" className="modal-button-col">
                  {steps[state.step].btn1 &&
                    <MDBBtnWrapper label={intl.formatMessage({id: steps[state.step].btn1})} color="secondary" className="order-1" onClick={prevStep}>
                    </MDBBtnWrapper>
                  }
                  {steps[state.step].btn2 &&
                    <MDBBtnWrapper label={intl.formatMessage({id: steps[state.step].btn2})} color="primary" className="order-first" onClick={nextStep} >
                      <FormattedMessage id={steps[state.step].btn2} />
                    </MDBBtnWrapper>
                  }
                </MDBCol>
              </MDBRow>
              {steps[state.step].btn3 &&
                <MDBRow>
                  <MDBCol size="12" className="modal-button-col">
                  <MDBBtnWrapper label={intl.formatMessage({id: steps[state.step].btn3})} color="cancel" className="order-last" onClick={closeModal}>
                    </MDBBtnWrapper>
                  </MDBCol>
                </MDBRow>
              }
            </MDBModalFooter>
          </MDBModalStepWrapper>
          </MDBModal>
        </ReactPortalModal>

        {state.walletModal && <AddPaymentMethodModal
          isOpen={state.walletModal}
          toggleModal={() => dispatch({ type: 'toggleWallet' })}
          creditAllowed={R.view(payByTextAccountCreditAllowedLens, state)}
          walletSuccess={pm => {
              addWalletHandler(pm, state.payByText.account.id, state.payByText.id)
              if(is3dSecureFlow(pm)) dispatch({ type: 'set_3ds_form', formData: pm.hostedCheckout });
              else dispatch({ type: 'fetch_wallet'})
          }}
        />}
      </>
    );
}
