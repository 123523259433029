import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {MDBCol, MDBBtn} from "mdbreact";
import CheckboxWithValidation from "../../CheckboxWithValidation";
import {useSelector} from "react-redux";
import * as R from 'ramda';
import {getConfigByBU} from "../../../utils/ups-utils";
import Alert from "../../Alert";

const getUrlPath = R.compose
        ( ({selectedCountry, locale}) => `${selectedCountry}/${locale}`
        , R.over(R.lensProp('selectedCountry'), R.toLower)
        , R.over(R.lensProp('locale'), R.takeWhile(c => c !== '-'))
        );

const agreementConfigs = {
    DD: {},
    SCB:{
        hasLink: false,
        checkboxLabel: "payment-method.scb.authorize.agreement",
        checkboxErrorLabel: "field.authorize"
    }
}

export default function AchAgreementConfirmation ({authorizeDD, onAuthorizeACH, type = "DD"}) {
    const intl = useIntl();
    const {selectedCountry, locale} = useSelector(s => s.auth.user);
    const {hasLink = true, checkboxLabel, checkboxErrorLabel} = agreementConfigs[type]

    const businessUnit = useSelector(s=>s.config.sessionSelection.businessUnit.name);
    const showACHWarning = getConfigByBU(businessUnit)('showACHWarning');
    return (<div>{ selectedCountry === "US" && showACHWarning && hasLink &&
                    <MDBCol size="12">
                        <Alert type='warning' className="ach-debit-block-warning my-4">
                            {intl.formatMessage({ id: "payment-method.echeck.debit-block.line1" })}
                            <ul className="mb-0">
                            <li>{intl.formatMessage({ id: "payment-method.echeck.debit-block.line2a" })}: {intl.formatMessage({ id: "payment-method.echeck.debit-block.line2b" })}</li>
                            <li>{intl.formatMessage({ id: "payment-method.echeck.debit-block.line3a" })}: {intl.formatMessage({ id: "payment-method.echeck.debit-block.line3b" })}</li>
                            </ul>
                        </Alert>
                    </MDBCol>
                }
                <MDBCol size="12">
                    <CheckboxWithValidation name="authorizeDD" id="authorizeDD"
                        containerClass="pt-1 pl-0 m-0"
                        label={intl.formatMessage({ id: checkboxLabel ?? "payment-method.echeck.authorize.agreement" })}
                        value={authorizeDD || false}
                        onChange={e=>onAuthorizeACH(e)}
                        required
                        validations={[
                            ["required", checkboxErrorLabel ?? "field.authorizeAch"]
                        ]}
                    >
                        <FormattedMessage id="payment-method.echeck.authorize.view" values={{
                            action: msg => hasLink && <MDBBtn
                                className="btn-text-link p-0 text-left overflow-visible"
                                onClick={() => window.open(`${window.config.s3HelpUrl}${getUrlPath({selectedCountry, locale})}/terms-and-conditions-no-fee.html`, "_blank")}
                            >{msg}</MDBBtn>
                        }} />
                    </CheckboxWithValidation>
                </MDBCol>
           </div>)  
}