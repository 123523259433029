import React,  { Component } from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactPortalModal from "../../../ReactPortalModal";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBLink  } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIntl from '../../../../components/CheckboxIntl/index';
import * as identityActions from "../../../../actions/identity-action";
import produce from "immer";

/*
* A generic column settings modal that can be used for any datatable columns, don't pass in always-hidden or actions columns.
* The columns will be saved to the backend as an array of column names. Use this array to obtain the column objects in the data table component.
* */

class ColumnSettingsModal extends Component {

    constructor(props) {
        super(props);
        this.defaultColumns = props.columns();
        this.state = {
            selectedColumns: this.initializeColumns()
        };
    }

    initializeColumns(){
        const { preferences, savedPreferenceType, columnCompatibilityFunc } = this.props;
        const savedColumns = preferences?.[savedPreferenceType]?.savedColumns;

        const compatibilityFunc = columnCompatibilityFunc ?? ((name)=>name)

        let selectedColumns = []
        if(savedColumns && Array.isArray(savedColumns) && savedColumns.length > 0){
            selectedColumns = savedColumns.map((data)=>{
                return this.defaultColumns[compatibilityFunc((typeof data === 'object') ? data.name : data)]
            }).filter((column)=>!!column)
        } else {
            selectedColumns = Object.values(this.defaultColumns)
        }
        return selectedColumns
    }

    componentDidUpdate(prevProps){
        if(!prevProps.isOpen && this.props.isOpen){
            this.defaultColumns = this.props.columns();
            this.setState({selectedColumns: this.initializeColumns()})
        }
    }

    handleColumnSelected = e => {
        const inputName = e.target.name;
        const inputChecked = e.target.checked;
        this.setState(produce(draft => {
            if (inputChecked) {
                draft.selectedColumns.push(this.defaultColumns[inputName]);
            } else {
                draft.selectedColumns = draft.selectedColumns.filter(column => column.field !== inputName);
            }
        }));
    };

    shiftSelectedColumn = (e, columnIdx, direction) => {
        e.preventDefault();
        this.setState(produce(draft => {
            const newIdx = columnIdx + direction;
            if (newIdx < 0 || newIdx > draft.selectedColumns.length) return;

            const removed = draft.selectedColumns.splice(columnIdx, 1);
            draft.selectedColumns.splice(newIdx, 0, ...removed);
        }));
    }

    removeSelectedColumn = (e, columnName) => {
        e.preventDefault();
        this.setState(produce(draft => {
            draft.selectedColumns = draft.selectedColumns.filter(column => column.field !== columnName);
        }));
    }

    handleApplyClicked = (e) => {
        const { identityActions, toggleModal, modalName, handleUpdateColumnSettings, savedPreferenceType } = this.props;
        const { selectedColumns } = this.state;
        const savedColumns = selectedColumns.map(column => column.field)
        identityActions.saveIdentityPreferences({
            [savedPreferenceType]: {savedColumns},
        }
        , 'tableColumns'
        , ()=>{
            toggleModal(modalName)
            handleUpdateColumnSettings?.()
        });
    }

    render() {
        const { selectedColumns } = this.state;
        const { isOpen, toggleModal, backdrop, modalName, noHiding, preferences, intl } = this.props;
        const modalHeading = "invoice.column-settings.title";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="column-settings">
                    <MDBModal isOpen={isOpen} toggle={() => toggleModal(modalName)} size="xl" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal(modalName)} ><FormattedMessage id={modalHeading} /></MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol size={"6"}>
                                    <fieldset>
                                        <legend className='col-legend'><FormattedMessage id={'invoice.column-settings.available-columns.label'} /></legend>
                                        <div className={"table-column-container column-left"}>
                                            {Object.values(this.defaultColumns).map(column => {
                                                return (
                                                    <CheckboxIntl
                                                        key={column.field}
                                                        name={column.field}
                                                        id={`item-${column.field}`}
                                                        containerClass="mr-0 mb-2"
                                                        labelClass="mr-0"
                                                        onChange={e => this.handleColumnSelected(e)}
                                                        label={column.label}
                                                        checked={selectedColumns.findIndex(sc => (sc.field === column.field)) > -1}
                                                        disabled={noHiding || !column.hideable}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </fieldset>
                                </MDBCol>
                                <MDBCol size={"6"}>
                                    <fieldset>
                                        <legend className='col-legend'><FormattedMessage id={'invoice.column-settings.selected-columns.label'} /></legend>
                                        <div className={"table-column-container column-right"}>
                                            {selectedColumns.map((column, columnIdx) => {
                                                return (
                                                    <div key={column.field} className={"column-item"}>
                                                        {!noHiding && column.hideable &&
                                                            <div className={"column-item-remove"}>
                                                                <MDBLink aria-label={intl.formatMessage({id: "ups.remove.column"} , {column: column.label})} className={'px-0'} id={`column-item-remove-${columnIdx}`} to='#' onClick={e => this.removeSelectedColumn(e, column.field)}>
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="column-item-remove" width="32" height="32" viewBox="0 0 32 32">
                                                                        <title>x</title>
                                                                        <path d="M31.75 27.563l-11.563-11.5 11.563-11.563c0.438-0.438 0.438-1.125 0-1.563l-2.563-2.563c-0.5-0.5-1.125-0.5-1.625 0l-11.5 11.5-11.563-11.5c-0.438-0.5-1.125-0.5-1.563 0l-2.563 2.563c-0.5 0.438-0.5 1.125 0 1.563l11.5 11.563-11.5 11.5c-0.5 0.5-0.5 1.125 0 1.625l2.563 2.563c0.438 0.438 1.125 0.438 1.563 0l11.563-11.563 11.5 11.563c0.5 0.438 1.125 0.438 1.625 0l2.563-2.563c0.438-0.5 0.438-1.125 0-1.625z"></path>
                                                                    </svg>
                                                                </MDBLink>
                                                            </div>
                                                        }
                                                        <div className={"column-item-label"}>{column.label}</div>
                                                        <div className={"column-item-order"}>
                                                            <div className={"column-item-down"}>
                                                                <MDBLink className={'p-0'} id={`column-item-down-${columnIdx}`} to='#' onClick={e => this.shiftSelectedColumn(e, columnIdx, 1)}>
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="column-item-svg-down" viewBox="0 0 32 32">
                                                                        <title><FormattedMessage id={'ups.move-down.column'} values={{column: column.label}}/></title>
                                                                        <path d="M29.625 17l-1.5-1.563c-0.563-0.563-1.563-0.563-2.125 0l-6.813 6.813v-20.75c0-0.813-0.688-1.5-1.5-1.5h-2.5c-0.875 0-1.563 0.688-1.563 1.5v21.438l-7.5-7.5c-0.563-0.563-1.563-0.563-2.063 0l-1.563 1.563c-0.563 0.5-0.563 1.5 0 2.063l12.375 12.375c0.563 0.563 1.563 0.563 2.125 0l12.375-12.375c0.813-0.563 0.813-1.375 0.25-2.063z"></path>
                                                                    </svg>
                                                                </MDBLink>
                                                            </div>
                                                            <div className={"column-item-up"}>
                                                                <MDBLink  className={'p-0'} id={`column-item-up-${columnIdx}`} to='#' onClick={e => this.shiftSelectedColumn(e, columnIdx, -1)}>
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="column-item-svg-up" viewBox="0 0 32 32">
                                                                        <title><FormattedMessage id={'ups.move-up.column'}  values={{column: column.label}}/></title>
                                                                        <path d="M2.5 14.875l1.5 1.563c0.563 0.563 1.563 0.563 2.063 0l6.875-6.813v20.688c0 0.875 0.688 1.563 1.5 1.563h2.5c0.813 0 1.563-0.688 1.563-1.563v-21.438l7.5 7.563c0.563 0.563 1.5 0.563 2.063 0l1.563-1.563c0.563-0.563 0.563-1.5 0-2.063l-12.375-12.375c-0.563-0.563-1.563-0.563-2.125 0l-12.375 12.375c-0.813 0.563-0.813 1.375-0.25 2.063z"></path>
                                                                    </svg>
                                                                </MDBLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </fieldset>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter className={"mt-5"}>
                            <MDBBtn color="primary" onClick={this.handleApplyClicked}><FormattedMessage id={'invoice.column-settings.btn-apply.label'} /></MDBBtn>
                            <MDBBtn color="cancel" onClick={() => toggleModal(modalName)}><FormattedMessage id={'invoice.column-settings.btn-cancel.label'} /></MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        preferences: state.identity.preferences,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ColumnSettingsModal));
