import React, { Component } from 'react';
import {connect} from "react-redux";
import Exception from '../../Exception';
import {bindActionCreators} from "redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBCol } from "mdbreact";
import { get as lodashGet, isEmpty } from 'lodash';

import moment from 'moment';
import * as invoiceActions from "../../../actions/invoice-action";
import * as invoiceTypes from "../../../constants/invoice-types";
import * as invoiceApi from '../../../api/invoice-api';
import { getInvoiceDownloadRecordType } from '../../../utils/invoice-utils';
import MDBSelectWrapper from  '../../MDBFix/MDBSelectWrapper';

class UPSDocumentSupportingTable extends Component {
    constructor(props) {
        super(props);

        const { invoice } = props;

        this.state = {
            loaded: false,
            ifdDownloads: [],
            brokerageDownloads: [],
            freightDownloads: [],
            hasGiroForm: lodashGet(invoice, 'fields.GIRO_IR', false),
            selectedIfdShipment: null,
            selectedBrokerageShipment: null,
            hasSupportingDocs: false,
            error: '',
        };
    }

    maybeHasBrokerageDocuments(invoice, shipmentNumber) {
        const invoiceType = lodashGet(invoice, 'invoiceType');

        return [
            invoiceTypes.IMPORT,
            invoiceTypes.IMPORT_CPP_INVOICE,
            invoiceTypes.CREDIT_NOTE,
            invoiceTypes.EXPORT_PLAN_CREDIT_NOTE,
            invoiceTypes.DEBIT_NOTE,
            invoiceTypes.EXPORT_PLAN_DEBIT_NOTE
        ].includes(invoiceType) && !isEmpty(shipmentNumber);
    }

    maybeHasIfdDocuments(invoice, shipmentNumber, trackingNumber) {
        const billTypeCode = lodashGet(invoice, 'billTypeCode');

        return ['5', '22'].includes(billTypeCode) && !isEmpty(shipmentNumber) && !isEmpty(trackingNumber);
    }

    componentDidMount() {
        const { invoiceActions, invoice } = this.props;
        invoiceActions.getInvoiceDetails({invoice}, this.handleInvoiceDetails);
    }

    createFreightSupportingDoc = ({documentType, documentID}) =>
        <tr>
            <td>{documentType}</td>
            <td>
                <MDBBtn id={`download-${documentType}-doc`} key={`download-${documentType}-doc`} color={"text-link"} onClick={() => this.handleDownloadSupportingDoc(documentType, documentID)}>
                    <FormattedMessage id={'invoice.download-documents.table.btn-download.label'} />
                </MDBBtn>
            </td>
        </tr>;

    handleInvoiceDetails = (invoiceDetails) => {
        const { invoice, documents } = this.props;
        const { hasGiroForm, brokerageDownloads, ifdDownloads, freightDownloads } = this.state;

        for (const invoiceDetail of invoiceDetails) {
            const shipmentNumber = lodashGet(invoiceDetail, 'fields.shipmentNumber', '');
            const trackingNumber = lodashGet(invoiceDetail, 'fields.trackingNumber', '');

            if (this.maybeHasBrokerageDocuments(invoice, shipmentNumber)) {
                brokerageDownloads.push({
                    text: shipmentNumber,
                    value: { shipmentNumber }
                });
            }

            if (this.maybeHasIfdDocuments(invoice, shipmentNumber, trackingNumber)) {
                ifdDownloads.push({
                    text: shipmentNumber,
                    value: { shipmentNumber, trackingNumber }
                });
            }
        }

        if (invoice.businessUnit === 'FRT') {
            for (const document of documents) {
                if (!isEmpty(document.documentType) && !isEmpty(document.documentID)) {
                    freightDownloads.push(this.createFreightSupportingDoc(document));
                }
            }
        }

        const hasSupportingDocs = hasGiroForm || !isEmpty(ifdDownloads) || !isEmpty(brokerageDownloads) || !isEmpty(freightDownloads);

        this.setState({
            ifdDownloads,
            brokerageDownloads,
            hasSupportingDocs,
            freightDownloads,
            loaded: true
        });
    };

    handleDownloadSupportingDoc = (documentType, documentId) => {
        const { invoiceActions, invoice, intl, businessUnit } = this.props;
        const { apiUrlPrefix } = this.props.envProps;
        const { selectedBrokerageShipment, selectedIfdShipment } = this.state;

        const languageCode = intl.locale.split('-')[0].toUpperCase();

        if (documentType === 'GIRO') {
            invoiceActions.getInvoiceDownload(`${apiUrlPrefix}/invoice/download?fileType=${documentType}`,
                {
                    invoices: [{
                        businessUnit,
                        planNumber: invoice.planNumber,
                        accountNumber: invoice.accountNumber,
                        // ...(invoice.accountNumber ? { accountNumber: invoice.accountNumber } : {}),
                        // ...(invoice.planNumber ? { planNumber: invoice.planNumber } : {}),
                        countryCode: invoice.countryCode,
                        invoiceNumber: invoice.invoiceNumber,
                        invoiceDate: moment(invoice.invoiceDate.split('T')[0]).format('DD/MM/YY'),
                        languageCode,
                        recordType: getInvoiceDownloadRecordType(invoice)
                    }]
                });
        } else if (documentType === 'IFD') {
            if (!selectedIfdShipment) return;

            invoiceActions.getInvoiceDownload(`${apiUrlPrefix}/invoice/download?fileType=PDF`, {
                invoices: [{
                    businessUnit,
                    planNumber: invoice.planNumber,
                    accountNumber: invoice.accountNumber,
                    // ...(invoice.accountNumber ? { accountNumber: invoice.accountNumber } : {}),
                    // ...(invoice.planNumber ? { planNumber: invoice.planNumber } : {}),
                    invoiceDate: moment(invoice.invoiceDate.split('T')[0]).format('DD/MM/YY'),
                    languageCode,
                    invoiceType: documentType,
                    uniqueId: selectedIfdShipment[0].trackingNumber,
                    documentId: selectedIfdShipment[0].shipmentNumber,
                    recordType: getInvoiceDownloadRecordType(invoice)
                }]
            });
        } else if (documentType === 'BROKERAGE') {
            if (!selectedBrokerageShipment) return;

            invoiceActions.getInvoiceDownload(`${apiUrlPrefix}/invoice/download?fileType=${documentType}`, {
                invoices: [{
                    businessUnit,
                    planNumber: invoice.planNumber,
                    accountNumber: invoice.accountNumber,
                    // ...(invoice.accountNumber ? { accountNumber: invoice.accountNumber } : {}),
                    // ...(invoice.planNumber ? { planNumber: invoice.planNumber } : {}),
                    documentId: selectedBrokerageShipment[0].shipmentNumber,
                    recordType: getInvoiceDownloadRecordType(invoice)
                }]
            });
        } else {
            if (!documentId) return;

            invoiceActions.getInvoiceDownload(`${apiUrlPrefix}/invoice/download?fileType=${documentType}`, {
                invoices: [{
                    documentId,
                    planNumber: invoice.planNumber,
                    accountNumber: invoice.accountNumber,
                    // ...(invoice.accountNumber ? { accountNumber: invoice.accountNumber } : {}),
                    // ...(invoice.planNumber ? { planNumber: invoice.planNumber } : {}),
                    businessUnit,
                    isSupportingDoc: true,
                    recordType: getInvoiceDownloadRecordType(invoice)
                }]
            });
        }
    };

    render() {
        const { error, loaded, ifdDownloads, brokerageDownloads, hasGiroForm, hasSupportingDocs, freightDownloads} = this.state;
        const { intl } = this.props;

        if (!loaded) return null;
        if (error instanceof TypeError) return <Exception error={error} />

        const tableRows = this.state.supportingDocs || [];

        if (!isEmpty(brokerageDownloads)) {
            tableRows.push(
                <tr>
                    <td>
                        <FormattedMessage id={'invoice.download-documents.brokerage.label'} />
                        <div className={"md-form my-0"}>
                            <MDBSelectWrapper
                                label={intl.formatMessage({id:'invoice.download-documents.shipment-number.label'})}
                                field={"shipment-number"}
                                className={'mt-4 mb-0'}
                                selected={intl.formatMessage({ id: 'invoice.download-documents.select-shipment-number.label' })}
                                options={brokerageDownloads} 
                                search 
                                searchLabel={intl.formatMessage({ id: "search.label" })}
                                onUpdateValue={(field, val) => this.setState({ selectedBrokerageShipment: val })}
                            />
                        </div>
                    </td>
                    <td>
                        <MDBBtn id={`download-brokerage-doc`} key={`download-brokerage-doc`} color={"text-link"} onClick={() => this.handleDownloadSupportingDoc('BROKERAGE')}><FormattedMessage id={'invoice.download-documents.table.btn-download.label'} /></MDBBtn>
                    </td>
                </tr>
            );
       }

        if (!isEmpty(ifdDownloads)) {
            tableRows.push(
                <tr>
                    <td>
                        <FormattedMessage id={'invoice.download-documents.other.label'} />
                        <div className={"md-form my-0"}>
                            <MDBSelectWrapper
                                label={intl.formatMessage({id:'invoice.download-documents.shipment-number.label'})}
                                field={"ifdShipment-number"}
                                className={'mt-4 mb-0'}
                                selected={intl.formatMessage({ id: 'invoice.download-documents.select-shipment-number.label' })}
                                options={ifdDownloads}
                                search
                                searchLabel={intl.formatMessage({ id: "search.label" })}
                                onUpdateValue={(field, val) => this.setState({ selectedIfdShipment: val })}
                            />
                        </div>
                    </td>
                    <td>
                        <MDBBtn id={`download-other-doc`} key={`download-other-doc`} color={"text-link"} onClick={() => this.handleDownloadSupportingDoc('IFD')}><FormattedMessage id={'invoice.download-documents.table.btn-download.label'} /></MDBBtn>
                    </td>
                </tr>
            );
        }

        if (hasGiroForm) {
            tableRows.push(
                <tr>
                    <td><FormattedMessage id={'invoice.download-documents.giro-form.label'} /></td>
                    <td>
                        <MDBBtn id={`download-giro-doc`} selected={'invoice.download-documents.select-shipment-number.label'} key={`download-giro-doc`} color={"text-link"} onClick={() => this.handleDownloadSupportingDoc('GIRO')}><FormattedMessage id={'invoice.download-documents.table.btn-download.label'} /></MDBBtn>
                    </td>
                </tr>
            );
        }

        if (!isEmpty(freightDownloads)) {
            tableRows.push(...freightDownloads);
        }

        return (
            <React.Fragment>
                <MDBCol size={"12"}>
                    {(loaded && hasSupportingDocs)
                        ? (
                            <>
                                <h3 className={"document-sub-heading"}><FormattedMessage id={'invoice.download-documents.supporting-documents.label'} /></h3>
                                <MDBTable width={'100%'} className={"table-bordered-simple table-sm"}>
                                    <MDBTableHead color="light">
                                        <tr>
                                            <th><FormattedMessage id={'invoice.download-documents.table.document-name.label'} /></th>
                                            <th className={'text-center datatable-action-col'}><FormattedMessage id={'invoice.download-documents.table.actions.label'} /></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {tableRows}
                                    </MDBTableBody>
                                </MDBTable>
                            </>
                        )
                        : (
                            <FormattedMessage id={'invoice.download-documents.no-documents-found'}/>
                        )
                    }
                </MDBCol>
            </React.Fragment>
        )

    }
}

function mapStateToProps(state, ownProps) {
    return {
        envProps: state.envProps,
        businessUnit: state.auth.user.selectedPaymentType,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSDocumentSupportingTable));
