import React, { useEffect, useReducer }       from "react";
import { Link, useParams, useLocation }       from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCol
       , MDBContainer, MDBRow}                from "mdbreact";
import { FormattedMessage, useIntl }          from "react-intl";
import { useSelector, useDispatch }           from "react-redux";
import {has, isEmpty, isNil}                  from "ramda";


import { getSchedules }                       from "../../api/autopay-api";
import ScheduleTable                          from "./ScheduleTable";
import Error                                  from '../../components/Error';
import Spinner                                from '../../components/Spinner';
import AddScheduleModal                       from "../../components/UPS/Modal/AddScheduleModal";
import ViewScheduleModal                      from "../../components/UPS/Modal/ViewScheduleModal";
import CancelScheduleModal                    from "../../components/UPS/Modal/CancelScheduleModal";
import FilterModal                            from "../../components/UPS/Modal/FilterModal/FilterModal";
import AutopayFilterCriteria                  from "../../components/UPS/Modal/FilterModal/FilterCriteria/AutopayFilterCriteria";
import {INITIAL_TABLE_STATE}                  from "../../components/DataTableV3/DataTableV3FilterWrapper";
import * as configActions                     from "../../actions/config-action";
import { is3dSecureFlow }                     from '../../utils/payment-utils';
import {getSessionFilter} from "../../utils/utils";

const DT_SRC = "autopay-schedule";
const INITIAL_STATE = {
    schedule: {}, // Focused schedule
    schedules: [], // All schedules
    fetching: true,
    createModal: false,
    editModal: false,
    viewModal: false,
    cancelModal: false,
    exportModal: false,
    filterModal: false,
    scheduleColumnSettings: false,
    error: '',
    in3dsFlow: false
};

const initState = (savedFilter) => ({...INITIAL_STATE, tableState: {...INITIAL_TABLE_STATE, filter: savedFilter ?? {}}})

function reducer(state, {type, schedules, schedule, filter, error, tableAction}) {
    switch (type) {
        case 'fetch':
            return {
                ...state,
                fetching: true
            }
        case "fetched":
            return {
                ...state,
                schedules: schedules ?? state.schedules,
                fetching: false
            };
        case 'error':
            return {
                ...state,
                error,
                fetching: false
            };
        case "addSchedule":
            return {
                ...state,
                createModal: !state.createModal,
                fetching: state.createModal
            };
        case "filterModal":
            return {
                ...state,
                filterModal: !state.filterModal
            };
        case "scheduleColumnSettings":
            return {
                ...state,
                scheduleColumnSettings: !state.scheduleColumnSettings
            };
        case "exportData":
            return {
                ...state,
                exportModal: !state.exportModal
            };
        case "viewSchedule":
            return {
                ...state,
                schedule: state.viewModal ? {} : schedule,
                viewModal: !state.viewModal
            };
        case "editSchedule":
            return {
                ...state,
                schedule: state.editModal ? {} : schedule,
                editModal: !state.editModal,
                fetching: state.editModal
            }
        case "cancelSchedule":
            return {
                ...state,
                schedule: state.cancelModal ? {} : schedule,
                cancelModal: !state.cancelModal,
                fetching: state.cancelModal
            };
        case "setFilter":
            return {
                ...state,
                tableState:{
                    ...state.tableState,
                    filter
                }
            }
        case "3dsFlowStart":
            return {
                ...state,
                in3dsFlow: true
            }
        case 'tableAction': return tableAction(state)
        default:
            return state;
    }
}



export default function UPSAutoPay() {

    const {id: identityId, selectedCountry, selectedPaymentType} = useSelector(s => s.auth.user);
    const sessionFilter = getSessionFilter('autopayTableFilter', selectedPaymentType, selectedCountry)
    const dbFilter = useSelector(s => s.identity?.preferences?.autopayTableFilter ?? {});
    const [state, dispatch] = useReducer(reducer, sessionFilter ?? dbFilter, initState);
    const { merchants }= useSelector(s => s.config);
    const navParams = useParams();
    const dispatchRedux = useDispatch();
    const location = useLocation();

    const threeDsState =  useSelector(state => state.config?.threeDSecureDetails);

    useEffect (() => {
      const errorCode = navParams.errorCode ?? location.state?.errorCode;

      if (!isNil(errorCode)) {
        dispatch({type: 'error', error: errorCode})
      }
    }, []);

    useEffect(() => {
      //auto-open add modal if we are coming from dashboard
      if (navParams.openModal) {
        toggleModal("addSchedule");
      }
    }, [navParams.openModal])

    useEffect(()=>{
      //auto-open add or edit modal if we are coming back from 3d secure flow
      if(!isNil(threeDsState.referenceId) && !state.fetching && !state.in3dsFlow) {
        if (threeDsState.scheduleId) {
          const schedule = state.schedules.find(s => s.id === (threeDsState.scheduleId))
          if (schedule) {
              toggleModal("editSchedule", schedule)
          }
        } else {
            toggleModal("addSchedule")
        }
      }
    }, [state.fetching, state.schedules, state.in3dsFlow, threeDsState]);

    useEffect(() => {
        dispatch({type: 'fetch'});
    }, [selectedCountry]);

    useEffect(() => {
        let stale = false;
        if (state.fetching) {
          getSchedules()
            .then(schedules => {if (!stale) dispatch({type: "fetched", schedules})})
            .catch(({errorCode}) => {if (!stale) dispatch({type: 'error', error: errorCode || ''})});
        }
        return () => stale = true;
    }, [state.fetching, merchants, selectedCountry, identityId, selectedPaymentType]);

    const toggleModal = (modalId, schedule) => dispatch({type: modalId, schedule});
    const intl = useIntl();
    return (
      <MDBContainer role="main" fluid>
        <MDBRow>
          {(state.fetching || state.in3dsFlow) && <Spinner isSpinning={true} />}
          {state.error?.length > 0 && <Error errors={[state.error]} />}
          <MDBCol size="12">
            <MDBCard>
              <MDBCardBody className="autopay-section">
                <MDBRow>
                  <MDBCol size="12">
                    <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                      <ol className="ups-wrap_inner">
                        <li className="ups-breadcrumb-item">
                          <Link to="/home" className="ups-link">
                            <FormattedMessage id="ups.title.dashboard" />
                          </Link>
                          <span aria-hidden="true"> &gt; </span>
                        </li>
                        <li className="ups-breadcrumb-item">
                          <FormattedMessage id="ups.title.autopay" />
                        </li>
                      </ol>
                    </nav>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol size="12">
                    <h1 className="has-breadcrumb">
                      <FormattedMessage id="ups.title.autopay" />
                    </h1>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol size="12">
                    {!state.fetching && <ScheduleTable
                    caption={'ups.title.autopay'}
                      source={DT_SRC}
                      schedules={state.schedules}
                      filter={state.filter}
                      columnSettingsOpen={state.scheduleColumnSettings}
                      exportModalOpen={state.exportModal}
                      handleAction={toggleModal}
                      tableState={state.tableState}
                      tableStateAction={(tableAction)=>dispatch({type:'tableAction', tableAction})}
                    />}
                  </MDBCol>
                </MDBRow>

                {(state.createModal || state.editModal) &&
                  <AddScheduleModal
                    modalId={state.createModal ? 'addSchedule' : 'editSchedule'}
                    isOpen={true}
                    toggleModal={toggleModal}
                    initialSchedule={state.schedule}
                    preSelectedAccount={threeDsState.accountId}
                    preSelectedWallet={threeDsState.referenceId}
                    clearPreSelection={()=>{}}
                    submit3DSForm={state.in3dsFlow}
                    addWalletHandler={(pm, accountId, scheduleId) => {
                        if (is3dSecureFlow(pm)) {
                            dispatch({type: '3dsFlowStart'})
                            dispatchRedux(configActions.enter3ds({
                              redirectLocation: location.pathname,
                              referenceId: pm._id,
                              scheduleId,
                              accountId,
                              type: 'wallet'
                            }))
                        }
                    }}
                  />
                }

                {state.viewModal && <ViewScheduleModal
                  modalId="viewSchedule"
                  isOpen={state.viewModal}
                  toggleModal={toggleModal}
                  schedule={state.schedule}
                />}

                {state.cancelModal && <CancelScheduleModal
                  modalId="cancelSchedule"
                  isOpen={state.cancelModal}
                  toggleModal={toggleModal}
                  schedule={state.schedule}
                />}

                <FilterModal
                  businessUnit={selectedPaymentType}
                  country={selectedCountry}
                  filterName={'autopayTableFilter'}
                  isOpen={state.filterModal}
                  toggleModal={()=>toggleModal('filterModal')}
                  setFilter={(filter) => dispatch({ type: "setFilter", filter })}
                  filter={state.tableState.filter}
                  renderFilterCriteria={(props)=>{
                      return <AutopayFilterCriteria
                          {...props}
                          autopayList={state.schedules}
                      />
                  }}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
}
