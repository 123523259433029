/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/6/19 12:37 PM
 * Created by kxian
 *
 */


export default () => {
    return {
        apiUrlPrefix: '/api/v1/bc',
        //apiUrlPrefix: 'http://localhost:1464/api/v1/bc',
        apiToken: 'UPS',
        devMode: window.location.href.indexOf("k8-dev") > -1 || window.location.href.indexOf("localhost") > -1,
        simulateApi: false,
        tealiumSyncLink:window.config.tealiumSyncLink,
        tealiumLink:window.config.tealiumLink,
        disableSecurePdf: window.config.disableSecurePdf,
        upsHostName: window.config.upsHostName,
        enableChatbot: window.config.enableUPSChatbot
    };
}