/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/5/19 11:47 AM
 * Created by kxian
 *
 */

import {all, call, fork, put, select, takeEvery, delay} from 'redux-saga/effects';
import * as merchantApi from '../api/config-api';
import * as merchantActions from '../actions/config-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';

const getEnvProps = (state) => state.envProps;

function* getAllMerchants() {
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const {apiUrlPrefix, simulateApi} = envProps;
        
        // const response = yield call(merchantApi.getMerchant, apiUrlPrefix, apiToken);
        const response = yield call(merchantApi.getAllMerchants, apiUrlPrefix, simulateApi);
        const {ok, parsedBody} = response;

        // for success, redirect to home page
        if (ok && parsedBody) {
            yield put(merchantActions.getAllMerchantsSuccess(parsedBody));
        }

    } catch (error) {
        if (error.status === 403) {
            // yield put(layoutActions.invalidSession());
        } else {
            // yield put(merchantActions.getMerchantFailure());
        }
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getAllMerchantsSaga() {
    yield takeEvery(actionTypes.GET_ALL_MERCHANTS, getAllMerchants);
}


export default function* configSaga() {
    yield all([
        fork(getAllMerchantsSaga)
    ]);
}