/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {all, call, fork, put, takeEvery, delay, select } from 'redux-saga/effects';
import * as invoiceApi from '../api/invoice-api';
import * as invoiceActions from '../actions/invoice-action';
import * as invoiceStatuses from '../constants/invoice-statuses';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';
import * as errorActions from '../actions/error-action';
import { get as lodashGet } from 'lodash';

import * as R from 'ramda';

const getEnvProps = (state) => state.envProps;

function* getInvoice(action) {
    try {
        yield put(layoutActions.showSpinner());

        const {invoice, callback} = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const response = yield call(invoiceApi.getInvoice, {invoice}, apiUrlPrefix, apiToken);

        if (response) {
            yield put(invoiceActions.getInvoiceSuccess(response));

            if (callback) {
                yield call(callback, response);
            }
        } else {
            yield put(invoiceActions.getInvoiceFailure());
        }
    } catch (err) {
        console.log('getInvoice', err);
        yield put(invoiceActions.getInvoiceFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getInvoiceList(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { filters = {}, callback } = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        // TODO: These should be cached somewhere by the time a session is created, for the mean time we'll fetch it if its not present
        // let invoiceMetadata = yield select(getMetadata);
        // if (!invoiceMetadata) {
        //     yield put(invoiceActions.getInvoiceMetadata());
        //     yield take(invoiceActions.getInvoiceMetadataSuccess);
        // }

        const searchFields = {
            ...filters
        };
        const response = yield call(invoiceApi.getInvoiceList, searchFields, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            yield put(invoiceActions.getInvoiceListSuccess(response.parsedBody));

            if (callback) {
                yield call(callback, response.parsedBody);
            }
        } else {
            yield put(invoiceActions.getInvoiceListFailure());
        }
    } catch (err) {
        yield put(invoiceActions.getInvoiceListFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

const pickAccountFieldsForDetails = R.pick(['accountNumber', 'planNumber', 'recordType', 'id'])

function* getInvoiceDetails(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { invoice, searchCriteria = {}, conditionals = {}, callback } = action;
        const detailType = action.searchCriteria?.detailType;

        // const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const response = yield call(invoiceApi.getInvoiceDetails, {invoice: pickAccountFieldsForDetails(invoice), searchCriteria, conditionals});

        if (response) {
            if (callback) {
                callback(response)
            }
            if (detailType) {
                yield put(invoiceActions.getInvoiceDetailsSuccess(detailType, response));
            }
        } else {
            yield put(invoiceActions.getInvoiceDetailsFailure());
        }
    } catch (err) {
        console.error(err);
        yield put(invoiceActions.getInvoiceDetailsFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getInvoiceMetadata(action) {
    try {
        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const response = yield call(invoiceApi.getInvoiceMetadata, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            yield put(invoiceActions.getInvoiceMetadataSuccess(response.parsedBody));
        } else {
            yield put(invoiceActions.getInvoiceMetadataFailure(response.parsedBody));
        }
    } catch (err) {
        console.error(err);
    }
}

function* getInvoiceListByIdentity(action) {
    try {
        yield put(layoutActions.showSpinner());
        // const apiUrlPrefix = yield select(getApiUrlPrefix);
        // const apiToken = yield select(getApiToken);
        const apiUrlPrefix = null;
        const apiToken = null;
        const {identity, callBack} = action;
        const response = yield call(invoiceApi.getInvoiceListByIdentity, identity, apiUrlPrefix, apiToken);

        if (response) {
            yield put(invoiceActions.getInvoiceListByIdentitySuccess(response));
            if (callBack && callBack.history) {
                yield call(callBack.history.push({
                    pathname: callBack.forwardUrl, // (string) The path of the URL
                    // search: '', search - (string) The URL query string e.g. '?query=abc',
                    state: { backUrl: callBack.backUrl }
                }));
            } else if (callBack && callBack.toggleModal) {
                yield call(callBack.toggleModal(callBack.modalName));
            }
        } else {
            // yield put(invoiceActions.getIdentityFailure());
        }
        // if (parsedBody && parsedBody.id) {
        //     yield put(invoiceActions.getIdentitySuccess(parsedBody));
        // } else {
        //     yield put(invoiceActions.getIdentityFailure());
        // }
    } catch (error) {
        // if (error.status === 403) {
        //     yield put(layoutActions.invalidSession());
        // } else {
        //     yield put(invoiceActions.getInvoiceListByIdentityFailure());
        // }
    } finally {
        yield delay(500); // demo purposes as there is no load time when loading the demo json local file
        yield put(layoutActions.hideSpinner());

    }
}

function* getInvoiceDownload(action) {
    try {
        yield put(errorActions.clearErrors());
        yield put(layoutActions.showSpinner());

        const {url, searchCriteria} = action;

        const { apiToken } = yield select(getEnvProps);

        const apiBody = {
            ...searchCriteria
        };

        const response = yield call(invoiceApi.getInvoiceDownload, url, apiBody, apiToken);
        if (response) {
            yield put(invoiceActions.getInvoiceDownloadSuccess());
        } else {
            yield put(invoiceActions.getInvoiceDownloadFailure());
        }
    } catch (err) {
        const errors =
            lodashGet(err.parsedBody, 'error.errorBody.response.errors', [])
            .filter(({ code }) => ["110400"].includes(code))
            .map(({ code }) => `invoice.download.${code}`);
        if (err.status === 404) {
            yield put(errorActions.setErrors(['invoice.download.not.found']));
        } else if (err.status === 400 && errors.length > 0) {
            yield put(errorActions.setErrors(errors));
        } else if ([413, 403].includes(err.status)) {
            yield put(errorActions.setErrors(err));
        } else {
            yield put(errorActions.setErrors(['page.unexpectedError']));
        }
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getInvoicePendingDownload(action) {
    try {
        yield put(errorActions.clearErrors());
        yield put(layoutActions.showSpinner());

        const {url, body} = action;

        const { apiToken } = yield select(getEnvProps);

        const response = yield call(invoiceApi.getInvoicePendingDownload, url, apiToken, body);
        if (response) {
            yield put(invoiceActions.getInvoiceDownloadSuccess());
        } else {
            yield put(invoiceActions.getInvoiceDownloadFailure());
        }
    } catch (err) {
        const errors =
            lodashGet(err.parsedBody, 'error.errorBody.response.errors', [])
            .filter(({ code }) => ["110400"].includes(code))
            .map(({ code }) => `invoice.download.${code}`);
        if (err.status === 404) {
            yield put(errorActions.setErrors(['invoice.download.not.found']));
        } else if (err.status === 400 && errors.length > 0) {
            yield put(errorActions.setErrors(errors));
        } else if ([413, 403].includes(err.status)) {
            yield put(errorActions.setErrors(err));
        } else {
            yield put(errorActions.setErrors(['page.unexpectedError']));
        }
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getPastDueInvoices(action) {
    try {
        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const filters = {
            invoiceStatus: [invoiceStatuses.PAST_DUE],
        };

        const response = yield call(invoiceApi.getInvoiceList, filters, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            yield put(invoiceActions.getPastDueInvoicesSuccess(response.parsedBody));
        } else {
            yield put(invoiceActions.getPastDueInvoicesFailure());
        }
    } catch (err) {
        yield put(invoiceActions.getPastDueInvoicesFailure());
    } finally {
    }
}

function* getPastDuePlanInvoices(action) {
    try {
        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const { additionalFilters, isDrillMode } = action;

        let planNumber, planInvoiceNumber;
        if (isDrillMode) {
            planNumber = lodashGet(additionalFilters, 'planNumber');
            planInvoiceNumber = lodashGet(additionalFilters, 'planInvoiceNumber');
        }

        const filters = {
            invoiceStatus: [invoiceStatuses.PAST_DUE],
            ...(planNumber ? { planNumber } : {}),
            ...(planInvoiceNumber ? { planInvoiceNumber } : {}),
        };

        const response = yield call(invoiceApi.getPlanInvoiceList, filters, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            yield put(invoiceActions.getPastDuePlanInvoicesSuccess(response.parsedBody));
        } else {
            yield put(invoiceActions.getPastDuePlanInvoicesFailure());
        }
    } catch (err) {
        yield put(invoiceActions.getPastDuePlanInvoicesFailure());
    } finally {
    }
}

function* getPayableInvoices(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { filters = {}, callback } = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const searchFields = {
            ...filters
        };
        const response = yield call(invoiceApi.getPayableInvoices, searchFields, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            yield put(invoiceActions.getPayableInvoicesSuccess(response.parsedBody));

            if (callback) {
                yield call(callback, response.parsedBody);
            }
        } else {
            yield put(invoiceActions.getPayableInvoicesFailure());
        }
    } catch (err) {
        yield put(invoiceActions.getPayableInvoicesFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}


function* getPlanInvoiceList(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { filters = {}, callback } = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const searchFields = {
            ...filters
        };
        const response = yield call(invoiceApi.getPlanInvoiceList, searchFields, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            // const preventPayment = R.map(overIf(R.lensProp("isPayable"), R.always(false)));
            // const invoices = preventPayment(response.parsedBody); // Temp; stop preventing plan-pay later
            const invoices = response.parsedBody;

            yield put(invoiceActions.getPlanInvoiceListSuccess(invoices));

            if (callback) {
                yield call(callback, invoices);
            }
        } else {
            yield put(invoiceActions.getPlanInvoiceListFailure());
        }
    } catch (err) {
        yield put(invoiceActions.getPlanInvoiceListFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getAdvancedSearchInvoices(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { searchType, queryParams = {}, callback, isBatch, localeSetting, currencyDisplay } = action;

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const searchFields = {
            ...queryParams
        };
        const response = yield call(invoiceApi.getAdvancedSearchList, searchType, searchFields, isBatch, localeSetting, currencyDisplay, apiUrlPrefix, apiToken);

        if (response && response.parsedBody) {
            yield put(invoiceActions.getAdvanceSearchInvoicesSuccess(response.parsedBody));

            if (callback) {
                yield call(callback, response.parsedBody,response);
            }
        } else {
            yield put(invoiceActions.getAdvanceSearchInvoicesFailure());
        }
    } catch (err) {
        yield put(invoiceActions.getAdvanceSearchInvoicesFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getInvoiceSaga() {
    yield takeEvery(actionTypes.GET_INVOICE, getInvoice);
}

export function* getInvoiceListSaga() {
    yield takeEvery(actionTypes.GET_INVOICE_LIST, getInvoiceList);
}

export function* getInvoiceDetailsSaga() {
    yield takeEvery(actionTypes.GET_INVOICE_DETAILS, getInvoiceDetails);
}

export function* getInvoiceMetadataSaga() {
    yield takeEvery(actionTypes.GET_INVOICE_METADATA, getInvoiceMetadata);
}

export function* getInvoiceListByIdentitySaga() {
    yield takeEvery(actionTypes.GET_INVOICE_LIST_BY_IDENTITY, getInvoiceListByIdentity);
}

export function* getInvoiceDownloadSaga() {
    yield takeEvery(actionTypes.GET_INVOICE_DOWNLOAD, getInvoiceDownload);
}

export function* getInvoicePendingDownloadSaga() {
    yield takeEvery(actionTypes.GET_INVOICE_PENDING_DOWNLOAD, getInvoicePendingDownload);
}

export function* getPastDueInvoicesSaga() {
    yield takeEvery(actionTypes.GET_PAST_DUE_INVOICES, getPastDueInvoices);
}

export function* getPastDuePlanInvoicesSaga() {
    yield takeEvery(actionTypes.GET_PAST_DUE_PLAN_INVOICES, getPastDuePlanInvoices);
}

export function* getPlanInvoiceListSaga() {
    yield takeEvery(actionTypes.GET_PLAN_INVOICE_LIST, getPlanInvoiceList);
}

export function* getPayableInvoicesSaga() {
    yield takeEvery(actionTypes.GET_PAYABLE_INVOICES, getPayableInvoices);
}

export function* getAdvancedSearchInvoicesSaga() {
    yield takeEvery(actionTypes.GET_ADVANCE_SEARCH_INVOICES, getAdvancedSearchInvoices);
}


export default function* invoiceSaga() {
    yield all([
        fork(getInvoiceSaga),
        fork(getInvoiceListSaga),
        fork(getInvoiceDetailsSaga),
        fork(getInvoiceMetadataSaga),
        fork(getInvoiceListByIdentitySaga),
        fork(getInvoiceDownloadSaga),
        fork(getPastDueInvoicesSaga),
        fork(getPastDuePlanInvoicesSaga),
        fork(getPlanInvoiceListSaga),
		fork(getPayableInvoicesSaga),
        fork(getInvoicePendingDownloadSaga),
        fork(getAdvancedSearchInvoicesSaga)
    ]);
}