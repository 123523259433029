import React, { Component } from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import defaultPhrases from 'react-dates/lib/defaultPhrases';
import MediaQuery from 'react-responsive'
import "react-dates/lib/css/_datepicker.css";
import CalendarPane from "../CalendarPane";
import {getLabel} from "../../../utils/invoice-utils";
import {get as lodashGet, isEmpty, isEqual as lodashIsEqual} from 'lodash';
import moment from "moment";
import memoize from "memoize-one";
import {AutoFocus} from "../../UtilComponents/AutoFocus";

const START_DATE = "startDate";
const END_DATE = "endDate";

function phraseOverrides(intl) {
    return {
        keyboardShortcuts: intl.formatMessage({ id: `input.shortcut.keys.keyboardShortcuts`}),
        showKeyboardShortcutsPanel: intl.formatMessage({ id: `calendar.phrases.showKeyboardShortcutsPanel`}),
        hideKeyboardShortcutsPanel: intl.formatMessage({ id: `calendar.phrases.hideKeyboardShortcutsPanel`}),
        moveFocusByOneMonth: intl.formatMessage({ id: `calendar.phrases.moveFocusByOneMonth`}),
        selectFocusedDate: intl.formatMessage({ id: `input.shortcut.keys.selectFocusedDate`}),
        moveFocusByOneWeek: intl.formatMessage({ id: `input.shortcut.keys.moveFocusByOneWeek`}),
        moveFocusByOneDay: intl.formatMessage({ id: `input.shortcut.keys.moveFocusByOneDay`}),
        moveFocustoStartAndEndOfWeek: intl.formatMessage({ id: `input.shortcut.keys.moveFocustoStartAndEndOfWeek`}),
        returnFocusToInput: intl.formatMessage({ id: `input.shortcut.keys.returnFocusToInput`}),
        openThisPanel: intl.formatMessage({ id: `input.shortcut.keys.openThisPanel`}),
        chooseAvailableStartDate: ({ date }) => `${date}`,
        chooseAvailableEndDate: ({ date }) => `${date}`,
        jumpToPrevMonth: intl.formatMessage({ id:'calendar.phrases.previousMonth'}),
        jumpToNextMonth: intl.formatMessage({ id:'calendar.phrases.nextMonth'})
    }
}

class MetadataDateRangePicker extends Component {
    constructor(props) {
        super(props);


        this.formatLabel = getLabel.bind({intl: props.intl})

        this.state = {
            focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE
        }
    }

    setFormatLabel = memoize(() => {
        this.formatLabel = getLabel.bind({intl: this.props.intl})
    })


    onInit() {
        const { dateFilterFields, existingFilters, intl } = this.props;

        let defaultCalendarOption = {};

        const calendarOptionList = [];
        const { dateFilters } = existingFilters;
        let calendarOptionType = null,
            startDate = null,
            endDate = null,
            dateType = null;
        if (dateFilters) {
            calendarOptionType = dateFilters.calendarOptionType;
            startDate = dateFilters.startDate;
            endDate = dateFilters.endDate;
            dateType = dateFilters.dateType;
        }

        for (const dateOption of dateFilterFields) {
            const field = dateOption.field;
            const title = this.formatLabel({}, dateOption);;
            const options = [];
            if (dateOption.filter) {
                for (const option of dateOption.filter.options) {
                    if (option.value == 'today' || option.value == 'all') {
                        if (option.default || (!defaultCalendarOption[field] && option.value == 'all')) {
                            defaultCalendarOption[field] = option.value;
                        }
                        options.push({
                            value: option.value,
                            label: intl.formatMessage({id: `calendar.option-range.${option.value}`}),
                            optionType: `${option.value}`
                        });
                    } else {
                        if (option.default || (!defaultCalendarOption[field] && option.value == 'all')) {
                            defaultCalendarOption[field] = `${option.value}, ${option.type}`;
                        }
                        options.push({
                            value: `${option.value}, ${option.type}`,
                            label: intl.formatMessage({id: `calendar.option-range.${option.value}_${option.type.toUpperCase()}`}),
                            optionType: `${option.value}_${option.type}`
                        });
                    }
                }
            } else if (dateOption.options) {
                const defaultSelection = dateOption.defaultOption ? dateOption.defaultOption : 'AVAILABLE';
                dateOption.options.forEach(option => {
                    if (option === defaultSelection) defaultCalendarOption[field] = option;
                    options.push({
                        value: option,
                        label: intl.formatMessage({id: `calendar.option-range.${option}`}),
                        optionType: option
                    });
                });
            }

            options.push({
                value: 'custom',
                label: intl.formatMessage({id: 'calendar.option-range.CUSTOM'}),
                optionType: 'custom'
            });

            calendarOptionList.push({
                field,
                title,
                options
            });

            if (!defaultCalendarOption[field]) {
                defaultCalendarOption[field] = options[0].value;
            }
        }

        let transientDateType = dateType || calendarOptionList[0].field;
        let transientCalendarOption = defaultCalendarOption[transientDateType];

        this.setState({
            calendarOptionList,
            loaded: true,
            calendarOptionType: calendarOptionType ? calendarOptionType : transientCalendarOption,
            transientCalendarOptionType: calendarOptionType ? calendarOptionType : transientCalendarOption,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            transientStartDate: startDate ? startDate : null,
            transientEndDate: endDate ? endDate : null,
            dateType: transientDateType,
            defaultCalendarOption
        });
    }

    componentDidMount() {
        this.onInit();
    }

    componentDidUpdate(prevProps) {
        const prevDateFilters = lodashGet(prevProps, 'existingFilters.dateFilters');
        const dateFilters = lodashGet(this.props, 'existingFilters.dateFilters');
        if (!lodashIsEqual(prevDateFilters, dateFilters) || (prevProps.intl.locale !== this.props.intl.locale) ) {
            this.onInit();
        }
    }


    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ transientStartDate: startDate, transientEndDate: endDate, transientCalendarOptionType: 'custom'});
    };

    onFocusChange = focusedInput => {
        this.setState({
            // Force the focusedInput to always be truthy so that dates are always selectable
            focusedInput: !focusedInput ? START_DATE : focusedInput
        });
    };

    submitDateFilter = (dateOptions) => {
        const { showDatePicker, updateFiltersAndSearch, updateDateRangeString, intl, calendarOptionType } = this.props;
        const { dateType } = dateOptions;
        const { transientStartDate, transientEndDate, transientCalendarOptionType} = this.state;

        if (!(transientCalendarOptionType === 'custom' && !(transientStartDate && transientEndDate))) {
            const dateFilters = {
                dateType,
                calendarOptionType: transientCalendarOptionType,
                startDate: transientStartDate,
                endDate: transientEndDate
            };
            updateFiltersAndSearch({dateFilters});
            this.setState({
                dateType,
                calendarOptionType: transientCalendarOptionType,
                startDate: transientStartDate,
                endDate: transientEndDate
            });

            updateDateRangeString({
                calendarOptionType: transientCalendarOptionType,
                startDate: transientStartDate,
                endDate: transientEndDate
            });
            showDatePicker();
        }
    }

    resetFilters = e => {
        const { showDatePicker } = this.props;
        const {calendarOptionType, startDate, endDate} = this.state;
        this.setState({
            transientCalendarOptionType: calendarOptionType,
            transientStartDate: startDate,
            transientEndDate: endDate
        })
        showDatePicker();
    }

    resetTransientState = e => {
        const { dateType, calendarOptionType, startDate, endDate, calendarOptionList, defaultCalendarOption } = this.state;
        const inputValue = e.target.value;
        if (dateType && inputValue === dateType) {
            this.setState({
                transientCalendarOptionType: calendarOptionType,
                transientStartDate: startDate,
                transientEndDate: endDate
            });
        } else {
            this.setState({
                transientCalendarOptionType: defaultCalendarOption[inputValue],
                transientStartDate: null,
                transientEndDate: null
            })
        }
    };

    render() {
        const {submitDateFilter, resetFilters, resetTransientState} = this;
        const { dateRangeString, focusText, closeFocusSelector, showCalendar, dateFilterFields, minDate, intl } = this.props;
        const { focusedInput, calendarOptionList, loaded, calendarOptionType, transientStartDate, transientEndDate, dateType, transientCalendarOptionType} = this.state;

        if (!loaded) return null;

        // as norway correct language-code is "nb-NO" but we are passing "no-NO"
       // needs to pass correct language code "nb-NO" so that calendar gets translated
       let currLocale = intl.locale;
       if(currLocale == "no-NO"){
           currLocale = "nb-NO";
       }

        this.setFormatLabel(intl.locale)

        return (
            <React.Fragment>

                {showCalendar ?
                    <React.Fragment>
                    <AutoFocus requirement={(showCalendar===true)} observe={[showCalendar]}>
                        <div tabIndex={-1} id={"calendarFocus"} className={"screenreader-text"}>
                            {focusText
                                ? <FormattedMessage id={'calendar.focusTextWithLabel'} values={{label:focusText}}/>
                                : <FormattedMessage id={'calendar.focusText'}/>
                            }
                        </div>
                    </AutoFocus>
                    <MediaQuery minWidth={600}>
                        <DateRangePicker
                            onFocusChange={this.onFocusChange}
                            onDatesChange={({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })}
                            focusedInput={focusedInput}
                            startDate={transientStartDate}
                            endDate={transientEndDate}
                            keepOpenOnDateSelect={false}
                            enableOutsideDays={false}
                            numberOfMonths={2}
                            minDate={minDate}
                            calendarInfoPosition={"after"}
                            renderCalendarInfo={() => (
                                <CalendarPane
                                       dateOptions={dateFilterFields}
                                       calendarOptionList={calendarOptionList}
                                       calendarOptionType={transientCalendarOptionType}
                                       submitDateFilter={submitDateFilter}
                                       clearDates={() => this.setState({transientStartDate: null, transientEndDate: null})}
                                       dateType={dateType}
                                       updateTransientCalendarOption={({calendarOptionType}) => this.setState({transientCalendarOptionType: calendarOptionType})}
                                       resetFilters={resetFilters}
                                       resetTransientState={resetTransientState}
                                />
                            )}
                            isOutsideRange={() => false}
                            phrases={phraseOverrides(intl)}
                            renderMonthElement={({ month }) => moment(month).locale(currLocale).format(intl.formatMessage({id: `calendar-format` }))}
                            startDateId={'startDate'}
                            endDateId={'endDate'}
                        />
                    </MediaQuery>
                    <MediaQuery maxWidth={599}>
                        <DateRangePicker
                            onFocusChange={this.onFocusChange}
                            onDatesChange={({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })}
                            focusedInput={focusedInput}
                            startDate={transientStartDate}
                            endDate={transientEndDate}
                            keepOpenOnDateSelect={false}
                            enableOutsideDays={false}
                            numberOfMonths={1}
                            minDate={minDate}
                            calendarInfoPosition={"bottom"}
                            renderCalendarInfo={() => (
                                <CalendarPane
                                       dateOptions={dateFilterFields}
                                       calendarOptionList={calendarOptionList}
                                       calendarOptionType={transientCalendarOptionType}
                                       submitDateFilter={submitDateFilter}
                                       clearDates={() => this.setState({transientStartDate: null, transientEndDate: null})}
                                       dateType={dateType}
                                       updateTransientCalendarOption={({calendarOptionType}) => this.setState({transientCalendarOptionType: calendarOptionType})}
                                       resetFilters={resetFilters}
                                       resetTransientState={resetTransientState}
                                       closeFocusSelector={closeFocusSelector}
                                />
                            )}
                            isOutsideRange={() => false}
                            phrases={phraseOverrides(intl)}
                            renderMonthElement={({ month }) => moment(month).locale(currLocale).format(intl.formatMessage({id: `calendar-format` }))}
                            startDateId={'startDate'}
                            endDateId={'endDate'}
                        />
                    </MediaQuery>
                    </React.Fragment>
                : null}
            </React.Fragment>
        );
    }
}

export default injectIntl(MetadataDateRangePicker)