import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from "mdbreact";
import ReactPortalModal from "../../../ReactPortalModal";
import InvoicesDownloadListTable from "../../InvoicesDownloadListTable/InvoicesDownloadListTable";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import * as invoiceApi from '../../../../api/invoice-api';
import { countryCodeToMerchantId } from "../../../../utils/utils";
import { isEmpty } from "lodash";

const InvoicesDownloadLinksModal = ({ isPlanInvoice }) => {
  const {filterType} = useParams()
  const [isOpen, setIsOpen] = useState(filterType==="download");
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [dataRows, setDataRows] = useState([]);
  const { selectedPaymentType: businessUnit } = useSelector((s) => s.auth.user);
  const { apiUrlPrefix, apiToken } = useSelector((s) => s.envProps);
  const { merchants } = useSelector((s) => s.config);
  const { user } = useSelector((s) => s.auth);
  const merchantId = countryCodeToMerchantId(merchants, user.selectedCountry);
  const intl = useIntl();
  const modalHeading = "invoice.downloadInvoices.title";

  useEffect(() => {
    const fetchList = async () => {
      let res = await invoiceApi.getDownloadInvoiceList(apiUrlPrefix, apiToken);
      res = await res.parsedBody;
      const filteredRes = res?.length > 0 ? res.filter(({isPlanInvoice:_isPlanInvoice})=> isPlanInvoice ? _isPlanInvoice : !_isPlanInvoice) : [];
      if(!isEmpty(filteredRes)){
        setIsDataAvailable(true)
      }
      setDataRows(filteredRes);
    };
    fetchList();
  }, []);

  return <div className="col-md-12 mt-3 mb-3 p-0">
    {isDataAvailable 
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      ? <a role='button' className="btn-text-link btn-minimal" onClick={() => setIsOpen(true)}>
        <FormattedMessage id="invoice.downloadInvoices.button" />
      </a> 
      : ''
    }
    {isOpen && <ReactPortalModal isOpen={isOpen} an_label="invoices-download-links">
      <MDBModal id={"batch-search-links-modal-modal"} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="lg" centered disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => setIsOpen(!isOpen)}>
          <FormattedMessage id={modalHeading} />
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size={"12"}>
              <InvoicesDownloadListTable caption= "invoice.downloadInvoices.title" isPlanInvoice={isPlanInvoice} apiUrlPrefix={apiUrlPrefix} dataRows={dataRows} />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn id={"batch-search-links-modal-btn-close"} color="primary" onClick={() => setIsOpen(!isOpen)}>
            <FormattedMessage id={"close.label"} />
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </ReactPortalModal>}
  </div>
};

export default InvoicesDownloadLinksModal;
