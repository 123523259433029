import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../actions/invoice-action";
import * as invoiceApi from '../../api/invoice-api';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody } from "mdbreact";
import { get as lodashGet } from 'lodash';

import ScreenBuilder from '../../components/InvoiceDetails/ScreenBuilder';

import UPSInvoiceSummary from '../../components/UPS/UPSInvoiceSummary';

class UPSInvoiceDetails extends React.Component {

    constructor(props) {
        super(props);
        const planInvoiceId = lodashGet(this.props, 'match.params.planInvoiceId');
        let accountDetails = lodashGet(this.props, 'history.location.state.accountDetails');

        // advanced search
        if (!accountDetails){
            const advancedSearchDetails = localStorage.getItem('advancedSearchDetails');
            const invoice = JSON.parse(advancedSearchDetails)
            accountDetails = {
                accountNumber: invoice.accountNumber,
                planNumber: invoice?.planNumber,
                recordType: invoice?.recordType,
                id: invoice?.invoiceHeader? invoice.invoiceId : invoice?.id,
                isDetail: invoice.hasOwnProperty('invoiceHeader')
            }
        }

        this.state = {
            planInvoiceId,
            screen: '',
            loaded: false,
            summaryData: [],
            accountDetails,
        };
    }

    componentDidMount() {
        const { invoiceActions } = this.props;
        const { planInvoiceId, accountDetails } = this.state;
        const { planNumber, isDetail } = accountDetails;

        invoiceActions.getInvoice(accountDetails, (invoice) => {
            this.onInit(invoice)
        });
        if(planInvoiceId){
            invoiceActions.getInvoice({planNumber, accountNumber: null, recordType:"PLAN", id: planInvoiceId, isDetail}, (planInvoice) => {
                this.setState({planInvoice});
            })
        }
    }

    async onInit(invoice, planInvoice) {
        const { envProps, user, merchantId, invoiceActions, invoiceMetadata, settings } = this.props;
        const { apiUrlPrefix, apiToken } = envProps;
        const { selectedCountry, locale} = user;

        let screen = '';
        for (const [key, value] of Object.entries(invoiceMetadata.screens)) {
            if (value.isRoot && value.businessUnit === invoice.businessUnit) {
                screen = key;
                break;
            }
        }

        const [ language, country ] = settings?.locale.split("-");
        invoiceActions.getInvoiceDetails({invoice, searchCriteria: {detailType: 'SUMMARY'}}, (summaryData) => {
            this.setState({
                summaryData
            });
        });
        invoiceActions.clearInvoiceDetailsFilters();
        const lang = language || locale;
        const countryCode = selectedCountry || country;


        const languageCode = (lang || '').toUpperCase().split(/[_-]+/)[0];
        const res = await invoiceApi.getInvoiceLiterals(languageCode, apiUrlPrefix, apiToken);
        const invoiceLiterals = res.parsedBody || {};

        this.setState({
            invoice,
            screen,
            invoiceLiterals,
            loaded: true
        })

        window.scrollTo(0, 0);
    }

    switchTab = (e, name) => {
        e.preventDefault();
        this.setState({
            selectedTab: name
        });
    }

    render() {
        const { invoiceMetadata, planEnrolled, intl } = this.props;
        const { screen, loaded, invoiceLiterals, invoice, planInvoice, summaryData } = this.state;
        const isFRTBU = invoiceMetadata.screens[screen]?.businessUnit === "FRT";

        if (!invoice || !loaded) return null;

        const invoiceTitle = isFRTBU ? "invoice.my-pros.title" : planEnrolled ? 'invoice.my-account-invoices.title' : 'invoice.my-invoices.title';
        const thirdLevelInvoiceTitle =  isFRTBU ? "invoice.freight-details.title" : "invoice.invoice-detail.title";
        const breadcrumbs = [
            <li className="ups-breadcrumb-item" key={'breadcrumb-1'}><Link to={'/home'} activeclassname="ups-link"><FormattedMessage id={"ups.title.dashboard"}/></Link><span aria-hidden="true"> &gt; </span></li>,
            <li className="ups-breadcrumb-item" key={'breadcrumb-2'}><Link to={'/ups/billing/invoice'} activeclassname="ups-link"><FormattedMessage id={invoiceTitle}/></Link><span aria-hidden="true"> &gt; </span></li>,
            <li className="ups-breadcrumb-item" key={'breadcrumb-3'}><FormattedMessage id={thirdLevelInvoiceTitle} /></li>
        ];

        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                {breadcrumbs}
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>

                                <UPSInvoiceSummary key={`invoice-summary-${intl.locale}`} invoice={invoice} planInvoice={planInvoice} screenConfig={invoiceMetadata.screens[screen]} invoiceLiterals={invoiceLiterals} summaryData={summaryData} />
                                <ScreenBuilder isTopLevel={true} caption={thirdLevelInvoiceTitle} key={`screen-builder-${intl.locale}`} invoice={invoice} screen={screen} screenConfig={invoiceMetadata.screens[screen]} invoiceLiterals={invoiceLiterals} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        planEnrolled: state.identity.planEnrolled,
        envProps: state.envProps,
        user: state.auth.user,
        settings: state.settings,
        merchantId: state.config.sessionSelection.merchant.id
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSInvoiceDetails));


