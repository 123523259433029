import React, {useState, useEffect}                   from 'react';
import {MDBRow, MDBCol}                                       from "mdbreact";
import ReactSelectAllGrouped                                  from '../../../../ReactSelectAllGrouped';
import { payMethodsToLabels }              from '../../../../../utils/payment-utils';
import {uniquePropVals} from "../../../../../utils/utils";
import {FormattedMessage, useIntl} from "react-intl";

const allOption = { value: "*", msgId: "filter.multi.option.dropdown.all.selector"}

export default function WalletFilterCriteria({walletList, filter, setFilter, defaultFilter, setDefaultFilter}) {
    const intl = useIntl();
    const [pmTypeOptions, setPmTypeOptions] = useState([]);
    useEffect(() => {
        let pmTypes = uniquePropVals(walletList, 'methodType').map((methodType)=>{
            let text = payMethodsToLabels(methodType).id
            return {value: text, msgId: text}
        })
        pmTypes.unshift(allOption)
        setPmTypeOptions([{
            msgId: "react-select.select-your-filters",
            options: pmTypes
        }])
        setDefaultFilter({methodType: pmTypes})
    }, []);
    return (
        <React.Fragment>
            <MDBRow>
                <MDBCol size="12">
                    <ReactSelectAllGrouped
                        label={intl.formatMessage({id:'payment-method-table.payment-method-type'})}
                        inputId="paymentMethodType"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={pmTypeOptions}
                        isMulti intlLabel allowSelectAll
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(selected)=>setFilter('methodType', selected)}
                        isSearchable={false}
                        backspaceRemovesValue={false}
                        value={filter?.methodType ?? defaultFilter?.methodType}
                        placeholder={<FormattedMessage id="payment-method.placeholder" />}
                    />
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    );
}
