import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/auth-action";

import { getQueryParams } from "../../utils/utils";
import * as configActions from "../../actions/config-action";

import { FormattedMessage } from 'react-intl';

/**
 * This component is the login page. If a token is passed in via query parameter we will treat it as an sso token and start the login process.
 */
class LoginCallback extends Component {

  constructor (props) {
    super(props);
    /* delete any existing state so UI does not load until response comes back for login api */
    this.props.authActions.init();
    this.state = {};
  }


  componentDidMount () {
    const query = getQueryParams(this.props.location.search, false);
    const { code, client_id, response_type, state } = query;  
    
    if (code && state && !this.props.auth.user) {
      const data = {
        code, client_id, response_type, lastClientRoute: state
      };
      this.props.authActions.ssoCallback(data);
      this.props.history.replace({ pathname: '/login/validation' }, []);
    } else {
      this.props.history.push(state ? state : '/home');
    }
  }

  render () {

    const { errors } = this.props;

    if (errors) {
      return (
        <div className={"ups-landing-container"}>
          <div className="text-center mb-4 mt-5">
            <FormattedMessage id={errors[0]} />
          </div>
        </div>
      )
    }

    return (
      <div className={"ups-landing-container"}>
        <div className="text-center mb-4 mt-5">
          <FormattedMessage id="ups.bc.access"></FormattedMessage>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: state.auth,
    enroll: state.auth.enroll,
    merchants: state.config.merchants,
    errors: state.error.errors
  }
}

function mapDispatchToProps (dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    configActions: bindActionCreators(configActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback);

