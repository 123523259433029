import * as actionTypes from '../constants/action-types';
import {getCountries} from "../utils/utils";

const INTITIAL_STATE = {
  threeDSecureDetails: {}
}

export default function reducer(state = INTITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_MERCHANTS_SUCCESS: return {
      ...state,
      merchants: action.data,
      countries: getCountries(action.data)
    }

    case 'SESSION_SELECTION_CHANGED': return {
      ...state,
      sessionSelection: action.sessionSelection
    }

    case actionTypes.ENTER_3DS: return {
      ...state,
      threeDSecureDetails: action.data,
    }

    case actionTypes.COMPLETE_3DS: return {
      ...state,
      threeDSecureDetails: {},
    }

    default: return state;
  }
}
