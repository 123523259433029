import { Component } from 'react'
import ReactDOM from "react-dom";

const spinnerRoot = document.getElementById('loader-spinner-root');

/** Read more about react portals: https://reactjs.org/docs/portals.html
 *  When modals are wrapped with ReactPortalSpinner, they'll be attached to the modal-root in the public/index.html page
 *  Therefore avoiding any css issues that arise from where the component is inserted in our pages as they may vary
*/

class ReactPortalSpinner extends Component {
    constructor(props) {
        super(props);
        // Create a div that we'll render the modal into. Because each
        // Modal component has its own element, we can render multiple
        // modal components into the modal container.
        this.el = document.createElement('div');
        this.el.className = 'loader-container';
    }

    componentDidMount() {
        // Append the element into the DOM on mount. We'll render
        // into the modal container element (see the HTML tab).
        spinnerRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        // Remove the element from the DOM when we unmount
        spinnerRoot.removeChild(this.el);
    }

    render() {
        // Use a portal to render the children into the element
        return ReactDOM.createPortal(
            // Any valid React child: JSX, strings, arrays, etc.
            this.props.children,
            // A DOM element
            this.el,
        );
    }
}
export default ReactPortalSpinner;
