const vaSupportedCountries = [
    'US', 
    'CA'
];

function setPageIdForChatbot(pathname, selectedPaymentType, countryCode, closeBeforeReinit) {
    /* Set page_id empty by default and provide value based on the following conditions:
       - current route is among the list of invocation points
       - selected BU is Small package
       - countryCode is either US or CA since VA is available only for these 2 countries.

       For empty page_id, chat window hides itself automatically. 
    */
    window.utag_data.page_id = "";

    if (selectedPaymentType !== 'EBS' || !vaSupportedCountries.includes(countryCode)) {
        //This function will end the chat session, include server logs for closing the chat, and reload the Nuance framework
        window.Inq?.fireCustomEvent('closeChat');
        return;
    }

    if(closeBeforeReinit){
        window.Inq?.fireCustomEvent('closeChat');
    }

    let invocationRoutes = [
        '/home',
        '/ups/billing/invoice',
        '/admin/manage/accounts',
        '/ups/billing/wallet',
        '/ups/billing/profile'
    ];

    //using indexOf so chatbot works for the sub-sections of the required pages 
    const pageId = invocationRoutes.find(path => pathname.indexOf(path) > -1);
    if (pageId) {
        window.utag_data.page_id = pageId;
    }
    
    window.Inq?.reinitChat();
}

/* nuance chatbot function 
 * update page_id and notify nuance framework that the content of the page has changed
 */
export function updateChatbotTracking(options) {
    const { pathname, enableChatbot, selectedPaymentType, countryCode, closeBeforeReinit } = options;
    /* if chatbot setting is off, then return from this point */
    if (!enableChatbot) {
        return;
    }

    if (!pathname) {
        pathname = window.location.pathname;
    }
    
    setPageIdForChatbot(pathname, selectedPaymentType, countryCode, closeBeforeReinit);
}