import * as R from 'ramda';

export const getTotalAmount = R.compose
  ( R.flip(R.divide) (100)
  , R.reduce((total, {amount}) => total + (amount * 100)) (0)
  );

export const isRefund = R.compose
  ( R.flip(R.lt) (0)
  , getTotalAmount
  );
