import React, { Component } from 'react'
import { MDBBtn, MDBRow, MDBCol, MDBSelect, MDBAutocompleteV5, MDBPopover, MDBPopoverBody  } from "mdbreact";
import { FormattedMessage, injectIntl } from 'react-intl';
import * as invoiceApi from '../../../api/invoice-api';
import {countryCodeToMerchantId} from "../../../utils/utils";
import {connect} from "react-redux";
import {DropdownOption, MultiSelectOption, TypeAheadOption} from '../FilterOptions';
import {getLabel, getTranslatedOption} from "../../../utils/invoice-utils";
import {get as lodashGet, isEmpty} from 'lodash';

class FilterBuilder extends Component {

    constructor(props) {
        super(props);
        const {intl, invoiceLiterals} = props;
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });
        this.formatOption = getTranslatedOption.bind({ intl, invoiceLiterals });

        this.state = {
            saveSearchCriteria: false,
            loaded: false,
        };
    }

    getFilterAggregateData = async (filterCriteria) => {
        const {conditionals} = this.props;
        const { invoiceId, requestParams, fields, accountNumber, recordType, planNumber } = filterCriteria;
        const {merchantId, detailType, userId, apiUrlPrefix, apiToken} = requestParams;
        const filterData = (await invoiceApi.getFilterAggregate(detailType, fields, conditionals, invoiceId, apiUrlPrefix, apiToken, { accountNumber, recordType, planNumber })).parsedBody;
        const filterAggregate = {};

        if (filterData && filterData.data) {
            for (let [key, value] of Object.entries(filterData.data)) {
                filterAggregate[key] = { aggregateData: Object.keys(value) }
            }
        }
        return filterAggregate;
    }

    onInit = async () => {
        const { filters, onUpdateFilters, initialFilters, intl, invoice, detailType, invoiceLiterals, onAggregateDataLoaded} = this.props;
        const { merchantId, envProps, user, setAllSelections } = this.props;
        const { apiUrlPrefix, apiToken } = envProps;
        const requestParams = {
            merchantId,
            detailType,
            userId: user.externalId,
            apiUrlPrefix,
            apiToken
        };

        const aggregateFields = [];
        for (const filter of filters) {
            if (filter && filter.filter.aggregate) {
                aggregateFields.push(filter.field);
            }
        }
        const aggregateData = await this.getFilterAggregateData({
                fields: aggregateFields.join(','),
                requestParams,
                invoiceId: invoice?.id,
                ...(invoice?.accountNumber ? { accountNumber: invoice?.accountNumber } : {}),
                ...(invoice?.planNumber ? { planNumber: invoice?.planNumber } : {}),
                ...(invoice?.recordType ? { recordType: invoice?.recordType } : {})
                
        });
        const components = [];

        const aggregateDataIsEmpty = isEmpty(aggregateData)
        onAggregateDataLoaded(aggregateDataIsEmpty);
        if(aggregateDataIsEmpty) return // skip the below code if no aggregate data as it will produce a console error and we'll display "no data to filter" in this case anyway
        try {
            let allSelections = null
            for (const filter of filters) {
                const filterType = filter.filter.type;
                let data = {
                    ...filter,
                    ...aggregateData[filter.field],
                    existingFilters: initialFilters,
                    onUpdateFilters,
                    intl,
                    invoiceLiterals,
                    formatLabel: this.formatLabel,
                    formatOption: this.formatOption
                };
                if (filterType === 'dropdown') {
                    components.push(
                        <DropdownOption
                            key={filter.field}
                            getCheckedFilter={(existingFilters, field)=>lodashGet(existingFilters, field)?.[0]}
                            {...data}
                        />
                    );
                } else if (filterType === 'multi-select') {
                    if (!allSelections) allSelections = {}
                    allSelections[filter.field] = aggregateData[filter.field].aggregateData
                    components.push(<MultiSelectOption key={filter.field} {...data}/>);
                } else if (filterType === 'type-ahead') {
                    components.push(<TypeAheadOption key={filter.field} {...data}/>);
                }
            }
            this.setState({
                components
            }, setAllSelections(allSelections));
        } catch (err) {
            console.error(err);
        }
        
    }

    componentDidMount() {
        this.onInit();
    }

    toggleModal = (modalName, dataIndex = 0) => {
        this.setState({
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    render() {
        const { modalMode, aggregateDataIsEmpty } = this.props;
        const { components } = this.state;
        if (aggregateDataIsEmpty === null) return null;

        const modalComponents = [];
        
        if(aggregateDataIsEmpty){
            return <h3 role="alert" className="text-center"><FormattedMessage id={"invoice.no-data-to-filter"}/></h3>
        }

        return(
            <React.Fragment>
                <MDBRow>
                    {components}
                </MDBRow>
                {!modalMode ?
                    <MDBRow className={"mt-3"}>
                        <MDBCol size={"12"}>
                            <MDBBtn color="secondary" className="m-0 mr-3"><FormattedMessage id={"clear.label"}/></MDBBtn>
                            <MDBBtn color="primary" className="m-0" ><FormattedMessage id={"search.label"}/></MDBBtn>
                        </MDBCol>
                    </MDBRow>
                : null}
                {modalComponents}
            </React.Fragment>
        );
    }
}


function mapStateToProps(state, ownProps) {
    const { auth, envProps, config } = state;
    const { user } = auth;

    let merchantId;
    if (config && config.merchants && user && user.selectedCountry) {
        merchantId = countryCodeToMerchantId(config.merchants, user.selectedCountry);
    }

    return {
        merchantId,
        envProps,
        user: auth.user
    };
}

export default injectIntl(connect(mapStateToProps, null)(FilterBuilder));
