import React, { useEffect, useState }       from "react";
import { useSelector}                       from "react-redux";
import supportLinks                         from '../constants/support-links';
import { FormattedMessage, useIntl }                 from 'react-intl';
import { Link }                             from 'react-router-dom';
import { upsHelpUrlFormatter } from "../utils/utils";

export default function UPSServiceTerms() {
    const { supportBaseUrl, upsBaseUrl, upsServiceResource, upsWebsiteResource, websiteConditionsResource, termsResource  } = supportLinks;
    const { countryLocale } = useSelector(s => s.settings);
    const { selectedCountry, locale } = useSelector(s => (s.auth.user || s.auth.userInfo));
    const { user } = useSelector(s => s.auth);
    const [ upsServiceTermsUrl, updateUPSServiceTermsUrl ] = useState();
    const [ upsWebsiteTermsUrl, updateUPSWebsiteTermsUrl ] = useState();
    const [ paymentusWebsiteUrl, updatePaymentusWebsiteUrl ] = useState();
    const [ paymentusTermsUrl, updatePaymentusTermsUrl ] = useState();
    const path = require('path');
    const intl = useIntl();
    useEffect(() => {
        let language = locale ? locale.split("-")[0] : null;
        let country = selectedCountry ? selectedCountry.toLowerCase() : null;
        
        if(countryLocale){
            let arr = countryLocale.split("-");
            language = arr[0];
            country = arr[1].toLowerCase();
        }

        if(language && country){                             
            const urlServiceTerms = upsBaseUrl + path.join(upsHelpUrlFormatter(country, language), upsServiceResource);
            const urlWebsiteTerms = upsBaseUrl + path.join(upsHelpUrlFormatter(country, language), upsWebsiteResource);
            const urlPaymentusWebsiteTerms = supportBaseUrl + path.join(country, language, websiteConditionsResource);
            const urlPaymentusTerms = supportBaseUrl + path.join(country, language, termsResource);
            
            updateUPSServiceTermsUrl(urlServiceTerms);
            updateUPSWebsiteTermsUrl(urlWebsiteTerms);
            updatePaymentusWebsiteUrl(urlPaymentusWebsiteTerms);
            updatePaymentusTermsUrl(urlPaymentusTerms);
        }
    }, [countryLocale]);

    return (
        <React.Fragment>
            <div role="main" className="container-fluid">
                <div className="row">
                    <div className="col"> 
                        <div className="card-body">
                            <div className={user? "align-body":"align-body-enroll"}>
                            {user? <div className="row">
                                    <div className="col-12">
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.add-account-breadcrumb-dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                    <FormattedMessage id='ups.title.service-terms' />
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>                                   
                                </div> : null
                            }
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className={user? "has-breadcrumb":"help-support-header"}><FormattedMessage id='ups.title.service-terms' /></h1>
                        
                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <a role='link' href={upsServiceTermsUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                    <span className={user ? "" : "enroll-align"}>  <FormattedMessage id='ups.service-terms' /> </span>
                                                    <span className="material-icons">open_in_new</span>
                                                </a>
                                            </div>   
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <a role='link' href={upsWebsiteTermsUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                    <span className={user? "":"enroll-align"}><FormattedMessage id='ups.website-terms' /></span>
                                                    <span className="material-icons" >open_in_new</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <a role='link' href={paymentusWebsiteUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                    <span className={user ? "" : "enroll-align"}><FormattedMessage id='ups.website-conditions' /></span>
                                                    <span className="material-icons" >open_in_new</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <a role='link' href={paymentusTermsUrl} target="_blank" title={intl.formatMessage({id:"ups.new-tab.link.label"})}>
                                                <span className={user ? "" : "enroll-align"}> <FormattedMessage id='ups.authorization-terms' /></span>
                                                    <span className="material-icons">open_in_new</span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}