import React, { Component } from 'react';
import classnames from 'classnames';

// Modified MDBInput to support placeholder with react-intl
// Because <FormattedMessage /> is a component which cannot be placed in placeholder which expects a raw String.
class DisplayOnlyField extends Component{
    render(){
        const { label, value, wrapperMode, className } = this.props;
        // if type is radio or checkbox, please ensure they each have a unique ID
        if (wrapperMode) {
            return (
                <div className={classnames("md-form", className)}>
                    <span className="display-label">{label}</span>
                    <span className="display-plaintext">{value}</span>
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <span className="display-label">{label}</span>
                    <span className="display-plaintext">{value}</span>
                </React.Fragment>
            )
        }
    }
}

export default DisplayOnlyField;
