import React, {useEffect, useReducer}                       from "react";
import {
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter,
    MDBRow, MDBCol, MDBBtn
}                                                           from "mdbreact";
import {FormattedMessage, useIntl}                          from "react-intl";
import {useSelector}                                        from "react-redux";
import * as R                                               from "ramda";
import * as invoiceTypes                                    from "../../../constants/invoice-types";

import {useValidator}                                       from "../../../utils/validation";
import {InputRestrictors as restrict}                       from "../../../utils/validation/standard-validations";
import {stripAccountNumber}                                 from "../../../utils/ups-utils";
import {formatCurrency, isCredit}                                     from "../../../utils/invoice-utils";
import {encodeText64, decodeText64}                         from "../../../utils/utils";
import {getPartialPayReasons}                               from "../../../utils/config-utils";

import DisplayTable                                         from "../../../pages/UPSPayInvoice/DisplayTable";
import ReactPortalModal                                     from "../../ReactPortalModal";
import Error                                                from "../../Error";
import DisplayOnlyField                                     from "../../DisplayOnlyField";
import SelectFieldIntl                                      from "../../SelectFieldIntl";
import InputFieldIntl                                       from "../../InputFieldIntl";
import CurrencyFieldIntl                                    from "../../CurrencyFieldIntl";
import MDBBtnWrapper from "../../MDBFix/MDBBtnWrapper";
// Helpers
const set = (k, v) => R.set(R.lensProp(k), v);
const over = (k, f) => R.over(R.lensProp(k), f);
const invoiceToString = ({formatMessage: f}) => inv => R.compose(
    R.concat(inv.planNumber ? f(
        {id : "modal.view-schedule.plan.label"},
        {plan: stripAccountNumber(inv.planNumber)}
    ) + " " : ""),
    ({accountNumber, businessUnit}) => f(
        {id : "modal.view-schedule.account.label"},
        {account: stripAccountNumber(accountNumber, businessUnit)}
    )
)(inv);
const reasonsToOptions = ({formatMessage: f}) => R.compose(
    R.prepend({
        value: "",
        label: f({id: "modal.partial-pay.reason.select"})
    }),
    R.map(value => ({
        value,
        label: f({id: `modal.partial-pay.reason.${value}`})
    }))
);
const loadInvoice = inv => R.compose(
    set("paymentAmount", inv.paymentAmount),
    set("partialPayReason", inv.partialPayReason || ""),
    set("partialPayDescription", inv.partialPayDescription
        ? decodeText64(inv.partialPayDescription)
        : ""
    )
);
const saveState = (state, isRefund) => R.compose(
    set("paymentAmount", (isRefund ? -Math.abs(state.paymentAmount) : state.paymentAmount)),
    R.ifElse(
        R.propEq("outstandingAmount", state.paymentAmount),
        R.omit(["partialPayReason", "partialPayDescription"]),
        R.compose(
            set("partialPayReason", state.partialPayReason),
            set("partialPayDescription", encodeText64(state.partialPayDescription))
        )
    )
);

// State tools
const STEPS = [
    {
        id: "partial",
        btn2: "ups.btn.next.label",
        btn3: "ups.btn.cancel.label"
    },
    {
        id: "confirm",
        submitOnBtn2: true,
        btn1: "ups.btn.back.label",
        btn2: "ups.btn.submit.label",
        btn3: "ups.btn.cancel.label"
    }
];

const INITIAL_STATE = {
    error: "",
    partialPayReasons: [],
    step: 0,
    paymentAmount: 0,
    partialPayReason: "",
    partialPayDescription: ""
};

function reducer(state, action) {
    return (() => {
        switch (action.type) {
            // Modal flow
            case "SOFT_RESET": return R.compose(
                set("step", 0),
                loadInvoice(action.invoice)
            );
            case "ERROR": return set("error", action.error || "");
            case "PREV": return over("step", R.dec);
            case "NEXT": return over("step", R.inc);
            // Cache reason codes from merchant
            case "REASONS": return set("partialPayReasons", action.partialPayReasons);
            // Form fields
            case "AMOUNT": return set("paymentAmount", action.paymentAmount);
            case "REASON": return set("partialPayReason", action.partialPayReason);
            case "DESCRIPTION": return set("partialPayDescription", action.partialPayDescription);
            default: return R.identity;
        }
    })()(state);
}

export default function PartialPayModal({
    modalId = "partialPayModal", isOpen, toggleModal,
    invoice, currencyDisplay, onChange
}) {
    const [state, dispatch] = useReducer(
        reducer,
        loadInvoice(invoice)(INITIAL_STATE)
    );
    const intl = useIntl();
    const isRefund = isCredit(invoice)

    // Validation
    const validator = useValidator();
    const {vFields, vState} = useSelector(s => s.validation);

    // Get reason codes
    const {selectedCountry: country} = useSelector(s => s.auth.user);
    const {sessionSelection} = useSelector(s => s.config);
    const {merchant} = sessionSelection;
    useEffect(() => {
        const partialPayReasons = getPartialPayReasons(merchant);
        dispatch({type: "REASONS", partialPayReasons});
    }, [country, merchant]);

    // onOpen/onInvoiceSwitch
    useEffect(() => {
        if (!isOpen) return;
        dispatch({type: "SOFT_RESET", invoice});
    }, [isOpen, invoice.id]);

    // Handlers
    const close = () => toggleModal(modalId);
    const prev = () => dispatch({type: "PREV"});
    const next = () => {
        const result = validator.validateAll();
        if (result.messages.length > 0) return;

        if (STEPS[state.step].submitOnBtn2) {
            onChange(
                invoice.id,
                saveState(state, isRefund)(invoice)
            );
        }

        if (state.step + 1 < STEPS.length)
            dispatch({type: "NEXT"});
        else close();
    };

        const modalHeading = isRefund ? "modal.partial-pay.refund.title" : "modal.partial-pay.title";
        return <ReactPortalModal isOpen={isOpen} an_label="partial-pay">
            <MDBModal isOpen={isOpen} toggle={close} size={isRefund ? "md" : "lg"} disableBackdrop centered disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={close}>
                    <FormattedMessage id={isRefund ? "modal.partial-pay.refund.title" : "modal.partial-pay.title"} />
                </MDBModalHeader>

                <MDBModalBody>
                    <Error
                        errors={state.error ? [state.error] : []}
                        vFields={vFields} vState={vState}
                    />

                    {STEPS[state.step].id === "partial" && <MDBRow>
                        {!isRefund && <MDBCol size="12">
                            <FormattedMessage id="modal.partial-pay.description" values={{
                                b: (...chunks) => <b>{chunks}</b>,
                                invoice: invoiceToString(intl)(invoice)
                            }} />
                        </MDBCol>}

                        <MDBCol size={"12"}>
                            <p className="ups-note-1 mb-0">*<span className="font-italic"><FormattedMessage id="ups.required-field.note" /></span></p>
                        </MDBCol>

                        <MDBCol size="12" md={!isRefund && "6"}>
                            <CurrencyFieldIntl name="paymentAmount" id="paymentAmount"
                                label={intl.formatMessage({ id: isRefund ? "modal.payment.amount.to.refund" : "modal.payment.payment.amount" })}
                                hint={`make-payment.payment-components-table.input.payment-amount.placeholder${Number.isNaN(parseInt(merchant.maxCurrencyDigits)) ? '': ("." + merchant.maxCurrencyDigits)}`}
                                value={Math.abs(state.paymentAmount)}
                                onChange={({ target: { value } }) => dispatch({
                                    type: "AMOUNT",
                                    paymentAmount: value
                                })}
                                onBlur={({ value }) => dispatch({
                                    type: "AMOUNT",
                                    paymentAmount: value
                                })}
                                maxLength={15}
                                restrict={restrict.currency}
                                required
                                isNegative={isRefund}
                                maxCurrencyDigits={merchant.maxCurrencyDigits}
                                validations={[
                                    ["required", "field.currency"],
                                    ["currency", "field.currency"],
                                    ["nonzeroAmount", "field.currency", { max: Math.abs(invoice.outstandingAmount) }]
                                ]}
                            />
                        </MDBCol>

                        <MDBCol size="12">
                            <DisplayOnlyField
                                className="mt-3"
                                label={intl.formatMessage({ id: isRefund ? "modal.partial-pay.total-available-credit" : "invoice.invoice-table.amount-due" })}
                                value={formatCurrency(intl, invoice.outstandingAmount, invoice.currencyCode, currencyDisplay)}
                                wrapperMode
                            />
                        </MDBCol>

                        {!isRefund && <>
                            <MDBCol size="12">
                                <SelectFieldIntl name="partialPayReason" id="partialPayReason"
                                    extraContainerClasses="mt-3"
                                    label={intl.formatMessage({ id: "modal.partial-pay.reason" })}
                                    value={state.partialPayReason}
                                    selectOptions={reasonsToOptions(intl)(state.partialPayReasons)}
                                    onChange={({ target: { value } }) => dispatch({
                                        type: "REASON",
                                        partialPayReason: value
                                    })}
                                    setLabelBefore
                                    required
                                    validations={[
                                        ["required", "field.required"]
                                    ]}
                                />
                            </MDBCol>

                            <MDBCol size="12">
                                <InputFieldIntl name="partialPayDescription" id="partialPayDescription"
                                    type="textarea"
                                    label={intl.formatMessage({ id: "modal.partial-pay.comments" })}
                                    hint=""
                                    value={state.partialPayDescription}
                                    onChange={({ target: { value } }) => dispatch({
                                        type: "DESCRIPTION",
                                        partialPayDescription: value
                                    })}
                                    onBlur={({ target: { value } }) => dispatch({
                                        type: "DESCRIPTION",
                                        partialPayDescription: R.trim(value)
                                    })}
                                    maxLength={250}
                                    rows="5"
                                    labelClass="active"
                                />
                            </MDBCol>
                        </>}
                    </MDBRow>}

                    {STEPS[state.step].id === "confirm" && <MDBRow>
                        <MDBCol size="12">
                            <FormattedMessage id={isRefund ? "modal.partial-pay.refund.confirmation" : "modal.partial-pay.confirmation"} values={{
                                b: (...chunks) => <b>{chunks}</b>,
                                invoice: invoiceToString(intl)(invoice)
                            }} />
                        </MDBCol>

                        <MDBCol size="12">
                            <div className="mt-5 mb-5">
                                <ReviewTable {...state} invoice={invoice} isRefund={isRefund} currencyDisplay={currencyDisplay}/>
                            </div>
                        </MDBCol>
                    </MDBRow>}
                </MDBModalBody>

                <MDBModalFooter>
                    <MDBRow>
                        <MDBCol size="12" className="modal-button-col">
                            {STEPS[state.step].btn1 &&
                                <MDBBtnWrapper label={intl.formatMessage({id: STEPS[state.step].btn1})} color="secondary" className="order-1" onClick={prev}>
                                </MDBBtnWrapper>
                            }
                            {STEPS[state.step].btn2 &&
                                <MDBBtnWrapper label={intl.formatMessage({id: STEPS[state.step].btn2})} color="primary" className="order-first" disabled={!!state.error} onClick={next}>
                                </MDBBtnWrapper>
                            }
                        </MDBCol>
                    </MDBRow>
                    {STEPS[state.step].btn3 &&
                        <MDBRow>
                            <MDBCol size="12" className="modal-button-col">
                                <MDBBtnWrapper label={intl.formatMessage({id: STEPS[state.step].btn3})} color="cancel" className="order-last" onClick={close}>
                                </MDBBtnWrapper>
                            </MDBCol>
                        </MDBRow>
                    }
                </MDBModalFooter>
            </MDBModal>
        </ReactPortalModal>
}

function ReviewTable({invoice, paymentAmount, partialPayReason, partialPayDescription, isRefund, currencyDisplay}) {
    const intl = useIntl();
    const isPartial = paymentAmount < invoice.outstandingAmount;
    const rows = [
        {
            label: isRefund ? "modal.payment.refund.amount" : "modal.payment.payment.amount",
            value: formatCurrency(intl, isRefund ? -Math.abs(paymentAmount) : paymentAmount, invoice.currencyCode, currencyDisplay)
        },
        {
            label: isRefund ? "modal.partial-pay.total-available-credit" : "invoice.invoice-table.amount-due",
            value: formatCurrency(intl, invoice.outstandingAmount, invoice.currencyCode, currencyDisplay)
        },
        {
            label: "modal.partial-pay.reason",
            value: intl.formatMessage({id: `modal.partial-pay.reason.${partialPayReason}`}),
            hide: !isPartial
        },
        {
            label: "modal.partial-pay.comments",
            value: partialPayDescription,
            hide: !isPartial
        }
    ];
    return <DisplayTable rows={rows}/>;
}
