import React, {useEffect} from "react";
import $ from 'jquery';
import propTypes from 'prop-types';

//focuses a child component when mounted or when a specific observed value is modified and a requirement is met
//can only accept a single child component.

export const AutoFocus = ({ observe, requirement = true, children: child}) => {
    useEffect(() => {
        if(requirement) $(`#${child.props.id}`).focus()
    }, observe)
    return child
}

AutoFocus.propTypes = {
    observe: propTypes.array, //array of values, when any of these values change we will check if we should autofocus again. if not defined we will only attempt to autofocus once when the component is mounted.
    requirement: propTypes.bool, //if this is true, we will focus the child element.
    children: propTypes.shape({
        props: propTypes.shape({
            id: propTypes.string.isRequired //the child element must have an id assigned in order to be focused on
        })
    })
}