/*
 * Copyright ©2021 Paymentus Inc.
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 5/14/21, 12:40 PM
 * Author: kelvin
 */

import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getQueryParams} from "../utils/utils";
import {clearExtraCookies, removeCookie} from "../utils/cookie-utils";
import * as authActions from "../actions/auth-action";

export default function BdLogin(props) {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [mounted, setMounted] = useState(false);
    const auth = useSelector(s => s.auth);
    const {enroll} = useSelector(s => s.auth);
    const {merchants} = useSelector(s => s.config);

    useEffect(() => {
        // clean up local storage and cookies
        dispatch(authActions.init());
        removeCookie({name: "csr"});
        removeCookie({name: "st"});
        clearExtraCookies();

        const params = getQueryParams(props.location.search);
        const {externalId, emailAddress, firstName, lastName, loginId} = params;

        if (externalId && emailAddress) {
            dispatch(authActions.ssoCallback({
                user: {
                    userId: externalId,
                    emailAddress,
                    firstName: firstName || `${externalId}FN`,
                    lastName: lastName || `${externalId}LN`,
                    loginId: loginId || externalId,
                    bd: true
                }
            }));
        } else {
            setError("Missing required parameters: externalId, emailAddress");
        }
        setMounted(true);
    }, []);

    useEffect(() => {
        if (mounted && merchants) {
            //on completion of login, clear any billing center browser session data (at the moment just session filters)
            sessionStorage.removeItem("sessionFilters");
            if (enroll) {
                props.history.push({pathname: '/enrollment'});
            }
            if (auth.user && auth.merchants) {
                props.history.push({pathname: auth.user.lastVisitedPage});
            }
        }
    }, [merchants, auth, enroll, mounted]);

    return (
        <div className={"ups-landing-container"}>
            <div className="text-center mb-4 mt-5">
                {error ? error : "Logging in"}
            </div>
        </div>
    );
}
