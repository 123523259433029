import React, {useReducer} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody} from "mdbreact";
import * as R from "ramda";

import {formatCurrency, isOverpayment} from "../../utils/invoice-utils";
import {useValidator} from "../../utils/validation";

import PayInvoiceTable from "./PayInvoiceTable";
import Details from "./Details";
import ValidationErrors from "../../components/ValidationErrors";
import PartialPayModal from "../../components/UPS/Modal/PartialPayModal";
import {useDispatch, useSelector} from "react-redux";
import * as layoutActions from "../../actions/layout-action";
import {getMaxInvoices} from "../../utils/config-utils";
import Alert from "../../components/Alert";
import {invoicesCreditedAmount, invoicesTotalAmount, invoicesTotalDue} from "../../utils/payment-utils";

const isInputOverPayment = (value) => R.gt(value[0], value[1]);
const isZeroPayment = (value) => R.gte(0, value[0]);
const isCredit = (value) => R.lt(value[0],0)
const isInvalidPaymentAmount = R.both(R.compose(R.not, isCredit), R.anyPass([ isZeroPayment, isInputOverPayment ]))

const countInvalidInvoicePayments = R.compose(
    R.length,
    R.filter(R.compose(
        isInvalidPaymentAmount,
        R.props(["paymentAmount", "outstandingAmount"])
    ))
);
const invoiceListIsValidSize = (MAX_INVOICES)=>R.compose(
    R.both(R.lte(1), R.gte(MAX_INVOICES)),
    R.length
);

function Card({title, children}) {
    return <div className="summary-widget-item account-summary-item">
        <div className="summary-widget-item-label payment-summary-item-label">
            <FormattedMessage id={title}/>
            <br/>
            <span className="payment-summary-item-value">{children}</span>
        </div>
    </div>;
}

// Step 1
const INITIAL_STATE = {
    errors: [],
    errorArgs: {},
    invoice: {},
    partialPayModal: false
};

function reducer(state, action) {
    const {
        error, errorArgs = {},
        modalId, invoice
    } = action;

    switch (action.type) {
        case "ERROR": return {
            ...state,
            errors: error ? [error] : [],
            errorArgs
        };
        case "CLOSE_MODAL": return {
            ...state,
            [modalId]: false
        };
        case "OPEN_MODAL": return {
            ...state,
            [modalId]: true,
            invoice
        };
        default: return state;
    }
}

export default function InvoiceInformation({selectedInvoices, onChange, onNext, onCancel, creditAllowed, isCanProceed, isOnlyCreditNotes, flowConsts}) {
    const intl = useIntl();
    const validator = useValidator();
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const reduxAction = useDispatch();
    const {merchant} = useSelector(s=>s.config.sessionSelection)
    const MAX_INVOICES = getMaxInvoices(merchant);

    const paymentInvoices = [...selectedInvoices]
        .map(p => p[1])
        .filter(inv => !inv.deselected);
    const totalDue = invoicesTotalDue(paymentInvoices)
    const totalAmount = invoicesTotalAmount(paymentInvoices)
    const {currencyCode} = [...selectedInvoices][0]?.[1] || {};
    const creditedAmount = invoicesCreditedAmount(paymentInvoices)

    const validateInvoiceList = invoices => {
        let hasOverpayment = false
        invoices.forEach((invoice)=>{
            hasOverpayment |= isOverpayment(invoice)
        })
        if(totalAmount < 0 && hasOverpayment) {
            reduxAction(layoutActions.openBasicModal({message:"invoice.refund-not-eligible.body",title:"invoice.refund-not-eligible.title"}))
            return false
        }
        else if(totalAmount === 0) {//FIXME: Temporary block on any payment that results in 0 owed
            reduxAction(layoutActions.openBasicModal({message:"invoice.zero-payment.body",title:"invoice.zero-payment.title"}))
            return false
        }
        const numInvalid = countInvalidInvoicePayments(invoices);
        if (numInvalid <= 0) return true;
        dispatch({
            type: "ERROR",
            error: "invoiceInfo.invalidPayments",
            errorArgs: {numInvalid, total: invoices.length}
        });
        validator.validateAll();
        return false;
    };

    return (<Details submitLabel="make-payment.btn.continue" 
        onSubmit={(...args) => {
            if(validateInvoiceList(paymentInvoices)){
                onNext(...args)
            }
        }}
        onCancel={onCancel}
        canProceed={isCanProceed && invoiceListIsValidSize(MAX_INVOICES)(paymentInvoices)}
    >
        <MDBRow>
            <MDBCol size="12">
                <MDBCard className="summary-widget-card">
                    <MDBCardHeader tag="h2" className="summary-widget-title">
                        <FormattedMessage id="make-payment.payment-summary.card-header.title"/>
                    </MDBCardHeader>

                    <MDBCardBody className="summary-widget-body">
                        <div className="summary-widget-items dashboard-account-summary-items">
                            <Card title={flowConsts.getKey("invoicesToPayCount")}>
                                {paymentInvoices.length}
                            </Card>
                            <Card title="make-payment.payment-components-table.total-due">
                                {formatCurrency(intl, totalDue.toString(), currencyCode, merchant.currencyDisplay)}
                            </Card>
                            {creditedAmount !== 0 && <Card title="make-payment.payment-components-table.credited-amount">
                                {formatCurrency(intl, creditedAmount.toString(), currencyCode, merchant.currencyDisplay)}
                            </Card>}
                            <Card title={totalAmount < 0 ? "make-payment.payment-components-table.total-refund" : "make-payment.payment-components-table.total-payment"}>
                                {formatCurrency(intl, totalAmount.toString(), currencyCode, merchant.currencyDisplay)}
                            </Card>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>

        <MDBRow>
            <MDBCol size="12">
                <PayInvoiceTable
                caption={'make-payment.payment-summary.card-header.title'}
                    invoices={selectedInvoices}
                    onChange={(...args) => {
                        dispatch({type: "ERROR"});
                        onChange(...args);
                    }}
                    onEdit={invoice => dispatch({
                        type: "OPEN_MODAL",
                        modalId: "partialPayModal",
                        invoice
                    })}
                    isOnlyCreditNotes={isOnlyCreditNotes}
                />
            </MDBCol>

            <MDBCol size="12">
                <div className="mt-3">
                    <ValidationErrors
                        name="invoiceInfo"
                        messages={state.errors}
                        values={state.errorArgs}
                    />
                </div>
            </MDBCol>

            {!creditAllowed &&
                <MDBCol size="12">
                    <Alert className="bank-direct-debit-only-warning" label={flowConsts.getKey('debitOnlyWarning')} type='warning'/>
                </MDBCol>
            }

            {paymentInvoices.length > MAX_INVOICES &&
                <MDBCol size="12">
                    <Alert className="bank-direct-debit-only-warning"  label="payment.too-many-invoices" values={{max: MAX_INVOICES}} type='warning'/>
                </MDBCol>
            }
        </MDBRow>

        <PartialPayModal
            modalId="partialPayModal"
            isOpen={state.partialPayModal}
            toggleModal={modalId => dispatch({
                type: "CLOSE_MODAL",
                modalId
            })}
            invoice={state.invoice}
            currencyDisplay={merchant.currencyDisplay}
            onChange={onChange}
        />
    </Details>);
}
