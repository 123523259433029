import React, {useEffect, useReducer}           from "react";
import { MDBModal, MDBModalHeader, MDBModalBody
       , MDBModalFooter, MDBBtn}                from "mdbreact";
import {FormattedMessage, useIntl}                       from "react-intl";
import {isEmpty, inc}                           from "ramda";

import {removeSchedule}                         from "../../../../api/autopay-api";
import ReactPortalModal                         from "../../../ReactPortalModal";
import Error                                    from "../../../Error";
import ScheduleView                             from "../../ScheduleView";
import Spinner                                  from '../../../Spinner';

const STEPS = [
    {
        label: "modal.cancel-schedule.btn.cancel-schedule",
        msg: "modal.cancel-schedule.confirm.msg",
        btn1: "ups.btn.submit.label",
        btn2: "ups.btn.cancel.label"
    },
    {
        label: "modal.cancel-schedule.done",
        msg: "modal.cancel-schedule.done.msg",
        btn1: "close.label"
    }
];

const INITIAL_SATE = {step: 0, fetching: false, error: ''};

function stateReducer (state, action) {
  switch (action.type) {
    case "init": return INITIAL_SATE;
    case "deleteSchedule": return {
      ...state,
      fetching: true,
      error: ''
    };
    case "error": return {
      ...state,
      fetching: false,
      error: action.errorCode
    };
    case "success": return {
      ...state,
      fetching: false,
      step: inc (state.step)
    };
    case "stepForward": return {
      ...state,
      step: inc (state.step),
      error: ''
    };
    default: return state;
  }
}

export default function CancelScheduleModal({modalId, toggleModal, isOpen, schedule}) {
  const [state, dispatch] = useReducer(stateReducer, INITIAL_SATE);
  const intl = useIntl();

    useEffect(() => {
        if (isOpen) {
          dispatch({type: "init"})
        }
    }, [isOpen]);

    useEffect(() => {
      let closed = false;
      if (state.fetching) {
        removeSchedule(schedule)
          .then(() => !closed && dispatch({type: "success"}))
          .catch(({errorCode}) => !closed && dispatch({type: "error", errorCode}));
      }
      return () => closed = true;
    }, [state.fetching, schedule]);

    const closeModal = () => {
        toggleModal(modalId);
    };

    const nextStep = () => {
        if (state.step === 0) {
          dispatch({type: 'deleteSchedule'});
        } else if ((state.step >= STEPS.length - 1)) {
          closeModal();
        } else {
          dispatch({type: 'stepForward'});
        }
    };

  const currentStep = STEPS[state.step];
  const modalHeading = currentStep.label;
  const modalLabel = "cancel-schedule";

    return (
      <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
        <MDBModal isOpen={isOpen} toggle={closeModal} size="md" disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
            <Spinner isSpinning={state.fetching} />
            <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={closeModal}>
                <FormattedMessage id={modalHeading}/>
            </MDBModalHeader>

            <MDBModalBody aria-live="assertive">
                {!isEmpty(state.error) && <Error errors={[state.error]}/>}
                <p><FormattedMessage id={currentStep.msg} values={{id: schedule.referenceId}}/></p>
                <ScheduleView schedule={schedule}/>
            </MDBModalBody>

            <MDBModalFooter className="mt-3" aria-live="polite">
                {currentStep.btn1 &&
                    <MDBBtn color="primary" onClick={nextStep}>
                        <FormattedMessage id={currentStep.btn1}/>
                    </MDBBtn>
                }
                {currentStep.btn2 &&
                    <MDBBtn color="cancel" onClick={closeModal}>
                        <FormattedMessage id={currentStep.btn2}/>
                    </MDBBtn>
                }
            </MDBModalFooter>
        </MDBModal>
      </ReactPortalModal>
    );
}
