import React, {Component} from "react";
import InputFieldIntl from "../../InputFieldIntl";
import {FormattedMessage, injectIntl} from "react-intl";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import CheckboxIntl from "../../CheckboxIntl";
import * as _ from 'lodash';
import {doFocus} from "../../../utils/utils";


class CalendarInfoPanel extends Component {

    constructor(props) {
        super(props);

        const { filters, calendarOptionType } = this.props;

        this.state = {
            calendarOptionType: (filters && filters.calendarOptionType) || calendarOptionType,
            dateType: (filters && filters.dateType) || '1',
        };
    }

    handleInput = e => {
        const { handleCalendarChange } = this.props;
        const inputName = e.target.name;
        const inputValue = e.target.value;

        if (inputName == 'dateType') {
            handleCalendarChange({dateType: inputValue});
            this.setState( {
                dateType: inputValue,
            });
        } else {
            handleCalendarChange({calendarOptionType: inputValue});
        }
    };

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    };

    submitDateFilter = () => {
        const { handleSearch } = this.props;
        handleSearch(this.state);
    }

    onClose = (action) => {
        const {closeFocusSelector='.btn-calendar'} = this.props;
        doFocus(closeFocusSelector);
        action();
    }

    render() {
        const { dateType } = this.state;
        const { showDatePicker, calendarOptionType, isDrillMode, businessUnit, intl } = this.props;

        let calendarInvoiceOptionList = [];

        if (dateType === "1") {
            calendarInvoiceOptionList = ["TODAY","LAST_7_DAYS","LAST_30_DAYS","LAST_60_DAYS","LAST_90_DAYS","AVAILABLE","CUSTOM"];
        } else {
            calendarInvoiceOptionList = ["TODAY","NEXT_7_DAYS","NEXT_30_DAYS","NEXT_60_DAYS","NEXT_90_DAYS","CUSTOM"];
        }

        const calendarRadioComponents = [];

        for (var calendarOption of calendarInvoiceOptionList) {
            if(!calendarOptionType && calendarOption === "AVAILABLE"){
                calendarRadioComponents.push(
                    <InputFieldIntl
                        key={"calendarOptionType_"+calendarOption}
                        label={<FormattedMessage id={"calendar.option-range."+calendarOption} />}
                        type="radio"
                        name="calendarOptionType"
                        id={"calendarOptionType_"+calendarOption}
                        labelClass={"mr-0"}
                        value={"AVAILABLE"}
                        checked={true}
                        onChange={this.handleInput}
                        containerClass={"form-check mx-0 mb-3 col-6 col-md-12"}
                    />
                )
            }else{
                calendarRadioComponents.push(
                    <InputFieldIntl
                        key={"calendarOptionType_"+calendarOption}
                        label={<FormattedMessage id={"calendar.option-range."+calendarOption} />}
                        type="radio"
                        name="calendarOptionType"
                        id={"calendarOptionType_"+calendarOption}
                        labelClass={"mr-0"}
                        value={calendarOption}
                        checked={calendarOptionType === calendarOption}
                        onChange={this.handleInput}
                        containerClass={"form-check mx-0 mb-3 col-6 col-md-12"}
                    />
                )
            }
        }
        const labelSet =  _.cond([
            [(label, bu)=> label==="date" && bu === "FRT" ,                _.constant("invoice.freight-table.date")],
            [(label, bu)=> label==="date" && bu === "SCS" && isDrillMode,  _.constant("invoice.plan-statement-table.invoice-date")],
            [(label, bu)=> label==="date" && bu === "SCS" && !isDrillMode, _.constant("invoice.search-field.invoice-statement-date-filter.label")],
            [(label, bu)=> label==="date" && bu !== "FRT",                 _.constant("invoice.search-field.invoice-date.label")],
            [(label, bu)=> label==="subDate" && bu === "FRT",              _.constant("invoice.search-field.shipment-date-range-filter.label")],
            [(label, bu)=> label==="subDate" && bu !== "FRT",              _.constant("invoice.search-field.invoice-date-range-filter.label")],
        ]);
        const invoiceDateLabel = labelSet("date", businessUnit)
        const invoiceSubDateLabel = labelSet("subDate", businessUnit)
        const hasSecondDateType = (businessUnit === "SCS" && isDrillMode) || (businessUnit !== "SCS" && !isDrillMode)

        const dateLabelkey = dateType === "1" ? invoiceSubDateLabel : "invoice.search-field.due-date-range-filter.label";
        const dateAriaLabel = (hasSecondDateType ? '' : intl.formatMessage({id:invoiceDateLabel})) + " " + intl.formatMessage({id:dateLabelkey});
        return (
            <React.Fragment>
                <div className={"calendar-settings-panel"}>
                    <MDBRow>
                        <MDBCol size={"12"}>
                            <fieldset className={"form-radio-group"}>
                                <legend aria-label={intl.formatMessage({id:"calendar.phrases.selectDateType"})}></legend>
                                <InputFieldIntl
                                    label={<FormattedMessage id={invoiceDateLabel} />}
                                    type="radio"
                                    name="dateType"
                                    id={"dateType1"}
                                    labelClass={"mr-0"}
                                    tabIndex={hasSecondDateType?0:-1}
                                    value={"1"}
                                    checked={dateType == "1"}
                                    onChange={this.handleInput}
                                    className={"form-radio-group-check"}
                                    containerClass={"form-radio-group-item mx-0 mb-3 pl-0"}
                                />
                                {
                                    (hasSecondDateType)
                                    ? 
                                        <InputFieldIntl
                                            label={<FormattedMessage id={'invoice.search-field.due-date.label'} />}
                                            type="radio"
                                            name="dateType"
                                            id={"dateType2"}
                                            labelClass={"mr-0"}
                                            value={"2"}
                                            checked={dateType == "2"}
                                            onChange={this.handleInput}
                                            className={"form-radio-group-check"}
                                            containerClass={"form-radio-group-item mx-0 mb-3 pl-0"}
                                        />
                                    :''
                                }
                                
                            </fieldset>
                            <fieldset>
                                <legend aria-label={dateAriaLabel}>
                                    {<FormattedMessage id={dateLabelkey} />}</legend>
                                <MDBRow>
                                  {calendarRadioComponents}
                                </MDBRow>
                            </fieldset>
                        </MDBCol>
                    </MDBRow>

                    {!isDrillMode &&
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <CheckboxIntl
                                        key={"saveSearchCriteria"}
                                        name={"saveSearchCriteria"}
                                        id={"saveSearchCriteria"}
                                        containerClass="mx-0 my-3 pl-0"
                                        labelClass="mr-0"
                                        onChange={({target}) => { this.setState({ saveSearchCriteria: target.checked }) }}
                                        label={<FormattedMessage id={"invoice.search-field.save-date-range-filter.label"} />}
                                    />
                                </MDBCol>
                            </MDBRow>
                    }

                    <div className={"calendar-settings-panel-actions"}>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <MDBBtn id={'invoice-search-date-btn-apply'} color={"primary"} className={"ml-0 my-0"} onClick={()=>this.onClose(this.submitDateFilter)}><FormattedMessage id={'invoice.search-field.btn-apply.label'} /></MDBBtn>
                                <MDBBtn id={'invoice-search-date-btn-cancel'} color={"text-link"} onClick={()=>this.onClose(showDatePicker)}><FormattedMessage id={'invoice.search-field.btn-cancel.label'} /></MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(CalendarInfoPanel);