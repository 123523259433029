import React, { useEffect, useRef } from 'react';
import { MDBAutocompleteV5 } from 'mdbreact';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

export default function MDBAutocompleteV5Wrapper({ id, clearClass, clearlabel, getValue, isFiltersClean, ...rest }) {
    const intl = useIntl();    
    const autocomplete = useRef(null);

    const onValueChange = (value) => {
        if (value && clearlabel && clearClass) {
            let element = document.getElementsByClassName(clearClass)[0];
            if (element) {
                element.ariaLabel = `${intl.formatMessage({ id: clearlabel })} ${value}`;
            }
        }
        if (value) {
            const el = document.getElementsByClassName("mdb-autocomplete-wrap")[0];
            const { noSuggestion } = rest;
            if (el && el.children && el.children.length && noSuggestion && (el.children[0].innerText == noSuggestion)) {
                el.children[0].setAttribute("role", "alert");
            }

        }
        getValue(value);
    }

    useEffect(()=>{
        if (isFiltersClean === true) {            
            //only good way of "clearing" this component's value manually because it goes into a loop when we manually set optionValue to empty.
            autocomplete.current?.handleClear();
        }
    },[isFiltersClean])

    return (
        <React.Fragment>
            <MDBAutocompleteV5
                clearlabel={clearlabel}
                clearClass={clearClass}
                id={id}
                ref={autocomplete}
                getValue={(v) => onValueChange(v)}
                {...rest}
            />
        </React.Fragment>
    );
}

MDBAutocompleteV5Wrapper.propTypes = {
    id: PropTypes.string, // property that we need to bind to MDBAutocompleteV5
    clearClass: PropTypes.string, // property for clear button cssclass
    clearButtonLabel: PropTypes.string, // property for clear button label
    getValue: PropTypes.func, // func to be called after updating value
}