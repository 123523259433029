import React from "react";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import * as R from 'ramda';
import { BU_METADATA, BUSINESS_UNIT } from '../constants/business-unit';
import { countryData } from '../constants/country-data';
import { updateUserSession } from "../actions/identity-action";
import { setStoreState } from "../actions/setting-action";
import { inflateSync } from "browserify-zlib";
import { formatCurrency as formatCurrencyBase } from "./invoice-utils"

export const getMessageByBU = (section)=>(intl)=>(bu)=> R.compose(
    intl.formatMessage,
    id => ({id}),
    getKeyByBU(section)(bu)
)
export const getKeyByBU = (section)=>(bu)=>(key)=>R.pathOr(
    R.pathOr(
        'account-selection-table.main.empty',
        [section, 'EBS', key],
        BU_METADATA
    ),
    [section, bu, key],
    BU_METADATA
)
export const getConfigByBU = getKeyByBU('config')

const pmTableFlowMsg = (keyGroup)=>(intl)=>(bu)=>getMessageByBU('paymentFlowTable')(intl)(bu)(keyGroup)
export const getPlanAccNumLabel = pmTableFlowMsg('planAccNumLabel');
export const getPlanAccNameLabel = pmTableFlowMsg('planAccNameLabel');
export const getInvoiceNumLabel = pmTableFlowMsg('invoiceNumLabel');
export const getInvoiceDateLabel = pmTableFlowMsg('invoiceDateLabel');
export const getAccNumLabel = pmTableFlowMsg('accountNumLabel');
export const getAccNameLabel = pmTableFlowMsg('accountNameLabel');
export const getPlanNameLabel = pmTableFlowMsg('planNameLabel');

function countryUPSType(countryCode) {
    if(!countryCode || countryCode === '') return null
    let countryUPSTypeValue = 'Domestic'
    if (countryCode) {
        if (countryCode === 'LU') {
            countryUPSTypeValue = 'Restricted Intl'; // Restricted international
        } else if (countryCode !== 'US' && countryCode !== 'CA') {
            countryUPSTypeValue = 'Intl'; // Other countries
        }
    }
    return countryUPSTypeValue;
}

function countryCodeToCountry(countryCode) {
    const countryObj = countryData.find(c => c.code === countryCode);
    if (countryObj) {
        return countryObj.name;
    }
    return "N/A";
}

export const getAllCountryCodes = R.always(R.map(R.prop("code"), countryData));

export function buildHelpCenterUrl(countryCode, language) {
    return `https://${window.config.upsHostName}/${countryCode?.toLowerCase() || "us"}/${language?.toLowerCase() || "en"}/help-center/billing-payment.page`;
}

function booleanToEnrolledStatus(bool) {
    if (bool === true) {
        return "Enrolled";
    } else if (bool === false) {
        return "Not Enrolled";
    } else {
        return "N/A";
    }
}

function accountNumberMaxLength(businessUnit) {
    const {accNumMaxLength} = BU_METADATA.config[businessUnit] ?? BU_METADATA.config.EBS;
    return accNumMaxLength;
}

function stripAccountNumber(accountNumber, businessUnit) {
    return accountNumber && accountNumber.slice(-accountNumberMaxLength(businessUnit));
}

function accountType({isParent, parentAccount}){
    if(isParent) return "PLAN"
    if(parentAccount) return "PLAN_ACCOUNT"
    return "ACCOUNT"
}

function getFormattedAccountNumber(accountNumber, businessUnit, parentAccount, intl, type){
    const formattedAccNum = stripAccountNumber(accountNumber, businessUnit)
    if(parentAccount){
        const planAcctNum = stripAccountNumber(parentAccount.accountNumber, businessUnit);
        switch(type){
            case 'serialize': return `${intl.formatMessage({id:'account.name.text'}, {accountNumber: formattedAccNum})}\n${intl.formatMessage({id:'plan.name.text'}, {accountNumber: planAcctNum})}`
            case 'sort': return `${formattedAccNum}${planAcctNum}`
            default: return <div>
                <div>{intl.formatMessage({id:'account.name.text'}, {accountNumber: formattedAccNum})}</div>
                <div>{intl.formatMessage({id:'plan.name.text'}, {accountNumber: planAcctNum})}</div>
            </div>;
        }
    }
    return formattedAccNum;
}

function getFormattedAccountName(accountName, intl, type){
    switch(type){
        case 'sort':
        case 'serialize':
            return accountName ? accountName : "-";
        default: return accountName ? accountName :
            <React.Fragment>
                <span className='sr-only'>{intl.formatMessage({id: "ups.aria-label.blank"})}</span>
                <span aria-hidden={true}>-</span>
            </React.Fragment>
    }
}

function padAccountNumbers(accountNumbers) {
    return accountNumbers && Array.isArray(accountNumbers) && accountNumbers.map(accountNumber => padAccountNumber(accountNumber));
}

function padAccountNumber(accountNumber) {
    return accountNumber && typeof accountNumber === 'string' && accountNumber.padStart(10, '0');
}

function formatAmount(amount, maxCurrencyDigits, intl) {
    if (!intl) return amount;
    if (!amount || Number.isNaN(+amount)) return amount;

    const maximumCurrencyDigits= Number.isNaN(parseInt(maxCurrencyDigits)) ? 2 : parseInt(maxCurrencyDigits);

    return intl.formatNumber(amount, {
        signDisplay: 'never',
        useGrouping: false,
        minimumFractionDigits: maximumCurrencyDigits,
        maximumFractionDigits: maximumCurrencyDigits
    });
}

function checkPlanEnrolled(accounts) {
    return (accounts || []).some(account => account.isParent == 'true' || account.isParent == true)
}

function getAccountUid(account) {
    let uid = account.accountNumber
    if (account.parentAccount) uid += account.parentAccount.accountNumber
    return uid
}

const createLookupTable = (sourceArray, targetObject) => {
    sourceArray.forEach((account) => {
        targetObject[getAccountUid(account)] = true
    })
}

function getAccountAssignmentDelta(visible, current, target) {
    let visibleAccountLookup = {}
    let currentlyAssignedLookup = {}
    let targetAssignedLookup = {}
    //visibleAccounts = accounts visible to the administrator browsing the app (may not be all accounts)
    //currentlyAssignedAccounts = accounts currently assigned to the user who the administrator is changing assignments on (may contain accounts not visible to the administrator)
    //targetAssignedAccounts = accounts the administrator wishes to assign to the user
    createLookupTable(visible, visibleAccountLookup)
    createLookupTable(current, currentlyAssignedLookup)
    createLookupTable(target, targetAssignedLookup)

    let addAccounts = {}
    let removeAccounts = {}
    current.forEach((cur) => {
        let uid = getAccountUid(cur)
        if (!targetAssignedLookup[uid] && visibleAccountLookup[uid]) removeAccounts[uid] = cur.id
    })
    target.forEach((tar) => {
        let uid = getAccountUid(tar)
        if (!currentlyAssignedLookup[uid]) addAccounts[uid] = true
    })
    return { addAccounts, removeAccounts }
}

function filterUnassignableAccounts(myAccounts, companyAccounts, isFreightActive){
    let myLookupTable = {}
    let companyLookupTable = {}
    createLookupTable(myAccounts, myLookupTable)
    createLookupTable(companyAccounts, companyLookupTable)
    return myAccounts.filter((cur)=>{
        let uid = getAccountUid(cur)
        //Bar assigning of accounts that don't exist any longer in the company - and disable assignment of freight accounts if isFreightActive = false
        return (companyLookupTable[uid] && myLookupTable[uid]) && (!isFreightActive ? (cur.paymentType !== BUSINESS_UNIT.FRT) : true)
    })
}

function formatCurrency(intl, amount, currencyCode, currencyDisplay="symbol") {
    if (!intl) return amount;
    if (Number.isNaN(+amount)) return amount;

    if (currencyCode) {
        return formatCurrencyBase(intl, amount, currencyCode, currencyDisplay);
    }
    else {
        return intl.formatNumberToParts(amount).map(({ type, value }) => {
            return value;
        }).reduce((string, part) => string + part);
    }
}

function formatDate(intl, dateString) {
    if (!intl) return dateString;
    if (!dateString) return dateString;
    const date = moment(dateString.split('T')[0]).toDate();
    return intl.formatDate(date, { month: 'short', day: '2-digit', year: 'numeric' });
}

function formatToLocalDate(intl, dateString) {
    if (!intl) return dateString;
    if (!dateString) return dateString;
    let date = moment(dateString).toDate();
    return intl.formatDate(date, { month: 'short', day: '2-digit', year: 'numeric' });
}

function formatDateTime(intl, dateString) {
    if (!intl) return dateString;
    if (!dateString) return dateString;
    const date = moment(dateString);
    return intl.formatDate(date, { month: 'short', day: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true });
}

function getCalendarSavedPreferences(preferences, savedPreferenceType, dateKey) {
    let selectedStartDate = null, selectedEndDate = null, dateType = null, calendarOptionType = 'LAST_90_DAYS';
    if (preferences && preferences[savedPreferenceType]) {
        const dateObj = preferences[savedPreferenceType][dateKey];
        calendarOptionType = preferences[savedPreferenceType].calendarOptionType;
        // dateType = preferences[savedPreferenceType].dateType;

        if (calendarOptionType === 'CUSTOM' && dateObj) {
            selectedStartDate = moment(dateObj.dateBegin);
            selectedEndDate = moment(dateObj.dateEnd);
        }
    }

    if (calendarOptionType === 'CUSTOM' && (!selectedStartDate || !selectedEndDate)) {
        calendarOptionType = 'AVAILABLE';
    }

    return {
        selectedStartDate,
        selectedEndDate,
        dateType,
        calendarOptionType
    };
}

function getSupportedLanguages(countries){
    let supportedLanguages = [];

    if(countries && countries.length > 0){
        countries.forEach((item) => {
            let country = countryData.find(x => x.code === item);
            if(country){
                country.languages.forEach((lang) => {
                    let language = Object.assign({}, lang);
                    language.country = country.code;
                    supportedLanguages.push(language);
                });
            }
        });
    }

    return supportedLanguages;
}

function convertCurrencyToNumber(num) {
    if(typeof num === "number" || num === ""){
        return num;
    }
    if(num===','||num==='.') return 0
    let dotPos = num.indexOf('.');
    let commaPos = num.indexOf(',');
    let sep = "";

    if (dotPos > commaPos)
        sep = dotPos;
    else if (commaPos > dotPos)
        sep = commaPos;
    else if(commaPos === -1 && dotPos === -1)
        sep = false;

    if (sep === false) return parseFloat(num.replace(/[^\d]/g, ""));
    return parseFloat(
        num.substr(0, sep).replace(/[^\d]/g, "") + '.' +
        num.substr(sep+1, num.length).replace(/[^0-9]/, "")
    );

}

function getLocaleDateString(date, locale){
    const options = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];

    function format(_opt) {
        const func = new Intl.DateTimeFormat(locale, _opt);
        return func.format(date);
    }

    let arr = options.map(format);
    if(arr.length === 3){
        return `${arr[1]} ${arr[0]}, ${arr[2]}`; // MMM D,YYYY representation of date
    }
    else{
        return arr.join("-");
    }
}

// make changes in the state for email notification in case of active session
function updateState(store) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());        
    const {dispatch} = store;

    let loadStore = true;

    if(params?.token){
        const state = store.getState();
        
        if(params.cc && params.cc?.toUpperCase() !== state.auth.user.selectedCountry){
            const lastSelectedCountryCode = params.cc.toUpperCase();
            const merchant = state.auth.merchants.find(x => x.countryCode === lastSelectedCountryCode);

            if(merchant){                
                loadStore = false;
                
                const preferences = {
                    lastSelectedCountryCode,
                    lastSelectedPaymentType: params.bu.toUpperCase(),
                    selectedMerchantId: merchant.id,
                    lastSelectedLanguageCode: countryData.find(x => x.code === lastSelectedCountryCode).languages[0].code,
                    tla: merchant.tla
                };

                dispatch(updateUserSession({updateCountry: true, preferences}));
            }
        }
        else if(params.bu?.toUpperCase() !== state.auth.user.selectedPaymentType){
            const lastSelectedCountryCode = state.auth.user.selectedCountry;
            const lastSelectedPaymentType = params.bu.toUpperCase();
            const merchant = state.auth.merchants.find(x => x.countryCode === lastSelectedCountryCode);
            
            if(merchant.paymentTypes.findIndex( x => x.name === lastSelectedPaymentType) > -1){
                loadStore = false;
                dispatch(updateUserSession({preferences: {lastSelectedCountryCode, lastSelectedPaymentType}}));
            }
        }
    }

    if(loadStore){
        // no change required - either state is already updated or token params is not present
        dispatch(setStoreState(true));
    }
}

function decodeToken(encodedStr) {
    return JSON.parse(inflateSync(new Buffer.from(encodedStr, "base64")).toString());
}

function removeUrlQueryParams() {
    window.history.replaceState(null, null, window.location.pathname);
}

export {
    countryUPSType,
    countryCodeToCountry,
    booleanToEnrolledStatus,
    accountNumberMaxLength,
    stripAccountNumber,
    padAccountNumbers,
    padAccountNumber,
    formatAmount,
    checkPlanEnrolled,
    getAccountAssignmentDelta,
    filterUnassignableAccounts,
    formatCurrency,
    formatDate,
    formatToLocalDate,
    getCalendarSavedPreferences,
    formatDateTime,
    getSupportedLanguages,
    convertCurrencyToNumber,
    getAccountUid,
    getFormattedAccountNumber,
    getLocaleDateString,
    getFormattedAccountName,
    updateState,
    decodeToken,
    removeUrlQueryParams,
    accountType
}

