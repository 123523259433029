import * as actionTypes from '../constants/action-types';

export default function reducer(state = {}, action) {
  switch (action.type) {

    case actionTypes.SET_3D_SECURE_REDIRECT: return {
      ...state,
      redirectDetails: action.data,
    }

    default: return state;
  }
}