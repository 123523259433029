import React from 'react';
import { MDBSelect } from 'mdbreact';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

const assignIdToInput = (field, ref) => {
    const inputElement = ref.current?.inputRef?.current;
    if(inputElement){
        inputElement.setAttribute("id", `${field}-input`);
    }
}

export default function MDBSelectWrapper({ label, field, onUpdateValue, filter, ...rest }) {
    const selectRef = useRef(null);
    const intl = useIntl();

    useEffect(() => {
        assignIdToInput(field, selectRef);
    }, []);

    return (
        <React.Fragment>
            <label htmlFor={`${field}-input`} aria-label={intl.formatMessage({id: `ups.select-filter.label${rest.multiple? '.multi' : ''}`}, {label})} className={"multi-select-label"}>{label}</label>
            <MDBSelect
                ref={selectRef}
                id={field}
                getValue={(v) => onUpdateValue(field, v, filter)}
                {...rest}
            />
        </React.Fragment>
    );
}

MDBSelectWrapper.propTypes = {
    field: PropTypes.string, // property that we need to bind to MDBSelect
    label: PropTypes.string, // property display name
    onUpdateValue: PropTypes.func, // func to be called after updating value
    filter: PropTypes.object // required only if filter needs to be passed while updating value
}