import {validate, validateGetValue, validateAll, register, deregister} from '.';

/* Attach validation functions to a react component.
*  To use, you must have vFields and vState available in your state (pulled from redux store)
*  As well as validationActions available for action dispatches.
*  Attach by importing and calling attachValidator.call(this) in the constructor of your component.
* */

const attachValidator = function () {
    this.validator = {}

    //function to register a field for validation
    //if the "current value" of a field isn't stored in this.props.value, specify the getValue argument.
    //if the "current array of validations" of a field isn't stored in this.props.validations, specify the getValidations argument
    //specify getContext to override variables for templated messages
    this.validator.register = function(
        fieldName,
        getValue = () => this.props.value,
        getValidations = () => this.props.validations,
        getContext = () => ({})
    ) {
        register({actions: this.props.validationActions}, {
            fieldName,
            getValue,
            getValidations,
            getContext: () => Object.assign({
                label: this.props.label
            }, getContext())
        })
    }.bind(this)

    //deregister a field when it's unmounted
    this.validator.deregister = function(fieldName){
        //can pass in multiple field names if deregistering many
        if(Array.isArray(fieldName)){
            fieldName.forEach((field)=>{
                deregister({actions: this.props.validationActions}, field)
            })
        } else {
            deregister({actions: this.props.validationActions}, fieldName)
        }

    }.bind(this)

    //get a field's value and validate it
    this.validator.validateGetValue = function(fieldName){
        const {vFields, validationActions: actions} = this.props;
        return validateGetValue({vFields, actions}, fieldName);
    }.bind(this)

    //validate a field with a provided value
    this.validator.validate = function(fieldName, value){
        const {vFields, validationActions: actions} = this.props;
        return validate({vFields, actions}, fieldName, value);
    }.bind(this)

    this.validator.validateAll = function(){
        const {vFields, validationActions: actions} = this.props;
        return validateAll({vFields, actions});
    }.bind(this)

    this.validator.setError = function(fieldName, messages){
        this.props.validationActions.updateValidation({fieldName, result: {status:'error', messages}})
    }.bind(this)

    this.validator.resetError = function(fieldName){
        this.props.validationActions.updateValidation({fieldName, result: null})
    }.bind(this)

    this.validator.resetErrors = function(){
        this.props.validationActions.resetValidation()
    }.bind(this)
}

export default attachValidator;