import React, { Component } from "react";
import { MDBCard } from "mdbreact";
import { injectIntl } from "react-intl";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as enrollmentActions from "../../actions/enrollment-action";
import * as accountActions from "../../actions/account-action";
import * as authActions from "../../actions/auth-action";

import ProgressBar from '../../components/UPS/ProgressBar';
import AccountDetails from '../../components/UPS/AccountDetails';
import InvoiceDetails from '../../components/UPS/InvoiceDetails';
import EnrollmentCompanyDetails from '../../components/UPS/EnrollmentCompanyDetails';
import EnrollmentConfirmation from '../../components/UPS/EnrollmentConfirmation';

const stepNames = [
    { key: 0, label: "ups.enrollment.account.details" },
    { key: 1, label: "ups.enrollment.invoice.details" },
    { key: 2, label: "ups.enrollment.company.details" },
    { key: 3, label: "ups.enrollment.confirmation" }
];


class Enrollment extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        // const { layoutActions, pageTitle } = this.props;
        window.scrollTo(0, 0);
        //layoutActions.updateHeaderTitle(<FormattedMessage id={"components.header.title.payment"} />);
        // layoutActions.updateHeaderTitle("Make a Payment | By Account");
        // layoutActions.updatePageTitle("Enroll);

        // enrollment flow checks for user in auth state , in case userInfo and user not present , initiating sso
        const { user, userInfo, authActions } = this.props;

        if (!userInfo && !user)
            authActions.login();
    }

    render() {

        // If user and userInfo both are not present , return null instead
        const { aiaRequired, accountValid, user, userInfo } = this.props;
        if (!userInfo && !user) {
            return null;
        }
        let enrollmentStep = 1;
        if (aiaRequired) {
            enrollmentStep = 2;
        }
        if (accountValid) {
            enrollmentStep = 3;
        }
        if (user) {
            enrollmentStep = 4;
        }

        const enrollmentStepComponents = [];

        if (enrollmentStep === 1) {
            enrollmentStepComponents.push(
                <AccountDetails key="enrollmentAccountDetailsSection"
                    submitAction={this.props.accountActions.validateAccountEligibility}
                    handleCancel={this.cancelEnrollment}
                />
            )
        } else if (enrollmentStep === 2) {
            enrollmentStepComponents.push(
                <InvoiceDetails key="enrollmentInvoiceDetailsSection"
                    submitAction={this.props.accountActions.validateInvoice}
                    handleCancel={this.cancelEnrollment}
                    handleRestart={this.restartEnrollment}
                />
            )
        } else if (enrollmentStep === 3) {
            enrollmentStepComponents.push(
                <EnrollmentCompanyDetails key="enrollmentCompanyDetailsSection"
                    handleCancel={this.cancelEnrollment}
                    handleRestart={this.restartEnrollment}
                />
            )
        } else if (enrollmentStep === 4) {
            enrollmentStepComponents.push(
                <EnrollmentConfirmation key="enrollmentConfirmationSection"
                    history={this.props.history}
                    countryCode={this.props.user?.selectedCountry}
                />
            )
        }

        return (
            <React.Fragment>
                <section className={"ups-section ups-enroll-section"}>
                    <section className={"ups-enroll-subsection mb-4"}>
                        <MDBCard>
                            <ProgressBar stepNames={stepNames} currentStep={enrollmentStep - 1}
                                isShown={true} />
                        </MDBCard>
                    </section>
                    {enrollmentStepComponents}
                </section>
            </React.Fragment>
        )
    }

    restartEnrollment = () => {
        this.props.enrollmentActions.restartEnrollment();
    };

    cancelEnrollment = () => {
        //Cancel enrollment does not save the state of identity in db
        // hence user should be logged out since the user is not enrolled
        this.props.authActions.expireSessionCookie();
    };

}

function mapStateToProps(state) {
    return {
        aiaRequired: state.account.aiaRequired,
        accountValid: state.account.accountValid,
        user: state.auth.user,
        userInfo : state.auth.userInfo,
        envProps: state.envProps
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions : bindActionCreators(authActions,dispatch),
        enrollmentActions: bindActionCreators(enrollmentActions, dispatch),
        accountActions: bindActionCreators(accountActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Enrollment));
