import React  from 'react';
import {MDBCol, MDBRow} from 'mdbreact';
import classNames from 'classnames';
import {FormattedMessage, useIntl} from 'react-intl';

/**
 * Wizard progress
 * @param {Object} props
 * @param {Array.<{label: String}>} props.stepNames
 * @param {Number} props.currentStep Index
 * @param {Boolean} props.isShown
 * @returns Component
 */
export default function ProgressBar({stepNames, currentStep, isShown, stepId}) {
    const intl = useIntl();
    const numberOfSteps = stepNames.length;

    // extract step name from here for screen reading
    const step = stepNames[0].key !== undefined ? stepNames.find(x => x.key === currentStep) : stepNames.find(x => x.id === stepId);
    let stepAriaLabel = step ? intl.formatMessage({id: "ups.progress-bar.label"}, {label : intl.formatMessage({id:step.label}) })  : "";
    
    if (!isShown) return null;
    if (numberOfSteps <= 0 || numberOfSteps > 12) return null;
    if (currentStep < 0 || currentStep >= numberOfSteps) return null;

    const listBars = stepNames.map(({label}, key) => (
        <MDBCol
            key={label}
            className={classNames({
                'progress-display-bar-past': key < currentStep,
                'progress-display-bar-current': key === currentStep
            })}
        >
        
        </MDBCol>
    ));

    const listSteps = stepNames.map(({label}, key) => (
        <MDBCol
            aria-hidden={true}
            key={label}
            className={classNames({
                'hyphenate progress-display-title-past': key < currentStep,
                'hyphenate progress-display-title-current': key === currentStep,
                'hyphenate progress-display-title-future': key > currentStep
            })}
        >
            <FormattedMessage id={label} />
        </MDBCol>
    ));

    return (
        <React.Fragment>
        {/* this is to announce step name in reader */}
        {stepAriaLabel ? <label className="sr-only" aria-live='polite'>{stepAriaLabel}</label>: null}
        <section className='progress-display'>
            <MDBRow
                className={`mb-1 progress-display-bar row row-cols-${numberOfSteps}`}
            >
                {listBars}
            </MDBRow>
            <MDBRow
                className={`mb-3 progress-display-title row row-cols-${numberOfSteps}`}
            >
                {listSteps}
            </MDBRow>
        </section>
        </React.Fragment>
    );
}
