import React, { useEffect, useState }           from "react";
import { useHistory }                           from "react-router";
import { useSelector }                          from "react-redux";

import { handle3DSecureCallback }               from '../api/wallet-api';
import Spinner                                  from '../components/Spinner';

export default function UPS3DSecureLanding() {
    const history = useHistory();

    const params = new URLSearchParams(window.location.search);
    const redirectResult = params.get('redirectResult');

    const redirectLocation = useSelector((state) => state.wallet?.redirectDetails?.redirectLocation || "/ups/billing/wallet")
    const referenceId = useSelector((state) => state.wallet?.redirectDetails?.referenceId)

    useEffect(() => {
        let closed = false;

        const complete3ds = async () => {
            const result = await handle3DSecureCallback({redirectResult, referenceId}).catch(e => e);
            console.log('3ds complete result', result);
            if (!closed) {
                history.replace(redirectLocation, result);
            }
        };
        complete3ds();
        return () => closed = true;
    }, []);

    return (
        <>
             <Spinner isSpinning={true} />
        </>
        )
}
