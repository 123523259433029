import React from "react";
import {MDBCol, MDBRow} from "mdbreact";
import {FormattedMessage} from "react-intl";

import AccountPlanSelectionCardTable from "../../AccountPlanSelectionCardTable";
import Alert from "../../../Alert";


export default function AccountSelection({accountList, onChange, payByText}) {
    return <MDBRow>
        <MDBCol size="12">
            <h3 className="mb-4">
                <FormattedMessage id="pay-by-text.account-plan-selection.title"/>
            </h3>
            <p className="ups-note-1 my-0">*<span className="font-italic">
                <FormattedMessage id="ups.required-field.note"/>
            </span></p>
        </MDBCol>

        <MDBCol size="12">
            <div  className="datatable-blend-container-autopay account-selection">
                <div className="datatable-blend-inner">
                    <p><FormattedMessage id="pay-by-text.account-plan-selection.instructions-1"/>*</p>
                </div>
            </div>

            <AccountPlanSelectionCardTable
                accountList={accountList}
                caption= "pay-by-text.account-plan-selection.title"
                name="pay-by-text-account-list"
                source="pay-by-text-account-list"
                pageLength={20}
                selectedAccount={payByText.account}
                hidePlanAccountFilterOptions
                onChange={onChange}
                validations={[
                    ['required', 'pay-by-text.account-plan-selection.instructions-1']
                ]}
            />
            <MDBCol size="12">
                <Alert type="warning" label="pay-by-text.plans-not-allowed"/>
            </MDBCol>
        </MDBCol>
    </MDBRow>
}
