import React from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import CardActions from './CardActions';
import {maskIban, maskString} from '../../../utils/utils';
import {formatPMDesc, mapCardTypeToCss} from '../../../utils/payment-utils';

export default ({
                    editHandler, removeHandler, makeDefaultHandler, showCardActions, extraCssClass,
                    paymentMethod: {
                        accountNumber, cardHolderName,
                        methodType, cardNickName, default: isPrimary, bankId, bic, tokenPending
                    },
                    permissions
                }) => {
    const intl = useIntl();
    const toLabel = formatPMDesc(intl);
    let echeckPermissions = {...permissions}

    return <React.Fragment>
        <div className={classNames("card-container echeck", extraCssClass)}>
            <p className="pm-info card-nickname">{cardNickName}</p>
            {tokenPending && <p className="pm-info card-pending-token">
                <span className='pm-card-pending-token-text'>
                    <FormattedMessage id={`card.pending-token`} />
                </span>
            </p>}
            <p className="pm-info echeck-type">{toLabel(methodType)}</p>
            <p className="pm-info account-holder-name">{cardHolderName}</p>
            <p className="pm-info bank-name">{bic ? maskString(bic) + " " + bankId : bankId}</p>
            <p className="pm-info routing-number">{maskIban(accountNumber)}</p>
            <div className="echeck-decoration-overlay">
                <div className="echeck-decoration-line1"/>
                <div className="echeck-decoration-line3"/>
                <div className="echeck-decoration-box1"/>
                <div className="echeck-decoration-icon-bank">
                    <i className="fa fa-university" aria-hidden="true"/>
                </div>
            </div>
            <p className="pm-info card-type" role="img" aria-label={intl.formatMessage({id: `payment-method.${methodType}`})}>
                <span className={classNames("pm-icon", mapCardTypeToCss(methodType))} >
                    <FormattedMessage id={`payment-method.${methodType}`}/>
                </span>
            </p>
        </div>
        {showCardActions &&
            <CardActions
                editHandler={editHandler}
                removeHandler={removeHandler}
                makeDefaultHandler={makeDefaultHandler}
                isPrimary={isPrimary}
                permissions={echeckPermissions}
                cardNickName={cardNickName}
                pmLabel={`${toLabel(methodType)} ${intl.formatMessage({id: `payment.method.ECheckCard.Ending.with.bank-account-number.btn.label`},{accountNumber: accountNumber.slice(-4)})}`}
                tokenPending={tokenPending}
            />
        }
    </React.Fragment>;
};
