import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBSpinner  } from 'mdbreact';
import { FormattedMessage, injectIntl  } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import classNames from "classnames";
import PlayBackTable from '../../PlayBackTable'
import {connect} from "react-redux";
import {deleteIdentity, deleteAdminIdentity} from "../../../../api/identity-api";
import Error from "../../../Error";

class RemoveUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalStep: 1,
            userRemoved: {...this.props.data}, //this user is gone from the source data after deletion so we grab the data here for display
            loading: false,
            errors:[]
        };
    }

    handleDeleteUser = () => {
        const {data, permissions} = this.props;
        const {id, externalId, loginId} = data;
        const deleteCall = permissions['user_admin_delete'] ? deleteAdminIdentity : deleteIdentity
        this.setState({loading: true});
        deleteCall({id, externalId, loginId})
            .then((deletedUser)=> {
                this.setState({modalStep: 2})
                this.props.deleteUserCallback([deletedUser])
            })
            .catch(({errorCode})=>{
                this.setState({errors: [errorCode]})
            })
            .finally(()=>{
                this.setState({loading:false})
            })
    };

    render() {
        const { isOpen, toggleModal, intl } = this.props;
        const { modalStep, loading, userRemoved, errors } = this.state;
        const modalHeading = modalStep === 1 ? "ups.remove-user-single.title" : "ups.remove-user-single.confirmation.title";
        const modalLabel = "remove-user";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                    <MDBModal isOpen={isOpen} toggle={() => toggleModal('removeUser')} size="lg" centered disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('removeUser')}>
                            {modalStep === 1 ? <FormattedMessage id={"ups.remove-user-single.title"}/> : <FormattedMessage id={"ups.remove-user-single.confirmation.title"}/>}</MDBModalHeader>
                        <MDBModalBody aria-live="assertive">
                            {errors?.length > 0 && <MDBRow><Error errors={errors}/></MDBRow>}
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    {modalStep === 1 && <PlayBackTable key={"remove-user-play-back"} playBackTextMsgId={"ups.remove-user-single.intro-text-1"} user={userRemoved} />}
                                    {modalStep === 2 && <PlayBackTable key={"remove-user-success-play-back"} user={userRemoved} />}
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter aria-live="polite">
                            {modalStep === 1 && (
                                <React.Fragment>
                                    <MDBBtn
                                        color="primary"
                                        onClick={this.handleDeleteUser}
                                        className={classNames({"loading": loading})}>
                                        <FormattedMessage id={"ups.btn-remove-user.label"}/> <MDBSpinner />
                                    </MDBBtn>
                                    <MDBBtn color="cancel" onClick={() => toggleModal('removeUser')}><FormattedMessage id={"ups.remove-user.btn-cancel"}/></MDBBtn>
                                </React.Fragment>
                            )}
                            {modalStep === 2 && (
                                <React.Fragment>
                                    <MDBBtn color="primary" onClick={() => toggleModal('removeUser')}><FormattedMessage id={"ups.remove-user.btn-close"}/></MDBBtn>
                                </React.Fragment>
                            )}

                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        permissions: state.auth.user.permissions
    }
}

export default connect(mapStateToProps)(injectIntl(RemoveUserModal));
