import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";

import ValidationErrors from "../../../ValidationErrors";
import attachValidator from "../../../../utils/validation/attach-validator.js";
import * as validationActions from "../../../../actions/validation-action";
import * as errorActions from "../../../../actions/error-action";
import CheckboxIntl from '../../../CheckboxIntl';

class ACHCheckbox extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.validations) attachValidator.call(this);
    }

    componentDidMount() {
        let {name, validations} = this.props;
        if (validations) this.validator.register(name);
    }

    componentWillUnmount() {
        let {name, validations, errorActions, id} = this.props;
        if (validations) this.validator.deregister(name);
        errorActions.clearFieldError({id});
    }

    onChange(e) {
        let {onChange, validations, name, id, errorActions} = this.props;
        let val = e.target.checked;
        if (validations) this.validator.validate(name, val);
        errorActions.clearFieldError({id});
        if (onChange) onChange(e);
    }

    render() {
        const {name, id, value, onViewAgreement, errors, ownVState, intl} = this.props;
        const error = errors && errors.find(e => e.split('.')[0] === name);
        let messages = [];

        if (error) messages.push(error);
        if (ownVState && ownVState.messages) messages = messages.concat(ownVState.messages);

        return <MDBRow className="mb-1"><MDBCol size="12">
            <CheckboxIntl name={name} id={id}
                containerClass="pl-0 m-0"
                className="m-0 p-0"
                label={<FormattedMessage id="payment-method.sepa.authorize.agreement"/>}
                value={value}
                onChange={this.onChange.bind(this)}
            />
            <div style={{marginTop: "15px"}}><ValidationErrors name={name} messages={messages} intl={intl}/></div>
            <FormattedMessage id="payment-method.sepa.authorize.view" values={{
                action: msg => <MDBBtn className="btn-text-link p-0" onClick={onViewAgreement}>
                    {msg}
                </MDBBtn>
            }}/>
        </MDBCol></MDBRow>;
    }
}

function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            ownVState: state.validation.vState[ownprops.name]
        }: {})
    };
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ACHCheckbox));
