import * as actionTypes from '../constants/action-types';

export const getLatestMessages = (callback) => {
    return {
        type: actionTypes.GET_LATEST_MESSAGES,
        callback
    };
};

export const getLatestMessagesSuccess = (data) => {
    return {
        type: actionTypes.GET_LATEST_MESSAGES_SUCCESS,
        data
    };
};

export const getLatestMessagesFailure = (data) => {
    return {
        type: actionTypes.GET_LATEST_MESSAGES_FAILURE,
        data
    };
};


export const deleteUserMessage = (messageId, callback) => {
    return {
        type: actionTypes.DELETE_USER_MESSAGE,
        messageId,
        callback
    };
};

export const deleteUserMessageSuccess = (data) => {
    return {
        type: actionTypes.DELETE_USER_MESSAGE_SUCCESS,
        data
    };
};

export const deleteUserMessageFailure = (data) => {
    return {
        type: actionTypes.DELETE_USER_MESSAGE_FAILURE,
        data
    };
};