import React, {Component} from "react";
import {connect} from 'react-redux';
import PlansInvoiceDetails from "../PlansInvoiceDetails";
import IndependentAccountInvoiceDetails from "../IndependentAccountInvoiceDetails";
import {TYPE_ACCOUNT_TYPE_ACCOUNT, TYPE_ACCOUNT_TYPE_PLAN} from "../../../constants/paymentus-resources";


class InvoiceDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {isModal, account, submitAction, handleRestart, handleCancel} = this.props;

        return (
            <React.Fragment>
                {account.accountType === TYPE_ACCOUNT_TYPE_ACCOUNT ?
                    <IndependentAccountInvoiceDetails
                        submitAction={submitAction}
                        handleRestart={handleRestart}
                        handleCancel={handleCancel}
                        isModal={isModal}/>
                    : null}

                {account.accountType === TYPE_ACCOUNT_TYPE_PLAN ?
                    <PlansInvoiceDetails
                        submitAction={submitAction}
                        handleRestart={handleRestart}
                        handleCancel={handleCancel}
                        isModal={isModal}/>
                    : null}
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        account: state.account.account,
    }
}

export default connect(mapStateToProps, null)(InvoiceDetails);
