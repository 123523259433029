import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBContainer, MDBCol, MDBRow} from "mdbreact";
import UPSDashboard from "../../components/UPS/UPSDashboard";

class Home extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const components = [];

        components.push(
            <UPSDashboard key={'my-dashboard'} colSize={"12"} />
        );
        return (
            <MDBContainer role="main" fluid className={"dashboard-container"}>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <h1 className={"has-breadcrumb"}><FormattedMessage id={"dashboard.title"} /></h1>
                    </MDBCol>
                </MDBRow>
                {components}
            </MDBContainer>
        );
    }
}

export default injectIntl(Home);