import { useEffect, useReducer } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { EXPIRE_COOKIE } from '../constants/action-types';

// https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event

function getVisibilityChange () {
    if (typeof document.hidden !== 'undefined') {
        return {
            event:'visibilitychange',
            hidden: 'hidden'
        };
    } else if (typeof document.msHidden !== 'undefined') {
        return {
            event: 'msvisibilitychange',
            hidden: 'msHidden'
        };
    } else if (typeof document.webkitHidden !== 'undefined') {
        return {
            event: 'webkitvisibilitychange',
            hidden: 'webkitHidden'
        };
    } else {
        return null;
    }
}

function tabReducer (state, action) {
    switch (action.type) {
        case 'visible': return {
            ...state,
            reload: action.reload
        }
        case 'init': return {
            ...state,
            id: action.id
        }
        default: return state;
    }
}

/**
 * This component listens to visibility change events. Whenever
 * we have multiple tabs of this SPA we reload when returning to
 * any of the SPA instances. Returning from any non instance tab
 * will not cause a reload of the SPA.
 *
 * @returns {Function} react render function
 */
export default function TabReload () {
    const [tabState, dispatch] = useReducer(tabReducer, {});
    // const rDispatch = useDispatch();
    // const user = useSelector(s => s.auth?.user);

    // console.log('tabState: ', tabState);
    useEffect( () => {
        const visibilityChange = getVisibilityChange();

        const visibilityHandler = () =>
            dispatch ({ type: 'visible'
                      , reload: (!document[visibilityChange.hidden])
                      });

        // dispatch the logout action unfortunately named EXPIRE_COOKIE.
        // Disabled for now. Need to find out how to get this to work
        // with a 3ds flow and impersonation.

        // const unloadHandler = () => {
        //     if (!user?.adUserId) {
        //         console.log('logging out user who closed window');
        //         rDispatch({type: EXPIRE_COOKIE});
        //     } else {
        //         console.log('unload called by csr logon');
        //     }
        // }

        if (visibilityChange) {
            document.addEventListener(visibilityChange.event, visibilityHandler);
        }

        // window.addEventListener('beforeunload', unloadHandler);

        const uniqueId = '' + Date.now();
        dispatch({type: 'init', id: uniqueId})
        localStorage.setItem('instanceId', uniqueId);

        return () => {
            if (visibilityChange) {
                document.removeEventListener(visibilityChange, visibilityHandler);
            }
            // window.removeEventListener('beforeunload', unloadHandler);
        }
    }, []);

    useEffect (() => {
        const uniqueId = localStorage.getItem('instanceId');

        if (tabState.reload && (uniqueId !== tabState.id)) {
            console.log('returning to active tab having multiple instances, reload required.');
            window.location.reload();
        }

    }, [tabState.reload])

    return null;
}