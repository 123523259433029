import React from "react";
import {MDBTable, MDBTableBody} from "mdbreact";
import {FormattedMessage, FormattedNumber, FormattedDate, useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {stripAccountNumber, getAccNameLabel} from "../../../utils/ups-utils";
import { displayWalletSelection} from "../../../utils/payment-utils";

export default function PayByTextConfirmation({payByText: s}) {
    const intl = useIntl();
    const {selectedPaymentType: businessUnit} = useSelector(s => s.auth.user);
    const {accountNumber, accountName, paymentType, paymentMethod: pm = {}} = s;

    return <MDBTable className="table-two-col">
        <MDBTableBody>
            
            {accountNumber &&
                <tr>
                    <th><FormattedMessage id="modal.view-schedule.account"/></th>
                    <td>{stripAccountNumber(accountNumber, paymentType)}</td>
                </tr>
            }
            
            { accountName &&
                <tr>
                    <th>{getAccNameLabel(intl)(businessUnit)}</th>
                    <td>{accountName}</td>
                </tr>
            }

            <tr>
                <th><FormattedMessage id="modal.view-schedule.payment-method"/></th>
                <td>{displayWalletSelection(intl)(pm)}</td>
            </tr>

        </MDBTableBody>
    </MDBTable>;
}
