import React, {useEffect} from 'react';
import {MDBRow, MDBCol} from 'mdbreact';
import {useIntl} from 'react-intl';

import Details from './Details';
import CheckboxWithValidation from '../../components/CheckboxWithValidation';
import BacsAgreement from "../../components/UPS/PaymentMethodDetails/BacsDetails/BacsAgreement";

export function AgreementStep({authorized,paymentMethod, onChange, onNext, onBack, authorizeProperty}) {
    const intl = useIntl();
    const {methodType} = paymentMethod
    const agreementProps = {
        paymentMethod,
        authorized
    }

    useEffect(()=>{
        if (authorized) onChange(false) //always start with not being authorized yet when starting this step
    },[])


    return (
        <Details
            onSubmit={onNext}
            onCancel={onBack}
            submitLabel='make-payment.btn.continue'
            cancelLabel='ups.btn.back.label'
            canProceed={authorized}
        >
            {getAgreementComponent[methodType]?.(agreementProps)}
            <MDBRow>
                <MDBCol size='12'>
                    <CheckboxWithValidation
                        id='authorizePayment'
                        name='authorizePayment'
                        label={intl.formatMessage({
                            id: 'sepa-mandate.sign.otp.approval'
                        })}
                        value={authorized}
                        onChange={({target: {checked}}) => onChange(checked)}
                        validations={[
                            ['required', "field.authorize"]
                        ]}
                    />
                </MDBCol>
            </MDBRow>
        </Details>
    )
}

const getAgreementComponent = {
    BACS:(agreementProps)=><BacsAgreement {...agreementProps}/>
}

export const AgreementSteps = {
    bacs: 'authorizeDD'
}
