import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as settingAction from '../actions/setting-action';
import * as actionTypes from '../constants/action-types';
import { setIssuer } from '../utils/local-storage';
import {getLocaleFiles} from '../utils/i18n'; 

function* setSelectedLocale(language) {
  try {
    if(language.data){
      /* dispatch action */
      yield call(getLocaleFiles, language.data);
      yield put(settingAction.setLocaleSuccess(language.data));
    }
  } catch (error) {
    // console.log('error: setLocaleLocalStorage', error);
  }
}

export function* setLocaleSaga() {
  yield takeEvery(actionTypes.SET_LOCALE, setSelectedLocale);
}

function* setIssuerInLocalStorage(issuer) {
    try {
        yield call(setIssuer, issuer.data);
        /* dispatch action */
        yield put(settingAction.setIssuerSuccess(issuer.data));
    } catch (error) {
        // console.log('error: setLocaleLocalStorage', error);
    }
}

export function* setIssuerSaga() {
    yield takeEvery(actionTypes.SET_ISSUER, setIssuerInLocalStorage);
}


export default function* authSaga() {
  yield all([
      fork(setLocaleSaga),
      fork(setIssuerSaga)
  ]);
}