import React, { Component } from 'react';
import {MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRow} from 'mdbreact';
import { withRouter } from 'react-router-dom';
import Exception from '../../Exception/index'
import produce from "immer";
import classNames from "classnames";
import {injectIntl} from "react-intl";
import RadioIntl from "../../RadioIntl";
import {stripAccountNumber} from "../../../utils/ups-utils";
import {accountSupportsDebitOnly} from "../../../utils/payment-utils";
import * as validationActions from "../../../actions/validation-action";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ValidationErrors from "../../ValidationErrors";
import attachValidator from "../../../utils/validation/attach-validator";
import {FormattedMessage} from "react-intl";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";

class AccountPlanSelectionCardTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            filterType: '',
            filterType2: '',
            datatableCols: [],
            accountListKeys: [],
            itemSelectList: [],
            planSelectList: [],
            accountSelectList: [],
            accountPlanSelection: {},
            tableState: {
                filter: {},
                ...INITIAL_TABLE_STATE
            }
        };
        if (this.props.validations) attachValidator.call(this)
    }

    componentDidMount() {
        let {name, validations} = this.props;
        if (validations) this.validator.register(name, () => this.props.selectedAccount)
    }

    componentWillUnmount() {
        let {name, validations} = this.props;
        if (validations) this.validator.deregister(name)
    }

    createDatatableCols = () => {
        const {intl} = this.props;
        return [
            {
                field: 'card',
                label: '',
                tdClassName: 'datatable-selection-col no-export',
                orderable: false,
                display: (data) => this.createDatatableRowSelection(data),
                serialize: (account) => {
                    const {isParent, parentAccount, accountNumber, paymentType, accountName, planNumber} = account;

                    const strAcc = stripAccountNumber(accountNumber, paymentType);
                    const strPlan = stripAccountNumber(planNumber, paymentType);
                    let searchText = ''
                    if (accountName) searchText += accountName
                    if (isParent) searchText += intl.formatMessage({id:'plan.name.text'}, {accountNumber: strPlan})
                    if (parentAccount) {
                        const pAcc = stripAccountNumber(parentAccount.accountNumber, parentAccount.paymentType);
                        searchText += intl.formatMessage({id:'plan.name.text'}, {accountNumber: pAcc})
                    }
                    if (!isParent) searchText += intl.formatMessage({id:'account.name.text'}, {accountNumber: strAcc})
                    if (accountSupportsDebitOnly(account)) searchText += intl.formatMessage({id:'modal.view-schedule.DD-only'})
                    return searchText
                },
                sortFunc: ({parentAccount, isParent, planNumber, accountNumber}) => {
                    let sortText = (isParent || parentAccount) ? 'p' : 'a';
                    if (isParent) sortText += planNumber?.concat('0')
                    if (!parentAccount && !isParent) sortText += accountNumber
                    if (parentAccount) sortText += (parentAccount.accountNumber + 'a' + accountNumber)
                    return sortText;
                }
            }
        ]
    }

    createDatatableRowSelection = (data) => {
        const { selectedAccount } = this.props;
        let key = `autopay-account-select-${data.id}`;
        return (
            <RadioIntl
                key={key}
                name={key}
                id={key}
                labelClass={"plan-label mr-0"}
                type={"radio"}
                containerClass={classNames({"top-plan-level": data.isParent})}
                checked={selectedAccount?.id === data.id}
                onChange={(e)=>this.onChange(e, data)}
                label={this.createDatatableRowText(data)}
            />
        )
    };

    onChange(e, data) {
        const {validations, name, onChange} = this.props;
        if (validations) this.validator.validate(name, true) //any value is valid
        onChange(data)
    }

    createDatatableRowText = (data) => {
        const {intl} = this.props;
        const {paymentType, parentAccount, isParent, accountName} = data;
        const accountNumber = stripAccountNumber(data.accountNumber, paymentType);
        const planNumber = stripAccountNumber(data.planNumber, paymentType);
        const pAcc = parentAccount && stripAccountNumber(parentAccount.accountNumber, parentAccount.paymentType)
        return (
            <div>
                {accountName && (<div className='datatable-card-description'>{accountName}</div>)}
                <div>{isParent
                        ? <strong>{intl.formatMessage({id:'plan.name.text'}, {accountNumber: planNumber})}</strong>
                        : <span>{intl.formatMessage({id:'account.name.text'}, {accountNumber})}</span>
                }</div>
                {parentAccount && <div>{intl.formatMessage({id:'plan.name.text'}, {accountNumber: pAcc})}</div>}
                <span className={'card-display-item display-accepted-payment-method'}>
                    {accountSupportsDebitOnly(data) && <React.Fragment>
                        <i className="fa fa-university mr-1"></i>
                        {intl.formatMessage({id:'modal.view-schedule.DD-only'})}
                    </React.Fragment>}
                </span>
            </div>
        )
    };

    setFilter(type, index='') {
        let filterType = 'filterType'+index
        this.setState(produce(draft => {
            if (draft.tableState.filter[filterType] === type) {
                draft.tableState.filter[filterType] = ''
            } else {
                draft.tableState.filter[filterType] = type
            }
        }))
    }

    filterFunc(data) {
        const {planNumber, parentAccount} = data;
        let {filterType, filterType2} = this.state.tableState.filter
        let show = true
        if (filterType || filterType2) {
            if (filterType === "PLAN") {
                show &= (!!planNumber || !!parentAccount)
            } else if (filterType === "ACCOUNT") {
                show &= (!planNumber && !parentAccount)
            }
            if (filterType2 === "BANKDIRECTDEBIT") {
                show &= accountSupportsDebitOnly(data)
            }
        }
        return show
    }

    renderFilterDOM = () => {
        const {filterType, filterType2} = this.state.tableState.filter;
        const {hidePlanAccountFilterOptions, intl} = this.props;
        const filterTypePlan = (filterType === "PLAN")
        const filterTypeAccount = (filterType === "ACCOUNT")
        const filterTypeBank = (filterType2 === "BANKDIRECTDEBIT")
        return <div className={"manage-custom-buttons"}>
            <MDBDropdown className={"datatable-custom-dropdown"}>
                <MDBDropdownToggle color="secondary" className={"btn-icon outline-fix"} aria-label={intl.formatMessage({ id: "filter.title" })}>
                    <span><svg version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         width="32" height="32"
                         className="datatable-ups-svg-icon"
                         viewBox="0 0 32 32">
                        <title>
                            <FormattedMessage id="filter.title"/>
                        </title>
                        <path className="st0" d="M28.4,0H1.6C1.2,0,0.8,0.3,0.8,0.7c0,0.2,0.1,0.4,0.2,0.5l10.2,12.2v10.8c0,0.4,0.2,0.9,0.6,1.1l5.7,4.5 c0.3,0.3,0.8,0.2,1.1-0.1c0.1-0.1,0.2-0.3,0.2-0.5V13.4L29,1.2c0.3-0.3,0.2-0.8-0.1-1C28.8,0.1,28.6,0,28.4,0z"/>
                    </svg></span>
                    <span>{(filterType || filterType2) && <i className="fa fa-check-circle filter-active"/>}</span>
                </MDBDropdownToggle>

                <MDBDropdownMenu basic right>
                    {!hidePlanAccountFilterOptions && <MDBDropdownItem header>
                        <FormattedMessage id='schedule.account-plan-selection.filter.account-type'/>
                    </MDBDropdownItem>}

                    {!hidePlanAccountFilterOptions && <MDBDropdownItem
                        toggle={false} active={filterTypePlan}
                        className={classNames({"disable": (!filterTypePlan && filterTypeAccount)}, "outline-fix")}
                        onClick={() => this.setFilter("PLAN")}
                    >
                                <span className={"dropdrop-item-checkbox"}>
                                    <FormattedMessage id='schedule.account-plan-selection.filter.plans'/>
                                </span>
                    </MDBDropdownItem>}

                    {!hidePlanAccountFilterOptions && <MDBDropdownItem
                        toggle={false} active={filterTypeAccount}
                        className={classNames({"disable": (filterTypePlan && !filterTypeAccount)}, "outline-fix")}
                        onClick={() => this.setFilter("ACCOUNT")}
                    >
                                <span className={"dropdrop-item-checkbox"}>
                                    <FormattedMessage id='schedule.account-plan-selection.filter.accounts'/>
                                </span>
                    </MDBDropdownItem>}

                    <MDBDropdownItem header>
                        <FormattedMessage id='schedule.account-plan-selection.filter.payment-method'/>
                    </MDBDropdownItem>

                    <MDBDropdownItem
                        toggle={false} active={filterTypeBank}
                        onClick={() => this.setFilter("BANKDIRECTDEBIT",2)}
                        className={"outline-fix"}
                    >
                                <span className={"dropdrop-item-checkbox"}>
                                    <i className="fas fa-university mr-1"></i><FormattedMessage id='schedule.account-plan-selection.filter.bank'/>
                                </span>
                    </MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
    }

    render() {
        const {error, tableState} = this.state;
        const {accountList, source, validations, ownVState, name, caption,intl, hidePlanAccountFilterOptions} = this.props;
        if (error instanceof TypeError) {
            return (<Exception error={error}/>)
        } else {
            return <React.Fragment>
                <FilteredDataTableV3
                    name={source}
                    caption={caption}
                    searchInputAriaLabel= {intl.formatMessage({ id: `datatable.account${ hidePlanAccountFilterOptions? "" : "-plan"}.search.arialabel`})}
                    className="table-card table-card-plan"
                    wrapperClassName='card-wrapper'
                    trClassName={()=>'card-row'}
                    itemsPerPage={[20, 50, 100]}
                    data={accountList}
                    columns={this.createDatatableCols()}
                    defaultSorting={'card'}
                    buttons={[
                        this.renderFilterDOM
                    ]}
                    tableState={tableState}
                    tableStateAction={(tableAction)=>this.setState(tableAction)}
                    filterFunc={this.filterFunc.bind(this)}
                    searchable
                    searchRight
                />

                <div className="mt-4">
                    {(validations && ownVState?.messages) &&
                        <ValidationErrors name={name} messages={ownVState.messages}/>
                    }
                </div>
            </React.Fragment>;
        }
    }
}

function mapStateToProps(state, ownprops) {
    return {
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState: state.validation.vState[ownprops.name]
        } : {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AccountPlanSelectionCardTable)));
