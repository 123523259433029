import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import { MDBBtn} from "mdbreact";
class LoginTestPage extends Component {

    constructor(props) {
        super(props);       
    }

    redirectToLogin = () => {
        this.props.history.push("/login");
    }

    render() {        
        return (
            <div className={"ups-landing-container"}>
                <div className="text-center mb-4 mt-5">
                    <MDBBtn color="primary" onClick={() => this.redirectToLogin()}>Log in to Billing center</MDBBtn>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginTestPage);

