import React from "react";
import {
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn
} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";

import ReactPortalModal from "../../../ReactPortalModal";
import ScheduleView from "../../ScheduleView";

export default function ViewScheduleModal({ modalId, toggleModal, isOpen, schedule }) {
    const intl = useIntl();
    const closeModal = () => {
        toggleModal(modalId);
    };
    const handlePrint = e => {
        window.print();
        e.preventDefault();
    };

    const modalHeading = "modal.view-schedule.title";
    return <ReactPortalModal isOpen={isOpen} an_label="view-schedule">
        <MDBModal isOpen={isOpen} toggle={closeModal} size="md" disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading}, {id: schedule.referenceId})}>
            <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading}, {id: schedule.referenceId} ) })} toggle={closeModal}>
                <FormattedMessage id={modalHeading} values={{id: schedule.referenceId}}/>
            </MDBModalHeader>

            <MDBModalBody>
                <ScheduleView schedule={schedule}/>
            </MDBModalBody>

            <MDBModalFooter>
                <MDBBtn color="primary" onClick={handlePrint}>
                    <FormattedMessage id="ups.btn-print"/>
                </MDBBtn>
                <MDBBtn color="cancel" onClick={closeModal}>
                    <FormattedMessage id="close.label"/>
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </ReactPortalModal>;
}
