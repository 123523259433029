import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import * as R from 'ramda';

/* Redux */
import {useSelector, useDispatch} from 'react-redux';

import PublicLayout from '../layouts/PublicLayout';
import PrivateLayout from '../layouts/PrivateLayout';

import Page from '../pages';
import BdLogin from '../pages/BdLogin';
import Login from '../pages/Login';
import LoginCallback from '../pages/LoginCallback';
import Enrollment from '../pages/Enrollment';
import Home from '../pages/Home';
import CSRLogin from '../pages/CSRLogin';

import Invoice from '../pages/UPSInvoice';
import PayInvoice from '../pages/UPSPayInvoice';
import ManageAccount from '../pages/ManageAccount';
import ManagePlan from '../pages/ManagePlan';
import PlanInvoice from '../pages/UPSPlanInvoice';
import Wallet from '../pages/UPSWallet';
import ManageUsers from '../pages/UPS/ManageUsers';
import ManageDisputes from '../pages/UPS/ManageDisputes';
import AutoPay from '../pages/UPSAutoPay';
import PaymentHistory from '../pages/UPSPaymentHistory';
import * as validationActions from "../actions/validation-action";
import * as errorActions from "../actions/error-action";
import UPSInvoiceDetails from '../pages/UPSInvoiceDetails';
import UPSProfilePreferences from '../pages/UPSProfilePreferences';
import UPSPayByText from '../pages/UPSPayByText';
import UPSSupport from '../pages/UPSSupport';
import ErrorModal from '../pages/ErrorModal';
import UPSServiceTerms from '../pages/UPSServiceTerms';
import UPSPrivacyPolicy from '../pages/UPSPrivacyPolicy';
import {logTealiumAnalytics, TEALIUM_EVENT} from '../utils/tealium-utils';
import CSRCallback from '../pages/CSRCallback';
import {ERROR_TYPE} from '../constants/error-type';
import UPSAdvanceSearch from '../pages/UPSAdvanceSearch';
import LoginTestPage from '../pages/LoginTestPage';
import {ADMIN_ROUTES} from '../constants/routes';
import {PREFERENCES_ROUTES} from '../constants/preferences-constants';
import NotificationPreferences from '../components/UPS/UPSPreferenceModal/NotificationPreferences';
import SecurePDFPreferences from '../components/UPS/UPSPreferenceModal/SecurePDFPreferences';
import PayByTextPreferences from '../components/UPS/UPSPreferenceModal/PayByTextPreferences';
import SessionTimeOut from '../pages/SessionTimeOut';
import LogonWait from '../pages/LogonWait';
import * as chatbotActions from "../actions/chatbot-action";
import UPS3DSecureReturn from "../pages/UPS3DSecureReturn";
import WalletLanding from '../pages/UPS3DSecureLanding';
import DefaultPage from '../pages/UPSDefault';

export const routes = [
    {
        id: 'publicLayout',
        layout: PublicLayout,
        subRoutes: [
            {
                path: "/enrollment",
                title: "ups.tab-title.enrollment",
                component: Enrollment
            },
            {
                path: "/bd/login",
                component: BdLogin
            },
            {
                path: "/login",
                component: Login
            },
            {
                path: "/login/callback",
                component: LoginCallback
            },
            {
                path: "/error/:errorCode?",
                component: ErrorModal
            },
            {
                path: "/login_csr",
                component: CSRLogin
            },
            {
                path: "/csr/callback",
                component: CSRCallback
            },
            {
                path: "/login_test",
                component: LoginTestPage
            },
            {
                path:"/timeout",
                component: SessionTimeOut
            },
            {
              path: '/login/validation',
              component: LogonWait
            }
        ]
    },
    {
        id: 'privateLayout',
        layout: PrivateLayout,
        subRoutes: [
            {
                path: '/',
                title: 'ups.tab-title.dashboard',
                component: DefaultPage
            },
            {
                path: '/home',
                title: 'ups.tab-title.dashboard',
                component: Home
            },
            {
                path: '/guestenrollment',
                title: 'ups.tab-title.dashboard',
                component: Home
            },
            {
                path: '/home/error/:errorCode?',
                title: 'ups.tab-title.dashboard',
                component: Home
            },
            {
                path: '/ups/billing/wallet/error/:errorCode?',
                title: "ups.tab-title.wallet",
                component: Wallet,
                permission: "wallet_revision_history"
            },
            {
                path: '/autopay/error/:errorCode?',
                title: "ups.tab-title.autopay",
                component: AutoPay,
                permission: "payment_search"
            },
            {
                path: "/ups/billing/invoice/:invoiceId/details",
                exact: true,
                component: UPSInvoiceDetails
            },
            {
                path: "/ups/billing/planaccountinvoice/:invoiceId/:planInvoiceId/details",
                exact: true,
                component: UPSInvoiceDetails
            },
            {
                path: "/ups/billing/invoice/:filterType?",
                title: null, // this route can nest under plan invoices so let the children customize title
                render: (props) => {
                    return <Invoice key={props.location.key} {...props} />
                }
            },
            {
                path: "/ups/billing/dispute",
                title: "ups.tab-title.dispute",
                component: ManageDisputes
            },
            {
                path: "/ups/billing/otp",
                title: "ups.tab-title.pay",
                component: PayInvoice,
                permission: "otp_payment"
            },
            {
                path: "/ups/billing/wallet",
                title: "ups.tab-title.wallet",
                component: Wallet,
                permission: "wallet_revision_history"
            },
            {
                path: ADMIN_ROUTES.MANAGE_ACCOUNTS,
                title: "ups.tab-title.manage-accounts",
                component: ManageAccount,
                permission: "account_view",
                sideNavState:{adminOpen:true}
            },
            {
                path: ADMIN_ROUTES.MANAGE_PLANS,
                title: "ups.tab-title.manage-plans",
                component: ManagePlan,
                permission: "account_view",
                sideNavState:{adminOpen:true}
            },
            {
                path: ADMIN_ROUTES.PAY_BY_TEXT,
                title: "ups.tab-title.payByText",
                component: UPSPayByText,
                permission: "payment_search",
                sideNavState:{adminOpen:true}
            },
            {
                path: '/ups/billing/plan/:filterType?',
                title: null, //can vary depending on business unit, and is set manually in child
                component: PlanInvoice
            },
            {
                path: ADMIN_ROUTES.MANAGE_USERS,
                title: "ups.tab-title.manage-users",
                component: ManageUsers,
                permission: "user_modify",
                sideNavState:{adminOpen:true}
            },
            {
                path: "/autopay/:openModal?",
                title: "ups.tab-title.autopay",
                component: AutoPay,
                permission: "payment_search"
            },
            {
                path: "/payments",
                title: "ups.tab-title.payment-history",
                component: PaymentHistory,
                permission: "payment_search"
            },
            {
                path: "/support",
                title: "ups.tab-title.support",
                component: UPSSupport
            },
            {
                path: "/ups/billing/terms",
                title: "ups.tab-title.terms",
                component: UPSServiceTerms
            },
            {
                path: "/ups/billing/privacy",
                title: "ups.tab-title.privacy",
                component: UPSPrivacyPolicy
            },
            {
                path: "/ups/billing/search",
                title: "ups.tab-title.search",
                component: UPSAdvanceSearch
            },
            {
                path: PREFERENCES_ROUTES.PREF_OVERVIEW,
                title: "ups.tab-title.pref_overview",
                component: UPSProfilePreferences,
                sideNavState:{preferencesOpen:true}
            },
            {
                path: PREFERENCES_ROUTES.PREF_NOTIFICATIONS,
                title: "ups.tab-title.pref_notifications",
                component: NotificationPreferences,
                sideNavState:{preferencesOpen:true}
            },
            {
                path: PREFERENCES_ROUTES.PREF_PDF_INVOICE,
                title: "ups.tab-title.pref_pdf_invoice",
                component: SecurePDFPreferences,
                sideNavState:{preferencesOpen:true}
            },
            {
                path: PREFERENCES_ROUTES.PREF_PAY_BY_TEXT,
                title: "ups.tab-title.pref_payByText",
                component: PayByTextPreferences,
                sideNavState:{preferencesOpen:true}
            },
            {
                path: "/ups/billing/3ds/:redirectResult?",
                title: "ups.tab-title.wallet",
                component: UPS3DSecureReturn,
                permission: "wallet_revision_history"
            },
            {
                path: "/ups/billing/wallet/landing/:redirectResult?",
                title: "ups.tab-title.wallet",
                component: WalletLanding,
                permission: "wallet_revision_history"
            },
        ]
    }
];

export default function Routes() {
    const auth = useSelector(s => s.auth);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        //this action will update page_id for chatbot when page refreshes - UPS-9432
        dispatch(chatbotActions.updateChatbotTracking({pathname: history.location.pathname}));

        history.listen((location, action) => {
            logTealiumAnalytics(TEALIUM_EVENT.VIEW, {"page_id": location.pathname.replace(/^\/+/g, '')});
            dispatch(chatbotActions.updateChatbotTracking({pathname: location.pathname}));

            const bodyElement = document.querySelector("body.fixed-sn");
            bodyElement?.focus();
        });
    }, [])


    const hasPermission = permission => {
        const {user} = auth;
        let isAllowed = false;

        if (user && user.permissions) {
            Object.keys(user.permissions).forEach(field => {
                if (field === permission) {
                    isAllowed = true;
                    return;
                }
            });
        }

        return isAllowed;
    }

    return (
        <div>
            <Switch>
                {routes.map((route) =>
                    <Route key={route.id} exact={route.subRoutes.some(r => r.exact)}
                           path={route.subRoutes.map(r => r.path)}>
                        <route.layout>
                            {route.subRoutes.map((subRoute) => {
                                    return (
                                        subRoute.permission && !hasPermission(subRoute.permission) ?
                                            <Route key={subRoute.path} path={subRoute.path}
                                                   render={(innerProps) => {
                                                       innerProps.history.push({
                                                           pathname: '/error',
                                                           errorType: ERROR_TYPE.ROUTE,
                                                           redirectPath: 'home'
                                                       });
                                                       return null;
                                                   }}
                                                   permission={subRoute.permission} exact/>
                                            :
                                            <Route key={subRoute.path} {...R.omit(['component'])(subRoute)} exact
                                                   render={props => {
                                                       const {component: Component, render, title} = subRoute;

                                                       if (Component) return <Page title={title}>
                                                           <Component {...props}/>
                                                       </Page>;

                                                       return <Page title={title}>
                                                           {render(props)}
                                                       </Page>;
                                                   }}
                                            />
                                    )
                                }
                            )}
                        </route.layout>
                    </Route>
                )}
            </Switch>
        </div>
    )
}
