import React from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import CardActions from '../CardActions';
import {maskString} from '../../../../utils/utils';
import {formatPMDesc} from '../../../../utils/payment-utils';

export default ({
    editHandler, removeHandler, makeDefaultHandler, showCardActions, extraCssClass,
    paymentMethod: {
        accountNumber, routingNumber, cardHolderName, fiName,
        methodType, cardNickName, default: isPrimary, bankId, bankTransitNumber, tokenPending
    },
    permissions
}) => {
    const intl = useIntl();
    const toLabel = formatPMDesc(intl);
    let echeckPermissions = {...permissions}

    return <React.Fragment>
        <div className={classNames("card-container echeck", extraCssClass)}>
            <p className="pm-info card-nickname">{cardNickName}</p>
            {tokenPending && <p className="pm-info card-pending-token">
                <span className='pm-card-pending-token-text'>
                    <FormattedMessage id={`card.pending-token`} />
                </span>
            </p>}
            <p className="pm-info echeck-type">{toLabel(methodType)}</p>
            <p className="pm-info account-holder-name">{cardHolderName}</p>
            {fiName && <p className="pm-info bank-name">{fiName}</p>}
            <p className="pm-info routing-number">{routingNumber ? maskString(routingNumber) : maskString(bankId + bankTransitNumber)}</p>
            <p className="pm-info bank-account-number">{maskString(accountNumber)}</p>
            <div className="echeck-decoration-overlay">
                <div className="echeck-decoration-line1"/>
                {fiName && <div className="echeck-decoration-line3"/>}
                {fiName && <div className="echeck-decoration-line4"/>}
                <div className="echeck-decoration-box1"/>
                {fiName && <div className="echeck-decoration-icon-bank">
                    <i className="fa fa-university" aria-hidden="true"/>
                </div>}
            </div>
        </div>
        {showCardActions &&
            <CardActions
                editHandler={editHandler}
                removeHandler={removeHandler}
                makeDefaultHandler={makeDefaultHandler}
                isPrimary={isPrimary}
                permissions={echeckPermissions}
                cardNickName={cardNickName}
                pmLabel={`${toLabel(methodType)} ${intl.formatMessage({id: `payment.method.ECheckCard.Ending.with.bank-account-number.btn.label`},{accountNumber: accountNumber.slice(-4)})}`}
                tokenPending={tokenPending}
            />
        }
    </React.Fragment>;
};
