import * as actionTypes from '../constants/action-types';

export function resetValidation() {
    return {
        type: actionTypes.CLEAR_VALIDATION
    }
}

export function submitValidation(){
    return {
        type: actionTypes.SUBMIT_VALIDATION
    }
}

export function updateValidation(data) {
    return {
        type: actionTypes.SET_VALIDATION,
        data: data
    }
}

export function registerField(data) {
    return {
        type: actionTypes.REGISTER_FIELD,
        data: data
    }
}

export function deregisterField(data) {
    return {
        type: actionTypes.DEREGISTER_FIELD,
        data: data
    }
}

export function initValidation(data) {
    return {
        type: actionTypes.INIT_VALIDATION,
        data: data
    }
}



