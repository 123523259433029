import React, { Component } from 'react'
import {  MDBBtn, MDBRow, MDBCol, MDBDatePicker, MDBFormInline } from "mdbreact";
import { FormattedMessage } from 'react-intl';
import InputFieldIntl from '../../../components/InputFieldIntl';
import {connect} from "react-redux";
import PlayBackTable from '../PlayBackTable'

class UserDetails extends Component {

    render() {
        const { editMode, user, handleInput, handleOnBlur, titleMsgId, textMsgId, playBackTitleMsgId, currentUser } = this.props;

        let userRoleList = ["VIEW_ONLY","VIEW_PAY_DISPUTE","PLAN_MANAGER"];
        if(currentUser.permissions && currentUser.permissions['user_admin_modify']) userRoleList.push("ADMINISTRATOR")
        let userRoleListComponents = [];

        for (var userRoleOption of userRoleList) {
            userRoleListComponents.push(
                <React.Fragment key={`userRoleOptionType_${userRoleOption}`}>
                    <MDBRow>
                        <MDBCol size={"12"} md={"4"}>
                            <InputFieldIntl
                                label={<FormattedMessage id={`ups.manage-user.role.${userRoleOption}.label`} />}
                                type="radio"
                                name="role"
                                id={`userRole.${userRoleOption}`}
                                labelClass={"mr-0"}
                                value={userRoleOption}
                                checked={user.role === userRoleOption}
                                onChange={handleInput}
                                containerClass={"mx-0 mb-3 pl-0"}
                            />
                        </MDBCol>
                        <MDBCol size={"12"} md={"8"}>
                            <FormattedMessage
                                id={`ups.manage-user.role.${userRoleOption}.description`}
                                tagName={'p'}
                                values={{
                                    style: msg => (
                                        <i>{msg}</i>
                                    )
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </React.Fragment>
            )
        }

        let components = [];

        if (editMode) {
            components.push(
                <React.Fragment key='new-user-input-fragment'>
                <MDBRow key={"new-user-input-fields"}>
                    <MDBCol size={"12"}>
                        <p className="ups-note-1 mb-0">
                            <FormattedMessage id="note.required-fields"
                              values={{
                                  style: msg => (<span className="font-italic">{msg}</span>)
                              }} />
                        </p>
                    </MDBCol>
                    <MDBCol size={"12"} md={"6"}>
                        <InputFieldIntl
                            key={"userName"}
                            name={"userName"}
                            id={"userName"}
                            label={<FormattedMessage id={"ups.add-new-user.user-id.label"}/>}
                            value={user.userName}
                            onChange={handleInput}
                            onBlur={handleOnBlur}
                            hint={"ups.add-new-user.user-id.hint"}
                            required
                            validations={[
                                ['required', 'field.required'],
                                ['userIdCharacters', 'field.userIdCharacters'],
                                ['minmaxlength', 'userId.minmaxlength', {min: 1, max: 36}]
                            ]}
                        />
                    </MDBCol>
                    <MDBCol size={"12"} md={"6"}>
                        <InputFieldIntl
                            key={"email"}
                            name={"email"}
                            id={"email"}
                            label={<FormattedMessage id={"ups.add-new-user.user-email.label"}/>}
                            value={user.email}
                            onChange={handleInput}
                            hint={"ups.add-new-user.user-email.hint"}
                            required
                            validations={[
                                ['required', 'field.required'],
                                ['email', 'field.validemail']
                            ]}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow key='role-legend'>
                    <MDBCol size={"12"}>
                        <fieldset required>
                            <legend><FormattedMessage id={"ups.manage-user.role.legend"} />*</legend>
                            {userRoleListComponents}
                        </fieldset>
                    </MDBCol>
                </MDBRow>
                </React.Fragment>

            )
        } else {
            components.push(
                <MDBRow key={"user-details-play-back-title"}>
                    <MDBCol size={"12"}>
                        <PlayBackTable playBackTitleMsgId={playBackTitleMsgId} user={user} />
                    </MDBCol>
                </MDBRow>
            )
        }

        return(
            <React.Fragment>
                {titleMsgId || textMsgId ?
                <MDBRow>
                    <MDBCol size={"12"}>
                        {titleMsgId ? <h3 className={"mb-4"}><FormattedMessage id={titleMsgId} /></h3> : null }
                        {textMsgId ? <p><FormattedMessage id={textMsgId} /></p> : null}
                    </MDBCol>
                </MDBRow>
                    : null}
                {components}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.auth.user
    }
}

export default connect(mapStateToProps)(UserDetails);