import React, { Component } from 'react';
import { MDBInput } from "mdbreact";

// Use the RadioGroup or InputIntl component for full-featured radio elements. This is a very barebones radio element for use as a datatable selector.
class RadioIntl extends Component{
    render(){
        const { name, id, label, checked, onChange, tabIndex, containerClass, className, labelClass, ...rest } = this.props;
        return (
            <MDBInput
                name={name}
                id={id || name}
                type={'radio'}
                label={label}
                checked={checked}
                onChange={onChange}
                containerClass={containerClass}
                className={className}
                tabIndex={tabIndex}
                labelClass={labelClass || ''}
                {...rest}
            />
        )
    }
}

export default RadioIntl;