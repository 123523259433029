import * as actionTypes from '../constants/action-types';

export const createIdentity = (data) => {
    return {
        type: actionTypes.CREATE_IDENTITY,
        data: data
    }
};


export const setIdentity = (data) => {
    return {
        type: actionTypes.SET_IDENTITY,
        data: data
    }
};

export const updateIdentity = (data) => {
    return {
        type: actionTypes.UPDATE_IDENTITY,
        data: data
    }
};

export const updateIdentityStaging = (data) => {
    return {
        type: actionTypes.UPDATE_IDENTITY_STAGING,
        data: data
    }
};

export const updateIdentitySuccess = (data) => {
    return {
        type: actionTypes.UPDATE_IDENTITY_STAGING,
        data: data
    }
};

export const updateIdentityAccount = (data, index) => {
    return {
        type: actionTypes.UPDATE_IDENTITY_ACCOUNT,
        data: data,
        index: index
    }
};

export const undoManageIdentity = () => {
    return {
        type: actionTypes.UNDO_MANAGE_IDENTITY
    }
};

export const addAccountToIdentity = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_IDENTITY,
        account: data.accountToAddToIdentity
    }
};

export const addAccountToIdentitySuccess = (data) => {
    return {
        type: actionTypes.ADD_ACCOUNT_TO_IDENTITY_SUCCESS,
        data: data
    }
};


export const removeAccountFromIdentity = (index) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_FROM_IDENTITY,
        index: index
    }
};


export const getIdentity = (identityId, callback) => {
    return {
        type: actionTypes.GET_IDENTITY,
        identityId,
        callback
    }
};

export const checkIdentityExists = (data, callback) => {
    return {
        type: actionTypes.IDENTITY_EXISTS,
        data,
        callback
    }
};

export const getIdentitySuccess = (data) => {
    return {
        type: actionTypes.GET_IDENTITY_SUCCESS,
        data: data
    }
};

export const getIdentityFailure = (data) => {
    return {
        type: actionTypes.GET_IDENTITY_FAILURE,
        data: data
    }
};

export const getIdentityList = (data) => {
    return {
        type: actionTypes.GET_IDENTITY_LIST,
        data: data
    }
};

export const getIdentityListSuccess = (data) => {
    return {
        type: actionTypes.GET_IDENTITY_LIST_SUCCESS,
        data: data
    }
};

export const getIdentityListFailure = (data) => {
    return {
        type: actionTypes.GET_IDENTITY_LIST_FAILURE,
        data: data
    }
};

export const clearIdentity = () => {
    return {
        type: actionTypes.CLEAR_IDENTITY
    }
};

export const clearIdentityList = () => {
    return {
        type: actionTypes.CLEAR_IDENTITY_LIST
    }
};

export const clearChangeLog = () => {
    return {
        type: actionTypes.CLEAR_CHANGE_LOG
    }
};

export const getIdentityAccountList = (callback) => {
    return {
        type: actionTypes.GET_IDENTITY_ACCOUNT_LIST,
        callback
    };
};

export const getIdentityAccountListSuccess = (data) => {
    return {
        type: actionTypes.GET_IDENTITY_ACCOUNT_LIST_SUCCESS,
        data
    };
};

export const getIdentityAccountListFailure = () => {
    return {
        type: actionTypes.GET_IDENTITY_ACCOUNT_LIST_FAILURE
    };
};

export const getAccountAggregateByIdentity = (identityId, callBack) => {
    return {
        type: actionTypes.GET_ACCOUNT_AGGREGATE_BY_IDENTITY,
        identityId,
        callBack: callBack
    }
};

export const getAccountAggregateByIdentitySuccess = (data) => {
    return {
        type: actionTypes.GET_ACCOUNT_AGGREGATE_BY_IDENTITY_SUCCESS,
        data
    }
};

export const getAccountAggregateByIdentityFailure = () => {
    return {
        type: actionTypes.GET_ACCOUNT_AGGREGATE_BY_IDENTITY_FAILURE
    }
};

/**
 * Shell for saga-redux generator function: savePreferencesUpdate(action)
 * in apps/billing-center/src/sagas/identity-saga.js
 *
 * @param preferences to update into identity's preferences.
 * @param {string} endpointUrlPostfix one of payByPdf, payByText, notifications, tableSearchCriteria, tableColumns or tableFilter
 * @param callback
 * @returns {{preferences, callback, type: string, endpointUrlPostfix}}
 */
export const saveIdentityPreferences = (preferences, endpointUrlPostfix, callback) => {
    return {
        type: actionTypes.SAVE_IDENTITY_PREFERENCES_UPDATE,
        preferences,
        endpointUrlPostfix,
        callback
    };
};



export const saveIdentityPreferencesSuccess = (data) => {
    return {
        type: actionTypes.SAVE_IDENTITY_PREFERENCES_SUCCESS,
        data
    };
};

export const saveIdentityPreferencesFailure = () => {
    return {
        type: actionTypes.SAVE_IDENTITY_PREFERENCES_FAILURE
    };
};

export const setIdentityPreferences = (data) => {
    return {
        type: actionTypes.SET_IDENTITY_PREFERENCES,
        data: data
    }
};

export const setIdentityPlanEnrolled = (data) => {
    return {
        type: actionTypes.SET_IDENTITY_PLAN_ENROLLED,
        data: data
    }
};

export const setSelectedCountry = (data) => {
    return {
        type: actionTypes.SET_SELECTED_COUNTRY,
        data: data
    }
};

// USER MANAGEMENT PAGE ACTIONS START

export const addUser = (data, callback) => {
    return {
        type: actionTypes.ADD_USER,
        data,
        callback
    }
}

export const editUser = (data, callback) => {
    return {
        type: actionTypes.EDIT_USER,
        data,
        callback
    }
}

export const removeUser = (data, callback) => {
    return {
        type: actionTypes.REMOVE_USER,
        data,
        callback
    }
}

export const getIdentityListByCompanyId = (callback) => {
    return {
        type: actionTypes.GET_IDENTITY_LIST_BY_COMPANY_ID,
        callback
    }
};

// USER MANAGEMENT PAGE ACTIONS END

export const getAccessibleMerchants = (data) => {
    return {
        type: actionTypes.GET_ACCESSIBLE_MERCHANTS,
        data: data
    }
};

export const getAccessibleMerchantsSuccess = (data) => {
    return {
        type: actionTypes.GET_ACCESSIBLE_MERCHANTS_SUCCESS,
        data: data
    }
};

export const getAccessibleMerchantsFailure = (data) => {
    return {
        type: actionTypes.GET_ACCESSIBLE_MERCHANTS_FAILURE,
        data: data
    }
};

export const setSelectedPaymentType = (data) => {
    return {
        type: actionTypes.SET_SELECTED_PAYMENT_TYPE,
        data: data
    }
};

export const setUserCountrySettings = (data) => {
    return {
        type: actionTypes.SET_USER_COUNTRY_SETTINGS,
        data: data
    }
};

export const updateUserSession = (data) => {
    return {
        type: actionTypes.UPDATE_USER_SESSION,
        data: data
    }
};