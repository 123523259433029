import React, {Component} from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBPopover, MDBPopoverBody} from "mdbreact";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {FormattedMessage, injectIntl} from 'react-intl';
import InputFieldIntl from '../../../components/InputFieldIntl';
import DateField from '../../../components/DateField';
import CheckboxWithValidation from "../../CheckboxWithValidation";
import EnrollmentInfoMessage from "../EnrollmentInfoMessage";
import attachValidator from "../../../utils/validation/attach-validator.js";

import classNames from "classnames";
import {set as lodashSet} from 'lodash';

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import produce from "immer";
import PageError from "../../PageError";
import moment from "moment";
import * as validationActions from "../../../actions/validation-action";
import {
    InputRestrictors as restrict,
    StandardValidations as stdv
} from "../../../utils/validation/standard-validations.js";
import {formatAmount, countryUPSType} from '../../../utils/ups-utils';
import CurrencyFieldIntl from '../../../components/CurrencyFieldIntl';
import { COUNTRY_UPS_CODE } from '../../../constants/country-ups-type';
import { BUSINESS_UNIT } from '../../../constants/business-unit';
import MDBBtnWrapper from '../../MDBFix/MDBBtnWrapper';
import supportLinks from '../../../constants/support-links';
import  path from 'path';
class IndependentAccountInvoiceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
        attachValidator.call(this)
    }

    render() {

        const {focused, invoiceNumber, invoiceAmount, invoiceDate, invoiceCharges, accountNumber, acceptPaymentTerms, postSubmit, invoiceControlId} = this.state;
        const { isModal, account, user, userInfo, intl, merchant, merchants } = this.props;
        const maxDays = countryUPSType(account.countryCode) == "Domestic" ? 45 : 45;
        
        let maxCurrencyDigits = merchant?.maxCurrencyDigits;
        if(!merchant && account){
            maxCurrencyDigits = merchants.find(x => x.countryCode === account.countryCode)?.maxCurrencyDigits;  
        }
        const { supportBaseUrl, termsResource  } = supportLinks;
        const {selectedCountry: countryCode, locale} = userInfo || user || {};
        const selectedCountryCode = countryCode?countryCode.toLowerCase():'';
        const language = locale? locale.split('-')[0].toLowerCase():''; 
        const urlPaymentusTerms = supportBaseUrl + path.join(selectedCountryCode, language, termsResource);
        const isBusinessUnitFRT = (account) => {
            if (account && (account.businessUnit === BUSINESS_UNIT.FRT)) {
                return true;
            } else {
                return false;
            }
        }

        const businessUnitToLowerCase = (account) => {
            if (account && account.businessUnit) {
                return account.businessUnit.toLowerCase();
            }
        }

        const getValidationConditionForBU = (account, value) => {
            if (account && value) {
                switch (account.businessUnit) {
                    case `${BUSINESS_UNIT.FRT}`:
                        return (value.length !== 8);
                    case `${BUSINESS_UNIT.SCS}`:
                        return (value.length !== 10);
                    default:
                        return (value.length < 6 || value.length > 13)
                }
            }
            return false;
        }

        return (
            <React.Fragment>
                <section id="enrollmentInvoiceDetailsSection" className={classNames({"ups-enroll-subsection": true, "mb-4 non-modal": !isModal})}>
                    <MDBCard>
                        {!isModal && <MDBCardHeader tag="h1" className={"p-0 mb-3"}><FormattedMessage
                            id={(isBusinessUnitFRT(account)) ? "statementDetails.title" : "invoiceDetails.title"}/></MDBCardHeader>}
                        <MDBCardBody className={"p-0"}>
                            {postSubmit && <PageError/>}
                            <EnrollmentInfoMessage enrollMode={!isModal} country={isModal ? account.countryCode : countryCode} language={language?.substr(0, 2)} accountType = {account.accountType}/>

                            <p className="ups-note-1">*<span className="font-italic"><FormattedMessage
                                id={"ups.required-field.note"}/></span></p>

                            {/* Order; invoice number, amount due, invoice date, control id(only for US/CA and UPS Package)*/}
                                <InputFieldIntl
                                    key={"invoiceNumber"}
                                    name={"invoiceNumber"}
                                    id={"invoiceNumber"}
                                    label={<FormattedMessage id={(isBusinessUnitFRT(account)) ? "statement.number.account.label" : "invoice.number.account.label"}/>}
                                    value={invoiceNumber}
                                    onChange={this.handleInput}
                                    hint={(isBusinessUnitFRT(account) ? "statement.number.account.hint" : `invoice.number.account.hint.${businessUnitToLowerCase(account)}`)}
                                    maxLength={13}
                                    restrict={stdv.validInvoiceNumber}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['validInvoiceNumber', "field.alphanumeric"],
                                        (value) => (getValidationConditionForBU(account,value) ? `invoiceNumber.maxLength.${businessUnitToLowerCase(account)}`:"")
                                    ]}
                                />

                                <CurrencyFieldIntl
                                    key={"invoiceAmount"}
                                    name={"invoiceAmount"}
                                    id={"invoiceAmount"}
                                    label={<FormattedMessage id={"invoice.amountDue.account.label"}/>}
                                    value={invoiceAmount}
                                    onChange={this.handleInput}
                                    onBlur={this.validateInput}
                                    hint={`invoice.amountDue.account.hint${Number.isNaN(parseInt(maxCurrencyDigits)) ? '': ("." + maxCurrencyDigits)}`}
                                    restrict={restrict.currency}
                                    maxLength={19}
                                    required
                                    validations={[
                                        ['required', "amountDueThisPeriod.required"],
                                        ['currency', "field.currency"]
                                    ]}
                                    maxCurrencyDigits={maxCurrencyDigits}
                                />

                                <DateField
                                    name={"invoiceDate"}
                                    label={<FormattedMessage
                                        id={(isBusinessUnitFRT(account)) ? "statement.date.label" : 'invoice.date.label'}/>}
                                    ariaLabel={intl.formatMessage({id:(isBusinessUnitFRT(account)) ? `statement.date.label` : `invoice.date.label`})}
                                    date={invoiceDate}
                                    onDateChange={date => this.setState({postSubmit: false, invoiceDate: date})}
                                    focused={focused}
                                    onBlur={this.validateInput}
                                    onFocusChange={({focused}) => this.setState({focused})}
                                    range={day =>
                                        (moment().startOf('day').diff(moment(day, "YYYY-MM-DD").startOf('day'), 'days') > maxDays) ||
                                        (moment().startOf('day').diff(moment(day, "YYYY-MM-DD").startOf('day'), 'days') < 0)
                                    }
                                    id="invoiceDate"
                                    restrict={restrict.datemmddyyyy}
                                    required
                                    validations={[
                                        ['required', `invoiceDate.required${maxDays}`],
                                        ['daterange', `field.mmddyyyy.past${maxDays}`, {min: maxDays, max: 0, scale: 'days'}]
                                    ]}
                                />
                                
                            {
                                (countryUPSType(account.countryCode) == COUNTRY_UPS_CODE.DOMESTIC && account.businessUnit == BUSINESS_UNIT.EBS) ?
                                    <>
                                        <InputFieldIntl
                                            key={"invoiceControlId"}
                                            name={"invoiceControlId"}
                                            id={"invoiceControlId"}
                                            label={<FormattedMessage id={"invoice.control.id.label"} />}
                                            value={invoiceControlId}
                                            onChange={this.handleInput}
                                            onBlur={this.validateInput}
                                            maxLength={4}
                                            restrict={stdv.alphanumeric}
                                            hint={"invoice.control.id.hint"}
                                            required
                                            validations={[
                                                ['alphanumeric', "controlId.alphanumeric"],
                                                (value) => ((value && value.length < 4 && value.length != 0) ? "controlId.minmaxlength4":"")
                                            ]}
                                        />
                                        <p className="ups-control-id-note-1"><span className="font-italic"><FormattedMessage id={"ups.control.id.note"} /></span></p>
                                    </>
                                    : null
                            }

                            {isModal &&
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <CheckboxWithValidation
                                        name="acceptPaymentTerms"
                                        id="acceptPaymentTerms"
                                        label={<FormattedMessage id="payment-authorization-terms.authorize.label"/>}
                                        containerClass={"py-0 pl-0 m-0 "}
                                        labelClass="mr-0 font-weight-bold"
                                        value={acceptPaymentTerms || false}
                                        onChange={this.handleInput}
                                        required
                                        validations={[
                                            ['required', "field.authorizePaymentTerms"]
                                        ]}
                                    >
                                        <a role='link'  title={intl.formatMessage({id:"ups.new-tab.link.label"})} href={urlPaymentusTerms} target={"_blank"} className={"text-blue"}><FormattedMessage id={"payment-authorization-terms.label"} /></a>
                                    </CheckboxWithValidation>
                                </MDBCol>
                            </MDBRow>
                            }

                        </MDBCardBody>
                    </MDBCard>
                </section>
                <section className={classNames({"ups-section ups-enroll-subsection ups-btn-section": true, "mb-4 non-modal": !isModal})}>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                            <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.back.label"})} color={"secondary"} id="enroll-invoice-details-btn-back" onClick={this.props.handleRestart}>
                            </MDBBtnWrapper>
                            <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.continue.label"})} color={"primary"} id="enroll-invoice-details-btn-next" onClick={this.validateAccount}>
                            </MDBBtnWrapper>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                        <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.enroll.cancel.label"})} id="enroll-invoice-details-btn-cancel" flat className={classNames({"btn-flat-link": true})}
                                    onClick={this.props.handleCancel}>
                            </MDBBtnWrapper>
                        </MDBCol>
                    </MDBRow>
                </section>
            </React.Fragment>

        );
    }

    handleInput = e => {
        const {name, value, checked, type} = e.target;
        switch (type) {
            case 'text':
                this.setState(
                    produce(draft => {
                        draft.postSubmit = false;
                        lodashSet(draft, name, value);
                    })
                );
                break;
            case 'checkbox':
                this.setState(
                    produce(draft => {
                        draft.postSubmit = false;
                        lodashSet(draft, name, checked);
                    })
                );
                break;
            default: break;
        }
    };

    validateInput = args =>{
        const {name, value} = args;

        this.setState(produce(draft => {
            lodashSet(draft, name, value);
        }));
        
        this.validator.validate(name, value);
    }

    validateAccount = () => {
        this.setState({postSubmit: true});
        let result = this.validator.validateAll()
        if (result.messages.length > 0) return false;
        const {account} = this.props;
        const {invoiceNumber, invoiceAmount, invoiceDate, invoiceControlId} = this.state;
        const data = {
            account,
            invoice: {
                invoiceNumber: this.getInvoiceNumForBU(invoiceNumber, account),
                invoiceAmount,
                invoiceDate: invoiceDate,
                ...(invoiceControlId ? {invoiceControlId}:{})
            }
        };
        this.props.submitAction(data);
    }

    getInvoiceNumForBU = (invoiceNumber, account) => {
        let maxLength = 15;
        if (invoiceNumber && account) {
            if (account.businessUnit === BUSINESS_UNIT.FRT) {
                maxLength = 8;
            } else if (account.businessUnit === BUSINESS_UNIT.SCS) {
                maxLength = 10;
            }
        }
        return invoiceNumber.padStart(maxLength, '0')
    }
}

function mapStateToProps(state) {
    return {
        account: state.account.account,
        user: state.auth.user,
        userInfo: state.auth.userInfo,
        merchant: state.config?.sessionSelection?.merchant,
        merchants: state.config?.merchants,
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IndependentAccountInvoiceDetails));

