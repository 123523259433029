/*
 * Copyright (C) 2020 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 6/18/20, 10:29 AM
 * Created by kxian
 *
 */

import produce from "immer"
import * as actionTypes from '../constants/action-types';

// This reducer is not persisted in redux local storage (by design)
// it starts from scratch on a page refresh with default state

export default (state = {}, action) =>
    produce(state, draft => {
            switch (action.type) {
                case actionTypes.SET_ERRORS:
                    draft.errors = action.data;
                    return;
                case actionTypes.CLEAR_FIELD_ERROR:
                    if(draft.errors){
                        let backendErrorIndex = draft.errors.findIndex?.(e => e.split('.')[0] === action.data.id )
                        if(backendErrorIndex > -1) draft.errors.splice(backendErrorIndex, 1)
                    }
                    return;
                case actionTypes.CLEAR_ERRORS:
                    delete draft.errors;
                    return;
            }
        }
    );