import React, {useReducer, useEffect, useMemo}  from "react";
import { useSelector, useDispatch}              from "react-redux";
import { useLocation, useHistory}               from "react-router-dom";
import { FormattedMessage, useIntl}             from "react-intl";
import { MDBCard, MDBCardBody, MDBCol
       , MDBContainer, MDBRow}                  from "mdbreact";
import * as R                                   from 'ramda';
import moment                                   from "moment";
import {getSortedWalletPMs}                     from '../../api/wallet-api';
import { checkRecentPayments, submitPayment
       , checkPaymentMethodEligibilty }         from '../../api/otp-api';
import NavHeader                                from "./NavHeader";
import InvoiceInformation                       from "./InvoiceInformation";
import PaymentInformation                       from "./PaymentInformation";
import SepaMandate                              from "../../components/UPS/SepaMandate";
import PaymentConfirmation                      from "./PaymentConfirmation";
import PaymentReceipt                           from "./PaymentReceipt";
import RecentPaymentsModal                      from '../../components/UPS/Modal/RecentPaymentsModal';
import ProgressBar                              from "../../components/UPS/ProgressBar";
import Error                                    from '../../components/Error';
import Spinner                                  from '../../components/Spinner';
import NotificationModal                        from "../../components/UPS/Modal/NotificationModal";
import {getKeyByBU, getMessageByBU}             from '../../utils/ups-utils';
import { useValidator}                          from "../../utils/validation";
import { isSepa, invoicesTotalAmount }          from '../../utils/payment-utils';
import {getClientConfig, getMaxInvoices}        from '../../utils/config-utils';
import ThreeDSForm                              from '../../components/UPS/3dSecureForm';
import { payInvoiceReducer
       , getSelectedInvoices
       , payInvoiceInitialState
       , getAllInvoices }                       from './reducer';
import { MAX_DUPLICATE_CHECK_INVOICES }         from "../../constants/invoice-constants";
import {enter3ds, complete3ds}                  from "../../actions/config-action";
import {AgreementStep, AgreementSteps} from "./AgreementStep";
const buildInvoiceSubmission = R.map
  ( R.compose
      ( R.pick([ 'id'
               , 'amount'
               , 'currency'
               , 'partialPayReason'
               , 'partialPayDescription'
               , 'accountNumber'
               , 'planNumber'
               , 'recordType'
               ])
      , inv => ({...inv, amount: +inv.paymentAmount, currency: inv.currencyCode})
      , R.last
      )
  );

const hasCreditInvoices = R.compose
    ( R.any(R.propSatisfies(R.flip(R.lt) (0), 'paymentAmount'))
    , getSelectedInvoices
    );

const buildRecentPaymentInvoices = R.compose
    ( R.map ( R.pick ([ 'id', 'accountNumber', 'planNumber', 'invoiceNumber'
                      , 'planInvoiceNumber', 'amount', 'recordType', 'businessUnit'
                      ]
                     )
            )
    , getSelectedInvoices
    );

const getStepLabels = (getKey) => ({
    invoice: getKey('step1Title'),
    sepa: "sepa-mandate.card-header.title",
    bacs: "make-payment.step.bacs",
    payment: "make-payment.payment-information.card-header.title",
    review: "make-payment.review-payment.card-header.title",
    receipt: "receipt.payment.card-header.title",
})

const getMostRecentDueDate = (invoices) => {
  const invoicesDueDateSorted = [...invoices.entries()].map((invoice) => invoice[1].dueDate).sort();
  const mostRecentInvoiceDueDate = moment(invoicesDueDateSorted[0].split(".")[0]);
  const currDate = moment().startOf("day");
  const differenceInDays = mostRecentInvoiceDueDate.diff(currDate, 'days')
  return differenceInDays
}

export default function UPSPayInvoice() {
    // const [pay3dSecure, setPay3dSecure] = useCookieState('c3dSecure');
    const location = useLocation();
    const forwardedState = location.state;
    const intl = useIntl();
    const rDispatch = useDispatch();
    const history = useHistory();
    const validator = useValidator();
    const { selectedCountry, selectedPaymentType } = useSelector(s => s.auth.user);
    const { sessionSelection } = useSelector(s => s.config);
    const { merchant } = sessionSelection;
    const enableSepaMandate = useMemo(() => getClientConfig(merchant).ENABLE_SEPA_MANDATE, [merchant]);
    const [payInvoiceState, dispatch] = useReducer(payInvoiceReducer, {...payInvoiceInitialState, enableSepaMandate});
    const currentStep = payInvoiceState.steps[payInvoiceState.step];
    const flowConsts = useMemo(() => ({ getMessage: getMessageByBU('paymentFlow')(intl)(selectedPaymentType)
                                      , getKey: getKeyByBU('paymentFlow')(selectedPaymentType)})
                              , [selectedPaymentType, intl]
                              );
    const stepLabels = getStepLabels(flowConsts.getKey);
    const fetchRecentPayments = ({invoices}) => checkRecentPayments
      ({ invoices: buildRecentPaymentInvoices (invoices) });
    const fetchPaymentEligibility = (countryCode, isPlan) => R.compose
      ( checkPaymentMethodEligibilty
      , invoices => ({invoices, countryCode, isPlan})
      , R.map(R.pick(['planNumber', 'accountNumber', 'id', 'outstandingAmount', 'recordType', 'businessUnit']))
      , getAllInvoices
      );
    const dispatchError = (error, blocked = false) => dispatch({type: 'ERROR', error, blocked});
    const referenceId = useSelector(s => s.config.threeDSecureDetails?.referenceId);

    useEffect(() => {
        if (forwardedState.errorCode) {
          dispatchError(forwardedState.errorCode);
        } else if (referenceId && !payInvoiceState.threeDsFlow && forwardedState?.invoices) {
          rDispatch(complete3ds());
          dispatch({ type: '3D_SECURE_COMPLETED', paymentResult: forwardedState });
        } else if (forwardedState && !R.isEmpty(forwardedState.invoices)) {
          const { invoices, isPlan } = forwardedState;
          if (!invoices || !invoices?.size) {
            // if user is redirected to this page via mail, then redirect him to home since there are no invoices available in the state
            history.replace({ pathname: '/home' });
          } else {
            dispatch({type: 'RECEIVE_INVOICES', invoices, isPlan, MAX_INVOICES: getMaxInvoices(merchant)});
          }
        } else {
          // console.log('someone hit refresh?');
          // If we have not arrived from invoice or dashboard or returned from
          // 3ds then someone must have hit the refresh button resulting in all component
          // state lost. In this case we'll take the back home.
          history.replace({ pathname: '/home' });
        }
    }, []);

    useEffect(() => {
      let closed = false;
      if (payInvoiceState.requestRecentPayments) {
        fetchRecentPayments(payInvoiceState)
          .then(recentPayments => {if (!closed) dispatch({type: 'RECEIVE_RECENT_PAYMENTS', recentPayments})})
          .catch(({errorCode}) => {if (!closed) dispatchError(errorCode)});
      }
      return () => closed = true;
    }, [payInvoiceState.requestRecentPayments]);

    useEffect(() => {
      let closed = false;
      if (payInvoiceState.requestCheckPayEligible) {
        fetchPaymentEligibility(selectedCountry, payInvoiceState.isPlan) (payInvoiceState.invoices)
          .then(invoices       => {if (!closed) dispatch({type: 'RECEIVE_VALIDATED_INVOICES', invoices})})
          .catch(({errorCode}) => {if (!closed) dispatchError(errorCode, true)});
      }
      return () => closed = true;
    }, [payInvoiceState.requestCheckPayEligible]);

    useEffect(() => {
      let closed = false;
      const fetchWallets = async () => {
        const wallet = await getSortedWalletPMs(merchant.paymentMethodCategories)({
            intent: payInvoiceState.isRefund ? 'refund' : 'payment'
        });
        if (!closed) dispatch({type: 'RECEIVE_WALLET', wallet});
      }

      if (payInvoiceState.requestWallet) {
        fetchWallets().catch(({errorCode}) => {
          if (!closed) dispatchError(errorCode);
        });
      }
      return () => closed = true;
    }, [payInvoiceState.requestWallet]);

    const onBack = () => dispatch({type: 'STEP_BACK'});

    const onNext = () => {
        const result = validator.validateAll();
        if (result.messages.length > 0) return;
        dispatch({type: 'STEP_FORWARD'});
    };

    const onClose = () => {
      if (payInvoiceState.isPlan) {
        history.push('/ups/billing/plan');
      } else {
        history.push('/ups/billing/invoice');
      }
    }

    const paymentInvoices = new Map([...payInvoiceState.invoices].filter(p => !p[1].deselected));
    const mobileAppPayment = R.includes(payInvoiceState.paymentMethod?.methodType, ['BLIK','SWISH']);

    useEffect (() => {
      if (payInvoiceState.paymentInProgress) {
        submitPayment({
          invoices: buildInvoiceSubmission([...paymentInvoices]),
          ...(payInvoiceState.paymentMethod._id
                  ? { walletId: payInvoiceState.paymentMethod._id }
                  : { paymentMethod: payInvoiceState.paymentMethod }
                  ),
          ...(enableSepaMandate && isSepa(payInvoiceState.paymentMethod)
                  ? {sepaMandate: payInvoiceState.paymentMethod?.sepaMandate}
                  : {}
                  ),
          ...(payInvoiceState.effectiveDate ? {effectiveDate: payInvoiceState.effectiveDate} : {}),
          isPlan: payInvoiceState.isPlan,
          isSavePayMethod: payInvoiceState.isSavePayMethod,
          hasDuplicates: payInvoiceState.duplicatePayments,
          paymentSessionId: payInvoiceState.paymentSessionId,
          ...((payInvoiceState.authorizeDD || payInvoiceState.paymentMethod.authorizeDD) ? {authorizeDD: true}: {})
        }).then(paymentResult => {
          //3D Secure & alt pm flow
          const { hostedCheckoutData, referenceId } = paymentResult;
          if ('PROCESSING' === paymentResult.paymentStatus && hostedCheckoutData && !mobileAppPayment) {
            rDispatch(enter3ds({
              redirectLocation: location.pathname,
              referenceId,
              type: 'payment'
            }));
            if (hostedCheckoutData?.httpmethod === 'GET') {
              // setPay3dSecure({ referenceId, flow: true});
              dispatch({type: 'SET_3DS_FLOW'});
              window.location.replace(hostedCheckoutData.hostedurl);
            } else {
              // setPay3dSecure({referenceId});
              dispatch({ type: 'SET_3D_SECURE_FORM_DATA', formData: hostedCheckoutData });
            }
          } else {
            dispatch({ type: 'RECEIVE_RECEIPT', paymentResult });
          }
        }).catch(({errorCode}) => dispatchError(errorCode));
      }
    }, [payInvoiceState.paymentInProgress]);

    useEffect(() => {
      if (!R.isEmpty(payInvoiceState.threeDSecureFormData)
          && !payInvoiceState.threeDsFlow) {
        // setPay3dSecure({...pay3dSecure, flow: true});
        dispatch({type: 'SET_3DS_FLOW'});
      }
    }, [payInvoiceState.threeDSecureFormData, payInvoiceState.threeDsFlow])

    // scroll to top of page when navigating between steps
    useEffect (() => {
        window.scrollTo(0, 0);
    }, [payInvoiceState.step]);

    return (
      <MDBContainer role="main" fluid>
        {( payInvoiceState.paymentInProgress
        || payInvoiceState.requestCheckPayEligible
        || payInvoiceState.requestRecentPayments
        // || in3dSecureFlow(pay3dSecure)
        || payInvoiceState.threeDsFlow
        || payInvoiceState.requestWallet) &&
          <Spinner isSpinning={!(!R.isEmpty(payInvoiceState.threeDSecureFormData) && mobileAppPayment)} />
        }
        {
          !R.isEmpty(payInvoiceState.threeDSecureFormData) && !mobileAppPayment &&
            <ThreeDSForm httpmethod={payInvoiceState.threeDSecureFormData.httpmethod}
                         hostedurl={payInvoiceState.threeDSecureFormData.hostedurl}
                         pareq={payInvoiceState.threeDSecureFormData.pareq}
                         md={payInvoiceState.threeDSecureFormData.md}
                         termurl={payInvoiceState.threeDSecureFormData.termurl}
                         submit={payInvoiceState.threeDsFlow}
            />
        }

        <MDBRow>
          <MDBCol>
            <MDBCard>
              <MDBCardBody className="ups-section">
                <MDBRow>
                  <MDBCol size="12">
                    <NavHeader section={flowConsts.getKey("pageTitle")} />
                  </MDBCol>
                </MDBRow>

                <MDBRow center className="mt-4 mb-4">
                  <MDBCol size="12">
                    <MDBCard>
                      <ProgressBar
                        stepNames={payInvoiceState.steps.map((step)=>({label: stepLabels[step.id], id: step.id}))}
                        currentStep={payInvoiceState.step}
                        isShown
                        stepId={currentStep.id}
                      />
                    </MDBCard>
                  </MDBCol>
                </MDBRow>

                <h1 className="has-breadcrumb">
                  <FormattedMessage id={stepLabels[currentStep.id]} />
                </h1>

                {payInvoiceState.error.length > 0 && <Error errors={[payInvoiceState.error]} />}

                { payInvoiceState.showRecentPaymentsAlert &&
                <RecentPaymentsModal
                  isOpen={payInvoiceState.showRecentPaymentsAlert}
                  invoices={payInvoiceState.recentPayments}
                  paymentBlocked={payInvoiceState.paymentBlocked}
                  toggleModal={() => dispatch({type: 'CLOSE_RECENT_PAYMENTS'})}
                  acceptRepayment={() => dispatch({type: 'IGNORE_RECENT_PAYMENTS'})}
                  businessUnit={selectedPaymentType}
                  flowConsts={flowConsts}
                  merchant={merchant}
                />
                }

                { payInvoiceState.showRecentPayLimitReached &&
                  <NotificationModal isOpen={true}
                      message={<FormattedMessage id={"invoice.skip-duplicate-check"} values={{limit:MAX_DUPLICATE_CHECK_INVOICES}}/>}
                      title="invoice.skip-duplicate-check.title"
                      callback={()=> dispatch({type: 'IGNORE_RECENT_PAYMENTS'})}
                      closeModal={()=> dispatch({type: 'CLOSE_RECENT_PAYMENTS'})}
                      buttonTypes="OK_CANCEL"
                  />
                }

                <MDBRow>
                  <MDBCol size="12">
                    {currentStep.id === 'invoice' && (
                      <InvoiceInformation
                        key={`invoice-info-${payInvoiceState.step}`}
                        selectedInvoices={payInvoiceState.invoices}
                        onChange={(id, invoice) => dispatch({type: 'SELECT_INVOICE', id, invoice})}
                        onNext={onNext}
                        onCancel={() => {
                          if (payInvoiceState.error !== '' && payInvoiceState.invoices.length > 0) {
                            history.goBack();
                          } else {
                            history.replace('/home', {});
                          }
                        }}
                        creditAllowed={payInvoiceState.creditAllowed}
                        isCanProceed={!payInvoiceState.paymentBlocked}
                        isOnlyCreditNotes={payInvoiceState.isOnlyCreditNotes}
                        flowConsts={flowConsts}
                      />
                    )}
                    {currentStep.id === 'payment' && (
                      <PaymentInformation
                        wallet={payInvoiceState.wallet}
                        paymentMethod={payInvoiceState.paymentMethod}
                        onPMChange={paymentMethod => dispatch({type: 'SET_PAYMENT_METHOD', paymentMethod})}
                        date={payInvoiceState.effectiveDate}
                        onDateChange={effectiveDate => dispatch({type: 'SET_EFFECTIVE_DATE', effectiveDate})}
                        onNext={() =>{dispatch({type: 'GENERATE_PAYMENT_SESSION_ID'}); return onNext() } }
                        onBack={onBack}
                        creditAllowed={payInvoiceState.creditAllowed}
                        isSavePayMethod={payInvoiceState.isSavePayMethod}
                        onSavePM={() => dispatch({ type: 'TOGGLE_SAVE_TO_WALLET'})}
                        onError={dispatchError}
                        isRefund={payInvoiceState.isRefund}
                        hasCredits={hasCreditInvoices (payInvoiceState.invoices)}
                        daysTilDueDate={getMostRecentDueDate(paymentInvoices)}
                        onAuthorizeACH={({target: {checked}}) => dispatch({ type: 'SET_AGREEMENT_PROPERTY', authorized: checked, authorizeProperty:"authorizeDD"})}
                        authorizeDD={payInvoiceState.authorizeDD}
                        totalAmount={invoicesTotalAmount([...payInvoiceState.invoices].map(p => p[1]).filter(inv => !inv.deselected))}
                      />
                    )}
                    {AgreementSteps[currentStep.id] && (
                      <AgreementStep
                          paymentMethod={payInvoiceState.paymentMethod}
                          authorized={payInvoiceState[AgreementSteps[currentStep.id]]}
                          onChange={authorized => dispatch({type: 'SET_AGREEMENT_PROPERTY', authorized, authorizeProperty:AgreementSteps[currentStep.id]})}
                          authorizeProperty={AgreementSteps[currentStep.id]}
                          onError={dispatchError}
                          onNext={onNext}
                          onBack={onBack}
                      />
                    )}
                    {currentStep.id === 'sepa' && (
                      <SepaMandate
                        payment={payInvoiceState.paymentMethod}
                        onChange={authorizeSepa => dispatch({type: 'SET_AUTH_SEPA', authorizeSepa})}
                        onError={dispatchError}
                        onSepaMandateLoad={sepaMandate => dispatch({type: 'SET_SEPA_MANDATE_INFO', sepaMandate})}
                        onNext={onNext}
                        onBack={onBack}
                      />
                    )}
                    {currentStep.id === 'review' && (
                      <PaymentConfirmation
                      caption= {stepLabels[currentStep.id]}
                        selectedInvoices={paymentInvoices}
                        paymentMethod={payInvoiceState.paymentMethod}
                        date={payInvoiceState.effectiveDate}
                        onSubmit={() => dispatch({type: 'SUBMIT_PAYMENT'})}
                        onBack={payInvoiceState.paymentSubmitted ? onClose : onBack}
                        isRefund={payInvoiceState.isRefund}
                        paymentSubmitted={payInvoiceState.paymentSubmitted}
                        currencyDisplay={merchant.currencyDisplay}
                      />
                    )}
                    {currentStep.id === 'receipt' && (
                      <PaymentReceipt
                        receipt={payInvoiceState.paymentReceipt}
                        invoices={paymentInvoices}
                        onSubmit={() => window.print()}
                        onBack={onClose}
                        isRefund={payInvoiceState.isRefund}
                        currencyDisplay={merchant.currencyDisplay}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
}
