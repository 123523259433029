import React, {Component} from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from "mdbreact";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {FormattedMessage, injectIntl} from 'react-intl';
import SelectFieldIntl from '../../../components/SelectFieldIntl';
import InputFieldIntl from '../../../components/InputFieldIntl';
import RadioGroup from '../../../components/RadioGroup';
import PageError from '../../../components/PageError';
import EnrollmentInfoMessage from "../EnrollmentInfoMessage";
import attachValidator from "../../../utils/validation/attach-validator.js";

import classNames from "classnames";
import {set as lodashSet} from 'lodash';
import produce from "immer";
import * as validationActions from "../../../actions/validation-action";
import {StandardValidations as stdv} from "../../../utils/validation/standard-validations.js";
import {accountNumberMaxLength, stripAccountNumber} from "../../../utils/ups-utils.js";
import {TYPE_ACCOUNT_TYPE_ACCOUNT, TYPE_ACCOUNT_TYPE_PLAN} from "../../../constants/paymentus-resources";
import {countryData} from "../../../constants/country-data";
import * as settingActions from "../../../actions/setting-action";
import * as identityActions from "../../../actions/identity-action";
import {updateTealiumParams, loadTealiumScripts} from '../../../utils/tealium-utils';
import { BUSINESS_UNIT } from "../../../constants/business-unit";
import { sortCountryList, generateCountryList } from "../../../utils/utils.js";
import { MDBPopoverWrapper } from "../../MDBFix/MDBPopoverWrapper";
import {isFreightActive} from "../../../utils/config-utils";

const ACCOUNT_TYPES = [TYPE_ACCOUNT_TYPE_ACCOUNT, TYPE_ACCOUNT_TYPE_PLAN];

/**
 * This component captures account number, country, account type (account/plan) and validates if the account number is valid and eligible for billing center.
 */
class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        const {countries, accountTypeOverride, account, userInfo, user, isModal} = this.props;

        // automatically select the country in user's profile or the first one in the list
        let countryCode = isModal ? user?.selectedCountry: userInfo?.selectedCountry;
        if (!countryCode || !countries.includes(countryCode)) {
            countryCode = countries[0];
        }
        this.state.countryCode = countryCode;
        const {merchants} = this.props;
        const merchant = merchants.find(m => m.countryCode === countryCode);

        const {paymentTypes, currencyCode} = merchant;
        // automatically select business unit if there is only one for this country
        if (paymentTypes.length > 0) {
            const businessUnit = paymentTypes[0].name;
            this.state.businessUnit = businessUnit;
        }
        this.state.currencyCode = currencyCode;

        let accountTypes = ACCOUNT_TYPES;
        if (accountTypeOverride) {
            accountTypes = [accountTypeOverride];
            this.state.accountType = accountTypeOverride;
        } else {
            this.state.accountType = TYPE_ACCOUNT_TYPE_ACCOUNT;
        }

        // autofill form if provided
        if (account) Object.assign(this.state, {
            ...account,
            formattedAccountNumber: account.accountNumber,
            accountNumber: stripAccountNumber(account.accountNumber, account.businessUnit)
        });

        if(!isModal && userInfo){
            /* load tealium scripts on enrollment page after setting the country and locale */
            updateTealiumParams({country: countryCode, language : userInfo.locale.split("-")[0]})
            loadTealiumScripts()
        }

        this.state.languageCode =userInfo?.locale;
        attachValidator.call(this)
        //TODO if there are multiple countries, default to the one which is preferred by the user (from UPS userInfo api)
    }

    render() {
        const {merchants, countries, isModal, user, userInfo, intl} = this.props;
        const {countryCode, businessUnit, accountType, accountNumber, postSubmit, languageCode} = this.state;
        const merchant = merchants.find(m => m.countryCode === countryCode);
        let {paymentTypes} = merchant;
        let filteredPaymentTypes;
        if(isModal && accountType === TYPE_ACCOUNT_TYPE_PLAN){
            filteredPaymentTypes = paymentTypes.filter((value)=>(value.name === BUSINESS_UNIT.EBS));
        }
        if(Array.isArray(filteredPaymentTypes) && filteredPaymentTypes.length !== 0){
            paymentTypes = filteredPaymentTypes;
        }
        if(!isFreightActive(merchant)) {
            // Entirely disable enrollment of FRT business unit if turned off via config
            paymentTypes = paymentTypes.filter((value)=>(value.name !== BUSINESS_UNIT.FRT))
        }

        let sortedCountriesList = sortCountryList(generateCountryList(countries, countryData, intl));

        if (businessUnit !== BUSINESS_UNIT.EBS && accountType === TYPE_ACCOUNT_TYPE_PLAN) {
            this.setState({
                postSubmit: false,
                accountType: TYPE_ACCOUNT_TYPE_ACCOUNT
            })
        }

        const selectedCountryCode = countryData.find(c => c.code === countryCode);

        const languagesList = selectedCountryCode ? selectedCountryCode.languages.map(language => {
            /* useCountryLangCodeForDisplay to use full language code instead of just lang, this is the case for Taiwan-Mandarin zh-TW where
               on splitting this code would pick lang code for zh which is Chinese but for Taiwan it needs to be Mandarin */
            return {"value": language.code, "langId": language.id, "msgId": `language-item.${language.useCountryLangCodeForDisplay ? language.code : language.code.split("-")[0]}`};
        }) : [];

        const {selectedCountry: country, locale: language} = userInfo || user || {};

        const accountMaxLength = accountNumberMaxLength(businessUnit);
        const inputFieldType = (accountType === TYPE_ACCOUNT_TYPE_PLAN) ? "planNumber" : "accountNumber";

        return (
            <React.Fragment>

                <section id="enrollmentAccountDetailsSection"
                         className={classNames({"ups-enroll-subsection": true, "mb-4 non-modal": !isModal})}>
                    <MDBCard>
                        {!isModal && <MDBCardHeader tag="h1" className={"p-0 mb-3"}><FormattedMessage
                            id="enroll.accountDetail.title"/></MDBCardHeader>}
                        <MDBCardBody className={"p-0"}>
                            {postSubmit && <PageError/>}
                            <EnrollmentInfoMessage enrollMode={!isModal} country={!isModal ? country : this.state.countryCode ? this.state.countryCode : country}
                                                   language={language?.substr(0, 2)} accountType={accountType}/>

                            <p className="ups-note-1">*<span className="font-italic"><FormattedMessage
                                id={"ups.required-field.note"}/></span></p>

                            {/*
                            country selection
                            */}
                            {countries.length > 1 ?
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"} className={"mt-3 mt-sm-0"}>
                                            <SelectFieldIntl
                                                key={"countryCode"}
                                                name={"countryCode"}
                                                id={"countryCode"}
                                                value={this.state.countryCode}
                                                selectOptions={sortedCountriesList}
                                                label={<FormattedMessage id={"country.label"}/>}
                                                setLabelBefore={true}
                                                onChange={this.countryHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    {!isModal ? <MDBRow>
                                        <MDBCol size={"12"} className={"mt-3 mt-sm-0"}>
                                            <SelectFieldIntl
                                                key={"languageCode"}
                                                name={"languageCode"}
                                                id={"languageCode"}
                                                value={this.state.languageCode}
                                                selectOptions={languagesList}
                                                label={<FormattedMessage id={"language.label"}/>}
                                                setLabelBefore={true}
                                                onChange={this.languageHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow> : null }
                                </React.Fragment> : null
                            }

                            {/*business unit*/}
                            {paymentTypes.length > 1 ?
                                <fieldset>                                   
                                        <legend className="d-contents"><FormattedMessage id="businessUnit.label" />&nbsp;&nbsp;</legend>
                                            <MDBPopoverWrapper
                                                buttonLabel={`${intl.formatMessage({ id: "businessUnit.label" })} ${intl.formatMessage({ id: "ups.btn.help.label" })}`}
                                                content={<> <FormattedMessage
                                                    id={"businessUnit.hint.line1"} />:
                                                    <ul>
                                                        <li><FormattedMessage
                                                            id={"businessUnit.hint.line2"} /></li>
                                                        <li><FormattedMessage
                                                            id={"businessUnit.hint.line3"} /></li>
                                                        <li><FormattedMessage
                                                            id={"businessUnit.hint.line4"} /></li>
                                                    </ul>
                                                </>}
                                            />
                                    {paymentTypes.map(pt => {
                                        return (
                                            <MDBCol md={"12"} sm={"12"} key={pt.name}>
                                                <InputFieldIntl
                                                    label={<FormattedMessage id={"business-unit." + pt.name}
                                                                            values={{
                                                                                sup: msg => (
                                                                                    <sup>{msg}</sup>
                                                                                )
                                                                            }}
                                                    />}
                                                    type="radio"
                                                    name="businessUnit"
                                                    id={"businessUnit_" + pt.name}
                                                    labelClass={"mr-0"}
                                                    value={pt.name}
                                                    checked={pt.name === this.state.businessUnit}
                                                    onChange={this.businessUnitHandler}
                                                />
                                            </MDBCol>
                                        );
                                    })}
                                </fieldset> : null}

                            {/* account type */}
                            {!isModal && businessUnit === BUSINESS_UNIT.EBS ?
                                <fieldset>
                                    {isModal ? null : <legend><FormattedMessage id={`ups.accountType.title`} /></legend>}
                                    <RadioGroup
                                        name={"accountType"}
                                        radioProps={
                                            ACCOUNT_TYPES.map(
                                                type => ({
                                                    id: "accountType_" + type,
                                                    label: <FormattedMessage id={"accountType." + type}/>,
                                                    name: "accountType",
                                                    checkedValue: type,
                                                    labelClass: "mr-0"
                                                })
                                            )
                                        }
                                        value={accountType}
                                        onChange={this.handleInput}
                                    />

                                </fieldset> : null}

                            {/* account number */}
                            <InputFieldIntl
                                key={inputFieldType + accountMaxLength}
                                name={inputFieldType}
                                id={inputFieldType}
                                label={<FormattedMessage
                                    id={`accountNumber.${accountType}.${businessUnit}.label`}/>}
                                value={accountNumber}
                                onChange={this.accountNumberHandler}
                                maxLength={accountMaxLength}
                                hint={`accountNumber.${accountType}.${businessUnit}.hint`}
                                restrict={stdv.alphanumeric}
                                required
                                validations={[
                                    ['required', "field.minmaxlength" + accountMaxLength],
                                    ['alphanumeric', "field.alphanumeric"],
                                    ['minmaxlength', "field.minmaxlength" + accountMaxLength, {
                                        min: accountMaxLength,
                                        max: accountMaxLength
                                    }]
                                ]}
                            />
                            <p><strong><FormattedMessage id={"note.label"}/>:</strong>&nbsp;
                                {isModal & (accountType === TYPE_ACCOUNT_TYPE_PLAN)
                                    ? <FormattedMessage id="add-plan.line2"/>
                                    : <FormattedMessage id="enroll.line2"/>
                                }</p>
                        </MDBCardBody>

                    </MDBCard>
                </section>

                <section
                    className={classNames({
                        "ups-section ups-enroll-subsection ups-btn-section": true,
                        "mb-4 non-modal": !isModal
                    })}>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                            <MDBBtn color={"primary"} id="enroll-account-details-btn-next"
                                    onClick={this.validateAccountEligibility}>
                                <FormattedMessage id={"ups.btn.continue.label"}/>
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                            <MDBBtn flat id="enroll-account-details-btn-cancel"
                                    className={classNames({"btn-flat-link": true})}
                                    onClick={this.props.handleCancel}>
                                <FormattedMessage id={"ups.btn.enroll.cancel.label"}/>
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </section>
            </React.Fragment>
        );
    }

    validateAccountEligibility = () => {
        this.setState({postSubmit: true});
        let result = this.validator.validateAll();
        if (result.messages.length > 0) return false;
        const {countryCode, businessUnit, formattedAccountNumber, accountType, currencyCode} = this.state;
        const data = {
            account: {
                countryCode, businessUnit, accountType, currencyCode,
                ...(accountType === 'PLAN' ? {planNumber: formattedAccountNumber} : {accountNumber: formattedAccountNumber})
            }
        };
        this.props.submitAction(data);
    };

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState(
            produce(draft => {
                draft.postSubmit = false;
                lodashSet(draft, inputName, inputValue);
            })
        );
    };

    accountNumberHandler = (event) => {
        const accountNumber = event.target.value;
        let formattedAccountNumber = accountNumber.toUpperCase();
        const {businessUnit} = this.state;
        if (accountNumberMaxLength(businessUnit) === 6) {
            formattedAccountNumber = accountNumber.toUpperCase().padStart(10, '0');
        }
        this.setState({
            postSubmit: false,
            accountNumber,
            formattedAccountNumber
        });
    };

    countryHandler = (event) => {
        const countryCode = event.target.value;
        this.selectCountry(countryCode);
    };

    languageHandler = (event) => {
        const { settingActions, identityActions } = this.props;
        const { countryCode } = this.state;
        const langCode = event.target.value;

        const selectedCountryCode = countryData.find(c => c.code === countryCode);
        const selectedLang = selectedCountryCode ? selectedCountryCode.languages.find(x => x.code === langCode) : '';

        if (selectedLang) {
            settingActions.setCountryLocale(selectedLang.id);
            settingActions.setLocale(langCode);
            identityActions.setSelectedCountry({country : countryCode, locale : selectedLang.code});
        }

        this.setState({languageCode: langCode});
    };

    selectCountry = (countryCode) => {
        this.setState({postSubmit: false, countryCode});
        const {merchants, settingActions, isModal, identityActions} = this.props;
        const merchant = merchants.find(m => m.countryCode === countryCode);
        const {paymentTypes, currencyCode} = merchant;
        // automatically select business unit if there is only one for this country
        if (paymentTypes.length === 1) {
            const businessUnit = paymentTypes[0].name;
            this.selectBusinessUnit(businessUnit);
        }

        if (!isModal) {
            const countryInfo = countryData.find(x => x.code === countryCode);
            if (countryInfo) {
                settingActions.setCountryLocale(countryInfo.languages[0].id);
                settingActions.setLocale(countryInfo.languages[0].code);
                identityActions.setSelectedCountry({country : countryCode, locale : countryInfo.languages[0].code});
                this.setState({languageCode: countryInfo.languages[0].code});
            }
        }
        this.setState({postSubmit: false, currencyCode: currencyCode});
    };

    businessUnitHandler = (event) => {
        const businessUnit = event.target.value;
        this.selectBusinessUnit(businessUnit);
    };

    selectBusinessUnit = (businessUnit) => {
        this.setState({postSubmit: false, businessUnit});
    };
}

function mapStateToProps(state) {
    return {
        account: state.account.account,
        merchants: state.config.merchants,
        countries: state.config.countries,
        user: state.auth.user,
        userInfo: state.auth.userInfo,
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //required for attaching validator
        settingActions: bindActionCreators(settingActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch),
        identityActions : bindActionCreators(identityActions, dispatch)
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountDetails));