import React from "react";
import {FormattedMessage} from "react-intl";
import {MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBCardBody, MDBBtn} from "mdbreact";

const DashboardDueSoonInvoicesTile = ({dueSoonSummary, goToAccountInvoices, goToPlanInvoices, businessUnit}) => {
    const { dueTodayPlans, dueTodayAccounts, plansIn7days, accountsIn7days, plansIn30days, accountsIn30days } = dueSoonSummary || {};
    const planCountTitle1DueToday = 'dashboard.past-due.title1-' + (dueTodayPlans===1 ? 'single' : 'multiple');
    const planCountTitle1DueIn7Days = 'dashboard.past-due.title1-' + (plansIn7days===1 ? 'single' : 'multiple');
    const planCountTitle1DueIn30Days = 'dashboard.past-due.title1-' + (plansIn30days===1 ? 'single' : 'multiple');
    const accountCountTitleDueToday = 'dashboard.past-due.title3-' + (dueTodayAccounts===1 ? 'single' : 'multiple') + `.${businessUnit}`;
    const accountCountTitle1DueIn7Days = 'dashboard.past-due.title3-' + (accountsIn7days===1 ? 'single' : 'multiple') + `.${businessUnit}`;
    const accountCountTitle1DueIn30Days = 'dashboard.past-due.title3-' + (accountsIn30days===1 ? 'single' : 'multiple') + `.${businessUnit}`;
    return (
                <MDBCard className={"dashboard-widget-card invoice-due-soon-card h-100"}>
                    <MDBCardTitle tag="h2">
                        <span className="material-icons align-top mr-2">today</span>
                        <FormattedMessage id={`dashboard.invoices-due-notice.title.${businessUnit}`}/>
                    </MDBCardTitle>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <div className="invoices-due-soon">
                                    <ul className="custom-timeline">
                                        <li>
                                            <span className="timeline-date">
                                                <FormattedMessage id="dashboard.invoices.title1"/>
                                            </span>
                                            <span className="timeline-desc">
                                                { !!dueTodayPlans && <div><a role='button' tabIndex={0} className="dashboard-btn-text-link dashboard-btn-minimal" onClick={() => goToPlanInvoices({invoiceDueOption: "TODAY"},true)}>{dueTodayPlans} <FormattedMessage id={planCountTitle1DueToday}/></a></div>}
                                                { !!dueTodayAccounts && <div className={`${dueTodayPlans ? 'link-spacing': ''}`}><a role='button' tabIndex={0} className="dashboard-btn-text-link dashboard-btn-minimal" onClick={() => goToAccountInvoices({invoiceDueOption: "TODAY"},true)}>{dueTodayAccounts} <FormattedMessage id={accountCountTitleDueToday}/></a></div>}
                                                { (!dueTodayPlans && !dueTodayAccounts) && <FormattedMessage id={`dashboard.no-invoices.title.${businessUnit}`}/>}
                                            </span>
                                        </li>

                                        <li>
                                            <span className="timeline-date">
                                                <FormattedMessage id="dashboard.invoices.title2"/>
                                            </span>
                                            <span className="timeline-desc">
                                                { !!plansIn7days && <div><a role='button' tabIndex={0} className="dashboard-btn-text-link dashboard-btn-minimal" onClick={() => goToPlanInvoices({invoiceDueOption: "NEXT_7_DAYS"},true)}>{plansIn7days} <FormattedMessage id={planCountTitle1DueIn7Days}/></a></div>}
                                                { !!accountsIn7days && <div className={`${plansIn7days ? 'link-spacing': ''}`}><a role='button' tabIndex={0} className="dashboard-btn-text-link dashboard-btn-minimal" onClick={() => goToAccountInvoices({invoiceDueOption: "NEXT_7_DAYS"},true)}>{accountsIn7days} <FormattedMessage id={accountCountTitle1DueIn7Days}/></a></div>}
                                                { (!plansIn7days && !accountsIn7days) && <FormattedMessage id={`dashboard.no-invoices.title.${businessUnit}`}/>}
                                            </span>
                                        </li>

                                        <li>
                                            <span className="timeline-date">
                                                <FormattedMessage id="dashboard.invoices.title3"/>
                                            </span>
                                            <span className="timeline-desc">
                                                { !!plansIn30days && <div><a role='button' tabIndex={0} className="dashboard-btn-text-link dashboard-btn-minimal" onClick={() => goToPlanInvoices({invoiceDueOption: "NEXT_30_DAYS"},true)}>{plansIn30days} <FormattedMessage id={planCountTitle1DueIn30Days}/></a></div>}
                                                { !!accountsIn30days && <div className={`${plansIn30days ? 'link-spacing': ''}`}><a role='button' tabIndex={0} className="dashboard-btn-text-link dashboard-btn-minimal" onClick={() => goToAccountInvoices({invoiceDueOption: "NEXT_30_DAYS"},true)}>{accountsIn30days} <FormattedMessage id={accountCountTitle1DueIn30Days}/></a></div>}
                                                { (!plansIn30days && !accountsIn30days) && <FormattedMessage id={`dashboard.no-invoices.title.${businessUnit}`}/>}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                    <div className={"card-footer invoice-due-soon-footer"}>
                            { (!!dueTodayPlans || !!plansIn7days || !!plansIn30days) &&
                                <MDBBtn color={"text-link"} className={"m-0 btn-view-plan-invoice"}
                                    onClick={() => goToPlanInvoices()} id="btn-view-plan-invoices"
                                ><FormattedMessage id="dashboard.invoices.btn.plan-invoices"/></MDBBtn>
                            }
                            <div className="minimal-spacing"/>
                            { (!!dueTodayAccounts || !!accountsIn7days || !!accountsIn30days) &&                                 
                                <MDBBtn color={"text-link"} className={"m-0 btn-view-account-invoice"}
                                    onClick={() => goToAccountInvoices()} id="btn-view-account-invoices"
                                ><FormattedMessage id={`dashboard.invoices.btn.account-invoices.${businessUnit}`}/></MDBBtn>
                            }
                    </div>
                </MDBCard>
    );
};

export default DashboardDueSoonInvoicesTile;
