/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {all, call, fork, put, takeEvery, delay, select  } from 'redux-saga/effects';
import * as paymentApi from '../api/payment-api';
import * as paymentActions from '../actions/payment-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';

// const getApiUrlPrefix = (state) => state.envProps.apiUrlPrefix;
// const getApiToken = (state) => state.apiToken;
const getEnvProps = (state) => state.envProps;

function* getPaymentListByIdentityId(action) {
    try {
        yield put(layoutActions.showSpinner());
        // const apiUrlPrefix = yield select(getApiUrlPrefix);
        // const apiToken = yield select(getApiToken);
        const apiUrlPrefix = null;
        const apiToken = null;
        const { identity, callBack } = action;
        const response = yield call(paymentApi.getPaymentListByIdentityId, identity, apiUrlPrefix, apiToken);
        // const {parsedBody} = response;

        // examples of error response bodies
        //{error: "400 - {"id":{"location":"params","param":"id","value":"undefined","msg":"ID is invalid"}}"}
        //{error: "400 - {"id":{"location":"params","param":"id","value":"5d97bc71ac243cb3a","msg":"ID is invalid"}}"}


        if (response) {
            yield put(paymentActions.getPaymentListByIdentityIdSuccess(response));
            if (callBack && callBack.history) {
                yield call(callBack.history.push({
                    pathname: callBack.forwardUrl, // (string) The path of the URL
                    // search: '', search - (string) The URL query string e.g. '?query=abc',
                    state: { backUrl: callBack.backUrl }
                }));
            } else if (callBack && callBack.toggleModal) {
                yield call(callBack.toggleModal(callBack.modalName));
            }
        } else {
            // yield put(paymentActions.getIdentityFailure());
        }

    } catch (error) {
        // if (error.status === 403) {
        //     yield put(layoutActions.invalidSession());
        // } else {
        //     yield put(paymentActions.getMerchantFailure());
        // }
    } finally {
        yield delay(1250); // demo purposes as there is no load time when loading the demo json local file
        yield put(layoutActions.hideSpinner());

    }
}

function* cancelPayment(action) {
    const { data, callback } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);

        const { apiUrlPrefix, apiToken, simulateApi } = envProps;

        
        
        const response = yield call(paymentApi.cancelPaymentApi, data, apiUrlPrefix, apiToken);
        const {ok, parsedBody} = response;
        if (ok && parsedBody) {
            yield put(paymentActions.cancelPaymentSuccess(response));
            if (callback) {
                yield call(callback, parsedBody);
            }
        }
        
    } catch (error) {
        yield put(paymentActions.cancelPaymentFailure())
        if (callback) {
            yield call(callback, error);
        }
    }
    finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getPaymentListByIdentityIdSaga() {
    yield takeEvery(actionTypes.GET_PAYMENT_LIST_BY_IDENTITY_ID, getPaymentListByIdentityId);
}

export function* cancelPaymentSaga() {
    yield takeEvery(actionTypes.CANCEL_PAYMENT, cancelPayment);
}

export default function* paymentSaga() {
    yield all([
        fork(getPaymentListByIdentityIdSaga),
        fork(cancelPaymentSaga)
    ]);
}