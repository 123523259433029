import React, { Component } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from "mdbreact";
import { FormattedMessage,injectIntl } from "react-intl";
import ReactPortalModal from "../../../ReactPortalModal";
class InvoiceErrorModal extends Component {
  render() {
    const { isOpen, toggleModal, intl } = this.props;
    const modalHeading = "invoice.common-modal.no-invoice-title";
    if (isOpen) {
      return (
        <ReactPortalModal isOpen={isOpen} an_label="invoice-error">
          <MDBModal
            id="view-account-payment-history-modal"
            isOpen={isOpen}
            toggle={() => toggleModal("showInvoiceError")}
            size={"lg"}
            backdrop={() => toggleModal("showInvoiceError")}
            disableBackdrop={true}
            disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}
          >
            <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal("showInvoiceError")}>
              <FormattedMessage id={modalHeading} />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol size={"12"}>
                  <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id:"invoice.common-modal.no-invoice-body"})}} />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBRow>
                <MDBCol size="12" className="modal-button-col">
                  <MDBBtn color="primary" onClick={() => toggleModal("showInvoiceError")}>
                    <FormattedMessage id="btn-close" />
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBModalFooter>
          </MDBModal>
        </ReactPortalModal>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InvoiceErrorModal);
