import React, {useMemo} from "react";
import {
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";

import {displayScheduleFrequency, displayWalletSelection} from "../../utils/payment-utils";
import {stripAccountNumber, getPlanAccNumLabel, getPlanAccNameLabel} from "../../utils/ups-utils";
import ColumnSettingsModal from "../../components/UPS/Modal/ColumnSettingsModal";
import {useSelector} from "react-redux";
import {FilterFuncMultiSelect} from "../../components/UPS/Modal/FilterModal/FilterFuncs";
import FilteredDataTableV3 from "../../components/DataTableV3/DataTableV3FilterWrapper";
import ExportDataModal from "../../components/UPS/Modal/ExportDataModal";
import {defaultButtonProps, exportData} from "../../components/DataTableV3/DataTableV3-utils";
import {isFreightActive} from "../../utils/config-utils";

function defaultCustomizableColumns(intl, businessUnit) {
    return {
        referenceId: {
            field: 'referenceId',
            label: intl.formatMessage({ id: 'modal.view-schedule.confirmation-number'}),
        },
        planAccountDetail: {
            field: 'planAccountDetail',
            label: getPlanAccNumLabel(intl)(businessUnit),
            display: (data) => <AccountCell account={data.account} intl={intl}/>,
            serialize: ({account})=>{
                const {planText, accountText} = getAccountText(account, intl)
                return `${planText} ${accountText}`
            },
            sortFunc: ({account})=>{
                const {planText, accountText} = getAccountText(account, intl)
                return `${planText} ${accountText}`
            },
        },
        accountName: {
            field: 'accountName',
            label: getPlanAccNameLabel(intl)(businessUnit),
            display: (data) =>  data.account.accountName,
            mobileCardPrimary: true,
            serialize: ({account}) => {
                return account.accountName
            },
            mobileDisplay: ({accountName, account})=> {
                const {accountNumber, paymentType} = account;
                return (accountName || stripAccountNumber(accountNumber, paymentType))
            }
        },
        frequency: {
            field: 'frequency',
            label: intl.formatMessage({ id: 'modal.view-schedule.frequency'}),
            display: (schedule)=>displayScheduleFrequency(intl)(schedule),
            serialize: (schedule)=>displayScheduleFrequency(intl)(schedule),
            sortFunc: (schedule)=>displayScheduleFrequency(intl)(schedule)
        },
        paymentMethod: {
            field: 'paymentMethod',
            label: intl.formatMessage({ id: 'modal.view-schedule.payment-method'}),
            display: ({paymentMethod})=>displayWalletSelection(intl)(paymentMethod),
            serialize: ({paymentMethod})=>displayWalletSelection(intl)(paymentMethod),
            sortFunc: ({paymentMethod})=>displayWalletSelection(intl)(paymentMethod)
        },
    }
}

const getActionColumn = (intl, handleAction, permissions) => {
    return {
        field: 'actions',
        label: intl.formatMessage({ id: 'modal.view-schedule.actions' }),
        className: 'no-export',
        sortable: false,
        display: (data) => <ScheduleActions
            schedule={data}
            handleAction={handleAction}
            permissions={permissions}
            intl={intl}
        />
    }
}

const getDataTableColumns = (intl, handleAction, permissions, businessUnit, savedColumns) => {
    const defaultColumns = defaultCustomizableColumns(intl, businessUnit)
    const customizableCols = savedColumns ? savedColumns.map(name=>defaultColumns[name]): Object.values(defaultColumns)
    return [
        ...customizableCols,
        getActionColumn(intl, handleAction, permissions)
    ];
}

function ScheduleActions({schedule, handleAction, permissions, intl}) {
    const {planText, accountText} = getAccountText(schedule.account, intl);
    const actionLabel = intl.formatMessage({id: `more-actions.autoPay-label`}, {accountNumber: (accountText ? accountText : planText)});

    return <MDBDropdown>
        <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" aria-label={actionLabel}>
            <i className="fas fa-ellipsis-v"></i>
        </MDBDropdownToggle>

        <MDBDropdownMenu basic right>
            <MDBDropdownItem className="text-left" onClick={
                () => handleAction("viewSchedule", schedule)
            }>
                {intl.formatMessage({id: "ups.btn.view.label"})}
            </MDBDropdownItem>

            {permissions && permissions["otp_payment"] && (
             <React.Fragment>
                <MDBDropdownItem className="text-left" onClick={
                    () => handleAction("editSchedule", schedule)
                }>
                    {intl.formatMessage({id:"ups.btn.edit.label"})}
                </MDBDropdownItem>

                <MDBDropdownItem divider/>

                <MDBDropdownItem className="text-left" onClick={
                    () => handleAction("cancelSchedule", schedule)
                }>
                    {intl.formatMessage({id: "ups.btn.cancel.label"})}
                </MDBDropdownItem>
            </React.Fragment>
            )}
        </MDBDropdownMenu>
    </MDBDropdown>;
}

function getAccountText({accountNumber, isParent, parentAccount, paymentType}, intl){
    const plan = isParent ? accountNumber : parentAccount?.accountNumber;
    const account = isParent ? null : accountNumber;
    return {
        planText: plan
            ? intl.formatMessage(
                {id:"modal.view-schedule.plan.labeled"},
                {plan: stripAccountNumber(plan, paymentType)}
            )
            : '',
        accountText: account
            ? intl.formatMessage(
                {id:"modal.view-schedule.account.labeled"},
                {account: stripAccountNumber(account, paymentType)}
            )
            : ''
    }
}

function AccountCell({account, intl}) {
    const {planText, accountText} = getAccountText(account, intl)
    return <div>
        {planText && <div>{planText}</div>}
        {accountText && <div>{accountText}</div>}
    </div>;
}

const filterFunc = (filter, intl, columns) => (data) => {
    let shouldShow = true;
    shouldShow &= FilterFuncMultiSelect(filter, 'paymentMethod', data, null, null, null, ({paymentMethod})=>paymentMethod._id)
    shouldShow &= FilterFuncMultiSelect(filter, 'frequency', data, columns, intl, (f)=>displayScheduleFrequency(intl)(JSON.parse(f)))
    return shouldShow
}

export default function ScheduleTable({source, schedules, handleAction, columnSettingsOpen, exportModalOpen, tableState, tableStateAction, caption}) {
    const intl = useIntl()
    const {filter, filteredData} = tableState;
    const {scheduleColumnSettings} = useSelector(s => s.identity?.preferences ?? {});
    const {merchant} = useSelector(s => s.config?.sessionSelection ?? {})
    const {permissions, selectedPaymentType: businessUnit} = useSelector(s => s.auth.user ?? {});
    const buttonProps = defaultButtonProps(intl)

    const columns = useMemo(()=>{
        return getDataTableColumns(intl, handleAction, permissions, businessUnit, scheduleColumnSettings?.savedColumns)
    }, [intl.locale, scheduleColumnSettings?.savedColumns, businessUnit])

    return <React.Fragment>
        <FilteredDataTableV3
        caption={caption}
            name={source}
            data={schedules}
            columns={columns}
            className="table-card-mobile allow-default-wrap"
            wrapperClassName='table-newline-pagination'
            buttons={[
                {text: <FormattedMessage id="schedule.btn.create-schedule"/>, hide: (!permissions.otp_payment || (businessUnit === 'FRT' && !isFreightActive(merchant))), action:()=>handleAction('addSchedule')},
                {type: "filter", action:()=>handleAction('filterModal')},
                {...buttonProps.columnSettings, action:()=>handleAction('scheduleColumnSettings')},
                {type: "print"},
                {...buttonProps.export, action:()=>handleAction('exportData')},
            ]}
            defaultSorting={'planAccountDetail'}
            tableState={tableState}
            tableStateAction={tableStateAction}
            filterFunc={filterFunc(filter, intl, columns)}
            searchable
            mobileCard
            printable
            rowHeaderField={'planAccountDetail'}
        />
        <ColumnSettingsModal
            noHiding
            isOpen={columnSettingsOpen}
            columns={() => defaultCustomizableColumns(intl, businessUnit)}
            toggleModal={handleAction}
            savedPreferenceType={'scheduleColumnSettings'}
            modalName={'scheduleColumnSettings'}
        />
        <ExportDataModal
            modalId="exportData"
            isOpen={exportModalOpen}
            toggleModal={handleAction}
            handleExport={(type)=>exportData(filteredData,columns,tableState,document.title,type)}
            an_label={`export-${source}-data`}
        />
    </React.Fragment>
}
