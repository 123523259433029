import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl";
import { ReactComponent as AlertSvg } from '../../assets/images/svg/alert.svg'
import classNames from "classnames";

const Alert = ({className, label, values, children, type}) => {
    if(type === 'warning'){
        return <div className={className}>
             <div className="alert alert-warning">
                <div className="alert-icon-container">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <title>informationcircle</title>
                        <path d="M15.875 0c-8.688 0-15.875 7.188-15.875 15.875s7.188 15.813 15.875 15.813c8.688 0 15.813-7.125 15.813-15.813s-7.125-15.875-15.813-15.875zM28.813 15.875c0 7.125-5.813 12.938-12.938 12.938-7.188 0-13-5.813-13-12.938 0-7.188 5.813-13 13-13 7.125 0 12.938 5.813 12.938 13zM18.125 12.063l-4.438 0.563-0.438 2h1.5l-2.25 7.313c-0.188 0.625-0.188 1.063 0.125 1.375s0.813 0.438 1.375 0.438c1.063 0 2.313-0.313 3.063-0.563h0.188l0.563-1.688h-1.813l2.75-9zM17.5 6.25c-0.875 0-1.813 0.75-1.813 1.813 0 0.938 0.75 1.563 1.688 1.563s1.813-0.813 1.813-1.875c0.188-0.75-0.625-1.5-1.688-1.5z">
                        </path>
                    </svg>
                </div>
                <span className="alert-desc p-0 m-0">
                    {children ?? <FormattedMessage id={label} values={values}/>}
                </span>
             </div>
        </div>
    } else {
        return (
            <div className={classNames("alert alert-past-due pl-0 mt-3 mb-0", className)} role="alert">
                <AlertSvg />
                <span className="alert-msg font-weight-normal">
                <FormattedMessage id={label} values={values}/>
            </span>
            </div>
        )
    }
}

export default Alert