/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import produce, {applyPatches} from "immer"
import * as actionTypes from '../constants/action-types';

const changes = {};
let currentVersion = -1;
const noOfVersionsSupported = 100;
const undoableActions = [actionTypes.ADD_ACCOUNT_TO_IDENTITY_SUCCESS, actionTypes.UPDATE_IDENTITY_STAGING, actionTypes.UPDATE_IDENTITY_ACCOUNT];

export default (state = {}, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.CREATE_IDENTITY:
                draft.newIdentity = action.data;
                return;
            case actionTypes.SET_IDENTITY:
                draft.identity = action.data;
                return;
            case actionTypes.CLEAR_IDENTITY:
                delete draft.identity;
                return;
            case actionTypes.GET_IDENTITY_SUCCESS:
                draft.identity = action.data;
                return;
            case actionTypes.GET_IDENTITY_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.UPDATE_IDENTITY_STAGING:
                draft.identity = action.data;
                // draft.identity.email = action.data.newEmail; // not allowed to use this at the same time...if it was a separate reducer action it is fine or chained through middleware
                return;
            case actionTypes.UPDATE_IDENTITY_SUCCESS:
                draft.identity = action.data;
                return;
            case actionTypes.GET_IDENTITY_LIST_SUCCESS:
                draft.identityList = action.data;
                return;
            case actionTypes.GET_IDENTITY_LIST_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.UPDATE_IDENTITY_ACCOUNT:
                draft.identity.accounts = [...draft.identity.accounts.slice(0,action.index), {...action.data}, ...draft.identity.accounts.slice(action.index+1)];
                return;
            case actionTypes.ADD_ACCOUNT_TO_IDENTITY_SUCCESS:
                draft.identity.accounts = [...draft.identity.accounts, {...action.data}];
                return;
            //case actionTypes.REMOVE_ACCOUNT_FROM_IDENTITY:
            //    draft.identity.accounts = [...draft.identity.accounts.slice(0,action.index), ...draft.identity.accounts.slice(action.index+1)];
            //    return;
            case actionTypes.GET_ACCOUNT_AGGREGATE_BY_IDENTITY_SUCCESS:
                draft.accounts = action.data.accounts;
                return;
            case actionTypes.GET_ACCOUNT_AGGREGATE_BY_IDENTITY_FAILURE:
                draft.invalid = true;
                return;

            case actionTypes.CLEAR_IDENTITY_LIST:
                delete draft.identityList;
                return;
            case actionTypes.CLEAR_CHANGE_LOG:
                draft.changeLog = [];
                return;
            case actionTypes.UNDO_MANAGE_IDENTITY:
                return applyPatches(state, changes[currentVersion--].undo);
            case actionTypes.SAVE_IDENTITY_PREFERENCES_SUCCESS:
            case actionTypes.SET_IDENTITY_PREFERENCES:
                draft.preferences = action.data;
                return;
            case actionTypes.SET_IDENTITY_PLAN_ENROLLED:
                draft.planEnrolled = action.data;
                return;
        }
    }, (patches, inversePatches) => {
        if (undoableActions.indexOf(action.type) !== -1) {
            currentVersion++;
            changes[currentVersion] = {
                // redo: patches,
                undo: inversePatches
            };

            delete changes[currentVersion + 1];
            delete changes[currentVersion - noOfVersionsSupported];
        };
    }
);