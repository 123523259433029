
export function formatMaskedNr(val) {
    let regexp = /[*]{3,}/;
    if (regexp.test(val)) {
        //console.log("true");
        return val.replace(regexp, "***");
    } else {
        //console.log("false");
        return val;
    }
}

export function luhnCheck(cardNumber) {
    let digits = cardNumber.length,
        digit = 0,
        sum = 0,
        oddOrEven = (digits & 1);
    for (let count = 0; count < digits; count++) {
        digit = Number(cardNumber.charAt(count));
        if (((count & 1) ^ oddOrEven) === 0) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
    }
    return (sum === 0) ? false : (sum % 10 === 0);
};

export const isAgreementOptionSelected = (paymentMethod) => ["DD","SCB"].includes(paymentMethod?.categoryCode);