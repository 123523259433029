/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 05/24/20 3:12 PM
 * Created by berestet
 *
 */

import produce from "immer"
import * as actionTypes from '../constants/action-types';

export default (state = {}, action) =>
    produce(state, draft => {
        switch (action.type) {

        }
    }
);