import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from "classnames";

import CardActions from '../CardActions';
import { maskIban, maskString } from '../../../../utils/utils';
import { useIntl } from 'react-intl';
export default ({
    editHandler, removeHandler, makeDefaultHandler, showCardActions, extraCssClass,
    paymentMethod: {
        accountNumber, bic, cardHolderName,
        cardNickName, default: isPrimary
    },
    permissions
}) => {
    const intl = useIntl();
    return <React.Fragment>
        <div className={classNames("card-container echeck sepa", extraCssClass)}>
            <p className="pm-info card-nickname">{cardNickName}</p>
            <p className="pm-info echeck-type">
                <FormattedMessage id="payment-method.title.sepa" />
            </p>
            <p className="pm-info account-holder-name">{cardHolderName}</p>
            <p className="pm-info routing-number">{maskIban(accountNumber)}</p>
            <p className="pm-info bank-account-number">{maskString(bic)}</p>
            <div className="echeck-decoration-overlay">
                <div className="echeck-decoration-line1" />
                <div className="echeck-decoration-line2" />
                <div className="echeck-decoration-box1" />
            </div>
        </div>
        {showCardActions &&
            <CardActions
                editHandler={editHandler}
                removeHandler={removeHandler}
                makeDefaultHandler={makeDefaultHandler}
                isPrimary={isPrimary}
                permissions={permissions}
                cardNickName={cardNickName}
                pmLabel={intl.formatMessage({ id: `payment.method.SEPA.Ending.with.bank-account-number.btn.label` }, { accountNumber: accountNumber.slice(-4) })}
            />
        }
    </React.Fragment>
};
