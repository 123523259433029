import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBIcon, MDBNavbar, MDBNavItem, MDBNavbarNav, MDBBtn } from 'mdbreact';
import UserAccountDropdown from './UserAccountDropdown';
import { FormattedMessage, injectIntl } from 'react-intl';
import IE11DisableCacheModal from "../Modal/IE11DisableCacheModal";

class UPSHeaderLoggedIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ie11DisableCacheModal: false
        };
    }

    componentDidMount() {
        let elm = document.getElementById("main-nav-bar");
        elm.setAttribute("role", "none");
    }

    toggleModal = (modalName) => {
        let modalActive = !this.state[`${modalName}Modal`];
        this.setState({
            [`${modalName}Modal`]: modalActive
        });
    };

    render() {
        const { toggleModal } = this;
        const { layout, setMenuStatus, isMenuOpen, intl} = this.props;
        const { ie11DisableCacheModal } = this.state;
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        return (
            <React.Fragment>
                <MDBNavbar double fixed="top" scrolling className={"logged-in"} id="main-nav-bar">
                    <MDBBtn title={intl.formatMessage({id: "ups.side-menu-btn.title"})} color='link' aria-expanded= {isMenuOpen} className="float-left hamburger-menu" onClick={() => setMenuStatus()} tabIndex={layout.modalOpen ? '-1' : '0'}>
                        <MDBIcon icon="bars" color="black" />
                    </MDBBtn>
                    <div role="navigation" className='nav-div'  aria-label={intl.formatMessage({id: "ups.banner.label"})}>
                        <div className="breadcrumb-container mr-auto">
                            <div className="app-title d-none d-sm-table-cell">
                                <FormattedMessage id="ups.billing-center.title" />
                            </div>
                        </div>
                        <MDBNavbarNav right className={"header-dropdown-navbar"}>
                            {isIE &&
                            <MDBNavItem>
                                <div className={"ie11-btn-wrapper"}>
                                    <i className="ie11-icon fas fa-exclamation-circle"></i>
                                    <MDBBtn title={intl.formatMessage({id: "ups.ie11-disable-cache.title"})} color={"ie11"} onClick={() => toggleModal('ie11DisableCache')}>
                                        <i className="fab fa-internet-explorer"></i>
                                    </MDBBtn>
                                </div>
                            </MDBNavItem>
                            }
                            <MDBNavItem>
                                <UserAccountDropdown key={"user-logged-in"}/>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </div>
                </MDBNavbar>
                {ie11DisableCacheModal &&
                    <IE11DisableCacheModal
                        isOpen={ie11DisableCacheModal}
                        toggleModal={toggleModal}
                        key={"ie11-disable-cache-modal"}
                    />
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { layout} = state;
    return { layout};
}

export default connect(mapStateToProps, null)(injectIntl(UPSHeaderLoggedIn));