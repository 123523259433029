import React, {useEffect, useRef, useState} from "react";
import {SORTED_ASC, SORTED_DSC, SORTED_NONE} from "../DataTableV3-utils";
import {useIntl} from "react-intl";

// Component that will announce to screenreaders that sorting of the table has changed
// put into it's own component so it can be the only thing that rerenders instead of the whole table when it gets shown/hidden

export const SortAnnounce = ({ sortedLabel, sortedDirection }) => {
    const [sortAnnounceShown, setSortAnnounceShown] = useState(false)
    const timer = useRef(0)
    const intl = useIntl()
    useEffect(()=>{
        if(sortedLabel && sortedDirection){
            setSortAnnounceShown(true)
            clearTimeout(timer.current)
            timer.current = setTimeout(()=>{
                setSortAnnounceShown(false)
            }, 300)
        }
    }, [sortedLabel, sortedDirection])

    return sortAnnounceShown && <div id="sort-announcement-div" aria-live="polite" aria-atomic="true" className="visuallyhidden">{
        intl.formatMessage(
            {id: `datatable.sorted.${getSortAnnounceIntlId(sortedDirection)}`},
            {column : `${typeof sortedLabel === 'function' ? sortedLabel() : sortedLabel}`}
        )
    }</div>
}

const getSortAnnounceIntlId = (sortedDirection) => {
    switch (sortedDirection){
        case SORTED_ASC: return 'ascending'
        case SORTED_DSC: return 'descending'
        case SORTED_NONE: return 'none'
    }
}