import * as actionTypes from '../constants/action-types';

export function clearErrors() {
    return {
        type: actionTypes.CLEAR_ERRORS
    }
}

export function setErrors(data) {
    return {
        type: actionTypes.SET_ERRORS,
        data: data
    }
}

export function clearFieldError(data){
    return{
        type: actionTypes.CLEAR_FIELD_ERROR,
        data: data
    }
}

