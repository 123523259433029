import * as actionTypes from '../constants/action-types';

export const resetSettings = () => {
    return {
        type: actionTypes.RESTORE_SETTINGS,
    }
};

export const resetSettingsSuccess = () => {
    return {
        type: actionTypes.RESTORE_SETTINGS,
    }
};

export const setLocale = (locale) => {
    //console.log('setting-action: setLocale', locale);
    return {
        type: actionTypes.SET_LOCALE,
        data: locale
    }
};

export const setLocaleSuccess = (locale) => {
    return {
        type: actionTypes.SET_LOCALE_SUCCESS,
        data: locale
    }
};

export const setIssuer = (issuer) => {
    //console.log('setting-action: setLocale', locale);
    return {
        type: actionTypes.SET_ISSUER,
        data: issuer
    }
};

export const setSessionData = (data) => {
    //console.log('setting-action: setLocale', locale);
    return {
        type: actionTypes.USER_SESSION_DATA,
        data: data
    }
};

export const setIssuerSuccess = (issuer) => {
    return {
        type: actionTypes.SET_ISSUER_SUCCESS,
        data: issuer
    }
};

export const handleSideMenuCollapse = () => {
    return {
        type: actionTypes.SIDE_MENU_COLLAPSE
    }
};

/* setting sessionwidget state*/
export const setSessionWidgetState = () => {
    //console.log('setting-action: setLocale', locale);
    return {
        type: actionTypes.SET_SESSION_MODAL_STATE
       
    }
};

export const setCountryLocale = (countryLocale) => {
    return {
        type: actionTypes.SET_COUNTRY_LOCALE,
        data: countryLocale
    }
};

export const setStoreState = (data) => {
    return {
        type: actionTypes.SET_STORE_STATE,
        data
    }
};
