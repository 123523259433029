import React, { Component } from 'react';
import Exception from '../../Exception/index';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import produce from "immer";
import { bindActionCreators } from "redux";
import * as accountActions from "../../../actions/account-action";
import * as errorActions from '../../../actions/error-action';
import AddAccountModal from "../Modal/AddAccountModal";
import CancelAccountModal from '../Modal/CancelAccountModal/index';
import XMLEnrollModal from '../Modal/XMLEnrollModal/index';
import XMLUnEnrollModal from '../Modal/XMLUnEnrollModal/index';
import XMLEnrollErrorModal from '../Modal/XMLEnrollErrorModal/index';
import {getFormattedAccountNumber, stripAccountNumber, getFormattedAccountName, getConfigByBU} from '../../../utils/ups-utils';
import {FormattedMessage, injectIntl} from 'react-intl';
import FilterModal from "../Modal/FilterModal/FilterModal";
import {FilterFuncMultiSelect, FilterFuncSelect} from "../Modal/FilterModal/FilterFuncs"
import AccountFilterCriteria from "../Modal/FilterModal/FilterCriteria/AccountFilterCriteria";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import Spinner from "../../Spinner";
import {getSessionFilter} from "../../../utils/utils";

class AccountTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: '',
            accountList: [],
            data: null,
            datatableCols: [],
            addAccountModal: false,
            cancelAccountModal: false,
            filterModal: false,
            selectedAccount: null,
            tableState:{
                ...INITIAL_TABLE_STATE,
                filter: this.props.filter ?? {}
            }

        };
    }

    componentDidMount(){
        this.initAccountList();
    }

    initAccountList() {
        this.retrieveAccounts(accounts => {
            this.setState(produce(newState=>{
                accounts.forEach(account => {
                    newState.accountList.push(account);
                })
                newState.loaded = true;
            }))
        })
    }

    toggleAddAcctModal = () => {
        this.setState({
            addAccountModal: !this.state.addAccountModal
        });
        if (this.state.addAccountModal) {
            this.props.errorActions.clearErrors();
            this.props.accountActions.initAddAccount();
        }
    };

    toggleFilterModal = () => {
        this.setState(
            produce(draft => {
                draft.filterModal = !draft.filterModal;
            })
        );
    };

    closeCancelAcctModal = () => {
        this.setState(produce(draft => {
            draft.cancelAccountModal = false;
            draft.selectedAccount = null
        }))
    }

    toggleCancelAcctModal = (data, index) => {
        this.setState(
            produce(draft => {
                draft.cancelAccountModal = !draft.cancelAccountModal;
                draft.selectedAccount = data;
            })
        );
    };

    setSelectedAccount = (data, index) => {
        this.setState(
            produce(draft => {
                draft.selectedAccount = data;
            })
        );
    };

    dispatchXmlEditComplete = () => {
        this.props.accountActions.getXmlEnrollmentComplete();
    }

    getDisplayFuncs = (intl) => ({
        getAccountType: ({ accountType }) => intl.formatMessage({ id: `plan.plan-type.${accountType.toLowerCase()}` }),
        getFormattedAccount: (type)=>({ accountNumber, paymentType, parentAccount }) => getFormattedAccountNumber(accountNumber, paymentType, parentAccount, intl, type),
        getPaymentTypeMessage: ({ paymentType }) => intl.formatMessage({ id: `account.payment-type.${paymentType.toLowerCase()}` }),
        getCountryName: ({ merchant}) => merchant ? intl.formatMessage({ id: `country-item.${merchant.countryCode}` }) : "N/A",
        getAccountName: (type)=>({ accountName }) => getFormattedAccountName(accountName, intl, type)
    })

    createDatatableCols = () => {
        const { intl, permissions, accountTypeOverride } = this.props;
        const {getAccountType, getFormattedAccount, getPaymentTypeMessage, getCountryName, getAccountName} = this.getDisplayFuncs(intl);

        const labelIdPrefix = `manage-${accountTypeOverride.toLowerCase()}s`

        let paymentTypeCol = {};
        if (accountTypeOverride === "ACCOUNT") {
            paymentTypeCol = {
                field: 'businessUnit',
                label: intl.formatMessage({ id: `${labelIdPrefix}-table.main.business-unit` }),
                display: getPaymentTypeMessage,
                serialize: getPaymentTypeMessage,
                sortFunc: getPaymentTypeMessage
            };
        } else if (accountTypeOverride === "PLAN") {
            paymentTypeCol = {
                field: 'planType',
                label: intl.formatMessage({ id: `${labelIdPrefix}-table.main.plan-type` }),
                display: getAccountType ,
                serialize: getAccountType,
                sortFunc: getAccountType
            };
        }

        let cols = [
            {
                field: 'accountNumber',
                label: intl.formatMessage({ id: `${labelIdPrefix}-table.main.account-number` }),
                display: getFormattedAccount(),
                serialize: getFormattedAccount('serialize'),
                sortFunc: getFormattedAccount('sort'),
                mobileCardPrimary: true
            },
            {
                field: 'accountName',
                label: intl.formatMessage({ id: `${labelIdPrefix}-table.main.account-name` }),
                display: getAccountName(),
                serialize: getAccountName('serialize'),
                sortFunc: getAccountName('sort')
            },
            {
                field: 'country',
                label: intl.formatMessage({ id: `${labelIdPrefix}-table.main.country` }),
                display: getCountryName,
                serialize: getCountryName,
                sortFunc: getCountryName
            },
            paymentTypeCol
        ]
        if(permissions.account_remove || permissions.enroll_xml) cols.push(
            {
                field: 'actions',
                label: intl.formatMessage({ id: `${labelIdPrefix}-table.main.actions` }),
                className: 'text-center datatable-action-col prevent-toggle no-export',
                sortable: false,
                display: this.createDatatableRowActions
            }
        )
        return cols;
    }

    getXmlEnrollStatus = (data, index) => {
        const { accountNumber, merchant: { countryCode }, paymentType: businessUnit} = data;
        const { accountTypeOverride } = this.props;
        const accountType = accountTypeOverride === "PLAN" ? "Plan":"Account";
        this.setSelectedAccount(data);
        this.props.accountActions.getXmlEnrollment({accountType, accountNumber, countryCode, businessUnit});
    }

    createDatatableRowActions = (data, index) => {
        let {permissions, intl, accountTypeOverride} = this.props;
        let components = [];
        const hasXmlEnrollment = getConfigByBU(data.paymentType)('hasXmlEnrollment');
        const actionLabel = intl.formatMessage({id: `more-actions.${accountTypeOverride.toLowerCase()}-label`}, {accountNumber: stripAccountNumber(data.accountNumber, data.paymentType)});
        if(permissions.account_remove){
            components.push(
                <MDBDropdownItem
                    id={`dropdown-cancel-account-${data.id}`}
                    key={`dropdown-cancel-account-${data.id}`}
                    onClick={() => { this.toggleCancelAcctModal(data, index) }}>
                    {intl.formatMessage({id: `ups.btn.cancel-${this.props.accountTypeOverride.toLowerCase()}.label` })}
                </MDBDropdownItem>
            );
        }
        if(permissions.enroll_xml && hasXmlEnrollment ) {
            components.push(
                <MDBDropdownItem
                    id={`dropdown-enroll-xml-${data.id}`}
                    key={`dropdown-enroll-xml-${data.id}`}
                    onClick={() => this.getXmlEnrollStatus(data,index)}
                >
                    {intl.formatMessage({id: "ups.edit-xml-enroll.label"})}
                </MDBDropdownItem>
            );
        }

        return (
            <MDBDropdown >
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" id={`dropdown-toggle-${data.id}`} aria-label={actionLabel}>
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    {components}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    }

    resetAccounts = (accounts, callback)=>{
        this.setState(produce(newState=>{
            newState.accountList = accounts
        }), ()=>{
            if(callback) callback()
        });
    }

    updateAccounts = (callback) => {
        this.retrieveAccounts((accounts)=>this.resetAccounts(accounts, callback))
    }

    retrieveAccounts = (callback) => {
        const { accountTypeOverride } = this.props;

        if (accountTypeOverride === "PLAN") {
            this.props.accountActions.getPlanList(accounts => {
                callback(accounts)
            })
        } else if (accountTypeOverride === "ACCOUNT") {
            this.props.accountActions.getAccountList(accounts => {
                callback(accounts)
            })
        }
    }

    filterFunc(data, columns){
        const {intl, accountTypeOverride} = this.props;
        const {filter} = this.state.tableState
        let shouldShow = true
        shouldShow &= FilterFuncSelect(filter, 'country', data, columns, intl)
        shouldShow &= FilterFuncMultiSelect(filter, ((accountTypeOverride === 'PLAN') ? 'planType' : 'businessUnit'), data, columns, intl)
        return shouldShow
    }

    rowCheckBoxProps = (rowData) => {
        const {intl, accountTypeOverride} = this.props;
        let accountLabelKey = (accountTypeOverride === 'PLAN') ? "accountNumber.PLAN.label" : "manage-accounts-table.main.account-number";
        return {            
            ...(rowData ? {'aria-label': `${intl.formatMessage({id: accountLabelKey})} ${stripAccountNumber(rowData.accountNumber, rowData.paymentType)}`} : '')
        };
    }

    render() {
        const { accountTypeOverride, permissions, intl,  enrollXmlModal, unEnrollXmlModal, enrollXmlErrorModal, caption } = this.props;
        const { selectedAccount, accountList, loaded, tableState } = this.state;
        const { selection, filter } = tableState;
        // modals
        const { addAccountModal, cancelAccountModal, filterModal, error } = this.state;
        
        if (!loaded) {
            return <Spinner isSpinning={!loaded}/>
        } else {
            if (error instanceof TypeError) {
                return (<Exception error={error} />)
            } else {
                return (<>
                    <FilteredDataTableV3
                        name='my-accounts'
                        caption={caption}
                        data={accountList}
                        defaultSorting={'accountNumber'}
                        columns={this.createDatatableCols()}
                        tableState={tableState}
                        tableStateAction={(action)=>this.setState(action)}
                        searchable
                        buttons={[
                            {
                                className: 'btn-add-account',
                                hide: (!permissions.account_add),
                                text: accountTypeOverride === "ACCOUNT" ? intl.formatMessage({id:"ups.btn.add-account.label"}) : intl.formatMessage({id:"addAccount.plan.title"}),
                                action:()=>this.toggleAddAcctModal()
                            },
                            {type: 'secondary', hide: (selection.size === 0 || !permissions.account_remove),text: <FormattedMessage id="ups.removeSelected"/>, action:()=>this.toggleCancelAcctModal()},
                            {type: "filter", action:()=>this.toggleFilterModal()}
                        ]}
                        {...(permissions.account_remove && {
                            selectEnabled: true,
                            selectAll: true
                        })}
                        filterFunc={this.filterFunc.bind(this)}
                        mobileCard
                        rowCheckBoxProps={this.rowCheckBoxProps}
                        rowHeaderField={'accountNumber'}
                    />
                    {addAccountModal && (
                        <AddAccountModal
                            key={'add-account-modal'}
                            isOpen={addAccountModal}
                            toggleModal={this.toggleAddAcctModal}
                            isModal={true}
                            updateAccounts={()=>this.updateAccounts()}
                            accountTypeOverride={accountTypeOverride}
                        />
                    )}
                    {cancelAccountModal && (
                        <CancelAccountModal
                        caption= {caption}
                            key={'remove-account-modal'}
                            isOpen={cancelAccountModal}
                            selectedAccount={selectedAccount}
                            selectedAccounts={selection}
                            toggleModal={this.toggleCancelAcctModal}
                            closeModal={this.closeCancelAcctModal}
                            updateAccounts={()=>this.updateAccounts()}
                            accountTypeOverride={accountTypeOverride}
                        />
                    )}
                    <FilterModal
                        key={'account-filter-modal'}
                        filterName={(accountTypeOverride === "PLAN") ? 'planTableFilter' : 'accountTableFilter'}
                        isOpen={filterModal}
                        toggleModal={this.toggleFilterModal}
                        renderFilterCriteria={(props)=>{
                            return <AccountFilterCriteria
                                key={'account-filter-criteria'}
                                {...props}
                                accountList={accountList}
                                planView={(accountTypeOverride === "PLAN")}
                            />
                        }}
                        filter={filter}
                        setFilter={(newFilter)=>this.setState(produce(newState=>{
                            newState.tableState.filter = newFilter
                        }))}
                    />
                    {enrollXmlModal && (
                        <XMLEnrollModal
                            key={'enroll-xml-modal'}
                            isOpen={enrollXmlModal}
                            selectedAccount={selectedAccount}
                            toggleModal={this.setSelectedAccount}
                        />
                    )}
                    {unEnrollXmlModal && (
                        <XMLUnEnrollModal
                            key={'unenroll-xml-modal'}
                            isOpen={unEnrollXmlModal}
                            selectedAccount={selectedAccount}
                            toggleModal={this.setSelectedAccount}
                        />
                    )}
                    {enrollXmlErrorModal && (
                        <XMLEnrollErrorModal
                            isOpen={enrollXmlErrorModal}
                            toggleModal={this.dispatchXmlEditComplete}
                        />
                    )}
                </>)
            }

        }
    }
}

function mapStateToProps(state, ownProps) {
    const sessionFilter = getSessionFilter((ownProps.accountTypeOverride === "PLAN") ? 'planTableFilter' : 'accountTableFilter')
    const dbFilter = (ownProps.accountTypeOverride === "PLAN") ? state.identity?.preferences?.planTableFilter : state.identity?.preferences?.accountTableFilter
    return {
        settings: state.settings,
        filter: sessionFilter ?? dbFilter,
        permissions: state.auth?.user?.permissions ?? {},
        enrollXmlModal: state.account.enrollXmlModal,
        unEnrollXmlModal: state.account.unEnrollXmlModal,
        enrollXmlErrorModal: state.account.enrollXmlErrorModal
    }
}
function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
        errorActions: bindActionCreators(errorActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountTable)));
