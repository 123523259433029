import React, {useRef} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {MDBRow, MDBCol} from "mdbreact";

import QRCode from "react-qr-code";

import {formatInvoiceDate, formatCurrency} from "../../utils/invoice-utils";
import {formatPMDesc} from "../../utils/payment-utils";

import Details from "./Details";
import DisplayTable from "./DisplayTable";
import PayInvoiceTable from "./PayInvoiceTable";
import {useReactToPrint} from "react-to-print";
import SepaMandateDownload from "../../components/UPS/SepaMandateDownload"

import * as R from 'ramda';

// Step 4

export default function PaymentReceipt({receipt, invoices, onBack, isRefund, currencyDisplay}){
    const componentRef = useRef();
    const intl = useIntl();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return <Details
        canProceed
        onSubmit={handlePrint} submitLabel="receipt.payment.btn.print"
        onCancel={onBack} cancelLabel="close.label"
    >
        <PaymentReceiptPrintable
            ref={componentRef}
            receipt={receipt}
            invoices={invoices}
            isRefund={isRefund}
            intl={intl}
            currencyDisplay={currencyDisplay}
        />
    </Details>
}

const getTotalCreditAmount = R.compose
  ( Math.abs
  , R.reduce((t, v) => v < 0 ? t + v : t) (0)
  , R.map(R.prop('paymentAmount'))
  , m => Array.from(m.values())
  );

class PaymentReceiptPrintable extends React.PureComponent {
    render(){
        const {receipt, invoices, intl, isRefund, currencyDisplay} = this.props;
        const {paymentMethod} = receipt;
        const creditedAmount = getTotalCreditAmount (invoices);

        const rows = [
            {
                label: "receipt.payment.confirmation-number",
                value: receipt.referenceId
            },
            {
                label: "receipt.payment.status",
                value: intl.formatMessage({id: `status.${receipt.paymentStatus}`})
            },
            {
                label: isRefund ? "make-payment.refund.method" : "receipt.payment.payment-method",
                value: formatPMDesc(intl)(paymentMethod.methodType)
                    + (paymentMethod.cardNickName && ` | ${paymentMethod.cardNickName}` || "")
            },
            {
                label: "receipt.payment.payment-date",
                value: receipt.paymentDate && formatInvoiceDate(intl, receipt.paymentDate),
                hide: !receipt.paymentDate || isRefund
            },
            {
                label: "make-payment.payment-components-table.sepa-mandate-download",
                value: <SepaMandateDownload payment={receipt} />,
                hide: !receipt?.sepaMandate?.authorizeSepa
            },
            {
                label: "make-payment.payment-components-table.credited-amount",
                value: formatCurrency(intl, creditedAmount, receipt.currencyCode, currencyDisplay),
                hide: (creditedAmount === 0)
            },
            {
                label: isRefund ? "make-payment.payment-components-table.total-refund" : "make-payment.payment-components-table.total-payment",
                value: formatCurrency(intl, receipt.signedTotalAmount, receipt.currencyCode, currencyDisplay),
                className: "total-p-line"
            }
        ];
        return <div id="paymentReceiptPrintBounds">
            <h1 className="has-breadcrumb print-only-content">
                <FormattedMessage id={'receipt.payment.card-header.title'}/>
            </h1>
            <MDBRow>
                <MDBCol size="12">
                    <InfoMessage receipt={receipt}/>
                    < WalletSavedInfoMessage receipt={receipt}/>
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol size="12">
                    <DisplayTable rows={rows}/>
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol size="12">
                    <PayInvoiceTable caption="receipt.payment.card-header.title" invoices={invoices}/>
                </MDBCol>
            </MDBRow>
        </div>;
    }
}


function InfoMessage({receipt: {paymentStatus, paymentMethod, hostedCheckoutData}}) {
    switch (paymentStatus) {
        case "DECLINED":
            return <p role="alert"><FormattedMessage
                id="receipt.payment.DECLINED"
            /></p>;
        case "PROCESSING":
            if(paymentMethod?.methodType === 'SCB') return <PaymentSubmitted/>
            const msgKey = "ups.payment.processing.label." + paymentMethod?.methodType.toLowerCase();
            return <p role="alert" className="processing-msg">
                <FormattedMessage id={msgKey}/>
                {hostedCheckoutData?.qrcodedata && <div className='text-center'><QRCode value={hostedCheckoutData.qrcodedata} /></div>}
            </p>;
        default:
            return <PaymentSubmitted/>
    }
}

function WalletSavedInfoMessage({receipt: { walletResp }}) {
   return (walletResp === 'saved') &&
            <p><FormattedMessage id="payment-method.wallet.save-method-success"/></p>
}

const PaymentSubmitted = () => <p role="alert"><FormattedMessage
    id="receipt.payment.successfully-submitted"
/></p>