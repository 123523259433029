import React from "react";
import {
    MDBTable, MDBTableHead, MDBTableBody
} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {stripAccountNumber, getPlanNameLabel, getAccNameLabel} from "../../../utils/ups-utils";
import {displayWalletSelection, accountSupportsDebitOnly} from "../../../utils/payment-utils";

export default function AccountPreview(props) {
    const intl = useIntl();
    const {account, paymentMethod} = props[props.modelName];
    const {selectedPaymentType: businessUnit} = useSelector(s => s.auth.user);
    return <MDBTable className="table-sm table-bordered-simple mb-4 table-custom-responsive">
        <MDBTableHead color="light">
            <tr>
                <th className="w-33">
                    {account?.isParent ? getPlanNameLabel(intl)(businessUnit) : getAccNameLabel(intl)(businessUnit)}
                </th>

                <th className="w-33">
                    <FormattedMessage id={account?.isParent
                        ? "modal.view-schedule.plan"
                        : "modal.view-schedule.account"
                    }/>
                </th>

                {!paymentMethod && <th className="w-33">
                    <FormattedMessage id="modal.view-schedule.DD-only"/>
                </th>}

                {paymentMethod && <th className="w-33">
                    <FormattedMessage id="modal.view-schedule.payment-method"/>
                </th>}
            </tr>
        </MDBTableHead>

        <MDBTableBody>
            <tr>
                <td>{account?.accountName}</td>

                <td>{stripAccountNumber(account?.accountNumber, account?.paymentType)}</td>

                {!paymentMethod && <td>
                    <FormattedMessage id={accountSupportsDebitOnly(account) ? "yes.label" : "no.label"}/>
                </td>}

                {paymentMethod && <td>
                    {displayWalletSelection(intl)(paymentMethod)}
                </td>}
            </tr>
        </MDBTableBody>
    </MDBTable>;
}
