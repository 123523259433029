import * as R from 'ramda';
import {MAX_INVOICES, PAY_LATER_RANGE} from "../constants/invoice-constants";

const FREIGHT_ACTIVE = true
const ENABLE_COBRAND_CARD_TYPE_SELECT = true

export const getClientConfig = R.compose(
    R.propOr({}, 'clientConfiguration')
);

export const getPartialPayReasons = R.compose(
    R.filter(R.complement(R.isEmpty)),
    R.split(","),
    R.propOr('', 'PARTIAL_PAY_REASONS'),
    getClientConfig
);

export const getPayLaterRange = invoiceDueDate => R.compose(
    configPayLaterDays => configPayLaterDays < 0 ||  invoiceDueDate < configPayLaterDays
        ? invoiceDueDate
        : configPayLaterDays === 0 
            ? parseInt(PAY_LATER_RANGE) 
            : configPayLaterDays,
    parseInt,
    R.propOr(PAY_LATER_RANGE, 'MAX_PAY_LATER_DAYS'),
    getClientConfig
);

export const isPBTConfigEnabled = R.compose(
    R.propEq('PAY_BY_TEXT_ENABLED', true),
    getClientConfig
);

export const getMaxInvoices = R.compose(
    R.propOr(MAX_INVOICES, 'BC_MAX_INVOICES'),
    getClientConfig
);

export const isFreightActive = R.compose(
    R.propOr(FREIGHT_ACTIVE, 'BC_FREIGHT_ACTIVE'),
    getClientConfig
);

export const isCobrandCardTypeSelectEnabled = R.compose(
    R.propOr(ENABLE_COBRAND_CARD_TYPE_SELECT, 'ENABLE_COBRAND_CARD_TYPE_SELECT'),
    getClientConfig
);

export const noDocumentDownloadInvoiceTypes = ({clientConfiguration = {}})=>{
    const {BC_DISABLE_INVOICE_DOC_DL_TYPES = ''} = clientConfiguration
    return BC_DISABLE_INVOICE_DOC_DL_TYPES.split(',')
}

const hasBusinessUnit = businessUnit => R.compose
    ( R.any(R.propEq('name', businessUnit))
    , R.prop('paymentTypes')
    );

export const getMerchantPaymentType = ({countryCode, paymentType}) => R.compose
  ( R.compose (R.find(R.propEq('name', paymentType)), R.prop('paymentTypes'))
  , R.find(R.allPass([ R.propEq('countryCode', countryCode)
                     , hasBusinessUnit(paymentType)
                     ])
          )
  );

export const findSelectedMerchant = countryCode => R.find(R.propEq('countryCode', countryCode));

export const isPlanSupported = R.pathEq(['businessUnit', 'name'], 'EBS');

const getLastSelectedCountryCode =  R.ifElse
    ( R.has('selectedCountry')
    , R.prop('selectedCountry')
    , R.path(['preferences', 'lastSelectedCountryCode'])
    );

const getLastSelectedBusinessUnit = R.ifElse
    ( R.has('selectedPaymentType')
    , R.prop('selectedPaymentType')
    , R.path(['preferences', 'lastSelectedPaymentType'])
    );

export const getSessionSelection = (user, merchants) => ({ merchant: findSelectedMerchant (getLastSelectedCountryCode(user)) (merchants)
                                                         , businessUnit: getMerchantPaymentType ({ countryCode: getLastSelectedCountryCode (user)
                                                                                                 , paymentType: getLastSelectedBusinessUnit (user)
                                                                                                 }) (merchants)
                                                         });
