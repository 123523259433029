import React, {useEffect} from "react";

const AlternatePmNoInput = ({pmCategory, paymentMethod, submitAction}) => {

    useEffect(()=>{
        const data = {
            methodType: pmCategory,
            categoryCode: pmCategory,
        };
        if (paymentMethod) data.id = paymentMethod.id;

        return submitAction?.(data);
    },[])

    return <></>;
}

export default AlternatePmNoInput