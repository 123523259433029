import React, {useState, useEffect} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {MDBRow, MDBCol, MDBBtn} from "mdbreact";
import InputFieldIntl from "../../../components/InputFieldIntl";
import {InputRestrictors} from '../../../utils/validation/standard-validations';
import Details from ".";

import DisplayOnlyField from "../../DisplayOnlyField";
const BlikDetails = ({
    isModal, pmCategory, paymentMethod, submitAction, handleCancel,
    controlled, merchantId, onError
}) => {
    const [blikCode, setBlikCode] = useState(null)
    const intl = useIntl();
    
    useEffect(() => {
        if (!controlled) return;
        validatePM();
    }, [blikCode]);

    const validatePM = () => {
        const data = {
            methodType: pmCategory,
            categoryCode: pmCategory,
            'blikCode': blikCode
        };
        if (paymentMethod) data.id = paymentMethod.id;

        return submitAction?.(data);
    }

    const form =
        <>
            <MDBRow>
            {controlled &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="blikCode" id="blikCode"
                        label={intl.formatMessage({id: "ups.payment-method.blik-code.label"})}
                        value={blikCode}
                        onChange={e => setBlikCode(e.target.value)}
                        hint="ups.payment-method.blik-code.tip"
                        maxLength={6}
                        required
                        restrict={InputRestrictors.numeric}
                        validations={[
                            ["required", "field.required"]
                        ]}
                    />
                </MDBCol>
                }
            </MDBRow>
        </>
    if (controlled) return form;
    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}

export default BlikDetails