import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as settingActions from "../../../actions/setting-action";

import { countryData } from '../../../constants/country-data';

import produce from "immer";
import { set as lodashSet } from 'lodash';
import * as identityActions from "../../../actions/identity-action";
import * as invoiceActions from '../../../actions/invoice-action';
import * as chatbotActions from '../../../actions/chatbot-action';
import CheckboxIntl from '../../CheckboxIntl';
import { logTealiumAnalytics, updateTealiumParams, loadTealiumScripts, TEALIUM_EVENT } from '../../../utils/tealium-utils';
import NotificationModal, { BUTTON_TYPES } from '../Modal/NotificationModal';
import { setGlobalSettingAlertPref, getGlobalSettingAlertPref } from "../../../utils/local-storage";
import { sortCountryList, generateCountryList } from "../../../utils/utils.js";
import { getMerchantPaymentType, findSelectedMerchant } from '../../../utils/config-utils';

import * as R from 'ramda';

const getUserSelectedMerchantIdFromPreferences = R.path(['auth', 'user', 'preferences', 'selectedMerchantId']);

const addCountryData = R.compose
( ([{ languages = [] }, country]) => R.map(lang => ({ ...country, ...lang }))(languages)
  , R.juxt
  ( [ ({ countryCode }) => R.find(R.propEq('code', countryCode))(countryData)
      , ({ countryCode, id, tla }) => ({ country: countryCode, merchantId: id, tla })
    ]
  )
);

const toLanguages = R.compose
( R.flatten
  , R.map(addCountryData)
  , R.uniqBy(R.prop('countryCode'))
  , R.map(R.pick(['countryCode', 'id', 'tla']))
);

class CountrySettingsPanel extends Component {
    constructor(props) {
        super(props);

        const languages = this.getSupportedLanguages();
        const languageInfo = this.getLanguageInfo(languages);
        const currentBusinessUnits = this.getBusinessUnits(languageInfo.country);
        const selectedBusinessUnit = props.auth?.user?.selectedPaymentType ? props.auth.user.selectedPaymentType : currentBusinessUnits[0]
        const sortedLanguageList = this.sortCountryAndLanguage(languages);

        this.state = {
            globalCountryOption: languageInfo.id,
            globalBusinessUnitType: selectedBusinessUnit,
            toggleGlobalOption: false,
            languages: languages,
            globalLangDisplayValue: languageInfo.id,
            globalBusinessUnitDisplayValue: selectedBusinessUnit,
            saveAsDefaultView: false,
            availableBusinessUnits: currentBusinessUnits,
            showRedirectNotification: false,
            sortedLanguageList: sortedLanguageList,
            countryCode: languageInfo.country,
            selectedMerchantId: getUserSelectedMerchantIdFromPreferences(props),
            showErrorMessage: false
          };

    updateTealiumParams({ country: languageInfo.country, language: languageInfo.id.split("-")[0] });
    loadTealiumScripts();
  }

  sortCountryAndLanguage(languages) {
    const { intl } = this.props;
    let sortedCountriesList = sortCountryList(generateCountryList(languages, null, intl));
    return sortedCountriesList;
}

  getLanguageInfo(languages) {
    const {auth, settingActions, identityActions} = this.props;
    /*  we get auth.user.locale from login callback and this locale is set as the initial locale in the application,
        earlier we were using settings.locale for comparison but in some cases it seems that state change takes place after the country settings
        comp is loaded ( this could be due to lazy loading of locale files), so to avoid locale and country mismatch due to which get country undefined error,
        we are picking up locale from auth.user.locale when CountrySettingsPanel is loaded.
    */

    // console.log("auth.user.locale - " + auth.user.locale + " & auth.user.selectedCountry - " + auth.user.selectedCountry);

    let langInfo = languages.find(x => x.code === auth.user.locale && x.country === auth.user.selectedCountry);

    if(!langInfo){
        // console.log("Undefined language log: ");
        // console.log("auth.user.locale - " + auth.user.locale + " & auth.user.selectedCountry - " + auth.user.selectedCountry);
        // console.log(JSON.stringify(languages));

        // assign first available language for selected country and set this locale as current locale
        langInfo = languages.find(x => x.country === auth.user.selectedCountry);
        settingActions.setLocale(langInfo.code);
        settingActions.setCountryLocale(langInfo.id);
        identityActions.setSelectedCountry({country : langInfo.country, locale : langInfo.code});
    }
    else{
        // replace the default settings when url is refreshed.
        settingActions.setLocale(langInfo.code);
        settingActions.setCountryLocale(langInfo.id);
    }

    return langInfo;
  }

    updateState = (languageInfo, globalBusinessUnitType, languages, availableBusinessUnits) => {
        const { identityActions, settingActions, invoiceActions, currentSelectionChanged, allMerchants, chatbotActions } = this.props;
        const { globalLangDisplayValue } = this.state;
        
        const isLocaleChanged = globalLangDisplayValue !== languageInfo.id;
        
        settingActions.setLocale(languageInfo.code);
        settingActions.setCountryLocale(languageInfo.id);
        identityActions.setUserCountrySettings({country : languageInfo.country, locale : languageInfo.code, selectedPaymentType: globalBusinessUnitType});
        invoiceActions.getInvoiceMetadata();

        currentSelectionChanged({ businessUnit: getMerchantPaymentType({countryCode: languageInfo.country,  paymentType: globalBusinessUnitType}) (allMerchants)
                                , merchant: findSelectedMerchant (languageInfo.country) (allMerchants)
                                });
        

        this.setState({
            toggleGlobalOption: false,
            languages,
            globalCountryOption : languageInfo.id,
            globalBusinessUnitType,
            globalLangDisplayValue: languageInfo.id,
            globalBusinessUnitDisplayValue: globalBusinessUnitType,
            availableBusinessUnits
        });
        
        chatbotActions.updateChatbotTracking({pathname: window.location.pathname, isLocaleChanged});
    }

    updateTealiumAnalytics(languageInfo) {
        updateTealiumParams({country: languageInfo.country, language : languageInfo.id.split("-")[0]});
        logTealiumAnalytics(TEALIUM_EVENT.VIEW);
    }

    updateCountryList = () => {
        const { auth, settings, preferences } = this.props;

        if(auth.merchants && auth.merchants.length > 0){
            let { globalCountryOption, globalBusinessUnitType, availableBusinessUnits} = this.state;
            const languages = this.getSupportedLanguages();
            let defaultLang = "";
            let langIndex = languages.findIndex(x => x.country == auth.user.selectedCountry && x.code === settings.locale);

            if(langIndex < 0){
                defaultLang = languages[0];
                let preferredCountry = preferences.defaultCountryCode || preferences.lastSelectedCountryCode;
                let preferredLang = preferences.defaultLanguageCode || preferences.lastSelectedLanguageCode;

                if(preferredCountry){
                    langIndex = languages.findIndex(x => x.country == preferredCountry);
                    if(langIndex > -1){
                        if(languages.findIndex(x => x.country == preferredCountry && x.code === preferredLang) > -1) {
                            defaultLang = languages.find(x => x.country == preferredCountry && x.code === preferredLang);
                        }
                        else{
                            defaultLang = languages.find(x => x.country == preferredCountry);
                        }
                    }
                }

                globalCountryOption = defaultLang.id;
            }
            else{
                defaultLang = languages[langIndex];
            }

            availableBusinessUnits = this.getBusinessUnits(defaultLang.country);

            let preferredBusinessUnit = ""

            if(!preferences.defaultCountryCode && preferences.lastSelectedCountryCode && preferences.lastSelectedPaymentType){
                preferredBusinessUnit = preferences.lastSelectedPaymentType;
            }
            else if(preferences.defaultPaymentType){
                preferredBusinessUnit = preferences.defaultPaymentType;
            }

            globalBusinessUnitType = availableBusinessUnits.indexOf(preferredBusinessUnit)  > -1 ? preferredBusinessUnit : availableBusinessUnits[0];

            this.setState({
              globalCountryOption : globalCountryOption,
              availableBusinessUnits: availableBusinessUnits,
              globalBusinessUnitType: globalBusinessUnitType,
              languages: languages
            }, () => {
              this.handleViewChange();
            });
        }
    };

    componentDidMount(){
        const { globalBusinessUnitDisplayValue } = this.state;
        const { identityActions } = this.props;
        identityActions.setSelectedPaymentType(globalBusinessUnitDisplayValue);
    }

  componentDidUpdate (prevProps) {
    const { auth, invoiceActions } = this.props;

    if (prevProps.auth.merchants != auth.merchants) {
      this.updateCountryList();
    }
  }

  handleInput = e => {

    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (inputName === "globalCountryOption") {
      const { languages } = this.state;
      const dataValue = languages.find(x => x.id === inputValue);
      this.updateBusinessUnit(dataValue.country);
    }

    this.setState(
      produce(draft => {
        lodashSet(draft, inputName, inputValue);
      })
    );
  };

    updatePreferences(languageInfo,callback){
        let { globalBusinessUnitType, saveAsDefaultView, languages, availableBusinessUnits} = this.state;
        const { identityActions } = this.props;

        let preferences = {
          lastSelectedLanguageCode: languageInfo.code,
          lastSelectedCountryCode: languageInfo.country,
          lastSelectedPaymentType: globalBusinessUnitType,
          selectedMerchantId: languageInfo.merchantId,
          tla: languageInfo.tla
        };

        if (saveAsDefaultView) {
          preferences.defaultLanguageCode = languageInfo.code;
          preferences.defaultCountryCode = languageInfo.country;
          preferences.defaultPaymentType = globalBusinessUnitType;
          preferences.selectedMerchantId = languageInfo.merchantId;
          preferences.tla = languageInfo.tla;
        }

        identityActions.saveIdentityPreferences(preferences, 'countryBusinessUnit', (result) => {
          if(result.status === true){
            this.updateTealiumAnalytics(languageInfo);
            this.updateState(languageInfo, globalBusinessUnitType, languages, availableBusinessUnits);
            if(callback) {
              callback();
            }
          }
          else{
            this.setState({
              showErrorMessage: true,
              errorCode: result.errorCode ?? 'internalError'
            });
            this.cancelViewChange();
          }
        });
    }

    handleViewChange = (callback) => {
        let { globalCountryOption, languages} = this.state;
        const languageInfo = languages.find(x => x.id === globalCountryOption);
        this.updatePreferences(languageInfo, callback);
   };

    cancelViewChange = () => {

    let { globalLangDisplayValue, globalBusinessUnitDisplayValue, globalCountryOption, globalBusinessUnitType, availableBusinessUnits } = this.state;
    const { auth } = this.props;
    // Restore original state in drop-down
    globalCountryOption = globalLangDisplayValue;
    globalBusinessUnitType = globalBusinessUnitDisplayValue;
    availableBusinessUnits = this.getBusinessUnits(auth.user.selectedCountry); // revert to original list of business units

    this.setState(prevState => ({
      toggleGlobalOption: !prevState.toggleGlobalOption, globalCountryOption, globalBusinessUnitType, availableBusinessUnits
    }));
  }

  editGlobalCountrySettings = () => {
    const { languages } = this.state;
    const sortedLanguageList = this.sortCountryAndLanguage(languages);
    this.setState(prevState => ({
        toggleGlobalOption: !prevState.toggleGlobalOption,
        sortedLanguageList: sortedLanguageList
    }), () => {
      // on opening country section for editing, focus on first combo-box
      document.getElementById("globalCountryOption").focus();
    });
  }

  getSupportedLanguages () {
    return toLanguages(this.props.auth.merchants);
  }

    getBusinessUnits(countryCode){
        const { allMerchants, auth } = this.props;

        let businessUnits = [];
        const merchant = auth.merchants.find(m => m.countryCode === countryCode) || allMerchants.find(m => m.countryCode === countryCode);

        if(merchant){
            const {paymentTypes} = merchant;
            // automatically select business unit if there is only one for this country
            if (paymentTypes.length > 0) {
                paymentTypes.forEach((item) => {
                    businessUnits.push(item.name);
                });
            }
        }

    return businessUnits;
  }

  handleCheckBox = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.checked;

    this.setState(
      produce(draft => {
        lodashSet(draft, inputName, inputValue);
      })
    );
  }

  updateBusinessUnit (value) {
    const businessUnits = this.getBusinessUnits(value);
    this.setState(produce(draft => {
      draft.availableBusinessUnits = businessUnits;
      draft.globalBusinessUnitType = businessUnits[0]
    }));
  }

  closeNotificationModal = (args) => {
    this.setState(produce(draft => {
      draft.showRedirectNotification = false;
    }));

    if (args.value === "ok") {
      this.handleViewChange(()=>{
        this.props.history.push("/home");
      });
    }
    else {
      this.cancelViewChange();
    }

    if (args.hideNotification) {
      setGlobalSettingAlertPref(args.hideNotification);
    }
  }

  isRedirectRequired () {
    const { pathname } = this.props.location;

    // country change does not have effect on the following routes, so alert and redirect is not required for these routes.
    const allowedRoutes = [
      "/home",
      "/ups/billing/admin/manage/users",
      "/admin/manage/plans",
      "/admin/manage/accounts",
      "/preferences",
      "/support",
      "/ups/billing/terms",
      "/ups/billing/privacy"
    ];

    return (allowedRoutes.indexOf(pathname) < 0);
  }

  saveSettings = () => {
    const hideAlert = getGlobalSettingAlertPref();

    let notifyUser = false;
    const { globalLangDisplayValue, globalCountryOption, globalBusinessUnitDisplayValue, globalBusinessUnitType } = this.state;

    const prevCountryCode = globalLangDisplayValue.split("-")[1];
    const newCountryCode = globalCountryOption.split("-")[1];

    if (globalBusinessUnitDisplayValue !== globalBusinessUnitType || prevCountryCode !== newCountryCode) {
      notifyUser = true && this.isRedirectRequired();
    }

    if (notifyUser) {
      if (hideAlert) {
        this.handleViewChange(()=>{
          this.props.history.push("/home");
        });
      }
      else {
        this.setState(produce(draft => {
          draft.showRedirectNotification = true;
        }));
      }
    }
    else {
        this.handleViewChange();
    }
  }

  render () {
    const { globalCountryOption, globalBusinessUnitType, toggleGlobalOption, languages, globalLangDisplayValue, globalBusinessUnitDisplayValue, saveAsDefaultView, availableBusinessUnits, showRedirectNotification,
    sortedLanguageList, showErrorMessage, errorCode } = this.state;
    const { intl } = this.props;

    return (
      <React.Fragment>
        <div className={"global-ups-country"}>
          {toggleGlobalOption === false ?
            <React.Fragment>
              <MDBRow>
                <MDBCol>
                  <hr />

                  <p className={"text-center m-0"}><FormattedMessage id={"country-language-item." + globalLangDisplayValue} /></p>
                  <p className={"text-center mb-0"}>
                      <FormattedMessage id={"business-unit." + globalBusinessUnitDisplayValue}
                                        values={{
                                          sup: msg => (
                                              <sup>{msg}</sup>
                                          )
                                        }}
                      />
                  </p>
                  <div className={"text-center"}><MDBBtn color="change-country"
                                                         onClick={this.editGlobalCountrySettings}><FormattedMessage id="side-nav.btn-change" /></MDBBtn>
                  </div>
                  <hr />
                </MDBCol>
              </MDBRow></React.Fragment>
            : null}
          {toggleGlobalOption === true ?
            <React.Fragment>
              <MDBRow className={"mt-4"}>
                <MDBCol size={"12"}>
                  <div
                    className={"md-form md-browser-default-form browser-default-select has-sr-only"}>
                    <label className="browser-default-label"
                      htmlFor="globalCountryOption">
                      <FormattedMessage id="side-nav.country.label" />
                    </label>

                    <select
                      key={"globalCountryOption"}
                      name={"globalCountryOption"}
                      id={"globalCountryOption"}
                      className={"browser-default custom-select"}
                      required
                      onChange={this.handleInput}
                      value={globalCountryOption}
                    >
                      {sortedLanguageList.map((item) => {
                          return (
                              <option key={item.id} value={item.id}>
                                  {intl.formatMessage({ id: "country-language-item." + item.id})}
                              </option>
                          )
                      })}
                    </select>
                  </div>
                </MDBCol>
                <MDBCol size={"12"}>
                  <div
                    className={"md-form md-browser-default-form browser-default-select has-sr-only"}>
                    <label className="browser-default-label"
                      htmlFor="globalBusinessUnitType">
                      <FormattedMessage id="side-nav.business-unit.label" />
                    </label>
                    <select
                      key={"globalBusinessUnitType"}
                      name={"globalBusinessUnitType"}
                      id={"globalBusinessUnitType"}
                      className={"browser-default custom-select"}
                      required
                      onChange={this.handleInput}
                      value={globalBusinessUnitType}>
                      {availableBusinessUnits.map((item) => {
                        if(item === "SCS"){
                          return (
                              <option key={item} value={item}>
                              {intl.formatMessage({id: "business-unit.SCS.label"},
                                  {values: {
                                          sup: msg => (
                                              <sup>{msg}</sup>
                                          )
                                      }
                                  }
                                )}
                              </option>
                            )
                        }
                        else{
                            return (
                              <option key={item} value={item}>
                                  {intl.formatMessage({id: "business-unit." + item})}
                              </option>
                          )}
                      })}
                    </select>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size={"12"}>
                  <MDBBtn color="primary" className="ups-style btn-primary" onClick={this.saveSettings}><FormattedMessage id="side-nav.btn-apply" /></MDBBtn>
                                        <MDBBtn color="cancel" onClick={this.cancelViewChange}><FormattedMessage id="side-nav.btn-cancel" /></MDBBtn>

                  <CheckboxIntl name={"saveAsDefaultView"}
                    type={"checkbox"} onChange={this.handleCheckBox} value={saveAsDefaultView}
                    label={<FormattedMessage id="side-nav.save-default-view" />}
                  />


                  <hr />
                </MDBCol>
              </MDBRow></React.Fragment> : null}
        </div>
        {showRedirectNotification && <NotificationModal isOpen={showRedirectNotification} closeModal={this.closeNotificationModal} buttonTypes={BUTTON_TYPES.OK_CANCEL} message="notification.change-settings" dontShowAgain separator />}
        {showErrorMessage && <NotificationModal isOpen={showErrorMessage} title=" " size="md" closeModal={() => { this.setState({ showErrorMessage: false }) }} buttonTypes={BUTTON_TYPES.OK} message={ errorCode ?? "internalError" } />}
        </React.Fragment>
    );
  }
}

function mapStateToProps (state, ownProps) {
  const { auth, settings, identity, config } = state;

  return {
    auth,
    settings,
    preferences: identity.preferences,
    allMerchants: config.merchants
  };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        identityActions: bindActionCreators(identityActions, dispatch),
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        chatbotActions: bindActionCreators(chatbotActions, dispatch),
        currentSelectionChanged: sessionSelection => dispatch({type: 'SESSION_SELECTION_CHANGED', sessionSelection})
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CountrySettingsPanel)));
