import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router";
import { isNil } from 'ramda';

export default function LogonWait () {
  const errors = useSelector(s => s.error?.errors);
  const merchants = useSelector(s => s.config.merchants);
  const {enroll, user, merchants: identityAccessibleMerchants, csrfToken} = useSelector(s => s.auth);
  const {invoiceMetadata} = useSelector(s => s.invoice);

  const history = useHistory();

  // Multiple requests are made after logon and we need to wait for
  // each to return so the application state is ready.
  useEffect(() => {
    if (merchants?.length > 0 && !isNil(csrfToken)) {
      if (enroll) {
        history.replace({ pathname: '/enrollment' }, []);
      } else if (!isNil(user) && identityAccessibleMerchants?.length > 0) {
        if(user.permissions['invoice_view_invoices']){
          if(invoiceMetadata){
            history.push(user.lastVisitedPage);
          }
        }
        else{
          history.push(user.lastVisitedPage);
        }
      } else {
        console.log('waiting for logon ready state');
      }
    }
  }, [enroll, merchants, identityAccessibleMerchants, user, csrfToken, invoiceMetadata]);

  return (<div>
            <div className="text-center mb-4 mt-5">
                <FormattedMessage id="ups.bc.access"></FormattedMessage>
            </div>
            {errors &&
             <div className={"ups-landing-container"}>
              <div className="text-center mb-4 mt-5">
                <FormattedMessage id={errors[0]} />
              </div>
            </div>
            }
          </div>
  );
}
