import React, {useEffect, useReducer}                           from "react";
import { useSelector }                                          from "react-redux";
import { inc }                                                  from 'ramda';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter
       , MDBRow, MDBCol, MDBBtn}                                from "mdbreact";
import {FormattedMessage, useIntl}                                       from "react-intl";

import { removeWalletPm }                                       from '../../../../api/wallet-api';
import PaymentMethodCard                                        from "../../PaymentMethodCard";
import ReactPortalModal                                         from "../../../ReactPortalModal";
import {isPBTSupportedCountry}                                  from '../../../../utils/payByText-utils';

import Error                                                   from '../../../../components/Error';
import Spinner                                                 from '../../../Spinner';

const initialState = {
  deleting: false,
  error: '',
  modalStep: 1
};

function removeWalletStateReducer (state, action) {
    switch (action.type) {
        case 'init':           return initialState;
        case 'delete_wallet':  return { ...state, deleting: true };
        case 'delete_success': return { ...state, deleting: false, modalStep: inc(state.modalStep) };
        case 'delete_failed':  return { ...state, deleting: false, error: action.error };
        default:               return state;
    }
}

export default function RemovePaymentMethodModal({paymentMethod, isOpen, toggleModal, backdrop}) {
    const [state, dispatch] = useReducer(removeWalletStateReducer, initialState);
    const { selectedCountry, selectedPaymentType } = useSelector(s => s.auth.user);
    const pmDeletionWarningMsg = !isPBTSupportedCountry(selectedCountry, selectedPaymentType) ? "payment-method.wallet.cancel-method.line1" : "payment-method.wallet.cancel-method.line2";
    const intl = useIntl();
    const onClose = () => {
        dispatch({type: 'init'});
        toggleModal("removePaymentMethod");
    };

    useEffect(() => {
        let closed = false;
         if (state.deleting) {
            removeWalletPm(paymentMethod)
              .then(() => !closed && dispatch({type: 'delete_success'}))
              .catch(({errorCode}) => !closed && dispatch({type: 'delete_failed', error: errorCode}));
        }
        return () => closed = true;
    }, [state.deleting]);

    const body = <MDBRow>
        <MDBCol size="12">
            {state.modalStep === 1 &&
                <React.Fragment>
                    <h3 className="mb-3"><FormattedMessage id="payment-method.wallet.cancel-method.target"/></h3>
                </React.Fragment>
            }
            {state.modalStep === 2 &&
                <p><FormattedMessage id="payment-method.wallet.cancel-method-success.line1"/></p>
            }
            <PaymentMethodCard paymentMethod={paymentMethod}/>
            {state.modalStep === 1 && <div className="remove-pm-alert-container">
                <div className="alert alert-warning d-flex align-items-center">
                    <div className="alert-icon-container">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                            viewBox="0 0 32 32"><title>alert</title>
                            <path
                                d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                        </svg>
                    </div>
                    <span className="alert-desc p-0 m-0 text-left"><FormattedMessage id={pmDeletionWarningMsg} /></span>
                </div>
            </div>
            }
        </MDBCol>
    </MDBRow>;

    const footer = <MDBRow>
        {state.modalStep === 1 &&
            <React.Fragment>
                <MDBCol size="12" className="mt-3 mt-sm-0 text-center">
                    <MDBBtn id="cancel-account-modal-btn-cancel-account" color="primary" onClick={() => dispatch({type: 'delete_wallet'})}>
                        <FormattedMessage id="ups.btn.submit.label"/>
                    </MDBBtn>
                </MDBCol>
                <MDBCol size="12" className="mt-3 mt-sm-0 text-center">
                    <MDBBtn flat id="cancel-account-modal-btn-cancel" className="btn-flat-link" onClick={onClose}>
                        <FormattedMessage id="ups.btn.cancel.label"/>
                    </MDBBtn>
                </MDBCol>
            </React.Fragment>
        }
        {state.modalStep === 2 &&
            <MDBCol size="12" className="mt-3 mt-sm-0 text-center">
                <MDBBtn color="primary" onClick={onClose}>
                    <FormattedMessage id="ups.remove-user.btn-close"/>
                </MDBBtn>
            </MDBCol>
        }
    </MDBRow>;
    const modalHeading = (state.modalStep === 1) ? "payment-method.wallet.label.cancel-method" : (state.modalStep === 2) ? "payment-method.wallet.label.cancel-method-success" : "";
    const modalLabel = "remove-payment-method";
    if (isOpen) {
        return (
            <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                <MDBModal isOpen={isOpen} toggle={onClose} size="md" backdrop={backdrop} disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
                    {state.deleting && <Spinner isSpinning={true} />}
                    <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={onClose}>
                        {state.modalStep === 1 && <FormattedMessage id="payment-method.wallet.label.cancel-method" />}
                        {state.modalStep === 2 && <FormattedMessage id="payment-method.wallet.label.cancel-method-success" />}
                        {state.error?.length > 0 &&
                            <Error errors={[state.error]} />
                        }
                    </MDBModalHeader>
                    <MDBModalBody aria-live="assertive">{body}</MDBModalBody>
                    <MDBModalFooter aria-live="polite" className="mt-3">{footer}</MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        )
    } else {
        return null;
    }
}
