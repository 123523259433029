import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as identityActions from "../../../../actions/identity-action";
import * as validationActions from "../../../../actions/validation-action";
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIntl from '../../../CheckboxIntl';
import InputFieldIntl from '../../../InputFieldIntl';
import { InputRestrictors, StandardValidations as stdv } from '../../../../utils/validation/standard-validations';
import { withRouter, Prompt } from 'react-router-dom';
import attachValidator from '../../../../utils/validation/attach-validator';
import { set as lodashSet, get as lodashGet } from 'lodash';
import produce from "immer";
import OptOutPreferencesComponent from './OptOutPreferencesComponent';
import {PREF_SECTION} from '../../../../constants/preferences-constants';
import { countryUPSType } from '../../../../utils/ups-utils.js';
import { COUNTRY_UPS_CODE } from '../../../../constants/country-ups-type';
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';

class InvoicesSection extends OptOutPreferencesComponent {

    constructor(props) {
        super(props);
        const { preferences } = this.props;
        this.state = {
            invoiceReadyForView: preferences.notifications.emailSettings.triggers.invoiceReadyForView,
            notifyBeforeDueDate: preferences.notifications.emailSettings.triggers.notifyBeforeDueDate,
            onInvoiceDownloadReady: preferences.notifications.emailSettings.triggers.onInvoiceDownloadReady,
            onInvoiceDownloadFailed: preferences.notifications.emailSettings.triggers.onInvoiceDownloadFailed,
            daysPrior: preferences.notifications.emailSettings.triggers.daysPrior,
            pdfSecure: preferences?.invoiceSettings?.pdf?.secure,
            propChangeCounter: 0,
            isDomesticCountry: true
        }
        attachValidator.call(this);
    }

    componentWillMount(){
        const {selectedCountry} = this.props.auth.user;
        const {isDomesticCountry} = this.state;
        (countryUPSType(selectedCountry) === COUNTRY_UPS_CODE.DOMESTIC) ? 
        this.setState({isDomesticCountry: true}) : this.setState({isDomesticCountry: false});
    }

    componentDidMount(){
        this.props.setRef(this);
    }

    validateTriggers = () => {
        const result = this.validator.validateAll();
        if (result.messages.length > 0) return false;

        this.setState({
            propChangeCounter : 0
        }, () => {
            this.props.updateNotificationPreferences(PREF_SECTION.INVOICES);
        });        
    };

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            }),
            () => this.onPreferenceChange(inputName)
        );
    };

    toggleInvoices = () => {
        const {pdfSecure} = this.state;
        this.setState({
            invoiceReadyForView: pdfSecure ? true : false, 
            notifyBeforeDueDate: false,
            onInvoiceDownloadReady: false, 
            onInvoiceDownloadFailed: false
        },() => {
            this.onPreferenceChange();
        });
    }

    getPreferences() {
        let pref = super.getPreferences();   
        pref.daysPrior = pref.daysPrior ? Number(pref.daysPrior) : 0;
        return pref;  
    }

    render() {
        const { invoiceReadyForView, notifyBeforeDueDate, onInvoiceDownloadReady, onInvoiceDownloadFailed, daysPrior, isDomesticCountry,pdfSecure } = this.state;
        const { userRole, auth, intl } = this.props;
        return (
            <MDBRow className="col-md-6 mt-2">
                <MDBCol size="12" className="lg-6 mb-4">
                    <MDBCard className="card dashboard-widget-card min-mh-0 h-100  outline-card">
                        <MDBCardTitle tag="h2">
                            <FormattedMessage id="ups.preferences.invoices.title" />
                            <MDBCardBody>
                                <MDBCol size="12">
                                    <fieldset className="notify-align mt-4">
                                        <legend>
                                            <FormattedMessage id="ups.preferences.notification.notifyWhen" />
                                        </legend>
                                        <MDBRow className="invoices-align">
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"invoiceReadyForView"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.invoices.option-1" /></span>}
                                                    value={invoiceReadyForView}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({invoiceReadyForView: !invoiceReadyForView}, () => {this.onPreferenceChange("invoiceReadyForView") })}
                                                    checked={invoiceReadyForView}
                                                    disabled={pdfSecure}
                                                />
                                            </MDBCol>
                                            {( userRole !== 'VIEW_ONLY' && isDomesticCountry ) ? 
                                                <MDBCol size="12">
                                                    <div className="numeric-input-view-p1">
        
                                                        <CheckboxIntl aria-label={`${intl.formatMessage({id: 'ups-pref.invoices.days-prior.label'}, {daysPrior})}`}
                                                            name={"notifyBeforeDueDate"}
                                                            type={"checkbox"}
                                                            value={notifyBeforeDueDate}
                                                            containerClass="mb-0 mt-3 p-0"
                                                            onClick={() => this.setState({notifyBeforeDueDate: !notifyBeforeDueDate}, () => { this.onPreferenceChange("notifyBeforeDueDate") })}
                                                            checked={notifyBeforeDueDate}
                                                        />

                                                                <div className="pref-input-label-l1"> <FormattedMessage id="ups.preferences.notifications.invoices.option-2" /></div>
        
                                                                <InputFieldIntl
                                                                    className="pref-input"
                                                                    restrict={InputRestrictors.numeric}
                                                                    name={"daysPrior"}
                                                                    value={daysPrior}
                                                                    onChange={this.handleInput}
                                                                    validations={notifyBeforeDueDate ? [
                                                                        ['required', 'field.required'],
                                                                        ['minMaxValue', "field.minMaxValue", { min: 1, max: 5 }]
                                                                    ] : []}
                                                                    disabled={!notifyBeforeDueDate}
                                                                />
        
                                                        <div className="pref-input-label"><FormattedMessage id="ups.preferences.notifications.invoices.option-3" /></div>
                                                    </div>
                                                </MDBCol> : 

                                                <MDBCol size="12" className="mt-4"> </MDBCol>
                                            
                                            }
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onInvoiceDownloadReady"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.invoices.option-4" /></span>}
                                                    value={onInvoiceDownloadReady}
                                                    containerClass={userRole !== 'VIEW_ONLY' ? "mt-n2 p-0" : "mb-0 mt-3 p-0"}
                                                    onClick={() => this.setState({onInvoiceDownloadReady: !onInvoiceDownloadReady}, () => {this.onPreferenceChange("onInvoiceDownloadReady")})}
                                                    checked={onInvoiceDownloadReady}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onInvoiceDownloadFailed"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.invoices.option-5" /></span>}
                                                    value={onInvoiceDownloadFailed}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onInvoiceDownloadFailed: !onInvoiceDownloadFailed}, () => {this.onPreferenceChange("onInvoiceDownloadFailed")})}
                                                    checked={onInvoiceDownloadFailed}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <div className="section-separator my-4"></div>
                                            </MDBCol>
    
                                            <MDBCol size="12" className="section-align">
                                                <CheckboxIntl
                                                    name={"donotSendAnyInvoices"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.invoice-btn" /></span>}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={this.toggleInvoices}
                                                    checked={!((pdfSecure?false:invoiceReadyForView) || notifyBeforeDueDate || onInvoiceDownloadReady || onInvoiceDownloadFailed)}
                                                   
                                                />
                                            </MDBCol>
    
                                            {auth.user.adUserId ? null :
                                                <MDBCol size="12" className="mt-3 d-flex d-md-block flex-column">
                                                    <MDBBtnWrapper label={intl.formatMessage({id: "ups.preferences.notifications.btn-save"})}  aria-label={intl.formatMessage({id: "ups.preferences.invoices.save.button.label"})} color="primary" onClick={this.validateTriggers} >
                                                    </MDBBtnWrapper>
                                                </MDBCol>}
                                        </MDBRow>
                                    </fieldset>
                                </MDBCol>
                            </MDBCardBody>
                        </MDBCardTitle>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        auth: state.auth,
        merchants: state.auth.merchants,
        preferences: state.identity.preferences,
        userRole: state.auth.user.role
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvoicesSection)));