import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody  } from 'mdbreact';
import { FormattedMessage, FormattedDate, injectIntl  } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import UPSDocumentTable from "../../UPSDocumentTable";
import UPSDocumentSupportingTable from "../../UPSDocumentSupportingTable";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as accountApi from "../../../../api/account-api";
import {getKeyByBU, stripAccountNumber} from "../../../../utils/ups-utils";
import * as layoutActions from "../../../../actions/layout-action";
import * as errorActions from "../../../../actions/error-action";
import {generateStatementList, getDownloadEligibility} from "../../../../utils/invoice-utils";
import * as invoiceApi from "../../../../api/invoice-api";
import {get as lodashGet } from 'lodash'
import DownloadMessageModal from '../DownloadMessageModal/DownloadMessageModal';

class DownloadAllDocumentModal extends Component {

    constructor(props) {
        super(props);
        const {businessUnit, isPlanInvoice} = props;
        let heading = '';
        if (businessUnit === 'FRT') {
            heading = isPlanInvoice ? 'invoice.download-documents.statements-documents.label' : 'invoice.download-documents.pros-documents.label';
        } else if (businessUnit === 'SCS') {
            heading = isPlanInvoice ? 'invoice.download-documents.statements-documents.label' : 'invoice.download-documents.invoice-documents.label';
        } else {
            heading = isPlanInvoice ? 'invoice.download-documents.plan-invoice-documents.label' : 'invoice.download-documents.invoice-documents.label';
        }

        this.state = {
            loaded: false,
            heading,
            documents: []
        };
    }

    componentDidMount() {
        this.onInit();
    }

    onInit = async () => {
        const { invoice, intl, invoiceMetadata, isPlanInvoice, errorActions, layoutActions, countryCode, businessUnit, user, downloadType} = this.props;
        const accountNumber = isPlanInvoice ? invoice.planNumber : invoice.accountNumber;
        const isLocalCsv = invoice?.localCsvIr === "1" ? true : false;
        const downloadEligibility = getDownloadEligibility(businessUnit, this.props.invoice?.recordType, isLocalCsv);
        let documents = [];
        let errorCode;

        try {
            errorActions.clearErrors();
            layoutActions.showSpinner();
            if (!downloadEligibility.includes('xml')) {
                if (businessUnit === 'FRT' && !isPlanInvoice && downloadType !== 'inv') {
                    errorCode = 'invoice.supportingDoc.failed'
                    documents = await this.getSupportingDocs();
                }
                this.setState({
                    loaded: true,
                    documentList: generateStatementList(invoice, intl, invoiceMetadata, isPlanInvoice, businessUnit, false, downloadEligibility),
                    documents
                });
            } else {
                errorCode = 'invoice.alert-message.xml-error.label'
                const xmlRequest = {
                    accountType: isPlanInvoice ? 'Plan' : 'Account',
                    accountNumber,
                    countryCode,
                    businessUnit
                };

                // use xmlIr to check xml enrollment and in case this flag is not defined in invoices, then call iHub xml enrollment api
                let isXmlEnrolled = invoice.xmlIr == "1";

                // enrollment api gives error with CCR token, so bypassing this call if xmlIr is undefined in case of impersonated session
                if(invoice.xmlIr === undefined && !user.adUserId){
                    const xmlResponse = await accountApi.getXmlEnrollment(xmlRequest);
                    const {parsedBody} = xmlResponse;
                    if (parsedBody) {
                        const {mediaFormatStatus} = parsedBody;
                        isXmlEnrolled = mediaFormatStatus !== 'N';
                    }
                }

                this.setState({
                    loaded: true,
                    documentList: generateStatementList(invoice, intl, invoiceMetadata, isPlanInvoice, businessUnit, isXmlEnrolled, downloadEligibility)
                });
            }
        } catch (err) {
            this.setState({
                loaded: true,
                documentList: generateStatementList(invoice, intl, invoiceMetadata, isPlanInvoice, businessUnit, false, downloadEligibility),
            });
            errorActions.setErrors([errorCode]);
        } finally {
            layoutActions.hideSpinner();
        }
    };

    getSupportingDocs = async () => {
        const {invoice, envProps} = this.props;
        const { apiUrlPrefix, apiToken } = envProps;
        const requestBody = {
            proNumber: invoice.invoiceNumber,
            accountNumber: invoice.accountNumber,
            documentTypes: ['BOL', 'DR'],
            recordType: invoice.recordType
        };

        const res = await invoiceApi.getSupportingDocs(requestBody, apiUrlPrefix, apiToken);
        const parsedRes = res.parsedBody || {};
        const documents = parsedRes.documents || [];
        return documents;
    };

    render() {
        const { isOpen, toggleModal, backdrop, invoice, isPlanInvoice, errors, invoiceMetadata, errorActions, intl, downloadType, businessUnitKeys } = this.props;
        const { documentList, loaded, heading, documents } = this.state;
        const modalHeading = downloadType !== 'inv' ? 'invoice.download-documents.title' : businessUnitKeys('downloadInvoice')

        if (!isOpen || !loaded) return null;

        if ([403, 413].includes(errors?.status)) {
            return (
                <DownloadMessageModal
                    errorCode={errors?.status}
                    errorMessage={errors?.parsedBody?.msg}
                    invoiceMetadata={invoiceMetadata}
                    isOpen={true}
                    toggleParentModal={()=>{
                        errorActions.clearErrors();
                        toggleModal('downloadAllDocument')
                    }}
                />
            )
        }

        return (
            <ReactPortalModal isOpen={isOpen} an_label="download-all-document">
                <MDBModal id={'invoice-documents-modal'} isOpen={isOpen} toggle={() => toggleModal('downloadAllDocument')} size="lg" backdrop={backdrop} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader  tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('downloadAllDocument')}>
                        <FormattedMessage id={modalHeading} />
                    </MDBModalHeader>
                    <MDBModalBody>
                        {errors && errors.length > 0 &&
                            <div className="alert alert-error" role="alert">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                                    <title><FormattedMessage id="alert.label" /></title>
                                    <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                                </svg><span className="alert-msg"><FormattedMessage id={errors[0]} /></span>
                            </div>
                        }
                        <MDBRow>
                            {downloadType !== 'doc' && (
                                <MDBCol size={"12"} className={"mb-3 download-documents-modal"}>
                                    <h3 className={"document-sub-heading"}><FormattedMessage id={heading} /></h3>
                                    {documentList && <UPSDocumentTable documentList={documentList} isPlanInvoice={isPlanInvoice}/>}
                                </MDBCol>
                            )}

                            {downloadType !== 'inv' && !isPlanInvoice && (
                                <UPSDocumentSupportingTable invoice={invoice} documents={documents} />
                            )}
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn id={'download-documents-btn-cancel'} color="primary" onClick={() => toggleModal('downloadAllDocument')}>
                            <FormattedMessage id={'invoice.download-documents.btn-close.label'} />
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const {auth, config} = state;
    const countryCode = auth.user && auth.user.selectedCountry ? auth.user.selectedCountry : config.countries[0]
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        locale: state.settings.locale,
        countryCode,
        businessUnit: state.auth.user.selectedPaymentType,
        businessUnitKeys: getKeyByBU(ownProps.isPlanInvoice ? 'planInvoiceTable' : 'invoiceTable')(state.auth.user.selectedPaymentType),
        user: state.auth.user,
        envProps: state.envProps,
        errors: state.error.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        layoutActions: bindActionCreators(layoutActions, dispatch),
        errorActions: bindActionCreators(errorActions, dispatch),
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadAllDocumentModal)));
