import { fetchWrapper, fetchDownloadWrapper } from '../utils/utils';

export function getLatestMessages({ languageCode }, apiUrlPrefix, apiToken) {
    const params = new URLSearchParams();
    params.append('languageCode', languageCode);

    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/messages/latest?${params.toString()}`, 'GET', headers);
}

export function deleteUserMessage({ messageId }, apiUrlPrefix, apiToken) {
    const headers = {
        'x-api-token-sub': apiToken,
        'Content-Type': 'application/json'
    };

    return fetchWrapper(`${apiUrlPrefix}/messages/${messageId}`, 'DELETE', headers);
}