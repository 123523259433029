/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import * as invoiceApi from '../../../api/invoice-api';
import { useIntl } from "react-intl";
import DataTableV3 from '../../DataTableV3/DataTableV3';
import {formatDate} from "../../../utils/ups-utils";

const BatchReportsTable = ({ dataRows, businessUnit, apiUrlPrefix, apiToken, merchantId,caption }) => {
  const intl = useIntl()

  const datatableRows = dataRows?.map(({ lastModified, objectId }) => {
    return {
      createdData: formatDate(intl, lastModified),
      file: (
        <a role='button'tabIndex={0}
          onClick={() =>
            {invoiceApi.getAdvancedSearchBatchReportFile(objectId, apiUrlPrefix, apiToken);}
          }
        >
          {objectId}
        </a>
      )
    };
  });

  const datatableCols = [
    {
      label: intl.formatMessage({ id: "invoice.batchReports.field.createdData" }),
      field: 'createdData'
    },
    {
      label: intl.formatMessage({ id: "invoice.invoice-table.action" }),
      field: 'file',
      tdClassName: "reportTextLink",
    }
  ];

  return (
    <DataTableV3
    caption={caption}
      className="table table-bordered-simple table-sm table-hover"
      data={datatableRows}
      columns={datatableCols}
    />
  );
};

export default BatchReportsTable;
