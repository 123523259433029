import { stripAccountNumber } from './ups-utils';
import {
    PLAN, ACCOUNT, INVOICE_DATE, INVOICE_DUE_DATE, CHANNEL_CODE_EXT, EXTERNAL_CODE_BC, SCHEDULED_PAYMENT, ONE_TIME,
    PAYMENT_STATUS_RETURNED, CHARGEBACK_OPERATION, ACH_RETURNS, CHARGEBACK_REVERSAL_OPERATION, TRANSACTION_TYPE_CHARGEBACK,
    TRANSACTION_TYPE_CHARGEBACK_REVERSAL, AUTO_PAY, REAL_TIME
} from "../constants/payment-history";
import { formatDate, formatCurrency } from "./ups-utils";
import {getInvoiceTypeMessage} from './invoice-utils';
import moment from 'moment';
import { get as lodashGet } from 'lodash';

export function formatPaymentMethod(pm, intl, pmChannel) {
    if (!!pm.methodType || !!pm.accountNumber) {
        return (!!pm.methodType ? intl.formatMessage({ id: `payment-method.${pm.methodType}` }) : "")
            + (!!pm.accountNumber ? pm.accountNumber.slice(-8) : "");
    }
    return "";
}

export function extractAccountNumberFromInvoices(invoice) {
    return {
        ...(invoice.accountNumber ? {accountNumber: invoice.accountNumber}: {}),
        ...(invoice.planNumber ? {planNumber: invoice.planNumber}: {}),
        ...(invoice.businessUnit ? {paymentTypeCode: invoice.businessUnit}: {})
    }
}

export function returnAccountNumberType(data, intl) {
    if(Object.keys(data).length === 0){
        return "";
    }
    const returnObj = extractAccountNumberFromInvoices(data)

    if (!data.isPlan && returnObj.accountNumber && returnObj.planNumber) {
        return {
            planNumber: `${intl ? intl.formatMessage({ id: "payment-history.PLAN" }) : "Plan"} ${stripAccountNumber(returnObj.planNumber,returnObj.paymentTypeCode)}`,
            accountNumber: `${intl ? intl.formatMessage({ id: "payment-history.ACCOUNT" }) : "Account"} ${stripAccountNumber(returnObj.accountNumber,returnObj.paymentTypeCode)}`
        };
    } else if (!data.isPlan && returnObj.accountNumber) {
        return { accountNumber: `${intl ? intl.formatMessage({ id: "payment-history.ACCOUNT" }) : "Account"}  ${stripAccountNumber(returnObj.accountNumber,returnObj.paymentTypeCode)}` };
    }
    else if (returnObj.planNumber) {
        return { planNumber: `${intl ? intl.formatMessage({ id: "payment-history.PLAN" }) : "Plan"}  ${stripAccountNumber(returnObj.planNumber,returnObj.paymentTypeCode)}` };
    }
    return {};
}


export function returnInvoiceNumber({planNumber, accountNumber, invoiceNumber}) {
    if(planNumber && !accountNumber) return ""
    return invoiceNumber ?? "";
}

export function returnInvoiceType({invoiceType}) {
    return invoiceType ?? ""
}

export const returnSchedule = (intl) => ({channel, executionTypeCode}) => {
    if (channel === SCHEDULED_PAYMENT) {
        return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' })
    }
    if(channel === CHANNEL_CODE_EXT) {
        if (executionTypeCode === AUTO_PAY) return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' })
        if (executionTypeCode === REAL_TIME) return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })
    }
    return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })
}

export function returnInvoiceRelatedDates(intl, data, dateCase) {
    
    if(data.invoices)
    {
        data = data.invoices[0];
    }

    const { dueDate, invoiceDate } = data;
    switch (dateCase) {
        case INVOICE_DATE:
            return formatDate(intl, invoiceDate);
        case INVOICE_DUE_DATE:
            return lodashGet(data, "channel", "") !== CHANNEL_CODE_EXT && !!dueDate ? formatDate(intl, dueDate) : "";
        default:
            return '';
    }
}

export function returnCurrencyCode(data) {
    return lodashGet(data, "currencyCode", "");
}

export function combinePlanOrAccountNumberStr(data) {
    if (Object.keys(data).length === 0) {
        return "";
    }
    let str = "";
    if (data.planNumber) {
        str = `${data.planNumber}`;
    }
    if(data.accountNumber){
        str = `${str} ${data.accountNumber}`;
    }
    str = str.trim();
    return str;
}

export function getReferenceNumber(data) {
    const key = !!data.referenceId ? 'referenceId' : (!!data.upsReferenceNumber ? 'upsReferenceNumber' : null)
    if (key) { return data[key] }
    return ""
}

export function getChargebackTransactionType(data){
    if (data.operation === CHARGEBACK_OPERATION && data.channel === ACH_RETURNS) {
        return TRANSACTION_TYPE_CHARGEBACK;
    } else if (data.channel === ACH_RETURNS && data.operation === CHARGEBACK_REVERSAL_OPERATION) {
        return TRANSACTION_TYPE_CHARGEBACK_REVERSAL;
    }
}

export function getPlanAndAccountNumberFromInvoice({ planNumber, accountNumber }) {
    return {
        planNumber,
        accountNumber
    }
}

export function getScheduleValue(data, intl) {
    if (data) {
        if ( data.channel === SCHEDULED_PAYMENT) {
            return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' })
        } else {
            if(data.channel === CHANNEL_CODE_EXT)
            {
                if(data.executionTypeCode === AUTO_PAY)
                {
                    return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' })
                }
                else if (data.executionTypeCode === REAL_TIME)
                {
                    return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })
                }
            }
            return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })
        }
    } else 
        return '';
}

export const getSource = (intl, data) => {

    if(data.channel === CHANNEL_CODE_EXT){
        if(data.externalChannel === EXTERNAL_CODE_BC){
            return intl.formatMessage({id: 'view-payment-modal.source.billing_center'});
        }else{
            return intl.formatMessage({id: 'view-payment-modal.source.external'});
        }
    }else{
        return intl.formatMessage({id: 'view-payment-modal.source.billing_center'});
    }

}

export const getChargebackSource = (intl, data) => {
    if (data.channel === CHANNEL_CODE_EXT) {
        if (data.externalChannel === EXTERNAL_CODE_BC) {
            return intl.formatMessage({ id: 'view-payment-modal.source.billing_center' });
        } else {
            return intl.formatMessage({ id: 'view-payment-modal.source.external' });
        }
    } else {
        if (data.channel === ACH_RETURNS && data.operation === CHARGEBACK_REVERSAL_OPERATION) {
            return intl.formatMessage({ id: 'chargeback-history.history-table.transaction-type.chargeback-reversal' });
        }
        return intl.formatMessage({ id: 'view-payment-modal.source.billing_center' });
    }
}

export const getDataSearchForMulti = (invoices, intl) => {
    let value = intl.formatMessage({ id: "payment-history.multiple" });
    invoices.forEach(invoice => {
        value = value + ` ${combinePlanOrAccountNumberStr(returnAccountNumberType(invoice,intl))}`
    })
    value.trim();
    return value;
} 

export const getSearchableStringFromMultipleInvoices = (intl, invoices, invoiceMetadata, currencyDisplay="symbol") => {
    let acc = "";
    for(let invoice of invoices)
    {
        let invoiceType = returnInvoiceType(invoice);
        if (invoiceType !== "") {
            invoiceType = getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType)
        }

        const amount = invoice.amount;
        const currencyCode = returnCurrencyCode(invoice);
        const formattedAmount =  formatCurrency(intl, amount, currencyCode, currencyDisplay);

        acc += " " + returnInvoiceRelatedDates(intl, invoice, INVOICE_DATE) + " " +
        combinePlanOrAccountNumberStr(returnAccountNumberType(invoice, intl)) + " " +
        returnInvoiceNumber(invoice)  + " " +
        invoiceType + " " +
        returnInvoiceRelatedDates(intl, invoice, INVOICE_DUE_DATE) + " " +
        formattedAmount;
    }
    return acc;
}


export const sanitizeMasterFilter = (filter) => {
    let { paymentStatus, paymentMethod, channel, executionTypeCode, planOrAccountNumber, planOrAccountNumberCurrent } = filter;
    paymentStatus = paymentStatus.filter(item => !!item);
    channel = channel.filter(item => !!item);
    executionTypeCode = executionTypeCode.filter(item => !!item);
    paymentMethod = paymentMethod.filter(item => Object.keys(item).length > 0);
    planOrAccountNumber = planOrAccountNumber.filter(item => Object.keys(item).length > 0);
    return {paymentStatus, channel, executionTypeCode, paymentMethod, planOrAccountNumber, planOrAccountNumberCurrent};
}

export const isReturnedPayment = (paymentStatus) => {
    return paymentStatus === PAYMENT_STATUS_RETURNED
}

export const isPaymentMadeByCurrentUser = (loggedInExternalId, paymentData) => {
    return loggedInExternalId === (lodashGet(paymentData,'identity.externalId', null) ?? lodashGet(paymentData,'user.userId', null));
}