import React, { useEffect, useState }               from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader }   from 'mdbreact';
import ReactPortalModal                             from "../../ReactPortalModal";
import { MDBRow, MDBCol, MDBBtn }                   from 'mdbreact';
import { FormattedMessage, useIntl }                         from 'react-intl';
import CheckboxIntl                                 from '../../CheckboxIntl';
import classnames from "classnames";

export default function NotificationModal({ isOpen, closeModal, title, message, buttonTypes, dontShowAgain, separator, callback, size, alert}) {
    const [hideNotification, setNotificationState] = useState(false);
    const intl = useIntl();

    const handleCheckBox = (e) => {
        setNotificationState(e.target.checked);
    }

    const closeWindow = (e) => {
        closeModal({value: e, hideNotification : hideNotification});
    }

    const primaryAction = (e) => {
        if(!!callback) callback()
        closeWindow(e)
    }

    let buttons = [];

    if(buttonTypes){
        switch(buttonTypes){
            case BUTTON_TYPES.OK:
                buttons.push({title : "btn-ok.label", value: 'ok'});
                break;
            case BUTTON_TYPES.CONTINUE:
                buttons.push({title : "continue.label", value: 'ok'});
                break;
            case BUTTON_TYPES.OK_CANCEL:
                buttons.push({title : "btn-ok.label", value: 'ok'});
                buttons.push({title : "btn-cancel", value: 'cancel'});
                break;
            case BUTTON_TYPES.YES_NO:
                buttons.push({title : "yes.label", value: 'yes'});
                buttons.push({title : "no.label", value: 'no'});
                break;
        }
    }
    else{
        buttons.push({title : "btn-close", value: 'close'});
    }
    
    const modalHeading = intl.formatMessage({id:`${title ?? "notification-modal.title.notice"}`})
    return (
        <ReactPortalModal isOpen={isOpen} an_label="notification">
            <MDBModal isOpen={isOpen} size={size && ["sm", "md", "lg"].includes(size) ? size :"lg"} disableBackdrop={true} centered disableFocusTrap={false} labelledBy={modalHeading}>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: modalHeading })} toggle={() => closeWindow("cancel")}>
                    {modalHeading}
                </MDBModalHeader>                
                <MDBModalBody>
                    {separator && <hr className="separator" />}
                    <MDBRow>
                        <MDBCol size="12" className={classnames("text-center mt-3 d-flex d-md-block flex-column", {"alert alert-past-due":alert})} {...(alert && {role:"alert"})}>
                            {(typeof message === 'string') && <FormattedMessage  id={message} values={{newline: msg => <React.Fragment><br/>{msg}</React.Fragment>}} />}
                            {(typeof message === 'object') && message}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                            {buttons.map((item, index) => {
                                if(index % 2 == 0){
                                    return (
                                        <MDBBtn key={'primary-'+item.title} color="primary"  className={"m-0"} onClick={() => primaryAction(item.value)}>
                                            <FormattedMessage  id={item.title} />
                                        </MDBBtn>
                                    )
                                }
                                else{
                                    return (
                                        <MDBBtn key={'link-'+item.title} color={"text-link"} className={"m-0"} onClick={() => closeWindow(item.value)}>
                                            <FormattedMessage  id={item.title} />
                                        </MDBBtn>
                                    )
                                }
                            })}
                        </MDBCol>
                    </MDBRow>
                    {dontShowAgain && <MDBRow>
                        <MDBCol className="text-left mt-3 d-flex d-md-block flex-column notification-checkbox">
                            <CheckboxIntl name="do-not-show-again" id="do-not-show-again"
                                label={<FormattedMessage id="do-not-show-again" />}
                                value={hideNotification}
                                onChange={handleCheckBox}
                            />
                        </MDBCol>
                    </MDBRow>}
                </MDBModalBody>
            </MDBModal>
        </ReactPortalModal>
    );
}

export const BUTTON_TYPES = {
    OK: "OK",
    OK_CANCEL: "OK_CANCEL",
    YES_NO: "YES_NO",
    CONTINUE: "CONTINUE"
};