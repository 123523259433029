import React from 'react';
import {connect} from "react-redux";
import {injectIntl} from 'react-intl';

import ExportDataModal from '../../components/UPS/Modal/ExportDataModal';

import {getFormattedValue, getLabel} from '../../utils/invoice-utils';
import {customColumns, defaultColumnProps} from "./TableScreen/TableScreen-utils";
import memoize from "memoize-one";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../DataTableV3/DataTableV3FilterWrapper";
import {defaultButtonProps, exportData} from "../DataTableV3/DataTableV3-utils";

class AdvancedSearchResultsTable extends React.Component {

    constructor(props) {
        super(props);

        const { intl, invoiceLiterals, invoiceMetadata, merchant } = props;
        const currencyDisplay = merchant.currencyDisplay;
        this.formatValue = getFormattedValue.bind({ intl, invoiceLiterals, invoiceMetadata, currencyDisplay });
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });

        this.state = {
            exportDataModal: false,
            tableState: INITIAL_TABLE_STATE
        };
    }

    toggleModal = (modalName, data) => {
        this.setState({
            data,
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    createDatatableCols = memoize(() => {
        const { intl, invoiceLiterals, tableColumns, merchant } = this.props;
        const currencyDisplay = merchant.currencyDisplay;

        const columns = tableColumns.filter((c)=>(!c.hidden)).map(c => {
            return {
                ...defaultColumnProps(intl, c),
                ...(customColumns[c.type]?.(intl, c, this.formatValue, invoiceLiterals, null, currencyDisplay)||{})
            };
        });

        columns[0].responsivePriority = 1 //first column has highest responsive priority by default

        return columns;
    })

    render() {
        const { toggleModal } = this;
        const { exportDataModal, tableState } = this.state;
        const { screen, data, intl, tableColumns, defaultButtonProps } = this.props;
        const { filteredData } = tableState;

        const columns = this.createDatatableCols(intl.locale, tableColumns)

        return (
            <>
                <FilteredDataTableV3
                    name={`${screen}-tablev3`}
                    data={data}
                    columns={columns}
                    defaultSorting={columns[0].field}
                    buttons={[
                        {...defaultButtonProps.export, hide: (data.length === 0),action:()=>toggleModal('exportData')}
                    ]}
                    searchable
                    delayedSearch={data.length > 1000}
                    responsive
                    tableState={tableState}
                    tableStateAction={(action)=>this.setState(action)}
                />
                <ExportDataModal
                    isOpen={exportDataModal}
                    handleExport={(type)=>exportData(filteredData,columns,tableState,document.title,type)}
                    toggleModal={toggleModal}
                    an_label={`export-${screen}-data`}
                />
            </>
        );
    }
}

function mapStateToProps(state, {intl}) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        defaultButtonProps: defaultButtonProps(intl),
        merchant: state.config.sessionSelection.merchant
    }
}

export default injectIntl(connect(mapStateToProps)(AdvancedSearchResultsTable));