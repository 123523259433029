import React,  { Component } from 'react'
import {bindActionCreators} from "redux";
import {MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol} from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import InputFieldIntl from '../../../InputFieldIntl';
import produce from "immer";
import ManageUserAccessTable from '../../ManageUserAccessTable';
import PlayBackTable from '../../PlayBackTable';
import * as identityActions from "../../../../actions/identity-action";
import {connect} from "react-redux";
import * as validationActions from "../../../../actions/validation-action";
import attachValidator from "../../../../utils/validation/attach-validator";
import {getAccountAssignmentDelta} from "../../../../utils/ups-utils";
import {updateAdminIdentity, updateIdentity} from "../../../../api/identity-api";
import Spinner from "../../../Spinner";
import Error from "../../../Error";
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';
import Alert from "../../../Alert";
import {isEmpty} from "lodash";
class EditUserRoleModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalStep: 1,
            user: {
                id: this.props.data.id,
                userName: this.props.data.loginId,
                email: this.props.data.email,
                firstName: this.props.data.firstName,
                lastName: this.props.data.lastName,
                externalId: this.props.data.externalId,
                role: (this.props.data.role === "VIEW_ONLY") ? 'VIEW_PAY_DISPUTE': 'VIEW_ONLY'
            },
            loading: false,
            errors:[]
        };
        this.submitStep.bind(this)
        attachValidator.call(this)
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState(
            produce(draft => {
                draft.user[inputName] = inputValue
            })
        );
    };

    gotoPrevious = () => {
        this.setState(
            produce(draft => {
                draft.modalStep--
            })
        );
        let scrollTarget = document.getElementById('edit-user-modal');
        scrollTarget.scrollTop = 0;
    };

    gotoNext = () => {
        if (this.state.user.role === "ADMINISTRATOR") {
            this.setState(
                produce(draft => {
                    draft.modalStep = 3
                })
            );
        } else {
            this.setState(
                produce(draft => {
                    draft.modalStep++
                })
            );
        }

        let scrollTarget = document.getElementById('edit-user-modal');
        scrollTarget.scrollTop = 0;
    };

    submitStep(step) {
        let result = this.validator.validateAll()
        if(result.messages.length > 0) return;
        if(step === 2 || (step === 1 && this.state.user.role === "ADMINISTRATOR")){
            let {user} = this.state
            let {accounts, data, editUserCallback, permissions} = this.props
            const {id, externalId, role} = user
            let targetAccounts = []
            if(step === 2) {
                targetAccounts = Object.values(this.getSelectedAccounts())
            } else {
                targetAccounts = accounts //set account list to my list of assigned accounts if I'm creating an admin
            }
            let {addAccounts, removeAccounts} = getAccountAssignmentDelta(accounts, data.accounts, targetAccounts)
            this.setState({loading:true})
            const updateUserCall = permissions['user_admin_modify'] ? updateAdminIdentity : updateIdentity
            updateUserCall({id, externalId, role, addAccounts, removeAccounts})
                .then((resultUser)=> {
                    this.setState({playBackList: resultUser.accounts}, ()=>this.gotoNext())
                    editUserCallback(resultUser)
                })
                .catch(({errorCode})=>{
                    if(errorCode) this.setState({errors: [errorCode]})
                })
                .finally(()=>{
                    this.setState({loading:false})
                })
        }
        else if(step === 1){
            //re-query account list to keep up to date on loading the list
            this.gotoNext()
        }
    }


    render() {
        const { isOpen, toggleModal, backdrop, data, currentUser, intl} = this.props;

        const { modalStep, user, loading, errors } = this.state;

        let userRoleList = ["VIEW_ONLY","VIEW_PAY_DISPUTE","PLAN_MANAGER"];
        if(currentUser.permissions && currentUser.permissions['user_admin_modify']) userRoleList.push("ADMINISTRATOR")
        let newUserRoleList = userRoleList.filter(item => item !== data.role);
        let userRoleListComponents = [];


        for (var userRoleOption of newUserRoleList) {
            userRoleListComponents.push(
                <React.Fragment key={`userRoleOptionType_${userRoleOption}`}>
                    <MDBRow>
                        <MDBCol size={"12"} md={"4"}>
                            <InputFieldIntl
                                label={<FormattedMessage id={`ups.manage-user.role.${userRoleOption}.label`} />}
                                type="radio"
                                name="role"
                                id={`user.role.${userRoleOption}`}
                                labelClass={"mr-0"}
                                value={userRoleOption}
                                checked={user.role === userRoleOption}
                                onChange={this.handleInput}
                                containerClass={"mx-0 mb-3 pl-0"}
                            />
                        </MDBCol>
                        <MDBCol size={"12"} md={"8"}>
                            <FormattedMessage
                                id={`ups.manage-user.role.${userRoleOption}.description`}
                                tagName={'p'}
                                values={{
                                    style: msg => (
                                        <i>{msg}</i>
                                    )
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </React.Fragment>
            )
        }

        if (isOpen) {
            const modalHeading = "ups.edit-user-role.title";
            const modalLabel = "edit-user-role";
            return (
                <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                    <MDBModal id={"edit-user-modal"} isOpen={isOpen} toggle={() => toggleModal('editUserRole')} size="xl" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <Spinner isSpinning={loading} spinnerText={'loading.spinner.long'}/>
                        <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('editUserRole')}><FormattedMessage id={modalHeading}/></MDBModalHeader>
                        <MDBModalBody aria-live="assertive">
                            {errors?.length > 0 && <MDBRow><Error errors={errors}/></MDBRow>}
                            {modalStep === 1 &&
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <p><FormattedMessage id={"ups.edit-user-role.note-1"}/></p>
                                            <PlayBackTable key={"playback-edit-user-role-overview"} user={data} />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <fieldset required>
                                                <legend><FormattedMessage id={"ups.manage-user.role.legend"} />*</legend>
                                                {userRoleListComponents}
                                            </fieldset>
                                        </MDBCol>
                                    </MDBRow>
                                </React.Fragment>
                             }
                            {modalStep === 2 &&
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3 className={"mb-4"}><FormattedMessage id={"ups.add-new-user.account-access.title"}/></h3>
                                            <PlayBackTable key={"playback-edit-user-role-account-access"} user={user} />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <p className="ups-note-1 mt-0">*<span className="font-italic"><FormattedMessage id="ups.required-field.note" /></span></p>
                                        </MDBCol>
                                        <MDBCol size={"12"}>
                                            <div className={"datatable-blend-container"}>
                                                <div className={"datatable-blend-inner"}>
                                                    <h3><FormattedMessage id={"ups.add-new-user.plans-and-accounts.title"} /></h3>
                                                    <p><FormattedMessage id={"ups.add-new-user.plans-and-accounts.instructions-1"}/></p>
                                                </div>
                                            </div>
                                            <hr/>
                                            <ManageUserAccessTable
                                            caption= "ups.add-new-user.plans-and-accounts.title"
                                                name='edit-user-role-assign-account-list'
                                                accounts={this.props.accounts}
                                                key="edit-user-role-assign-account-list"
                                                source={"edit-user-role-assign-account-list"}
                                                selectable={true}
                                                registerGetSelection={(func)=>{
                                                    this.getSelectedAccounts = func
                                                }}
                                                preSelected={data.accounts}
                                                validations={[
                                                    (selectedList)=>{
                                                        if(Object.keys(selectedList).length === 0) return "account.assignment.required"
                                                        else return ""
                                                    }
                                                ]}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </React.Fragment>
                             }
                            {modalStep === 3 &&
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3 className={"mb-4"}><FormattedMessage id={"ups-edit-user-role-updated.title"}/></h3>
                                            <PlayBackTable key={"playback-edit-user-role-account-access"} user={user} />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3><FormattedMessage id="ups.add-new-user.confirmation.plans-and-accounts.line1" /></h3>
                                            {this.state.playBackList && this.state.playBackList.length > 0 && (
                                                <ManageUserAccessTable
                                                caption= "ups-edit-user-role-updated.title"
                                                    accounts={this.state.playBackList}
                                                    key="add-new-user-account-confirmation-list"
                                                    source={"add-new-user-account-confirmation-list"}
                                                    selectable={false}
                                                />
                                            )}
                                        </MDBCol>
                                    </MDBRow>
                                </React.Fragment>
                            }
                        </MDBModalBody>

                        <MDBModalFooter aria-live="polite">
                            {(modalStep === 2 && data.accounts && this.state.user.role !== "ADMINISTRATOR") && <MDBRow>
                                <MDBCol size={"12"}>
                                    <Alert type="warning" className="bank-direct-debit-only-warning mt-3 mb-4" label="unassign-accounts-cleanup-warning"/>
                                </MDBCol>
                            </MDBRow>}
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    {(modalStep !== 3 && modalStep > 1) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-back"})} color="secondary" onClick={this.gotoPrevious}></MDBBtnWrapper>}
                                    {(modalStep === 1) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-next"})} color="primary"  onClick={()=>this.submitStep(modalStep)}></MDBBtnWrapper>}
                                    {(modalStep === 2) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-update"})} color="primary"  onClick={()=>this.submitStep(modalStep)}></MDBBtnWrapper>}
                                    {(modalStep === 3) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-close"})} color="primary" onClick={() => toggleModal('editUserRole')}></MDBBtnWrapper>}
                                </MDBCol>
                            </MDBRow>
                            {modalStep !== 3 && (
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                    <MDBBtnWrapper label={intl.formatMessage({id: "btn-cancel"})} color="cancel" onClick={() => toggleModal('editUserRole')}></MDBBtnWrapper>
                                    </MDBCol>
                                </MDBRow>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        currentUser: state.auth.user,
        permissions: state.auth.user.permissions
    }
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditUserRoleModal));
