import React                                        from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader }   from 'mdbreact';
import ReactPortalModal                             from "../../ReactPortalModal";
import { MDBRow, MDBCol, MDBBtn }                   from 'mdbreact';
import { FormattedMessage,useIntl }                         from 'react-intl';

export default function WelcomeMessageModal({ isOpen, closeModal }) {
    const intl = useIntl();
    const modalHeading = "ups.welcome";
    return (
        <ReactPortalModal isOpen={isOpen} an_label="welcome-message">
            <MDBModal isOpen={isOpen} size="md" disableBackdrop={true} className="notification-modal" disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={closeModal}>
                    <FormattedMessage id={modalHeading} />
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12" className="text-left mt-3 d-md-block flex-column">
                            <FormattedMessage id="ups.welcome.message.1" 
                            values={
                                {strong: msg =>
                                    <strong>{msg}</strong>
                                }
                            }/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12" className="text-left mt-3 d-md-block flex-column">
                            <FormattedMessage id="ups.welcome.message.2" />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12" className="text-left mt-3 d-md-block flex-column">
                            <FormattedMessage id="ups.welcome.message.3" 
                                values={{
                                        newline: msg => (
                                            <i className="menu-icon fa fa-bars" />
                                        ),
                                        strong: msg => (
                                            <strong>{msg}</strong>
                                        )                                    
                                }}/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                                <MDBBtn color="primary" onClick={closeModal}>
                                    <FormattedMessage  id={"btn-close"} />
                                </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </ReactPortalModal>
    );
}