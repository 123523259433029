import React from 'react';
import * as R from 'ramda';
import {FormattedMessage} from "react-intl";

const asServerAlerts = R.compose
    ( R.map ( R.compose
                ( R.assoc('values', {})
                , R.assoc('field', 'server')
                , id => ({id})
                )
            )
    , R.uniq
    );

export default function Error ({errors = [], vFields = {}, vState = {}}) {
    const serverAlerts = asServerAlerts (errors);

    const clientAlerts = [];
    Object.keys(vFields).forEach(field => {
        const {messages} = vState[field];
        if (!messages) return;
        clientAlerts.push(...messages.map(id => ({
            field,
            id,
            values: vFields[field].getContext()
        })));
    });

    const alerts = serverAlerts.concat(clientAlerts);
    if (!alerts.length) return null;

    return (
        <div id="pageError" className="invalid-status component-level" aria-live="assertive">
            {alerts.map(a => <div role="alert" key={`${a.field}.${a.id}`}>
                <i aria-hidden={true} className="fa fa-exclamation-circle"></i>{" "}
                <FormattedMessage id={a.id} values={a.values}/>
            </div>)}
        </div>
    );
}