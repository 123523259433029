import moment from "moment";
import {convertCurrencyToNumber} from "../ups-utils.js";
import {luhnCheck} from "../payment-method-utils.js";
import {PM_TYPE_METADATA} from "../payment-utils.js";
import store from '../../index';
import * as R from 'ramda';
import validator from 'validator';

const postalCodes = require('postal-codes-js');

const defaultSupported = ['VISA', 'MC', 'VISA_DEBIT', 'MC_DEBIT', 'AMEX', 'DISC'];

const cardRegExValidators = R.compose(
    R.values,
    R.mapObjIndexed((meta, type) => [type, meta.accountNumberRegEx]),
    R.filter(R.has("accountNumberRegEx"))
) (PM_TYPE_METADATA);

const getCardValidations = supportedCards =>
    cardRegExValidators.filter(([c,]) =>
        supportedCards.includes(c));

const validateCard = cardNumber => R.compose
    ( R.any(R.identity)
    , R.map(([c,r]) => r.test(cardNumber))
    , getCardValidations
    );

const validCreditCard = (cardNumber, supportedCards = defaultSupported) => {
    const regexp = /^[0-9]+$/;

    if (!regexp.test(cardNumber)) {
        return false;
    }
    const len = cardNumber.length;
    if (len < 13 || len > 19) {
        return false;
    }
    if (!luhnCheck(cardNumber)) {
        return false;
    }

    // if (!cardNumber) {
    //   return false;
    // }

    // if (!validator.isCreditCard(cardNumber)) {
    //   return false;
    // }

    return validateCard (cardNumber) (supportedCards);
};

const containsCreditCardNumber = (str) =>{
    if (!str) {
        return false;
    }
    str = str.trim();
    var len = str.length;
    if (isNumeric(str)) {
        if (len === 16 || len === 15) {
            return validCreditCard(str);
        }
        // a numeric string with length less than 15 or greater than 16
        // digits is considered valid
        return false;
    }

    for (var i = 0; i < len; i++) {
        var c = str.charAt(i);
        if (!isDigit(c)) continue;
        if (len - i < 15) break;
        // look forward for 15-16 digits
        // look for the longest card numbers first, and work our way into
        // the shorter ones
        for (var j = 16; j >= 15; j--) {
            if (i + j > len) continue;
            var cn = str.substring(i, i + j);
            if (validCreditCard(cn)) return true;
        }
    }
    return false;
}

const charCheck = (value, checkFunc) => {
    if (!value) return true;
    let sz = value.length;
    for (let i = 0; i < sz; i++) {
        let ch = value.charAt(i);
        if (checkFunc(ch)) {
            return false;
        }
    }
    return true;
}


const InputRestrictors = {
    datemmddyyyy: (value) => {
        const regex = getInputRegex("restrict.date");
        return regexTest(value, regex);
    },
    numeric: (value) => /^\d*$/.test(value),
    alphanumeric: (value) => /^[a-zA-Z0-9]*$/.test(value),
    currency: (value) => {
        const regex = getInputRegex("restrict.currency");
        return regexTest(value, regex);
    },
    currencyLimited: (limit)=>(value)=>{
        //limit the number of digits before decimal place
        const regex = getInputRegex("restrict.currency", {limit});
        return regexTest(value, regex);
    },
    achAccountNumber: (value) => /^[a-zA-Z0-9 -\/]*$/.test(value)
}

const StandardValidations = {
    required: (value) => !!value || value === 0,
    alphanumeric: (value) => /^[a-zA-Z0-9]*$/.test(value),
    // TODO: Match the Java code for name check
    name: (value) => /^[a-zA-Z0-9]*$/.test(value),
    // TODO: Match the Java code for name check
    noInvalidCharacters: (value) => /^[a-zA-Z0-9.,# ]+$/.test(value),
    userIdCharacters: (value) => /^[a-zA-Z0-9\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]+$/.test(value),
    integer: (value) => /^[+-]?[0-9]+$/.test(value),
    positive: (value) => /^[0-9]+$/.test(value) && !/^0+$/.test(value),
    numeric: (value) => /^[+-]?[0-9]+([.][0-9]+)?$/.test(value),
    currency: (value) => {
        const regex = getInputRegex("validation.currency");
        return regexTest(value, regex);
    },
    nonzeroAmount: (value, args) => {
        const amount = convertCurrencyToNumber(value);
        if (!amount || amount <= 0) return false;
        if (args?.max && args.max < amount) return false;
        return true;
    },
    datemmddyyyy: (value) => moment(value, "MM/DD/YYYY", true).isValid(),
    daterange: (date, args) => {
        if(moment(date, "YYYY-MM-DD", true).isValid()){
            return !(
                (moment().startOf('day').diff(moment(date, "YYYY-MM-DD").startOf('day'), args.scale) > args.min) ||
                (moment().startOf('day').diff(moment(date, "YYYY-MM-DD").startOf('day'), args.scale) < args.max)
            )
        } else return false
    },
    email: validator.isEmail,
    minLength2: (value) => ((value || '').length >= 2),
    validCreditCard,
    notCreditCard: value => value ? !validator.isCreditCard(value) : true, //(value, supportedCards)=>!validCreditCard(value?.replace(/\D/g,''), supportedCards),
    validExpiryDate: (value) => (/^\d{2}\/\d{2}$/.test(value) && moment(value, 'MM/YY').isSameOrAfter(moment().startOf('month'))),
    validRoutingNumberUS: (value) => /^\d{9}$/.test(value),
    validRoutingNumberCA: (value) => /^\d{9}$/.test(value),
    validPhoneNumber10d: (value) => /^\d{10}$/.test(value),
    validBankAccount: (value) => /^\d{3,20}$/.test(value),
    validAch: (value) => (/^[a-zA-Z0-9 -\/]{3,17}$/.test(value) && (value?.replace(/[-\/]/g, '')!=='')),
    almostEverything: (value) => /^((?![{}\[\]<>"]).)*$/.test(value),
    zipCode: (value, args) => {
        let result = postalCodes.validate(args.selectedCountry, value) === true ? true : false;
        return result;
    },
    // zipCode: (value, {selectedCountry}) => validator.isPostalCode(value, selectedCountry),
    alphaNumericAndPunctuation: (value) => charCheck(value, (ch)=>(!isLetter(ch) && !isDigit(ch) && !isWhiteSpace(ch) && !isPunctuation(ch))),
    alphaAndPunctuation: (value) => charCheck(value, (ch)=>(!isLetter(ch) && !isWhiteSpace(ch) && !isPunctuation(ch))),
    notContainsCreditCardNumber: (value) => (!containsCreditCardNumber(value)),
    greaterOrEqual: (value, args) => (value >= args.checkValue),
    lessThanOrEqual: (value, args) => (value <= args.checkValue),
    maxlength: (value, args) => (!value || value.length <= args.max),
    minlength: (value, args) => (value && value.length >= args.min),
    minmaxlength: (value, args) => value && (value.length >= args.min && value.length <= args.max),
    cvv: (value) => !value || /^\d{3,4}$/.test(value),
    cvv3: (value) => !value || /^\d{3}$/.test(value),
    cvv4: (value) => !value || /^\d{4}$/.test(value),
    minMaxValue: (value, args) => value && (value >= args.min && value <= args.max),
    validInvoiceNumber: (value) => charCheck(value, (ch)=>(!isLetter(ch) && !isDigit(ch) && !isWhiteSpace(ch) && !isForwardSlash(ch))),
    regexp: (value, args) => {
        if (!value) {
            return true;
        }
        var regexps = args.regexps;
        if (!Array.isArray(regexps)) {
            regexps = [regexps];
        }
        let result = true;
        regexps.forEach(regexp => {
            var patt = new RegExp(regexp);
            result &= patt.test(value);
        })
        return result;
    },
    match: (value, args) => {
        return value && (args.value1 === value);
    },
    isIBAN: (value) => validator.isIBAN(value ?? ''),
    isBIC: (value) => validator.isBIC(value ?? '')
};

const TextFormatters = {
    creditCardExpiry: (val)=>{
        function limit(val, max) {
            if (val.length === 1 && val[0] > max[0]) {
                val = '0' + val;
            }

            if (val.length === 2) {
                if (Number(val) === 0) {
                    val = '01';

                    //this can happen when user paste number
                } else if (val > max) {
                    val = max;
                }
            }

            return val;
        }
        let month = limit(val.substring(0, 2), '12');
        let year = val.substring(2, 4);

        return month + (month.length === 2 ? '/' : '') + (year.length ? year : '');
    }
}

const isLetter = (c)=>{
    return c.toLowerCase() !== c.toUpperCase()
}

const isDigit = (c)=>{
    return !isNaN(parseInt(c, 10));
}

const isPunctuation = (c)=>{
    switch (c) {
        case ',':
        case '.':
        case '-':
        case '&':
        case '\xA9':
        case '\x27':
        case '\x60':
        case '\xB4':
            return true;
        default:
            return false;
    }
}

const isWhiteSpace = (c)=>{
    return /\s/.test(c);
}

const isForwardSlash = (c)=>{
    return /\//.test(c);
}

const isNumeric = (c)=>{
    return /^\d+$/.test(c);
}


const regexTest = (value, regex) => {
    return (new RegExp(regex)).test(value);
}

const funcCheck = (o, params) => (((typeof o) === 'function') ? o(params) : o)

const getInputRegex = (key, params = {}) => {
    const currentLocale = getCurrentLocale();
    let languageCode = currentLocale.split('-')[0];
    
    if (inputRegex[currentLocale]?.[key]){
        return funcCheck(inputRegex[currentLocale][key], params)
    }
    else if(inputRegex[languageCode]?.[key]){
        return funcCheck(inputRegex[languageCode][key], params)
    }
    else{
        /* return content for default locale */
        return funcCheck(inputRegex["en"][key], params)
    }
}

const getCurrentLocale = () => {
    const storeState = store.getState();
    if(storeState.settings.countryLocale  !== undefined){
        return storeState.settings.countryLocale;
    }
    else{
         return "en-US"
        }
}

const inputRegex = {
    "en": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\.\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "fr": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "de": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "fi": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "no": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "cs": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "da": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\-?\\d{0,2}\\-?\\d{0,4}$"
    },
    "de-CH": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\.\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "es-ES": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "fr-CH": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\.\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "fr-CA": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,4}\\-?\\d{0,2}\\-?\\d{0,2}$"
    },
    "hu": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,4}\\.?\\d{0,2}\\.?\\d{0,2}$"
    },
    "it-IT": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "it-CH": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\.\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\.?\\d{0,2}\\.?\\d{0,4}$"
    },
    "nl-BE": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "nl-NL": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\-?\\d{0,2}\\-?\\d{0,4}$"
    },
    "pl": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,4}\\-?\\d{0,2}\\-?\\d{0,2}$"
    },
    "sv" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,4}\\-?\\d{0,2}\\-?\\d{0,2}$"
    },
    "ja" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}?$`,
        "restrict.date": "^\\d{0,4}\\/?\\d{0,2}\\/?\\d{0,2}$"
    },
    "en-JP" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}?$`,
        "restrict.date": "^\\d{0,4}\\/?\\d{0,2}\\/?\\d{0,2}$"
    },
    "vi" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "en-VN" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "pt": {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}(\\,\\d{0,2})?$`,
        "restrict.date": "^\\d{0,2}\\/?\\d{0,2}\\/?\\d{0,4}$"
    },
    "ko" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}?$`,
        "restrict.date": "^\\d{0,4}\\/?\\d{0,2}\\/?\\d{0,2}$"
    },
    "en-KR" : {
        "restrict.currency": ({limit}) => `^\\d${limit ? `{0,${limit}}` : '*'}?$`,
        "restrict.date": "^\\d{0,4}\\/?\\d{0,2}\\/?\\d{0,2}$"
    } 
}

export {StandardValidations, InputRestrictors, TextFormatters};
