

import {isLocalhost} from "../utils/services/serviceWorker";
import store from '../index';

export const TEALIUM_EVENT = Object.freeze({
    VIEW: "view",
    LINK: "link"
});

const { REACT_APP_TEALIUM_SYNC_LINK, REACT_APP_TEALIUM_ASYNC_LINK } = process.env;

export function logTealiumAnalytics(event, payload) {
    if(window.upsNotifyViewChange && window.utag && typeof window.upsNotifyViewChange === 'function' ){
        setTimeout(() => {
            let analyticsObj = {
            "page_id" : window.location.pathname,
            "page_name" : document.title,
            "page_description" : document.title,
            "page_country_code": window.utag_data ? window.utag_data.page_country_code : "",
            "page_language": window.utag_data ? window.utag_data.page_language : "",
            "site_area" : "billing-center",
            "site_sub_area" : "billing-center",
            ...payload
            }
            analyticsObj.viewName = analyticsObj.page_id;
            window.utag.track(event, analyticsObj);
            window.upsNotifyViewChange(analyticsObj);
        }, 200);
    }
}

export function updateTealiumParams(payload) {
    if(window.utag_data){
        window.utag_data.page_country_code = payload.country;
        window.utag_data.page_language = payload.language;
    }
}

export function loadTealiumScripts() {
    const appState = store.getState();  
    // get script links from env props
    if(!isLocalhost && appState.envProps && appState.envProps.tealiumLink){        
    // Not loading tealium in local env as application will keep on reloading after giving consent to capture cookie due to secure nature.
        if(!document.getElementById('tealium-script-sync')){
            const script = document.createElement('script');
            script.id="tealium-script-sync";
            script.src = appState.envProps.tealiumSyncLink;
            document.body.appendChild(script);
        }

        if(!document.getElementById('tealium-script')){
            const script2 = document.createElement('script');
            script2.src = appState.envProps.tealiumLink;
            script2.type = "text/javascript";
            script2.id="tealium-script";
            script2.async = true;
            document.body.appendChild(script2);

            script2.onload = () => {
                logTealiumAnalytics(TEALIUM_EVENT.VIEW);
                // check if new func is available or not in the Tealium script - temporary logging
                if(!window.upsNotifyViewChange){
                    console.log("Tealium - upsNotifyViewChange not available.");
                }
            };
        }
    }
};