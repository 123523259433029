import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBInputGroup  } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import InputFieldIntl from '../../../InputFieldIntl';
import produce from "immer";
import { set as lodashSet } from 'lodash';

const optionToFileType = {
    CSV:'csv',
    EXCEL:'xlsx'
}

class ExportDataModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exportOptionType: 'CSV'
        };
    }

    handleInput = e => {
        const { name, value } = e.target;

        this.setState(
            produce(draft => {
                lodashSet(draft, name, value);
            })
        );
    };

    handleExport = e => {
        const {toggleModal, getDataTable, handleExport} = this.props;
        const {exportOptionType} = this.state;
        if(handleExport) handleExport(optionToFileType[exportOptionType])
        else getDataTable?.().buttons(`.btn-export-${exportOptionType.toLowerCase()}`).trigger();
        toggleModal('exportData');
    };


    render() {
        const { exportOptionType, } = this.state;
        const { isOpen, toggleModal, backdrop, an_label, handleExport, intl } = this.props;

        const exportOptionList = ["CSV","EXCEL"];
        const exportRadioComponents = [];
        const modalHeading = "invoice.export-table-data.title";

        if (!isOpen) return null;

        for (const exportOption of exportOptionList) {
            exportRadioComponents.push(
                <InputFieldIntl
                    key={exportOption}
                    label={<FormattedMessage id={`btn.export-option.${exportOption}`} />}
                    type="radio"
                    name="exportOptionType"
                    id={`exportOptionType_${exportOption}`}
                    labelClass={"mr-0"}
                    value={exportOption}
                    checked={exportOptionType === exportOption}
                    onChange={this.handleInput}
                    className={"form-radio-group-check"}
                    containerClass={"form-radio-group-item w-50 mx-0 mb-3 pl-0"}
                />
            )
        }

        return (
            <ReactPortalModal isOpen={isOpen} an_label={an_label ? an_label : "export-data"}>
                <MDBModal id={'invoice-export-modal'} isOpen={isOpen} toggle={() => toggleModal('exportData')} size="md" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('exportData')}>
                        <FormattedMessage id={modalHeading} />
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <p>
                                    <strong><FormattedMessage id={'invoice.export-table-data.download-prompt.label'} /></strong>
                                </p>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <div className={"form-radio-group mt-3"}>
                                    {exportRadioComponents}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn id={'invoice-export-btn-download'} color="primary" className={"block"}
                                onClick={this.handleExport}
                        >
                            <FormattedMessage id={'invoice.export-table-data.btn-download.label'} />
                        </MDBBtn>
                        <MDBBtn id={'invoice-export-btn-cancel'} color="cancel" onClick={() => toggleModal('exportData')}>
                            <FormattedMessage id={'invoice.export-table-data.btn-cancel.label'} />
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        );
    }
}

export default injectIntl(ExportDataModal);
