import {useState, useEffect} from 'react';
import * as R from 'ramda';

const setCookie = ({name, value, maxAge=1200, path='/'}) =>
  document.cookie = `${name}=${value}; path=${path}; max-age=${maxAge}; secure; samesite=none`;

export const removeCookie = ({name, path = '/'}) => document.cookie = `${name}=; path=${path}; max-age=0`;

const parseCookies = R.split('; ');
const parseCookiePair = R.split('=');

const getCookie = name => R.compose
  ( R.ifElse
      ( R.isNil
      , R.always({})
      , R.tryCatch
          ( ([,v]) => JSON.parse(atob(v))
          , R.always({})
          )
      )
  , R.find(([n,]) => n === name)
  // , R.tap(xs => console.log('cookie pairs ', xs))
  , R.map(parseCookiePair)
  , parseCookies
  ) (document.cookie);

const toCookieValue = R.compose
    ( s => btoa (s)
    , o => JSON.stringify(o)
    );

export function useCookieState (name) {
  const [cookieState, setCookieState] = useState(getCookie(name));

  useEffect(() => {
    if (!R.isEmpty(cookieState) && !R.isNil(cookieState)) {
      setCookie({name, value: toCookieValue(cookieState)});
    } else {
      removeCookie({name});
    }
  }, [cookieState]);

  return [cookieState, setCookieState];
}

export function clearExtraCookies(){
  //list of cookies that need to be preserved and exempted from deletion
  const expemtedCookies = [
    "CONSENTMGR",
    "utag_main"
  ];

  const cookies = document.cookie.split(';'); 

  for (var i = 0; i < cookies.length; i++){
      const cookieName = cookies[i].split("=")[0];
      if(expemtedCookies.indexOf(cookieName.trim()) > -1){
           continue;
      }

      //deleting cookies from ups.com domain to reduce header size as these are not required.
      document.cookie = cookieName + "=;domain=.ups.com;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  }
}


	