import React,  { Component } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import {stripAccountNumber} from "../../../../utils/ups-utils";
import DownloadMultipleInvoiceModal from '../DownloadMultipleInvoiceModal';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getDownloadRequestObject} from "../../../../utils/invoice-utils";
import * as invoiceApi from '../../../../api/invoice-api';
import * as INVOICE_TYPES from '../../../../constants/invoice-types';
import _ from 'lodash';

class UPSInvoiceDownloadMultipleModal extends Component {

    constructor(props) {
        super(props);

        this.invoiceDisplayData = this.convertMapToDisplayArray(props.data);
    }

    convertMapToDisplayArray = (data) => {
        const {businessUnit} = this.props;
        let arr = [];
        for (const [key, value] of data.entries()) {
            arr.push({
                invoiceNumber: value.invoiceNumber,
                accountNumber: stripAccountNumber(value.accountNumber, businessUnit),
                invoiceDate: moment(value.invoiceDate.split('T')[0]).toDate(),
                recordType: value.recordType
            });
        }
        return arr;
    }

    getStatementDetailsFromInvoice = (scsStatements, invoice) => {
        if (!scsStatements || ![INVOICE_TYPES.SCS_AIR, INVOICE_TYPES.SCS_BROKERAGE, INVOICE_TYPES.SCS_OCEAN].includes(invoice.invoiceType)) return {};

        const statement = scsStatements.find(s => s.planInvoiceNumber === invoice.planInvoiceNumber);

        if (!statement) return {};

        return {
            invoiceDate: statement.invoiceDate,
            invoiceNumber: statement.planInvoiceNumber,
            invoiceAmount: statement.invoiceAmount,
        };
    }

    getDownloadCriteria = async (downloadOptionType) => {
        const { data, intl, businessUnit, merchantId, user, envProps } = this.props;
        const { apiUrlPrefix, apiToken } = envProps;

        let scsStatements;
        if (downloadOptionType === 'pdf' && businessUnit === 'SCS') {
            const accountNumbers = _.uniq([...data.entries()].map(([k, i]) => i.accountNumber));
            const statementNumbers = _.uniq([...data.entries()].map(([k, i]) => i.planInvoiceNumber));

            // for scs pdf downloads, we need to fetch the statement and modify some of the fields to match the statement
            const response = await invoiceApi.getPlanInvoiceList({
                merchantId: merchantId,
                businessUnit: businessUnit,
                accountNumber: accountNumbers,
                planInvoiceNumber: statementNumbers
            }, apiUrlPrefix, apiToken);

            if (response.ok) {
                scsStatements = response.parsedBody;
            }
        }

        let arr = [];
        for (const [key, value] of data.entries()) {
            // cannot submit the same statement more than once per payload, otherwise ihub errors out so skip if its already present
            if (businessUnit === 'SCS' && arr.find(v => v.invoiceNumber === value.planInvoiceNumber)) continue;

            arr.push({
                ...getDownloadRequestObject({
                    ...value,
                    ...this.getStatementDetailsFromInvoice(scsStatements, value),
                }, businessUnit, false, intl),
                fileType: downloadOptionType,
            });
        }

        return { invoices: arr };
    }


    render() {
        const { isOpen, toggleModal, data } = this.props;

        if (isOpen) {
            return (
                <DownloadMultipleInvoiceModal
                    isOpen={isOpen}
                    toggleModal={toggleModal}
                    data={data}
                    modalName={'downloadMultipleInvoice'}
                    invoiceDisplayData={this.invoiceDisplayData}
                    getDownloadCriteria={this.getDownloadCriteria}
                    invoiceType={'invoice'}
                />
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        businessUnit: state.auth.user.selectedPaymentType,
        user: state.auth.user,
        envProps: state.envProps,
        merchantId: state.config?.sessionSelection?.merchant?.id,
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, null)(UPSInvoiceDownloadMultipleModal)));
