import React, { useEffect, useState }                  from "react";
import { useSelector}                                  from "react-redux";
import { countryData }                                 from "../constants/country-data";
import supportLinks, { adaVideoSupportedCountries }    from '../constants/support-links';
import Spinner                                         from '../components/Spinner';
import { FormattedMessage, useIntl }                   from 'react-intl';
import { Link }                                        from "react-router-dom";

function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if(typeof item === "object" && item !== null) {
        return true;
    }
    return false;
}

export default function UPSSupport() {
    const { supportBaseUrl, supportResource } = supportLinks;
    const { countryLocale } = useSelector(s => s.settings);
    const { selectedCountry, locale, selectedPaymentType } = useSelector(s => s.auth.user);
   
    const [ helpUrl, updateHelpUrl ] = useState();
    const [ isLoading, updateLoadingStatus ] = useState(true);
    const path = require('path');
    const intl = useIntl();
    useEffect(() => {
        const country = countryLocale?.split('-')[1] ?? selectedCountry;
        const countryInfo = countryData.find(x => x.code === country);
        
        if(countryInfo){
            const languageInfo = countryInfo.languages.find(x => countryLocale ? x.id === countryLocale : x.code === locale);
            if(languageInfo){
                const langCode = languageInfo.id;
                const langArr = langCode.split("-");
                const url = supportBaseUrl + path.join(langArr[1].toLowerCase(), langArr[0].toLowerCase(), supportResource) + `?businessUnit=${getBusinessUnit()}`;
                updateHelpUrl(url);
                updateLoadingStatus(true);
            }
        }
    }, [countryLocale, selectedPaymentType]);

    useEffect(() => {
        const handler = event => {
            const message = event.data;
            if ((!message || isJson(message))) {
                return;
            }
            let splitIndex = message.indexOf(':');
            if (splitIndex === -1) {
                return;
            }
            // console.log('Getting Message: ', message);
            let action = message.substring(0, splitIndex);
            let value = message.substring(splitIndex + 1).trim();

            if (action === 'frameHeight') {
                let supportIframe = document.getElementById("supportIframe");
                supportIframe.setAttribute('height', value);
            }
        };

        window.addEventListener("message", handler);
        return () => window.removeEventListener("message", handler)
    }, []);

    const onLoad = () => {
        updateLoadingStatus(false);
    }

    const getBusinessUnit = () => {
        if(selectedPaymentType === "FRT"){
            return "upsFreight";
        }
        else if(selectedPaymentType === "SCS"){
            return "upsSCS";
        }

        return "upsPackage";
    }

    return (
        <React.Fragment>
            <div role="main" className="container-fluid">
                <div className="row">
                    <div className="col"> 
                        <div className="card-body">
                            <div className="row">
                                    <div className="col-12">
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.add-account-breadcrumb-dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                <FormattedMessage id='ups.side-nav.support' />
                                                </li>
                                            </ol>
                                        </nav> 
                                    </div>
                                {isLoading &&
                                    <Spinner isSpinning={isLoading} />
                                }
                                {helpUrl ? <iframe id="supportIframe" src={helpUrl} width="100%" height="564" frameBorder="0" scrolling="no" onLoad={onLoad} allowFullScreen /> : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
