import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from 'react-router-dom';
import MetadataDateRangePicker from "../../InvoiceDetails/MetadataDateRangePicker";
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { get as lodashGet, isEmpty } from 'lodash';
import * as disputeActions from "../../../actions/dispute-action";
import { getStatusCodeLabel, isGRSDisputeSupported } from "../../../utils/dispute-utils";
import { formatDateRangeString } from "../../../utils/invoice-utils";
import {formatDate, getConfigByBU} from "../../../utils/ups-utils";
import Exception from '../../Exception/index'
import ExportDataModal from '../../../components/UPS/Modal/ExportDataModal';
import DisputeSearchCriteriaModal from '../../../components/UPS/Modal/DisputeSearchCriteriaModal';
import ColumnSettingsModal from "../Modal/ColumnSettingsModal";
import DisputeDetailsModal from '../Modal/DisputeDetailsModal';
import RequestServiceRefundModal from "../../UPS/Modal/RequestServiceRefundModal";
import moment from 'moment';
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import {defaultButtonProps, exportData, SORTED_DSC} from "../../DataTableV3/DataTableV3-utils";
import memoize from "memoize-one";
import {calendarIcon} from "../../../assets/images/icon/datatableIcons";
import {produce} from "immer";
import {constructDateFilter} from "./DisputeTable-utils";
import classnames from "classnames";
import {
    getSessionFilter,
    partiallyClearSessionFilter,
    setSessionFilter
} from "../../../utils/utils";

class DisputeTable extends Component {
    constructor(props) {
        super(props);
        const { businessUnit, country } = props;

        let sessionFilter = getSessionFilter('disputeTableFilter', businessUnit, country)
        if(sessionFilter?.dateFilters?.startDate) sessionFilter.dateFilters.startDate = moment(sessionFilter.dateFilters.startDate)
        if(sessionFilter?.dateFilters?.endDate) sessionFilter.dateFilters.endDate = moment(sessionFilter.dateFilters.endDate)

        this.state = {
            loaded: false,
            error: '',
            filter: sessionFilter ?? {businessUnit},
            isFilterEnabled: this.isFilterEnabled(sessionFilter ?? {}),
            exportDataModal: false,
            filterModal: false,
            columnSettingsModal: false,
            disputeDetailsModal: false,
            requestServiceRefundModal: false,
            calendarModal: false,
            calendarOptionType: sessionFilter?.dateFilters?.calendarOptionType  ?? 'AVAILABLE',
            selectedStartDate: sessionFilter?.dateFilters?.startDate ?? null,
            selectedEndDate: sessionFilter?.dateFilters?.endDate ?? null,
            dateFilters: [{
                field: "createdDate",
                msgId: "invoice.dispute.created-date.label",
                defaultOption: "AVAILABLE",
                options: ["TODAY", "LAST_7_DAYS", "LAST_30_DAYS", "LAST_60_DAYS", "LAST_90_DAYS", "AVAILABLE"]
            }],
            tableState: INITIAL_TABLE_STATE,
            disputeList: []
        };
    }

    componentDidMount() {
        this.getDisputes()
    }

    toggleModal = (modalName, data) => {
        this.setState({
            data,
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    isFilterEnabled = (filter = this.state.filter) => {
        const exists = (prop) => (!!filter?.[prop] && !isEmpty(filter?.[prop]))
        //calendar is not included in this consideration because it has its own filter indicator on the calendar button
        return exists('reasonCode') || exists('statusCode') || exists('createdBy')
    }

    getDateRangeString(){
        const {intl} = this.props;
        const {calendarOptionType, selectedStartDate, selectedEndDate} = this.state;
        return formatDateRangeString(intl, calendarOptionType, selectedStartDate ? moment(selectedStartDate) : '', selectedEndDate ? moment(selectedEndDate) : '')
    }

    getActionColumn = () => {
        const {intl} = this.props;
        return {
            field: 'actions',
            label: intl.formatMessage({ id: 'invoice.dispute.actions.label' }),
            className: 'text-center datatable-action-col prevent-toggle no-export',
            sortable: false,
            responsivePriority: 2,
            display: this.createDatatableRowActions
        }
    }

    createDatatableColsNew = memoize(() => {
        const { preferences } = this.props;
        const defaultColumns = this.getDefaultColumns()
        const savedColumns = lodashGet(preferences, `disputesColumnSettings.savedColumns`);
        const customizableCols = (savedColumns && !isEmpty(savedColumns)) ? savedColumns.map(data=>{
            return defaultColumns[(typeof data === 'object') ? data.name : data]
        }).filter((column)=>!!column) : Object.values(defaultColumns)
        return [
            ...customizableCols,
            this.getActionColumn()
        ];
    });

    getDefaultColumns = () => {
        const { intl, invoiceMetadata } = this.props;

        const reasonCodesMap = invoiceMetadata.disputeRules.reasonCodes;

        return {
            disputeId: {
                field: 'disputeId',
                label: intl.formatMessage({ id: 'invoice.dispute.dispute-id.label' }),
                responsivePriority: 1,
                display: (rowData) => <MDBBtn color={'datatable-text-link'} onClick={() => this.toggleModal('disputeDetails', rowData)}>{rowData.disputeId}</MDBBtn>,
                serialize: ({disputeId})=>disputeId
            },
            reference: {
                field: 'reference',
                label: intl.formatMessage({ id: 'invoice.dispute.reference.label' }),
                display: ({ request: { trackingNbr, pickupReqNbr, invoiceNbr }}) => trackingNbr ?? pickupReqNbr ?? invoiceNbr,
                serialize: ({ request: { trackingNbr, pickupReqNbr, invoiceNbr }}) => trackingNbr ?? pickupReqNbr ?? invoiceNbr,
                sortFunc: ({ request: { trackingNbr, pickupReqNbr, invoiceNbr }}) => trackingNbr ?? pickupReqNbr ?? invoiceNbr
            },
            createdBy: {
                field: 'createdBy',
                label: intl.formatMessage({ id: 'invoice.dispute.created-by.label' }),
                display: ({createdBy})=>  createdBy === "UPS Representative" ? intl.formatMessage({ id: 'invoice.dispute.createdby.label' }) : createdBy,
                hideable: true
            },
            createdDate: {
                field: 'createdDate',
                label: intl.formatMessage({ id: 'invoice.dispute.created-date.label' }),
                display: ({createdDate})=>formatDate(intl, createdDate),
                serialize: ({createdDate})=>formatDate(intl, createdDate),
                hideable: true
            },
            status: {
                field: 'status',
                label: intl.formatMessage({ id: 'invoice.dispute.status.label' }),
                display: ({ response: { reqStatus }}) => getStatusCodeLabel(intl)[reqStatus] ?? reqStatus ?? '',
                serialize: ({ response: { reqStatus }}) => getStatusCodeLabel(intl)[reqStatus] ?? reqStatus ?? '',
                sortFunc: ({ response: { reqStatus }}) => getStatusCodeLabel(intl)[reqStatus] ?? reqStatus ?? '',
                hideable: true
            },
            reasonCode: {
                field: 'reasonCode',
                label: intl.formatMessage({ id: 'invoice.dispute.reason-code.label' }),
                display: ({ request: { reasonCode }}) => intl.formatMessage({ id: reasonCodesMap[reasonCode]?.msgId ? reasonCodesMap[reasonCode].msgId : "invoice.disputes.invalid-reason-code.label"}),
                serialize: ({ request: { reasonCode }}) => intl.formatMessage({ id: reasonCodesMap[reasonCode]?.msgId ? reasonCodesMap[reasonCode].msgId : "invoice.disputes.invalid-reason-code.label"}),
                sortFunc: ({ request: { reasonCode }}) => intl.formatMessage({ id: reasonCodesMap[reasonCode]?.msgId ? reasonCodesMap[reasonCode].msgId : "invoice.disputes.invalid-reason-code.label"}),
                hideable: true
            },
            refundMethod: {
                field: 'refundMethod',
                label: intl.formatMessage({ id: 'invoice.dispute.refund-method.label' }),
                display: ({request: {countryCode = 'US'}}) => intl.formatMessage({
                    id: !(countryCode === 'US' || countryCode === 'CA') ? "invoice.disputes.issue-credit-note.label" : "invoice.disputes.credit-next-invoice.label"
                }),
                serialize: ({request: {countryCode = 'US'}}) => intl.formatMessage({
                    id: !(countryCode === 'US' || countryCode === 'CA') ? "invoice.disputes.issue-credit-note.label" : "invoice.disputes.credit-next-invoice.label"
                }),
                sortFunc: ({request: {countryCode = 'US'}}) => intl.formatMessage({
                    id: !(countryCode === 'US' || countryCode === 'CA') ? "invoice.disputes.issue-credit-note.label" : "invoice.disputes.credit-next-invoice.label"
                }),
                hideable: true
            },
        }
    };

    createDatatableRowActions = (data) => {
        const { intl } = this.props;
        const components = [];

        components.push(
            <MDBDropdownItem key={data.id} className={"text-left"} onClick={() => this.toggleModal('disputeDetails', data)}>
                {intl.formatMessage({ id: 'invoice.dispute.view-dispute.label'})}
            </MDBDropdownItem>
        );

        return (
            <MDBDropdown key={data.id}>
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" 
                        aria-label={intl.formatMessage({id: "more-actions.dispute-label"}, {disputeId: data.disputeId})}>
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    {components}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    };

    toggleCalendar = () => this.setState({calendarModal: !this.state.calendarModal});

    applyFilter = (newFilters) => {
        const {filter} = this.state;
        const {businessUnit, country} = this.props;
        let updatedFilters = {
            ...filter,
            ...newFilters,
        };
        const parsedDateFilter = updatedFilters.dateFilters ? constructDateFilter(updatedFilters.dateFilters) : {};
        updatedFilters = {
            ...updatedFilters,
            ...(parsedDateFilter ? { ...parsedDateFilter } : {})
        }
        setSessionFilter('disputeTableFilter', updatedFilters, businessUnit, country)
        this.setState({
            filter: updatedFilters,
            isFilterEnabled: this.isFilterEnabled(updatedFilters)
        }, this.getDisputes);
    };

    clearFilter = () => {
        const {businessUnit, country} = this.props;
        partiallyClearSessionFilter('disputeTableFilter', businessUnit, country, ['dateFilters', 'createdDate'])
        this.setState(produce(newState=>{
            newState.filter.reasonCode = undefined
            newState.filter.statusCode = undefined
            newState.filter.createdBy = undefined
            newState.isFilterEnabled = false
            newState.tableState.searchQuery = ''
        }), this.getDisputes)
    }

    getDisputes = () => {
        const { disputeActions } = this.props;
        disputeActions.getDisputes(this.state.filter, (list)=>this.setState({disputeList: list, loaded:true}));
    }

    handleCalendarChange = (filters) => {
        const { calendarOptionType = 'AVAILABLE', startDate = null, endDate = null } = filters.dateFilters;
        this.setState({
            selectedStartDate: startDate ? moment(startDate) : null,
            selectedEndDate: endDate ? moment(endDate) : null,
            calendarOptionType
        }, ()=>this.applyFilter(filters));
    }

    getCalendarButtonText(){
        return <><span><span id="datatable-date-selection" className="datatable-date-selection">{this.getDateRangeString()}</span> {calendarIcon}</span></>
    }

    hideServiceRefundButton = () => {
        const { sessionSelection, businessUnitConfigs } = this.props;
        const notRequestServiceRefundForBU = !businessUnitConfigs('hasRequestServiceRefund');
        const isGRSDisputeNotSupported = !isGRSDisputeSupported(sessionSelection);
        return notRequestServiceRefundForBU || isGRSDisputeNotSupported;
    }

    render() {
        const { toggleModal, toggleCalendar, handleCalendarChange } = this;
        const { intl, invoiceMetadata: { disputeRules }, preferences, defaultButtonProps, businessUnitConfigs, caption, user: { adUserId }  } = this.props;
        const { exportDataModal, filterModal, columnSettingsModal, requestServiceRefundModal, disputeDetailsModal, calendarModal } = this.state;
        const { data, filter, disputeList, error, loaded, calendarOptionType, dateFilters, tableState, isFilterEnabled } = this.state;
        const {filteredData} = tableState;

        if (!loaded) return null;
        if (error instanceof TypeError) return <Exception error={error} />

        const minDate = disputeRules.maxNumberOfMonths ? moment().add(-disputeRules.maxNumberOfMonths, 'months') : null;

        const savedColumns = lodashGet(preferences, `disputesColumnSettings.savedColumns`)
        //these memoized functions will only rerun on a render when the input arguments change
        const columns = this.createDatatableColsNew(intl.locale, savedColumns)

        return (
            <>
                <MetadataDateRangePicker
                    autoFocusEndDate={false}
                    calendarInfoPosition="after"
                    showDefaultInputIcon={true}
                    showDatePicker={toggleCalendar}
                    showCalendar={calendarModal}
                    intl={intl}
                    dateRangeString=""
                    dateFilterFields={dateFilters}
                    updateFiltersAndSearch={handleCalendarChange}
                    calendarOptionType={calendarOptionType}
                    updateDateRangeString={()=>{}}
                    existingFilters={filter}
                    minDate={minDate}
                />
                <FilteredDataTableV3
                    caption={caption}
                    data={disputeList}
                    columns={columns}
                    buttons={[
                        {type: "filter", action:()=>toggleModal('filter')},
                        {...defaultButtonProps.columnSettings, action:()=>toggleModal('columnSettings')},
                        {...defaultButtonProps.calendar, text: this.getCalendarButtonText(), action:toggleCalendar},
                        {type: "print"},
                        {...defaultButtonProps.export, action:()=>toggleModal('exportData')}
                    ]}
                    defaultSorting={'createdDate'}
                    defaultSortDirection={SORTED_DSC}
                    externalFilterActive={isFilterEnabled}
                    onClearFilter={this.clearFilter}
                    tableState={tableState}
                    tableStateAction={(action)=>this.setState(action)}
                    searchable
                    printable
                    responsive
                    rowHeaderField ={'disputeId'}
                />
                  {!this.hideServiceRefundButton() && !adUserId  &&<div className={classnames('col-sm-12 dt-pagination-col service-refund-container', this.state.tableState.filteredData?.length > 0 ? 'col-lg-4 ':'col-lg-5 service-refund-no-data')} >
                    <a className='service-refund-link' onClick={()=>toggleModal('requestServiceRefund',false)}><FormattedMessage id='invoice.dispute.link-request-refund.label' /></a>
                </div>}
                {filterModal &&
                    <DisputeSearchCriteriaModal
                        isOpen={filterModal}
                        searchCriteria={filter}
                        toggleModal={()=>toggleModal('filter')}
                        applyFilter={this.applyFilter}
                        clearFilter={this.clearFilter}
                    />
                }
                <ColumnSettingsModal
                    modalName={'columnSettings'}
                    isOpen={columnSettingsModal}
                    columns={this.getDefaultColumns}
                    toggleModal={toggleModal}
                    savedPreferenceType={'disputesColumnSettings'}
                />
                <ExportDataModal
                    isOpen={exportDataModal}
                    toggleModal={toggleModal}
                    handleExport={(type)=>exportData(filteredData,columns,tableState,document.title,type)}
                    an_label={`export-dispute-refunds-data`}
                />
                {disputeDetailsModal &&
                    <DisputeDetailsModal
                        isOpen={disputeDetailsModal}
                        data={data}
                        businessUnit={this.props.businessUnit}
                        toggleModal={toggleModal}
                    />
                }
                {requestServiceRefundModal &&
                    <RequestServiceRefundModal
                        isOpen={requestServiceRefundModal}
                        toggleModal={toggleModal}
                        onSuccess={this.getDisputes}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        country: state.config?.sessionSelection?.merchant?.countryCode,
        businessUnit: state.auth.user.selectedPaymentType,
        user: state.auth.user,
        defaultButtonProps: defaultButtonProps(ownProps.intl),
        businessUnitConfigs: getConfigByBU(state.auth.user.selectedPaymentType),
        sessionSelection: state.config.sessionSelection
    };
}

function mapDispatchToProps(dispatch) {
    return {
        disputeActions: bindActionCreators(disputeActions, dispatch),
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DisputeTable)));
