// import {useState, useEffect} from 'react';
// import * as R                from 'ramda';

import defaultSettings from '../constants/default-settings';

export function setAuthToken(item) {
    return localStorage.setItem(defaultSettings['local-storage-token-name'], item);
}

export function getAuthToken() {
    return localStorage.getItem(defaultSettings['local-storage-token-name']);
}

export function removeAuthToken() {
    return localStorage.removeItem(defaultSettings['local-storage-token-name']);
}

export function removeLocale() {
    return localStorage.removeItem('locale');
}

export function setIssuer(issuer) {
    return localStorage.setItem('issuer', issuer);
}

export function getIssuer() {
    let issuer = localStorage.getItem('issuer');
    if(!issuer || issuer === 'undefined') {
        issuer= defaultSettings['issuer'];
        setIssuer(issuer);
    }
    return issuer;
}

export function setLastLogin(date) {
    return localStorage.setItem('lastLogin', date);
}

export function getLastLogin() {
    let lastLogin = localStorage.getItem('lastLogin');
    if(!lastLogin || lastLogin === 'undefined') {
        lastLogin= defaultSettings['lastLogin'];
        setLastLogin(lastLogin);
    }
    return lastLogin;
}

export function removeLastLogin() {
    return localStorage.removeItem('lastLogin');
}

export function setTheme(theme) {
    return localStorage.setItem('theme', theme);
}


export function getTheme() {
    let theme = localStorage.getItem('theme');
    if(!theme || theme === 'undefined') {
        theme = 'default';
        setTheme('default');
    }
    return theme;
}

export function setCSRTokens(item) {
    return localStorage.setItem('csr_token', JSON.stringify(item));
}

export function getCSRTokens() {
    const ccrTokens = localStorage.getItem('csr_token');
    if(ccrTokens){
        return JSON.parse(ccrTokens)
    }
    return ccrTokens;
} 

export function setGlobalSettingAlertPref(value) {
    return localStorage.setItem('hideGlobalSettingAlert', value);
}

export function getGlobalSettingAlertPref() {
    return localStorage.getItem('hideGlobalSettingAlert');
}

/**
 * STATIC DEMO LOCALE START
 * for demo purposes, to still logged into the private routers
 * sets a user in the local storage so page refreshes don't take you back to the login page during sales demos
 */

export function setLocaleUser(user) {
    return localStorage.setItem('user', user);
}

export function getLocaleUser() {
    let user = localStorage.getItem('user');
    if(!user || user === 'undefined') {
        user='';
        setLocaleUser(user);
    }
    return user;
}

export function removeLocaleUser() {
    return localStorage.removeItem('user');
}

// const getLocalStorage = R.ifElse
//   ( R.anyPass([R.isNil, R.isEmpty])
//   , R.always({})
//   , s => JSON.parse(s)
//   )

// export function use3dSecureLocalStorage() {
//   const [state3dSecure, setState3dSecure] = useState(getLocalStorage(localStorage.getItem('3dSecure')));

//   useEffect(() => {
//     if (state3dSecure) {
//       localStorage.setItem('3dSecure', JSON.stringify(state3dSecure));
//     }
//   }, [state3dSecure]);

//   const clearState3dSecure = () => {
//     localStorage.removeItem('3dSecure');
//     setState3dSecure(null);
//   };

//   return [state3dSecure, setState3dSecure, clearState3dSecure];
// }