import { all, call, fork, put, take, select, takeEvery } from 'redux-saga/effects';
import * as authApi from '../api/auth-api';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from "../actions/layout-action";
import * as authActions from "../actions/auth-action";
import * as configActions from "../actions/config-action";
import * as identityActions from "../actions/identity-action";
import * as invoiceActions from "../actions/invoice-action";
import * as errorActions from "../actions/error-action";
import { mapRole } from "../utils/utils";
import * as settingActions from "../actions/setting-action";
import { checkPlanEnrolled } from "../utils/ups-utils";
import {getSessionSelection} from '../utils/config-utils';

const getApiUrlPrefix = (state) => state.envProps.apiUrlPrefix;
const getEnvProps = (state) => state.envProps;

function* ssoCallback(action) {
    const { data } = action;

    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix, simulateApi, apiToken } = envProps;

        const response = yield call(authApi.loginCallback, data, apiUrlPrefix, simulateApi, apiToken);
        const { ok, parsedBody } = response;
        // for success, redirect to home page

        if (ok && parsedBody) {
            if (parsedBody.newUser) {
                //set role in expected frontend format
                if (parsedBody.locale) {
                    yield put(settingActions.setLocale(parsedBody.locale));
                }
                yield put(authActions.loginNewUser(parsedBody));
            } else {
               if (parsedBody.role) parsedBody.role = mapRole(parsedBody.role.code);
               yield put(identityActions.setIdentityPreferences(parsedBody.preferences));
               yield put(settingActions.setLocale(parsedBody.locale));
               yield put(authActions.loginSuccess(parsedBody));

               // check if the account is enrolled in any plans, if so - add a flag to the identity
               const planEnrolled = checkPlanEnrolled(parsedBody.accounts);
               yield put (identityActions.setIdentityPlanEnrolled(planEnrolled));
               // retrieve merchants specific to user
               yield put(identityActions.getAccessibleMerchants());
            }
            // retrieve merchant config after login
            yield put(configActions.getAllMerchants());
            const {data: merchants} = yield take(actionTypes.GET_ALL_MERCHANTS_SUCCESS);
            yield put({type: 'SESSION_SELECTION_CHANGED', sessionSelection: getSessionSelection(parsedBody, merchants)});
            yield put(invoiceActions.getInvoiceMetadata());
        }

    } catch (error) {
        console.error(error);
        yield put(authActions.loginFailure(error));
        yield put(errorActions.setErrors(['page.unexpectedError']));

    } finally {
        yield put(settingActions.setStoreState(true));
        yield put(layoutActions.hideSpinner());
    }
}

function* extend(action) {
    const { data } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix, simulateApi } = envProps;

        const response = yield call(authApi.extendSession, data, apiUrlPrefix, simulateApi);
        const { ok, parsedBody } = response;
        // for success, redirect to home page
        if (ok && parsedBody) {
            //set role in expected frontend format
            yield put(settingActions.setSessionWidgetState());
            yield put(settingActions.setLocale(parsedBody.languageCode));
            // retrieve merchant config after login
        }

    } catch (error) {
        yield put(authActions.loginFailure(error));
        yield put(errorActions.setErrors([error.errorCode]));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* login(action) {
    const { data } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix, simulateApi, apiToken } = envProps;

        const response = yield call(authApi.login, data, apiUrlPrefix, simulateApi, apiToken);
        const { ok, parsedBody } = response;
        // for success, redirect to home page
        if (ok && parsedBody && !parsedBody.redirect) {
            if (parsedBody.newUser) {
                //set role in expected frontend format
                if (parsedBody.newUser.role) parsedBody.newUser.role = mapRole(parsedBody.newUser.role.code)
                if (parsedBody.locale)
                    yield put(settingActions.setLocale(parsedBody.locale));
                yield put(authActions.loginNewUser(parsedBody));
            } else {
                if(parsedBody.role) parsedBody.role = mapRole(parsedBody.role.code)
                yield put(identityActions.setIdentityPreferences(parsedBody.preferences));
                yield put(settingActions.setLocale(parsedBody.locale));
                yield put(authActions.loginSuccess(parsedBody));

                // check if the account is enrolled in any plans, if so - add a flag to the identity
                const planEnrolled = checkPlanEnrolled(parsedBody.accounts);
                yield put (identityActions.setIdentityPlanEnrolled(planEnrolled));
                // retrieve merchants specific to user
                yield put(identityActions.getAccessibleMerchants());

            }
            // retrieve merchant config after login
            yield put(configActions.getAllMerchants());
            yield take(actionTypes.GET_ALL_MERCHANTS_SUCCESS);
            yield put(invoiceActions.getInvoiceMetadata());
            yield put(settingActions.setLocale(parsedBody.languageCode));
        }
        else {
            yield put(authActions.redirectUser(parsedBody));


        }
    } catch (error) {
        yield put(authActions.loginFailure(error));
        yield put(errorActions.setErrors([error.errorCode]));
    } finally {
        yield put(settingActions.setStoreState(true));
        yield put(layoutActions.hideSpinner());
    }
}

function* csrLogin(action) {
    const { data } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix } = envProps;
        const response = yield call(authApi.csrLogin, data, apiUrlPrefix);
        const { ok, parsedBody } = response;

        if (ok && parsedBody) {
            if(parsedBody.externalId){
                yield call(loadUserDetails, parsedBody);
            }
        }
    } catch (error) {
        yield put(authActions.loginFailure(error));
        yield put(errorActions.setErrors([error.errorCode]));

    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* loadUserDetails(parsedBody) {

    if(parsedBody.role) parsedBody.role = mapRole(parsedBody.role.code)
    yield put(identityActions.setIdentityPreferences(parsedBody.preferences));
    yield put(settingActions.setLocale(parsedBody.locale));
    yield put(authActions.loginSuccess(parsedBody));

    // check if the account is enrolled in any plans, if so - add a flag to the identity
    const planEnrolled = checkPlanEnrolled(parsedBody.accounts);
    yield put (identityActions.setIdentityPlanEnrolled(planEnrolled));
    
    // retrieve merchants specific to user
    yield put(identityActions.getAccessibleMerchants());

    // retrieve merchant config after login
    yield put(configActions.getAllMerchants());
    const {data: merchants} = yield take(actionTypes.GET_ALL_MERCHANTS_SUCCESS);
    yield put({type: 'SESSION_SELECTION_CHANGED', sessionSelection: getSessionSelection(parsedBody, merchants)});

    yield put(invoiceActions.getInvoiceMetadata());    
    yield put(settingActions.setStoreState(true));
}

function* csrCallback(action) {
    const { data } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix } = envProps;
        const response = yield call(authApi.csrCallback, data, apiUrlPrefix);
        const { ok, parsedBody } = response;

        if (ok && parsedBody) {
            // yield call(setCSRTokens, parsedBody.ccrTokens);
            yield call(loadUserDetails, parsedBody);
        }

    } catch (error) {
        yield put(authActions.loginFailure(error));
        yield put(errorActions.setErrors([error.errorCode]));

    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* expireSessionCookie(action) {
    const { data } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix, simulateApi } = envProps;

        const response = yield call(authApi.logout, data, apiUrlPrefix, simulateApi);
        // const { ok, parsedBody } = response;
        // for success, redirect to home page

        if (response.ok) {
            if (data.isSessionTimedOut) {
                yield put(authActions.sessionTimeOut());
            } else {
                yield put(authActions.logout());
            }
        }

    } catch (error) {

        yield put(authActions.loginFailure(error));
        yield put(errorActions.setErrors(['page.unexpectedError']));

    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function getSuccessResponse(action) {
    return action.type.endsWith("SUCCESS");
}

function* setSessionWidgetState() {
    //setting widget state to extend timer for widget to open as session is extended
    yield put(settingActions.setSessionWidgetState());

}

export function* loginSaga() {
    yield takeEvery(actionTypes.LOGIN, login);
    yield takeEvery(actionTypes.LOGIN_NEW_USER, setSessionWidgetState);
    yield takeEvery(actionTypes.SSO_CALLBACK, ssoCallback);
    yield takeEvery(actionTypes.CSR_LOGIN, csrLogin);
    yield takeEvery(actionTypes.CSR_CALLBACK, csrCallback);
    yield takeEvery(actionTypes.EXTEND_SESSION, extend);
    yield takeEvery(actionTypes.EXPIRE_COOKIE, expireSessionCookie);
    yield takeEvery(getSuccessResponse, setSessionWidgetState);
}
export default function* authSaga() {
    yield all([
        fork(loginSaga)
    ]);
}
