import React, { Component } from 'react'
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import CheckboxIntl from '../../../components/CheckboxIntl';
import { bindActionCreators } from "redux";
import { isEqual as lodashIsEqual } from 'lodash';
import { connect } from "react-redux";
import * as invoiceActions from "../../../actions/invoice-action";
import * as identityActions from "../../../actions/identity-action";
import * as invoiceApi from "../../../api/invoice-api";
import SaveSearchSettingsModal from '../../../components/UPS/Modal/SaveSearchSettingsModal';
import { FormattedMessage, injectIntl } from 'react-intl';
import { countryCodeToMerchantId } from "../../../utils/utils"
import { padAccountNumbers, stripAccountNumber } from "../../../utils/ups-utils";
import { formatPaymentMethod, returnAccountNumberType, combinePlanOrAccountNumberStr } from "../../../utils/payment-history-util";
import { PAYMENT_METHOD, PAYMENT_STATUS, SCHEDULE, SCHEDULED_PAYMENT, ONE_TIME, AUTOMATIC, REAL_TIME, AUTO_PAY, CHANNEL_CODE_EXT } from "../../../constants/payment-history";
import MDBAutocompleteV5Wrapper from '../../MDBFix/MDBAutocompleteV5Wrapper';
import { MDBPopoverWrapper } from "../../MDBFix/MDBPopoverWrapper"; 
import ReactSelectAllGrouped from '../../ReactSelectAllGrouped';

const getAllOption = (intl) => {return {value: "*", msgId: "filter.multi.option.dropdown.all.selector", label: intl.formatMessage({id: "filter.multi.option.dropdown.all.selector"})}};

const multiSelectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    isMulti:true,
    closeMenuOnSelect:false,
    hideSelectedOptions:false,
    allowSelectAll:true,
    isSearchable:false,
    backspaceRemovesValue:false,
    placeholder:<FormattedMessage id={"react-select.select-your-filters"}/>
};

class UPSPaymentHistorySearchCriteria extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            planAccountNumberList: [],
            displayPlanAccountNumberList: [],
            scheduleList: [],
            paymentMethodList: [],
            paymentStatusList: [],
            displayAccount: ""
        };
    }

    onInit = async () => {
        const { paymentHistoryFilters, intl, masterFilters, selectedPaymentType, onUpdateTranslationsMapping } = this.props;
        const { schedule, paymentMethod, paymentStatus } = paymentHistoryFilters;
        const { planOrAccountNumberCurrent } = masterFilters;
        let localPlanAccountNumberList = [];
        let localDisplayPlanAccountNumberList = [];
        let localPaymentMethods = [];
        let localStatus = [];
        let localSchedule = [];
        let translationsMapping = {
            paymentStatus: {},
            schedule: {}
        }
        try {
            localPlanAccountNumberList = masterFilters.planOrAccountNumber || [];
            localDisplayPlanAccountNumberList = localPlanAccountNumberList.map(account => combinePlanOrAccountNumberStr(returnAccountNumberType({...account,businessUnit: selectedPaymentType}, intl)));
            localDisplayPlanAccountNumberList = localDisplayPlanAccountNumberList.filter(account => !!account);
            localPaymentMethods = masterFilters.paymentMethod || [];
            localStatus = masterFilters.paymentStatus ? masterFilters.paymentStatus.map(pmStatus => {
                let translatedStatus = intl.formatMessage({ id: `payment-history.search-field.status.option.${pmStatus}` });
                translationsMapping['paymentStatus'][translatedStatus] = pmStatus;
                return translatedStatus;
            }) : [];
            if (masterFilters.channel.includes(SCHEDULED_PAYMENT) || (!!masterFilters.executionTypeCode.includes(AUTO_PAY)  && masterFilters.channel.includes(CHANNEL_CODE_EXT))) {
                localSchedule.push(intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' }));
                translationsMapping['schedule'][intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' })] = AUTOMATIC;
            }
            if ((masterFilters.channel.includes(CHANNEL_CODE_EXT) 
                && !!masterFilters.executionTypeCode.includes(REAL_TIME)) 
                || (masterFilters.channel.filter(ch => ch !== SCHEDULED_PAYMENT && ch !== CHANNEL_CODE_EXT).length > 0)) {
                localSchedule.push(intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' }));
                translationsMapping['schedule'][intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })] = ONE_TIME;
            }
            let displayAccount = "";
            if (planOrAccountNumberCurrent && Object.keys(planOrAccountNumberCurrent).length > 0) {
                displayAccount = combinePlanOrAccountNumberStr(returnAccountNumberType({ businessUnit: selectedPaymentType, ...planOrAccountNumberCurrent }, intl));
            }
            onUpdateTranslationsMapping(translationsMapping);

            let sl = this.getMultiSelectList(localSchedule, schedule, SCHEDULE, translationsMapping['schedule']);
            sl.unshift(getAllOption(intl));

            let pml = this.getMultiSelectList(localPaymentMethods, paymentMethod, PAYMENT_METHOD, translationsMapping['paymentMethod'], true);
            pml.unshift(getAllOption(intl));

            let psl = this.getMultiSelectList(localStatus, paymentStatus, PAYMENT_STATUS, translationsMapping['paymentStatus']);
            psl.unshift(getAllOption(intl));

            this.setState({
                loaded: true,
                scheduleList: [{
                    msgId: "react-select.select-your-filters",
                    options: sl
                }],
                paymentMethodList: [{
                    msgId: "react-select.select-your-filters",
                    options: pml
                }],
                paymentStatusList: [{
                    msgId: "react-select.select-your-filters",
                    options: psl
                }],
                planAccountNumberList: localPlanAccountNumberList,
                displayPlanAccountNumberList: localDisplayPlanAccountNumberList,
                displayAccount,
                translationsMapping
            });
        } catch (err) {
            console.error(err);
        }
    }

    componentDidMount() {
        this.onInit()
    }

    toggleModal = (modalName, dataIndex = 0) => {
        this.setState({
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    isValidAccountNumber = (accountNumber) => {
        const { displayPlanAccountNumberList } = this.state;
        const index = displayPlanAccountNumberList.findIndex(item => item === accountNumber);
        return index;
    }

    handlePlanOrAccountNumber = value => {
        const { isValidAccountNumber } = this;
        const { planAccountNumberList, planOrAccountNumber, displayAccount } = this.state;

        const { onUpdatePlanFilters } = this.props;
        if (displayAccount !== value) {
            const index = value !== '' ? isValidAccountNumber(value) : -1;
            if (value === '' || index !== -1) {
                const accountDetailsObj = index !== -1 ? planAccountNumberList[index] : null;
                onUpdatePlanFilters('planOrAccountNumber', accountDetailsObj);
                this.setState({
                    planOrAccountNumber: accountDetailsObj,
                    displayAccount: value
                });
            }
        }
    };

    getMultiSelectList = (options, selected, displayFor, keyTranslations, stringifyValue) => {
        let optionList = [];
        const { intl } = this.props;
        const { returnDisplayText, isChecked } = this;
        options.forEach(function (obj) {
            const displayText = returnDisplayText(displayFor, obj, intl);
            optionList.push({
                checked: Array.isArray(selected) ? isChecked(obj, selected, displayFor, keyTranslations) : true,
                disabled: false,
                value: stringifyValue ? JSON.stringify(obj) : obj,
                text: displayText,
                label: displayText,
                msgId: displayText
            }); 
        })
        return optionList;
    };

    isChecked = (value, selected, displayFor, keyTranslations) => {
        switch (displayFor) {
            case PAYMENT_METHOD:
                for (let sel of selected) {
                    if (sel.methodType === value.methodType && sel.accountNumber === value.accountNumber) {
                        return true;
                    }
                }
                return false;
            case SCHEDULE:
            case PAYMENT_STATUS:
                return selected && Array.isArray(selected) &&
                    !!keyTranslations && (Object.keys(keyTranslations).length > 0 ? selected.includes(keyTranslations[value]) : selected.includes(value));
            default:
                return false;
        }
    }

    returnDisplayText = (displayFor, data, intl) => {
        switch (displayFor) {
            case PAYMENT_METHOD:
                return formatPaymentMethod(data, intl);
            case SCHEDULE:
                return data;
            case PAYMENT_STATUS:
                return data;
            default:
                return 'TODO';
        }
    }

    getPaymentStatusFilters = () => {
        const { masterFilters, paymentHistoryFilters, intl } = this.props;
        const paymentStatus = paymentHistoryFilters?.paymentStatus ? paymentHistoryFilters?.paymentStatus : masterFilters?.paymentStatus?.map(val => val);
        
        let filters =  paymentStatus.map(pmStatus => {
            let translatedStatus = intl.formatMessage({ id: `payment-history.search-field.status.option.${pmStatus}` });
            return {value: translatedStatus};
        });

        if(filters?.length === masterFilters?.paymentStatus?.length){
            filters.unshift({value: "*"});
        }

        return filters;
    }

    render() {
        const { modalMode, paymentHistoryFilters, planInvoiceFilters, onUpdatePlanFilters, intl, saveSearchCriteria, selectedPaymentType } = this.props;
        const { loaded, scheduleList, paymentMethodList, paymentStatusList, planAccountNumberList, displayPlanAccountNumberList, displayAccount } = this.state;
        const components = [];
        if (!loaded) return null;

        const scheduleFilters = paymentHistoryFilters?.schedule ? paymentHistoryFilters?.schedule.map(val => ({value: val})) : scheduleList[0].options.map(val => ({value: val.value}));
        const paymentMethodFilters = paymentHistoryFilters?.paymentMethod ? paymentHistoryFilters?.paymentMethod.map(val => ({value: JSON.stringify(val)})) : paymentMethodList[0].options.map(val => ({value: val.value}));
        const paymentStatusFilters = this.getPaymentStatusFilters();

        components.push(
            <MDBCol md={modalMode ? "12" : "4"} size={"12"} key={"field-plan-number"}>
                <div className={"autocomplete-form"}>
                    <label className="autocomplete-default-label" htmlFor="planNumber">
                        <FormattedMessage id={`payment-history.search-field.plan_or_account_number.label.${selectedPaymentType}`} />&nbsp;&nbsp;
                        <MDBPopoverWrapper
                            id="planNumber"
                            buttonLabel={`${intl.formatMessage({ id: `payment-history.search-field.plan_or_account_number.alt.${selectedPaymentType}` })} ${intl.formatMessage({ id: "ups.btn.help.label" })}`}
                            content={<><FormattedMessage id={`autocomplete.help`} values={{ label: <FormattedMessage id={`payment-history.search-field.plan_or_account_number.label.${selectedPaymentType}`} /> }} /></>}
                        />
                    </label>
                    <MDBAutocompleteV5Wrapper
                        name={"planOrAccountNumberSearch"}
                        data={displayPlanAccountNumberList}
                        clear
                        clearlabel={"ups.remove.btn.label"}                        
                        clearClass={"planOrAccountNumberSearch"}
                        id="planOrAccountNumberSearch"
                        heightItem={24}
                        maxLength={10}
                        noSuggestion={[intl.formatMessage({ id: "payment-history.search-field.plan_or_account_number.not-found.label" })]}
                        getValue={this.handlePlanOrAccountNumber}
                        valueDefault={displayAccount}
                        placeholder={intl.formatMessage({ id: `payment-history.search-field.plan_or_account_number.placeholder.${selectedPaymentType}` })}
                    />
                </div>
            </MDBCol>
        );

        components.push(
            <MDBCol md={modalMode ? "6" : "2"} size={"12"} key={"field-payment-history-schedule"}>
                <ReactSelectAllGrouped
                    label={intl.formatMessage({id:"payment-history.search-field.schedule.label"})}
                    inputId={"scheduleFilter"}
                    options={scheduleList}
                    field="schedule"
                    onChange={(selected) => {
                            const filteredValues = selected.filter(x => x.value !== "*").map(y => y.value);
                            onUpdatePlanFilters("schedule", filteredValues);
                        } 
                    }
                    value={scheduleFilters}
                    {...multiSelectProps}
                />
            </MDBCol>
        );

        components.push(
            <MDBCol md={modalMode ? "6" : "2"} size={"12"} key={"field-payment-history-payment-method"}>
                <ReactSelectAllGrouped
                    label={intl.formatMessage({id:"payment-history.search-field.payment-method.label"})}
                    inputId={"paymentMethodFilter"}
                    options={paymentMethodList}
                    field="paymentMethod"
                    onChange={(selected) => {
                            const filteredValues = selected.filter(x => x.value !== "*").map(y => JSON.parse(y.value));
                            onUpdatePlanFilters("paymentMethod", filteredValues);
                        } 
                    }
                    value={paymentMethodFilters}
                    {...multiSelectProps}
                />
            </MDBCol>
        );

        components.push(
            <MDBCol md={modalMode ? "6" : "2"} size={"12"} key={"field-payment-history-payment-status"}>
                <ReactSelectAllGrouped
                    label={intl.formatMessage({id:"payment-history.search-field.payment-status.label"})}
                    inputId={"paymentStatusFilter"}
                    options={paymentStatusList}
                    field="paymentStatus"
                    onChange={(selected) => {
                            const filteredValues = selected.filter(x => x.value !== "*").map(y => y.value);
                            onUpdatePlanFilters("paymentStatus", filteredValues);
                        } 
                    }
                    value={paymentStatusFilters}
                    {...multiSelectProps}
                />
            </MDBCol>
        );

        const modalComponents = [];
        // if (saveSearchSettingsModal) {
        //     modalComponents.push(
        //         <SaveSearchSettingsModal
        //             key={'save-search-criteria-modal'}
        //             isOpen={saveSearchSettingsModal}
        //             toggleModal={this.toggleModal}
        //         />
        //     );
        // }
        return (
            <React.Fragment>
                <MDBRow>
                    {components}
                </MDBRow>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <CheckboxIntl
                            key={"rememberPlanSearchCriteria"}
                            name={"rememberPlanSearchCriteria"}
                            id={"rememberPlanSearchCriteria"}
                            containerClass="mx-0 my-3 pl-0"
                            labelClass="mr-0"
                            checked={saveSearchCriteria}
                            onClick={({ target }) => onUpdatePlanFilters('saveSearchCriteria', target.checked)}
                            label={<FormattedMessage id={"invoice.search-field.invoice-save-search-settings"} />}
                        />
                    </MDBCol>
                </MDBRow>
                {!modalMode ?
                    <MDBRow className={"mt-3"}>
                        <MDBCol size={"12"}>
                            <MDBBtn color="secondary" className="m-0 mr-3"><FormattedMessage id={"reset.label"} /></MDBBtn>
                            <MDBBtn color="primary" className="m-0" onClick={this.handlePlanSearch(saveSearchCriteria)}><FormattedMessage id={"search.label"} /></MDBBtn>
                        </MDBCol>
                    </MDBRow> : null}
                {modalComponents}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        selectedPaymentType: state.auth.user.selectedPaymentType
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // invoiceActions: bindActionCreators(invoiceActions, dispatch),
        // identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSPaymentHistorySearchCriteria));