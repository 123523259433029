import React, { useMemo } from "react";
import {
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import * as R from 'ramda';

import { isPayPalMethod, formatPMDesc, isWalletExpiring, payMethodsToLabels } from '../../../utils/payment-utils';
import { maskNumber } from "../../../utils/utils";
import PaymentMethodCard, { serializePaymentMethodCard } from "../PaymentMethodCard";
import { useSelector } from 'react-redux';
import { FilterFuncMultiSelect } from "../Modal/FilterModal/FilterFuncs";
import FilteredDataTableV3 from "../../DataTableV3/DataTableV3FilterWrapper";

const CARD = "CARD", TABLE = "TABLE";
const cardIcon = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="datatable-ups-svg-icon" width="32"
    height="32" viewBox="0 0 32 32">
    <path d="M11.063 0h-8.688c-1.25 0-2.375 1.125-2.375 2.375v8.688c0 1.25 1.125 2.375 2.375 2.375h8.688c1.25 0 2.375-1.125
                        2.375-2.375v-8.688c0.188-1.25-0.938-2.375-2.375-2.375zM11.063 18.375h-8.688c-1.25 0-2.375 1.125-2.375 2.438v8.625c0
                        1.438 1.125 2.563 2.375 2.563h8.688c1.25 0 2.375-1.125 2.375-2.375v-8.688c0.188-1.438-0.938-2.563-2.375-2.563zM29.625
                        0h-8.688c-1.25 0-2.375 1.125-2.375 2.375v8.688c0 1.25 1.125 2.375 2.375 2.375h8.688c1.25 0 2.375-1.125
                        2.375-2.375v-8.688c0-1.25-1.125-2.375-2.375-2.375zM29.625 18.375h-8.688c-1.25 0-2.375 1.125-2.375 2.438v8.625c0 1.313
                        1.125 2.375 2.375 2.375h8.688c1.25 0 2.375-1.063 2.375-2.375v-8.625c0-1.313-1.125-2.438-2.375-2.438z">
    </path>
</svg>

const tableIcon = <svg className="datatable-ups-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16
                    16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0
                    0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0
                    0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0
                    0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"/>
</svg>

const format = intl => R.compose(
    intl.formatMessage,
    id => ({ id })
);

const showDetails = R.ifElse(
    isPayPalMethod,
    R.prop('email'),
    R.compose(maskNumber, R.prop('accountNumber'))
);

const filterFunc = (filter) => (data) => FilterFuncMultiSelect(filter, 'methodType', data, null, null, null, ({ methodType }) => payMethodsToLabels(methodType).id)
const sortFunc = (pm) => pm.default ? '0' : ('1' + showDetails(pm))

const getTableColumnsNew = (intl, onEdit, onRemove, onDefault, permissions, displayMode) => {
    const f = format(intl);

    let columns = []
    if (displayMode === CARD) columns = [
        {
            field: 'card',
            label: f('account-selection-table.main.empty'),
            display: (pm) => <PaymentMethodCard
                paymentMethod={pm}
                onEdit={() => onEdit(pm)}
                onRemove={() => onRemove(pm)}
                onDefault={() => onDefault(pm)}
                permissions={permissions}
                intl={intl}
            />,
            serialize: serializePaymentMethodCard(intl)
        }
    ]
    if (displayMode === TABLE) columns = (
        [
            {
                field: 'paymentMethodDetail',
                label: f('payment-method-table.payment-method-details'),
                display: (pm) => <>
                    {showDetails(pm)}
                    {(pm.ccExpired || isWalletExpiring(pm)) &&
                        <><span>&emsp;</span>
                            <span className='pm-card-expiration-text'>
                                {pm.ccExpired ? <FormattedMessage id={`card.expired`} /> : <FormattedMessage id={`card.expiring`} />}
                            </span></>
                    }
                {
                    pm.tokenPending &&
                    <><span>&emsp;</span>
                    <span className='pm-card-pending-token-text'>
                        <FormattedMessage id={`card.pending-token`}/>
                    </span></>
                }
                </>,
                serialize: (pm) => (`${showDetails(pm)} ${pm.ccExpired ? intl.formatMessage({ id: `card.expired` }) : ''} ${isWalletExpiring(pm) ? intl.formatMessage({ id: `card.expiring` }) : ''}`),
                sortFunc: showDetails,
                mobileCardPrimary: true
            },
            {
                field: 'methodType',
                label: f('payment-method-table.payment-method-type'),
                display: pm => formatPMDesc(intl)(pm.methodType),
                serialize: pm => formatPMDesc(intl)(pm.methodType),
                sortFunc: pm => formatPMDesc(intl)(pm.methodType)
            },
            {
                field: 'cardNickName',
                label: f('payment-method-table.nickname'),
                display: (pm) => <>
                {(pm.cardNickName) ? 
                      pm.cardNickName
                  :   <label className="sr-only" >{f("payment-method-table.no.nickname.assigned")}</label>}
               </>,
                serialize: R.propOr('', 'cardNickName'),
                sortFunc: R.propOr('', 'cardNickName')
            },
            {
                field: 'actions',
                label: f('payment-method-table.actions'),
                className: 'text-center datatable-action-col no-export',
                sortable: false,
                display: (pm) =>
                    <PMActions
                        paymentMethod={pm}
                        onEdit={() => onEdit(pm)}
                        onRemove={() => onRemove(pm)}
                        onDefault={() => onDefault(pm)}
                        permissions={permissions}
                        intl={intl}
                    />
            }
        ])
    return columns
}

function viewMorePMActionLabel(data, intl){
    let pmDetail = "";
    switch (data.categoryCode) {
        case "CC":
        case "DC":
            pmDetail = intl.formatMessage({ id: `payment.method.creditdebitgeneric.btn.label.ending.with`}, { cardType: formatPMDesc(intl)(data.methodType), accountNumber: data.accountNumber.slice(-4)})
            break;
        case "DD":
        case "BACS":
        case "SCB":
            pmDetail = intl.formatMessage({id: `payment.method.ECheckCard.Ending.with.bank-account-number.btn.label`},{accountNumber: data.accountNumber.slice(-4), cardType:formatPMDesc(intl)(data.methodType)})
            break;
        case "SEPA":
            pmDetail = intl.formatMessage({ id: `payment.method.SEPA.Ending.with.bank-account-number.btn.label` }, { accountNumber: data.accountNumber.slice(-4) })
            break;
        case "PAYPAL_ACCOUNT":
            pmDetail = intl.formatMessage({ id: `payment.method.PayPal.having.email.btn.label` }, { email: data.email })
            break;
        default:
            return null;
    }
    return intl.formatMessage({ id: `more-actions.payment-method-label` }, { paymentMethodDetail: pmDetail });
}

function PMActions({ paymentMethod: data, onEdit, onRemove, onDefault, permissions, intl }) {
    const actionLabel = viewMorePMActionLabel(data, intl);
    return <MDBDropdown>
        <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" aria-label={actionLabel}>
            <i className="fas fa-ellipsis-v"></i>
        </MDBDropdownToggle>

        <MDBDropdownMenu basic right>
            {permissions && permissions["wallet_edit"] && <MDBDropdownItem className="text-left" onClick={onEdit}>
                <FormattedMessage id='ups.btn.edit.label' />
            </MDBDropdownItem>}

            {permissions && permissions["wallet_add"] && !data.default && <MDBDropdownItem className="text-left" onClick={onDefault}>
                <FormattedMessage id='btn-make-default' />
            </MDBDropdownItem>}

            {permissions && permissions["wallet_remove"] && <MDBDropdownItem className="text-left" onClick={onRemove}>
                <FormattedMessage id='ups.btn.remove.label' />
            </MDBDropdownItem>}
        </MDBDropdownMenu>
    </MDBDropdown>;
}

export default function PaymentMethodTable({ wallet, toggleModal, displayMode, toggleDisplayMode, onEdit, onRemove, onDefault, tableState, tableAction, caption }) {
    const intl = useIntl();
    const { permissions } = useSelector(s => s.auth.user);

    const columns = useMemo(() => {
        return getTableColumnsNew(intl, onEdit, onRemove, onDefault, permissions, displayMode)
    }, [displayMode, intl])

    return <>
        <FilteredDataTableV3
        caption={caption}
            name="my-wallet-payment-method-table"
            data={wallet}
            columns={columns}
            className={classnames(
                "table",
                displayMode === CARD && "table-card table-card-wallet"
            )}
            buttons={[
                { text: <FormattedMessage id="btn-add-payment-method" />, hide: !permissions.wallet_add, action: () => toggleModal('addPaymentMethod') },
                { type: "filter", action: () => toggleModal('filterModal') },
                { type: "iconButton", icon: cardIcon, className: (classnames('btn-card-view', { 'active': (displayMode === CARD) })), action: () => toggleDisplayMode(CARD), title: intl.formatMessage({ id: 'datatable.btn-grid-view.tooltip' }) },
                { type: "iconButton", icon: tableIcon, className: (classnames('btn-table-view', { 'active': (displayMode === TABLE) })), action: () => toggleDisplayMode(TABLE), title: intl.formatMessage({ id: 'datatable.btn-list-view.tooltip' }) }
            ]}
            defaultSorting={sortFunc}
            itemsPerPage={[12, 24, 48, 96]}
            tableState={tableState}
            tableStateAction={tableAction}
            filterFunc={filterFunc(tableState.filter)}
            searchable
            {...(displayMode === TABLE && { mobileCard: true })}
            rowHeaderField={'paymentMethodDetail'}
        />
    </>;
}
