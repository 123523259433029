import { toUrlNvps, getFullUrl, callApiGet
       , callApiPut, overIf, callApiPost
       , callApiDelete}                     from '../utils/utils';
import {
    expiryMonthLens, expiryYearLens
    , paymentToBody, sortWallet
} from '../utils/payment-utils';
import * as R                               from 'ramda';


const callGetWalletPms = req =>
    callApiGet({url: getFullUrl(`/wallet${req ? '?' + toUrlNvps(req) : ''}`)});

const callUpdateWalletPm = pm =>
    callApiPut({ url: getFullUrl(`/wallet/paymethod/${pm.id}`)
               , body: paymentToBody (pm)
               });

const callAddWalletPm = pm =>
    callApiPost({ url: getFullUrl(`/wallet/paymethod`)
                , body: paymentToBody (pm)
                });

const callRemoveWalletPm = pm =>
    callApiDelete({ url: getFullUrl(`/wallet/paymethod/${pm.id}`)})

const callHandle3DSecureCallback = req =>
    callApiPost({ url: getFullUrl(`/wallet/paymethod/3d-secure-callback`)
                , body: req
                })

const parseWalletResp = R.compose
    ( R.omit(['nickName'])
    , pm => ({...pm, id: pm._id, cardNickName: pm.nickName})
    , overIf(expiryMonthLens, m => `${m}`.padStart(2, '0'))
    , overIf(expiryYearLens, y => `${y - 2000}`.padStart(2, '0'))
    );

export const getWalletPMs = R.compose 
    ( R.andThen(R.map(parseWalletResp))
    , callGetWalletPms
    );

export const getSortedWalletPMs = (pmCategories) => R.compose
    (   R.andThen(sortWallet(pmCategories)),
        getWalletPMs
    );

export const updateWalletPm = callUpdateWalletPm;
export const addWalletPm = callAddWalletPm;
export const removeWalletPm = callRemoveWalletPm;
export const complete3dsWallet = body =>
  callApiPost({
    url: getFullUrl('/wallet/complete3ds'),
    body
  });
export const handle3DSecureCallback = callHandle3DSecureCallback;
