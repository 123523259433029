import * as R from "ramda";
import {ITEMS_PER_PAGE_ALL, SORTED_ASC, SORTED_DSC, SORTED_NONE} from "./DataTableV3-utils";

const sortedDirectionLens = R.lensProp('sortedDirection');
const columnsLens = R.lensProp('columns');
const itemsPerPageLens = R.lensProp('itemsPerPage');
const itemsPerPageOptionsLens = R.lensProp('itemsPerPageOptions');
const pageNumberLens = R.lensProp('currentPage');
const pagedItemsLens = R.lensProp('pagedItems');
const collapsedColumnsLens = R.lensProp('collapsedColumns')
const collapsedColumnCountLens = R.lensProp('collapsedColumnCount');

//DatatableV3 state change functions

const nextSortOrder = R.ifElse
( R.propEq('sortedDirection', SORTED_ASC)
    , R.over(sortedDirectionLens, R.always(SORTED_DSC))
    , state => ({...state
        , sortedField: null
        , sortFunc: null
        , sortedDirection: SORTED_NONE
    })
);

const cycleSort = (sortField, sortFunc) => R.ifElse
( R.propEq('sortedField', sortField)
    , nextSortOrder
    , state => ({
        ...state,
        sortedField: sortField,
        sortFunc: sortFunc,
        sortedDirection: SORTED_ASC
    })
);

const toPagedItems = state =>
    ({ ...state
            , pagedItems: R.splitEvery (state.itemsPerPage) (state.pagedItems)
        }
    );
const addBaseSortKey = R.addIndex(R.map) ((d, i) => ({...d, baseSort: i}));

const getSortBy = (fieldName, sortFunc) => R.compose(
    R.when(R.is(String), R.toUpper),
    sortFunc ?? R.prop(fieldName)
)

const sortDataByField = (fieldName, sortFunc) => R.compose
( toPagedItems
    , R.when( R.propEq('sortedDirection', SORTED_DSC)
    , R.over(pagedItemsLens, R.reverse)
    )
    , R.ifElse
    ( R.propEq('sortedDirection', SORTED_NONE)
        , R.over(pagedItemsLens, R.sortBy(R.prop('baseSort')))
        , R.over(pagedItemsLens, R.sortBy(getSortBy(fieldName, sortFunc)))
    )
    , R.over(pagedItemsLens, R.flatten)
);

const sortData = (fieldName, sortFunc, sortedLabel) => R.compose
( R.set(R.lensProp('currentPage'), 1),
    R.set(R.lensProp('sortedLabel'), sortedLabel)
    , sortDataByField (fieldName, sortFunc)
    , cycleSort (fieldName, sortFunc)
);

const setItemsPerPageOptions = enableShowAll => R.compose
( R.when(R.always(enableShowAll), R.append({text: 'All', value: ITEMS_PER_PAGE_ALL}))
    , R.over(R.lensPath([0]), R.assoc('default', true))
    , R.map(n => ({text: `${n}`, value: n}))
);

const selectItemsPerPageOption = itemsPerPage => R.over(
    itemsPerPageOptionsLens,
    R.map(item => ({...item, selected: item.value === itemsPerPage}))
);

const setShowingRange = R.ifElse
( R.propEq('itemsPerPage', ITEMS_PER_PAGE_ALL)
    , state => ({...state, showingFrom: 1, showingTo: state.totalItems})
    , state => (lastPageItem =>
            ( { ...state
                    , showingFrom: lastPageItem - (R.dec(state.itemsPerPage))
                    , showingTo: lastPageItem > state.totalItems
                        ? state.totalItems : lastPageItem
                }
            )
    ) (state.itemsPerPage * state.currentPage)
);

const setPageNumber = pageNumber => R.compose
( setShowingRange
    , R.set(pageNumberLens, pageNumber)
);

const setData = ({data, columns, itemsPerPage, enableShowAll}) => R.compose
(
    state => sortDataByField(state.sortedField, state.sortFunc) (state)
    , R.when(
        ({currentPage, pagedItems}) => (currentPage > pagedItems.length)
        , state => setPageNumber (1) (state)
    )
    , setShowingRange
    , toPagedItems
    , R.when(
    R.propEq('collapsedColumnCount', undefined),
    R.set(collapsedColumnCountLens, 0)
    )
    , R.when(
    R.propEq('itemsPerPage', 0),
    R.set(itemsPerPageLens, R.pathOr(0, [0], itemsPerPage))
    )
    , R.when(
    R.compose(R.isEmpty, R.view(itemsPerPageOptionsLens)),
    R.set(itemsPerPageOptionsLens, setItemsPerPageOptions(enableShowAll)(itemsPerPage))
    )
    , R.set(columnsLens, columns)
    , state => ({...state, totalItems: state.pagedItems.length})
    , R.set(pagedItemsLens, addBaseSortKey (data))
);

const setItemsPerPage = itemsPerPage => R.compose
( selectItemsPerPageOption(itemsPerPage)
    , setPageNumber(1) // Go back to first page
    , toPagedItems
    , R.over(pagedItemsLens, R.flatten)
    , R.ifElse
    ( R.always(itemsPerPage === ITEMS_PER_PAGE_ALL)
        , state => R.set(itemsPerPageLens, state.totalItems) (state)
        , R.set(itemsPerPageLens, itemsPerPage)
    )
);

const nextPage = R.when
( ({currentPage, pagedItems}) => currentPage !== pagedItems.length
    , state => setPageNumber (R.inc(state.currentPage)) (state)
);

const backPage = R.when
( ({currentPage}) => currentPage !== 1
    , state => setPageNumber (R.dec(state.currentPage)) (state)
);

const toggleRowResponsive = (id) => (state) => {
    const result = state.expandedRows[id]
    return {
        ...state,
        expandedRows: {
            ...state.expandedRows,
            [id]:result?undefined:true
        }
    }
}

const modifyColumnCountResponsive = (type, columns) => R.compose(
    R.ifElse(
        R.propSatisfies(R.lt(0),'collapsedColumnCount'),
        (state)=>R.set(collapsedColumnsLens, getResponsiveColumns(state.collapsedColumnCount)(columns))(state),
        R.set(collapsedColumnsLens,null)
    ),
    (state)=>{
        return {
            ...state,
            collapsedColumnCount:
                (type === 'expand')
                    ? Math.max(state.collapsedColumnCount-1,0)
                    : Math.min(columns.length, state.collapsedColumnCount+1)
        }
    }
)

const getResponsiveColumns = (collapsedColumnCount) => R.compose(
    R.addIndex(R.reduce)(
        (acc, cur, index)=>((index < collapsedColumnCount) ? acc.add(cur.field) : acc), new Set()
    ),
    R.reverse,
    R.sortBy(R.propOr(99,'responsivePriority'))
)

export default function tableReducer (state, action) {
    switch (action.type) {
        case 'set_data': return setData (action) (state)
        case 'set_items_per_page': return setItemsPerPage (action.itemsPerPage) (state)
        case 'sort_data': return sortData(action.sortField, action.sortFunc, action.sortedLabel) (state)
        case 'set_page': return setPageNumber (action.pageNumber) (state)
        case 'next_page': return nextPage (state)
        case 'back_page': return backPage (state)
        case 'collapse_columns': return modifyColumnCountResponsive('collapse',action.columns)(state)
        case 'expand_columns': return modifyColumnCountResponsive('expand', action.columns)(state)
        case 'toggle_responsive_row': return toggleRowResponsive (action.id) (state)
        default: return state
    }
}