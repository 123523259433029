import React, { Component } from 'react'
import * as identityActions from "../../../../actions/identity-action";
import * as paymentHistoryActions from "../../../../actions/payment-history-action";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import UPSPaymentHistorySearchCriteria from "../../UPSPaymentHistorySearchCriteria";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {sanitizeMasterFilter} from "../../../../utils/payment-history-util";

const INITIAL_STATE = {
    planOrAccountNumber: null,
    schedule: null,
    paymentMethod: null,
    paymentStatus: null,
    saveSearchCriteria: false,
}

class UPSPaymentHistorySearchCriteriaModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...INITIAL_STATE,
            translationsMapping: {},
            ...this.props.paymentHistoryFilters
        };
    }

    onUpdateTranslationsMapping = (translationsMapping) => {
        this.setState({
            translationsMapping
        })
    }

    runFilter = (clearFilters = false) => {
        const { runFilter, clearFilter } = this.props;
        const { toggleModal, paymentHistoryFilters, identityActions } = this.props;
        const { planOrAccountNumber, schedule, paymentMethod, paymentStatus, saveSearchCriteria, masterFilters } = this.state;

        const paymentHistorySearchCriteria = clearFilters
            ? {...paymentHistoryFilters, ...INITIAL_STATE}
            : {
                ...paymentHistoryFilters,
                planOrAccountNumber,
                schedule : Array.isArray(schedule) && schedule.length > 0 ? schedule : null,
                paymentMethod : Array.isArray(paymentMethod) && paymentMethod.length > 0 && paymentMethod.length !== masterFilters['paymentMethod'].length ? paymentMethod : null,
                paymentStatus: Array.isArray(paymentStatus) && paymentStatus.length > 0 && paymentStatus.length !== masterFilters['paymentStatus'].length ? paymentStatus : null,
                channel : Array.isArray(schedule) && schedule.length > 0 ? masterFilters['channel'] : null
            };

        this.setState({
            ...paymentHistorySearchCriteria
        }, ()=>{
            if (saveSearchCriteria) identityActions.saveIdentityPreferences({ paymentHistorySearchCriteria: paymentHistorySearchCriteria }, 'tableSearchCriteria');
            if(clearFilters){
                clearFilter()
                toggleModal()
            } else {
                runFilter({ ...paymentHistorySearchCriteria });
                toggleModal()
            }
        })
    };

    getEnglishTranslatedValues = (key, values) => {
        const { translationsMapping } = this.state;
        if (!translationsMapping.hasOwnProperty(key)) {
            return values;
        }
        let newValues;
        if (Array.isArray(values)) {
            newValues = [];
            for (let val of values) {
                newValues.push(translationsMapping[key][val]);
            }
        }
        return newValues;
    }

    getValueToApplyInFilter = (key, selectedValues) => {
        switch (key) {
            case 'paymentStatus':
            case 'paymentMethod':
                if (this.state.masterFilters[key].length === selectedValues.length) {
                    return null;
                }
                return this.getEnglishTranslatedValues(key, selectedValues);
            case 'schedule':
                if (selectedValues.length === 2 || (this.state.masterFilters['channel'].length === selectedValues.length)) {
                    return null;
                }
                return this.getEnglishTranslatedValues(key, selectedValues);
        }
    }

    onUpdatePlanFilters = (key, value) => {
        if (value && key !== 'planOrAccountNumber' && key !== 'saveSearchCriteria') {
            value = this.getValueToApplyInFilter(key, value)
        }
        this.setState({
            [key]: value
        });
    }

    getMasterFilterOptions = () => {
        const { paymentHistoryActions, paymentHistoryFilters } = this.props;
        paymentHistoryActions.getPaymentHistoryFilters(paymentHistoryFilters,(masterFilters) => {
            // filter the unexpected data
            masterFilters = sanitizeMasterFilter(masterFilters);
            this.setState({masterFilters})
        })
    }

    componentDidMount(){
        this.getMasterFilterOptions()
    }

    render() {
        const { isOpen, toggleModal, backdrop, handleStartOver, data, intl } = this.props;
        const { masterFilters } = this.state;
        const modalHeading = "invoice.search-title";
        if (isOpen && masterFilters) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="ups-payment-history-criteria">
                    <MDBModal id={'plan-search-modal'} isOpen={isOpen} toggle={() => toggleModal('paymentHistorySearchCriteria')} size="lg" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('paymentHistorySearchCriteria')} ><FormattedMessage id={modalHeading} /></MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <UPSPaymentHistorySearchCriteria
                                        modalMode={true}
                                        key={'payment-history-search-criteria'}
                                        onUpdatePlanFilters={this.onUpdatePlanFilters}
                                        onUpdateTranslationsMapping={this.onUpdateTranslationsMapping}
                                        paymentHistoryFilters={this.state}
                                        saveSearchCriteria={this.state.saveSearchCriteria}
                                        masterFilters={masterFilters}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn id={'plan-search-btn-search'} color="primary" onClick={() => this.runFilter()}><FormattedMessage id={"search.label"} /></MDBBtn>
                            <MDBBtn id={'plan-search-btn-cancel'} color="cancel" onClick={() => this.runFilter(true)}><FormattedMessage id={"clear.label"} /></MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        // layout: state.layout,
        // planInvoiceFilters: state.invoice.planInvoiceFilters,
        preferences: state.identity.preferences
    }
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        paymentHistoryActions: bindActionCreators(paymentHistoryActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(UPSPaymentHistorySearchCriteriaModal)));
