import React, { Component } from 'react';
import {MDBInput, MDBBtn, MDBCardBody} from "mdbreact";
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from "classnames";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ValidationErrors from "../ValidationErrors";
import attachValidator from "../../utils/validation/attach-validator.js"
import * as validationActions from "../../actions/validation-action";
import * as errorActions from "../../actions/error-action";
import { isArray } from 'highcharts';

// Modified MDBInput to support placeholder with react-intl
// Because <FormattedMessage /> is a component which cannot be placed in placeholder which expects a raw String.
class InputFieldIntl extends Component{
    constructor(props){
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        if(this.props.validations) attachValidator.call(this)
    }
    componentDidMount(){
        let {name, validations} = this.props;
        if(validations) this.validator.register(name)
    }
    componentWillUnmount(){
        let {name, validations, errorActions, id,errors} = this.props;
        if(validations) this.validator.deregister(name)
        //clear backend error
        if(isArray(errors)){
            errorActions.clearFieldError({id})
        }
    }
    onChange(e){
        let {onChange, validations, name, restrict, id, errorActions, errors} = this.props;
        let val = e.target.value
        if(restrict){
            //restrict enterable characters to those that return true from the restrict() function
            if(restrict(val)) {
                this.oldValue = val;
            } else {
                e.target.value = this.oldValue;
                e.preventDefault();
                e.stopPropagation();
                return;
            }
        }
        if(validations){
            clearTimeout(this.validationTimer)
            this.validationTimer = setTimeout(() => {
                this.validator.validate(name, val)
            }, 500)
        }
        //clear backend error

        if(isArray(errors)){
            errorActions.clearFieldError({id})
        }else{
            errorActions.clearErrors()
        }
        if(onChange) onChange(e)
    }
    onBlur(e){
        let {onBlur, validations, name, id, errorActions, errors} = this.props;
        let val = e.target.value
        if(validations){
            clearTimeout(this.validationTimer)
            this.validator.validate(name, val)
        }
        //clear backend error

        if(isArray(errors)){
            errorActions.clearFieldError({id})
        }else{
            errorActions.clearErrors()
        }
        if(onBlur) onBlur(e)
    }
    render(){
        const {
            name, id, type, label, labelRightElement, hint, value, onChange, onBlur, hideErrorMessages = false,
            iconClass, icon, onIconClick, tabIndex, containerClass, className, intl, labelClass,
            errors, vFields, ownVState, vState, validations, validationActions, errorActions, restrict, required, hideErrorSign, ...rest
        } = this.props;
        const error = errors && isArray(errors) && errors.find(e => e.split('.')[0] === name );
        let messages = [];
        //backend error
        if(error) messages.push(error)
        //frontend validation errors
        if(ownVState && ownVState.messages) messages = messages.concat(ownVState.messages)
        // if type is radio or checkbox, please ensure they each have a unique ID
        return (
                <React.Fragment>
                    <MDBInput
                        name={name}
                        id={id || name}
                        type={type || 'text'}
                        label={<span>
                            <span>{label}</span>
                            <span>{required && (typeof label !== 'undefined') && "*"}</span>
                            {labelRightElement && <span>&nbsp;&nbsp;{labelRightElement}</span>}
                        </span>}
                        hint={hint ? intl.formatMessage({ id: hint }) : ''}
                        value={value || ''}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        containerClass={containerClass}
                        className={classNames(className,'form-control', {"is-invalid": !hideErrorSign && (messages.length > 0)})}
                        tabIndex={tabIndex}
                        labelClass={labelClass || ''}
                        icon={icon}
                        aria-required={!!required}
                        {...(iconClass && {iconClass: classNames(iconClass,{"d-none": !value && iconClass === "input-clear-icon"})})}
                        {...(onIconClick && {onIconClick: () => onIconClick(id)})}
                        {...rest}
                    />
                    {!hideErrorMessages && <ValidationErrors name={name} messages={messages} values={{label}} intl={intl}/>}
                </React.Fragment>
        )
    }
}
function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState:state.validation.vState[ownprops.name]
        }: {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InputFieldIntl));
