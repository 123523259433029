import React, { useEffect }                  from "react";
import { useHistory}                         from "react-router-dom";
export default function DefaultPage() {
    const history = useHistory();
    useEffect(() => {
        history.replace({ pathname: '/login' });
    }, []);
    return (
        <React.Fragment>
        </React.Fragment>
    );
}