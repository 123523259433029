import React, {useState, useEffect, useReducer, useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import InputFieldIntl from "../../components/InputFieldIntl/index";
import FilterList from "./components/FilterList/FilterList";
import { isEmpty, get as lodashGet } from "lodash";
import * as actionTypes from "../../constants/action-types";
import BatchRequestModal from "./components/BatchRequestModal/BatchRequestModal";
import BatchSearchLinksModal from "../../components/UPS/Modal/BatchSearchLinksModal";
import * as identityApi from '../../api/identity-api';

import { getFormattedValue } from "../../utils/invoice-utils";
import AdvancedSearchResultsTable from "../../components/InvoiceDetails/AdvancedSearchResultsTable";
import Spinner from "../../components/Spinner";

const initialState = ({searchTypes, resultSet}) => ({
  loaded: false,
  queryParams: {},
  filtersCache: {},
  isFiltersClean: false,
  isBatchRequestModalOpen: false,
  isFetchAPI: false,
  invoiceLiterals: undefined,
  selectedSearchType: searchTypes[0],
  screen: `AdvancedSearch-${searchTypes[0].resultSet}`,
  data: undefined,
  fetchDataCollection: {},
  tableColumns: resultSet[searchTypes[0].resultSet].fields,
  isDataCollectionFetched: false,
  originalAccountsList: [],
})

const makeAction = (name, value) => ({type: 'single', field: name, value})
const makeMultiAction = (valueObj) => ({type: 'multi', value: valueObj})

const reducer = (state, {type, field, value})=> {
  switch (type) {
    case 'single':
      return {...state, [field]: value}
    case 'multi':
      return {...state, ...value}
  }
}

const mappingFilterField = (filterMap, {fetchDataCollection, selectedSearchType: {filters: filterFields}}, invoiceMetadata) => {
  return filterFields
      .map((filter) => {
        let filterObj = filterMap.get(filter.filterField);
        const overflow = lodashGet(filter, "overflow", true);
        return {
          ...filter,
          ...filterObj,
          ...(overflow ? { overflow } : {}),
        };
      })
      .map((filter) => {
        return {
          ...filter,
          ...(filter.metadata ? { name: filter.metadata } : {}),
          filter: {
            type: filter.type,
            ...((filter?.options || filter?.metadata || filter?.fetchdata) && {
              options: getOptions(filter, fetchDataCollection, invoiceMetadata),
            }),
            ...(filter.delimiter ? { delimiter: filter.delimiter } : {}),
            ...(filter.hasValueCode ? { hasValueCode: filter.hasValueCode } : {}),
          },
        };
      });
};

const getOptions = (filter, fetchDataCollection, invoiceMetadata) => {
  if (filter.metadata) {
    return Object.entries(invoiceMetadata[filter.metadata]).map(([key, value]) => {
      return { value: key, msgId: value.msgId };
    });
  }
  if (filter.fetchdata) {
    return fetchDataCollection[filter.fetchdata];
  }
  return filter?.options?.map((option) => {
    return option.valueCode ? { ...option, value: option.valueCode } : option;
  });
};

const cleanFilters = ({filtersCache, selectedSearchType}, stateDispatch) => {
  stateDispatch(makeMultiAction({
    filtersCache: { ...filtersCache, [selectedSearchType.msgId]:undefined },
    isFiltersClean: true,
    queryParams: {},
    data: undefined
  }))

};

const updateQueryParams = (queryParams, stateDispatch, params) => {
  const paramsCopy = { ...queryParams, ...params };
  for (let [key, value] of Object.entries(paramsCopy)) {
    if (isEmpty(value)) {
      delete paramsCopy[key];
    }
  }
  stateDispatch(makeMultiAction({
      queryParams: paramsCopy,
      isDataCollectionFetched: false,
      isFiltersClean: false
  }))
};

const fetchInvoices = (intl, dispatch, resultSet, state, stateDispatch) => {
  const {filtersCache, queryParams, selectedSearchType} = state
  const updateFiltersCache = {
    ...filtersCache,
    [selectedSearchType.msgId]: { ...queryParams },
  };
  stateDispatch(makeAction('filtersCache', updateFiltersCache))

  const params = {};
  for (let [key, value] of Object.entries({ ...queryParams })) {
      if (!isEmpty(value)) {
          params[key] = value;
      }
  }
  if (!isEmpty(params)) {
      dispatch({
          type: actionTypes.GET_ADVANCE_SEARCH_INVOICES,
          searchType: selectedSearchType.resultSet,
          queryParams: params,
          callback: updateResults(intl, state, stateDispatch, resultSet),
      });
  }
};

const updateResults = (intl, {selectedSearchType, invoiceLiterals}, stateDispatch, resultSet) => (data, res) => {
  const currentColumns = resultSet[selectedSearchType.resultSet].fields;
  const conditions = currentColumns
      .filter(({ findByAddress }) => findByAddress)
      .map(({ conditionals }) => conditionals)
      .flat();
  stateDispatch(makeMultiAction({
    isBatchRequestModalOpen: data.isBatch || data.batchStarted,
    tableColumns: currentColumns,
    screen: `AdvancedSearch-${selectedSearchType.resultSet}`,
    data: (data && Array.isArray(data) && !isEmpty(conditions)) ? mappingFindByType(intl, invoiceLiterals, data, conditions, currentColumns) : data
  }))
};

const mappingFindByType = (intl, invoiceLiterals, invoices, conditionals, currentColumns) => {
  return invoices.map((invoice) => {
    const copyInvoice = { ...invoice };
    conditionals.forEach((cond) => {
      const key = Object.keys(cond)[0];
      const ref = key.split(".")[0];
      const subKey = key.split(".")[1];
      let condValues = cond[key];
      if (copyInvoice[ref] === undefined) return;
      if (condValues.operation === "=" && Array.isArray(copyInvoice[ref])) {
        for (let value of copyInvoice[ref]) {
          if (value[subKey] === condValues.value) {
            const fieldDefinition = currentColumns.find(({ field }) => field === condValues.outputField);
            copyInvoice[condValues.outputField] = value;
            const getFormatterString = getFormattedValue.bind({ intl, invoiceLiterals });
            copyInvoice[condValues.outputField] = getFormatterString(copyInvoice, fieldDefinition);
          }
        }
      }
    });
    return copyInvoice;
  });
};

const breadcrumbs = [
  <li key="UPSAdvanceSearch-breadcrumbs-dashboard" className="ups-breadcrumb-item">
    <Link to={"/home"} activeclassname="ups-link">
      <FormattedMessage id={"ups.title.dashboard"} />
    </Link>
    <span aria-hidden="true"> &gt; </span>
  </li>,
  <li key="UPSAdvanceSearch-breadcrumbs-advanced-search" className="ups-breadcrumb-item">
    <FormattedMessage id={"ups.side-nav.advanced-search"} />
  </li>,
];

const UPSAdvanceSearch = () => {
  const intl = useIntl();
  const { invoiceMetadata } = useSelector((s) => s.invoice);
  const { id: merchantId, currencyDisplay } = useSelector((s) => s.config.sessionSelection.merchant);
  const { user } = useSelector((s) => s.auth);
  const { advancedSearch: { searchTypes, filters, resultSet } } = invoiceMetadata;
  const { apiUrlPrefix, apiToken } = useSelector((s) => s.envProps);

  const dispatch = useDispatch(); //redux dispatch
  const [state, stateDispatch] = useReducer(reducer, {searchTypes, resultSet}, initialState);

  const {
    queryParams,
    filtersCache,
    isFiltersClean,
    isBatchRequestModalOpen,
    invoiceLiterals,
    selectedSearchType,
    screen,
    data,
    tableColumns,
    isDataCollectionFetched,
    originalAccountsList,
    loaded
  } = state;

  const filterMap = useMemo(()=>{
    return new Map(filters.map((filter) => [filter.field, filter]));
  }, [filters])

  useEffect(() => {
    let closed = false;
    identityApi.getIdentityValidationAccountsList(apiUrlPrefix, apiToken)
      .then((res) => {
        if (!closed){
          const nonPlanAccounts =  res?.parsedBody?.filter((acc) => acc.isParent !== true)
          const accountList = nonPlanAccounts.map(({ accountNumber }) => accountNumber);
          stateDispatch(makeMultiAction({
            fetchDataCollection: {accountList},
            isDataCollectionFetched: true,
            originalAccountsList: nonPlanAccounts.map(({ accountNumber, planNumber, type }) => {return {accountNumber, planNumber, type}})
          }));
        }
      })
    return () => closed = true;
  }, []);

  return (
      <>
        <Spinner isSpinning={!loaded}/>
        <MDBContainer role="main" fluid key={`${intl.locale}`}>
          <MDBRow>
            <MDBCol>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol size={"12"}>
                      <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                        <ol className="ups-wrap_inner">{breadcrumbs}</ol>
                      </nav>
                    </MDBCol>
                  </MDBRow>
                  <h1 className={"has-breadcrumb"}>
                    <FormattedMessage id={"ups.side-nav.advanced-search"} />
                  </h1>
                  <MDBRow>
                    <BatchSearchLinksModal merchantId={merchantId}/>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size={"12"} className={"mt-3"}>
                      <fieldset className="ups-style-as">
                        <legend>
                          <FormattedMessage id={"invoice.common-select.category"} />
                        </legend>
                        <div className="form-inline">
                          {searchTypes.map((searchType) => {
                            return (
                              <InputFieldIntl
                                key={searchType.msgId}
                                label={<FormattedMessage id={searchType.msgId} />}
                                type="radio"
                                name="searchType"
                                id={searchType.msgId}
                                labelClass={"mr-0"}
                                value={"searchTypeOption"}
                                checked={selectedSearchType.msgId === searchType.msgId}
                                onChange={() => {
                                  stateDispatch(makeMultiAction({
                                    selectedSearchType: searchType,
                                    isFetchAPI: false,
                                    queryParams: filtersCache[searchType.msgId],
                                    data: undefined
                                  }))
                                }}
                                containerClass={"form-check search-card"}
                              />
                            );
                          })}
                        </div>
                      </fieldset>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size={"12"}>
                      <fieldset className="ups-style-as">
                        <legend>
                          <FormattedMessage id={"invoice.common-select.filters"} />
                        </legend>
                      </fieldset>
                      <div className={"search-components-container"}>
                        <FilterList
                          filters={mappingFilterField(filterMap, state, invoiceMetadata)}
                          queryParams={queryParams}
                          updateQueryParams={(query)=>updateQueryParams(queryParams,stateDispatch,query)}
                          invoiceLiterals = {invoiceLiterals}
                          setInvoiceLiterals={(value)=>stateDispatch(makeAction('invoiceLiterals', value))}
                          searchType={selectedSearchType.msgId}
                          filtersCache={filtersCache}
                          isDataCollectionFetched={isDataCollectionFetched}
                          isFiltersClean={isFiltersClean}
                          originalAccountsList={originalAccountsList}
                          setLoaded={()=>stateDispatch(makeAction('loaded', true))}
                        />
                        <MDBRow className={"mt-3"}>
                          <MDBCol size={"12"}>
                            <MDBBtn
                              color="primary"
                              className="m-0 mr-3"
                              disabled={(isEmpty(queryParams))}
                              onClick={() => {
                                fetchInvoices(intl, dispatch, resultSet, state, stateDispatch);
                              }}
                            >
                              <FormattedMessage id={"invoice.common-select.submit"} />
                            </MDBBtn>
                            <MDBBtn color="cancel" className="m-0" onClick={()=>cleanFilters(state, stateDispatch)}>
                              <FormattedMessage id={"clear.label"} />
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size={"12"}>
                      {data && Array.isArray(data) && ( //in the case of batch report data can be an object, but when displaying search results it's an array
                          <AdvancedSearchResultsTable
                              invoiceLiterals={invoiceLiterals}
                              tableColumns={tableColumns}
                              screen={screen}
                              data={data}
                          />
                      )}
                      {isBatchRequestModalOpen && (
                        <BatchRequestModal
                          isOpen={isBatchRequestModalOpen}
                          fetchBatchReportProps={{
                            type: actionTypes.GET_ADVANCE_SEARCH_INVOICES,
                            searchType: selectedSearchType.resultSet,
                            queryParams: { ...data?.queryParams },
                            callback: updateResults(intl, state, stateDispatch, resultSet),
                            localeSetting: user?.locale,
                            currencyDisplay: currencyDisplay??'symbol',
                            isBatch: data?.isBatch,
                          }}
                          maxResult={resultSet?.[selectedSearchType.resultSet].maxResult}
                          toggleModal={() => stateDispatch(
                              makeAction('isBatchRequestModalOpen', !isBatchRequestModalOpen)
                          )}
                        />
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
  );
};

export default UPSAdvanceSearch;
