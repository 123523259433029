import React,  { Component } from 'react';
import {isNil as lodashIsNil,isEmpty} from "lodash";
import {formatCurrency} from '../utils/ups-utils';
import * as R from 'ramda';

export const DISPUTE_PENDING = 'PE';
export const DISPUTE_APPROVED = 'AP';
export const DISPUTE_DENIED = 'DN';
export const DISPUTE_VALIDATION_ERROR = 'VE';
export const DISPUTE_ACCEPTED = 'AC';
export const DISPUTE_UNRESOLVED = 'UR';

export const DISPUTE_STATUS_CODES = [DISPUTE_PENDING, DISPUTE_APPROVED, DISPUTE_DENIED, DISPUTE_VALIDATION_ERROR, DISPUTE_ACCEPTED, DISPUTE_UNRESOLVED]

export const getStatusCodeLabel = (intl) => {
    return {
        [DISPUTE_APPROVED]: intl.formatMessage({ id: 'invoice.dispute.approved.label' }),
        [DISPUTE_PENDING]: intl.formatMessage({ id: 'invoice.dispute.pending.label' }),
        [DISPUTE_DENIED]: intl.formatMessage({ id: 'invoice.dispute.denied.label' }),
        [DISPUTE_VALIDATION_ERROR]: intl.formatMessage({ id: 'invoice.dispute.validation-error.label' }),
        [DISPUTE_ACCEPTED]: intl.formatMessage({ id: 'invoice.dispute.accepted.label' }),
        [DISPUTE_UNRESOLVED]: intl.formatMessage({ id: 'invoice.dispute.unresolved.label' }),
    }
};

export const renderRefundAmountTableRow = (intl, refundAmount, refundCurrency, currencyDisplay="symbol") => {
    if (lodashIsNil(refundAmount) || lodashIsNil(refundCurrency)) return null;

    const creditOrDebitLabel = refundAmount < 0
        ? 'invoice.dispute.refund-amount.credit.label'
        : 'invoice.dispute.refund-amount.debit.label';

    return (
        <tr>
            <th>{intl.formatMessage({id: 'invoice.dispute.refund-amount.label'})}</th>
            <td>
                {formatCurrency(intl, refundAmount, refundCurrency, currencyDisplay)} {refundAmount !== 0 ? intl.formatMessage({ id: creditOrDebitLabel }) : ''}
            </td>
        </tr>
    );
};

export function mapErrCode(errCollection, msgMappingCollection, intl) {
  if (isEmpty(errCollection)) return ''
  const { errorCodes, errorMsg } = errCollection;
  if (isEmpty(errorCodes)) return errorMsg
  if (isEmpty(errorMsg)) return errorMsg
  const codeArry = errorCodes.split(";");
  const msgArry = errorMsg.split(";");
  const errCodeMap = {};
  const constructErrCodeMap = (code, mappingMsg) => {
    if (mappingMsg[code]) {
      errCodeMap[mappingMsg[code]] = errCodeMap[mappingMsg[code]] ? errCodeMap[mappingMsg[code]].concat(code) : [code];
    }
  };
  return [
    ...new Set(
      codeArry.map((code, index) => {
        constructErrCodeMap(code, msgMappingCollection);
        return msgMappingCollection[code] || `${msgArry[index]}`;
      })
    ),
  ]
    .map((string) => {
      return errCodeMap[string] ? `${intl.formatMessage({ id: string })}` : string;
    })
    .join(";");
};

export const isGRSDisputeSupported = R.pathOr(false, ['merchant', 'clientConfiguration', 'GRS_DISPUTE_SUPPORTED']);
