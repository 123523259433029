import React from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from "classnames";

import CardActions from '../CardActions';
import {mapCardTypeToCss, isWalletExpiring} from "../../../../utils/payment-utils";
import {useIntl} from 'react-intl';


export default ({
    editHandler, removeHandler, makeDefaultHandler, showCardActions, extraCssClass, paymentMethod,
    paymentMethod: {
        methodType,
        accountNumber, cardHolderName, ccExpiry,
        cardNickName, default: isPrimary, ccExpired
    },
    permissions
    
} ) =>{
    const intl = useIntl();
    return <React.Fragment>
    <div className={classNames("card-container credit-card", extraCssClass)}>
        <p className="pm-info card-nickname">{cardNickName}</p>
        <p className="pm-info card-expiration">
            <span className='pm-card-expiration-text'>
                {ccExpired ? <FormattedMessage id={`card.expired`} /> : isWalletExpiring(paymentMethod) ? <FormattedMessage id={`card.expiring`} /> : null}
            </span>
        </p>
        <div className="pm-card-chip" role="img" aria-label={intl.formatMessage({id: "card.chip"})}/>
        <p className="pm-info card-number">{`**** **** **** ${accountNumber.slice(-4)}`}</p>
        <div className="card-exp-container">
            <p className="pm-info card-exp-label">
                <FormattedMessage id={`card.valid`}/>
                <br/>
                <FormattedMessage id={`card.thru`}/>
            </p>
            <p className="pm-info card-exp-date">
                {ccExpiry.month}<span className="card-sep">/</span>{ccExpiry.year}
            </p>
        </div>
        <p className="pm-info card-name">{cardHolderName}</p>
        <p className="pm-info card-type" role="img" aria-label={intl.formatMessage({id: `payment-method.${methodType}`})}>
            <span className={classNames("pm-icon", mapCardTypeToCss(methodType))} >
                <FormattedMessage id={`payment-method.${methodType}`}/>
            </span>
        </p>
    </div>
    {showCardActions &&
        <CardActions
            editHandler={editHandler}
            removeHandler={removeHandler}
            makeDefaultHandler={makeDefaultHandler}
            isPrimary={isPrimary}
            permissions={permissions} 
            pmLabel={intl.formatMessage({id: `payment.method.creditdebitgeneric.btn.label.ending.with`},{cardType: intl.formatMessage({id: `payment-method.${methodType}`}), accountNumber: accountNumber.slice(-4)})}
        />
    }
</React.Fragment>
};
