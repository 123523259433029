
import * as R                                                 from 'ramda';
import { ON_DUE, BEFORE_DUE, WEEKLY }                         from '../constants/autopay';
import { luhnCheck }                                          from './payment-method-utils';
import { overIf }                                             from './utils';
import moment                                                 from 'moment';
import Decimal                                                from 'decimal.js';
// all functions are defined using eta-reduction

export const PM_TYPE_METADATA = {
    // --- CC/DC ---
    VISA: {
        accountNumberRegEx: /^4/,
        label: "payment-method.VISA",
        cardCSS: "methodVisa"
    },
    VISA_DEBIT: {
        accountNumberRegEx: /^4/,
        label: "payment-method.VISA_DEBIT",
        cardCSS: "methodVisaDebit"
    },
    VISA_ELECTRON: {
        accountNumberRegEx: /^(4026|417500|4405|4508|4844|4913|4917)/,
        label: "payment-method.VISA_ELECTRON",
        cardCSS: "methodVisaElectron"
    },
    VPAY: {
        accountNumberRegEx: /^4/,
        label: "payment-method.VPAY",
        cardCSS: "methodVPay"
    },
    MC: {
        accountNumberRegEx: /^(5[1-5]|2[2-7])/,
        label: "payment-method.MC",
        cardCSS: "methodMastercard"
    },
    MC_DEBIT: {
        accountNumberRegEx: /^(5[1-5]|2[2-7])/,
        label: "payment-method.MC_DEBIT",
        cardCSS: "methodMastercardDebit"
    },
    AMEX: {
        accountNumberRegEx: /^3[47]/,
        label: "payment-method.AMEX",
        cardCSS: "methodAmex"
    },
    AMEX_DEBIT: {
        accountNumberRegEx: /^3[47]/,
        label: "payment-method.AMEX_DEBIT",
        cardCSS: "methodAmex"
    },
    DISC: {
        accountNumberRegEx: /^(6011|62212[6-9]|6221[3-9]|622[2-8]|6229[0-1]|62292[0-5]|64[4-9]|65)/,
        label: "payment-method.DISC",
        cardCSS: "methodDiscover"
    },
    DISC_DEBIT: {
        accountNumberRegEx: /^(6011|62212[6-9]|6221[3-9]|622[2-8]|6229[0-1]|62292[0-5]|64[4-9]|65)/,
        label: "payment-method.DISC_DEBIT",
        cardCSS: "methodDiscoverDebit"
    },
    MAESTRO: {
        accountNumberRegEx: /^(50|5[6-8]|6)/,
        label: "payment-method.MAESTRO",
        cardCSS: "methodMaestro"
    },
    BANCONTACT: {
        accountNumberRegEx: /^(6703|6060|561[3-4]|53944|52[0-2,4-5]|5182|5169|5127|4940|4934|4918|4871|4796|4579|4544)/,
        label: "payment-method.BANCONTACT",
        cardCSS: "methodBancontact"
    },
    UNION_PAY: {
        accountNumberRegEx: /^(62|81)/,
        label: "payment-method.UNION_PAY",
        cardCSS: "methodUnionPay"
    },
    UNION_PAY_DEBIT: {
        accountNumberRegEx: /^(62|81)/,
        label: "payment-method.UNION_PAY_DEBIT",
        cardCSS: "methodUnionPay"
    },
    DC: { //diners card
        accountNumberRegEx: /^(36|38|30[0-5]|3095|39)/,
        label: "payment-method.DINERS_CARD",
        cardCSS: "methodDinersCard"
    },
    JCB: {
        accountNumberRegEx: /^(35[2-8])/,
        label: "payment-method.JCB",
        cardCSS: "methodJCB"
    },
    CARTES_BANCAIRES: {
        accountNumberRegEx: /^(40100[5-9]|4010[1-9]|401[1-9]|40[2-9]|4[1-9]|5[0-9]|6[0-6]|67[0-7][0-5][0-8]|677590[0-9])/,
        label: "payment-method.CARTES_BANCAIRES",
        cardCSS: "methodCartesBancaires"
    },
    CARTES_BANCAIRES_DEBIT: {
        accountNumberRegEx: /^(40100[5-9]|4010[1-9]|401[1-9]|40[2-9]|4[1-9]|5[0-9]|6[0-6]|67[0-7][0-5][0-8]|677590[0-9])/,
        label: "payment-method.CARTES_BANCAIRES",
        cardCSS: "methodCartesBancaires"
    },
    // --- DD ---
    CHQ: {
        label: "payment-method.CHQ"
    },
    SAV: {
        label: "payment-method.SAV"
    },
    // --- Independent Categories ---
    SCB: {
        label: "payment-method.SCB",
        cardCSS: "methodSCB"
    },
    SEPA: {
        label: "payment-method.SEPA",
        requiresAddress: true
    },
    BACS: {
        label: "payment-method.BACS",
        requiresAddress: true
    },
    TRUSTLY: {
        label: "payment-method.TRUSTLY"
    },
    MOMO_WALLET: {
        label: "payment-method.MOMO_WALLET",
        isCCPayment: true
    },
    VIPPS: {
        label: "payment-method.VIPPS",
        isCCPayment: true
    },
    SWISH: {
        label: "payment-method.SWISH"
    },
    BLIK: {
        label: "payment-method.BLIK"
    },
    TWINT: {
        label: "payment-method.TWINT",
        isCCPayment: true
    },
    PAYPAL_ACCOUNT: {
        label: "payment-method.PAYPAL_ACCOUNT",
        isCCPayment: true
    },
    PAYPAL_CREDIT: {
        label: "payment-method.PAYPAL_CREDIT"
    },
    DOTPAY: {
        label: "payment-method.DOTPAY",
        requiresIssuer: true
    },
    ONLINEBANKING_PL: {
        label: "payment-method.ONLINEBANKING_PL",
        requiresIssuer: true
    },
    GIROPAY: {
        label: "payment-method.GIROPAY"
    },
    IDEAL: {
        label: "payment-method.IDEAL",
        requiresIssuer: true
    },
    SOFORT: {
        label: "payment-method.SOFORT"
    },
    WECHAT_PAY: {
        label: "payment-method.WECHAT_PAY",
        isCCPayment: true
    },
    ALIPAY: {
        label: "payment-method.ALIPAY",
        isCCPayment: true
    },
    ALIPAY_HK: {
        label: "payment-method.ALIPAY_HK",
        isCCPayment: true
    }
};

const PM_CATEGORY_METADATA = {
    // --- Independent Categories ---
    SCB: {
        label: "payment-method.SCB",
        saveWalletInPaymentFlowOnly: true
    },
    SEPA: {
        label: "payment-method.SEPA",
        requiresAddress: true
    },
    BACS: {
        label: "payment-method.BACS",
        requiresAddress: true,
        immutableAddress: true,
        saveWalletInPaymentFlowOnly: true
    },
    TRUSTLY: {
        label: "payment-method.TRUSTLY"
    },
    MOMO_WALLET: {
        label: "payment-method.MOMO_WALLET",
        isCCPayment: true
    },
    VIPPS: {
        label: "payment-method.VIPPS",
        isCCPayment: true
    },
    SWISH: {
        label: "payment-method.SWISH"
    },
    BLIK: {
        label: "payment-method.BLIK"
    },
    TWINT: {
        label: "payment-method.TWINT",
        isCCPayment: true
    },
    PAYPAL_ACCOUNT: {
        label: "payment-method.PAYPAL_ACCOUNT",
        isCCPayment: true
    },
    PAYPAL_CREDIT: {
        label: "payment-method.PAYPAL_CREDIT"
    },
    DOTPAY: {
        label: "payment-method.DOTPAY",
        requiresIssuer: true
    },
    ONLINEBANKING_PL: {
        label: "payment-method.ONLINEBANKING_PL",
        requiresIssuer: true
    },
    GIROPAY: {
        label: "payment-method.GIROPAY"
    },
    IDEAL: {
        label: "payment-method.IDEAL",
        requiresIssuer: true
    },
    SOFORT: {
        label: "payment-method.SOFORT"
    },
    WECHAT_PAY: {
        label: "payment-method.WECHAT_PAY",
        isCCPayment: true
    },
    ALIPAY: {
        label: "payment-method.ALIPAY",
        isCCPayment: true
    },
    ALIPAY_HK: {
        label: "payment-method.ALIPAY_HK",
        isCCPayment: true
    },
    // --- Umbrella Categories ---
    CC: {
        label: "payment-method-category.CC",
        requiresAddress: true,
        isCCPayment: true
    },
    DC: { // debit card
        label: "payment-method-category.DC",
        requiresAddress: true
    },
    DD: {
        label: "payment-method-category.DD"
    }
}

const PAYPAL_TYPES = ['PAYPAL_ACCOUNT', 'PAYPAL_CREDIT'];
const ALTERNATE_TYPES = ['DOTPAY', 'GIROPAY', 'IDEAL', 'SOFORT', 'ALIPAY', 'ALIPAY_HK', 'WECHAT_PAY',
    'TRUSTLY','TWINT','MOMO_WALLET','SWISH','BLIK','VIPPS','ONLINEBANKING_PL'];
const CARD_NUMBER_REGEX = /^[0-9\*]+$/;

// Ported and modified from ROTP framework.js
export const identifyCardType = (cardNumber, categ, fullCheck, supportedCards, cobrandCardTypeSelectEnabled) => {
    if (!R.test(CARD_NUMBER_REGEX, cardNumber)) return '';

    const len = cardNumber.length;
    if (fullCheck) {
        if (len < 13 || len > 19) return '';
        if (!luhnCheck(cardNumber)) return '';
    }

    //if there are multiple matches, we don't display any card type, the user must select it from a dropdown if their card number could be different card types
    const matches = identifyAllMatchingCardTypes(cardNumber, categ, supportedCards)
    if (!cobrandCardTypeSelectEnabled && matches.length > 1) return matches[0]
    if (matches.length > 1 || matches.length === 0) return ''
    else return matches[0]
};

export const identifyAllMatchingCardTypes = (cardNumber, category, supportedCards)=>{
    const supportedLookup = supportedCards?.reduce((acc, cur)=>{
        acc[cur]=true
        return acc
    },{})
    const findMatches = (pmTypeList)=>pmTypeList.reduce((acc, cur)=>{
        if(PM_TYPE_METADATA[cur].accountNumberRegEx.test(cardNumber)) acc.push(cur)
        return acc
    },[])
    // Order may matter, depending on regex
    if (category === 'DC') return findMatches([
        'CARTES_BANCAIRES_DEBIT',
        'BANCONTACT',
        'DISC_DEBIT',
        'UNION_PAY_DEBIT',
        'VISA_ELECTRON',
        'MAESTRO',
        'VISA_DEBIT',
        'MC_DEBIT',
        'AMEX_DEBIT'
    ].filter((cat)=>supportedLookup?supportedLookup[cat]:true))
    if (category === 'CC') return findMatches([
        'CARTES_BANCAIRES',
        'VISA',
        'MC',
        'AMEX',
        'DISC',
        'UNION_PAY',
        'DC', // diners card
        'JCB'
    ].filter((cat)=>supportedLookup?supportedLookup[cat]:true))
    return []
}

// -- General Utils --
const revIncludes = R.flip(R.includes);
export const notEmpty = R.allPass([R.complement(R.isNil), R.complement(R.isEmpty)]);
const numProps = R.compose(R.length, R.keys);
const isLength = n => R.compose(R.equals(n), numProps);

const methodType = R.prop('methodType');
const categoryCode = R.prop('categoryCode');

// -- PM Category Tools --

// String -> Bool
const pmCatRequiresIssuer = R.compose(
    R.pathOr(false, R.__, PM_CATEGORY_METADATA),
    cat => [cat, 'requiresIssuer']
);

// String -> Bool
export const pmCatRequiresAddress = R.compose(
    R.pathOr(false, R.__, PM_CATEGORY_METADATA),
    cat => [cat, 'requiresAddress']
);

// String -> Bool
export const pmCatImmuatableAddress = R.compose(
    R.pathOr(false, R.__, PM_CATEGORY_METADATA),
    cat => [cat, 'immutableAddress']
);

// PaymentMethod -> Bool
export const isPayPalMethod = R.compose(revIncludes(PAYPAL_TYPES), methodType);
const isAlternateMethod = R.compose(revIncludes(ALTERNATE_TYPES), methodType);
export const isCreditCard = R.propEq('categoryCode', 'CC');
export const isDebitCard = R.propEq('categoryCode', 'DC');
export const isSepa = R.compose(R.equals('SEPA'), methodType);
export const isSCB = R.compose(R.equals('SCB'), methodType);
const isBacs = R.compose(R.equals('BACS'), methodType);
const isECheque = R.propEq('categoryCode', 'DD');
export const isWallet = R.compose(notEmpty, R.prop('_id'));
export const requiresAddress = R.compose(pmCatRequiresAddress, R.prop('categoryCode'));
const requiresIssuer = R.compose(pmCatRequiresIssuer, R.prop('categoryCode'));
export const isCountryHK = R.compose(R.propEq('country', 'HK'), R.prop('address'));
const isBankingCA = R.compose(R.allPass([R.all(notEmpty), isLength(2)]), R.props(['bankId', 'bankTransitNumber']));

// -- Validation Tools --
const isExpiryComplete = R.compose(R.allPass([R.all(notEmpty), isLength(2)]), R.props(['month', 'year']), R.prop('ccExpiry'));
const isAddressComplete = R.cond(
    [[isCountryHK, R.compose(
        R.allPass([R.all(notEmpty), isLength(3)]),
        R.props(['line1', 'city', 'country']),
        R.prop('address'))]
        , [R.T, R.compose(
            R.allPass([R.all(notEmpty), isLength(4)]),
            R.props(['line1', 'city', 'zipCode', 'country']),
            R.prop('address'))]
    ]);
const isReqAddressComplete = R.ifElse(
    requiresAddress,
    isAddressComplete,
    R.T
);
const hasReqIssuer = R.ifElse(
    requiresIssuer,
    R.compose(notEmpty, R.prop('fiCode')),
    R.T
);

const hasUserAccepted = R.prop('authorizeDD');

const isPayPalComplete = R.compose(notEmpty, R.prop('nonce'));
const isCardDataComplete = R.compose(R.allPass([R.all(notEmpty), isLength(2)]), R.props(['accountNumber', /*'cvvNumber',*/ 'cardHolderName']));
const isUSBankAccountComplete = R.compose(R.allPass([R.all(notEmpty), isLength(3)]), R.props(['accountNumber', 'routingNumber', 'cardHolderName']));
const isCABankAccountComplete = R.compose(R.allPass([R.all(notEmpty), isLength(4)]), R.props(['accountNumber', 'bankId', 'bankTransitNumber', 'cardHolderName']));
const isSepaAccountComplete = R.compose(R.allPass([R.all(notEmpty), isLength(3)]), R.props(['accountNumber', 'cardHolderName', 'bic']));
const isEChequeAccountComplete = R.compose(R.allPass([R.all(notEmpty), isLength(1)]), R.props(['fiName']));
const isSCBAccountComplete = R.compose(R.allPass([R.all(notEmpty), isLength(6)]), R.props(['accountNumber', 'cardHolderName', 'bic', 'bankId', 'debtorIdType', 'debtorId']));

const isCardComplete = R.allPass([isExpiryComplete, isCardDataComplete, isReqAddressComplete]);
const isSepaComplete = R.allPass([isSepaAccountComplete, isReqAddressComplete]);
const isBacsComplete = R.allPass([isUSBankAccountComplete, isReqAddressComplete]);
const isEChequeComplete = R.cond(
    [[isBankingCA, R.allPass([isEChequeAccountComplete, isCABankAccountComplete, hasUserAccepted])]
        , [R.T, R.allPass([isEChequeAccountComplete, isUSBankAccountComplete, hasUserAccepted])]
    ]);
const isSCBComplete = R.allPass([isSCBAccountComplete, hasUserAccepted])

// isPaymentComplete :: PaymentMethod -> Bool
export const isPaymentComplete = R.cond(
    [[R.isNil, R.F]
        , [isWallet, R.T]
        , [isPayPalMethod, isPayPalComplete]
        , [isCreditCard, isCardComplete]
        , [isSepa, isSepaComplete]
        , [isBacs, isBacsComplete]
        , [isECheque, isEChequeComplete]
        , [isDebitCard, isCardComplete]
        , [isSCB, isSCBComplete]
        , [isAlternateMethod, hasReqIssuer]
        , [R.T, R.F]
    ]);

const getMerchantPaymentMethods = countryCode => R.compose
    (R.propOr([], 'paymentMethods')
        , R.find(R.propEq('countryCode', countryCode))
    );

const getSupportedPaymentMethodCategory = (countryCode, categoryCode, { tokenizableOnly } = {}) => R.compose
    (R.map(R.prop('code'))
        , R.when(
            R.always(tokenizableOnly),
            R.filter(R.complement(R.propEq('tokenizedProfile', false)))
        )
        , R.filter(R.propEq('categoryCode', categoryCode))
        , getMerchantPaymentMethods(countryCode)
    );

// -- Supported Card Tools --
// String -> Merchant[] -> String[]
export const getSupportedCards = countryCode => R.compose
    (R.map(R.prop('code'))
        , getMerchantPaymentMethods(countryCode)
    );

export const getSupportedCreditCards = (countryCode, options) => getSupportedPaymentMethodCategory(countryCode, 'CC', options);
export const getSupportedDebitCards = (countryCode, options) => getSupportedPaymentMethodCategory(countryCode, 'DC', options);

export const mapCardTypeToCss = R.compose(
    R.pathOr('', R.__, PM_TYPE_METADATA),
    t => [t, 'cardCSS']
);
export const mapCardTypeToLabel = R.compose(
    R.pathOr('', R.__, PM_TYPE_METADATA),
    t => [t, 'label']
);

export const getSupportedCardsCss = R.map(mapCardTypeToCss);
export const getSupportedCardsLabel = R.map(mapCardTypeToLabel);

export const accountSupportsDebitOnly = R.compose
    (R.propEq('creditAllowed', false)
        , R.defaultTo({})
    );

const pmTypeIsRealTimeOnly = ({paymentMethods}) => R.compose(
    R.ifElse(R.isNil, R.F, R.propEq('realTimePaymentOnly', true)),
    R.find(R.__, paymentMethods),
    R.propEq('code')
);

export const pmTypeValidMinPaymentAmount = ({paymentMethods}, amount) => (methodType)=> {
    if(!methodType) return true
    const {minPaymentAmount} = paymentMethods.find(({code})=>code===methodType) ?? {}
    return (minPaymentAmount !== undefined) ? amount >= minPaymentAmount : true
}

export const pmTypeValidMaxPaymentAmount = ({paymentMethods}, amount) => (methodType)=> {
    if(!methodType) return true
    const {maxPaymentAmount} = paymentMethods.find(({code})=>code===methodType) ?? {}
    return (maxPaymentAmount !== undefined) ? amount <= maxPaymentAmount : true
}

const pmTypeIsDeferPayWithWalletOnly = ({paymentMethods}) => R.compose(
    R.ifElse(R.isNil, R.F, R.propEq('deferPayWalletOnly', true)),
    R.find(R.__, paymentMethods),
    R.propEq('code')
);

export const isRealTimeOnlyCard = (merchant) => R.compose(
    pmTypeIsRealTimeOnly(merchant),
    methodType
);

export const isDeferPayWithWalletOnly = (merchant) => R.compose(
    pmTypeIsDeferPayWithWalletOnly(merchant),
    methodType
);

export const isPmPayLaterEligible = (merchant) => R.allPass[pmTypeIsRealTimeOnly(merchant), pmTypeIsDeferPayWithWalletOnly(merchant)];

// -- Display Tools --
export const payMethodsToLabels = R.compose(
    id => ({ id }),
    R.pathOr('payment-method.label', R.__, PM_TYPE_METADATA),
    t => [t, 'label']
);

export const payCategoriesToLabels = R.compose(
    id => ({ id }),
    R.pathOr('payment-method.label', R.__, PM_CATEGORY_METADATA),
    t => [t, 'label']
);

const hasNickName = R.allPass(
    [R.has('cardNickName')
        , R.propSatisfies(R.complement(R.isNil), 'cardNickName')
        , R.propSatisfies(R.complement(R.isEmpty), 'cardNickName')
    ]
);

export const displayWalletSelection = intl => R.cond(
    [
        [hasNickName, R.ifElse
            ( isWalletExpiring
            , R.compose
                ( (cardNickName) =>
                    `${cardNickName} - ${intl.formatMessage({id: "card.expiring"})}`
                , R.prop('cardNickName')
                )
            , R.prop('cardNickName')
            )
        ]
        , [isPayPalMethod, R.compose
            ( ({email, methodType}) =>
                `${email} - ${intl.formatMessage(payMethodsToLabels(methodType))}`
            , R.pick(['email', 'methodType'])
            )
        ]
        , [R.T, R.ifElse
            ( isWalletExpiring
            , R.compose
                ( ({accountNumber, methodType}) =>
                    `${accountNumber} - ${intl.formatMessage(payMethodsToLabels(methodType))} - ${intl.formatMessage({id: "card.expiring"})}`
                , R.pick(['accountNumber', 'methodType'])
                )
            , R.compose
                ( ({accountNumber, methodType}) =>
                    `${accountNumber} - ${intl.formatMessage(payMethodsToLabels(methodType))}`
                , R.pick(['accountNumber', 'methodType'])
                )
            )
        ]
    ]
);

export const formatPMDesc = intl => R.compose(
    intl.formatMessage,
    payMethodsToLabels
);

export const formatPMCategoryDesc = intl => R.compose(
    intl.formatMessage,
    payCategoriesToLabels
);

export const displayScheduleFrequency = ({ formatMessage: format }) => R.cond([
    [R.propEq('type', ON_DUE), s => format(
        { id: `schedule.frequency.${s.type}` }
    )],
    [R.propEq('type', BEFORE_DUE), s => format(
        { id: `schedule.frequency.${s.type}` },
        { numDays: s.payBeforeDueDate }
    )],
    [R.propEq('type', WEEKLY), s => format(
        { id: `schedule.frequency.${s.type}.${s.scheduleDay}` }
    )],
    [R.T, R.always('')]
]);

export const getFormattedMethodlist = intl => R.map(value => ({
    value,
    label: formatPMDesc(intl)(value)
}));

// -- API Tools --
export const expiryYearLens = R.lensPath(['ccExpiry', 'year']);
export const expiryMonthLens = R.lensPath(['ccExpiry', 'month']);

const paymentMethodLens = R.lensProp('paymentMethod');

const paymentMethodToBody = R.compose
    (R.omit(['id', 'cardNickName'])
        , overIf(expiryMonthLens, m => +m)
        , overIf(expiryYearLens, y => +y + 2000)
        , p => ({ ...p, nickName: p.cardNickName })
    );

export const paymentToBody = overIf(paymentMethodLens, paymentMethodToBody);

export const getMerchantPaymentCategories = (alternate = false) => R.compose
    (R.filter(
        (alternate ? R.identity : R.complement)
            (revIncludes(ALTERNATE_TYPES))
    )
        , R.prop('paymentMethodCategories')
    );

const getAllNonCCPaymentCategories = R.compose(R.keys, R.filter(R.complement(R.propEq('isCCPayment', true))))
const NON_CC_PAYMENT_CATEGORIES = getAllNonCCPaymentCategories(PM_CATEGORY_METADATA)
export const isNonCCPaymentMethod = R.compose(revIncludes(NON_CC_PAYMENT_CATEGORIES), categoryCode)
export const getNonCreditPaymentCategories = R.intersection(NON_CC_PAYMENT_CATEGORIES);

// Common for 3ds.
export const is3dSecureFlow = R.has('hostedCheckout');

const arrayToObjectIndex = R.addIndex(R.reduce)((acc, cur, index)=>R.set(R.lensProp(cur), index, acc),{})
const sortFunc = (lookup)=>(a,b)=>(lookup[a.categoryCode] - lookup[b.categoryCode])
export const sortWallet = (categories) => R.sort(sortFunc(arrayToObjectIndex(categories))) //sort wallet entries by order of payment method category list

export const isWalletExpiring = R.allPass([
    R.has('ccExpiry'),
    R.compose(
        R.equals(moment().format('MM')),
        R.view(expiryMonthLens),
    ),
    R.compose(
        R.equals(moment().format('YYYY').substr(-2)),
        R.view(expiryYearLens)
    )
])

const extractInvoices = R.map(R.prop('invoices'));

export const anyInvoicesFullyPaid = R.compose
    ( R.any (({amount, outstandingAmount}) => amount === outstandingAmount)
    , extractInvoices
    );

export const invoicesTotalDue = (invoices) => invoices.reduce((acc, inv) => acc.plus(((inv.outstandingAmount > 0) && inv.outstandingAmount) || 0), new Decimal(0)).toNumber()
export const invoicesTotalAmount = (invoices) => invoices.reduce((acc, inv) => acc.plus(inv.paymentAmount || 0), new Decimal(0)).toNumber()
export const invoicesCreditedAmount = (invoices) => Math.abs(invoices.reduce((acc,inv) => acc.plus(((inv.paymentAmount < 0) && inv.paymentAmount) || 0), new Decimal(0)).toNumber())

export const isPMCategoryOnlySavableToWalletInPaymentFlow = (category) => PM_CATEGORY_METADATA[category]?.saveWalletInPaymentFlowOnly
