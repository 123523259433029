import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import InputFieldIntl from "../../InputFieldIntl";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import {doFocus} from "../../../utils/utils";

class CalendarPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    onInit() {
        const { dateType, calendarOptionList } = this.props;
        const type = dateType ? dateType : calendarOptionList[0].field;
        this.setState({
            loaded: true,
            dateType: type
        });
    }

    componentDidMount() {
        this.onInit();
    }

    handleInput = e => {
        const { dateType } = this.state;
        const { calendarOptionList, resetTransientState } = this.props;
        if (calendarOptionList.length == 1) {
            return;
        }
        resetTransientState(e);
        this.setState({
            dateType: dateType == calendarOptionList[0].field ? calendarOptionList[1].field : calendarOptionList[0].field
        });
    }

    handleRadioChange = e => {
        const {clearDates, updateTransientCalendarOption} = this.props;
        const inputValue = e.target.value;
        updateTransientCalendarOption({calendarOptionType: inputValue});
        clearDates();
    }

    loadCalendarRadio(dateType, calendarOptionList, calendarOptionType, handleRadioChange) {
        const calendarRadioComponents = [];
        const optionList = dateType == calendarOptionList[0].field ? calendarOptionList[0].options : calendarOptionList[1].options;
        for (const calendarOption of optionList) {
            calendarRadioComponents.push(
                 <InputFieldIntl
                    key={calendarOption.optionType}
                    label={calendarOption.label}
                    type="radio"
                    name="calendarOptionType"
                    id={"calendarOptionType_"+calendarOption.optionType}
                    labelClass={"mr-0"}
                    value={calendarOption.value}
                    checked={calendarOptionType === calendarOption.value}
                    onChange={handleRadioChange}
                    containerClass={"form-check mx-0 mb-3 col-6 col-md-12"}
                />

            )
        }

        return calendarRadioComponents;
    }

    submitFilters = e => {
        const { submitDateFilter } = this.props;
        const { dateType } = this.state;
        submitDateFilter({dateType})
    }

    onClose = (action) => {
        const {closeFocusSelector='.btn-calendar'} = this.props;
        doFocus(closeFocusSelector);
        action();
    }

    render() {
        const { dateType, calendarField, loaded } = this.state;
        const { intl, dateOptions, calendarOptionList, calendarOptionType, resetFilters } = this.props;
        const { submitFilters, loadCalendarRadio, handleRadioChange } = this;

        if (!loaded) return null;

        const calendarRadioComponents = loadCalendarRadio(dateType, calendarOptionList, calendarOptionType, handleRadioChange);

        return (
            <React.Fragment>
                <div className={"calendar-settings-panel"}>
                    <MDBRow>
                        <MDBCol size={"12"}>
                        <fieldset className={"form-radio-group"}>
                                <legend aria-label={intl.formatMessage({id:"calendar.phrases.selectDateType"})}></legend>
                                <InputFieldIntl
                                    label={calendarOptionList[0].title}
                                    type="radio"
                                    name="dateType"
                                    id={"dateType1"}
                                    labelClass={"mr-0"}
                                    tabIndex={dateOptions.length == 2 ? 0 : -1}
                                    value={calendarOptionList[0].field}
                                    checked={dateType == calendarOptionList[0].field}
                                    onChange={this.handleInput}
                                    className={"form-radio-group-check"}
                                    containerClass={"form-radio-group-item mx-0 mb-3 pl-0"}
                                />
                                { dateOptions.length == 2 &&
                                    <InputFieldIntl
                                        label={calendarOptionList[1].title}
                                        type="radio"
                                        name="dateType"
                                        id={"dateType2"}
                                        labelClass={"mr-0"}
                                        value={calendarOptionList[1].field}
                                        checked={dateType == calendarOptionList[1].field}
                                        onChange={this.handleInput}
                                        className={"form-radio-group-check"}
                                        containerClass={"form-radio-group-item mx-0 mb-3 pl-0"}
                                    />
                                }
                            </fieldset>
                            
                            <fieldset>
                                <legend aria-label={`${calendarOptionList[0].title} ${intl.formatMessage({id:"calendar.filter.legend"})}`}>
                                    <FormattedMessage id={"calendar.filter.legend"}/>
                                </legend>
                                <MDBRow>
                                  {calendarRadioComponents}
                                </MDBRow>
                            </fieldset>
                        </MDBCol>
                    </MDBRow>

                    <div className={"calendar-settings-panel-actions"}>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <MDBBtn id={'invoice-search-date-btn-apply'} color={"primary"} className={"ml-0 my-0"} onClick={()=>this.onClose(submitFilters)}><FormattedMessage id={"calendar.btn-apply"}/></MDBBtn>
                                <MDBBtn id={'invoice-search-date-btn-cancel'} color={"text-link"} onClick={()=>this.onClose(resetFilters)}><FormattedMessage id={"calendar.btn-cancel"}/></MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default injectIntl(CalendarPane);