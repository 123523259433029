/*
 * Copyright (C) 2018 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 9/18/18 1:01 PM
 * Created by kxian
 *
 */
import ReactPortalModal from "../../../components/ReactPortalModal";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBCard, MDBCol, MDBRow, MDBBtn } from 'mdbreact';
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import {setLastStateOnSessionTimeOut} from '../../../utils/local-storage';

const $ = require('jquery');

/**
 * This component provides a popup modal to prompt the user
 * if they want to continue their session or log out from the application.
 *
 * parameters:
 * secondsUntilWarning - the number of seconds before the prompt modal will be displayed
 * warningDurationSeconds - the number of seconds after the modal is displayed and the onWarningTimeout will be executed
 * if the user doesn't select any options in the modal
 * onWarningTimeout - a function to be executed if the user doesn't select any options in the modal
 * onLogout - a function to be executed if the user selects logout in the modal
 * onContinueSession - a function to be executed if the user selects continue session in the modal
 * config
 *
 * secondsUntilWarning={sessionTimeoutSeconds - 70}
 *  warningDurationSeconds={60}
 *
 */
class SessionTimeoutWidget extends Component {

    constructor(props) {
        super(props);
        const { settings , actions } = this.props;
        const {config}  = settings;
        this.warningDurationSeconds = (settings.warningDurationSeconds || 60);
        this.secondsUntilWarning = config.sessionTimeoutSeconds - 70;

        //console.log('Initializing SessionTimeoutWidget: secondsUntilWarning=' + this.secondsUntilWarning + ', warningDurationSeconds=' + this.warningDurationSeconds);

        this.setTimers = this.setTimers.bind(this);
        this.clearTimers = this.clearTimers.bind(this);
        this.warn = this.warn.bind(this);
        this.startCountdown = this.startCountdown.bind(this);
        this.state = {
            showWarning: false,
            secondsUntilWarningTimer: 0,
            onWarningTimeoutTimer: 0,
            config: { ...config }
        };
        this.config = config;
        this.onContinueSession = this.onContinueSession.bind(this);
        this.onWarningTimeout = this.onWarningTimeout.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        this.setTimers();
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    setTimers() {
        let secondsUntilWarningTimer = setTimeout(this.warn, this.secondsUntilWarning * 1000);
        this.setState({
            secondsUntilWarningTimer
        })
    };

    warn() {
        this.setState({ showWarning: true });
        let onWarningTimeoutTimer = setTimeout(this.onWarningTimeout, this.warningDurationSeconds * 1000);
        this.setState({
            onWarningTimeoutTimer
        });
        this.startCountdown();
    }

    startCountdown() {
        this.setState({
            secondsCounter: this.warningDurationSeconds
        });
        let countdownTimer = setInterval(() => {
            this.setState({
                countdownTimer,
                secondsCounter: this.state.secondsCounter - 1
            }
            );
            if (this.state.secondsCounter <= 0) {
                clearInterval(countdownTimer);
            }
        }, 1000);
    }

    clearTimers() {
        clearInterval(this.state.countdownTimer);
        delete this.state.countdownTimer;
        clearTimeout(this.state.secondsUntilWarningTimer);
        delete this.state.secondsUntilWarningTimer;
        clearTimeout(this.state.onWarningTimeoutTimer);
        delete this.state.onWarningTimeoutTimer;
        delete this.state.secondsCounter;
    }



    onContinueSession() {
        this.clearTimers();
        this.setState({ showWarning: false });

        // const { settings ,actions } = this.props;
        // const { config } = settings;

        // this.state.showWarning = false;
        // actions.extendSession();
        this.setTimers();

    }

    onWarningTimeout() {
        this.clearTimers();
        this.setState({ showWarning: false });
        this.props.actions.expireSessionCookie(true);
        // warning timed out, call logout
        this.onLogout();
    }

    /**
     * Explicit logout by user.
     */
    onLogout() {
        this.clearTimers();
        this.setState({ showWarning: false });
        this.props.actions.expireSessionCookie();
    }


    render() {
        const { settings , actions, intl } = this.props;
        const { config } = settings;
        const modalHeading = 'session.widget.title';


        return this.state.showWarning && (
            <ReactPortalModal isOpen={this.state.showWarning} an_label="session-timeout" >
                <MDBModal isOpen={this.state.showWarning} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>

                    <MDBModalHeader tag="h2" color="danger">
                        <FormattedMessage id={modalHeading} />
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <p role="alert">
                                    <FormattedMessage id='session.widget.message' />
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>


                    <MDBBtn color="primary"

                        onClick={this.onLogout}>
                        <FormattedMessage id='session.widget.logout.button.title' />
                    </MDBBtn>
                    <MDBBtn
                         color="primary"
                        onClick={this.onContinueSession}>
                        <FormattedMessage id='session.widget.continue.button.title' />({this.state.secondsCounter})
                            </MDBBtn>
                </MDBModal>
            </ReactPortalModal>


        );
    }
}

export default injectIntl(SessionTimeoutWidget);