import React, { Component } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import * as authAction from '../actions/auth-action';
import * as settingAction from '../actions/setting-action';
import * as layoutActions from "../actions/layout-action";
import * as authActions  from "../actions/auth-action";
/* i18n Support */
import { IntlProvider } from 'react-intl';
import i18n from '../utils/i18n';

/* Routes */
import Routes from '../routes/index';

/* Components */
//import SideNav from '../components/SideNav';
//import Header from '../components/Header';
import Spinner from '../components/Spinner';
import DevWidget from '../components/DevWidget';

import defaultSettings from '../constants/default-settings'; 
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/*
There should be two types of layouts
1. Not logged in layout for enrollment flow / logged out / session expired (header + footer)
2. Logged in layout for existing users (header + footer + left side nav)

This main layout renders the main route component which contains entry points to the application
*/
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import SessionTimeoutWidget from '../components/UPS/SessionTimeoutWidget';
import {getLocaleFiles} from '../utils/i18n';
import NotificationModal from "../components/UPS/Modal/NotificationModal";

class MainLayout extends Component {
    constructor(props) {
      super(props);
      this.state = {ready: false};
    }

    componentDidMount() {
        /*
            This check will ensure that when user loads billing center then for the locale present in the state,
            translation files are imported and translations are set accordinly in the UI.
            Calling action to set locale so that once translation is downloaded, UI should be able to display
            it as well otherwise due to async nature of the call, it just gets download but UI does not get
            refreshed with translated. 
        */
        if (this.props.settings.locale && !i18n[this.props.settings.locale]) {
            this.loadLocaleFiles();
        } else {
            this.setState({ready: true});
        }
        // console.log('main layout mounted');
    }

    async loadLocaleFiles(){
        // console.log('loading locale files')
        await getLocaleFiles(this.props.settings.locale);
        if (i18n[this.props.settings.locale]){
            document.documentElement.setAttribute('lang', this.props.settings.locale)
            this.setState({
                key : this.props.settings.locale,
                ready: true
            });
        } 
    }

    render() {
        const {settings, spinner, spinnerText, basicModalOpen, basicModalTitle, basicModalMsg, basicModalButtons, basicModalCallback, devMode, layoutActions} = this.props;
        const appLocale = i18n[settings.locale] || i18n[defaultSettings.locale]; /* fallback in case translation is not defined for selected locale */ 
        appLocale.locale = settings.locale; /*set it to selected locale to get proper number, date, currency formats */

        return (
            <React.Fragment>
              { this.state.ready &&
                <IntlProvider key={this.state?.key ?? ''} locale={appLocale.locale} messages={appLocale.messages}>
                    <BrowserRouter basename='/'>
                        {devMode ? <DevWidget key={'devWidget'}/> : null}
                        <Spinner isSpinning={spinner} spinnerText={spinnerText}/>
                        {basicModalOpen && <NotificationModal
                            isOpen={basicModalOpen}
                            title={basicModalTitle}
                            closeModal={layoutActions.closeBasicModal}
                            callback={basicModalCallback}
                            message={basicModalMsg}
                            buttonTypes={basicModalButtons}
                        />}
                        <Routes />         
                {this.props.settings.sessionWidgetSettings ?
                                <SessionTimeoutWidget
                                     key={this.props.settings.sessionWidgetSettings.key}
                                     settings={this.props.settings.sessionWidgetSettings}
                                     actions={this.props.authActions}
                                /> : null}
                    </BrowserRouter>
                </IntlProvider>
            }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        settings: state.settings,
        sessionWidgetSettings : state.settings.sessionWidgetSettings,
        spinner: state.layout.spinner,
        spinnerText: state.layout.spinnerText,
        basicModalOpen: state.layout.basicModalOpen,
        basicModalTitle: state.layout.basicModalTitle,
        basicModalMsg: state.layout.basicModalMsg,
        basicModalButtons: state.layout.basicModalButtons,
        basicModalCallback: state.layout.basicModalCallback,
        devMode: state.envProps.devMode
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        settingAction: bindActionCreators(settingAction, dispatch),
        layoutActions: bindActionCreators(layoutActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
