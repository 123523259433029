import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetadataDateRangePicker from "../../../../../components/InvoiceDetails/MetadataDateRangePicker/index";
import { formatDateRangeString } from "./../../../../../utils/invoice-utils";
import { MDBInput, MDBCol } from "mdbreact";
import moment from "moment";
import { get as lodashGet } from "lodash";

const DateRangePicker = (props) => {
  const {
    filter,
    intl,
    colClassName,
    field,
    formatLabel,
    onUpdateFilters,
    isFiltersClean,
    queryParams,
    msgId,
    options,
    type,
  } = props;

  const [calendarModal, setCalendarModal] = useState(false);
  const [dateRangeString, setDateRangeString] = useState("");
  const calendarOptionType = "AVAILABLE";
  const title = formatLabel({}, props);
  const dateFilters = [
    {
      field,
      msgId,
      type,
      sort: true,
      filter: {
        type,
        options,
      },
    },
  ];
  const valueDefault = lodashGet(queryParams, field);
  const dateObject = Object.entries(queryParams).filter(([key, value]) => {
    return key.includes(field);
  });

  useEffect(() => {
    if (dateObject.length !== 0) {
      const startDate = moment(dateObject[0][1]).format("MMM D, YYYY");
      const endDate = moment(dateObject[1][1]).format("MMM D, YYYY");
      const dateRangeString = `${startDate} - ${endDate}`;
      setDateRangeString(dateRangeString);
    } else {
      setDateRangeString("");
    }
  }, [isFiltersClean, valueDefault]);

  const constructDateFilter = (dateFilters) => {
    if (dateFilters.hasOwnProperty("dateFilters")) return;
    const { calendarOptionType, startDate, endDate } = dateFilters;
    let filterStartDate = null;
    let filterEndDate = null;
    if (calendarOptionType === "all") {
      return {};
    } else if (calendarOptionType === "today") {
      filterStartDate = moment();
      filterEndDate = moment();
    } else if (calendarOptionType.split(",")[0] < 0) {
      filterStartDate = moment().add(calendarOptionType.split(",")[0], calendarOptionType.split(",")[1].trim());
      filterEndDate = moment();
    } else if (calendarOptionType.split(",")[0] > 0) {
      filterStartDate = moment();
      filterEndDate = moment().add(calendarOptionType.split(",")[0], calendarOptionType.split(",")[1].trim());
    } else if (calendarOptionType === "custom") {
      filterStartDate = moment(startDate);
      filterEndDate = moment(endDate);
    }
    return {
      startDate: moment(filterStartDate.startOf("day").toISOString()).format("YYYY-MM-DD"),
      endDate: filterEndDate.isValid() 
      ? moment(filterEndDate.endOf("day").toISOString()).format("YYYY-MM-DD") 
      : moment(filterStartDate.startOf("day").toISOString()).format("YYYY-MM-DD"),
    };
  };

  const updateDateRangeString = (dateFilters) => {
    if (dateFilters.hasOwnProperty("dateFilters")) return;
    const { calendarOptionType, startDate, endDate } = dateFilters;
    let dateRangeString = formatDateRangeString(intl, calendarOptionType, startDate, endDate);
    setDateRangeString(dateRangeString.replace("- null", ""));
    const constructDate = constructDateFilter(dateFilters);
    const result = {
      calendarOptionType,
      dateRangeString,
      ...constructDate,
    };

    onUpdateFilters(field, result, filter);
  };

  return (
    <React.Fragment>
      <div className={"md-form"}>
        <label className={"multi-select-label"}>{title}</label>
        <MDBInput id={`dateInput-${field}`} onFocus={() => setCalendarModal(!calendarModal)} value={dateRangeString} hint={title} />
      </div>
      <MetadataDateRangePicker
        autoFocusEndDate={false}
        showDefaultInputIcon={true}
        showDatePicker={() => setCalendarModal(!calendarModal)}
        showCalendar={calendarModal}
        intl={intl}
        dateFilterFields={dateFilters}
        updateFiltersAndSearch={updateDateRangeString}
        calendarOptionType={calendarOptionType}
        updateDateRangeString={updateDateRangeString}
        focusText={title}
        existingFilters={{}}
        closeFocusSelector={`#dateInput-${field}`}
      />
    </React.Fragment>
  );
};

export default DateRangePicker;
