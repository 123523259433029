import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {MDBBtn, MDBCol, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBModal} from "mdbreact";
import produce from "immer";
import {FormattedMessage, injectIntl} from "react-intl";
import classNames from "classnames";
import { bindActionCreators } from 'redux';
import * as accountActions from "../../../../actions/account-action";
import ReactPortalModal from '../../../ReactPortalModal';

class XMLEnrollModal extends Component {

    constructor(props) {
        super(props);
    }

    enrollXmlFormat = () => {

        let {selectedAccount} = this.props;

        if (selectedAccount) {
            const { accountNumber, isParent, merchant: { countryCode }, paymentType: businessUnit } = selectedAccount;
            const accountType = isParent ? "Plan":"Account";
            this.props.accountActions.enrollXml({accountType, accountNumber, countryCode, businessUnit});
        }
        
    };

    handleComplete = () => {
        
        this.props.accountActions.enrollXmlComplete();
        // this.props.updateAccounts();
        this.props.toggleModal();
        this.props.accountActions.getXmlEnrollmentComplete();
    }

    render() {
        const { isOpen, toggleModal, enrolledFinished, error, backdrop, intl } = this.props;

        let modalStep = 1;
        let hasError = false;
        if (enrolledFinished) {
            modalStep = 2;
            if (typeof error !== 'undefined') {
                hasError = true;
            }
        }
        const modalHeading = (modalStep === 2 && hasError === false) ? "ups.xml-enroll.title2" : "ups.xml-enroll.title";
        const modalLabel = "xml_enroll_model";
        return (
            <ReactPortalModal isOpen={isOpen}  an_label={modalLabel}>
                <MDBModal isOpen={isOpen} toggle={toggleModal} size="lg" backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
                    <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={this.handleComplete}>{modalStep === 2 && hasError === false ? <FormattedMessage id={"ups.xml-enroll.title2"} /> : <FormattedMessage id={"ups.xml-enroll.title"} />}</MDBModalHeader>
                    <MDBModalBody>
                        {modalStep === 1 ?
                            <React.Fragment>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <p><FormattedMessage id={"ups.xml-enroll.line1"} /></p>
                                        <p><FormattedMessage id={"ups.xml-enroll.line2"} /></p>
                                    </MDBCol>
                                </MDBRow>
                            </React.Fragment>
                            : null}

                        {modalStep === 2 && hasError === false ?
                            <React.Fragment>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <p><FormattedMessage id={"ups.xml-enroll.confirmation.line1"} /></p>
                                        <p><FormattedMessage id={"ups.xml-enroll.confirmation.line2"} /></p>
                                    </MDBCol>
                                </MDBRow>
                            </React.Fragment>
                            : null}
                        {modalStep === 2 && hasError === true ?
                            <React.Fragment>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <p><FormattedMessage id={"ups.xml-enroll.error"} /></p>
                                    </MDBCol>
                                </MDBRow>
                            </React.Fragment>
                            : null}
                    </MDBModalBody>
                    <MDBModalFooter aria-live="polite">
                        {modalStep === 1 ?
                            <React.Fragment>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <MDBBtn color={"primary"} id="enroll-xml-modal-btn-submit" onClick={this.enrollXmlFormat}><FormattedMessage id={"ups.btn.submit.label"} /></MDBBtn>
                                    </MDBCol>
                                    <MDBCol size={"12"}>
                                        <MDBBtn flat id="enroll-xml-modal-btn-cancel" className={classNames({ "btn-flat-link": true })} onClick={this.handleComplete}><FormattedMessage id={"ups.btn.enroll.cancel.label"} /></MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </React.Fragment>
                            : null}
                        {modalStep == 2 ?
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <MDBBtn color="primary" id="enroll-xml-modal-btn-close" onClick={this.handleComplete}><FormattedMessage id={"close.label"} /></MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            : null}
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        enrolledFinished: state.account.enrolledFinished,
        error: state.account.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (injectIntl(XMLEnrollModal)));