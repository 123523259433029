import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBSpinner
} from "mdbreact";
import { formatCurrency } from "../../../utils/invoice-utils";
import { useCookieState } from "../../../utils/cookie-utils"
import Alert from "../../Alert"
import classNames from "classnames";
import { TOTAL_INVOICES } from '../../../constants/invoice-constants';
import {isFreightActive} from "../../../utils/config-utils";

const SummmaryCard = ({ title, children, className }) =>
(
    <div className={classNames('summary-widget-item account-summary-item', className)}>
        <div className={"summary-widget-item-label account-summary-item-label"}><FormattedMessage id={title} /></div>
        <div className={"summary-widget-item-value account-summary-item-value"}>{children}</div>
    </div>
);


export default function DashboardSummary({ loading, invoicesSummary, currencyCode, onViewPaymentHistory, pastDueTotals, onPayInvoices, businessUnit, queryParams }) {
    const intl = useIntl();
    const history = useHistory();
    const { user } = useSelector(s => s.auth);
    const { merchant } = useSelector(s => s.config?.sessionSelection ?? {})
    const { errorCode } = useParams();
    const dispatch = useDispatch();
    const [pay3dSecure, setPay3dSecure] = useCookieState('c3dSecure');
    const totalDue = invoicesSummary?.totalDueAmount;
    const plansCount = invoicesSummary?.plansCount;
    const accountsCount = invoicesSummary?.accountsCount;

    const filters = { accounts: true, totals: true, keyToAccessInvoices: 'totals' };
    useEffect(() => {
        if (errorCode) {
            setPay3dSecure({});
        }
    }, [])





    return (
        <MDBRow>
            <MDBCol>
                <MDBCard className={"summary-widget-card dashboard-summary-widget-card"}>
                    <MDBCardHeader tag="h2" className={"summary-widget-title"}>
                        <FormattedMessage
                            id="dashboard.summary.welcome"
                            values={{
                                name: user.firstName
                            }}
                        /></MDBCardHeader>
                        <MDBCardBody className={"summary-widget-body"}>
                            {errorCode && (<MDBRow>
                                <MDBCol size="12">
                                    <Alert label={errorCode} />
                                </MDBCol>
                            </MDBRow>)}
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <div className={"summary-widget-items dashboard-account-summary-items"}>
                                        <SummmaryCard title="dashboard.summary.total-due">
                                            {loading ? <MDBSpinner className={'small-spinner'}/> : formatCurrency(intl, totalDue, currencyCode, merchant.currencyDisplay)}
                                        </SummmaryCard>
                                        <SummmaryCard title="dashboard.summary.total-past-due" className={pastDueTotals > 0 ? 'alert-past-due' : null}>
                                            {loading ? <MDBSpinner className={'small-spinner'}/> : formatCurrency(intl, pastDueTotals, currencyCode, merchant.currencyDisplay)}
                                        </SummmaryCard>
                                        <div className={"summary-widget-item summary-widget-cta flex-grow d-flex d-md-block flex-column text-md-right "}>
                                            
                                            {!loading && (businessUnit !== 'FRT' || isFreightActive(merchant)) && (user.permissions.hasOwnProperty("otp_payment") && !!plansCount) &&
                                                <MDBBtn className="btn-primary"
                                                onClick={()=> onPayInvoices(false, '', TOTAL_INVOICES)}
                                                ><FormattedMessage id="dashboard.payPlans" />
                                                </MDBBtn>
                                            }
                                            {!loading && (businessUnit !== 'FRT' || isFreightActive(merchant)) && (user.permissions.hasOwnProperty("otp_payment") && !!accountsCount) &&
                                                <MDBBtn className="btn-primary dashboard-act-btn"
                                                onClick={()=> onPayInvoices(true, filters, TOTAL_INVOICES)}
                                                ><FormattedMessage id="dashboard.payAccounts" />
                                                </MDBBtn>
                                            }
                                            {user.permissions.hasOwnProperty("payment_search") ? <MDBBtn color={"text-link"} onClick={onViewPaymentHistory} id="btn-dashboard-view-payment-history" className={"btn-dashboard-view-payment-history"}><FormattedMessage id="dashboard.btn.view-payment-history" /></MDBBtn> : null}
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
}
