import React from "react";
import {useIntl, FormattedMessage} from "react-intl";
import * as R from "ramda";

import InputFieldIntl from "./InputFieldIntl";
import {InputRestrictors} from "../utils/validation/standard-validations";

const getHint = R.cond([
    [R.equals("3"), R.always("payment-method.credit-card.cvv.placeholder")],
    [R.equals("4"), R.always("payment-method.credit-card.cvv.AMEX.placeholder")],
    [R.T,           R.always("payment-method.credit-card.cvv.placeholder")]
]);

export default function CvvField({name, id, value, onChange, length}) {
    const intl = useIntl();

    return <div>
        <InputFieldIntl name={name} id={id}
            key={`cvv-${length}`}
            type="password"
            label={intl.formatMessage({id: "payment-method.credit-card.cvv"})}
            restrict={InputRestrictors.numeric}
            value={value}
            onChange={onChange}
            hint={getHint(length)}
            maxLength={length}
            validations={[[`cvv${length}`, "field.cvv"]]}
        />
        {!value && <div className="field-info">
            <FormattedMessage id="payment-method.credit-card.cvv.field-info"/>
        </div>}
    </div>;
};
