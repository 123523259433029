import React, { useRef } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBModalBody } from 'mdbreact';
import { useIntl, FormattedMessage } from 'react-intl';

import SepaMandatePreview from './SepaMandatePreview';
import CheckboxWithValidation from '../CheckboxWithValidation';
import { useReactToPrint } from "react-to-print";

export default function SepaMandateDownload({ payment, schedule }) {
    const componentRef = useRef();
    const intl = useIntl();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <MDBBtn aria-label={intl.formatMessage({id: 'sepa-print-aria-label'})} color={"datatable-text-link"} onClick={handlePrint}>
            <FormattedMessage id='print.label' />
            <div style={{ overflow: "hidden", height: "0", width: "0" }} >
                <div ref={componentRef}>
                    <MDBModalBody className="print-only-content">
                        <MDBRow className='sepa-mandate-container'>
                            <MDBCol size='12'>
                                <h3>
                                    <FormattedMessage id='sepa-mandate.card-header.title' />
                                </h3>
                            </MDBCol>
                            <MDBCol size='12'>
                                <SepaMandatePreview payment={payment} schedule={schedule} />
                            </MDBCol>

                            <MDBCol size='12'>
                                <CheckboxWithValidation
                                    id='authorizeSepa'
                                    name='authorizeSepa'
                                    label={intl.formatMessage({
                                        id: 'sepa-mandate.sign.otp.approval'
                                    })}
                                    value={true}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </div>
            </div>
        </MDBBtn>

    );
}