import {FormattedMessage} from "react-intl";
import {MDBBtn} from "mdbreact";
import React from "react";

export function SuperPaging ({formattedPageSize, pages, getNextPage, hasSeenLastPage, page, isLoadMoreVisible}) {

    if (page.length <= 1 || !isLoadMoreVisible) {
        return <></>
    }

    return <>
        <p><FormattedMessage id={'invoice.details.load-more.hint'} values={{
            b: chunks => <b>{chunks}</b>,
            formattedPageSize
        }} />
        </p>
        {pages.map(p =>
            <MDBBtn
                color={'primary'}
                disabled={page === p}
                key={`page-${p}`}
                onClick={() => this.setState({ page: p }, ()=>this.requestInvoiceDetails())}
            >
                {p+1}
            </MDBBtn>
        )}
        {!hasSeenLastPage && (
            <MDBBtn color={'primary'} onClick={() => {
                getNextPage();
            }}><FormattedMessage id={'invoice.details.load-more.button.label'}/></MDBBtn>
        )}
    </>
}