import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import { default as ReactSelect, components } from "react-select";
import { injectIntl, useIntl } from 'react-intl';
import PopOver from "../PopOver/index";
import {equals, isNil} from "ramda";

const MultiValue = (props) => {
    const intl = useIntl();
    const options = props.selectProps.options[0]?.options
    let labelToBeDisplayed = null;
    if (props.data.value === "*") {
        labelToBeDisplayed = intl.formatMessage({id:"ups.selectAll"});
    } else {
        const option = options?.find((option)=>(equals(option.value, props.data.value)))
        if(option?.label) {
            labelToBeDisplayed = option.label
        } else if(option?.msgId){
            labelToBeDisplayed = intl.formatMessage({id:option.msgId });
        }
    }
    if (!props.data.isDisabled) {
        return (
            <components.MultiValue {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValue>
        );
    } else {
        return null;
    }

};

const MultiValueRemove = props => {
    return null;
};

const Option = (props) => {
    return (
        <components.Option {...props}>
            <span className={"react-select__option__label"}>{props.label}</span>
        </components.Option>
    );
};


const ValueContainer = ({children, ...props }) => {
    const {allOption} = props.selectProps
    const currentValues = props.getValue();
    let toBeRendered = children;
    const [_values, input] = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }
    return (
        <components.ValueContainer {...props} >
            <div className="selected-options--container">
                {toBeRendered}
            </div>
                {input}
        </components.ValueContainer>
    );
};

const filterDisabled = values => {
    return values.filter(v => !v.isDisabled);
};

const intlParseOptions = (options, intl) => {
    let parsedOptions = [{options:[]}]
    parsedOptions[0].options = options[0]?.options.map(option => ({...option, label: option.msgId ? intl.formatMessage({id:option.msgId}) : option.label}))
    parsedOptions[0].label = options[0]?.msgId ? intl.formatMessage({id:options[0]?.msgId}) : "";
    return parsedOptions
}

const checkAllSelectedMismatch = (value, options, allOption, startWithAll) => {
    //select all by default if we have nothing set yet, unless we want to start with none selected
    if(isNil(value) && startWithAll) return true
    //if we somehow have less options selected than the total amount of options but the "all" option is selected, we should select any erroneously not-selected options
    if((value?.[0]?.value === allOption.value) && (value?.length < options[0]?.options.length)) return true
}

const cleanupInvalidValues = (value, options) => {
    return value?.filter(({value})=>!!options[0]?.options.find(({value: optionsValue})=>(equals(value, optionsValue))))
}

const NoOptionsMessage = props => {
    const intl = useIntl();
    return (
      <components.NoOptionsMessage {...props}>
        <span className="react-select__menu">{intl.formatMessage({id: 'no.options'})}</span> 
      </components.NoOptionsMessage>
    );
  };

// based off of: https://codesandbox.io/embed/ecstatic-waterfall-e87xz
// modified to work with 1 group, where a group has a group label and options
// group label is 'Select your filters'
const ReactSelectAllGrouped = props => {
    const intl = useIntl();
    const [inputLabel, setlabel] = useState("");

    const newValue = useMemo(()=>{
        if(props.isMulti && props.options.length !== 0){
            let newVal = props.value
            const {options, allOption, startWithAll} = props;
            newVal = cleanupInvalidValues(newVal, options)
            if(checkAllSelectedMismatch(newVal, options, allOption, startWithAll)) newVal = [...options[0]?.options]
            return newVal
        } else return props.value
    }, [props.value, props.options.length])

    useEffect (()=> {
        let ariaLabel = props.label ? intl.formatMessage({id: `ups.select-filter.label${props.isMulti? '.multi' : ''}`}, {label: props.label}) : "";
        const noFilterLabel = intl.formatMessage({id: "ups.aria-label.no-filter-selected"});

        if(props.isMulti){
            const multiValues  = document.querySelectorAll(`#react-select-${props.inputId} .react-select__multi-value`);
            ariaLabel += multiValues?.length > 0 ? intl.formatMessage({id: `ups.selected.label.multi`}, {label: [...multiValues].map(x => x.textContent).join(', ')}) : noFilterLabel;
        }
        else{
            const elements  = document.querySelectorAll(`#react-select-${props.inputId} .react-select__single-value`);
            ariaLabel += elements?.length > 0 ? intl.formatMessage({id: `ups.selected.label`}, {label: elements[0].textContent}) : noFilterLabel;
        }

        setlabel(ariaLabel);

        if(!props.isSearchable){
            const inputElement  = document.getElementById(props.inputId);
            if(props.value?.length > 0){
                if(!props.isMulti && !props.value[0]){ // undefined drop-down value that shows only placeholder
                    inputElement?.classList.remove("zero-height");
                }
                else{
                    inputElement?.classList.add("zero-height");
                }
            }
            else
                inputElement?.classList.remove("zero-height");
        }

    }, [props.value]);

    if (props.allowSelectAll) {
        return (
            <React.Fragment>
                <div className={"react-select-form-group"}>
                    {props.label ? <label className="react-select-label" >{props.label}</label> : ''}
                    {props.popoverHelp ? <PopOver popoverHelp={props.popoverHelp} key={`popover-${props.inputId}`} /> : null}
                    <ReactSelect
                        id={`react-select-${props.inputId}`}
                        {...props}
                        value={newValue}
                        aria-label={inputLabel}
                        components={{Option,ValueContainer, MultiValue, MultiValueRemove,NoOptionsMessage ,...props.components }}
                        options={props.intlLabel ? intlParseOptions(props.options, intl) : props.options}
                        onChange={(selected, event) => {
                            if (selected !== null && selected.length > 0) {
                                //don't need the labels in the selected values, only the value itself
                                let selectedVals = selected.map(({value})=>({value}))
                                // if All checked, select all
                                if (selectedVals[selectedVals.length-1].value === props.allOption.value) {
                                    const allOptions = [...props.options[0].options]
                                    return props.onChange(allOptions);
                                }

                                let result = [];
                                if (selectedVals.length === (props.options[0].options.length-1) ) {
                                    // determine if everything is selected except All, if it is, check All
                                    // otherwise uncheck All option
                                    if (selectedVals.find(s => s.value === props.allOption.value)) {
                                        result = selectedVals.filter(
                                            option => option.value !== props.allOption.value
                                        );
                                    } else {
                                        if (event.action === "select-option") {
                                            result = [...props.options[0].options];
                                        }

                                    }
                                    return props.onChange(result);
                                }
                                return props.onChange(selectedVals)
                            }
                            else return props.onChange(selected);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {props.label ? <label className="react-select-label">{props.label}</label> : ''}
            {props.popoverHelp ? <PopOver popoverHelp={props.popoverHelp} key={`popover-${props.inputId}`} /> : null}
            <ReactSelect id={`react-select-${props.inputId}`} {...props} aria-label={inputLabel}
            components={{NoOptionsMessage,...props.components }}
            />
        </React.Fragment>
    );
};

ReactSelectAllGrouped.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        value: PropTypes.string,
        msgId: PropTypes.string,
        label: PropTypes.any
    }),
    startWithAll: PropTypes.bool,
    inputId: PropTypes.string
};

ReactSelectAllGrouped.defaultProps = {
    allOption: {
        value: "*",
        msgId: "filter.multi.option.dropdown.all.selector",
        label: "filter.multi.option.dropdown.all.selector"
    },
    startWithAll: true
};

export default injectIntl(ReactSelectAllGrouped);
