const defaultSetting  = {
    'local-storage-token-name': 'fake-token',
    'locale':'en-US',
    'datatableShowIssuer': true,
    'isMobile': false,
    'fixedHeader': true,
    'fixedSideMenu': false,
    'collapsedSideMenu': false,
    'supportedLanguages': ['en-US'], // 'en-US','es','zh-CN' for more codes: http://www.unicode.org/cldr/charts/28/supplemental/language_plural_rules.html
    'debugMode': true
};
export default defaultSetting;