import React from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody } from "mdbreact";
import UPSPreferenceView from "../../components/UPS/UPSPreferenceModal/index";
import { Link } from 'react-router-dom';

class UPSProfilePreferences extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {intl} = this.props;
        /* Support for multiple modals of preferences for different components. 
           TODO: Preferences of different components to be shown in tabs. 
        */

        return (
            <MDBContainer role="main" fluid>              
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.title.dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span></li>

                                                <li className="ups-breadcrumb-item">
                                                    <FormattedMessage id="ups.title.profile" />
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                
                                                <li className="ups-breadcrumb-item">
                                                    <FormattedMessage id="side-nav.dashboard-payments-overview" />
                                                </li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}>
                                    <FormattedMessage id="side-nav.dashboard-payments-overview" />
                                </h1>
                                <UPSPreferenceView colSize={"6"} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default injectIntl(UPSProfilePreferences);