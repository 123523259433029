


const sessionWidgetSettings  = {
    warningDurationSeconds: 60,
    config: {
        sessionTimeoutSeconds: 3000,
    },
    key : 'SessionTimeoutWidget'
};

export default sessionWidgetSettings;


   




