import React, {Component} from 'react';
import {connect} from 'react-redux';
import {MDBModal, MDBModalBody, MDBModalHeader, MDBCard} from 'mdbreact';
import {bindActionCreators} from "redux";
import AccountDetails from '../../AccountDetails';
import InvoiceDetails from '../../InvoiceDetails';
import AddAccountConfirmation from '../../AddAccountConfirmation/index';
import {FormattedMessage,injectIntl} from "react-intl";
import ReactPortalModal from "../../../ReactPortalModal";
import * as accountActions from "../../../../actions/account-action";
import ProgressBar from '../../../../components/UPS/ProgressBar';

const stepNames = [
    {key: 0, label: "addAccount.account.details", an_label: "account-details"},
    {key: 1, label: "addAccount.invoice.details", an_label: "invoice-details"},
    {key: 2, label: "addAccount.confirmation", an_label: "confirmation"}
];

class AddAccountModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {isOpen, toggleModal, isModal, backdrop, accountTypeOverride,intl} = this.props;
        const {accountAdded, aiaRequired} = this.props;
        
        let addAccountStep = 1;
        if (aiaRequired) {
            addAccountStep = 2;
        }
        if (accountAdded) {
            addAccountStep = 3;
        }

        const addAccountStepComponents = [];
        if (addAccountStep === 1) {
            addAccountStepComponents.push(
                <AccountDetails key="accountDetails"
                                submitAction={this.props.accountActions.addAccount}
                                handleCancel={this.handleCancel}
                                isModal={isModal}
                                accountTypeOverride={accountTypeOverride}
                />
            )
        } else if (addAccountStep === 2) {
            addAccountStepComponents.push(
                <InvoiceDetails key="invoiceDetails"
                                submitAction={this.props.accountActions.addAccount}
                                handleRestart={this.handleRestart}
                                handleCancel={this.handleCancel}
                                isModal={isModal}
                />
            )
        } else if (addAccountStep === 3) {
            addAccountStepComponents.push(
                <AddAccountConfirmation key="confirmation"
                                        handleClose={this.handleComplete}/>
            )
        }
        const modalLabel = `add-${accountTypeOverride.toLowerCase()}` + (stepNames[addAccountStep - 1] ? `|${stepNames[addAccountStep - 1].an_label}` : "");
        const modalHeading = "addAccount." + accountTypeOverride.toLowerCase() + ".title";

        return (
            <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                <MDBModal isOpen={isOpen}  toggle={toggleModal} size="lg" disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({ id: "close.dialog.btn" }, { name: intl.formatMessage({ id: modalHeading }) })} toggle={accountAdded ? this.handleComplete : this.handleCancel}><FormattedMessage id={modalHeading}/></MDBModalHeader>
                    <MDBModalBody>
                        <section className={"ups-section ups-enroll-section"}>
                            <section className={"ups-enroll-subsection mb-4"}>
                                <MDBCard>
                                    <ProgressBar stepNames={stepNames} currentStep={addAccountStep-1} isShown={true}/>
                                </MDBCard>
                            </section>
                            {addAccountStepComponents}
                        </section>
                    </MDBModalBody>
                </MDBModal>
            </ReactPortalModal>
        );
    }

    handleRestart = () => {
        this.props.accountActions.restartAddAccount();
    };

    handleCancel = () => {
        this.props.toggleModal();
        this.props.accountActions.cancelAddAccount();
    }

    handleComplete = () => {
        this.props.toggleModal();
        this.props.accountActions.cancelAddAccount();   
        this.props.updateAccounts(this.props.accountAdded);    
    }

}

function mapStateToProps(state, ownProps) {
    return {
        eligibility: state.account.eligibility,
        aiaRequired: state.account.aiaRequired,
        accountAdded: state.account.accountAdded
    }
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddAccountModal));