import React from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCard, MDBCardBody } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIntl from '../../CheckboxIntl';
import { InputRestrictors, StandardValidations as stdv } from '../../../utils/validation/standard-validations';
import PayByTextModal from '../Modal/PayByTextModal';
import MaskedNumberField from '../../MaskedNumberField';
import InputFieldIntl from '../../InputFieldIntl';
import { Component } from 'react';
import attachValidator from "../../../utils/validation/attach-validator.js";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as identityActions from "../../../actions/identity-action";
import * as validationActions from "../../../actions/validation-action";
import { bindActionCreators } from 'redux';
import { isPBTSupportedCountry, getPBTLanguagesByCountryCode, getLanguagesForPBT, defaultMobileNumberCountry, getMobileNumberCountryList, getCountryDialCode } from '../../../utils/payByText-utils.js';
import { set as lodashSet, get as lodashGet } from 'lodash';
import produce from "immer";
import { getSupportedLanguages } from "../../../utils/ups-utils.js";
import CommonModalData from '../Modal/CommonModalData';
import { formatBool, generateCountryList, sortCountryList } from "../../../utils/utils";
import SaveModal from '../Modal/SaveModal';
import { isPBTConfigEnabled } from "../../../utils/config-utils";
import PreferencesPrompt from './PreferencesPrompt';
import NotificationModal, { BUTTON_TYPES } from '../Modal/NotificationModal';
import {ERROR_TYPE} from "../../../constants/error-type";
import Alert from "../../Alert";

class PayByTextPreferences extends Component {

    constructor(props) {
        super(props);
        this.state = this.getIntialState();
        attachValidator.call(this);
    }

    componentDidMount() {
        const {showPayByTextPreferences} = this.state;
        const {history} = this.props;

        if(!showPayByTextPreferences) {
            history.push({
              pathname: '/error',
              errorType: ERROR_TYPE.PBT_NOT_SUPPORTED,
              redirectPath : 'home'
            })
          }
    }

    getIntialState() {
        const { auth } = this.props;
        const languages = this.getPrefLanguages("PBT");
        const userPreferences = this.getUserPreferences(languages);
        const currCountry = this.getCurrentCountry();
        const selectedPaymentType = auth.user?.selectedPaymentType;

        const showPayByTextPreferences = isPBTSupportedCountry(currCountry, selectedPaymentType);
        return {
            languages: languages,
            showPayByTextPreferences: showPayByTextPreferences,
            pbtSelectedLanguage: userPreferences.payByTextSettings.selectedLanguageCode,
            payByTextSettings: userPreferences.payByTextSettings,
            mobileNumber: userPreferences.mobileNumber,
            payByTextLink: userPreferences.payByTextLink,
            mobileNumberCountryCode: userPreferences.mobileNumberCountryCode,
            pbtLanguages: userPreferences.pbtLanguages,
            savedPreferenceSection: '',
            openModal: false,
            modalComp: '',
            saveButtonModalComp: '',
            saveButtonOpenModal: false,
            saveModalData: <CommonModalData />,
            isPageDirty: false,
            showErrorMessage: false
        };
    }

    getCurrentCountry() {
        return this.props.auth.user?.selectedCountry || '';
    }

    savePreferences = (preferences, commonSaveModalData) => {
        let result = this.validator.validateAll();
        if (result.messages.length > 0) return false;
        const { payByTextSettings } = this.state;
        this.setState({
            saveModalData: commonSaveModalData
        })
        const { identityActions } = this.props;
        if (Object.keys(preferences).length) {
            identityActions.saveIdentityPreferences(preferences, 'payByText', (apiSuccess) => {
                if (apiSuccess.status === true) {
                    this.setState({
                        saveButtonOpenModal: true,
                        saveButtonModalComp: <SaveModal />
                    });
                    if (payByTextSettings['enabled']) {
                        this.setState({ payByTextLink: true });
                    } else {
                        this.setState({ payByTextLink: false });
                    }
                } else {
                    this.setState({ showErrorMessage: true, errorCode : apiSuccess.errorCode ?? 'internalError'});
                }
            });
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false
        });
    }

    saveButtonCloseModal = () => {
        this.setState({
            saveButtonOpenModal: false
        });
    }

    goToPayByText = () => {
        const { history } = this.props;
        history.push("/admin/manage/paybytext");
    }

    unformat = (val) => {
        return val.replace(/[^0-9+]/ig, "");
    }

    componentDidUpdate(prevProps) {
        const currCountry = this.getCurrentCountry();
        const prevCountry = prevProps.auth.user?.selectedCountry || '';
        const selectedPaymentType = this.props.auth.user?.selectedPaymentType;

        if (currCountry != prevCountry || selectedPaymentType != prevProps.auth.user?.selectedPaymentType) {
            if (isPBTSupportedCountry(currCountry, selectedPaymentType)) {
                this.setState({
                    showPayByTextPreferences: true,
                    pbtSelectedLanguage: this.getSelectedPBTLangugaue()
                });
            }
            else {
                this.setState({
                    showPayByTextPreferences: false
                });
            }
        }
    }

    getCurrentCountry() {
        return this.props.auth.user?.selectedCountry || '';
    }

    getPrefLanguages(section) {
        const { merchants, preferences, auth } = this.props;
        let countries = [];
        let prefCountryInfo = null;

        if (merchants && merchants.length > 0) {
            countries = [...new Set(merchants.map(m => m.countryCode))];
        }

        if (preferences) {
            if (section === "PBT" && preferences?.payByTextSettings?.selectedLanguageCode) {
                const pbtCountryCode = preferences.payByTextSettings.countryCode ? preferences.payByTextSettings.countryCode : preferences.payByTextSettings.selectedLanguageCode.split("-")[1];

                if (countries.findIndex(x => x === pbtCountryCode) === -1) {
                    countries.splice(0, 0, pbtCountryCode);
                    prefCountryInfo = {
                        country: pbtCountryCode,
                        language: preferences.payByTextSettings.selectedLanguageCode
                    }
                }
            }
        }

        if (countries.findIndex(x => x === auth.user.selectedCountry) === -1) {
            countries.splice(0, 0, auth.user.selectedCountry)
        }

        /* This check will add the country-locale that user has chosen either via SSO or set as preference
            but that country account is no longer enrolled, so only that selected locale option will be added to list
            and not all the locales associated with that particular country
        */
        let supportedLocales = getSupportedLanguages(countries);

        if (prefCountryInfo) {
            for (let i = supportedLocales.length - 1; i >= 0; i--) {
                if (supportedLocales[i].country === prefCountryInfo.country && supportedLocales[i].code !== prefCountryInfo.language) {
                    supportedLocales.splice(i, 1);
                }
            }
        }
        return supportedLocales;
    }

    getPayByTextPreferences(currCountry) {
        const { preferences } = this.props;

        let payByTextPref = {
            payByTextSettings: { enabled: false, selectedLanguageCode: (isPBTSupportedCountry(currCountry) ? getPBTLanguagesByCountryCode(currCountry) : null), countryCode: currCountry }
        }

        const payByTextKeys = Object.keys(preferences.payByTextSettings);
        payByTextKeys.forEach((key) => {
            payByTextPref.payByTextSettings[key] = preferences.payByTextSettings[key];
        });
        if (!payByTextPref.payByTextSettings.selectedLanguageCode) {
            payByTextPref.payByTextSettings.selectedLanguageCode = getPBTLanguagesByCountryCode(currCountry)
        }
        if (payByTextKeys && preferences.payByTextSettings.enabled) {
            payByTextPref.payByTextLink = true;
        }

        if (!preferences.payByTextSettings.countryCode && payByTextPref.payByTextSettings.selectedLanguageCode) {
            payByTextPref.payByTextSettings.countryCode = payByTextPref.payByTextSettings.selectedLanguageCode.split("-")[1];
        }

        return payByTextPref;
    }

    getSelectedPBTLangugaue() {
        const { languages } = this.state;
        const userPreferences = this.getUserPreferences(languages);
        let userSelectedPbtlanguage = userPreferences?.payByTextSettings?.selectedLanguageCode;
        let selectedPbtLanguageCountry = userPreferences?.payByTextSettings?.countryCode;

        let selectedLanguageCode = null;
        /* this logic will ensure that we get proper lang id based on the combination of lang code and country code.
           This will be required when European countries will start supporting Pay by text */
        if (selectedPbtLanguageCountry && userSelectedPbtlanguage) {
            languages.forEach((item) => {
                if (item.country === selectedPbtLanguageCountry && item.code === userSelectedPbtlanguage) {
                    selectedLanguageCode = item.id;
                }
            });
        } else {
            selectedLanguageCode = userSelectedPbtlanguage;
        }
        return selectedLanguageCode;
    }

    getUserPreferences(languages) {
        const { preferences } = this.props;
        const currCountry = this.getCurrentCountry();
        const pbtLanguages = this.getPrefLanguages("PBT");

        let payByTextPreferences = {
            language: Object.assign({}, (languages.length ? languages[0] : {})),
            payByTextLink: false,
            payByTextSettings: { enabled: false, selectedLanguageCode: (isPBTSupportedCountry(currCountry) ? getPBTLanguagesByCountryCode(currCountry) : null), countryCode: currCountry },
            mobileNumber: preferences?.mobileNumber || null,
            mobileNumberCountryCode: preferences?.mobileNumberCountryCode || (isPBTSupportedCountry(currCountry) ? defaultMobileNumberCountry(pbtLanguages, currCountry) : null),
            pbtLanguages: pbtLanguages
        };

        if (preferences) {
            if (preferences.payByTextSettings) {
                payByTextPreferences = { ...payByTextPreferences, ...(this.getPayByTextPreferences(currCountry)) };
            }

        }
        payByTextPreferences["pbtSelectedLanguage"] = preferences?.showPayByTextPreferences ? this.getSelectedPBTLangugaue() : '';
        return payByTextPreferences;
    }

    handlePreferenceChange = () => {
        const languages = this.getPrefLanguages("PBT");
        const userPreferences = this.getUserPreferences(languages);

        const { payByTextSettings, mobileNumberCountryCode, mobileNumber } = this.state;
        const currentSettings  = { payByTextSettings, mobileNumberCountryCode, mobileNumber };
        const savedSettings  = { 
            payByTextSettings: userPreferences.payByTextSettings,
            mobileNumberCountryCode: userPreferences.mobileNumberCountryCode,            
            mobileNumber: userPreferences.mobileNumber
        }

        if(JSON.stringify(savedSettings) !== JSON.stringify(currentSettings)){
            this.setState({
                isPageDirty: true
            })
        }
        else{
            this.setState({
                isPageDirty: false
            })
        }
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            }), () => {
                this.handlePreferenceChange();
            }
        );
    };

    handleMaskedInput = (value, e) => {
        const inputName = e.target.name;
        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, value);
            }), () => {
                this.handlePreferenceChange();
            }
        );
    }

    onLanguageChange = e => {
        const inputValue = e.target.value;
        const { payByTextSettings, pbtLanguages } = this.state;

        let pbtSettings = JSON.parse(JSON.stringify(payByTextSettings));
        const pbtLangInfo = pbtLanguages.find(x => x.id === inputValue);

        pbtSettings['selectedLanguageCode'] = pbtLangInfo.code;
        pbtSettings['countryCode'] = pbtLangInfo.country;
        /* setting country code for PBT as for European countries this would be required since en-GB is the common langugae code for all European countires
        and just on the basis of en-GB, country of the user cannot be chosen which will provide wrong info on the UI. 
        */

        this.setState({
                pbtSelectedLanguage : inputValue,
                payByTextSettings : pbtSettings
            }, () => {
                this.handlePreferenceChange();
        });
    }

    handleCheckBox = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.checked;

        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            }), () => {
                this.handlePreferenceChange();
            }
        );
    }

    preparePBTPreferences = () => {
        const currCountry = this.getCurrentCountry();
        let pbtPref;
        if (isPBTSupportedCountry(currCountry)) {
            const { payByTextSettings, mobileNumber, mobileNumberCountryCode } = this.state;
            const countryDialCode = getCountryDialCode(mobileNumberCountryCode);

            pbtPref = {
                payByTextSettings: payByTextSettings,
                mobileNumber: mobileNumber,
                countryDialCode,
                mobileNumberCountryCode
            }

            this.setState({
                savedPreferenceSection: pbtPref.payByTextSettings.enabled ? 'paybytext' : ''
            });
        }
        if (pbtPref?.payByTextSettings['enabled']) {
            this.setState({ payByTextLink: true });
        } else {
            this.setState({ payByTextLink: false });
        }

        if (pbtPref.payByTextSettings.enabled) {
            this.savePreferences(pbtPref, <CommonModalData dataId='paybytext' actions={{ goToPayByText: this.goToPayByText }} />);
        }
        else {
            this.savePreferences(pbtPref, <CommonModalData />);
        }
    }

    handleSavePBT = () => {
        let result = this.validator.validateAll();
        if (result.messages.length == 0){
            this.setState({isPageDirty: false});
        }
        this.preparePBTPreferences();
    }

    render() {
        const { auth, intl, preferences, allMerchants } = this.props;
        const { permissions, selectedCountry } = auth.user;
        const { payByTextSettings, pbtSelectedLanguage, mobileNumberCountryCode, mobileNumber, payByTextLink, pbtLanguages, openModal, modalComp, saveButtonOpenModal, saveModalData, saveButtonModalComp, isPageDirty, showErrorMessage, errorCode } = this.state;
        const isPBTEnabledInUserPref = preferences?.payByTextSettings?.enabled || false;
        const hidePBTDataAndBtn = !isPBTEnabledInUserPref && !payByTextSettings.enabled;
        const pbtLanguagesCountry = getLanguagesForPBT(pbtLanguages);
        const pbtCountryDialCodeData = getMobileNumberCountryList(pbtLanguagesCountry);
        const sortedPbtLanguagesCountry = sortCountryList(generateCountryList(pbtLanguagesCountry, null, intl));

        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.title.dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span></li>
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/ups/billing/profile"><FormattedMessage id={'ups.title.profile'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                    <FormattedMessage id="ups.title.payByText" />
                                                </li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>

                                <h1 className={"has-breadcrumb"}>
                                    <FormattedMessage id="ups.title.payByText" />
                                </h1>

                                <React.Fragment>
                                    <div className="ups-breadcrumb preferences">
                                        <MDBRow>

                                            <MDBCol size="12 mt-2">
                                                <CheckboxIntl
                                                    name={"payByTextSettings.enabled"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.paybytext.header" /></span>}
                                                    value={payByTextSettings.enabled}
                                                    onChange={this.handleCheckBox}
                                                    containerClass="pl-0 m-0"
                                                />
                                            </MDBCol>
                                            <div className={!payByTextSettings.enabled && isPBTEnabledInUserPref ? "disable-section col-12 mt-2 pl-0" : "col-12 mt-2 pl-0"}>
                                                {(hidePBTDataAndBtn) ? null :
                                                    <React.Fragment>
                                                        <MDBCol size="12" className="mb-3">
                                                            <Alert type="warning" className="informationcirclealign" label="ups.preferences.paybytext.note1"/>
                                                        </MDBCol>

                                                        {openModal ?
                                                            <MDBRow>
                                                                <MDBCol>
                                                                    <PayByTextModal
                                                                        key={'paytext-modal'}
                                                                        isOpen={openModal}
                                                                        closeModal={this.closeModal}
                                                                        component={modalComp}
                                                                        languageCode={auth.user.locale}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            :
                                                            null
                                                        }

                                                        <MDBCol size={"12"}>
                                                            <p className="ups-note-1 mb-0">*<span className="font-italic"><FormattedMessage id="ups.required-field.note" /></span></p>
                                                        </MDBCol>

                                                        <MDBCol size={"12"} className="mt-5 mb-2">
                                                            <div className="md-form md-browser-default-form browser-default-select col-md-4 pl-0">
                                                                <label className="browser-default-label label-first">
                                                                    <span className="required-field"><FormattedMessage id="ups.preferences.paybytext.langPref" /></span>
                                                                </label>
                                                                <select
                                                                    key={"pbtSelectedLanguage"}
                                                                    name={"pbtSelectedLanguage"}
                                                                    id={"pbtSelectedLanguage"}
                                                                    className={"browser-default custom-select"}
                                                                    required
                                                                    aria-required="true"
                                                                    onChange={this.onLanguageChange}
                                                                    value={pbtSelectedLanguage}
                                                                    aria-label={intl.formatMessage({ id: 'ups.preferences.paybytext.langPref'})}
                                                                >
                                                                    {sortedPbtLanguagesCountry.map((item, index) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {intl.formatMessage({ id: "country-language-item." + item.id })}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </MDBCol>

                                                        <MDBCol size={"4"} className="mt-5 mb-2 pref-phone-number">

                                                            <div className="md-form mt-0">
                                                                <span>
                                                                    <div className="popover-div">
                                                                        <legend><span className="required-field"><FormattedMessage id="ups.preferences.paybytext.mobile-number" /></span></legend>
                                                                    </div>
                                                                </span>
                                                                <MDBRow>
                                                                    <MDBCol size="6" className="md-form md-browser-default-form browser-default-select mt-1">
                                                                        <select
                                                                            key={"mobileNumberCountryCode"}
                                                                            name={"mobileNumberCountryCode"}
                                                                            id={"mobileNumberCountryCode"}
                                                                            className={"browser-default custom-select"}
                                                                            required
                                                                            aria-required="true"
                                                                            onChange={this.handleInput}
                                                                            value={mobileNumberCountryCode}
                                                                            aria-label={intl.formatMessage({ id: 'ups.preferences.paybytext.mobile-number'}) + ' ' + intl.formatMessage({ id: 'ups.preferences.paybytext.mobile-number.country-code'})}
                                                                        >
                                                                            {pbtCountryDialCodeData && pbtCountryDialCodeData.map((item, index) => {
                                                                                return <option key={item.countryCode} value={item.countryCode}>
                                                                                    {intl.formatMessage({ id: "country-item." + item.countryCode })}
                                                        &nbsp;{item.description}
                                                                                </option>
                                                                            })}

                                                                        </select>
                                                                    </MDBCol>

                                                                    <MDBCol size="6">
                                                                        <MaskedNumberField
                                                                            className="number-aligned"
                                                                            format="(###) ### ####"
                                                                            mask="_"
                                                                            unformat={this.unformat}
                                                                            key="mobileNumber" name="mobileNumber" id="mobileNumber"
                                                                            value={mobileNumber}
                                                                            aria-label={intl.formatMessage({ id: 'ups.preferences.paybytext.mobile-number'})}
                                                                            required
                                                                            onChange={this.handleMaskedInput}
                                                                            hint={`ups.preferences.paybytext.mobile-number.hint`}
                                                                            validations={payByTextSettings.enabled ? [
                                                                                ['required', "field.mobileNumberRequired"],
                                                                                ["minlength", "field.paybytext.mobilenumber", { min: 10 }]
                                                                            ] : []}
                                                                        />
                                                                    </MDBCol>
                                                                </MDBRow>
                                                            </div>
                                                        </MDBCol>

                                                        <MDBCol size="12" md="8" className="mt-3"></MDBCol>

                                                        <MDBCol size="12">
                                                            <legend><span className="required-field"><FormattedMessage id="ups.preferences.paybytext.notification" /></span> </legend>
                                                            <div className="numeric-input-view pbt-days-prior">
                                                                <InputFieldIntl
                                                                    hideErrorSign={true}
                                                                    id="pbtDaysPrior"
                                                                    className="pref-input"
                                                                    restrict={InputRestrictors.numeric}
                                                                    name={"payByTextSettings.payByTextDaysPrior"}
                                                                    value={payByTextSettings.payByTextDaysPrior}
                                                                    onChange={this.handleInput}
                                                                    required
                                                                    validations={payByTextSettings.enabled ? [
                                                                        ['required', 'field.minMaxValue'],
                                                                        ['minMaxValue', "field.minMaxValue", { min: 1, max: 5 }]
                                                                    ] : []}
                                                                    aria-label={intl.formatMessage({ id: 'ups.preferences.notifications.days-notified-by-text.aria-label'})}
                                                                />
                                                                <div className="pref-input-label">&nbsp; <FormattedMessage id="ups.preferences.notifications.pref.option-2" /></div>
                                                            </div>
                                                        </MDBCol>
                                                        {payByTextLink ? <MDBCol className="col-12">
                                                            <FormattedMessage
                                                                tagName={'p'}
                                                                id={'ups.preferences.paybytext.save.message'}
                                                                values={{
                                                                    style: msg => (
                                                                        <strong>{msg}</strong>
                                                                    ),
                                                                    button: msg => (
                                                                        <MDBBtn color={"text-link"} className="paybytextbtn" onClick={this.goToPayByText}>{msg}</MDBBtn>
                                                                    )
                                                                }}
                                                            />
                                                        </MDBCol> : null
                                                        }
                                                    </React.Fragment>
                                                }

                                            </div>
                                            {auth.user.adUserId || (hidePBTDataAndBtn) ? null :
                                                <MDBRow className="mt-3">
                                                    <MDBCol size="12" className="btn-preferences">
                                                        <MDBBtn color="primary" onClick={this.handleSavePBT} >
                                                            <FormattedMessage id="ups.preferences.notifications.btn-save" />
                                                        </MDBBtn>
                                                    </MDBCol>
                                                </MDBRow>}
                                        </MDBRow>
                                    </div>
                                </React.Fragment>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    {saveButtonOpenModal ?
                        <MDBRow>
                            <MDBCol>
                                <SaveModal
                                    key={'SaveButton-modal'}
                                    isOpen={saveButtonOpenModal}
                                    closeModal={this.saveButtonCloseModal}
                                    component={saveButtonModalComp}
                                    modalData={saveModalData}
                                />
                            </MDBCol>
                        </MDBRow>
                        :
                        null
                    }
                    { isPageDirty && 
                        <PreferencesPrompt preparePreferences = {this.preparePBTPreferences} />
                    }
                </MDBRow>
                {showErrorMessage && <NotificationModal isOpen={showErrorMessage} title=" " size="md" closeModal={() => { this.setState(this.getIntialState()) }} buttonTypes={BUTTON_TYPES.OK} message={errorCode ?? "internalError"} />}
            </MDBContainer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        auth: state.auth,
        merchants: state.auth.merchants,
        disablePBTFeature: formatBool(state.envProps.disablePBTFeature),
        preferences: state.identity.preferences
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayByTextPreferences)));