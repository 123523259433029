import React from 'react'
import { MDBSpinner } from 'mdbreact';
import ReactPortalSpinner from "../ReactPortalSpinner";
import {FormattedMessage} from 'react-intl';


const Spinner = ({isSpinning = false, spinnerText}) => {
    if (isSpinning) {
        return (
            <ReactPortalSpinner>
                <div className={"load-overlay active"}>
                    <div className="spinner-container">
                        <MDBSpinner />
                        {spinnerText && <div><strong><FormattedMessage id={spinnerText}/></strong></div>}
                    </div>
                    <div className="load-overlay-bg"></div>
                </div>
            </ReactPortalSpinner>
        );
    } else {
        return null;
    }
};
export default Spinner;
