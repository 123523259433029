/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 04/29/20 3:12 PM
 * Created by hchau
 *
 */

import produce from "immer"
import * as actionTypes from '../constants/action-types';

export default (state = {}, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_INVOICE:
                draft.invoice = action.data;
                return;
            case actionTypes.GET_INVOICE_SUCCESS:
                draft.invoice = action.data;
                return;
            case actionTypes.GET_INVOICE_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.GET_INVOICE_LIST_SUCCESS:
                draft.invoiceList = action.data;
                return;
            case actionTypes.GET_INVOICE_METADATA_SUCCESS:
                draft.invoiceMetadata = action.data;
                return;
            case actionTypes.GET_INVOICE_LIST_BY_IDENTITY_SUCCESS:
                draft.invoiceList = action.data;
                return;
            case actionTypes.GET_INVOICE_LIST_BY_IDENTITY_FAILURE:
                draft.invalid = true;
                return;
            case actionTypes.CLEAR_INVOICE:
                delete draft.invoice;
                return;
            case actionTypes.CLEAR_INVOICE_LIST:
                delete draft.invoiceList;
                return;
            case actionTypes.SET_INVOICE_FILTERS:
                draft.invoiceFilters = action.data;
                return;
            case actionTypes.CLEAR_INVOICE_FILTERS:
                delete draft.invoiceFilters;
                return;
            case actionTypes.GET_PAST_DUE_INVOICES:
            case actionTypes.GET_PAST_DUE_PLAN_INVOICES:
                delete draft.hasPastDueInvoices;
                return;
            case actionTypes.GET_PAST_DUE_INVOICES_SUCCESS:
            case actionTypes.GET_PAST_DUE_PLAN_INVOICES_SUCCESS:
                draft.hasPastDueInvoices = action.data.length > 0;
                return;
            case actionTypes.GET_PLAN_INVOICE_LIST_SUCCESS:
                draft.planInvoiceList = action.data;
                return;
            case actionTypes.SET_PLAN_INVOICE_FILTERS:
                draft.planInvoiceFilters = action.data;
                return;
            case actionTypes.CLEAR_PLAN_INVOICE_FILTERS:
                delete draft.planInvoiceFilters;
                return;
            case actionTypes.GET_INVOICE_DETAILS_SUCCESS:
                // draft.invoiceDetails[action.detailType] = action.data;
                return;
            case actionTypes.SET_INVOICE_DETAILS_FILTERS:
                if (!draft.invoiceDetailsFilters) draft.invoiceDetailsFilters = {};
                draft.invoiceDetailsFilters[action.detailType] = action.data;
                return;
            case actionTypes.CLEAR_INVOICE_DETAILS_FILTERS:
                draft.invoiceDetailsFilters = {};
                return;
            case actionTypes.CLEAR_INVOICE_DETAILS:
                draft.invoiceDetailsFilters = {};
                return;
            default:
                return;
        }
    }
);