import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { MDBCol, MDBRow } from "mdbreact";
import * as dashboardActions from "../../../actions/dashboard-action";
import NoticeTile from "../../DashboardNoticeTile";
import DashboardSummary from "../DashboardSummary";
import DashboardDisputeTile from "../DashboardDisputeTile";
import * as invoiceActions from "../../../actions/invoice-action";
import { withRouter } from 'react-router-dom';
import DashboardPastDueTile from "../DashboardPastDueTile";
import DashboardDueSoonInvoicesTile from "../DashboardDueSoonInvoicesTile";
import { buildFilters, isDisputesEnabled, filterInvoices } from '../../../utils/invoice-utils';
import { BUSINESS_UNIT } from '../../../constants/business-unit';
import * as layoutActions from "../../../actions/layout-action";
import {getMaxInvoices} from "../../../utils/config-utils";
import { PAST_DUE_INVOICES, TOTAL_INVOICES } from "../../../constants/invoice-constants";
import DashboardAdminAlerts from "../../DashboardAdminAlerts";
import {decodeToken} from "../../../utils/ups-utils";
import { getQueryParams } from "../../../utils/utils"
import {getInvoiceTotals} from "../../../api/invoice-api";

class UPSDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceData: {
                totals:{
                    stats: {
                        totalDueAmount: null,
                        accountsCount: null,
                        plansCount: null
                    }
                },
                pastDue: {
                    stats: {
                        pastDueTotals: null
                    }
                },
                dueSoon: {
                    stats: {}
                },
                currencyCode: null
            },
            notices: null,
            alerts: null,
            loading: false
        }
    }

    componentDidMount() {
        this.getInvoicesTotals();
        this.getLatestDashboardMessages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedCountry: prevCountry, selectedLanguage: prevLanguage, selectedPaymentType: prevPaymentType } = prevProps;
        const { selectedCountry, selectedLanguage, selectedPaymentType } = this.props;
        if (selectedCountry !== prevCountry || selectedLanguage !== prevLanguage || selectedPaymentType !== prevPaymentType) {
            this.getLatestDashboardMessages();
            this.getInvoicesTotals();
        }

        this.navigateIfQueryParamsExist();
    }

    async navigateIfQueryParamsExist() {
        const queryParams = getQueryParams(this.props.location.search, false);
        const { userPermissions, history, layoutActions } = this.props;
        const { invoiceData } = this.state;
        const filters = { accounts: true, totals: true, keyToAccessInvoices: 'totals' };

        if (queryParams?.["t"] === "otp") {
            if (invoiceData?.totals?.stats) {
                const invoicesSummary = invoiceData?.totals?.stats;
                const plansCount = invoicesSummary?.plansCount;
                const accountsCount = invoicesSummary?.accountsCount;

                if (invoicesSummary?.accountsCount == undefined) {
                    layoutActions.showSpinner("payments-redirect-msg");
                } else {
                    layoutActions.hideSpinner();

                    if (queryParams?.["token"]) {
                        const { at: accountType, token } = queryParams;
                        if (accountType === "plan") {
                            this.props.history.push({ pathname: '/ups/billing/plan', search: this.props.location.search });
                        } else if (accountType === "account" && userPermissions.hasOwnProperty("otp_payment")) {
                            history.replace({ search: "" });
                            const decodedToken = await decodeToken(token);
                            if (decodedToken) {
                                this.onPayAccountInvoices(filters, buildFilters(decodedToken));
                            }

                        }
                    }
                    else {
                        if (invoicesSummary?.accountsCount !== null) {
                            // replace search params to avoid this same call again if user cancels the payment process
                            history.replace({ search: "" });
                        }
                        if (userPermissions.hasOwnProperty("otp_payment") && (accountsCount || plansCount)) {
                            this.onPayInvoices(!!accountsCount, !!accountsCount ? filters : '', TOTAL_INVOICES);
                        }
                    }
                }
            }
        }
    }

    onViewPaymentHistory = () => {
        this.props.history.push('/payments');
    }

    getInvoicesTotals = () => {
        const { selectedPaymentType } = this.props;

        let filters = {
            totals: true,
            dueSoon: true,
            pastDue: true,
            plans: (selectedPaymentType === BUSINESS_UNIT.EBS),
            accounts: true
        };

        this.setState({loading:true})

        getInvoiceTotals(filters)
        .then((invoiceData) => this.setState({invoiceData}))
        .catch(({errorCode}) => this.setState({errors: [errorCode]}))
        .finally(()=>this.setState({loading: false}))
    }

    getLatestDashboardMessages = () => {
        const { dashboardActions } = this.props;
        dashboardActions.getLatestMessages((messages) => {
            this.setState({
                ...messages
            })
        })
    }

    goToPlanInvoices = (filter='',setDataAsProps) => {
        if(setDataAsProps){
            this.props.history.push('ups/billing/plan', filter);
        }else{
            this.props.history.push('ups/billing/plan/' + filter);
        }
    }

    goToAccountInvoices = (filter='',setDataAsProps) => {
        if(setDataAsProps){
            this.props.history.push('ups/billing/invoice', filter);
        }else{
            this.props.history.push('ups/billing/invoice/'+filter);
        }
    }

    isDisputeSummaryVisible = () => {
        const { userPermissions, sessionSelection, selectedPaymentType } = this.props;

        return isDisputesEnabled(sessionSelection?.merchant) && userPermissions['dispute_invoice'] && (selectedPaymentType === 'EBS');
    }

    deleteMessage = (messageId) => {
        const { dashboardActions } = this.props;
        dashboardActions.deleteUserMessage(messageId, ({ id }) => {
            const alerts = this.state.alerts;
            const filteredAlerts = alerts.filter(alert => alert._id !== id);
            this.setState({
                alerts: filteredAlerts
            });
        });
    }

    gotoDisputes = () => this.props.history.push('/ups/billing/dispute')

    onPayAccountInvoices = (filters = null, invoiceFilters = null) => {
        const { history, invoiceActions } = this.props;
        filters = {...filters, businessUnit: this.props.selectedPaymentType };
        invoiceActions.getPayableInvoices(filters, (response) => {
            let invoices = new Map();
            let payableInvoices = response[filters.keyToAccessInvoices].invoices;
            payableInvoices = invoiceFilters && payableInvoices.length ? filterInvoices(payableInvoices, invoiceFilters) : payableInvoices;
            for (let x = 0; x < payableInvoices.length; x++) {
                invoices.set(payableInvoices[x].id, payableInvoices[x]);
            }
            history.push('/ups/billing/otp', { invoices, isPlan: false });
        })
    };

    onPayInvoices = (payAccount, filters, type='') => {
        const { MAX_INVOICES, layoutActions } = this.props;
        if (payAccount) {
            if((type===PAST_DUE_INVOICES && this.state.invoiceData?.pastDue?.stats?.accountsCount > MAX_INVOICES) || (type===TOTAL_INVOICES && this.state.invoiceData?.totals?.stats?.accountsCount > MAX_INVOICES)) {
                layoutActions.openBasicModal({
                    message: <FormattedMessage id={"invoice.max-invoices-reached"} values={{ limit: MAX_INVOICES }}/>,
                    title: "invoice.max-invoices-reached.title",
                    callback: () => { return this.onPayAccountInvoices({...filters, maxInvoices: MAX_INVOICES})},
                    buttons: "CONTINUE"
                })
            } else {
                this.onPayAccountInvoices(filters);
            }
        } else {
            this.goToPlanInvoices(filters);
        }
    }

    render() {
        const { userPermissions, userRole, selectedPaymentType } = this.props;
        const { invoiceData, notices, alerts, loading } = this.state;

        return (
            <div>
                <DashboardSummary
                    key={'dashboard-summary'}
                    loading={loading}
                    businessUnit={selectedPaymentType}
                    invoicesSummary={invoiceData?.totals?.stats}
                    pastDueTotals={invoiceData?.pastDue?.stats.pastDueTotals}
                    currencyCode={invoiceData.currencyCode}
                    onPayInvoices={this.onPayInvoices}
                    onViewPaymentHistory={this.onViewPaymentHistory}
                />
                <MDBRow>
                    {userRole != 'VIEW_ONLY' &&
                        <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-4"}>
                            <DashboardPastDueTile
                                loading={loading}
                                businessUnit={selectedPaymentType}
                                currencyCode={invoiceData.currencyCode}
                                pastDueSummary={invoiceData?.pastDue?.stats}
                                onPayInvoices={this.onPayInvoices}
                                goToAccountInvoices={this.goToAccountInvoices}
                            />
                        </MDBCol>
                    }
                    {userRole != 'VIEW_ONLY' &&
                        <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-4"}>
                            <DashboardDueSoonInvoicesTile
                                businessUnit={selectedPaymentType}
                                dueSoonSummary={invoiceData?.dueSoon?.stats}
                                goToPlanInvoices={this.goToPlanInvoices}
                                goToAccountInvoices={this.goToAccountInvoices}
                            />
                        </MDBCol>
                    }
                    {this.isDisputeSummaryVisible() &&
                    <DashboardDisputeTile gotoDisputes={this.gotoDisputes} />
                    }
                    <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-4"}>
                        <DashboardAdminAlerts alerts={alerts} deleteMessage={(msgId)=> this.deleteMessage(msgId)}></DashboardAdminAlerts>
                    </MDBCol> 
                    {notices && notices.length && notices.map((notice,i) => {
                        if (notice.message && ((notice.userPermission && userPermissions && userPermissions[notice.userPermission]) || !notice.userPermission)) {
                            return (
                                <MDBCol key={`notice-${i}`} size={"12"} lg={"4"} md={"6"} className={"mb-4"}>
                                 <NoticeTile msg={notice.message}>
                                </NoticeTile>
                            </MDBCol>)
                        }
                        return;
                        
                    })}
                </MDBRow>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        selectedLanguage: state.auth.user.locale,
        selectedCountry: state.auth.user.selectedCountry,
        selectedPaymentType: state.auth.user.selectedPaymentType,
        userPermissions: state.auth.user.permissions,
        userRole: state.auth.user.role,
        companyId: state.auth.user.companyId,
        sessionSelection: state.config.sessionSelection,
        MAX_INVOICES: getMaxInvoices(state.config?.sessionSelection?.merchant)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dashboardActions: bindActionCreators(dashboardActions, dispatch),
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        layoutActions: bindActionCreators(layoutActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(UPSDashboard)));
