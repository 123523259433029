import React,  { Component } from 'react'
import {Link} from "react-router-dom";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBSelect } from 'mdbreact';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import {bindActionCreators} from "redux";

import ReactPortalModal from "../../ReactPortalModal";
import SelectFieldIntl from "../../SelectFieldIntl";
import InputFieldIntl from '../../InputFieldIntl';
import DisplayOnlyField from '../../DisplayOnlyField';
import attachValidator from "../../../utils/validation/attach-validator";
import * as disputeActions from '../../../actions/dispute-action';
import * as validationActions from "../../../actions/validation-action";

import {connect} from "react-redux";
import { get as lodashGet, set as lodashSet, isUndefined as lodashIsUndefined, isEmpty as lodashIsEmpty, isNil as lodashIsNil } from 'lodash';
import produce from "immer";
import {withRouter} from "react-router-dom";
import {getTranslatedOption, formatInvoiceDate} from "../../../utils/invoice-utils";
import {formatCurrency, countryUPSType} from '../../../utils/ups-utils';
import moment from 'moment';
import { DISPUTE_APPROVED, DISPUTE_DENIED, DISPUTE_PENDING, getStatusCodeLabel, mapErrCode, renderRefundAmountTableRow } from '../../../utils/dispute-utils';
import {BUSINESS_UNIT} from '../../../constants/business-unit';
import {ROADIE_SECTION_CODES, OUTBOUND_SECTION_CODE, HAPPY_SECTION_CODES} from '../../../constants/invoice-detail-section-codes';

class DisputeModal extends Component {

    constructor(props) {
        super(props);

        const {intl,data} = this.props;

        this.formatOption = getTranslatedOption.bind({intl});

        const countryCode = lodashGet(this.props,'user.selectedCountry','US');

        this.state = {
            loaded: false,
            modalStep: 1,
            selectedReasonCode: '',
            formData: {},
            disputeReasons: [],
            disputeComment: '',
            disputeResponse: {},
            submittedDisputes: new Set(),
            errors: [],
            isInternational : !(countryCode === 'US' || countryCode === 'CA')
        };
        attachValidator.call(this);
    }

    componentDidMount() {
        const {disputeLevel} = this.props;
        if (disputeLevel === 'invoice') {
            this.getInvoiceDisputeReasons();
        } else {
            this.getInvoiceDetailDisputeReasons();
        }
    }

    getInvoiceDetailDisputeReasons = () => {
        const {disputeActions, data: invoiceDetail, businessUnit, invoice} = this.props;
        const {fields, detailType} = invoiceDetail;
        const {trackingNumber, pickupRequestNumber} = fields;

        if (invoiceDetail.inDispute) {
            this.setState({
                loaded: true,
            });
            return;
        }

        const invoiceDisputeData = {
            invoiceDetailId: invoiceDetail.id,
            ...(trackingNumber ? {trackingNumber} : {}),
            ...(pickupRequestNumber ? {pickupRequestNumber} : {})
        };

        const actionPayload = {
            invoiceId: null,
            invoiceDetailId: invoiceDetail.id,
            useTRK: detailType && detailType === 'SHIPMENT',
            businessUnit,
            accountNumber: invoice.accountNumber,
            planNumber: invoice.planNumber,
            recordType: invoice.recordType,
        }

        disputeActions.getDisputeReasons(actionPayload, (disputeReasons) => {
            this.handleDisputeReasonCodes(null, invoiceDisputeData, disputeReasons);
        });
    };

    getInvoiceDisputeReasons = async () => {
        const {disputeActions, invoice, businessUnit} = this.props;

        if (invoice.inDispute) {
            this.setState({
                loaded: true
            });
            return;
        }

        const actionPayload = {
            invoiceId: invoice.id,
            invoiceDetailId: null,
            useTRK: false,
            accountNumber: invoice.accountNumber,
            planNumber: invoice.planNumber,
            recordType: invoice.recordType,
            businessUnit
        }

        disputeActions.getDisputeReasons(actionPayload, (disputeReasons) => {
            this.handleDisputeReasonCodes(invoice.id, null, disputeReasons);
        })
    };

    handleDisputeReasonCodes = (invoiceId, invoiceDetailRef, disputeReasons) => {
        const {submittedDisputes} = this.state;
        const {disputeActions, businessUnit} = this.props;
        let selectedReasonCode = lodashGet(disputeReasons, '[0].code', null);

        disputeActions.getDisputesByIdentifier(invoiceId, invoiceDetailRef, businessUnit, (disputes) => {
            if (selectedReasonCode) {
                for (let i = 0; i < disputes.length; i++) {
                    if (disputes[i].request) {
                        const reasonCode = disputes[i].request.reasonCode;
                        submittedDisputes.add(reasonCode);
                    }
                }
            }
            this.setState({
                disputes,
                submittedReasonCode: submittedDisputes.has(selectedReasonCode),
                loaded: true,
                modalStep: selectedReasonCode ? 1 : 0,
                disputeReasons,
                selectedReasonCode
            });
        });
    };

    validateForm = () => {
        const { messages } = this.validator.validateAll();
        if (messages.length > 0) return false;

        this.submitInvoiceDetailDispute();
    };

    submitInvoiceDetailDispute = () => {
        const { invoice, user, intl } = this.props;
        const { disputeActions, data: invoiceDetail, businessUnit } = this.props;
        const { selectedReasonCode, formData, disputeComment, disputeReasons } = this.state;
        const {accCodes, netAmt} = disputeReasons.find(r => r.code === selectedReasonCode);
        const {fields} = invoiceDetail || {};

        const parsedFormData = {};
        for (const [key, value] of Object.entries(formData)) {
            if (value) {
                lodashSet(parsedFormData, key, value);
            }
        }

        if (parsedFormData) {
            const {disputeDimensions, disputeWeight, disputeCharge} = parsedFormData;
            if (disputeDimensions) {
                disputeDimensions['dimensionsUom'] = 'IN'; // TODO: Derive from original invoice UOM
            }
            if (disputeWeight) {
                disputeWeight['weightUom'] = fields.weightUom1 || 'LBS';
            }
            if (disputeCharge) {
                disputeCharge['currencyCode'] = invoice.currencyCode;
            }
        }

        let disputeData = {
            applicationId: 'UBP',
            reasonCode: selectedReasonCode,
            disputeLevel: 'PKG',
            disputeSource: 'I', //TODO: Free-Form
            invoiceNbr: invoice.invoiceNumber,
            accountNbr: invoice.accountNumber,
            invoiceType: invoice.invoiceType,
            refundLevel: 'F', // TODO: Allow partial refunds
            creditAdjType: countryUPSType(invoice.countryCode) === 'Domestic' ? '18' : '03', // TODO: Next Invoice vs Shortpay
            countryCode: invoice.countryCode,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userEmailId: user.email,
            ...(invoice.invoiceDate ? {invoiceDate: invoice.invoiceDate.split('T')[0]} : {}),
            userContactNbr: '0000',
            ...(accCodes ? {disputeChgType: accCodes} : {}),
            // AP-228 - In the ihub API call this netAmt must be passed in disputeCharge.accessorialChg property.
            ...(!lodashIsNil(netAmt) ? {
                disputeCharge: {
                    accessorialChg: netAmt,
                    currencyCode: invoice.currencyCode,
                }
            } : {}),
            ...parsedFormData,
            ...(disputeComment ? {customerComments: disputeComment} : {}),
            // AP-228 - CustomerRepComments should always be passed. In the case of Batch CSR disputes the customer rep can include this. But in the case of regular user disputes, we need to pass this. The format for is 'Submitted by  {User first name} {User last name}'.
            customerRepComments: `${intl.formatMessage({ id: 'ups.dispute.submitted-by' })} ${user.firstName} ${user.lastName}`
        };

        if (fields) {
            disputeData = {
                ...disputeData,
                ...(fields.secCd1 ? {invoiceSection1: fields.secCd1} : {}),
                ...(fields.secCd2 ? {invoiceSection2: fields.secCd2} : {}),
                ...(fields.secCd3 ? {invoiceSection3: fields.secCd3} : {}),
                ...(fields.pickupDate ? {pickupDate: fields.pickupDate.split('T')[0]} : {...fields.transactionDate1 ? { pickupDate:fields.transactionDate1.split('T')[0] } : {} })
            };
            // AP-228 - If the record being disputed has pickupRequestNumber (see mapping doc. PickupNumber is diff from pickupRequestNumber.
            if (fields.pickupRequestNumber) disputeData.pickupReqNbr = fields.pickupRequestNumber;
            else if (fields.trackingNumber) disputeData.trackingNbr = fields.trackingNumber;
            //AP-407 010 records do not have trackingNumber but they have trackingNumber1 and in case of 6w it should be included as trackingNbr
            else if (fields.trackingNumber1 && selectedReasonCode==="6W") disputeData.trackingNbr = fields.trackingNumber1
            
            if (fields.trackingSeqNumber) disputeData.trackingSeqNbr = fields.trackingSeqNumber

            //AP-421 adding cimsNbr as alternateReferenceNbr
            if(businessUnit === BUSINESS_UNIT.FRT){
                disputeData.alternateReferenceNbr = fields.cimsNbr
            }
        }

        if (lodashIsUndefined(invoiceDetail) || lodashIsEmpty(invoiceDetail)) {
            disputeActions.submitDispute({invoiceId: invoice.id}, businessUnit, invoice.invoiceAmount, [disputeData], this.handleResponse);
        } else {
            disputeActions.submitDispute({invoiceId: invoice.id, invoiceDetailId: invoiceDetail.id}, businessUnit, lodashGet(invoiceDetail, 'fields.netCharge'), [disputeData], this.handleResponse);
        }
    }

    handleResponse = (response) => {
        const { intl ,data:invoiceDetail, updateDisputeStatus} = this.props;
        const { error, errors,errorCode,message, disputeResponse, responseItems } = response;
        const disputeArr = disputeResponse || responseItems;

        if (error || errors) {
            this.setState({errors: errors ? errors : [intl.formatMessage({id: error})]});
        }else if (errorCode) {
            this.setState({errors: [intl.formatMessage({id: message})]});
        }
        else if (disputeArr) {
            let disputeMsgObj = {};
            let status = '',
                pending = false,
                voidApproved = false,
                refundAmount = null,
                refundCurrency = null;

            disputeArr.forEach(dispute => {
                const {disputeMsgCode, disputeMsg, refundAmount: disputeRefundAmt, refundCurrency: disputeCurrency,reasonCode} = dispute;
                if (!pending && dispute.reqStatus === DISPUTE_PENDING) {
                    pending = true;
                }

                if(!voidApproved && dispute.reqStatus === DISPUTE_APPROVED && (reasonCode === '614'||reasonCode === '3E') ){
                    voidApproved = true;
                }

                if (status !== DISPUTE_APPROVED) {
                    if (status === DISPUTE_DENIED || status === '') {
                        status = dispute.reqStatus;
                    } else if (status === DISPUTE_PENDING && dispute.reqStatus === DISPUTE_APPROVED) {
                        status = dispute.reqStatus;
                    }
                }
                if (disputeMsgCode || disputeMsg) {
                    disputeMsgObj = {msgCode:disputeMsgCode, msg:disputeMsg}
                }

                if (disputeRefundAmt && disputeCurrency) {
                    if (refundAmount == null) {
                        refundAmount = disputeRefundAmt;
                        refundCurrency = disputeCurrency;
                    } else {
                        refundAmount += disputeRefundAmt;
                    }
                }
            });

            status = getStatusCodeLabel(intl)[status];

            this.setState(
                produce(draft => {
                    draft.modalStep++;
                    draft.disputeResponse = {disputeArr, disputeMsgObj, status, refundAmount, refundCurrency};
                    draft.pending = pending;
                    draft.voidApproved = voidApproved;
                })
            );
            
            if(updateDisputeStatus && pending){
                updateDisputeStatus(invoiceDetail.id)
            }
        }
    };

    gotoMyDispute = () => {
        this.props.history.push('/ups/billing/dispute');
    };

    handleInput = e => {
        const {formData} = this.state;

        const inputName = e.target.name;
        const inputValue = e.target.value;

        formData[inputName] = inputValue;

        this.setState({ formData });
    };


    handleSelection = (field, value) => {
        const {formData} = this.state;

        formData[field] = value[0];

        this.setState({ formData });
    };

    handleChange = e => {
        const {submittedDisputes} = this.state;
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState({
            errors: [],
            [inputName]: inputValue,
            ...(inputName === 'selectedReasonCode' ? {
                        formData: {},
                        submittedReasonCode: submittedDisputes.has(inputValue)
                } : {}),

        });
    };

    createFormElements() {
        const {selectedReasonCode, disputeReasons,isInternational} = this.state;
        const { intl ,businessUnit} = this.props;

        return (
            <React.Fragment>
                <MDBCol size="12" md={"6"} key={"reasonCode"}>
                    <SelectFieldIntl
                        extraContainerClasses={'my-3'}
                        name={"selectedReasonCode"}
                        id={"selectedReasonCode"}
                        selectOptions={
                            disputeReasons.map(r => ({
                                value: r.code,
                                label: intl.formatMessage({ id: r.msgId })
                            }))
                        }
                        value={selectedReasonCode}
                        label={intl.formatMessage({ id: "invoice.disputes.reason.label" })}
                        setLabelBefore={true}
                        onChange={this.handleChange}
                    />
                </MDBCol>
                {businessUnit === BUSINESS_UNIT.EBS &&
                <MDBCol size="12" md={"6"} key={"refund-method"}>
                    <div className="md-form my-3">
                        <DisplayOnlyField
                            label={intl.formatMessage({ id: "invoice.disputes.refund-method.label" })}
                            value={
                                isInternational ?
                                intl.formatMessage({ id: "invoice.disputes.issue-credit-note.label" }) :
                                intl.formatMessage({ id: "invoice.disputes.credit-next-invoice.label" })}
                            wrapperMode={false}
                        />
                    </div>
                </MDBCol>}
            </React.Fragment>
      );
    }

    createFormRowElements() {
        const { selectedReasonCode, disputeReasons } = this.state;
        const reasonFields = lodashGet(disputeReasons.find(r => r.code === selectedReasonCode), 'fields', []);
        return reasonFields.map(field => {
           if (field.type === 'dropdown') {
               return this.createDropdownFormInput(field);
           } else {
               return this.createTextFormInput(field);
           }
        });
    }

    createDropdownFormInput(field) {
        const { intl } = this.props;
        const {formData} = this.state;
        const {value, apiField} = field;
        const selectedOption = formData[apiField];

        if (!value) return (null);
        const optionList = [];

        const required = {
            required: false,
            validations: [],
        };

        if (field.required) {
            required["required"] = true;
            required["validations"].push(['required', "field.required"]);
        }

        optionList.push({
            value: '',
            label: intl.formatMessage({id: 'filter-options.dropdown.default'})
        });

        for (const option of value) {
            const { value: optionValue, text } = this.formatOption(option);

            optionList.push({
                value: optionValue,
                label: text
            });
        }

        return (
            <MDBCol size="12" md="4" key={apiField}>
                <div className={"md-form"}>
                     <SelectFieldIntl
                        extraContainerClasses={'my-3'}
                        name={apiField}
                        id={apiField}
                        selectOptions={optionList}
                        value={selectedOption}
                        label={intl.formatMessage({ id: field.msgId })}
                        setLabelBefore={true}
                        onChange={this.handleInput}
                        {...required}
                    />
                </div>
            </MDBCol>
        );
    }

    createTextFormInput(field) {
        const { intl, invoice } = this.props;
        const { formData } = this.state;

        const type = this.getFieldType(field.type);
        const required = {
            required: false,
            validations: [],
        };

        if (field.required) {
            required["required"] = true;
            required["validations"].push(['required', "field.required"]);
        }

        if (!lodashIsUndefined(field.min)) required['validations'].push(['greaterOrEqual', 'invoice.dispute.minWeight', { checkValue: field.min }]);
        if (!lodashIsUndefined(field.max)){
            const errMsg = (field.type && field.type==='currency') ? 'refundAmount' : 'maxWeight';
            required['validations'].push(['lessThanOrEqual', `invoice.dispute.${errMsg}`, { checkValue: field.max }])
        }

        if (field.type === "currency" && invoice.invoiceAmount) required['validations'].push(['lessThanOrEqual', `invoice.dispute.maxAmount`, { checkValue: invoice.invoiceAmount }])
        if (type === 'tel') required['validations'].push(['validPhoneNumber10d', 'invoice.dispute.phone-number.validation']);
        let fieldSize = field.size || 4
        return (
            <MDBCol size={12} md={fieldSize}>
                <InputFieldIntl
                    key={field.apiField}
                    name={field.apiField}
                    id={field.apiField}
                    label={intl.formatMessage({ id: field.msgId },field.msgVar)}
                    value={formData[field.apiField] || ''}
                    onChange={this.handleInput}
                    hint={intl.formatMessage({ id: field.hint })}
                    maxLength={field.maxLength || 10}
                    type={type}
                    {...required}
                />
            </MDBCol>
        );
    }

    closeModal = () => {
        const {modalStep, pending,voidApproved} = this.state;
        const {toggleModal, updateData} = this.props;

        if (modalStep === 2 && (pending || voidApproved) && updateData) {
            updateData({});
        }
        toggleModal('disputes');
    };

    renderDisputeCommentsTableRow = (intl, disputeComment) => {
        if (lodashIsEmpty(disputeComment)) return null;

        return (
            <tr>
                <th>{intl.formatMessage({ id: 'invoice.disputes.comments.label' })}</th>
                <td>{disputeComment}</td>
            </tr>
        );
    }

    renderStatusTableRow = (intl, status) => {
        if (lodashIsEmpty(status)) return null;

        return (
            <tr>
                <th>{intl.formatMessage({ id: 'invoice.disputes.response-status.label' })}</th>
                <td>{status}</td>
            </tr>
        );
    }

    renderDisputeResponseMessageTableRow = (intl,disputeMsgObj) => {
        const { invoiceMetadata: { disputeRules: { errorCodes } } } = this.props
        const disputeErrMessageArr = mapErrCode({errorCodes:disputeMsgObj.msgCode,errorMsg: disputeMsgObj.msg},errorCodes,intl)?.split(";")
        return (
            <tr>
                <th>{intl.formatMessage({ id: 'invoice.disputes.response-message.label' })}</th>
                <td className={"text-left"}>
                    <ul className="dispute-msg-error">
                        {
                            disputeErrMessageArr?.map(msg=>{
                                return <li>{msg}</li>
                            })
                        }
                    </ul>
                </td>
            </tr>
        )
    };

    getFieldType = (fieldType) => {
      if (fieldType === 'numeric' || fieldType === 'currency') {
          return 'number'
      } else if (fieldType === 'phone_number') {
          return 'tel'
      }

      return 'text';
    };

    render() {
        const {closeModal} = this;
        const { isOpen, toggleModal, backdrop, data, intl, invoice } = this.props;
        const {modalStep, disputeComment,  disputeResponse, submittedReasonCode, loaded, disputeReasons, selectedReasonCode, errors} = this.state;
        const inDispute = lodashGet(data, 'inDispute', false) || lodashGet(invoice, 'inDispute');

        let isRoadieDisputable = true;
        let isHappyDisputable = true;

        if (!isOpen || !loaded) return null;
        let titleId = 'invoice.disputes.dispute.label';
        if (modalStep === 1) {
            if (inDispute) titleId = 'invoice.disputes.in-dispute.label';
            else titleId = 'invoice.disputes.dispute.label';
        } else if (modalStep === 2) {
            titleId = 'invoice.disputes.submission.label';
        }

        if (data?.fields?.secCd1 === OUTBOUND_SECTION_CODE && ROADIE_SECTION_CODES.includes(data?.fields?.secCd2)){
            isRoadieDisputable = false;
        }
        if (data?.fields?.secCd1 === OUTBOUND_SECTION_CODE && HAPPY_SECTION_CODES.includes(data?.fields?.secCd2)){
            isHappyDisputable = false;
        }

        const modalBody = [];

        if (modalStep === 0) {
            modalBody.push(
                <MDBRow>
                    <MDBCol size={"12"}>
                        <p><FormattedMessage id="invoice.disputes.no-applicable-reason-codes" /></p>
                    </MDBCol>
                </MDBRow>
            );
        }

        if (modalStep === 1) {
            if (inDispute) {
                modalBody.push(
                    <MDBRow>
                        <MDBCol size={"12"}>
                            <FormattedHTMLMessage tagName={'p'} id={"invoice.disputes.in-dispute.modal.msg1"} />
                            <FormattedHTMLMessage tagName={'p'} id={"invoice.disputes.in-dispute.modal.msg2"} />
                        </MDBCol>
                    </MDBRow>
                );
            } else {
                const commentValidation = {
                    required: false,
                    validations: [],
                };
                commentValidation['required'] = lodashGet(disputeReasons.find(r => r.code === selectedReasonCode), 'commentRequired', false);
                if (commentValidation['required']) {
                    commentValidation['validations'].push(['required', 'invoice.dispute.comment.validation'])
                }

                if (errors.length > 0) {
                    let errorMessages = [];
                    if (typeof errors[0] === 'string' || errors[0] instanceof String){
                        errorMessages = errors
                    } else {
                        errorMessages = errors.map(error => error.message)
                    }
                    modalBody.push(
                        <div className="alert alert-dispute-error" role="alert">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                                <title><FormattedMessage id="alert.label" /></title>
                                <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                            </svg><span className="alert-msg">
                                {errorMessages}
                            </span>
                        </div>
                    );
                }

                if (submittedReasonCode) {
                    modalBody.push(
                        <div className="alert alert-dispute-submitted" role="alert">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                                <title><FormattedMessage id="alert.label" /></title>
                                <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                            </svg><span className="alert-msg"><FormattedMessage id={'invoice.disputes.alert-message.submitted.label'} /></span>
                        </div>
                    );
                }
                modalBody.push(
                    <React.Fragment key={invoice.id}>
                        <MDBRow>
                            {this.createFormElements()}
                        </MDBRow>
                        <MDBRow>
                            {this.createFormRowElements()}
                        </MDBRow>
                        <MDBRow className={"mt-3"} key={"disputeComment"}>
                            <MDBCol size={"12"}>
                                <InputFieldIntl
                                    name={"disputeComment"}
                                    id={"disputeComment"}
                                    type={"textarea"}
                                    className={'form-control'}
                                    label={intl.formatMessage({id: "invoice.disputes.comments.label"})}
                                    value={disputeComment}
                                    onChange={this.handleChange}
                                    maxLength={80}
                                    disabled={null}
                                    hint={""}
                                    rows="5"
                                    labelClass="active"
                                    {...commentValidation}
                                />
                            </MDBCol>
                        </MDBRow>
                    </React.Fragment>
                );
            }
        }

        if (modalStep === 2) {
            const {status, disputeArr, disputeMsgObj, refundAmount, refundCurrency} = disputeResponse;
            const {businessUnit, merchant} = this.props;
            const {isInternational} = this.state;

            modalBody.push(
                <MDBRow>
                    <MDBCol size={"12"}>
                        <FormattedHTMLMessage tagName={'p'} id={"invoice.disputes.submission.success.msg"} />
                    </MDBCol>
                    <MDBCol size={"12"}>
                        <MDBTable className={"table-two-col"}>
                            <MDBTableBody>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'invoice.disputes.submission.label'})}</th>
                                    <td>{disputeArr && disputeArr.map(dispute =>
                                        <p className="mb-0">{dispute.disputeId}</p>
                                    )}</td>
                                </tr>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'invoice.disputes.reason.label' })}</th>
                                    <td>{intl.formatMessage({ id: disputeReasons.find(r => r.code === selectedReasonCode).msgId })}</td>
                                </tr>
                                {status !== getStatusCodeLabel(intl)[DISPUTE_DENIED] && businessUnit===BUSINESS_UNIT.EBS &&
                                    <tr>
                                        <th>{intl.formatMessage({ id: 'invoice.disputes.refund-method.label' })}</th>
                                        <td>
                                            {isInternational ?
                                            intl.formatMessage({ id: "invoice.disputes.issue-credit-note.label" }) :
                                            intl.formatMessage({ id: "invoice.disputes.credit-next-invoice.label" })}
                                        </td>
                                    </tr>
                                }
                                {status !== getStatusCodeLabel(intl)[DISPUTE_DENIED] && renderRefundAmountTableRow(intl, refundAmount, refundCurrency, merchant.currencyDisplay)}
                                {this.renderDisputeCommentsTableRow(intl, disputeComment)}
                                {this.renderStatusTableRow(intl, status)}
                                {this.renderDisputeResponseMessageTableRow(intl, disputeMsgObj)}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            );
        }

        if (!isRoadieDisputable){
            modalBody.length = 0
            modalBody.push(
                <MDBRow>
                    <MDBCol size={"12"}>
                        <p>
                            <FormattedMessage id='invoice.disputes.invoice-detail.not-disputable' />
                            <FormattedMessage id='invoice.disputes.invoice-detail.not-disputable.roadie.reachout' values={{link:
                                <a href="mailto: ar@roadie.com">
                                    <FormattedMessage id={"invoice.disputes.invoice-detail.not-disputable.roadie.linkText"}/>
                                </a>
                            }}/>
                        </p>
                    </MDBCol>
                </MDBRow>
            )
        }

        if (!isHappyDisputable){
            modalBody.length = 0
            modalBody.push(
                <MDBRow>
                    <MDBCol size={"12"}>
                        <p>
                            <FormattedMessage id='invoice.disputes.invoice-detail.not-disputable' />
                            <FormattedMessage id='invoice.disputes.invoice-detail.not-disputable.happy.reachout' values={{link:
                                <a href="mailto: billing@happyreturns.com">
                                    <FormattedMessage id={"invoice.disputes.invoice-detail.not-disputable.happy.linkText"}/>
                                </a>
                            }}/>
                        </p>
                    </MDBCol>
                </MDBRow>
            )
        }

        return (
            <ReactPortalModal isOpen={isOpen} an_label="disputes">
                <MDBModal id="disputes-modal" isOpen={isOpen} toggle={() => closeModal()} size={'lg'} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:titleId})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: titleId }) })} toggle={() => closeModal()}>
                        {!isHappyDisputable || !isRoadieDisputable ? <FormattedMessage id={"invoice.disputes.invoice-detail.not-dispute.label"}/> : <FormattedMessage id={titleId}/>}
                    </MDBModalHeader>
                    <MDBModalBody>
                        {modalBody}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                {modalStep === 1 && !inDispute && isRoadieDisputable && isHappyDisputable &&
                                    <MDBBtn color="primary" disabled={submittedReasonCode} onClick={() => this.validateForm()}>
                                        <FormattedMessage id="invoice.dispute.btn-submit.label"/>
                                    </MDBBtn>
                                }
                                <MDBBtn color={modalStep === 1 && !inDispute ? 'cancel' : 'primary'} onClick={() => closeModal()}>
                                    {modalStep === 1 && !inDispute ? <FormattedMessage id="invoice.dispute.btn-cancel.label"/> : <FormattedMessage id="close.label"/>}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        businessUnit: state.auth.user.selectedPaymentType,
        invoiceMetadata: state.invoice.invoiceMetadata,
        merchant: state.config.sessionSelection.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        validationActions: bindActionCreators(validationActions, dispatch),
        disputeActions: bindActionCreators(disputeActions, dispatch),
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DisputeModal)));