import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBSpinner  } from 'mdbreact';
import { FormattedMessage, injectIntl  } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import classNames from "classnames";
import {connect} from "react-redux";
import {deleteAdminIdentity, deleteIdentity} from "../../../../api/identity-api";
import Error from "../../../Error";

class RemoveMultipleUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalStep: 1,
            usersRemoved: [...this.props.data.values()], //user are gone from the source data after deletion so we grab the data here for display
            loading: false,
            errors: []
        };
    }

    handleDeleteUser = async () => {
        const {data, permissions} = this.props;
        const deletedUsers = []
        const deleteCall = permissions['user_admin_delete'] ? deleteAdminIdentity : deleteIdentity
        this.setState({loading: true});
        for(const {id, externalId, loginId} of data.values()){
            await deleteCall({id, externalId, loginId})
                .then((deletedUser)=> {
                    deletedUsers.push(deletedUser)
                })
                .catch(({errorCode})=>{
                    this.setState({errors: [errorCode]})
                })
        }
        if(deletedUsers.length > 0){
            this.setState({modalStep: 2, loading:false})
            this.props.deleteUserCallback(deletedUsers)
        }
    };

    render() {
        const { isOpen, toggleModal, errors, intl } = this.props;
        const { modalStep, loading, usersRemoved } = this.state;
        const modalHeading = (modalStep === 1) ? "ups.remove-user-multiple.title" : (usersRemoved.length === 1) ? "ups.remove-user-single.confirmation.title" : "ups.remove-user-multiple.confirmation.title";
        const modalLabel = "remove-multiple-users";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                    <MDBModal isOpen={isOpen} toggle={() => toggleModal('removeMultipleUser')} size="lg" disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('removeMultipleUser')}>
                        {modalStep === 1 ? <FormattedMessage id={"ups.remove-user-multiple.title"}/> : 
                                <FormattedMessage id={usersRemoved.length === 1?
                                    "ups.remove-user-single.confirmation.title":
                                    "ups.remove-user-multiple.confirmation.title"}/>
                            }
                        </MDBModalHeader>
                        <MDBModalBody aria-live="assertive">
                            {errors?.length > 0 && <MDBRow><Error errors={errors}/></MDBRow>}
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    {modalStep === 1 && (
                                        <React.Fragment>
                                            <p><FormattedMessage id={usersRemoved.length === 1?
                                                    "ups.remove-user-single.intro-text-1":
                                                    "ups.remove-user-multiple.intro-text-1"}/>
                                            </p>
                                        </React.Fragment>
                                    )}
                                    <MDBTable className={"table-sm table-bordered-simple mb-5"}>
                                        <MDBTableHead color={"light"}>
                                            <tr>
                                                <th className={"w-33"}><FormattedMessage id={"ups.manage-user-table.name"} /></th>
                                                <th className={"w-33"}><FormattedMessage id={"ups.manage-user-table.email"} /></th>
                                                <th className={"w-33"}><FormattedMessage id={"ups.manage-user-table.role"} /></th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {usersRemoved.map(filteredItem => (
                                                <tr>
                                                    <td>{filteredItem.loginId}</td>
                                                    <td>{filteredItem.email}</td>
                                                    <td><FormattedMessage id={`ups.manage-user.role.${filteredItem.role}.label`} /></td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter aria-live="polite">
                            {modalStep === 1 && (
                                <React.Fragment>
                                    <MDBBtn
                                        color="primary"
                                        onClick={this.handleDeleteUser}
                                        className={classNames({"loading": loading})}>
                                        <FormattedMessage id={"ups.btn-remove-user-multiple.label"}/> <MDBSpinner />
                                    </MDBBtn>
                                    <MDBBtn color="cancel" onClick={() => toggleModal('removeMultipleUser')}><FormattedMessage id={"ups.remove-user.btn-cancel"}/></MDBBtn>
                                </React.Fragment>
                            )}
                            {modalStep === 2 && (
                                <React.Fragment>
                                    <MDBBtn color="primary" onClick={() => toggleModal('removeMultipleUser')}><FormattedMessage id={"ups.remove-user.btn-close"}/></MDBBtn>
                                </React.Fragment>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    return {permissions: state.auth.user.permissions}
}

export default connect(mapStateToProps)(injectIntl(RemoveMultipleUserModal));
