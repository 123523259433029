/*
 * Copyright (C) 2020 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 6/26/20, 5:46 PM
 * Created by kxian
 *
 */

import produce from "immer"
import * as actionTypes from '../constants/action-types';

export default (state = {}, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.VALIDATE_ACCOUNT_ELIGIBILITY_SUCCESS:
                draft.account = action.data.account;
                draft.accountValid = true;
                return;
            case actionTypes.AIA_REQUIRED:
                draft.account = action.data.account;
                draft.aiaRequired = true;
                return;
            case actionTypes.VALIDATE_INVOICE_SUCCESS:
                draft.invoice = action.data.invoice;
                draft.account.accountNumber = action.data?.data?.account?.accountNumber;
                draft.account.hid = action.data.hid;
                draft.accountValid = true;
                return;
            case actionTypes.ADD_ACCOUNT_SUCCESS:
                draft.account = action.data.account;
                draft.invoice = action.data.invoice;
                draft.accountAdded = true;
                return;
            case actionTypes.GET_XML_ENROLLMENT_SUCCESS:
                draft.unEnrollXmlModal = true;
                return;
            case actionTypes.GET_XML_UNENROLLMENT_SUCCESS:
                draft.enrollXmlModal = true;
                return;
            case actionTypes.GET_XML_ENROLLMENT_FAILURE:
                draft.enrollXmlErrorModal = true;
                return;
            case actionTypes.GET_XML_ENROLLMENT_COMPLETE:
                draft.enrollXmlModal = false;
                draft.unEnrollXmlModal = false;
                draft.enrollXmlErrorModal = false;
                return;
            case actionTypes.ENROLL_XML_SUCCESS:
                draft.enrolledFinished = true;
                return;
            case actionTypes.ENROLL_XML_FAILURE:
                draft.error = action.data;
                draft.enrolledFinished = true;
                return;
            case actionTypes.UNENROLL_XML_SUCCESS:
                draft.enrolledFinished = true;
                return;
            case actionTypes.UNENROLL_XML_FAILURE:
                draft.error = action.data;
                draft.enrolledFinished = true;
                return;
            case actionTypes.ENROLL_XML_COMPLETE:
                delete draft.enrolledFinished;
                delete draft.error;
                return;
            case actionTypes.REMOVE_ACCOUNT_SUCCESS:
                draft.removeFinished = true;
                return;
            case actionTypes.REMOVE_ACCOUNT_COMPLETE:
                delete draft.removeFinished;
                return;
            case actionTypes.ENROLLMENT_RESTART:
            case actionTypes.ADD_ACCOUNT_RESTART:
                delete draft.eligibility;
                delete draft.invoice;
                delete draft.accountValid;
                delete draft.accountAdded;
                delete draft.aiaRequired;
                return;
            case actionTypes.INIT_ADD_ACCOUNT:
            case actionTypes.ENROLLMENT_SUCCESS:
            case actionTypes.ADD_ACCOUNT_CANCEL:
            case actionTypes.ADD_ACCOUNT_COMPLETE:
                delete draft.account;
                delete draft.eligibility;
                delete draft.invoice;
                delete draft.accountValid;
                delete draft.accountAdded;
                delete draft.aiaRequired;
                return;
            default: return;
        }
    });