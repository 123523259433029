import React from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow} from "mdbreact";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

// Wrapper for wizard steps
export default function Details(props) {
    const {
        children, isModal, extraFooterCssClass,
        onSubmit, onCancel,
        submitLabel, cancelLabel,
        canProceed = false
    } = props;

    return <React.Fragment>
        <section id="detailsSection" className={classNames({"ups-enroll-subsection mb-4": !isModal})}>
            <MDBCard>
                <MDBCardBody className="p-0">{children}</MDBCardBody>
            </MDBCard>
        </section>
        <section className={classNames({"ups-section ups-btn-section": true, "mb-4": !isModal}, extraFooterCssClass)}>
            {onSubmit && <MDBRow className="mb-1">
                <MDBCol size="12" className="mt-3 mt-sm-0 text-center d-flex d-md-block flex-column">
                    <MDBBtn 
                        color="primary" id="details-btn-submit"
                        onClick={canProceed ? onSubmit : null}
                        disabled={!canProceed}
                    >
                        <FormattedMessage id={submitLabel || "ups.btn.submit.label"}/>
                    </MDBBtn>
                </MDBCol>
            </MDBRow>}
            {onCancel && <MDBRow className="mb-1">
                <MDBCol size="12" className="mt-3 mt-sm-0 text-center d-flex d-md-block flex-column">
                    <MDBBtn id="details-btn-cancel" flat
                        className={classNames({"btn-flat-link": true})}
                        onClick={onCancel}
                    >
                        <FormattedMessage id={cancelLabel || "ups.btn.cancel.label"}/>
                    </MDBBtn>
                </MDBCol>
            </MDBRow>}
        </section>
    </React.Fragment>;
}
