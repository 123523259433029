import React from "react";
import {
    MDBRow, MDBCol, MDBBtn
} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";

import {displayWalletSelection} from "../../../utils/payment-utils";
import SelectFieldIntl from "../../SelectFieldIntl";
import AccountPreview from "../../UPS/CommonPanel/AccountPreview";
import Alert from "../../Alert";
import {GetCategoryCodeFromWalletEntries} from "../Modal/AddScheduleModal/PaymentMethodInfo";

export default function PaymentMethodInfo({wallet, onChange, onAddPM, payByText, sessionSelection}) {
    const intl = useIntl();

    const {businessUnit = {}, merchant = {}} = sessionSelection

    const walletOptions = [{
        value: "",
        label: intl.formatMessage({id: "cancel-account-reason.select-one"})
    }].concat(wallet.map(pm => ({
        value: pm._id,
        label: displayWalletSelection(intl)(pm)
    })));
    let {paymentMethod} = payByText;
    if (paymentMethod?.active===false) paymentMethod={};
    const categoryCode = GetCategoryCodeFromWalletEntries(paymentMethod, wallet)

    return <MDBRow>
        <MDBCol size="12">
            <h3 className="mb-4">
                <FormattedMessage id="modal.view-pay-by-text.subtitle.payment-method-information"/>
            </h3>
        </MDBCol>

        <MDBCol size="12">
            <AccountPreview payByText={{...(payByText),  paymentMethod: null}} modelName = 'payByText' />
        </MDBCol>

        <MDBCol size="12">
            <p className="ups-note-1 my-0">*<span className="font-italic">
                <FormattedMessage id="ups.required-field.note"/>
            </span></p>
        </MDBCol>

        <MDBCol size="12" md={"6"}>
            <SelectFieldIntl name="paymentMethod" id="paymentMethod"
                extraContainerClasses="mt-4"
                value={paymentMethod?._id || paymentMethod?.walletId || ""}
                selectOptions={walletOptions}
                label={intl.formatMessage({id: "modal.view-pay-by-text.my-wallet"})}
                setLabelBefore required
                onChange={e => {
                    const {value} = e.target;
                    const paymentMethod = wallet.find(pm => pm._id === value);
                    return onChange({...(payByText), paymentMethod});
                }}
                validations={[["required", "field.required"]]}
            />
        </MDBCol>

        <MDBCol size="12" md={"6"}>
            <MDBBtn className="btn-block w-100 w-sm-auto mt-0 mt-md-4 mb-5 mb-md-0" color="secondary" onClick={onAddPM}>
                <FormattedMessage id="btn-add-payment-method"/>
            </MDBBtn>
        </MDBCol>
        {/* {enableAuthCheckbox ? */}

        {(categoryCode === "CC") && (businessUnit?.name === "EBS") && (merchant?.countryCode === "US") && (
            <MDBRow>
                <MDBCol size={"12"}>
                    <Alert
                        type={'warning'}
                        label={'payment-method.autopay-paybytext.credit-surcharge-warning'}
                        values={{link:<a href={"https://www.ups.com/rates"} target={"_blank"}>https://www.ups.com/rates</a>}}
                    />
                </MDBCol>
            </MDBRow>
        )}
        
    </MDBRow>;
}
