import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage,useIntl} from "react-intl";

// My Dashboard > [props.section]
export default function NavHeader({section}) {
    const intl = useIntl();
    return <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
        <ol className="ups-wrap_inner">
            <li className="ups-breadcrumb-item">
                <Link className="ups-link" to="/home">
                    <FormattedMessage id="ups.title.dashboard"/>
                </Link>
                <span aria-hidden="true"> &gt; </span>
            </li>
            <li className="ups-breadcrumb-item">
                <FormattedMessage id={section}/>
            </li>
        </ol>
    </nav>;
}
