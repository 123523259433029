const PREF_OVERVIEW = '/ups/billing/profile';
const PREF_NOTIFICATIONS = '/ups/billing/profile/notifications';
const PREF_PDF_INVOICE = '/ups/billing/profile/pdf';
const PREF_PAY_BY_TEXT = '/ups/billing/profile/paybytext';

export const PREFERENCES_ROUTES = { PREF_OVERVIEW, PREF_NOTIFICATIONS, PREF_PDF_INVOICE, PREF_PAY_BY_TEXT };

export const PREF_SECTION = {
    INVOICES: "invoices",
    DISPUTES: "disputes",
    PAYMENTS: "payments",
    ADMIN: "admin"
};