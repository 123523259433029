import * as actionTypes from "../constants/action-types";
export const createSchedule = (schedule) => {
    return {
        type: actionTypes.CREATE_SCHEDULE,
        data: schedule
    }
};

export const getSchedule = (scheduleId) => {
    return {
        type: actionTypes.GET_SCHEDULE,
        data: scheduleId
    }
};

export const getScheduleSuccess = (scheduleItem) => {
    return {
        type: actionTypes.GET_SCHEDULE_SUCCESS,
        data: scheduleItem
    }
};

export const getScheduleList = (scheduleList) => {
    return {
        type: actionTypes.GET_SCHEDULE_LIST,
        data: scheduleList
    }
};

export const getScheduleListSuccess = (scheduleList) => {
    return {
        type: actionTypes.GET_SCHEDULE_LIST_SUCCESS,
        data: scheduleList
    }
};

export const clearSchedule = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULE
    }
};

export const clearScheduleList = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULE_LIST
    }
};

export const getScheduleListByIdentity = (identity,callBack) => {
    return {
        type: actionTypes.GET_SCHEDULE_LIST_BY_IDENTITY,
        identity: identity,
        callBack: callBack
    }
};

export const getScheduleListByIdentitySuccess = (data) => {
    return {
        type: actionTypes.GET_SCHEDULE_LIST_BY_IDENTITY_SUCCESS,
        data: data
    }
};

export const getScheduleListByIdentityFailure = () => {
    return {
        type: actionTypes.GET_SCHEDULE_LIST_BY_IDENTITY_FAILURE
    }
};