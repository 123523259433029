import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../../actions/invoice-action";
import { injectIntl } from 'react-intl';

import TabsScreen from '../TabsScreen';
import TableScreen from '../TableScreen/TableScreen';
import TableDropdown from '../TableDropdown';
import CardsScreen from '../CardsScreen';

class ScreenBuilder extends React.Component {
    constructor(props) {
        super();

        this.state = {
        }
    }

    render() {
        const { screen, invoiceMetadata, invoice, invoiceLiterals, caption, isTopLevel } = this.props;
        const screenConfig = invoiceMetadata.screens[screen];

        if (!screenConfig) return null;

        const { tabs, tableDropdown, table, cards } = screenConfig;

        if (tabs) return <TabsScreen isTopLevel={isTopLevel} invoice={invoice} screen={screen} screenConfig={screenConfig} invoiceLiterals={invoiceLiterals} />;
        if (tableDropdown) return <TableDropdown isTopLevel={isTopLevel} invoice={invoice} screen={screen} screenConfig={screenConfig} invoiceLiterals={invoiceLiterals} />
        if (table)
            return <TableScreen isTopLevel={isTopLevel} caption={caption} invoice={invoice} screen={screen} screenConfig={screenConfig} invoiceLiterals={invoiceLiterals} enableSuperPaging={true} />
        if (cards) return <CardsScreen isTopLevel={isTopLevel} invoice={invoice} screen={screen} screenConfig={screenConfig} invoiceLiterals={invoiceLiterals} />
    }
}

function mapStateToProps(state, ownProps) {
    return {
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ScreenBuilder));