import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as identityActions from "../../../../actions/identity-action";
import * as validationActions from "../../../../actions/validation-action";
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIntl from '../../../CheckboxIntl';
import { withRouter, Prompt } from 'react-router-dom';
import OptOutPreferencesComponent from './OptOutPreferencesComponent';
import {PREF_SECTION} from '../../../../constants/preferences-constants';
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';

class DisputesSection extends OptOutPreferencesComponent {

    constructor(props) {
        super(props);
        const { preferences } = this.props;
        this.state = {
            onDisputeStatusChange: preferences.notifications.emailSettings.triggers.onDisputeStatusChange,
            propChangeCounter: 0
        }
    }
    
    componentDidMount(){
        this.props.setRef(this);
    }

    validateTriggers = () => {
        this.setState({
            propChangeCounter : 0
        }, () => {
            this.props.updateNotificationPreferences(PREF_SECTION.DISPUTES);
        }); 
    };

    toggleDisputes = () => {
        this.setState({
            onDisputeStatusChange: false
        }, () => { this.onPreferenceChange() });
    }

    render() {
        const { onDisputeStatusChange } = this.state;
        const { auth, intl } = this.props;
        return (
            <MDBRow className="col-md-6 mt-2">
                <MDBCol size="12" className="lg-6 mb-4">
                    <MDBCard className="card dashboard-widget-card min-mh-0 h-100  outline-card">
                        <MDBCardTitle tag="h2">
                            <FormattedMessage id="ups.preferences.disputes.title" />
                            <MDBCardBody>
                                <MDBCol size="12">
                                    <fieldset className="notify-align mt-4">
                                        <legend>
                                            <FormattedMessage id="ups.preferences.notification.notifyWhen" />
                                        </legend>
                                        <MDBRow className="invoices-align">
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onDisputeStatusChange"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.disputes.option-2" /></span>}
                                                    value={onDisputeStatusChange}
                                                    onClick={() => this.setState({onDisputeStatusChange: !onDisputeStatusChange}, () => {this.onPreferenceChange("onDisputeStatusChange")})}
                                                    checked={onDisputeStatusChange}
                                                    containerClass="mb-0 mt-3 p-0"
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <div className="section-separator my-4"></div>
                                            </MDBCol>
    
                                            <MDBCol size="12" className="section-align">
                                                <CheckboxIntl
                                                    name={"dontSendanyDisputes"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.dispute-btn" /></span>}
                                                    onClick={this.toggleDisputes}
                                                    checked={!(onDisputeStatusChange)}
                                                    containerClass="mb-0 mt-3 p-0"
                                                />
                                            </MDBCol>
    
                                            {auth.user.adUserId ? null :
                                                <MDBCol size="12" className="mt-3 d-flex d-md-block flex-column">
                                                    <MDBBtnWrapper label={intl.formatMessage({id: "ups.preferences.notifications.btn-save"})}  aria-label={intl.formatMessage({id: "ups.preferences.disputes.save.button.label"})} color="primary" onClick={this.validateTriggers} >
                                                    </MDBBtnWrapper>
                                                </MDBCol>}
                                        </MDBRow>
                                    </fieldset>
                                </MDBCol>
                            </MDBCardBody>
                        </MDBCardTitle>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        auth: state.auth,
        merchants: state.auth.merchants,
        preferences: state.identity.preferences
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DisputesSection)));