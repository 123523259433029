import React from 'react';
import {connect} from "react-redux";
import AccountTable from "../../components/UPS/AccountTable";
import {MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import {bindActionCreators} from "redux";
import * as accountActions from "../../actions/account-action";
import {FormattedMessage, injectIntl} from "react-intl";
import {TYPE_ACCOUNT_TYPE_PLAN} from "../../constants/paymentus-resources";


class ManagePlan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountModal: false
        };
    }

    componentDidMount() {
    }

    toggleModal = () => {
        this.setState({
            accountModal: !this.state.accountModal
        });
    }

    render() {

        let components = [];
        const {intl} = this.props;
        components.push(
            <AccountTable caption= "ups.title.admin.manage-plans" key={"manage-plan-table"} accountTypeOverride={TYPE_ACCOUNT_TYPE_PLAN}/>
        );

        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <a role='link' className="ups-link" href="#"><FormattedMessage
                                                        id={"ups.title.admin"}/></a><span
                                                    aria-hidden="true"> &gt; </span></li>
                                                <li className="ups-breadcrumb-item"><FormattedMessage
                                                    id={"ups.title.admin.manage-plans"}/></li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}><FormattedMessage
                                    id={"ups.title.admin.manage-plans"}/></h1>
                                {components}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(injectIntl(ManagePlan));