import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead  } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../ReactPortalModal";

import {formatInvoiceDate, formatCurrency} from "../../../utils/invoice-utils";
import {get as lodashGet, groupBy as lodashGroupBy, orderBy} from 'lodash';

const sectionHeader = (sectionLabel) => {
  return (
      <tr>
        <th id="charge-outbound" colSpan="2" scope="colgroup">{sectionLabel}</th>
      </tr>
  );
};

const sectionRow = (rowLabel, charge) => {
  return (
    <tr>
        <td headers={"charge-outbound charge-description"} className={"text-left col-8"}>
            {rowLabel}
        </td>
        <td headers={"charge-outbound charge-amount"} className={"text-right"}>
            {charge}
        </td>
    </tr>
  )
};


class UPSInvoiceSummaryModal extends Component {

    constructor(props) {
        super(props);
    }

    handlePrint = (e) => {
        window.print();
        e.preventDefault();
    };

    generateTable() {
        const { summaryData, intl, invoice: { currencyCode, invoiceType, outstandingAmount }, invoiceLiterals, merchant } = this.props;
        const tableSections = [];
        const isCreditNote = invoiceType === "101";
        
        if (summaryData) {
            const charges = lodashGroupBy(summaryData, 'fields.secCd1');
            for (const [key, value] of Object.entries(charges)) {
                const sortedValue = orderBy(value,'fields.summSeqNr',['asc'])
                tableSections.push(sectionHeader(lodashGet(invoiceLiterals,key)));
                for (let i = 0; i < sortedValue.length; i++) {
                    const secCd2 = lodashGet(sortedValue[i], "fields.secCd2");
                    const secCd2Label = lodashGet(invoiceLiterals, secCd2, secCd2);
                    const netCharge = lodashGet(sortedValue[i], "fields.netCharge");
                    if (typeof netCharge === 'undefined' ) continue;
                    tableSections.push(sectionRow(secCd2Label, formatCurrency(intl, netCharge, currencyCode, merchant.currencyDisplay)));
                }
            }
        }

        return (
            <MDBTable className={"table-two-col table-invoice-summary has-multi-headers"}>
                <MDBTableHead>
                    <th id={"charge-description"}><FormattedMessage id="invoice.summary-charge-desc.label" /></th>
                    <th id={"charge-amount"} className={"text-right"}><FormattedMessage id="invoice.summary-amount.label" /></th>
                </MDBTableHead>
                <MDBTableBody>
                    {tableSections}
                </MDBTableBody>
                <tfoot>
                    <tr className={"total-p-line"}>
                        {
                            isCreditNote
                            ?  <td headers={"charge-description"} className={"font-weight-bold text-left col-8"}><FormattedMessage id="invoice.summary-credit-total.label" /></td>
                            :  <td headers={"charge-description"} className={"font-weight-bold text-left col-8"}><FormattedMessage id="invoice.summary-total.label" /></td>
                        }
                        <td headers={"charge-amount"} className={"font-weight-bold text-right"}>{formatCurrency(intl, outstandingAmount, currencyCode, merchant.currencyDisplay)}</td>
                    </tr>
                </tfoot>
            </MDBTable>
        )
    }

    render() {
        const { isOpen, toggleModal, backdrop, invoice, intl } = this.props;

        if (isOpen) {
            const table = this.generateTable();
            const modalHeading = intl.formatMessage({id:"invoice.summary.label"});
            return (
                <ReactPortalModal isOpen={isOpen} an_label="ups-invoice-summary">
                    <MDBModal id="invoice-summary-modal" isOpen={isOpen} toggle={() => toggleModal('invoiceSummary')} size={'lg'} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={modalHeading}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({ id: "close.dialog.btn" }, { name: modalHeading })} toggle={() => toggleModal('invoiceSummary')}>{modalHeading}</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow className={"d-none d-print-flex"}>
                                <MDBCol size={"6"} md={"2"}>
                                    <p className={'mb-0'}><strong><FormattedMessage id="invoice.summary-account-number.label" /></strong></p>
                                    <p>{invoice.accountNumber}</p>
                                </MDBCol>
                                <MDBCol size={"6"} md={"2"}>
                                    <p className={'mb-0'}><strong><FormattedMessage id="invoice.summary-number.label" /></strong></p>
                                    <p>{invoice.invoiceNumber}</p>
                                </MDBCol>
                                <MDBCol size={"6"}>
                                    <p className={'mb-0'}><strong><FormattedMessage id="invoice.summary-date.label" /></strong></p>
                                    <p>{formatInvoiceDate(intl, invoice.invoiceDate)}</p>
                                </MDBCol>

                            </MDBRow>
                            {table}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.handlePrint}><FormattedMessage id={"ups.btn-print"}/></MDBBtn>
                            <MDBBtn color="cancel" onClick={() => toggleModal('invoiceSummary')}><FormattedMessage id={"btn-close"}/></MDBBtn>

                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}


export default injectIntl(UPSInvoiceSummaryModal);

