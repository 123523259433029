import React from 'react'
import {connect} from 'react-redux';
import { MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent } from 'mdbreact';


var $ = require('jquery');

class DisplaySampleApiResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseID: "collapse1",
            activeTabItem: "1"
        }
    }

    toggleWidget = (e) => {
        $('body').toggleClass('api-open')
        $('.api-info-widget').toggleClass('active');
        e.preventDefault();
    };

    toggleTab = tabId => () => {
        if (this.state.activeTabItem !== tabId) {
            this.setState({
                activeTabItem: tabId
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.leStore !== this.props.leStore) {
            //console.log("le store", this.props.leStore);
        }
    }

    render() {
        // Add redux stores here
        const KEYS = [
            "enrollment", "config", "auth", "identity",
            "error", "validation", "accounts", "account", "paymentHistory"
        ];

        return (
            <div className={"api-info-widget"}>
                <button  className={"btn-api-toggle btn-text-link"} onClick={this.toggleWidget}>
                    <i className="material-icons">code</i>
                </button>

                <div className="api-info-content">
                    <div className="store-info-content always-show">
                        <h3><span className="api-url">Store State</span></h3>

                        <MDBNav className={"nav-tabs"}>
                            {KEYS.map((key, i) => <MDBNavItem key={key + "Tab"}>
                                <MDBNavLink link to="#"
                                    className={this.state.activeTabItem === `${i + 1}` ? "active" : ""}
                                    onClick={this.toggleTab(`${i + 1}`)}
                                >
                                    {key}
                                </MDBNavLink>
                            </MDBNavItem>)}
                        </MDBNav>

                        <MDBTabContent className={"pt-3 px-0"} activeItem={this.state.activeTabItem}>
                            {KEYS.map((key, i) => <MDBTabPane key={key + "Panel"} tabId={`${i + 1}`}>
                                <pre>{JSON.stringify(this.props.leStore[key], null, 2)}</pre>
                            </MDBTabPane>)}
                        </MDBTabContent>

                        <hr className={"hr-thick"} />
                    </div>
                </div>
            </div>
        );
    };
}

function mapStateToProps(state, ownProps) {
    return {
        leStore: state
    };
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySampleApiResponse);
