export const BUSINESS_UNIT = {
    EBS: "EBS",
    FRT: "FRT",
    SCS: "SCS"
};

export const BU_METADATA = {
    config:{
        EBS: {
            accNumMaxLength: 6,
            hasPayAllInvoicesTableButton: false,
            autoSelectPlanInvoices: true,
            hasPlans: true,
            hasPayableIr: true,
            showACHWarning: true,
            hasRequestServiceRefund: true,
            invoiceTableSavedColumns: 'invoiceColumnSettings',
            invoiceTableSearchCriteria: 'invoiceSearchCriteria',
            planInvoiceTableSavedColumns: 'planInvoiceColumnSettings',
            planInvoiceTableSearchCriteria: 'planInvoiceSearchCriteria',
            hasXmlEnrollment: true
        },
        FRT: {
            accNumMaxLength: 8,
            hasPayAllInvoicesTableButton: true,
            autoSelectPlanInvoices: false,
            hasPlans: false,
            hasPayableIr: false,
            showACHWarning: false,
            hasRequestServiceRefund: false,
            invoiceTableSavedColumns: 'prosColumnSettings',
            invoiceTableSearchCriteria: 'prosSearchCriteria',
            planInvoiceTableSavedColumns: 'frtStatementsColumnSettings',
            planInvoiceTableSearchCriteria: 'frtStatementsSearchCriteria',
            hasXmlEnrollment: false
        },
        SCS: {
            accNumMaxLength: 9,
            hasPayAllInvoicesTableButton: true,
            autoSelectPlanInvoices: false,
            hasPlans: false,
            hasPayableIr: false,
            showACHWarning: false,
            hasRequestServiceRefund: false,
            invoiceTableSavedColumns: 'scsInvoiceColumnSettings',
            invoiceTableSearchCriteria: 'scsInvoiceSearchCriteria',
            planInvoiceTableSavedColumns: 'scsStatementsColumnSettings',
            planInvoiceTableSearchCriteria: 'scsStatementsSearchCriteria',
            hasXmlEnrollment: false
        }
    },
    autoPayFlow:{
        EBS: {
            accountSelectionInstructions: "schedule.account-plan-selection.instructions-1"
        },
        FRT: {
            accountSelectionInstructions: "schedule.account-plan-selection.instructions-account-only"
        },
        SCS: {
            accountSelectionInstructions: "schedule.account-plan-selection.instructions-account-only"
        }
    },
    invoiceTable:{
        EBS:{
            pageTitle: 'invoice.my-invoices.title',
            drilldownPageTitle: 'invoice.plan-invoice-summary.title',
            backButton: 'invoice.btn-back-to-my-plan-invoices.label',
            invoiceNumber: "invoice.invoice-table.invoice-number",
            accountNumber: "invoice.invoice-table.account-number",
            invoiceDate: "invoice.invoice-table.date",
            invoiceStatus: "invoice.invoice-table.status",
            invoiceAmount: "invoice.invoice-table.billed-amount",
            outstandingAmount: "invoice.invoice-table.amount-due",
            dueDate: "invoice.invoice-table.invoice-due-date",
            invoiceType: "invoice.invoice-table.invoice-type",
            pastDueAlert: "invoice.alert-message.past-due.label",
            payInvoiceLabel: "invoice.btn-pay-invoice.label",
            downloadInvoice: "invoice.btn-download-invoice.label",
            moreActionsLabel: "more-actions.invoices-number.label",
            downloadInvoices: "invoice.btn-download-multiple.label"
        },
        FRT:{
            pageTitle: 'invoice.my-pros.title',
            drilldownPageTitle: 'invoice.statements-summary.title',
            backButton: 'invoice.btn-back-to-my-statements.label',
            invoiceNumber: "invoice.freight-table.pro-number",
            accountNumber: "invoice.freight-table.account-number",
            invoiceDate: "invoice.freight-table.date",
            invoiceStatus: "invoice.freight-table.status",
            invoiceAmount: "invoice.freight-table.billed-amount",
            outstandingAmount: "invoice.freight-table.amount-due",
            dueDate: "invoice.freight-table.due-date",
            pastDueAlert: "invoice.alert-message.pros-past-due.label",
            payInvoiceLabel: "invoice.btn-pay-pro.label",
            downloadInvoice: "invoice.btn-download-pro.label",
            moreActionsLabel: "more-actions.pro-number.label",
            downloadInvoices: "pro.btn-download-multiple.label"
        },
        SCS:{
            drilldownPageTitle: 'invoice.statements-summary.title',
            backButton: 'invoice.btn-back-to-my-statements.label',
            invoiceNumber: "invoice.invoice-table.invoice-number",
            invoiceDate: "invoice.plan-statement-table.invoice-date",
            invoiceAmount: "invoice.plan-statement-table.billed-amount",
            invoiceType: "invoice.invoice-table.service-type",
            moreActionsLabel: "more-actions.invoices-number.label"
        }
    },
    planInvoiceTable: {
        EBS:{
            pageTitle: "invoice.my-plan-invoices.title",
            pageSubHeader: "invoice.my-plan-invoices.drill-info",
            pastDueAlert: "invoice.alert-message.past-due.label",
            viewDrillDownMenuButton: "invoice.btn-view-plan-summary.label",
            invoiceDate: "invoice.invoice-table.date",
            invoiceAmount: "invoice.invoice-table.billed-amount",
            invoiceType: "invoice.invoice-table.invoice-type",
            moreActionsLabel: "more-actions.plan-invoices-label",
            downloadInvoice: "invoice.btn-download-invoice.label"
        },
        FRT:{
            pageTitle: "invoice.my-statements.title",
            pageSubHeader: "invoice.my-statements.drill-info",
            pastDueAlert: "invoice.alert-message.statements-past-due.label",
            viewDrillDownMenuButton: "invoice.btn-view-statement-summary.label",
            invoiceDate: "invoice.plan-statement-table.invoice-date",
            invoiceAmount: "invoice.plan-statement-table.billed-amount",
            invoiceType: "invoice.plan-statement-table.invoice-type",
            moreActionsLabel: "more-actions.statement-label",
            downloadInvoice: "invoice.btn-download-statement.label"
        },
        SCS:{
            pageTitle: "invoice.my-statements.title",
            pageSubHeader: "invoice.my-statements.drill-info",
            pastDueAlert: "invoice.alert-message.statements-past-due.label",
            viewDrillDownMenuButton: "invoice.btn-view-statement-summary.label",
            invoiceDate: "invoice.plan-statement-table.invoice-date",
            invoiceAmount: "invoice.original-billed-amount.label",
            moreActionsLabel: "more-actions.statement-label",
            downloadInvoice: "invoice.btn-download-statement.label"
        }
    },
    paymentFlowTable:{
        EBS: {
            planAccNumLabel: 'modal.view-schedule.plan-account',
            planAccNameLabel: 'modal.view-schedule.plan-account-name',
            invoiceNumLabel: 'invoice.invoice-table.invoice-number',
            invoiceDateLabel: 'invoice.invoice-table.date',
            accountNumLabel: 'table-heading.account.account-number',
            accountNameLabel: 'table-heading.account.account-name',
            planNameLabel: 'table-heading.plan.plan-name'
        },
        FRT: {
            planAccNumLabel: 'invoice.invoice-table.account-number',
            planAccNameLabel: 'modal.view-schedule.account-name',
            invoiceNumLabel: 'invoice.freight-table.pro-number',
            invoiceDateLabel: 'invoice.invoice-table.pro-date'
        },
        SCS: {
            planAccNumLabel: 'invoice.invoice-table.account-number',
            planAccNameLabel: 'modal.view-schedule.account-name',
            invoiceNumLabel: 'invoice.invoice-table.invoice-number',
            invoiceDateLabel: 'invoice.invoice-table.date'
        }
    },
    paymentFlow:{
        EBS:{
            pageTitle: 'ups.title.pay-invoice',
            step1Title: 'make-payment.invoice-information.card-header.title',
            invoicesToPayCount: "make-payment.payment-components-table.invoice-count",
            debitOnlyWarning: 'payment.credit-not-permitted',
            recentPaymentModalDesc: 'invoice.recent-payments.alert-desc',
            recentPaymentModalWarning: 'invoice.recent-payments.alert-warning',
            recentPaymentModalBlockedWarning: 'invoice.recent-payments-blocked.alert'
        },
        FRT:{
            pageTitle: 'ups.title.pay-pros',
            step1Title: 'make-payment.invoice-information.card-header.pro-title',
            invoicesToPayCount: "make-payment.payment-components-table.PRO-count",
            debitOnlyWarning: 'payment.credit-not-permitted.pro',
            recentPaymentModalDesc: 'invoice.recent-payments.alert-desc.pro',
            recentPaymentModalWarning: 'invoice.recent-payments.alert-warning.pro',
            recentPaymentModalBlockedWarning: 'invoice.recent-payments-blocked.alert.pro'
        }
    },
    chargebackTable:{
        EBS: {
            moreActionsLabel: "more-actions.multiple-invoices.label"
        },
        FRT: {
            moreActionsLabel: "more-actions.multiple-pro.label"
        },
        SCS: {
            moreActionsLabel: "more-actions.multiple-invoices.label"
        }
    },
    paymentHistoryTable:{
        EBS: {
            moreActionsLabel: "more-actions.multiple-invoices.label"
        },
        FRT: {
            moreActionsLabel: "more-actions.multiple-pro.label"
        },
        SCS: {
            moreActionsLabel: "more-actions.multiple-invoices.label"
        }
    },
    invoiceDetails:{
        EBS: {
            pageTitle: "invoiceDetails.title"
        },
        FRT: {
            pageTitle: "invoice.freight-details.title"
        }
    }
};