import * as actionTypes from '../constants/action-types';

// This reducer is not persisted in redux local storage (by design)
// it starts from scratch on a page refresh with default state

const INIT_STATE = {
    spinner: false, // when set to true, will show spinner
    basicModalOpen: false,
    basicModalMsg: null,
    basicModalTitle: null,
    basicModalButtons: null,
    basicModalCallback: null,
    modalOpen: false, // used to set the tabIndex of -1 or 0 to prevent tab focus when modal is opened
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_SPINNER:
            return {
                ...state,
                spinner: !state.spinner
            };
        case actionTypes.SHOW_SPINNER:
            return {
                ...state,
                spinner: true,
                spinnerText: action.data
            };
        case actionTypes.HIDE_SPINNER:
            return {
                ...state,
                spinner: false,
                spinnerText: null
            };
        case actionTypes.OPEN_BASIC_MODAL:
            return{
                ...state,
                basicModalOpen: true,
                basicModalTitle: action.title,
                basicModalMsg: action.message,
                basicModalButtons: action.buttons,
                basicModalCallback: action.callback
            }
        case actionTypes.CLOSE_BASIC_MODAL:
            return{
                ...state,
                basicModalOpen: false,
                basicModalTitle: null,
                basicModalMsg: null,
                basicModalButtons: null,
                basicModalCallback: null
            }
        default:
            return state;
    }
}