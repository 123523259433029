import React                                        from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader}     from 'mdbreact';
import ReactPortalModal                             from "../../../ReactPortalModal";
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { FormattedMessage, useIntl }                 from 'react-intl';

export default function SaveModal({ isOpen, closeModal, component, enabled, mobileNumber, modalData, modalHeadigKey,modalHeadingCssClass }) {
    const intl = useIntl();
    const modalHeading = modalHeadigKey? modalHeadigKey: "ups.preferences.btn.savechanges";
    
    return (
        <ReactPortalModal isOpen={isOpen} an_label="save-preferences">
            <MDBModal isOpen={isOpen} size="md" disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                <MDBModalHeader tag="h2" className={modalHeadingCssClass} closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={closeModal}>
                    <FormattedMessage id={modalHeading} />  
                </MDBModalHeader>
                <MDBModalBody>
                    <section className={"ups-section ups-enroll-section"}>
                        <section className={"ups-enroll-subsection mb-4"}>
                            {modalData}
                        </section>
                    </section>
                </MDBModalBody>
                {isOpen? 
                        <MDBRow>
                            <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                                <MDBBtn color="primary" onClick={closeModal}>
                                    <FormattedMessage id="close.label" />
                                </MDBBtn>
                            </MDBCol>   
                        </MDBRow> : null 
                }
            </MDBModal>
        </ReactPortalModal>
    );
}
