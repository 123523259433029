import React from "react";
import {MDBTable, MDBTableBody} from "mdbreact";
import {FormattedMessage, FormattedDate, useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {stripAccountNumber, getPlanAccNameLabel, formatCurrency} from "../../../utils/ups-utils";
import {displayScheduleFrequency, displayWalletSelection} from "../../../utils/payment-utils";
import SepaMandateDownload from "../SepaMandateDownload"
import {FormattedNumber} from "../../IntlFix/FormattedNumber";

export default function ScheduleView({schedule: s}) {
    const intl = useIntl();
    const {selectedPaymentType: businessUnit} = useSelector(s => s.auth.user);
    const {merchant: selectedMerchant} = useSelector(s => s.config.sessionSelection);
    const {account: acc = {}, paymentMethod: pm = {}} = s;
    const planNumber = acc.isParent ? acc.accountNumber : acc.parentAccount?.accountNumber;
    const accountNumber = acc.isParent ? null : acc.accountNumber;

    return <MDBTable className="table-two-col">
        <MDBTableBody>
            <tr>
                <th><FormattedMessage id="modal.view-schedule.confirmation-number"/></th>
                <td>{s.referenceId}</td>
            </tr>

            {acc.accountName &&
                <tr>
                    <th>{getPlanAccNameLabel(intl)(businessUnit)}</th>
                    <td>{acc.accountName}</td>
                </tr>
            }

            {planNumber &&
                <tr>
                    <th><FormattedMessage id="modal.view-schedule.plan"/></th>
                    <td>{stripAccountNumber(planNumber, acc.paymentType)}</td>
                </tr>
            }

            {accountNumber &&
                <tr>
                    <th><FormattedMessage id="modal.view-schedule.account"/></th>
                    <td>{stripAccountNumber(accountNumber, acc.paymentType)}</td>
                </tr>
            }

            <tr>
                <th><FormattedMessage id="modal.view-schedule.payment-method"/></th>
                <td>{displayWalletSelection(intl)(pm)}</td>
            </tr>

            <tr>
                <th><FormattedMessage id="modal.view-schedule.frequency"/></th>
                <td>{displayScheduleFrequency(intl)(s)}</td>
            </tr>

            <tr>
                <th><FormattedMessage id="modal.view-schedule.payment-amount"/></th>
                {s.amount ?
                    <td>
                        {/* <FormattedNumber
                            value={s.amount}
                            style="currency"
                            currency={s.merchant?.supportedCurrency}
                        /> */}
                        {formatCurrency(intl, s.amount, s.merchant?.supportedCurrency, selectedMerchant?.currencyDisplay)}
                        {" "}
                        <FormattedMessage id="modal.view-schedule.limit"/>
                    </td> :
                    <td><FormattedMessage id="modal.view-schedule.total-amount-charged"/></td>
                }
            </tr>

            <tr>
                <th><FormattedMessage id="modal.view-schedule.end-date"/></th>
                {s.expiryDate ?
                    <td><FormattedDate
                        value={s.expiryDate}
                        year="numeric"
                        month="short"
                        day="2-digit"
                        timeZone="utc"
                    /></td> :
                    <td><FormattedMessage id="modal.view-schedule.endless"/></td>
                }
            </tr>
            {s.sepaMandate?.authorizeSepa && <tr>
                <th><FormattedMessage id="modal.view-schedule.sepa-mandate-download"/></th>
                <td><SepaMandateDownload schedule={s} /></td>
            </tr>}
        </MDBTableBody>
    </MDBTable>;
}
