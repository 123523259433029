import React from 'react';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

export default function MDBBtnWrapper({ children, label, "aria-label": ariaLabel, ...rest }) {
    return <MDBBtn aria-label={ariaLabel ?? label} {...rest}>{label ?? children}</MDBBtn>
}

MDBBtnWrapper.propTypes = {
    label: PropTypes.string, //must be string to function properly
    children: PropTypes.node, //can use this instead of the label property if we want to make an icon button, for example. In these cases we need to explicitly define aria-label
    ariaLabel: PropTypes.string
}