import React, { Component } from 'react';
import { getReferenceNumber, getChargebackSource } from '../../../utils/payment-history-util'
import { injectIntl } from 'react-intl';
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import {SORTED_DSC} from "../../DataTableV3/DataTableV3-utils";
import memoize from "memoize-one";
import {columnParsers} from "../ChargebackHistoryTable/ChargebackHistoryTable-utils";

class ChargebackHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableState: INITIAL_TABLE_STATE
        };
    }

    getColumns = memoize(() => {
        const {intl} = this.props;
        const parsers = columnParsers(intl);
        return [
            {
                field: 'transactionType',
                label: intl.formatMessage({ id: 'chargeback-history.history-table.transaction-type' }),
                display: parsers.transactionType,
                serialize: parsers.transactionType,
                sortFunc: parsers.transactionType,
                thClassName: 'datatable-relative-col'
            },
            {
                field: 'comfirmationNumber',
                label: intl.formatMessage({ id: 'payment-history.history-table.confirmation_number' }),
                display: (rowData)=>getReferenceNumber(rowData),
                serialize: (rowData)=>`${getReferenceNumber(rowData)}`,
                sortFunc: (rowData)=>getReferenceNumber(rowData),
                thClassName: 'datatable-relative-col'
            },
            {
                field: 'paymentStatus',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_status' }),
                display: parsers.paymentStatus,
                serialize: parsers.paymentStatus,
                sortFunc: parsers.paymentStatus,
                mobileHidden: true
            },
            {
                field: 'source',
                label: intl.formatMessage({ id: 'view-payment-modal.source' }),
                display: (rowData)=>getChargebackSource(intl, rowData),
                serialize: (rowData)=>getChargebackSource(intl, rowData),
                sortFunc: (rowData)=>getChargebackSource(intl, rowData)
            },
            {
                field: 'paymentDate',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_date' }),
                display: parsers.paymentDate,
                serialize: parsers.paymentDate,
                mobileCardPrimary: true,
                mobileDisplay: (rowData) => `${parsers.paymentStatus(rowData)} – ${parsers.paymentDate(rowData)}`
            },
            {
                field: 'paymentAmount',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_amount' }),
                display: parsers.paymentAmount,
                serialize: parsers.paymentAmount,
                sortFunc: parsers.paymentAmount,
                thClassName: 'text-right',
                tdClassName: 'text-right'
            }
        ];
    })

    render() {
        const { paymentList, intl, caption } = this.props;
        const { tableState } = this.state;
        const columns = this.getColumns(intl.locale)

        return (
            <FilteredDataTableV3
            caption={caption}
                data={paymentList}
                columns={columns}
                searchable
                mobileCard
                responsive
                tableState={tableState}
                tableStateAction={(action)=>this.setState(action)}
                itemsPerPage={[5, 10, 20, 50, 100]}
                defaultSorting={"paymentDate"}
                defaultSortDirection={SORTED_DSC}
                rowHeaderField={'comfirmationNumber'}
            />
        )
    }
}

export default injectIntl(ChargebackHistoryTable);
