export const SCHEDULE = "SCHEDULE";
export const ONE_TIME = "One Time";
export const AUTOMATIC = "Automatic";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const SCHEDULED = "SCHEDULED";
export const PAYMENT_STATUS = "PAYMENT_STATUS";
export const PLAN = "PLAN";
export const ACCOUNT = "ACCOUNT";
export const INVOICE = 'INVOICE';
export const INVOICE_DATE = "INVOICE_DATE";
export const INVOICE_DUE_DATE = "INVOICE_DUE_DATE";
export const VIEW_PAYMENT = "VIEW_PAYMENT";
export const VIEW_CHARGEBACK_ACCOUNT_MULTI = "VIEW_CHARGEBACK_ACCOUNT_MULTI";
export const VIEW_CHARGEBACK_HISTORY = "VIEW_CHARGEBACK_HISTORY";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const PAYMENT_CANCELED = "PAYMENT_CANCELED";
export const CHANNEL_CODE_EXT = "EXT";
export const EXTERNAL_CODE_BC = 'BC';
export const EXTERNAL_CODE_O = 'O';
export const SCHEDULED_PAYMENT = 'SCHEDULED_PAYMENT';
export const REAL_TIME = 'REAL_TIME';
export const AUTO_PAY = 'AUTO_PAY';
export const CANCEL_FUTURE_PAYMENT_EXECUTING = "paymentCancel.futurePaymentExecuting";
export const ACH_RETURNS = "ACH_RETURNS";
export const PAYMENT_STATUS_RETURNED = "RETURNED";
export const CHARGEBACK_OPERATION = "VOID";
export const CHARGEBACK_REVERSAL_OPERATION = "SALE";
export const TRANSACTION_TYPE_CHARGEBACK = "chargeback";
export const TRANSACTION_TYPE_CHARGEBACK_REVERSAL = "chargeback-reversal";
export const PAGE_SIZE = 10000;
