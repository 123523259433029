import {
    overIf,
    getFullUrl,
    callApiGet,
    callApiPost,
    callApiPut,
    callApiDelete,
    toUrlNvps
} from "../utils/utils";
import {DATE_FORMAT, ON_DUE, BEFORE_DUE, WEEKLY} from "../constants/autopay";
import moment from "moment";
import * as R from "ramda";

// Helpers
const SCH_ROOT_PROPS = [
    "account",
    "amount",
    "authorizeSchedule",
    // "authorizeSepa",
    "expiryDate",
    // "merchantId",
    "payBeforeDueDate",
    // "sepaSignDate",
    // "sepaSignURL",
    // "sepaSignIP",
    "sepaMandate",
    "scheduleDay",
    "type",
    "walletId",
    "authorizeDD"
];
const weeklyDayLens = R.lensProp("scheduleDay");
const daysBeforeLens = R.lensProp("payBeforeDueDate");
const maxAmountLens = R.lensProp("amount");
const expiryLens = R.lensProp("expiryDate");
const accountLens = R.lensProp("account");
const pmLens = R.lensProp("paymentMethod");

const numericCast = n => +n;
const parseISO = d => moment.utc(d).format(DATE_FORMAT);
const trimScheduleForFrequency = R.cond([
    [R.propEq("type", ON_DUE), R.omit(["scheduleDay", "payBeforeDueDate"])],
    [R.propEq("type", BEFORE_DUE), R.omit(["scheduleDay"])],
    [R.propEq("type", WEEKLY), R.omit(["payBeforeDueDate"])],
    [R.T, R.identity]
]);

const getAccountData = R.compose
    ( // R.omit(['planNumber'])
      R.when
        ( R.propEq('accountNumber', null)
        , account => ({...account, accountNumber: account.planNumber})
        )
    , R.pick(["id", "isParent", "paymentType", "accountNumber", "type", "planNumber"])
    );

// Converters
const scheduleToBody = R.compose
    ( s => ( s.amount? s : {...s, amount: 0}) // Change null to 0 so that it can be stored in Oracle
    , overIf(weeklyDayLens, numericCast)
    , overIf(daysBeforeLens, numericCast)
    , overIf(maxAmountLens, numericCast)
    , R.reject(R.isNil)
    , trimScheduleForFrequency
    , overIf(accountLens, getAccountData)
    , R.pick(SCH_ROOT_PROPS)
    , s => ({...s, walletId: s.paymentMethod._id})
    );

// Disable/enable plans in AP
const resToAccounts = R.filter(
    // R.complement(R.propEq("isParent", true))
   R.T
);

const resToSchedule = R.compose
    ( s => ( s.amount > 0? s : {...s, amount: null}) // Change non-positive value to null for UI logic
    , overIf(expiryLens, parseISO)
    , overIf(pmLens, R.omit(["walletId", "nickName"]))
    , overIf(pmLens, pm => ({...pm, _id: pm.walletId, cardNickName: pm.nickName}))
    );

const resToSchedules = R.map(resToSchedule);

// API calls
const callGetAccounts = data =>
  callApiGet({ url: getFullUrl(`/schedule/accounts/list?${toUrlNvps(data)}`) });

const callGetSchedules = () =>
  callApiGet({ url: getFullUrl('/schedule/list') });

const callAddSchedule = schedule => callApiPost({
    url: getFullUrl(`/schedule/create`),
    body: scheduleToBody(schedule)
});

const callEditSchedule = schedule => callApiPut({
    url: getFullUrl(`/schedule/update/${schedule.id}`),
    body: scheduleToBody(schedule)
});

const callRemoveSchedule = schedule => callApiDelete({
    url: getFullUrl(`/schedule/remove/${schedule.id}`)
});

const callGetLatestAccount = data =>
  callApiGet({ url: getFullUrl(`/schedule/account?${toUrlNvps(data)}`) });

// Exports
export const getAutopayAccounts = R.compose
    ( R.andThen(resToAccounts)
    , callGetAccounts
    );
export const getSchedules = R.compose
    ( R.andThen(resToSchedules)
    , callGetSchedules
    );
export const addSchedule = R.compose
    ( R.andThen(resToSchedule)
    , callAddSchedule
    );
export const editSchedule = R.compose
    ( R.andThen(resToSchedule)
    , callEditSchedule
    );
export const removeSchedule = callRemoveSchedule;

export const getLatestAccount = callGetLatestAccount;
