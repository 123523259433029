import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {buildHelpCenterUrl, countryUPSType} from "../../../utils/ups-utils.js";
import {TYPE_ACCOUNT_TYPE_ACCOUNT, TYPE_ACCOUNT_TYPE_PLAN} from "../../../constants/paymentus-resources";
import { useEffect, useState }       from "react";
import { useSelector}                       from "react-redux";

export default function EnrollmentInfoMessage({enrollMode, country, language, accountType}) {
    const intl = useIntl();
    const { countryLocale } = useSelector(s => s.settings);
    const { selectedCountry, locale } = useSelector(s => (s.auth.user || s.auth.userInfo));
    const [ globalSelectedCountry, updateglobalSelectedCountry ] = useState();
    const [ globalSelectedLanguage, updateglobalSelectedLanguage ] = useState();
    
    useEffect(() => {
        let languageCode = locale ? locale.split("-")[0] : null;
        let countryCode = selectedCountry ? selectedCountry.toLowerCase() : null;
        if(countryLocale){
            let arr = countryLocale.split("-");
            languageCode = arr[0];
            countryCode = arr[1].toLowerCase();
        }
        if(languageCode && countryCode){            
            updateglobalSelectedCountry(countryCode);
            updateglobalSelectedLanguage(languageCode);
        }
    }, [countryLocale]);

    return <p>
        <FormattedMessage id={"enroll.line"} values={{
            line1: enrollMode
            ? <FormattedMessage id={`enroll.line1.${countryUPSType(country).replace(/\s/g, "").toLowerCase()}`}/>
            : (accountType === TYPE_ACCOUNT_TYPE_PLAN)
                ? <FormattedMessage id={`add-plan.line1.${countryUPSType(country).replace(/\s/g, "").toLowerCase()}`}/>
                : <FormattedMessage id={`ups.account.line1.${countryUPSType(country).replace(/\s/g, "").toLowerCase()}`}/>
            ,
            line2: <FormattedMessage id={'enroll.line3'} values={{
                link: <a role='link' title={intl.formatMessage({id:"ups.new-tab.link.label"})} href={buildHelpCenterUrl(globalSelectedCountry, globalSelectedLanguage)} target="_blank">
                    <FormattedMessage id="enroll.line3-2"/>
                </a>
            }}/>
        }}/>
    </p>;
}
