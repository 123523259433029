import React, { useEffect, useState }       from "react";
import { useSelector, useDispatch}          from "react-redux";
import {FormattedMessage}                   from "react-intl"; 
import {getQueryParams}                     from "../utils/utils";
import {csrLogin, init}                     from "../actions/auth-action";
import {clearExtraCookies}                  from "../utils/cookie-utils"

export default function CSRLogin(props) {
    const dispatch = useDispatch();       
    const [ token, updateTokenState] = useState("");  
    const { user } = useSelector(s => s.auth);
    const { merchants } = useSelector(s => s.config);
    const { errors } = useSelector(s => s.error);
    const accessibleMerchants = useSelector(s => s.auth.merchants); 
        
    useEffect(() => {
        //clear cookies before starting session to avoid 400 error due to increased header size
        clearExtraCookies();

        //remove user and merchants from old state, if any
        dispatch(init());
        
        const query = getQueryParams(props.location.search);
        const {at } = query; // at = authToken

        if(at){
            setTimeout(() => {                
                updateTokenState(at);
                dispatch(csrLogin(at));
            }, 500);
        }
        else{
            updateTokenState(undefined);
        }
    }, []);

    useEffect(() => {
        // wait until merchants are in the state before redirecting and also if token is present then only move to this step       
        if (token) {            
            if (merchants && user && accessibleMerchants) {
                props.history.push({pathname: '/home'});
            }
          
        }
    }, [merchants, user, accessibleMerchants, token]);

    if(errors && errors.length > 0){
        let errorKey = errors[0];
        if(errors.findIndex(element => (element === "xotp.user.action.failed" || element === "xotp.user.action.error")) > -1){
            errorKey = "login.missing.user";
        }
        return (
            <div className={"ups-landing-container"}>
                <div className="text-center mb-4 mt-5">
                    { errorKey ? <FormattedMessage id={errorKey} /> : <FormattedMessage id="internalError"/>}
                </div>
            </div>
        )
    }

    return (
        <div className={"ups-landing-container"}>
            <div className="text-center mb-4 mt-5">
                {token === undefined ? <FormattedMessage id="login.token.missing" /> : <FormattedMessage id="login.status" />}
            </div>
        </div>
    );
}
