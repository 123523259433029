import React, {Component} from 'react';
import classNames from "classnames";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import CheckboxIntl from '../../../components/CheckboxIntl';
import Exception from '../../Exception/index'
import {FormattedMessage, injectIntl} from 'react-intl';
import produce from "immer";
import {stripAccountNumber} from "../../../utils/ups-utils";
import attachValidator from "../../../utils/validation/attach-validator";
import ValidationErrors from "../../ValidationErrors";
import * as validationActions from "../../../actions/validation-action";
import {MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRow} from "mdbreact";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";

class ManageUserAccessTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            datatableRows: [],
            selectedList: {},
            listTotal: 0,
            planTotal: 0,
            singleAccountTotal: 0,
            selectedCount: 0,
            plansSelected: 0,
            accountsSelected: 0,
            singleAccountsSelected: 0,
            planAccountCounts: {},
            tableState: {
                filter: {},
                ...INITIAL_TABLE_STATE
            }
        };
        if (this.props.selectable) this.initSelectData()
        this.datatableCols = this.createDatatableCols()
        if (this.props.registerGetSelection) this.props.registerGetSelection(() => this.state.selectedList)
        if (this.props.validations) attachValidator.call(this)
    }

    componentDidMount() {
        let {name, validations} = this.props;
        if (validations) this.validator.register(name, () => this.state.selectedList)
    }

    componentWillUnmount() {
        let {name, validations} = this.props;
        if (validations) this.validator.deregister(name)
    }

    initSelectData() {
        const {accounts, preSelected} = this.props;
        let planAccounts = []
        let accountLookup = {}
        accounts.forEach((account) => {
            let {accountNumber, parentAccount, isParent} = account;
            if (isParent) {
                this.state.planAccountCounts[accountNumber] = {count: 0, total: 0}
                this.state.planTotal++
            }
            if (parentAccount) planAccounts.push(account)
            if (!isParent && !parentAccount) this.state.singleAccountTotal++
            this.state.listTotal++
            accountLookup[this.getUid(account)] = true
        })
        planAccounts.forEach((account) => {
            //need to initialize each plan count object before doing this for each plan account
            let {accountNumber} = account.parentAccount
            if (this.state.planAccountCounts[accountNumber]) this.state.planAccountCounts[accountNumber].total++
        })
        if (preSelected) {
            preSelected.forEach((account) => {
                let {parentAccount, isParent} = account;
                let uid = this.getUid(account)
                if (accountLookup[uid]) { //only preselect accounts this manager can see
                    this.state.selectedList[uid] = account
                    isParent ? this.state.plansSelected++ : this.state.accountsSelected++
                    if (parentAccount && this.state.planAccountCounts[parentAccount.accountNumber]) this.state.planAccountCounts[parentAccount.accountNumber].count++
                    if (!(parentAccount || isParent)) this.state.singleAccountsSelected++
                    this.state.selectedCount++
                }
            })
        }
    }

    getUid(account) {
        //need unique account identifier, since we can have multiple of the same accountNumber with different plan parents
        let {parentAccount, accountNumber} = account
        return parentAccount ? (accountNumber + parentAccount.accountNumber) : accountNumber
    }

    handleCheckMultiButton(type) {
        this.setState(
            produce(draft => {
                let shouldSelect = true
                if (type === "plan" && (draft.plansSelected === draft.planTotal)) shouldSelect = false
                if (type === "account" && (draft.singleAccountsSelected === draft.singleAccountTotal)) shouldSelect = false
                if (type === "all" && (draft.selectedCount === draft.listTotal)) shouldSelect = false
                this.props.accounts.forEach((account) => {
                    let {accountNumber, parentAccount, isParent} = account;
                    let uid = this.getUid(account)
                    let shouldIter = !draft.selectedList[uid]
                    let iter = shouldIter ? 1 : -1
                    if (shouldIter != shouldSelect) iter = 0
                    if (type === "account" && !(accountNumber && !(isParent || parentAccount))) return
                    if (type === "plan" && !(isParent || parentAccount)) return
                    (isParent) ? draft.plansSelected += iter : draft.accountsSelected += iter
                    if (parentAccount && draft.planAccountCounts[parentAccount.accountNumber]) draft.planAccountCounts[parentAccount.accountNumber].count += iter
                    if (!(parentAccount || isParent)) draft.singleAccountsSelected += iter
                    draft.selectedCount += iter
                    if (shouldSelect) {
                        draft.selectedList[uid] = account
                    } else {
                        delete draft.selectedList[uid]
                    }
                })
            }),
            () => this.validationCallback()
        );
    }

    handleCheckBox(account, e) {
        let checked = e.target.checked
        let iter = checked ? 1 : -1
        let {accountNumber, parentAccount, isParent} = account
        let uid = this.getUid(account)
        this.setState(
            produce(draft => {
                let checkAction = (target, val) => {
                    if (checked) {
                        draft.selectedList[target] = val
                    } else {
                        delete draft.selectedList[target]
                    }
                }
                if (isParent) {
                    this.props.accounts.forEach((child) => {
                        if (child.parentAccount && (child.parentAccount.accountNumber === accountNumber)) {
                            let parentCountObj = draft.planAccountCounts[accountNumber]
                            let childUid = this.getUid(child)
                            if (checked !== !!draft.selectedList[childUid]) {
                                parentCountObj.count += iter
                                draft.accountsSelected += iter
                                draft.selectedCount += iter
                            }
                            checkAction(childUid, child)
                        }
                    })
                    draft.plansSelected += iter
                } else if (parentAccount) {
                    draft.accountsSelected += iter
                    let parentCountObj = draft.planAccountCounts[parentAccount.accountNumber]
                    if (parentCountObj) {
                        parentCountObj.count += iter
                        let compareCount = parentCountObj.total - (checked ? 0 : 1);
                        if (parentCountObj.count === compareCount) {
                            checkAction(parentAccount.accountNumber, parentAccount)
                            draft.plansSelected += iter
                            draft.selectedCount += iter
                        }
                    }
                } else {
                    draft.accountsSelected += iter
                    draft.singleAccountsSelected += iter
                }
                checkAction(uid, account)
                draft.selectedCount += iter
            }),
            () => this.validationCallback()
        );
    };

    validationCallback() {
        const {validations, name} = this.props;
        if (validations) this.validator.validateGetValue(name)
    }

    createDatatableCols = () => {
        const {selectable, intl} = this.props;
        return [({
            field: 'card',
            label: '',
            tdClassName: 'datatable-selection-col no-export',
            orderable: false,
            display:  (data)=>(selectable ? this.createDatatableRowSelection(data): this.createDatatableRowText(data)),
            serialize: ({isParent, parentAccount, accountNumber, accountName, paymentType}) => {
                const strAcc = stripAccountNumber(accountNumber, paymentType);
                let searchText = ''
                if (accountName) searchText += accountName
                if (isParent) searchText += intl.formatMessage({id:'plan.name.text'}, {accountNumber: strAcc})
                if (parentAccount) {
                    const pAcc = stripAccountNumber(parentAccount.accountNumber, parentAccount.paymentType);
                    searchText += intl.formatMessage({id:'plan.name.text'}, {accountNumber: pAcc})
                }
                if (!isParent) searchText += intl.formatMessage({id:'account.name.text'}, {accountNumber: strAcc})
                return searchText
            },
            sortFunc: ({parentAccount, isParent, accountNumber})=>{
                let sortText = (isParent || parentAccount) ? 'p' : 'a';
                if (isParent || !parentAccount) sortText += accountNumber
                if (isParent) sortText += '0'
                if (parentAccount) sortText += (parentAccount.accountNumber + 'a' + accountNumber)
                return sortText
            }
        })]
    }

    createDatatableRowSelection = (data) => {
        if (data.accountNumber) {
            let uid = this.getUid(data)
            let key = `manage-user-access-account-list-${uid}`;
            return (
                <CheckboxIntl
                    key={key}
                    name={key}
                    id={key}
                    labelClass="mr-0"
                    value={!!this.state.selectedList[uid]}
                    onChange={this.handleCheckBox.bind(this, data)}
                    label={this.createDatatableRowText(data)}
                />
            )
        }
    };

    createDatatableRowText = (data) => {
        const {intl} = this.props;
        const {paymentType, accountName, parentAccount, isParent} = data;
        const accountNumber = stripAccountNumber(data.accountNumber, paymentType);
        const pAcc = parentAccount && stripAccountNumber(parentAccount.accountNumber, parentAccount.paymentType)
        return (
            <div>
                {accountName && (<div className='datatable-card-description'>{accountName}</div>)}
                <div>{isParent
                    ? <strong>{intl.formatMessage({id:'plan.name.text'}, {accountNumber})}</strong>
                    : <span>{intl.formatMessage({id:'account.name.text'}, {accountNumber})}</span>
                }</div>
                {parentAccount && <div>{intl.formatMessage({id:'plan.name.text'}, {accountNumber: pAcc})}</div>}
            </div>
        )
    };

    setFilter(type) {
        let {type: filterType} = this.state.tableState.filter;
        this.setState(produce(draft => {
            if (filterType === type) {
                draft.tableState.filter = {}
            } else {
                draft.tableState.filter = {type}
            }
        }))
    }

    filterFunc(data) {
        let {type: filterType} = this.state.tableState.filter
        if (filterType) {
            const {isParent, parentAccount} = data;
            if (filterType === "plan") {
                return (isParent || parentAccount)
            } else if (filterType === "account") {
                return (!isParent && !parentAccount)
            }
        }
        return true
    }

    renderFilterDOM = (data) => {
        const {singleAccountsSelected, singleAccountTotal, listTotal, selectedCount, planTotal, plansSelected} = this.state;
        const { intl } = this.props;
        return <MDBDropdown className={"datatable-custom-dropdown"}>
            <MDBDropdownToggle color="secondary" className={"btn-icon outline-fix"} aria-label={intl.formatMessage({ id: "manage-user.dropdown.selection-title" })}>
                <svg className="datatable-ups-svg-icon large-1"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                        d="M360 0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V24c0-13.3-10.7-24-24-24zM64 112c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-32zm1.6 129.4l12.7-12.6c2.1-2.1 5.5-2.1 7.6 0l20.6 20.8 47.6-47.2c2.1-2.1 5.5-2.1 7.6 0l12.6 12.7c2.1 2.1 2.1 5.5 0 7.6l-64.2 63.6c-2.1 2.1-5.5 2.1-7.6 0L65.6 249c-2.1-2.1-2.1-5.5 0-7.6zM128 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zm192-8c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-128c0 4.4-4.3 8-9.6 8H170.2s29.2-30.2 30.4-32h109.7c5.3 0 9.6 3.6 9.6 8v16zm0-128c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"/>
                </svg>
            </MDBDropdownToggle>
            <MDBDropdownMenu basic right>
                <MDBDropdownItem header><FormattedMessage
                    id="manage-user.dropdown.selection-title"/></MDBDropdownItem>
                <MDBDropdownItem toggle={false} active={selectedCount === listTotal} className={"outline-fix"}
                                 onClick={() => this.handleCheckMultiButton("all")}>
                                            <span className={"dropdrop-item-checkbox"}><FormattedMessage
                                                id="manage-user.dropdown.selection-option-all"/></span>
                </MDBDropdownItem>
                <MDBDropdownItem toggle={false}
                                 active={plansSelected === planTotal && planTotal !== 0}
                                 className={"outline-fix"}
                                 onClick={() => this.handleCheckMultiButton("plan")}>
                                            <span className={"dropdrop-item-checkbox"}><FormattedMessage
                                                id="manage-user.dropdown.selection-option-plans"/></span>
                </MDBDropdownItem>
                <MDBDropdownItem toggle={false}
                                 active={singleAccountsSelected === singleAccountTotal && singleAccountTotal !== 0}
                                 className={"outline-fix"}
                                 onClick={() => this.handleCheckMultiButton("account")}>
                                            <span className={"dropdrop-item-checkbox"}><FormattedMessage
                                                id="manage-user.dropdown.selection-option-accounts"/></span>
                </MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    }

    renderSelectDOM = (data) => {
        const {type: filterType} = this.state.tableState.filter;
        const { intl } = this.props;
        return <MDBDropdown className={"datatable-custom-dropdown"}>
            <MDBDropdownToggle color="secondary" className={"btn-icon outline-fix"} aria-label={intl.formatMessage({id:"manage-user.dropdown.filter-title"})}>
                <svg version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     width="32" height="32"
                     className="datatable-ups-svg-icon"
                     viewBox="0 0 32 32">
                    <title>
                        <FormattedMessage id="manage-user.dropdown.filter-title"/>
                    </title>
                    <path className="st0"
                          d="M28.4,0H1.6C1.2,0,0.8,0.3,0.8,0.7c0,0.2,0.1,0.4,0.2,0.5l10.2,12.2v10.8c0,0.4,0.2,0.9,0.6,1.1l5.7,4.5 c0.3,0.3,0.8,0.2,1.1-0.1c0.1-0.1,0.2-0.3,0.2-0.5V13.4L29,1.2c0.3-0.3,0.2-0.8-0.1-1C28.8,0.1,28.6,0,28.4,0z"/>
                </svg>
            </MDBDropdownToggle>
            <MDBDropdownMenu basic right>
                <MDBDropdownItem header><FormattedMessage
                    id="manage-user.dropdown.filter-title"/></MDBDropdownItem>
                <MDBDropdownItem
                    toggle={false}
                    active={(filterType === "plan")}
                    className={classNames({disable: (filterType === "account")},"outline-fix")}
                    aria-label={<FormattedMessage
                        id="manage-user.dropdown.filter-option-plans.aria-label"/>}
                    onClick={() => this.setFilter("plan")}><span
                    className={"dropdrop-item-checkbox"}><FormattedMessage
                    id="manage-user.dropdown.filter-option-plans"/></span>
                </MDBDropdownItem>
                <MDBDropdownItem
                    toggle={false}
                    active={(filterType === "account")}
                    className={classNames({disable: (filterType === "plan")},"outline-fix")}
                    aria-label={<FormattedMessage
                        id="manage-user.dropdown.filter-option-accounts.arial-label"/>}
                    onClick={() => this.setFilter("account")}><span
                    className={"dropdrop-item-checkbox"}><FormattedMessage
                    id="manage-user.dropdown.filter-option-accounts"/></span>
                </MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    }

    renderButtons = (data) => {
        return <div className={"manage-custom-buttons"}>
            {this.renderFilterDOM(data)}
            {this.renderSelectDOM(data)}
        </div>
    }

    render() {
        const {tableState} = this.state;
        const {accounts, source, validations, name, ownVState, intl, selectable, caption} = this.props;
        const {error} = this.state;

        if (error instanceof TypeError) {
            return (<Exception error={error}/>)
        } else {
            let components = [];
            selectable && components.push(
                <div key="selection-stats" className="datatable-selection-stats-container">
                    <MDBRow>
                        <MDBCol size="12" className="px-0">
                            <div className="stats-container">
                                <span className="stats-counter">{this.state.plansSelected}</span>
                                <span className="stats-desc"><FormattedMessage
                                    id={"ups-manage-user-access-table.plans-selected"}/></span>
                                <br className="d-block d-sm-none"/>
                                <span className="stats-counter ml-sm-4">{this.state.accountsSelected}</span>
                                <span className="stats-desc"><FormattedMessage
                                    id={"ups-manage-user-access-table.accounts-selected"}/></span>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            )
            components.push(<FilteredDataTableV3
            caption={caption}
                name={source}
                searchInputAriaLabel= {intl.formatMessage({ id: 'datatable.account-plan.search.arialabel' })}
                className="table-card table-card-plan"
                wrapperClassName='card-wrapper table-newline-pagination'
                trClassName={()=>'card-row'}
                itemsPerPage={[20, 50, 100]}
                data={accounts}
                columns={this.createDatatableCols()}
                defaultSorting={'card'}
                tableState={tableState}
                tableStateAction={(tableAction)=>this.setState(tableAction)}
                {...(selectable && {
                        filterFunc: this.filterFunc.bind(this),
                        searchable: true,
                        searchRight: true,
                        buttons:[
                            this.renderButtons
                        ]
                    })
                }
            />)
            if (validations && ownVState?.messages) {
                components.push(<ValidationErrors key='manage-user-access-table-error' name={name} messages={ownVState.messages} intl={intl}/>)
            }
            return (
                <React.Fragment>
                    {components}
                </React.Fragment>
            )
        }
    }
}

function mapStateToProps(state, ownprops) {
    return {
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState: state.validation.vState[ownprops.name]
        } : {})
    }
}


function mapDispatchToProps(dispatch, ownprops) {
    return {
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManageUserAccessTable)));


