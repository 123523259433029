

import * as actionTypes from '../constants/action-types';

export const getAllMerchants = (data) => {
    return {
        type: actionTypes.GET_ALL_MERCHANTS,
        data: data
    }
};

export const getAllMerchantsSuccess = (data) => {
    return {
        type: actionTypes.GET_ALL_MERCHANTS_SUCCESS,
        data: data
    }
};

export const getAllMerchantsFailure = (data) => {
    return {
        type: actionTypes.GET_ALL_MERCHANTS_FAILURE,
        data: data
    }
};

export const enter3ds = (data) => {
    return {
        type: actionTypes.ENTER_3DS,
        data
    }
};

export const complete3ds = () => ({
    type: actionTypes.COMPLETE_3DS,
});
