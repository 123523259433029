import {stripAccountNumber, padAccountNumbers} from "../../../utils/ups-utils";
import {MDBBtn} from "mdbreact";
import * as invoiceStatuses from "../../../constants/invoice-statuses";
import React from "react";
import {
    checkPayableIr,
    formatCurrency,
    formatInvoiceDate,
    getInvoiceStatusMessage,
    getInvoiceTypeMessage
} from "../../../utils/invoice-utils";

export const getDefaultPlanInvoiceColumns = (props, toggleModal, viewPlanSummary) => {
    const { businessUnit } = props;
    switch (businessUnit) {
        case 'EBS': return planInvoiceTableColumnsEBS(props, toggleModal, viewPlanSummary);
        case 'FRT': return planInvoiceTableColumnsFRT(props, toggleModal, viewPlanSummary);
        case 'SCS': return planInvoiceTableColumnsSCS(props, toggleModal, viewPlanSummary);
    }
}
export const getrowHeaderField = (props) => {
    const { businessUnit } = props;
    switch (businessUnit) {
        case 'EBS': return 'planNumber';
        case 'FRT': return 'planInvoiceNumber';
        case 'SCS': return 'planInvoiceNumber';
    }
}

const planInvoiceCommonColumns = (props, toggleModal, viewPlanSummary)=> {
    const {intl, businessUnitKeys, invoiceMetadata, countryCode, sessionSelection} = props;
    return {
        invoiceDate: {
            field: 'invoiceDate',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceDate') }),
            display: ({ invoiceDate }) => formatInvoiceDate(intl, invoiceDate),
            serialize: ({ invoiceDate }) => formatInvoiceDate(intl, invoiceDate),
            hideable: true
        },
        accountNumber: {
            field: 'accountNumber',
            label: intl.formatMessage({ id: 'invoice.plan-statement-table.account-number' })
        },
        planInvoiceNumber: {
            field: 'planInvoiceNumber',
            label: intl.formatMessage({ id: 'invoice.plan-statement-table.plan-invoice-number' }),
            display: (rowData) => <MDBBtn color={'datatable-text-link'} onClick={() => viewPlanSummary(rowData) }>{rowData.planInvoiceNumber}</MDBBtn>,
            serialize: ({planInvoiceNumber}) => planInvoiceNumber,
            sortFunc: ({planInvoiceNumber}) => planInvoiceNumber
        },
        invoiceAmount: {
            field: 'invoiceAmount',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceAmount') }),
            display: ({ invoiceAmount, currencyCode }) => formatCurrency(intl, invoiceAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            serialize: ({ invoiceAmount, currencyCode }) => formatCurrency(intl, invoiceAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            thClassName: 'text-right',
            tdClassName: 'text-right',
            hideable: true
        },
        invoiceType: {
            field: 'invoiceType',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceType') }),
            display: ({ invoiceType }) => getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType, countryCode),
            serialize: ({ invoiceType }) => getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType, countryCode),
            hideable: true
        },
    }
}

const planInvoiceTableColumnsEBS = (props, toggleModal, viewPlanSummary) => {
    const { invoiceMetadata, intl, businessUnit, sessionSelection} = props;
    const {
        invoiceDate,
        invoiceAmount,
        invoiceType
    } = planInvoiceCommonColumns(props, toggleModal, viewPlanSummary)

    return {
        invoiceDate,
        planNumber: {
            field: 'planNumber',
            label: intl.formatMessage({ id: 'invoice.plan-invoice-table.plan-number' }),
            serialize: ({ planNumber }) => stripAccountNumber(planNumber, businessUnit),
            display: (rowData) => <MDBBtn color={'datatable-text-link'} onClick={() => viewPlanSummary(rowData) }>{stripAccountNumber(rowData.planNumber, businessUnit)}</MDBBtn>,
            responsivePriority:3
        },
        invoiceStatus: {
            field: 'invoiceStatus',
            label: intl.formatMessage({ id: 'invoice.invoice-table.status' }),
            display: (rowData) => {
                const isPastDue = rowData['invoiceStatus'] === invoiceStatuses.PAST_DUE;
                const rowText = getInvoiceStatusMessage(intl, invoiceMetadata, rowData.invoiceStatus)
                return <>
                    {isPastDue
                        ? <div className="alert alert-past-due mb-0 p-0">
                            <span className="status-desc">{rowText}</span>
                        </div>
                        : <div>{rowText}</div>
                    }
                    {!checkPayableIr(rowData) && <div className="not-payable-msg"><strong>{intl.formatMessage({id:'invoice.not-payable-online'})}</strong></div>}
                </>
            },
            serialize: (rowData) => `${getInvoiceStatusMessage(intl, invoiceMetadata, rowData.invoiceStatus)}\n${!checkPayableIr(rowData) ? intl.formatMessage({id:'invoice.not-payable-online'}) : ''}`,
            sortFunc: ({invoiceStatus}) => getInvoiceStatusMessage(intl, invoiceMetadata, invoiceStatus),
            hideable: true
        },
        invoiceAmount,
        outstandingAmount: {
            field: 'outstandingAmount',
            label: intl.formatMessage({ id: 'invoice.invoice-table.amount-due' }),
            display: ({ outstandingAmount, currencyCode }) => formatCurrency(intl, outstandingAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            serialize: ({ outstandingAmount, currencyCode }) => formatCurrency(intl, outstandingAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            thClassName: 'text-right',
            tdClassName: 'text-right',
            hideable: true
        },
        dueDate: {
            field: 'dueDate',
            label: intl.formatMessage({ id: 'invoice.invoice-table.invoice-due-date' }),
            display: ({ dueDate }) => formatInvoiceDate(intl, dueDate),
            serialize: ({ dueDate }) => formatInvoiceDate(intl, dueDate),
            hideable: true
        },
        invoiceType
    };
}

const planInvoiceTableColumnsFRT = (props, toggleModal, viewPlanSummary) => {

    const {
        invoiceDate,
        accountNumber,
        planInvoiceNumber,
        invoiceAmount,
        invoiceType
    } = planInvoiceCommonColumns(props, toggleModal, viewPlanSummary)

    // this seemingly redundant mapping
    // is done in case we want to change the default order of the columns for this business unit

    return {
        invoiceDate,
        accountNumber,
        planInvoiceNumber,
        invoiceAmount,
        invoiceType
    };
}

const planInvoiceTableColumnsSCS = (props, toggleModal, viewPlanSummary) => {

    const {
        invoiceDate,
        accountNumber,
        planInvoiceNumber,
        invoiceAmount
    } = planInvoiceCommonColumns(props, toggleModal, viewPlanSummary)

    // this seemingly redundant mapping
    // is done in case we want to change the default order of the columns for this business unit

    return {
        invoiceDate,
        accountNumber,
        planInvoiceNumber,
        invoiceAmount
    };
}

export const savedColumnCompatibility = (name) => {
    //old column naming may have been saved to the user's preferences
    if(name==='planInvoiceDate') return 'invoiceDate'
    return name
}

export const formatAccountNumberFilter = (planNumberFilter, businessUnit) => {
    if (planNumberFilter && !Array.isArray(planNumberFilter)){
        planNumberFilter = [planNumberFilter]
    }
    if (businessUnit === "EBS" && planNumberFilter && Array.isArray(planNumberFilter)){
        planNumberFilter = padAccountNumbers(planNumberFilter)
    }
    return planNumberFilter
}

export const formatPlanNumberFilter = (accountNumberFilter, businessUnit) => {
    if (accountNumberFilter && !Array.isArray(accountNumberFilter)){
        accountNumberFilter = [accountNumberFilter]
    }
    if (businessUnit === "EBS" && accountNumberFilter && Array.isArray(accountNumberFilter )){
        accountNumberFilter = padAccountNumbers(accountNumberFilter)
    }
    return accountNumberFilter
}