import React, { useEffect, useReducer }         from "react";
import { Link, useParams, useLocation }         from "react-router-dom";
import { MDBCard, MDBCardBody,MDBCol
       , MDBContainer, MDBRow}                  from "mdbreact";
import { FormattedMessage, useIntl }            from "react-intl";
import { useSelector, useDispatch }             from "react-redux";

import * as R                                   from 'ramda'

import PaymentMethodTable                       from "../components/UPS/PaymentMethodTable";
import AddPaymentMethodModal                    from "../components/UPS/Modal/AddPaymentMethodModal";
import RemovePaymentMethodModal                 from "../components/UPS/Modal/RemovePaymentMethodModal";
import Error                                    from '../components/Error';
import Spinner                                  from '../components/Spinner';
import { getWalletPMs, updateWalletPm }         from '../api/wallet-api';
import { is3dSecureFlow }                       from '../utils/payment-utils';
import ThreeDSForm                              from '../components/UPS/3dSecureForm';
import FilterModal                              from "../components/UPS/Modal/FilterModal/FilterModal";
import WalletFilterCriteria                     from "../components/UPS/Modal/FilterModal/FilterCriteria/WalletFilterCriteria";
import {INITIAL_TABLE_STATE}                    from "../components/DataTableV3/DataTableV3FilterWrapper";
import {enter3ds}                               from "../actions/config-action";
import * as walletActions                       from "../actions/wallet-action";
import {getSessionFilter} from "../utils/utils";

const walletInitialState =
    { wallet: []
    , displayMode: 'CARD'
    , targetPM: null
    , pmModal: false
    , removeModal: false
    , filterModal: false
    , filterSelection: null
    , error: ''
    , fetching: true
    , threeDSecureFormData: {}
    , threeDSecureFlow: false
    };

const initWalletState = (savedFilter) => ({...walletInitialState, tableState: {...INITIAL_TABLE_STATE, filter: savedFilter ?? {}}})

const applyDefaultProp = walletId => R.ifElse
  ( R.propEq('_id', walletId)
  , R.set(R.lensProp('default'), true)
  , R.omit(['default'])
  );

function walletReducer (state, action) {
    switch (action.type) {
        case 'fetchWallet': return {
            ...state,
            fetching: true,
            error: ''
        }
        case 'receiveWallet': return {
            ...state,
            fetching: false,
            wallet: action.wallet
        }
        case 'receiveError': return {
            ...state,
            error: action.error,
            fetching: false
        }
        case 'addPaymentMethod':
        case 'editPaymentMethod': return {
            ...state,
            pmModal: !state.pmModal,
            targetPM: action.pm || null,
            error: '',
            fetching: (!state.pmModal === false)
        }
        case 'removePaymentMethod': return {
          ...state,
          targetPM: !state.removeModal ? action.pm : state.targetPM,
          removeModal: !state.removeModal,
          error: '',
          fetching: (!state.removeModal === false)
        }
        case 'filterModal': return {
          ...state,
          filterModal: !state.filterModal
        }
        case 'setDisplayMode': return {
          ...state,
          displayMode: action.displayMode
        }
        case 'applyFilter': return {
          ...state,
          tableState: {
              ...state.tableState,
              filter: action.filter
          }
        }
        case 'set_3ds_form': return {
          ...state,
          threeDSecureFormData: action.formData
        }
        case 'in_3ds_flow': return {
          ...state,
          threeDSecureFlow: true
        }
        case 'set_default_wallet': return R.over
                                            ( R.lensProp('wallet')
                                            , R.map(applyDefaultProp(action.walletId))
                                            ) (state)
        case 'tableAction': return action.tableAction(state)
        default: return state
    }
}



export default function UPSWallet() {
    const sessionFilter = getSessionFilter('walletTableFilter')
    const {walletTableFilter: dbFilter} = useSelector(s => s.identity?.preferences ?? {});
    const [walletState, dispatch] = useReducer(walletReducer, walletInitialState, ()=>initWalletState(sessionFilter ?? dbFilter));
    const {id: identityId} = useSelector(s => s.auth.user);
    const dispatchRedux = useDispatch()
    const location = useLocation()

    const dispatchError = error => dispatch({type: 'receiveError', error});
    const intl = useIntl();

    useEffect(() => {
      let closed = false;
      if (!R.isNil(location.state?.errorCode)) {
        dispatchError(location.state?.errorCode);
      }

      return () => closed = true;
    }, []);

    useEffect(() => {
      let closed = false;
      if (walletState.fetching) {
        getWalletPMs()
          .then(wallet => !closed && dispatch({type: 'receiveWallet', wallet}))
          .catch(({errorCode}) => !closed && dispatchError (errorCode));

      }
      return () => closed = true;
    }, [walletState.fetching]);

    useEffect(() => {
      if (!R.isEmpty(walletState.threeDSecureFormData)
          && !walletState.threeDSecureFlow) {
        dispatch({type: 'in_3ds_flow'});
      }
    }, [walletState.threeDSecureFormData, walletState.threeDSecureFlow])

    const setDefaultWallet = pm =>
      updateWalletPm({id: pm.id, default: true , identityId})
        .then(() => dispatch({type: 'set_default_wallet', walletId: pm.id}))
        .catch(({errorCode}) => dispatchError (errorCode));

    const toggleModal = (action, pm) => dispatch({type: action, pm});

    const addWalletHandler = pm => {
      if (is3dSecureFlow(pm)) {
        const { hostedCheckout, _id } = pm;
        dispatchRedux(enter3ds({
          redirectLocation: location.pathname,
          referenceId: _id,
          type: 'wallet'
        }))
        dispatchRedux(walletActions.set3DSecureRedirect({redirectLocation: location.pathname, referenceId: _id}))
        dispatch({ type: 'set_3ds_form', formData: hostedCheckout });
      }
    }

    return (
      <MDBContainer role="main" fluid>

        { (walletState.threeDSecureFlow || walletState.fetching) && <Spinner isSpinning={true} /> }
        {
          !R.isEmpty(walletState.threeDSecureFormData) &&
          <ThreeDSForm httpmethod={walletState.threeDSecureFormData.httpMethod}
                       hostedurl={walletState.threeDSecureFormData.hostedUrl}
                       pareq={walletState.threeDSecureFormData.PaReq}
                       md={walletState.threeDSecureFormData.MD}
                       termurl={walletState.threeDSecureFormData.TermUrl}
                       submit={walletState.threeDSecureFlow}
          />
        }
        <MDBRow>
          <MDBCol size="12">
            <MDBCard>
              <MDBCardBody className="wallet-section">
                <MDBRow>
                  <MDBCol size="12">
                    <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                      <ol className="ups-wrap_inner">
                        <li className="ups-breadcrumb-item">
                          <Link to="/home" className="ups-link">
                            <FormattedMessage id="ups.title.dashboard" />
                          </Link>
                          <span aria-hidden="true"> &gt; </span>
                        </li>
                        <li className="ups-breadcrumb-item">
                          <FormattedMessage id="ups.title.wallet" />
                        </li>
                      </ol>
                    </nav>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol size="12">
                    <h1 className="has-breadcrumb">
                      <FormattedMessage id="ups.title.wallet" />
                    </h1>
                  </MDBCol>
                </MDBRow>
                { walletState?.error?.length > 0 && <Error errors={[walletState.error]} /> }
                <MDBRow>
                  <MDBCol size="12">
                      {!walletState.fetching &&
                          <PaymentMethodTable
                          caption={"ups.title.wallet"}
                              wallet={walletState.wallet}
                              toggleModal={toggleModal}
                              displayMode={walletState.displayMode}
                              toggleDisplayMode={displayMode => dispatch({type: 'setDisplayMode', displayMode})}
                              onEdit={(pm) => toggleModal("editPaymentMethod", pm)}
                              onRemove={(pm) => toggleModal("removePaymentMethod", pm)}
                              onDefault={setDefaultWallet}
                              tableState={walletState.tableState}
                              tableAction={(tableAction)=>dispatch({type:'tableAction', tableAction})}
                              clearFilter={()=>dispatch({type: 'applyFilter',filter:{}})}
                          />
                      }
                  </MDBCol>
                </MDBRow>
                  <FilterModal
                      filterName={'walletTableFilter'}
                      isOpen={walletState.filterModal}
                      toggleModal={()=>toggleModal('filterModal')}
                      renderFilterCriteria={(props)=>{
                          return <WalletFilterCriteria
                              {...props}
                              walletList={walletState.wallet}
                          />
                      }}
                      filter={walletState.tableState.filter}
                      setFilter={(filter) => dispatch({type: 'applyFilter', filter})}
                  />

                <AddPaymentMethodModal
                  paymentMethod={walletState.targetPM}
                  isOpen={walletState.pmModal}
                  toggleModal={toggleModal}
                  walletSuccess={addWalletHandler}
                />

                <RemovePaymentMethodModal
                  paymentMethod={walletState.targetPM}
                  isOpen={walletState.removeModal}
                  toggleModal={toggleModal}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
}