import React, {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import DataTableV3 from "./DataTableV3";
import {sortData, isColumnExportable} from "./DataTableV3-utils";
import classNames from 'classnames';
import Spinner from "../Spinner";

//renders table data for a print view. Run the print function with the assigned print action prop.

export default function DataTableV3ToPrint ({className, trClassName, data,columns,rowRender,assignPrintAction,tableState}) {
    const [printing, setPrinting] = useState(false)
    const [bufferPrinting, setBufferPrinting] = useState(0)
    const ref = useRef()
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        onAfterPrint: () => setPrinting(false),
        bodyClass: 'printDisplay',
        pageStyle: ''
    })
    assignPrintAction(() => Promise.resolve()
        .then(() => setPrinting(true) )
        .then(() => setBufferPrinting(bufferPrinting+1)) //some browsers need this arbitrary rerender for print to work
        .then(() => handlePrint())
    )

    return <>
        {printing && <Spinner isSpinning={true}/>}
        <div ref={ref} className={'printView'}>
            <h2>{document.title}</h2>
            {printing &&
                <DataTableV3
                    className={classNames('table-sm',className)}
                    trClassName={trClassName}
                    data={tableState ?  sortData(data, tableState) : data}
                    columns={columns.filter((column)=>isColumnExportable(column))}
                    itemsPerPage={[data?.length||10]}
                    sortable={false}
                    hidePaging
                    rowRender={rowRender}
                />
            }
        </div>
    </>
}