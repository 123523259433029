import React, { Component } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import memoize from "memoize-one";
import {getStatusCodeLabel} from '../../../utils/dispute-utils';
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import {SORTED_DSC} from "../../DataTableV3/DataTableV3-utils";

class DisputePackageListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableState: INITIAL_TABLE_STATE
        };
    }

    createDatatableCols = memoize(()=>{
        const {intl} = this.props;
        return [
            {
                field: 'trackingNbrD',
                label: intl.formatMessage({id: 'invoice.dispute.view-disputes.tracking-number.label'})
            },
            {
                field: 'status',
                label: intl.formatMessage({id: 'invoice.dispute.view-disputes.status.label'}),
                display: ({reqStatusD})=>getStatusCodeLabel(intl)[reqStatusD],
                serialize: ({reqStatusD})=>getStatusCodeLabel(intl)[reqStatusD],
                sortFunc: ({reqStatusD})=>getStatusCodeLabel(intl)[reqStatusD],
                thClassName: 'text-right', tdClassName: 'text-right'
            },
        ]
    })

    render() {
        const { tableState } = this.state;
        const { intl, packageList, caption } = this.props;
        const columns = this.createDatatableCols(intl.locale)

        return (
            <>
                <MDBRow>
                    <MDBCol size="12" className={"mt-3"}>
                        <div  className={"datatable-blend-container dispute-details"}>
                            <div className={"datatable-blend-inner"}>
                                <h6>{intl.formatMessage({id: 'invoice.dispute.view-disputes.package-list.label'})}</h6>
                            </div>
                        </div>
                        <FilteredDataTableV3
                        caption={caption}
                            data={packageList}
                            columns={columns}
                            searchable
                            searchRight
                            mobileCard
                            tableState={tableState}
                            tableStateAction={(action)=>this.setState(action)}
                            defaultSorting={'trackingNbrD'}
                            defaultSortDirection={SORTED_DSC}
                            rowHeaderField={'trackingNbrD'}
                        />
                    </MDBCol>
                </MDBRow>
            </>
        )

    }
}

export default DisputePackageListTable;
