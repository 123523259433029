import React,  { Component } from 'react'
import {bindActionCreators} from "redux";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import {connect} from "react-redux";
import ReactPortalModal from "../../../ReactPortalModal";
import ManageUserAccessTable from '../../ManageUserAccessTable';
import produce from "immer";
import {isEmpty, set as lodashSet} from 'lodash';
import UserDetails from '../../UserDetails';
import PlayBackTable from '../../PlayBackTable';
import * as identityActions from "../../../../actions/identity-action";
import * as validationActions from "../../../../actions/validation-action";
import attachValidator from "../../../../utils/validation/attach-validator";
import {getAccountAssignmentDelta} from "../../../../utils/ups-utils";
import {updateAdminIdentity, updateIdentity} from "../../../../api/identity-api";
import Spinner from "../../../Spinner";
import Error from "../../../Error";
import Alert from "../../../Alert";

class ManageUserAccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {modalStep: 1, loading: false, errors:[]};
        attachValidator.call(this)
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            })
        );
    };

    handleUpdate = () => {
        let result = this.validator.validateAll()
        if(result.messages.length > 0) return;
        let {accounts, data, editUserCallback, permissions} = this.props
        let {id, externalId} = data
        let targetAccounts = Object.values(this.getSelectedAccounts())
        let {addAccounts, removeAccounts} = getAccountAssignmentDelta(accounts, data.accounts, targetAccounts)
        //in the case the user doesn't change assignments, we make no api call
        if(isEmpty(addAccounts) && isEmpty(removeAccounts)){
            this.playbackResult(data.accounts)
        }
        else {
            this.setState({loading:true})
            const updateUserCall = permissions['user_admin_modify'] ? updateAdminIdentity : updateIdentity
            updateUserCall({id, externalId, addAccounts, removeAccounts})
            .then((resultUser)=> {
                this.playbackResult(resultUser.accounts)
                editUserCallback(resultUser)
            })
            .catch(({errorCode})=>{
                if(errorCode) this.setState({errors: [errorCode]})
            })
            .finally(()=>{
                this.setState({loading:false})
            })
        }
        let scrollTarget = document.getElementById('manage-user-access-modal');
        scrollTarget.scrollTop = 0;
    };

    playbackResult(accountList){
        this.setState(
            produce(draft => {
                draft.modalStep++
                draft.playBackList = accountList
            })
        )
    }


    render() {
        const { isOpen, toggleModal, backdrop, data, intl} = this.props;
        const { modalStep, loading, errors } = this.state;
        const modalHeading = "ups.assign-accounts.title";
        const modalLabel = "manage-user-access";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                    <MDBModal id="manage-user-access-modal" isOpen={isOpen} toggle={() => toggleModal('manageUserAccess')} size="xl" backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <Spinner isSpinning={loading} spinnerText={'loading.spinner.long'}/>
                        <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"}, { name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('manageUserAccess')}><FormattedMessage id={modalHeading}/></MDBModalHeader>
                        <MDBModalBody aria-live="assertive">
                            {errors?.length > 0 && <MDBRow><Error errors={errors}/></MDBRow>}
                            {modalStep === 1 && (
                                <React.Fragment>
                                    <UserDetails key={"manage-user-details"} user={data} editMode={false} textMsgId={"ups.assign-accounts.instructions-1"}/>
                                        <MDBRow>
                                            <MDBCol size={"12 mb-3"}>
                                                <h3><FormattedMessage id={"ups.assign-accounts.accounts-assigned.title"} /></h3>
                                                <ManageUserAccessTable
                                                caption= "manage-user-details"
                                                    accounts={data.accounts}
                                                    key="current-user-account-access-list"
                                                    source={"curernt-user-account-access-list"}
                                                    selectable={false}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol size={"12"}>
                                                <p className="ups-note-1 mt-0">*<span className="font-italic"><FormattedMessage id="ups.required-field.note" /></span></p>
                                            </MDBCol>
                                            <MDBCol size={"12"}>
                                                <div className={"datatable-blend-container"}>
                                                    <div className={"datatable-blend-inner"}>
                                                        <h3><FormattedMessage id={"ups.add-new-user.plans-and-accounts.title"} /></h3>
                                                        <p><FormattedMessage id={"ups.add-new-user.plans-and-accounts.instructions-1"}/></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <ManageUserAccessTable
                                                caption= "ups.add-new-user.plans-and-accounts.title"
                                                    name="manage-user-access-account-list"
                                                    accounts={this.props.accounts}
                                                    key="manage-user-access-account-list"
                                                    source={"manage-user-access-account-list"}
                                                    selectable={true}
                                                    registerGetSelection={(func)=>{
                                                        this.getSelectedAccounts = func
                                                    }}
                                                    preSelected={data.accounts}
                                                    validations={[
                                                        (selectedList)=>{
                                                            if(Object.keys(selectedList).length === 0) return "account.assignment.required"
                                                            else return ""
                                                        }
                                                    ]}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                </React.Fragment>
                            )}
                            {modalStep === 2 && (
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3 className={"mb-4"}><FormattedMessage id={"ups-assign-accounts.accounts-access-updated.title"}/></h3>
                                            <PlayBackTable key={"playback-account-access-updated"} user={data} />
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            {/* if you you're able to determine if a plan is added */}
                                            {/* this might need to be moved into the datatable component if that's the case */}
                                            <h3><FormattedMessage id="ups.add-new-user.confirmation.plans-and-accounts.line1" /></h3>
                                            {/*<h3><FormattedMessage id="ups.add-new-user.confirmation.accounts-only.line1" /></h3>*/}
                                            { this.state.playBackList && this.state.playBackList.length > 0 && (
                                                <ManageUserAccessTable
                                                caption= "ups-assign-accounts.accounts-access-updated.title"
                                                    accounts={this.state.playBackList}
                                                    key="manage-user-access-confirmation-list"
                                                    source={"manage-user-access--confirmation-list"}
                                                    selectable={false}
                                                />
                                            )}
                                        </MDBCol>
                                    </MDBRow>
                                </React.Fragment>
                            )}
                        </MDBModalBody>
                        <MDBModalFooter aria-live="polite">
                            {(modalStep === 1 && data.accounts) && <MDBRow>
                                <MDBCol size={"12"}>
                                    <Alert className={"bank-direct-debit-only-warning mt-3 mb-4"} type={'warning'} label={'unassign-accounts-cleanup-warning'}/>
                                </MDBCol>
                            </MDBRow>}
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    {(modalStep !== 2) && <MDBBtn color="primary"  onClick={this.handleUpdate}><FormattedMessage id="btn-update" /></MDBBtn>}
                                    {(modalStep === 2) && <MDBBtn color="primary" onClick={() => toggleModal('manageUserAccess')}><FormattedMessage id="btn-close" /></MDBBtn>}
                                </MDBCol>
                            </MDBRow>
                            {modalStep !== 2 && (
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <MDBBtn color="cancel" onClick={() => toggleModal('manageUserAccess')}><FormattedMessage id="btn-cancel" /></MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        permissions: state.auth.user.permissions
    }
}


function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManageUserAccessModal));
