import * as actionTypes from '../constants/action-types';

export const getDisputeSummary = (callback) => {
    return {
        type: actionTypes.GET_DISPUTE_SUMMARY,
        callback,
    };
};

export const getDisputeSummarySuccess = (callback) => {
    return {
        type: actionTypes.GET_DISPUTE_SUMMARY_SUCCESS,
        callback,
    };
};

export const getDisputeSummaryFailure = (callback) => {
    return {
        type: actionTypes.GET_DISPUTE_SUMMARY_FAILURE,
        callback,
    };
};

export const getDisputeReasons = ({invoiceId, invoiceDetailId, useTRK, businessUnit, accountNumber, planNumber, recordType}, callback) => {
    return {
        type: actionTypes.GET_DISPUTE_REASONS,
        invoiceId,
        invoiceDetailId,
        useTRK,
        businessUnit,
        accountNumber,
        planNumber,
        recordType,
        callback
    };
};

export const getDisputeReasonsSuccess = (data, callback) => {
    return {
        type: actionTypes.GET_DISPUTE_REASONS_SUCCESS,
        data: data
    };
};

export const getDisputeReasonsFailure = (data, callback) => {
    return {
        type: actionTypes.GET_DISPUTE_REASONS_FAILURE,
        data: data
    };
};

export const submitDispute = (identifier, businessUnit, netAmount, disputeData, callback) => {
    return {
        type: actionTypes.SUBMIT_DISPUTE,
        identifier,
        businessUnit,
        netAmount,
        disputeData,
        callback
    };
};

export const submitDisputeSuccess = (data, callback) => {
    return {
        type: actionTypes.SUBMIT_DISPUTE_SUCCESS,
        data: data
    };
};

export const submitDisputeFailure = (data, callback) => {
    return {
        type: actionTypes.SUBMIT_DISPUTE_FAILURE,
        data: data
    }
};

export const disputeFreeForm = (businessUnit, disputeData, callback) => {
    return {
        type: actionTypes.DISPUTE_FREE_FORM,
        businessUnit,
        disputeData,
        callback
    };
};

export const disputeFreeFormSuccess = (data, callback) => {
    return {
        type: actionTypes.DISPUTE_FREE_FORM_SUCCESS,
        data: data
    };
};

export const disputeFreeFormFailure = (data, callback) => {
    return {
        type: actionTypes.DISPUTE_FREE_FORM_FAILURE,
        data: data
    }
};

export const getDisputesByIdentifier = (invoiceId, invoiceDetailRef, businessUnit, callback) => {
    return {
        type: actionTypes.GET_DISPUTES_BY_IDENTIFIER,
        invoiceId,
        invoiceDetailRef,
        businessUnit,
        callback
    };
};

export const getDisputesByIdentifierSuccess = (data) => {
    return {
        type: actionTypes.GET_DISPUTES_BY_IDENTIFIER_SUCCESS,
        data
    };
};

export const getDisputesByIdentifierFailure = (data) => {
    return {
        type: actionTypes.GET_DISPUTES_BY_IDENTIFIER_FAILURE,
        data,
    };
};

export const getDisputes = (queryParams, callback) => {
    return {
        type: actionTypes.GET_DISPUTES,
        queryParams,
        callback
    };
};

export const getDisputesSuccess = (data, callback) => {
    return {
        type: actionTypes.GET_DISPUTES_SUCCESS,
        data: data
    };
};

export const getDisputesFailure = (data, callback) => {
    return {
        type: actionTypes.GET_DISPUTES_FAILURE,
        data: data
    };
};