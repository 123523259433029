import {Component}from 'react'
import ReactDOM from "react-dom";
import {logTealiumAnalytics, TEALIUM_EVENT} from '../../utils/tealium-utils';
import PropTypes from 'prop-types';

const modalRoot = document.getElementById('modal-root');
const _ = require('lodash');

/** Read more about react portals: https://reactjs.org/docs/portals.html
 *  When modals are wrapped with ReactPortalModal, they'll be attached to the modal-root in the public/index.html page
 *  Therefore avoiding any css issues that arise from where the component is inserted in our pages as they may vary
*/

class ReactPortalModal extends Component {
    constructor(props) {
        super(props);
        // Create a div that we'll render the modal into. Because each
        // Modal component has its own element, we can render multiple
        // modal components into the modal container.
        this.el = document.createElement('div');
        this.el.className = 'modal-container';
        if(window.modalsOpen === undefined) window.modalsOpen = 0
        this.captureTealiumAnalyticsData(props);
    }

    componentDidMount() {
        // Append the element into the DOM on mount. We'll render
        // into the modal container element (see the HTML tab).
        modalRoot.appendChild(this.el);
        const { isOpen, children: { props } } = this.props;
        if(isOpen !== undefined){
            if(!isOpen) {
                if(window.modalsOpen === 0) document.body.classList.remove("modal-open") //mdb bugfix
            } else {
                window.modalsOpen++
            }
            if (isOpen === true) {
                this.setModalAttributes(props);
            }
        }
    }

    setModalAttributes(props) {
        const elm = document.getElementsByClassName('modal') && document.getElementsByClassName('modal')[0];
                if (elm && elm.nodeType && elm.nodeType === 1) {
                    elm.setAttribute("aria-modal", "true"); 
                    elm.removeAttribute("aria-hidden");                
                    if(props && props.labelledBy) elm.setAttribute("aria-label", props.labelledBy);
        }
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        //if we're unmounting without having first closed the modal we may have to remove the modal-open class
        if(this.props.isOpen){
            if(window.modalsOpen > 0) window.modalsOpen--
            if(window.modalsOpen === 0) document.body.classList.remove("modal-open") //mdb bugfix
        }
    }

    componentDidUpdate(prevProps) {
        const { isOpen, children: { props } } = this.props;
        if(isOpen !== undefined) {
            if (!prevProps.isOpen && isOpen) {
                window.modalsOpen++
                document.body.classList.add("modal-open") //mdb bugfix
                
                this.captureTealiumAnalyticsData(this.props);
            }
            else if (prevProps.isOpen && !isOpen) {
                if(window.modalsOpen > 0) window.modalsOpen--
                if(window.modalsOpen === 0) document.body.classList.remove("modal-open") //mdb bugfix
            }

            if(prevProps.isOpen && isOpen && prevProps.an_label !== this.props.an_label){
                this.captureTealiumAnalyticsData(this.props);
                /*this will make sure that focus stays within the modal when content inside modal is updated specially in cases where we have ProgressBar
                  otherwise focus moves outside modal window
                */
                this.el?.lastElementChild?.focus();
            }
        }
    }

    captureTealiumAnalyticsData(props) {
        if (props.isOpen !== false) {            
            setTimeout(() => {            
                let modalName = "";
                
                if(props.an_label){
                    modalName = props.an_label;
                }
                else if (props.children?._owner?.elementType?.name) {
                    modalName =  _.kebabCase(props.children._owner.elementType.name.replace("Modal", ''));
                }

                const modalHeaders = document.querySelectorAll("h4.modal-title");
                const header = modalHeaders && modalHeaders.length > 0 ? modalHeaders[modalHeaders.length - 1] : null;
                
                logTealiumAnalytics(TEALIUM_EVENT.VIEW, {
                    "page_id": `${window.location.pathname}:${modalName}`,
                    ...( header?.innerText ? { 
                        "page_name" : header?.innerText,
                        "page_description" : header?.innerText
                    } : {})
                });
            }, 300);
        }
    }

    render() {
        // Use a portal to render the children into the element
        return ReactDOM.createPortal(
            // Any valid React child: JSX, strings, arrays, etc.
            this.props.children,
            // A DOM element
            this.el,
        );
    }
}

ReactPortalModal.propTypes = {
    an_label: PropTypes.string.isRequired,
    isOpen: PropTypes.any.isRequired // this prop is necessary to track open modals to apply the modal open class to the DOM body.
                                     // If you want a scrollbar on your modal when it gets too big, you have to use this prop.
};

export default ReactPortalModal;
