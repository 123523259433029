import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Exception extends Component {
    render() {
        const { error } = this.props;

        return (
            <React.Fragment>
                <h1><FormattedMessage id="exception.label" /></h1>
                {
                    error instanceof TypeError ? 
                    (<h2>{error.message}</h2>) : (<h2><FormattedMessage id="exception.unknown" /></h2>)
                }
            </React.Fragment>
        )
    }
}

export default Exception;