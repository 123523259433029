import React,  { Component } from 'react'
import * as invoiceActions from "../../../actions/invoice-action";
import * as identityActions from "../../../actions/identity-action";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBSpinner, MDBBox } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../ReactPortalModal";
import FilterBuilder from '../FilterBuilder';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createLookupTable} from "../../../utils/utils";

class FilterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            aggregateDataIsEmpty: null,
            isLoading: true,
            filters: {},
            allSelections: null
        }
    }

    onAggregateDataLoaded = (isEmpty) => this.setState({ 
        aggregateDataIsEmpty: isEmpty, 
        isLoading: false 
    });

    onUpdateFilters = (key, value) => {
        const { filters } = this.state;

        this.setState({
            filters: {
                ...filters,
                [key]: value
            }
        });
    };

    checkIfAllSelected = (selection, allSelection) => {
        let allSelected = false;
        if(allSelection){
            const lookup = createLookupTable(selection)
            allSelected = allSelection.reduce((acc, option)=>{
                acc &= lookup[option]
                return acc
            }, true)
        }
        return allSelected;
    }

    onSubmitFilters = e => {
        const {filters, allSelections} = this.state;
        const {runFilter, toggleModal} = this.props;
        const parsedFilters = {...filters}
        if(allSelections) {
            const filterKeys = Object.keys(allSelections)
            filterKeys.forEach((key)=>{
                if(filters?.[key]?.length === 0){
                    parsedFilters[key] = undefined
                } else {
                    const isAllSelected = this.checkIfAllSelected(filters[key],allSelections[key])
                    if(isAllSelected) parsedFilters[key] = undefined
                }
            })
        }
        runFilter(parsedFilters);
        toggleModal();
    };

    onClearFilters = e => {
        const {clearFilters, toggleModal} = this.props;
        clearFilters();
        toggleModal();
    };

    areModalButtonsVisible = () => {
        const { isLoading, aggregateDataIsEmpty } = this.state;
        return !isLoading && !aggregateDataIsEmpty;
    }

    render() {
        const {onSubmitFilters, onClearFilters} = this;
        const {aggregateDataIsEmpty, isLoading} = this.state;
        const { isOpen, toggleModal, backdrop, filters, initialFilters, screen,
                invoiceActions, invoice, detailType, invoiceLiterals, conditionals, intl } = this.props;

        if (!isOpen) return null;
        const modalHeading = intl.formatMessage({id:"invoice.details.search-title"});

        return (
            <ReactPortalModal isOpen={isOpen} an_label={`${screen}-filter`}>
                <MDBModal id={`${screen}-filter-modal`} isOpen={isOpen} toggle={() => toggleModal()} size="lg" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={modalHeading}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({ id: "close.dialog.btn" }, { name: modalHeading })} toggle={() => toggleModal()} >{modalHeading}</MDBModalHeader>
                    <MDBModalBody>
                        {isLoading && <MDBBox display={'flex'} justifyContent={'center'}><MDBSpinner /></MDBBox>}
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <FilterBuilder
                                    modalMode={true}
                                    key={`${screen}-search-criteria`}
                                    onUpdateFilters={this.onUpdateFilters}
                                    aggregateDataIsEmpty={aggregateDataIsEmpty}
                                    onAggregateDataLoaded={this.onAggregateDataLoaded}
                                    filters={filters}
                                    initialFilters={initialFilters}
                                    invoiceActions={invoiceActions}
                                    invoice={invoice}
                                    detailType={detailType}
                                    invoiceLiterals={invoiceLiterals}
                                    conditionals={conditionals}
                                    setAllSelections={(allSelections)=>()=>{
                                        if(allSelections) this.setState({allSelections})
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    {this.areModalButtonsVisible() &&
                        <MDBModalFooter>
                            <MDBBtn id={`${screen}-search-btn-search`} color="primary" onClick={onSubmitFilters}><FormattedMessage id={"search.label"}/></MDBBtn>
                            <MDBBtn id={`${screen}-search-btn-cancel`} color="cancel" onClick={onClearFilters}><FormattedMessage id={"clear.label"}/></MDBBtn>
                        </MDBModalFooter>
                    }
                </MDBModal>
            </ReactPortalModal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        layout: state.layout,
        planInvoiceFilters: state.invoice.planInvoiceFilters,
        preferences: state.identity.preferences,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterModal)));
