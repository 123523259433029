import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {onDownArrow, onEnterPress, onSpaceBar, onUpArrow} from "../../utils/utils";

//this is a re-implementation of MdbSideNavCat, since the one in mdb version 4 just doesn't respond to state changes it seems

export const MDBSideNavCat = ({children, className, label, id, isOpen, toggleOpen, "aria-label": ariaLabel, ...rest}) => {
    const [transition, setTransition] = useState(null)
    const [visible, setVisible] = useState(null)
    const timer = useRef();
    const heightRef = useRef({});
    const storeHeight = useRef()
    const fullToggleOpen = getToggleOpen(toggleOpen, setTransition, setVisible, isOpen, timer)
    const expanded = (transition===true||(transition===null && isOpen))
    useEffect(()=>{
        if(heightRef.current.offsetHeight > 0) storeHeight.current = heightRef.current.offsetHeight
    })
    return <li>
        <a  role='button' className={classnames("collapsible-header Ripple-parent arrow-r", {"active":expanded}, className)}
           tabIndex="0"
           aria-expanded={expanded}
           onClick={fullToggleOpen}
           onKeyPress={(e)=>{
               onSpaceBar(fullToggleOpen)(e)
               onEnterPress(fullToggleOpen)(e)
           }}
           onKeyDown={(e)=>{
               onUpArrow((e)=>fullToggleOpen(e, false))(e)
               onDownArrow((e)=>fullToggleOpen(e, true))(e)
           }}
           aria-label={label ?? ariaLabel}
           {...rest}
        >
            {label}
            <i data-test={"fa"} className={"fa fa-angle-down rotate-icon"}/>
        </a>
        <div data-test="collapse" id={id}
             className={classnames("collapsible-children", {"collapse": (transition===null)}, {"show":visible||isOpen})}
             style={{"maxHeight":getHeight(transition, isOpen, heightRef, storeHeight)}}
        >
            <div className={classnames("collapsible-body","d-block")} ref={heightRef}>
                <ul>
                    {children}
                </ul>
            </div>
        </div>
    </li>
}

const getToggleOpen = (toggleOpen, setTransition, setVisible, currentState, timer) => (e, nextState) => {
    clearTimeout(timer.current)
    const resultState = nextState ?? !currentState
    setVisible(resultState)
    //don't ask me why this first settimeout is needed, but it is
    setTimeout(()=>setTransition(resultState),1)
    timer.current = setTimeout(()=>{
        toggleOpen(e, nextState)
        setTransition(null)
    },350)
}

const getHeight = (transition, isOpen, heightRef, storeHeight)=>{
    if(!heightRef?.current) return undefined
    if (transition === true){
        return storeHeight.current ? `${storeHeight.current}px` : undefined
    } else if (transition === false || isOpen === false){
        return '0px'
    } else if (isOpen){
        return storeHeight.current ? `${storeHeight.current}px` : undefined
    } return undefined
}