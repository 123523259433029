import { all, call, fork, select, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../constants/action-types';
import { updateChatbotTracking } from "../utils/chatbot-utils";
import { formatBool } from "../utils/utils";

const getEnvProps = (state) => state.envProps;
const getPaymentType = ({ auth }) => auth.user.selectedPaymentType;
const getCountryCode = ({ auth }) => auth.user.selectedCountry;

function* updateTracking(action) {
    try {
        const envProps = yield select(getEnvProps);
      
        const { data } = action;
        const { enableChatbot } = envProps;

        const chatbotOptions = {
            pathname: data?.pathname ? data.pathname : window.location.pathname, 
            enableChatbot: formatBool(enableChatbot),
            selectedPaymentType: yield select(getPaymentType),
            countryCode: yield select(getCountryCode),
            closeBeforeReinit : formatBool(data?.isLocaleChanged)
        };

        yield call(updateChatbotTracking, chatbotOptions);
    } catch (error) {
        console.log('error: chatbot loading error', error);
    }
}

export function* setChatbotSaga() {
    yield takeEvery(actionTypes.UPDATE_CHATBOT_TRACKING, updateTracking);
}

export default function* chatbotSaga() {
  yield all([
    fork(setChatbotSaga)
  ]);
}