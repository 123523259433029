import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { MDBTable, MDBTableHead, MDBTableBody, MDBTableFoot, MDBBtn } from "mdbreact";
import { isEmpty as lodashIsEmpty, get as lodashGet, isEmpty, orderBy as lodashOrderBy } from 'lodash';
import Exception from "../../Exception";
import {injectIntl, FormattedMessage} from 'react-intl';
import DisputesModal from '../../InvoiceDetails/DisputesModal';
import DownloadAllDocumentModal from '../../UPS/Modal/DownloadAllDocumentModal';
import {BUSINESS_UNIT} from '../../../constants/business-unit'

import * as invoiceActions from '../../../actions/invoice-action';
import {evaluateConditionals, getFormattedValue, getLabel} from '../../../utils/invoice-utils';
import {getMessageByBU} from "../../../utils/ups-utils";

class CardsScreen extends React.Component {

    constructor(props) {
        super();

        const { intl, invoiceLiterals, merchant } = props;
        const currencyDisplay = merchant.currencyDisplay;

        this.formatValue = getFormattedValue.bind({ intl, invoiceLiterals, currencyDisplay });
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });

        this.state = {
            disputesModal: false,
            downloadAllDocumentModal: false,
            loaded: false,
            error: '',
            data: null,
            components: []
        };
    }

    toggleModal = (modalName, data) => {
        this.setState({
            data,
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    componentDidMount() {
        if(this.props.isTopLevel) this.setPageTitle()
        this.requestInvoiceDetails(this.onInit);
    }

    setPageTitle = () => {
        const {invoice, businessUnitMessages} = this.props
        document.title = `${businessUnitMessages('pageTitle')} - ${invoice.invoiceNumber}`
    }

    onInit = async (invoiceDetails) => {
        const { screen, invoiceMetadata, invoiceDetailsFilters } = this.props;
        const screenConfig = invoiceMetadata.screens[screen];

        if (!screenConfig) return null;

        const { cards } = screenConfig;

        const components = cards.map(cardSection => this.createCardRow(cardSection, invoiceDetails));

        this.setState({
            loaded: true,
            components,
            invoiceDetail: invoiceDetails
        })
    };

    createCardRow(cards, invoiceDetail) {
        const cardRow = cards.map(card => this.createCard(card, invoiceDetail, cards.length));

        return (<div className="cards stretch-card">{cardRow}</div>);
    }

    createInvoiceField = (label, value) => (
        <div className="col-md-12 col-lg-6 p-0">
            <div className="row m-0">
                <div className="col-6">
                    <strong>{label}</strong>
                </div>
                <div className="col-6 text" dangerouslySetInnerHTML={{__html: value}}/>
            </div>
        </div>
    )

    createCard = ({msgId, type = 'card', fields, ref, button, sortBy, orderBy}, invoiceDetail, totalCards) => {
        const cardClass = `card ${totalCards === 1 ? 'card-12' : 'card-6'} p-4 mb-4`;
        return (
                <div className={cardClass}>
                <h2 className="card-title">{this.formatLabel(null, { msgId })}</h2>
                {this.createButton(invoiceDetail, button)}
                <div className="card-body card-body-08rem">
                    <div className="row">
                        {(!type || type === 'card')
                            ? (this.mapDisplayFields(invoiceDetail, fields) || [])
                                .map(({ label, value }) => this.createInvoiceField(label, value))
                            : ''
                        }
                        {type === 'simple-table'
                            ? this.createSimpleTable(invoiceDetail, fields, ref, sortBy, orderBy)
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    };

    createButton = (invoiceDetail, button) => {
        const {user, invoiceMetadata, screenConfig} =this.props;
        const {businessUnit} = screenConfig
        const isViewOnlyUser = user.role === 'View Only' || user.role === 'VIEW_ONLY';

        if(businessUnit===BUSINESS_UNIT.FRT && invoiceMetadata?.disableFRTDisputes) return null;

        if(button && isViewOnlyUser && button.modal==='disputes') return null

        if (!button || !button.id || (button.conditionals && !evaluateConditionals(button.conditionals, invoiceDetail.fields))) return null;
        const id = button.id;
        return (
            <MDBBtn color={"secondary"} className={"btn btn-secondary ml-0 btn-mini position-top-right"} id={id} onClick={() => this.toggleModal(button.modal)}>
                {this.formatLabel(null, button)}
            </MDBBtn>
        )
    };

    createSimpleTable = (invoiceDetail, fieldDefinitions, ref, sortBy, orderBy) => {
        const totals = {};
        const { currencyCode } = invoiceDetail?.invoiceHeader;
        const tableHeaders = fieldDefinitions
        .filter(({hidden})=>{
            return !hidden
        }).map(fieldDefinition => (
            <th className={fieldDefinition.class}>
                {this.props.intl.formatMessage({ id: fieldDefinition.msgId })}
            </th>
        ))

        const rawRows = lodashGet(invoiceDetail, ref, [])
        const rowsWithCurrencyCode = rawRows.map(row=>({currencyCode,...row}))
        const orderedInvoices = sortBy
                                ? lodashOrderBy(rowsWithCurrencyCode, [sortBy], [orderBy])
                                : rowsWithCurrencyCode;
        const tableRows = orderedInvoices.map(row => {
           return (
               <tr>
                    {fieldDefinitions
                    .filter(({hidden})=>{
                        return !hidden
                    }).map(fieldDefinition => {
                        if (fieldDefinition.total) {
                            const curr = lodashGet(row, fieldDefinition.field);
                            if (typeof curr === "number") {
                                totals[fieldDefinition.field] = lodashGet(totals, fieldDefinition.field, 0) + lodashGet(row, fieldDefinition.field);
                                if (!lodashGet(totals, 'currencyCode')) {
                                    totals['currencyCode'] = lodashGet(row, 'currencyCode');
                                }
                            }
                        }
                        return (
                            <td className={fieldDefinition.class} data-th={this.props.intl.formatMessage({ id: fieldDefinition.msgId })}>
                                <table class='pmtus-vertical-table'><tr className='pmtus-vertical-table-tr'><td className='pmtus-vertical-table-header'>{this.props.intl.formatMessage({ id: fieldDefinition.msgId })}</td><td className='pmtus-vertical-table-content'>{this.formatValue(row, fieldDefinition)}</td></tr></table>
                            </td>
                        )
                    })}
                </tr>
           )
        });

        let tableFooter = null;
        if (Object.entries(totals).length > 0) {
            tableFooter = fieldDefinitions.filter(({hidden})=>{
                return !hidden
            }).map(fieldDefinition => {
                return (
                    <td className={`${fieldDefinition.class?fieldDefinition.class:'td-row-hidden'}`} data-th={this.props.intl.formatMessage({ id: fieldDefinition.msgId })}>
                        <table class='pmtus-vertical-table'><tr className='pmtus-vertical-table-tr'><td className='pmtus-vertical-table-header'>{this.props.intl.formatMessage({ id: fieldDefinition.msgId })}</td><td className='pmtus-vertical-table-content font-weight-bold'>{this.formatValue(totals, fieldDefinition)}</td></tr></table>
                    </td>
                )
            })
        }


        return (
                <MDBTable responsive={true} className={"table-bordered-simple table-sm"} id={"card-table"} width={'100%'}>
                    <MDBTableHead color="light">
                        <tr>
                            {tableHeaders}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {tableRows}
                    </MDBTableBody>
                    <tfoot>
                        <p className={'mb-0 table-responsive-footer-total'}>
                            <strong><FormattedMessage id={'invoice.details.total.label'} /></strong>
                        </p>
                        {tableFooter}
                    </tfoot>
                </MDBTable>
        )
    };

    requestInvoiceDetails(callback) {
        const { screen, invoiceDetailsFilters, invoiceActions, invoice, invoiceMetadata } = this.props;
        const extFields = {
            bol:invoice.bol,
            po:invoice.po,
            invoiceDate:invoice.invoiceDate,
            freightTerms:invoice.freightTerms,
        }
        const { detailType } = invoiceMetadata.screens[screen];

        const filters = lodashGet(invoiceDetailsFilters, detailType, {});

        let {dateFilters, ...searchCriteria } = filters;

        searchCriteria = {
            ...(!lodashIsEmpty(searchCriteria) ? { searchCriteria } : {}),
            ...(!lodashIsEmpty(dateFilters) ? this.constructDateFilter(dateFilters) : {}),
            ...(detailType ? { detailType } : {})
        };

        invoiceActions.getInvoiceDetails({invoice, searchCriteria}, (invoiceDetails) => {
            const _invoiceDetail = invoiceDetails?.[0]
            callback({ ..._invoiceDetail, fields: { ..._invoiceDetail.fields, ...extFields } });
        });
    }

    mapDisplayFields = (invoiceDetail, fieldDefinitions) => {
        const pairs = [];

        if (!fieldDefinitions) return [];

        for (const fieldDefinition of fieldDefinitions) {
            const { field: fieldName } = fieldDefinition;

            if (typeof fieldName === 'object') {
                pairs.push(
                    ...lodashGet(invoiceDetail, fieldName.ref, [])
                        .map(ref => {
                            const label = this.formatLabel(ref, fieldDefinition);
                            const value = this.formatValue(ref, fieldDefinition);

                            return { label, value };
                        })
                );
            } else {
                const label = this.formatLabel(invoiceDetail, fieldDefinition);
                const value = this.formatValue(invoiceDetail, fieldDefinition);

                pairs.push({ label, value });
            }
        }
        return pairs.filter(({value})=>!isEmpty(value));
    };

    render() {
        const { toggleModal } = this;
        const { loaded, error, disputesModal, downloadAllDocumentModal, components, invoiceDetail } = this.state;
        const { invoice } = this.props;
        const modalComponents = [];

        const businessUnit = this.props?.screenConfig?.businessUnit

        //AP-322 Don't show cards in case of missing invoice details NOTE: for now only applicable for Freights
        if(!invoiceDetail && businessUnit===BUSINESS_UNIT.FRT) return null
        if (!loaded) return null;

        if (error instanceof TypeError) {
            return (<Exception error={error} />)
        } else {

             if (disputesModal) {
                 modalComponents.push(
                     <DisputesModal
                        key={`disputes-modal`}
                        isOpen={disputesModal}
                        invoice={invoice}
                        toggleModal={toggleModal}
                        data={invoiceDetail}
                    />
                 )
             }

             if (downloadAllDocumentModal) {
                modalComponents.push(
                    <DownloadAllDocumentModal
                        key={'download-all-document-modal'}
                        isOpen={downloadAllDocumentModal}
                        invoice={invoice}
                        toggleModal={toggleModal}
                        showToggleDisplayMode={true}
                        isPlanInvoice={false}
                    />
                )
            }

            return (
                <React.Fragment>
                    {components}
                    {modalComponents}
                </React.Fragment>
            );
        }
    }
}

function mapStateToProps(state, {intl}) {
    return {
        businessUnitMessages: getMessageByBU('invoiceDetails')(intl)(state.auth.user.selectedPaymentType),
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        invoiceDetailsFilters: state.invoice.invoiceDetailsFilters,
        envProps: state.envProps,
        user: state.auth.user,
        merchant: state.config.sessionSelection.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CardsScreen));