import React from "react";
import PropTypes from "prop-types";
import DatatableV3 from "../../DataTableV3/DataTableV3";
import { useDispatch } from "react-redux";
import * as invoiceActions from "../../../actions/invoice-action";
import { useIntl } from "react-intl";
import {INVOICE_DOWNLOAD_CHANNEL_CODES} from "../../../constants/invoice-constants";
import {formatDate} from "../../../utils/ups-utils";
import * as R from'ramda';


const invoiceFields = R.pick(['accountNumber', 'planNumber', 'recordType']);

const InvoicesDownloadListTable = ({ dataRows, apiUrlPrefix, caption }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const datatableRows = dataRows?.map(({ downloadReadyDate, fileName, invoices, channelCode }) => {
    const invoiceData = invoiceFields(invoices?.[0]);
    return {
      dateSubmited:  formatDate(intl, downloadReadyDate),
      invoiceNumbers: channelCode === 'invoice-ready-email' ? '' : `invoice (${invoices.map(({invoiceNumber}) =>invoiceNumber).join(", ")})`,
      fileType: invoices?.[0]?.fileType,
      fileName: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() =>
            dispatch(invoiceActions.getInvoicePendingDownload(`${apiUrlPrefix}/invoice/download/pending/${fileName}`, invoiceData))
          }
        >
          {intl.formatMessage({id:"invoice.download-multiple.btn-download.label"})}
        </a>
      ),
      downloadType: <>{intl.formatMessage({id: (channelCode === INVOICE_DOWNLOAD_CHANNEL_CODES.INVOICE_READY_EMAIL) ? 'invoice.downloadInvoices.requestSourceInvoiceReady' : 'invoice.downloadInvoices.requestSourceOnDemand'})}</>
    };
  });

  const datatableCols = [
    {
      field: "dateSubmited",
      label: intl.formatMessage({id: "invoice.downloadInvoices.createdDate"}),
      tdClassName: "w-17p",
    },
    {
      field: "fileType",
      label: intl.formatMessage({id: "invoice.downloadInvoices.fileType"}),
      tdClassName: "w-17p",
    },
    {
      field: "invoiceNumbers",
      label: intl.formatMessage({id: "invoice.downloadInvoices.invoiceNbr"}),
    },
    {
      field: "downloadType",
      label: intl.formatMessage({id: "invoice.downloadInvoices.requestSource"}),
      tdClassName: "w-17p",
    },
    {
      field: "fileName",
      label: intl.formatMessage({id: "invoice.downloadInvoices.fileName"}),
      tdClassName: "invoiceTextLink",
    }
  ];

  return (
    <DatatableV3
    caption={caption}
      className="table table-bordered-simple table-sm table-hover table-invoicesDownload"
      data={datatableRows}
      columns={datatableCols}
    />
  );
};

InvoicesDownloadListTable.propTypes = {
  dataRows:PropTypes.array,
  apiUrlPrefix:PropTypes.string.isRequired,
};

InvoicesDownloadListTable.defaultProps = {
  dataRows: []
};

export default InvoicesDownloadListTable;