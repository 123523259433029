import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as dashboardActions from '../actions/dashboard-action';
import * as layoutActions from '../actions/layout-action';
import * as errorActions from '../actions/error-action';
import * as actionTypes from '../constants/action-types';
import * as dashboardApi from '../api/dashboard-api';
import { countryCodeToMerchantId } from '../utils/utils';

const getEnvProps = (state) => state.envProps;
const getLanguageCode = ({ auth }) => auth.user.locale;

export function* getLatestMessages(action) {
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);

        const { callback } = action;
        const { apiUrlPrefix, apiToken, simulateApi } = envProps;

        const searchFields = {
            languageCode: yield select(getLanguageCode),
        };

        const response = yield call(dashboardApi.getLatestMessages, searchFields, apiUrlPrefix, apiToken);

        const { ok, parsedBody } = response;
        if (ok && parsedBody) {
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(dashboardActions.getLatestMessagesSuccess(parsedBody));
        } else {
            yield put(dashboardActions.getLatestMessagesFailure(parsedBody));
        }

    } catch (err) {
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getLatestMessagesSaga() {
        yield takeEvery(actionTypes.GET_LATEST_MESSAGES, getLatestMessages);
}

export function* deleteUserMessage(action) {
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);

        const { messageId, callback } = action;
        const { apiUrlPrefix, apiToken, simulateApi } = envProps;
        const response = yield call(dashboardApi.deleteUserMessage, { messageId }, apiUrlPrefix, apiToken);
        const { ok, parsedBody } = response;
        if (ok && parsedBody) {
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(dashboardActions.deleteUserMessageSuccess(parsedBody));
        } else {
            yield put(dashboardActions.deleteUserMessageFailure(parsedBody));
        }

    } catch (err) {
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* deleteUserMessageSaga() {
    yield takeEvery(actionTypes.DELETE_USER_MESSAGE, deleteUserMessage);
}
    
export default function* dashboardSaga() {
    yield all([
        fork(getLatestMessagesSaga),
        fork(deleteUserMessageSaga)
    ]);
}
