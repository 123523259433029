import produce from "immer"
import * as actionTypes from '../constants/action-types';

// This reducer is not persisted in redux local storage (by design)
// it starts from scratch on a page refresh with default state

export default (state = {}, action) =>
    produce(state, draft => {
            switch (action.type) {
                case actionTypes.SET_VALIDATION:
                    draft.vState[action.data.fieldName] = action.data.result || {}
                    draft.valid = !(Object.values(draft.vState).filter((v)=>(!!v.messages && v.messages.length > 0)).length > 0)
                    draft.submitted = false
                    return;
                case actionTypes.CLEAR_VALIDATION:
                    draft.vState = {}
                    draft.valid = null
                    draft.vFields = {}
                    draft.submitted = false;
                    return;
                case actionTypes.REGISTER_FIELD:
                    let {getValidations, getValue, getContext} = action.data
                    draft.vFields[action.data.fieldName] = {getValidations, getValue, getContext};
                    draft.vState[action.data.fieldName] = {}
                    draft.submitted = false;
                    return;
                case actionTypes.DEREGISTER_FIELD:
                    delete draft.vFields[action.data.fieldName]
                    delete draft.vState[action.data.fieldName]
                    draft.submitted = false;
                    return;
                case actionTypes.SUBMIT_VALIDATION:
                    draft.submitted = true
                    return;
                default:
                    if(!draft.vState) draft.vState = {}
                    if(!draft.vFields) draft.vFields = {}
                    if(draft.valid === undefined) draft.valid = null
                    return;
            }
        }
    );