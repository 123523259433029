import React                                        from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader}     from 'mdbreact';
import ReactPortalModal                             from "../../ReactPortalModal";
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { FormattedMessage, useIntl }                 from 'react-intl';

export default function HelpSupportModal({ isOpen, closeModal, component }) {
    const intl = useIntl();
    
    return (
        <ReactPortalModal isOpen={isOpen} an_label="terms-privacy-policy">
            <MDBModal isOpen={isOpen} size="lg" disableBackdrop={true} disableFocusTrap={false} labelledBy=" ">
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name:" " })} toggle={closeModal}></MDBModalHeader>
                <MDBModalBody>
                    <section className={"ups-section ups-enroll-section"}>
                        <section className={"ups-enroll-subsection mb-4"}>
                        {component}
                        </section>
                    </section>
                </MDBModalBody>
                {isOpen? 
                        <MDBRow>
                            <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                                <MDBBtn color="primary" onClick={closeModal}>
                                    <FormattedMessage id="close.label" />
                                </MDBBtn>
                            </MDBCol>   
                        </MDBRow> : null 
                }
            </MDBModal>
        </ReactPortalModal>
    );
}
