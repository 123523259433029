import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as paymentHistoryActions from '../actions/payment-history-action';
import * as layoutActions from '../actions/layout-action';
import * as errorActions from '../actions/error-action';
import * as actionTypes from '../constants/action-types';
import * as paymentHistoryApi from '../api/payment-history-api';
import { countryCodeToMerchantId } from '../utils/utils';


const getEnvProps = (state) => state.envProps;
export function* getPaymentHistoryList(action) {
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { filters = {}, callback } = action;
        const { apiUrlPrefix, apiToken, simulateApi } = envProps;

        const response = yield call(paymentHistoryApi.getPaymentHistoryList, apiUrlPrefix, apiToken, filters);

        const { ok, parsedBody=[] } = response;
        if (ok && parsedBody) {
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(paymentHistoryActions.getPaymentHistoryListSuccess(parsedBody));
        } else {
            yield put(paymentHistoryActions.getPaymentHistoryListFailure(parsedBody));
        }
    } catch (err) {
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getPaymentHistoryFilters(action) {
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { filters: savedFilters = {}, callback } = action;
        const { apiUrlPrefix, apiToken, simulateApi } = envProps;

        const parsedSaveFilters = {...savedFilters, planOrAccountNumber: null}

        const response = yield call(paymentHistoryApi.getPaymentHistoryFilters, apiUrlPrefix, apiToken, parsedSaveFilters);

        const { ok, parsedBody } = response;
        if (ok && parsedBody) {
            if(savedFilters.planOrAccountNumber){
                parsedBody.planOrAccountNumberCurrent = savedFilters.planOrAccountNumber
            }
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(paymentHistoryActions.getPaymentHistoryFiltersSuccess(parsedBody));
        } else {
            yield put(paymentHistoryActions.getPaymentHistoryFiltersFailure(parsedBody));
        }
    } catch (err) {
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

// export function* getPaymentRecordsByParams(action){
//     try{
//         yield put(layoutActions.showSpinner());
//         const envProps = yield select(getEnvProps);
//         const { invoiceNumber, planInvoiceNumber, parentReferenceId, callback } = action;
//         const { apiUrlPrefix, apiToken } = envProps;

//         const searchFields = {
//             ...(!!invoiceNumber ? {invoiceNumber} : {}),
//             ...(!!planInvoiceNumber ? {planInvoiceNumber} : {}),
//             ...(!!parentReferenceId ? {parentReferenceId} : {})
//         };

//         const response = yield call(paymentHistoryApi.getPaymentRecordsByParams, searchFields, apiUrlPrefix, apiToken);

//         const { ok, parsedBody } = response;
//         if (ok && parsedBody) {
//             if (callback) {
//                 yield call(callback, parsedBody);
//             }
//             yield put(paymentHistoryActions.getPaymentRecordsByParamsSuccess(parsedBody));
//         } else {
//             yield put(paymentHistoryActions.getPaymentRecordsByParamsFailure(parsedBody));
//         }

//     }catch(err){
//         yield put(errorActions.setErrors(['page.unexpectedError']));
//     }finally {
//         yield put(layoutActions.hideSpinner());
//     }
// }

export function* getChargebackRecordsByReference(action){
    try{
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { chargebackReqData, callback } = action;
        const { apiUrlPrefix, apiToken } = envProps;

        const response = yield call(paymentHistoryApi.getChargebackRecordsByReference, chargebackReqData, apiUrlPrefix, apiToken);

        const { ok, parsedBody } = response;
        if (ok && parsedBody) {
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(paymentHistoryActions.getPaymentRecordsByParamsSuccess(parsedBody));
        } else {
            yield put(paymentHistoryActions.getPaymentRecordsByParamsFailure(parsedBody));
            if (callback) {
                yield call(callback, parsedBody);
            }
        }

    }catch(err){
        yield put(errorActions.setErrors(['page.unexpectedError']));
    }finally {
        yield put(layoutActions.hideSpinner());
    }
}

// export function* getPaymentRecordsByParamsSaga() {
//     yield takeEvery(actionTypes.GET_PAYMENT_ACTIVITY_BY_PARAMS, getPaymentRecordsByParams);
// }

export function* getChargebackRecordsByReferenceSaga() {
    yield takeEvery(actionTypes.GET_CHARGEBACK_ACTIVITY_BY_REFERENCE, getChargebackRecordsByReference);
}

export function* getPaymentHistoryListSaga() {
    yield takeEvery(actionTypes.GET_PAYMENT_HISTORY_LIST, getPaymentHistoryList);
}

export function* getPaymentHistoryFilterSaga(){
    yield takeEvery(actionTypes.GET_PAYMENT_HISTORY_FILTERS, getPaymentHistoryFilters);
}

export default function* paymentHistorySaga() {
    yield all([
        fork(getPaymentHistoryListSaga),
        fork(getPaymentHistoryFilterSaga),
        // fork(getPaymentRecordsByParamsSaga),
        fork(getChargebackRecordsByReferenceSaga)
    ]);
}
