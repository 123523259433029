import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MDBCol, MDBRow, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBTable, MDBTableBody } from 'mdbreact';
import { FormattedMessage, injectIntl, IntlProvider } from "react-intl";
import { bindActionCreators } from 'redux';
import classNames from "classnames";
import ReactPortalModal from "../../../ReactPortalModal";
import { stripAccountNumber, formatDateTime, formatCurrency, formatToLocalDate, formatDate } from "../../../../utils/ups-utils";
import { formatPaymentMethod, returnAccountNumberType, returnInvoiceNumber, returnInvoiceType, returnInvoiceRelatedDates, returnCurrencyCode, combinePlanOrAccountNumberStr, getReferenceNumber, extractAccountNumberFromInvoices, getSource } from "../../../../utils/payment-history-util";
import { PLAN, ACCOUNT, SCHEDULED, INVOICE_DUE_DATE, INVOICE_DATE, CANCEL_PAYMENT, VIEW_PAYMENT, PAYMENT_CANCELED, SCHEDULED_PAYMENT, CHANNEL_CODE_EXT, CANCEL_FUTURE_PAYMENT_EXECUTING, AUTO_PAY, VIEW_CHARGEBACK_ACCOUNT_MULTI, VIEW_CHARGEBACK_HISTORY } from "../../../../constants/payment-history";
import { getInvoiceTypeMessage } from "../../../../utils/invoice-utils";
import * as paymentActions from '../../../../actions/payment-action'
import SepaMandateDownload from "../../SepaMandateDownload";
import ReactToPrint from 'react-to-print';
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../../DataTableV3/DataTableV3FilterWrapper";
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';
import BacsAgreement from "../../PaymentMethodDetails/BacsDetails/BacsAgreement";
const $ = require('jquery');
class ViewPaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            cancelRequestFailed: false,
            cancelRequestFailedMessage: '',
            modalStep: 1,
            tableState: INITIAL_TABLE_STATE
        }
    }

    handleCancelPayment = () => {
        const { modalName, toggleModal, data, intl } = this.props;
        const { id } = data;
        const { referenceId, paymentStatus, invoices } = data;

        const payload = {
            referenceId,
            paymentStatus,
            notes: 'Cancelling scheduled payment from Billing Center.',
            invoices
        }

        this.props.paymentActions.cancelPayment(payload, (response) => {
            if (response.hasOwnProperty('errorCode')) {
                let errorCode = 'error';
                if (response.errorCode === CANCEL_FUTURE_PAYMENT_EXECUTING) {
                    errorCode = CANCEL_FUTURE_PAYMENT_EXECUTING;
                }
                this.setState({ cancelRequestFailed: true , cancelRequestFailedMessage: intl.formatMessage({ id: `view-payment-modal.cancel-payment-request-failed.${errorCode}` })});
            } else {
                this.setState({ modalStep: 2 })
                this.props.handleSuccess(id);
            }

        })
    }

    mobileCardAccountNumber = (accountType) => {
        return <>
            {accountType?.planNumber && <span>{accountType.planNumber}</span>}
            {accountType?.planNumber && accountType?.accountNumber && <span className={"mx-1"}>|</span>}
            {accountType?.accountNumber && <span>{accountType.accountNumber}</span>}
        </>
    }

    getInvoiceType = (intl, invoiceMetadata) => (data) => {
        const invoiceType = returnInvoiceType(data);
        return (invoiceType) ? getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType) : ""
    }

    createDatatableCols = () => {
        const { intl, invoiceMetadata, businessUnit, merchant } = this.props;
        return [
            {
                field: 'invoiceDate',
                label: intl.formatMessage({id: `view-payment-modal.invoice_date.${businessUnit}`}),
                display: (data) => returnInvoiceRelatedDates(intl, data, INVOICE_DATE) ?? '',
                serialize: (data) => returnInvoiceRelatedDates(intl, data, INVOICE_DATE) ?? '',
                sortFunc: ({invoiceDate}) => (invoiceDate ?? '')
            },
            {
                field: 'planOrAccountNumber',
                label: intl.formatMessage({ id: `payment-history.history-table.plan_or_account_number.${businessUnit}` }),
                display: (rowData) => {
                    let accountType = returnAccountNumberType(rowData, intl);
                    return <React.Fragment>
                        {rowData && !!accountType.planNumber && <div>{accountType.planNumber}</div>}
                        {rowData && !!accountType.accountNumber && <div>{accountType.accountNumber}</div>}
                    </React.Fragment>
                },
                serialize: (data) => combinePlanOrAccountNumberStr(returnAccountNumberType(data, intl)),
                sortFunc: (data) => combinePlanOrAccountNumberStr(returnAccountNumberType(data, intl)),
                mobileCardPrimary: true,
                mobileDisplay: (data) => this.mobileCardAccountNumber(returnAccountNumberType(data, intl))
            },
            {
                field: 'invoiceNumber',
                label: intl.formatMessage({ id: `payment-history.history-table.invoice_number.${businessUnit}` }),
                display: (data) => returnInvoiceNumber(data),
                serialize: (data) => returnInvoiceNumber(data),
                sortFunc: (data) => returnInvoiceNumber(data)
            },
            {
                field: 'invoiceType',
                label: intl.formatMessage({ id: `payment-history.history-table.invoice_type.${businessUnit}` }),
                display: this.getInvoiceType(intl, invoiceMetadata),
                serialize: this.getInvoiceType(intl, invoiceMetadata),
                sortFunc: this.getInvoiceType(intl, invoiceMetadata)
            },
            {
                field: 'invoiceDueDate',
                label: intl.formatMessage({ id: `view-payment-modal.due_date.${businessUnit}` }),
                display: (data) => (returnInvoiceRelatedDates(intl, data, INVOICE_DUE_DATE) ?? ""),
                serialize: (data) => (returnInvoiceRelatedDates(intl, data, INVOICE_DUE_DATE) ?? ""),
                sortFunc: (data) => (data.dueDate ?? '')
            },
            {
                field: 'paymentAmount',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_amount' }),
                display: (data) => formatCurrency(intl, data.amount, returnCurrencyCode(data), merchant.currencyDisplay),
                serialize: (data) => formatCurrency(intl, data.amount, returnCurrencyCode(data), merchant.currencyDisplay),
                sortFunc: ({amount}) => amount,
                tdClassName: 'text-right'
            }
        ]
    }

    getHeaderMsgId = (modalType, modalStep) => {
        switch (modalType) {
            case VIEW_PAYMENT:
                return VIEW_PAYMENT;
            case VIEW_CHARGEBACK_ACCOUNT_MULTI:
                return VIEW_CHARGEBACK_ACCOUNT_MULTI;
            case CANCEL_PAYMENT:
                switch (modalStep) {
                    case 1:
                        return CANCEL_PAYMENT;
                    case 2:
                        return PAYMENT_CANCELED;
                }
        }
    }

    getHeader = () => {
        const { modalStep } = this.state;
        const { modalType } = this.props;
        let { data } = this.props;
        if (modalType === VIEW_CHARGEBACK_ACCOUNT_MULTI) {
            data = data[0];
        }
        return (
            <React.Fragment>
                <FormattedMessage id={`view-payment-modal.title.${this.getHeaderMsgId(modalType, modalStep)}`} />
                { (modalType === VIEW_PAYMENT || modalType === VIEW_CHARGEBACK_ACCOUNT_MULTI) && <React.Fragment>{getReferenceNumber(data) !== "" && <span> <FormattedMessage id={"number.symbol"} /> {getReferenceNumber(data)}</span>}</React.Fragment>}
            </React.Fragment>
        )
    }

    getSubHeader = () => {
        const { modalStep } = this.state;
        const { data } = this.props;
        if (modalStep === 1) {
            return (<React.Fragment>
                <p><FormattedMessage id={"payment-history.btn-cancel-payment.header.step1.label"} /> </p>
            </React.Fragment>)
        }
        else if (modalStep === 2) {
            return (<React.Fragment>
                <p><FormattedMessage id={"payment-history.btn-cancel-payment.header.step2.label"} values={{ rn: data.referenceId }} /> </p>
            </React.Fragment>)
        }

    }

    getPaymentDetailsFragment = (invoiceType = '', accountType = '', accountNumberAndPlanNumber= '') => {
        const { data, intl, modalType, invoiceMetadata, businessUnit, merchant } = this.props;
        const { locale, messages } = intl;
        return (
            <IntlProvider locale={locale} messages={messages}>
                <MDBTable className={"table-two-col"}>
                    <MDBTableBody>
                        {(getReferenceNumber(data) !== "") && <tr>
                            <th><FormattedMessage id={"payment-history.history-table.confirmation_number"} /></th>
                            <td>{getReferenceNumber(data)}</td>
                        </tr>}
                        {!data.isMulti && modalType == CANCEL_PAYMENT && <tr>
                            <th><FormattedMessage id={"view-payment-modal.account_type"} /></th>
                            <td>{accountType === ACCOUNT ? <FormattedMessage id={"payment-history.ACCOUNT"}/> : <FormattedMessage id={"payment-history.PLAN"}/> }</td>
                        </tr>}
                        {(accountType === PLAN || (!!accountNumberAndPlanNumber.accountNumber && !!accountNumberAndPlanNumber.planNumber))
                            && (!data.isMulti)
                            && <tr>
                                <th><FormattedMessage id={"view-payment-modal.plan_number"} /></th>
                                <td>{stripAccountNumber(
                                    !!accountNumberAndPlanNumber.accountNumber ? accountNumberAndPlanNumber.accountNumber : accountNumberAndPlanNumber.planNumber,
                                    accountNumberAndPlanNumber.paymentTypeCode)}</td>
                            </tr>}
                        {accountType === ACCOUNT && !data.isMulti && <tr>
                            <th><FormattedMessage id={"view-payment-modal.account_number"} /></th>
                            <td>{stripAccountNumber(accountNumberAndPlanNumber.accountNumber, accountNumberAndPlanNumber.paymentTypeCode)}</td>
                        </tr>}

                        {!data.isMulti && modalType == VIEW_PAYMENT && invoiceType !== "" && <tr>
                            <th><FormattedMessage id={"view-payment-modal.invoice_type"} /></th>
                            <td>{getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType)}</td>
                        </tr>}
                        {!data.isMulti && accountType === ACCOUNT && <tr>
                            <th><FormattedMessage id={`payment-history.history-table.invoice_number.${businessUnit}`} /></th>
                            <td>{returnInvoiceNumber(data.invoices[0])}</td>
                        </tr>}
                        {!data.isMulti && <tr>
                            <th><FormattedMessage id={`view-payment-modal.invoice_date.${businessUnit}`} /></th>
                            <td>{returnInvoiceRelatedDates(intl, data, INVOICE_DATE)}</td>
                        </tr>}
                        {!data.isMulti && returnInvoiceRelatedDates(intl, data, INVOICE_DUE_DATE) !== "" && <tr>
                            <th><FormattedMessage id={`view-payment-modal.invoice_due_date.${businessUnit}`} /></th>
                            <td>{returnInvoiceRelatedDates(intl, data, INVOICE_DUE_DATE)}</td>
                        </tr>}
                        <tr>
                            <th><FormattedMessage id={"payment-history.history-table.payment_status"} /></th>
                            <td> {intl.formatMessage({ id: `payment-history.search-field.status.option.${data.paymentStatus}` })}</td>
                        </tr>
                        <tr>
                            <th><FormattedMessage id={"view-payment-modal.source"} /></th>
                            <td>{getSource(intl, data)}</td>
                        </tr>
                        <tr>
                            <th><FormattedMessage id={"payment-history.history-table.schedule"} /></th>
                            <td>{data.channel === SCHEDULED_PAYMENT || (data.channel === CHANNEL_CODE_EXT && data.executionTypeCode === AUTO_PAY) ? intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' }) : intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })}</td>
                        </tr>
                        {data.paymentMethod && <tr>
                            <th><FormattedMessage id={"payment-history.history-table.payment_method"} /></th>
                            <td>{formatPaymentMethod(data.paymentMethod, intl, data.channel)}</td>
                        </tr>}
                        <tr>
                            <th><FormattedMessage id={"payment-history.history-table.payment_date"} /></th>
                            <td>
                                {
                                    data?.paymentStatus?.toUpperCase() === SCHEDULED ? formatToLocalDate(intl, data.paymentDate) :
                                        (data.channel === CHANNEL_CODE_EXT ? formatDate(intl, data.paymentDate || "") : formatDateTime(intl, data.paymentDate))
                                }
                            </td>
                        </tr>
                        {data.sepaMandate?.authorizeSepa && <tr>
                            <th><FormattedMessage id={"payment-history.history-table.sepa-mandate-download"} /></th>
                            <td><SepaMandateDownload payment={data}/></td>
                        </tr>}
                        <tr>
                            <th>{<FormattedMessage id={`view-payment-modal.${data.isMulti ? "total_" : ""}payment_amount`} />} </th>
                            <td>{formatCurrency(intl, data.totalAmount, returnCurrencyCode(data), merchant.currencyDisplay)}</td>
                        </tr>
                        {/* <tr className="total-p-line">
                                        <th>Credit Amount</th>
                                        <td>$80.20</td>
                                    </tr> */}
                    </MDBTableBody>
                </MDBTable>
            </IntlProvider>
        )
    }

    render() {
        const { getSubHeader, getHeader } = this;
        const { loaded, modalStep, cancelRequestFailed, cancelRequestFailedMessage, tableState } = this.state;
        const { isOpen, toggleModal, modalName, intl, modalType, invoiceMetadata, caption } = this.props;
        let { data } = this.props;
        const chargeBackData = data;
        let components = [];
        let header = [];
        let subHeader = [];
        let invoiceType = ''; 
        let accountType = '';
        let accountNumberAndPlanNumber = '';
        if (modalType === VIEW_CHARGEBACK_ACCOUNT_MULTI) {
            data = data[0];
        }
        const methodType = data?.paymentMethod?.methodType
        if (loaded) {
            // Prepare the table component for the case of multiple
            if (data.isMulti) {
                components.push(
                    <FilteredDataTableV3
                    caption={caption}
                        key={`view-payment-modal-table`}
                        name={'view-payment-modal-table'}
                        className={`allow-default-wrap`}
                        data={data.invoices}
                        columns={this.createDatatableCols()}
                        defaultSorting={'invoiceDate'}
                        tableState={tableState}
                        tableStateAction={(tableAction)=>this.setState(state=>tableAction(state))}
                        searchable
                        searchRight
                        mobileCard
                        rowHeaderField={'planOrAccountNumber'}
                    />
                );
            } else if(!data.isMulti || data.isPlan){
                let localInvoice = JSON.parse(JSON.stringify(data.invoices[0]));
                if (data.isPlan) {
                    delete localInvoice.accountNumber;
                }
                invoiceType = returnInvoiceType(localInvoice);
                accountNumberAndPlanNumber = extractAccountNumberFromInvoices(localInvoice)
                if (accountNumberAndPlanNumber.accountNumber && accountNumberAndPlanNumber.planNumber) {
                    accountType = ACCOUNT;
                } else if (accountNumberAndPlanNumber.accountNumber) {
                    accountType = ACCOUNT;
                }
                else if (accountNumberAndPlanNumber.planNumber) {
                    accountType = PLAN;
                }
            }
            header = getHeader();
            if (modalType === CANCEL_PAYMENT) {
                subHeader = getSubHeader();
            }
        }
        const modalHeading = `view-payment-modal.title.${this.getHeaderMsgId(modalType, modalStep)}`;
        const modalLabel = "view-payment";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                    {/* backdrop={backdrop} */}
                    <MDBModal id="view-payment-modal" isOpen={isOpen} toggle={() => toggleModal(modalName)} size={data.isMulti ? "xl" : "md"} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})} >
                        <div ref={el => (this.componentRef = el)}>
                            <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal(modalName)}>
                                {header}
                            </MDBModalHeader>
                            <MDBModalBody aria-live="assertive">
                                {subHeader}
                                {/* TODO: For the cancelled payment status*/}

                                {loaded && !(modalType === VIEW_CHARGEBACK_ACCOUNT_MULTI) && <React.Fragment>
                                    {this.getPaymentDetailsFragment(invoiceType, accountType, accountNumberAndPlanNumber)}
                                </React.Fragment>}

                                {
                                    loaded && data.isMulti &&
                                    < MDBRow >
                                        <MDBCol size="12" className={"mt-3"}>
                                            {!(modalType === VIEW_CHARGEBACK_ACCOUNT_MULTI) && <div className={classNames("datatable-blend-container payment-detail")}>
                                                <div className={"datatable-blend-inner payment-detail-header"}>
                                                    <h3>{intl.formatMessage({ id: 'payment-history.payment_details' })}</h3>
                                                </div>
                                            </div>}
                                            {components}
                                        </MDBCol>
                                    </MDBRow>
                                }
                                {
                                    cancelRequestFailed && < MDBRow >
                                        <MDBCol size="12" className={"mb-2 text-center text-danger"}>
                                            {cancelRequestFailedMessage}
                                        </MDBCol>
                                    </ MDBRow>
                                }
                                {methodType === 'BACS' &&
                                    <div className='printView'>
                                        <BacsAgreement paymentMethod={data.paymentMethod} showAgreementResult={true} referenceNumber={data.referenceId}/>
                                    </div>
                                }
                            </MDBModalBody>
                        </div>
                        {modalType === VIEW_PAYMENT &&
                            <MDBModalFooter aria-live="polite">
                                <ReactToPrint
                                    trigger={() => {
                                        return (<MDBBtnWrapper label={intl.formatMessage({id: "ups.btn-print"})} color="primary"></MDBBtnWrapper>)
                                    }}
                                    content={() => this.componentRef}
                                    bodyClass='printDisplay'
                                />
                                <MDBBtnWrapper label={intl.formatMessage({id: "btn-close"})} color="cancel" onClick={() => toggleModal(modalName)}></MDBBtnWrapper>
                            </MDBModalFooter>
                        }
                        {modalType === CANCEL_PAYMENT && modalStep === 1 &&
                            <MDBModalFooter aria-live="polite">
                                <MDBBtnWrapper label={intl.formatMessage({id: "payment-history.btn-submit"})} color="primary" onClick={this.handleCancelPayment}></MDBBtnWrapper>
                                <MDBBtnWrapper label={intl.formatMessage({id: "payment-history.btn-cancel"})} color="cancel" onClick={() => toggleModal(modalName)}>
                                </MDBBtnWrapper>
                            </MDBModalFooter>
                        }

                        {modalType === CANCEL_PAYMENT && modalStep === 2 &&
                            <MDBModalFooter aria-live="polite">
                                <MDBBtnWrapper label={intl.formatMessage({id: "btn-close"})} color="primary" onClick={() => toggleModal(modalName)}></MDBBtnWrapper>
                            </MDBModalFooter>
                        }
                        {modalType === VIEW_CHARGEBACK_ACCOUNT_MULTI &&
                            <MDBModalFooter aria-live="polite">
                                <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.back.label"})} color="primary" onClick={() => {
                                    toggleModal(modalName);
                                    toggleModal('viewChargebackHistory', chargeBackData, VIEW_CHARGEBACK_HISTORY)
                                }
                                }> </MDBBtnWrapper>
                                <MDBBtnWrapper label={intl.formatMessage({id: "btn-close"})} color="cancel" onClick={() => toggleModal(modalName)}></MDBBtnWrapper>
                            </MDBModalFooter>
                        }
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        businessUnit: state.auth.user.selectedPaymentType,
        merchant: state.config.sessionSelection.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewPaymentModal)));