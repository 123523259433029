import React                                        from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader}     from 'mdbreact';
import ReactPortalModal                             from "../../../ReactPortalModal";
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { FormattedMessage, useIntl }                 from 'react-intl';

export default function PayByTextModal({isOpen, closeModal, component, languageCode}) {
    const sampleImage = languageCode.replace("-","");
    const payByTextSampleImage = sampleImage.substring(0, 4);
    const modalHeading = "ups.preferences.paybytext.modal.note1";
    const intl = useIntl();
    return (
        <ReactPortalModal isOpen={isOpen} an_label="pay-by-text">
            <MDBModal isOpen={isOpen} size="md" disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                <MDBModalHeader toggle={closeModal}>
                    <FormattedMessage closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} id={modalHeading} />
                </MDBModalHeader>
                <MDBModalBody>
                    <p><FormattedMessage id="ups.preferences.paybytext.modal.note2" /></p>
                    <section className={"ups-section ups-enroll-section"}>
                        <section className={"ups-enroll-subsection mb-4"}>
                        <img src={'images/' + payByTextSampleImage + '_PBT_sample.png ' } alt className="img-fluid border"/>
                        </section>
                    </section>
                </MDBModalBody>
                {isOpen? 
                        <MDBRow>
                            <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                                <MDBBtn color="primary" onClick={closeModal}>
                                    <FormattedMessage id="close.label" />
                                </MDBBtn>
                            </MDBCol>   
                        </MDBRow> : null 
                }
            </MDBModal>
        </ReactPortalModal>
    );
}
