import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardTitle,
    MDBCardBody,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBSpinner
} from "mdbreact";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/invoice-utils";
import { PAST_DUE_INVOICES } from '../../../constants/invoice-constants';
import MDBBtnWrapper from '../../MDBFix/MDBBtnWrapper';
import {isFreightActive} from "../../../utils/config-utils";
const DashboardPastDueTile = ({ currencyCode, pastDueSummary, onPayInvoices, businessUnit, loading, goToAccountInvoices }) => {
    const intl = useIntl();
    const { user } = useSelector(s => s.auth);
    const { merchant } = useSelector(s => s.config?.sessionSelection ?? {})
    const { plansSum, plansCount, accountsSum, accountsCount } = pastDueSummary || {};
    const pastDueFilters = { accounts: true, pastDue: true, keyToAccessInvoices: 'pastDue' };
    const planCountTitle = 'dashboard.past-due.title1-' + (plansCount === 1 ? 'single' : 'multiple');
    const accountCountTitle = 'dashboard.past-due.title3-' + (accountsCount === 1 ? 'single' : 'multiple') + `.${businessUnit}`;
    return (
        <MDBCard className={"dashboard-widget-card past-due-card h-100"}>
            <MDBCardTitle tag="h2">
                <span aria-hidden="true" className="fas fa-calendar-exclamation mr-2"></span>
                <FormattedMessage id={`dashboard.past-due-notice.title.${businessUnit}`} />
            </MDBCardTitle>
            <MDBCardBody>
                { loading ? <MDBSpinner className={'small-spinner'}/> : <MDBRow>
                    <MDBCol size={"12"}>
                        {(!accountsCount && !plansCount) ?
                            <span>
                                <br></br>
                                <FormattedMessage id={`dasboard.no-past-due.title.${businessUnit}`} />
                            </span>
                            : null
                        }
                        <ul className="custom-timeline past-due-timeline">
                            {plansCount > 0 ?
                            <li>
                               <MDBRow>
                                <MDBCol  size={"10"} className={"mt-3"}>
                                    <span className="timeline-date">
                                        <span className="pay-plans--container fas fa-folder"></span>
                                    </span>
                                    
                                    <span className="timeline-due-amount">
                                        {formatCurrency(intl, plansSum, currencyCode, merchant.currencyDisplay)}&nbsp;<span className="due-now"><FormattedMessage id="dashboard.past-due.title2" /></span>
                                    </span>
                                    <hr />
                                    <span className="timeline-desc past-due-span">
                                            <a href="#" onClick={() => onPayInvoices(false, `${PAST_DUE_INVOICES}?payable=true`)}>{plansCount}&nbsp;<FormattedMessage id={planCountTitle} /></a>
                                    </span>
                                    </MDBCol>
                                    {loading || (businessUnit === 'FRT' && !isFreightActive(merchant)) || user.adUserId ?
                                        null:
                                        <MDBCol  size={"2"}  className={"mt-3 past-due"}>
                                            <MDBBtn className="btn-primary btn-plan" onClick={() => onPayInvoices(false, `${PAST_DUE_INVOICES}?payable=true`)}>
                                                <FormattedMessage id="dashboard.payPlans" />
                                            </MDBBtn>
                                        </MDBCol>
                                    }
                                </MDBRow>                               
                           </li> : null
                       }

                       {accountsCount > 0 ?
                       
                           <li>
                            <MDBRow>
                                <MDBCol  size={"10"} className={"mt-3"}>
                                    <span className="timeline-date">
                                        <span aria-hidden="true" className="fas fa-file-alt">

                                        </span>
                                    </span>
                                    
                                    <span className="timeline-due-amount">
                                    {formatCurrency(intl, accountsSum, currencyCode, merchant.currencyDisplay)}&nbsp;<span className="due-now"><FormattedMessage id="dashboard.past-due.title2" /></span>
                                    </span>
                                    <hr />
                                    <span className="timeline-desc past-due-span">
                                            <a href="#" onClick={() => goToAccountInvoices(`${PAST_DUE_INVOICES}?payable=true`)}>{accountsCount}&nbsp;<FormattedMessage id={accountCountTitle} /></a>
                                    </span>
                                </MDBCol>
                                {loading || (businessUnit === 'FRT' && !isFreightActive(merchant)) || user.adUserId ?
                                    null:
                                    <MDBCol size={"2"} className={"mt-3 past-due"}>
                                        <MDBBtn className="btn-primary" onClick={() => onPayInvoices(true, pastDueFilters, PAST_DUE_INVOICES)}>
                                            <FormattedMessage id="dashboard.payAccounts" />
                                        </MDBBtn>
                                    </MDBCol>
                                }
                            </MDBRow>
                           </li> : null
                       }
                        </ul>
                    </MDBCol>
                </MDBRow>
                }
            </MDBCardBody>
        </MDBCard>
    );
};

export default DashboardPastDueTile;
