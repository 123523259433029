/* others */
export const INIT = "INIT";
export const LOGOUT = 'LOGOUT';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_FIELD_ERROR = 'CLEAR_FIELD_ERROR';
export const SUBMIT_VALIDATION = 'SUBMIT_VALIDATION';
export const SET_VALIDATION = 'SET_VALIDATION';
export const CLEAR_VALIDATION = 'CLEAR_VALIDATION';
export const REGISTER_FIELD = 'REGISTER_FIELD';
export const DEREGISTER_FIELD = 'DEREGISTER_FIELD';
export const INIT_VALIDATION = 'INIT_VALIDATION';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';

/* pay by text */

export const REMOVE_PAY_BY_TEXT_USER = 'REMOVE_PAY_BY_TEXT_USER';


/* login page */
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_NEW_USER = 'LOGIN_NEW_USER';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const GET_ALL_MERCHANTS = 'GET_ALL_MERCHANTS';
export const GET_ALL_MERCHANTS_SUCCESS = 'GET_ALL_MERCHANTS_SUCCESS';
export const GET_ALL_MERCHANTS_FAILURE = 'GET_ALL_MERCHANTS_FAILURE';
/* action responsible for login/callback*/
export const SSO_CALLBACK='SSO_CALLBACK';
/* action responsible for redirecting user to sso initiation at ups*/
export const REDIRECT_USER = 'REDIRECT_USER';
/* action responsible for extending the state of session widget  */
export const EXTEND_SESSION = 'EXTEND_SESSION';
/* action responsible for expiring cookie on logout*/
export const EXPIRE_COOKIE = 'EXPIRE_COOKIE';
export const GET_ACCESSIBLE_MERCHANTS = 'GET_ACCESSIBLE_MERCHANTS';
export const GET_ACCESSIBLE_MERCHANTS_SUCCESS = 'GET_ACCESSIBLE_MERCHANTS_SUCCESS';
export const GET_ACCESSIBLE_MERCHANTS_FAILURE = 'GET_ACCESSIBLE_MERCHANTS_FAILURE';
export const CSR_LOGIN ='CSR_LOGIN';
export const CSR_CALLBACK ='CSR_CALLBACK';

/* enrollment page */
export const VALIDATE_INVOICE = 'VALIDATE_INVOICE';
export const VALIDATE_INVOICE_SUCCESS = 'VALIDATE_INVOICE_SUCCESS';
export const ENROLL = 'ENROLL';
export const ENROLLMENT_SUCCESS = 'ENROLLMENT_SUCCESS';
export const ENROLLMENT_CANCEL = "ENROLLMENT_CANCEL";
export const ENROLLMENT_RESTART = "ENROLLMENT_RESTART";

/* add account actions */
export const VALIDATE_ACCOUNT_ELIGIBILITY = 'VALIDATE_ACCOUNT_ELIGIBILITY';
export const VALIDATE_ACCOUNT_ELIGIBILITY_SUCCESS = 'VALIDATE_ACCOUNT_ELIGIBILITY_SUCCESS';
export const INIT_ADD_ACCOUNT = 'INIT_ADD_ACCOUNT';
export const AIA_REQUIRED = 'AIA_REQUIRED';
export const ADD_ACCOUNT_RESTART = 'ADD_ACCOUNT_RESTART';
export const ADD_ACCOUNT_CANCEL = 'ADD_ACCOUNT_CANCEL';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';

/* DEMO */
export const TOGGLE_API = 'TOGGLE_API';
export const TOGGLE_CARD_MODE = 'TOGGLE_CARD_MODE';
export const TOGGLE_FLOW_MODE = 'TOGGLE_FLOW_MODE';
export const TOGGLE_SHOW_FLOW_MODE = 'TOGGLE_SHOW_FLOW_MODE';

/* APP */
export const RESTORE_SETTINGS = 'default_settings';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_ISSUER = 'SET_ISSUER';
export const SET_ISSUER_SUCCESS = 'SET_ISSUER_SUCCESS';
export const SIDE_MENU_COLLAPSE = 'side_menu_collapse';
export const SET_THEME = 'side_menu_collapse';
export const SET_THEME_SUCCESS = 'side_menu_collapse';
export const USER_SESSION_DATA = 'user_session_data';
export const SET_SESSION_MODAL_STATE = 'set_session_modal_state';
export const SET_COUNTRY_LOCALE = 'SET_COUNTRY_LOCALE';
export const SET_STORE_STATE = 'SET_STORE_STATE';


/* Auth */
export const SIGN_UP = 'sign_up';
export const SIGN_UP_SUCCESS = 'sign_up_success';
export const SIGN_IN = 'sign_in';
export const SIGN_IN_SUCCESS = 'sign_in_success';
export const SIGN_OUT = 'sign_out';
export const SIGN_OUT_SUCCESS = 'sign_out_success';
/**
 * GENERIC BASIC ACTIONS START
 */
export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const TOGGLE_SPINNER = 'TOGGLE_SPINNER';

export const AGENT_LOGIN = 'AGENT_LOGIN';
export const USER_LOGIN = 'USER_LOGIN';
export const CSRF_TOKEN = 'CSRF_TOKEN';




/**
 * GENERIC BASIC ACTIONS END
 */

/**
 * GENERIC MODAL ACTIONS START
 */

export const OPEN_BASIC_MODAL = 'OPEN_BASIC_MODAL';
export const CLOSE_BASIC_MODAL = 'CLOSE_BASIC_MODAL';
/**
 * GENERIC MODAL ACTIONS END
 */

/**
 * PAGES START
 */
export const SHOW_MAIN = 'SHOW_MAIN';
export const SHOW_CREATE_IDENTITY = 'SHOW_CREATE_IDENTITY';
export const SHOW_FIND_IDENTITY = 'SHOW_FIND_IDENTITY';

/**
 * PAGES END
 */

/**
 * IDENTITY ACTIONS START
 */
export const IDENTITY_EXPORT_CSV = 'IDENTITY_EXPORT_CSV';
export const IDENTITY_EXPORT_PDF = 'IDENTITY_EXPORT_PDF';

export const CREATE_IDENTITY = 'CREATE_IDENTITY';
export const SET_IDENTITY = 'SET_IDENTITY';
export const GET_IDENTITY = 'GET_IDENTITY';
export const IDENTITY_EXISTS = 'IDENTITY_EXISTS';
export const GET_IDENTITY_SUCCESS = 'GET_IDENTITY_SUCCESS';
export const GET_IDENTITY_FAILURE = 'GET_IDENTITY_FAILURE';
export const UNDO_MANAGE_IDENTITY = 'UNDO_MANAGE_IDENTITY';
export const UPDATE_IDENTITY = 'UPDATE_IDENTITY';
export const UPDATE_IDENTITY_STAGING = 'UPDATE_IDENTITY_STAGING';
export const UPDATE_IDENTITY_SUCCESS = 'UPDATE_IDENTITY_SUCCESS';
export const UNDO_UPDATE_IDENTITY = 'UNDO_UPDATE_IDENTITY';
export const GET_IDENTITY_LIST = 'GET_IDENTITY_LIST';
export const GET_IDENTITY_LIST_SUCCESS = 'GET_IDENTITY_LIST_SUCCESS';
export const GET_IDENTITY_LIST_FAILURE = 'GET_IDENTITY_LIST_FAILURE';
export const GET_IDENTITY_ACCOUNT_LIST = 'GET_IDENTITY_ACCOUNT_LIST';
export const GET_IDENTITY_ACCOUNT_LIST_SUCCESS = 'GET_IDENTITY_ACCOUNT_LIST_SUCCESS';
export const GET_IDENTITY_ACCOUNT_LIST_FAILURE = 'GET_IDENTITY_ACCOUNT_LIST_FAILURE';
export const ADD_ACCOUNT_TO_IDENTITY = 'ADD_ACCOUNT_TO_IDENTITY';
export const ADD_ACCOUNT_TO_IDENTITY_SUCCESS = 'ADD_ACCOUNT_TO_IDENTITY_SUCCESS';
export const ADD_ACCOUNT_TO_IDENTITY_FAILURE = 'ADD_ACCOUNT_TO_IDENTITY_FAILURE';
export const REMOVE_ACCOUNT_FROM_IDENTITY = 'REMOVE_ACCOUNT_FROM_IDENTITY';
export const CLEAR_IDENTITY = 'CLEAR_IDENTITY';
export const CLEAR_IDENTITY_LIST = 'CLEAR_IDENTITY_list';
export const CLEAR_CHANGE_LOG = 'CLEAR_CHANGE_LOG';

export const UPDATE_IDENTITY_ACCOUNT = 'UPDATE_IDENTITY_ACCOUNT';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';
export const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
export const GET_ACCOUNT_LIST_SUCCESS = 'GET_ACCOUNT_LIST_SUCCESS';
export const GET_ACCOUNT_LIST_FAILURE = 'GET_ACCOUNT_LIST_FAILURE'
export const GET_PLAN_LIST = 'GET_PLAN_LIST';
export const GET_PLAN_LIST_SUCCESS = 'GET_PLAN_LIST_SUCCESS';
export const GET_PLAN_LIST_FAILURE = 'GET_PLAN_LIST_FAILURE';
export const ADD_ACCOUNT_TO_COMPANY = 'ADD_ACCOUNT_TO_COMPANY';
export const ADD_ACCOUNT_TO_COMPANY_SUCCESS = 'ADD_ACCOUNT_TO_COMPANY_SUCCESS';
export const ADD_ACCOUNT_TO_COMPANY_FAILURE = 'ADD_ACCOUNT_TO_COMPANY_FAILURE';
export const ADD_ACCOUNT_COMPLETE = 'ADD_ACCOUNT_COMPLETE';
export const REMOVE_ACCOUNTS = 'REMOVE_ACCOUNTS';
export const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS';
export const REMOVE_ACCOUNT_FAILURE = 'REMOVE_ACCOUNT_FAILURE';
export const REMOVE_ACCOUNT_COMPLETE = 'REMOVE_ACCOUNT_COMPLETE';

export const GET_XML_ENROLLMENT = 'GET_XML_ENROLLMENT';
export const GET_XML_ENROLLMENT_SUCCESS = 'GET_XML_ENROLLMENT_SUCCESS ';
export const GET_XML_UNENROLLMENT_SUCCESS = 'GET_XML_UNENROLLMENT_SUCCESS';
export const GET_XML_ENROLLMENT_FAILURE = 'GET_XML_ENROLLMENT_FAILURE';
export const GET_XML_ENROLLMENT_COMPLETE = 'GET_XML_ENROLLMENT_COMPLETE';
export const ENROLL_XML = 'ENROLL_XML';
export const ENROLL_XML_SUCCESS = 'ENROLL_XML_SUCCESS';
export const ENROLL_XML_FAILURE = 'ENROLL_XML_FAILURE';
export const UNENROLL_XML = 'UNENROLL_XML';
export const UNENROLL_XML_SUCCESS = 'UNENROLL_XML_SUCCESS';
export const UNENROLL_XML_FAILURE = 'UNENROLL_XML_FAILURE';
export const ENROLL_XML_COMPLETE = 'ENROLL_XML_COMPLETE';
export const SET_IDENTITY_PREFERENCES = 'SET_IDENTITY_PREFERENCES';
export const SAVE_IDENTITY_PREFERENCES_UPDATE = 'SAVE_IDENTITY_PREFERENCES_UPDATE';
export const SAVE_IDENTITY_PREFERENCES_SUCCESS = 'SAVE_IDENTITY_PREFERENCES_SUCCESS';
export const SAVE_IDENTITY_PREFERENCES_FAILURE = 'SAVE_IDENTITY_PREFERENCES_FAILURE';
export const SET_IDENTITY_PLAN_ENROLLED = 'SET_IDENTITY_PLAN_ENROLLED';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_USER_COUNTRY_SETTINGS = 'SET_USER_COUNTRY_SETTINGS';
export const SET_SELECTED_PAYMENT_TYPE = 'SET_SELECTED_PAYMENT_TYPE';
export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';
// export const SAVE_LAST_LOGIN = 'SAVE_LAST_LOGIN';
export const SAVE_LAST_LOGIN_SUCCESS = 'SAVE_LAST_LOGIN_SUCCESS';
/**
 * IDENTITY ACTIONS END
 */

/**
 * ACCOUNT ACTIONS START
 */
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const GET_ACCOUNT_AGGREGATE_BY_ACCOUNT = 'GET_ACCOUNT_AGGREGATE_BY_ACCOUNT';
export const GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_SUCCESS = 'GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_FAILURE = 'GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_FAILURE';
export const GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_EXTERNAL = 'GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_EXTERNAL';
export const GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_EXTERNAL_SUCCESS = 'GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_EXTERNAL_SUCCESS';
export const GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_EXTERNAL_FAILURE = 'GET_ACCOUNT_AGGREGATE_BY_ACCOUNT_EXTERNAL_FAILURE';
export const GET_ACCOUNT_AGGREGATE_BY_IDENTITY = 'GET_ACCOUNT_AGGREGATE_BY_IDENTITY';
export const GET_ACCOUNT_AGGREGATE_BY_IDENTITY_SUCCESS = 'GET_ACCOUNT_AGGREGATE_BY_IDENTITY_SUCCESS';
export const GET_ACCOUNT_AGGREGATE_BY_IDENTITY_FAILURE = 'GET_ACCOUNT_AGGREGATE_BY_IDENTITY_FAILURE';
export const GET_ACCOUNT_AGGREGATE_BY_IDENTITY_EXTERNAL = 'GET_ACCOUNT_AGGREGATE_BY_IDENTITY_EXTERNAL';
export const GET_ACCOUNT_AGGREGATE_BY_IDENTITY_EXTERNAL_SUCCESS = 'ACCOUNT_AGGREGATE_BY_IDENTITY_EXTERNAL_SUCCESS';
export const GET_ACCOUNT_AGGREGATE_BY_IDENTITY_EXTERNAL_FAILURE = 'ACCOUNT_AGGREGATE_BY_IDENTITY_EXTERNAL_FAILURE';

/**
 * ACCOUNT ACTIONS END
 */


/**
 * UI ACTIONS START
 */
export const SCREEN_RESIZE = 'SCREEN_RESIZE';
/**
 * UI ACTIONS END
 */




/**
 * CUSTOMER START
 */
export const GET_CUSTOMER_SEARCH_TYPE = 'GET_CUSTOMER_SEARCH_TYPE';
export const GET_CUSTOMER_IDENTITY = 'GET_CUSTOMER_IDENTITY';
export const GET_MERCHANT_RESULTS_DEMO = 'GET_MERCHANT_RESULTS_DEMO';
/**
 * CUSTOMER S3 START
 */



/**
 * PAYMENTS START
 */
export const PROCESS_PAYMENT = 'PROCESS_PAYMENT';
export const GET_PAYMENT = 'GET_PAYMENT';
export const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
export const CLEAR_PAYMENT_LIST = 'CLEAR_PAYMENT_LIST';
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT';
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS';
export const CANCEL_PAYMENT_FAILURE = 'CANCEL_PAYMENT_FAILURE';
export const GET_PAYMENT_BY_PAYMENT_ID = 'GET_PAYMENT_BY_PAYMENT_ID';
export const GET_PAYMENT_BY_PAYMENT_ID_SUCCESS = 'GET_PAYMENT_BY_PAYMENT_ID_SUCCESS';
export const GET_PAYMENT_BY_PAYMENT_ID_FAILURE = 'GET_PAYMENT_BY_PAYMENT_ID_FAILURE';
export const GET_PAYMENT_BY_REFERENCE_ID = 'GET_PAYMENT_BY_REFERENCE_ID';
export const GET_PAYMENT_BY_REFERENCE_ID_SUCCESS = 'GET_PAYMENT_BY_REFERENCE_ID_SUCCESS';
export const GET_PAYMENT_BY_REFERENCE_ID_FAILURE = 'GET_PAYMENT_BY_REFERENCE_ID_FAILURE';
export const GET_PAYMENT_BY_EXTERNAL_ID = 'GET_PAYMENT_BY_EXTERNAL_ID';
export const GET_PAYMENT_BY_EXTERNAL_ID_SUCCESS = 'GET_PAYMENT_BY_EXTERNAL_ID_SUCCESS';
export const GET_PAYMENT_BY_EXTERNAL_ID_FAILURE = 'GET_PAYMENT_BY_EXTERNAL_ID_FAILURE';
export const GET_PAYMENT_LIST_BY_IDENTITY_ID = 'GET_PAYMENT_LIST_BY_IDENTITY_ID';
export const GET_PAYMENT_LIST_BY_IDENTITY_ID_SUCCESS = 'GET_PAYMENT_LIST_BY_IDENTITY_ID_SUCCESS';
export const GET_PAYMENT_LIST_BY_IDENTITY_ID_FAILURE = 'GET_PAYMENT_LIST_BY_IDENTITY_ID_FAILURE';
export const GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID = 'GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID';
export const GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID_SUCCESS = 'GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID_SUCCESS';
export const GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID_FAILURE = 'GET_PAYMENT_LIST_BY_IDENTITY_EXTERNAL_ID_FAILURE';
export const GET_PAYMENT_LIST_BY_ACCOUNT_ID = 'GET_PAYMENT_LIST_BY_ACCOUNT_ID';
export const GET_PAYMENT_LIST_BY_ACCOUNT_ID_SUCCESS = 'GET_PAYMENT_LIST_BY_ACCOUNT_ID_SUCCESS';
export const GET_PAYMENT_LIST_BY_ACCOUNT_ID_FAILURE = 'GET_PAYMENT_LIST_BY_ACCOUNT_ID_FAILURE';
export const GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID = 'GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID';
export const GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID_SUCCESS = 'GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID_SUCCESS';
export const GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID_FAILURE = 'GET_PAYMENT_LIST_BY_ACCOUNT_EXTERNAL_ID_FAILURE';
export const GET_PAYMENT_LIST_BY_MERCHANT_ID = 'GET_PAYMENT_LIST_BY_MERCHANT_ID';
export const GET_PAYMENT_LIST_BY_MERCHANT_ID_SUCCESS = 'GET_PAYMENT_LIST_BY_MERCHANT_ID_SUCCESS';
export const GET_PAYMENT_LIST_BY_MERCHANT_ID_FAILURE = 'GET_PAYMENT_LIST_BY_MERCHANT_ID_FAILURE';
/**
 * PAYMENTS END
 */

/**
 * SCHEDULES START
 */
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_LIST = 'GET_SCHEDULE_LIST';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
export const CLEAR_SCHEDULE_LIST = 'CLEAR_SCHEDULE_LIST';

export const PROCESS_SCHEDULE = 'PROCESS_SCHEDULE';
export const PROCESS_SCHEDULE_SUCCESS = 'PROCESS_SCHEDULE_SUCCESS';
export const PROCESS_SCHEDULE_FAILURE = 'PROCESS_SCHEDULE_FAILURE';

export const GET_SCHEDULE_LIST_BY_IDENTITY = 'GET_SCHEDULE_LIST_BY_IDENTITY';
export const GET_SCHEDULE_LIST_BY_IDENTITY_SUCCESS = 'GET_SCHEDULE_LIST_BY_IDENTITY_SUCCESS';
export const GET_SCHEDULE_LIST_BY_IDENTITY_FAILURE = 'GET_SCHEDULE_LIST_BY_IDENTITY_FAILURE';
export const GET_SCHEDULE_LIST_BY_IDENTITY_EXTERNAL = 'GET_SCHEDULE_LIST_BY_IDENTITY_EXTERNAL';
export const GET_SCHEDULE_LIST_BY_IDENTITY_EXTERNAL_SUCCESS = 'GET_SCHEDULE_LIST_BY_IDENTITY_EXTERNAL_SUCCESS';
export const GET_SCHEDULE_LIST_BY_IDENTITY_EXTERNAL_FAILURE = 'GET_SCHEDULE_LIST_BY_IDENTITY_EXTERNAL_FAILURE';
export const GET_SCHEDULE_LIST_BY_ACCOUNT = 'GET_SCHEDULE_LIST_BY_ACCOUNT';
export const GET_SCHEDULE_LIST_BY_ACCOUNT_SUCCESS = 'GET_SCHEDULE_LIST_BY_ACCOUNT_SUCCESS';
export const GET_SCHEDULE_LIST_BY_ACCOUNT_FAILURE = 'GET_SCHEDULE_LIST_BY_ACCOUNT_FAILURE';
export const GET_SCHEDULE_LIST_BY_ACCOUNT_EXTERNAL = 'GET_SCHEDULE_LIST_BY_ACCOUNT_EXTERNAL';
export const GET_SCHEDULE_LIST_BY_ACCOUNT_EXTERNAL_SUCCESS = 'GET_SCHEDULE_LIST_BY_ACCOUNT_EXTERNAL_SUCCESS';
export const GET_SCHEDULE_LIST_BY_ACCOUNT_EXTERNAL_FAILURE = 'GET_SCHEDULE_LIST_BY_ACCOUNT_EXTERNAL_FAILURE';
/**
 * SCHEDULES END
 */

/**
 * EBILLS START
 */
export const GET_EBILL = 'GET_EBILL';
export const GET_EBILL_LIST = 'GET_EBILL_LIST';
export const CLEAR_EBILL = 'CLEAR_EBILL';
export const CLEAR_EBILL_LIST = 'CLEAR_EBILL_LIST';
export const UNDO_EDIT_SCHEDULE = 'UNDO_EDIT_SCHEDULE';

export const EBILL_LIST_BY_IDENTIY = 'EBILL_LIST_BY_IDENTIY';
export const EBILL_LIST_BY_IDENTIY_SUCCESS = 'EBILL_LIST_BY_IDENTIY_SUCCESS';
export const EBILL_LIST_BY_IDENTIY_FAILURE = 'EBILL_LIST_BY_IDENTIY_FAILURE';
export const EBILL_LIST_BY_IDENTITY_EXTERNAL = 'EBILL_LIST_BY_IDENTITY_EXTERNAL';
export const EBILL_LIST_BY_IDENTITY_EXTERNAL_SUCCESS = 'EBILL_LIST_BY_IDENTITY_EXTERNAL_SUCCESS';
export const EBILL_LIST_BY_IDENTITY_EXTERNAL_FAILURE = 'EBILL_LIST_BY_IDENTITY_EXTERNAL_FAILURE';
export const EBILL_LIST_HISTORY_BY_IDENTITY = 'EBILL_LIST_HISTORY_BY_IDENTITY';
export const EBILL_LIST_HISTORY_BY_IDENTITY_SUCCESS = 'EBILL_LIST_HISTORY_BY_IDENTITY_SUCCESS';
export const EBILL_LIST_HISTORY_BY_IDENTITY_FAILURE = 'EBILL_LIST_HISTORY_BY_IDENTITY_FAILURE';
export const EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL = 'EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL';
export const EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL_SUCCESS = 'EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL_SUCCESS';
export const EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL_FAILURE = 'EBILL_LIST_HISTORY_BY_IDENTITY_EXTERNAL_FAILURE';
export const EBILL_LIST_HISTORY_BY_ACCOUNT = 'EBILL_LIST_HISTORY_BY_ACCOUNT';
export const EBILL_LIST_HISTORY_BY_ACCOUNT_SUCCESS = 'EBILL_LIST_HISTORY_BY_ACCOUNT_SUCCESS';
export const EBILL_LIST_HISTORY_BY_ACCOUNT_FAILURE = 'EBILL_LIST_HISTORY_BY_ACCOUNT_FAILURE';
export const EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL = 'EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL';
export const EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL_SUCCESS = 'EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL_SUCCESS';
export const EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL_FAILURE = 'EBILL_LIST_HISTORY_BY_ACCOUNT_EXTERNAL_FAILURE';

/**
 * EBILLS END
 */

/**
 * OTP START
 */
export const OTP_PROCESS_PAYMENT = 'OTP_PROCESS_PAYMENT';
export const OTP_CLEAR_PAYMENT = 'OTP_CLEAR_PAYMENT';
export const OTP_CANCEL_PAYMENT = 'OTP_CANCEL_PAYMENT';
export const OTP_SINGLE_MERCHANT_FLOW = 'OTP_SINGLE_MERCHANT_FLOW';
export const OTP_PAY_ANYONE_FLOW = 'OTP_PAY_ANYONE_FLOW';
/**
 * OTP END
 */


/**
 * REVISIONS ACTIONS START -- NOT USED
 */
export const ADD_REVISION_ITEM = 'ADD_REVISION_ITEM';
export const DELETE_REVISION_ITEM = 'DELETE_REVISION_ITEM';
export const DELETE_REVISION_HISTORY  = 'DELETE_REVISION_HISTORY';


/**
 * INVOICE ACTIONS START -- these may change later when the actual invoice-ms is built
 */
export const SET_INVOICE = 'SET_INVOICE';
export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAILURE = 'GET_INVOICE_LIST_FAILURE';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS';
export const GET_INVOICE_DETAILS_FAILURE = 'GET_INVOICE_DETAILS_FAILURE;'
export const GET_INVOICE_METADATA = 'GET_INVOICE_METADATA';
export const GET_INVOICE_METADATA_SUCCESS = 'GET_INVOICE_METADATA_SUCCESS';
export const GET_INVOICE_METADATA_FAILURE = 'GET_INVOICE_METADATA_FAILURE';
export const GET_INVOICE_LIST_BY_IDENTITY = 'GET_INVOICE_LIST_BY_IDENTITY';
export const GET_INVOICE_LIST_BY_IDENTITY_SUCCESS = 'GET_INVOICE_LIST_BY_IDENTITY_SUCCESS';
export const GET_INVOICE_LIST_BY_IDENTITY_FAILURE = 'GET_INVOICE_LIST_BY_IDENTITY_FAILURE';
export const SET_INVOICE_FILTERS = 'SET_INVOICE_FILTERS';
export const CLEAR_INVOICE_FILTERS = 'CLEAR_INVOICE_FILTERS';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const CLEAR_INVOICE_LIST = 'CLEAR_INVOICE_LIST';
export const GET_INVOICE_DOWNLOAD = 'GET_INVOICE_DOWNLOAD';
export const GET_INVOICE_PENDING_DOWNLOAD = 'GET_INVOICE_PENDING_DOWNLOAD';
export const GET_INVOICE_DOWNLOAD_SUCCESS = 'GET_INVOICE_DOWNLOAD_SUCCESS';
export const GET_INVOICE_DOWNLOAD_FAILURE = 'GET_INVOICE_DOWNLOAD_FAILURE';
export const GET_PAST_DUE_INVOICES = 'GET_PAST_DUE_INVOICES';
export const GET_PAST_DUE_INVOICES_SUCCESS = 'GET_PAST_DUE_INVOICES_SUCCESS';
export const GET_PAST_DUE_INVOICES_FAILURE = 'GET_PAST_DUE_INVOICES_FAILURE';
export const GET_PAST_DUE_PLAN_INVOICES = 'GET_PAST_DUE_PLAN_INVOICES';
export const GET_PAST_DUE_PLAN_INVOICES_SUCCESS = 'GET_PAST_DUE_PLAN_INVOICES_SUCCESS';
export const GET_PAST_DUE_PLAN_INVOICES_FAILURE = 'GET_PAST_DUE_PLAN_INVOICES_FAILURE';
export const GET_PLAN_INVOICE_LIST = 'GET_PLAN_INVOICE_LIST';
export const GET_PLAN_INVOICE_LIST_SUCCESS = 'GET_PLAN_INVOICE_LIST_SUCCESS';
export const GET_PLAN_INVOICE_LIST_FAILURE = 'GET_PLAN_INVOICE_LIST_FAILURE';
export const SET_PLAN_INVOICE_FILTERS = 'SET_PLAN_INVOICE_FILTERS';
export const CLEAR_PLAN_INVOICE_FILTERS = 'CLEAR_PLAN_INVOICE_FILTERS';
export const SET_INVOICE_DETAILS_FILTERS = 'SET_INVOICE_DETAILS_FILTERS';
export const CLEAR_INVOICE_DETAILS_FILTERS = 'CLEAR_INVOICE_DETAILS_FILTERS';
export const CLEAR_INVOICE_DETAILS = 'CLEAR_INVOICE_DETAILS';
export const GET_INVOICES_TOTALS = 'GET_INVOICES_TOTALS';
export const GET_INVOICES_TOTALS_SUCCESS = 'GET_INVOICES_TOTALS_SUCCESS';
export const GET_INVOICES_TOTALS_FAILURE = 'GET_INVOICES_TOTALS_FAILURE';
export const GET_PAYABLE_INVOICES = 'GET_PAYABLE_INVOICES';
export const GET_PAYABLE_INVOICES_SUCCESS = 'GET_PAYABLE_INVOICES_SUCCESS';
export const GET_PAYABLE_INVOICES_FAILURE = 'GET_PAYABLE_INVOICES_FAILURE';
export const GET_ADVANCE_SEARCH_INVOICES = 'GET_ADVANCE_SEARCH_INVOICES';
export const GET_ADVANCE_SEARCH_INVOICES_SUCCESS = 'GET_ADVANCE_SEARCH_INVOICES_SUCCESS';
export const GET_ADVANCE_SEARCH_INVOICES_FAILURE = 'GET_ADVANCE_SEARCH_INVOICES_FAILURE';

/**
 * INVOICE ACTIONS END
 */

/**
 * DISPUTE ACTIONS START
 */
export const GET_DISPUTE_REASONS = 'GET_DISPUTE_REASONS';
export const GET_DISPUTE_REASONS_SUCCESS = 'GET_DISPUTE_REASONS_SUCCESS';
export const GET_DISPUTE_REASONS_FAILURE = 'GET_DISPUTE_REASONS_FAILURE';
export const SUBMIT_DISPUTE = 'SUBMIT_DISPUTE';
export const SUBMIT_DISPUTE_SUCCESS = 'SUBMIT_DISPUTE_SUCCESS';
export const SUBMIT_DISPUTE_FAILURE = 'SUBMIT_DISPUTE_FAILURE';
export const DISPUTE_FREE_FORM = 'DISPUTE_FREE_FORM';
export const DISPUTE_FREE_FORM_SUCCESS = 'DISPUTE_FREE_FORM_SUCCESS';
export const DISPUTE_FREE_FORM_FAILURE = 'DISPUTE_FREE_FORM_FAILURE';
export const GET_DISPUTES = 'GET_DISPUTES';
export const GET_DISPUTES_SUCCESS = 'GET_DISPUTES_SUCCESS';
export const GET_DISPUTES_FAILURE = 'GET_DISPUTES_FAILURE';
export const GET_DISPUTES_BY_IDENTIFIER = 'GET_DISPUTES_BY_IDENTIFIER';
export const GET_DISPUTES_BY_IDENTIFIER_SUCCESS = 'GET_DISPUTES_BY_IDENTIFIER_SUCCESS';
export const GET_DISPUTES_BY_IDENTIFIER_FAILURE = 'GET_DISPUTES_BY_IDENTIFIER_FAILURE';
export const GET_DISPUTE_SUMMARY = 'GET_DISPUTE_SUMMARY';
export const GET_DISPUTE_SUMMARY_SUCCESS = 'GET_DISPUTE_SUMMARY_SUCCESS';
export const GET_DISPUTE_SUMMARY_FAILURE = 'GET_DISPUTE_SUMMARY_FAILURE';

/**
 * DISPUTE ACTIONS END
 */


/**
 * COMPANY ACTIONS START
 */

export const GET_IDENTITY_LIST_BY_COMPANY_ID = 'GET_IDENTITY_LIST_BY_COMPANY_ID';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const REMOVE_USER = 'REMOVE_USER';

/**
 * COMPANY ACTIONS END
 */

/**
 * PAYMENT HISTORY ACTIONS START
 */
export const GET_PAYMENT_HISTORY_LIST = 'GET_PAYMENT_HISTORY_LIST';
export const GET_PAYMENT_HISTORY_LIST_SUCCESS = 'GET_PAYMENT_HISTORY_LIST_SUCCESS';
export const GET_PAYMENT_HISTORY_LIST_FAILURE = 'GET_PAYMENT_HISTORY_LIST_FAILURE';
export const SET_PAYMENT_HISTORY_FILTERS = 'SET_PAYMENT_HISTORY_FILTERS';
export const CLEAR_PAYMENT_HISTORY_FILTERS = 'CLEAR_PAYMENT_HISTORY_FILTERS';

export const GET_PAYMENT_HISTORY_FILTERS = 'GET_PAYMENT_HISTORY_FILTERS';
export const GET_PAYMENT_HISTORY_FILTERS_SUCCESS = 'GET_PAYMENT_HISTORY_FILTERS_SUCCESS';
export const GET_PAYMENT_HISTORY_FILTERS_FAILURE = 'GET_PAYMENT_HISTORY_FILTERS_FAILURE';

/**
 * PAYMENT HISTORY ACTIONS END
 */

/**
 * PAYMENT ACTIVITY ACTIONS START
 */
export const GET_PAYMENT_ACTIVITY_BY_PARAMS = 'GET_PAYMENT_ACTIVITY_BY_PARAMS';
export const GET_PAYMENT_ACTIVITY_BY_PARAMS_SUCCESS = 'GET_PAYMENT_ACTIVITY_BY_PARAMS_SUCCESS';
export const GET_PAYMENT_ACTIVITY_BY_PARAMS_FAILURE = 'GET_PAYMENT_ACTIVITY_BY_PARAMS_FAILURE';

/**
 * PAYMENT ACTIVITY ACTIONS END
 */

/**
 * CHARGEBACK ACTIVITY ACTIONS START
 */
export const GET_CHARGEBACK_ACTIVITY_BY_REFERENCE = 'GET_CHARGEBACK_ACTIVITY_BY_REFERENCE';
export const GET_CHARGEBACK_ACTIVITY_BY_REFERENCE_SUCCESS = 'GET_CHARGEBACK_ACTIVITY_BY_REFERENCE_SUCCESS';
export const GET_CHARGEBACK_ACTIVITY_BY_REFERENCE_FAILURE = 'GET_CHARGEBACK_ACTIVITY_BY_REFERENCE_FAILURE';

/**
 * CHARGEBACK ACTIVITY ACTIONS END
 */

 /**
  * Dashboard Actions Start
  */
 export const GET_LATEST_MESSAGES = 'GET_LATEST_MESSAGES';
 export const GET_LATEST_MESSAGES_SUCCESS = 'GET_LATEST_MESSAGES_SUCCESS';
 export const GET_LATEST_MESSAGES_FAILURE = 'GET_LATEST_MESSAGES_FAILURE';
 export const DELETE_USER_MESSAGE = 'DELETE_USER_MESSAGE';
 export const DELETE_USER_MESSAGE_SUCCESS = 'DELETE_USER_MESSAGE_SUCCESS';
 export const DELETE_USER_MESSAGE_FAILURE = 'DELETE_USER_MESSAGE_FAILURE';

  /**
  * Dashboard Actions End
  */

/**
 * CHATBOT ACTIONS START
 */
 export const UPDATE_CHATBOT_TRACKING = 'UPDATE_CHATBOT_TRACKING';
 
 /**
  * CHATBOT ACTIONS END
  */

/**
 * 3D Secure ACTIONS START
 */
 export const ENTER_3DS = 'ENTER_3DS';
 export const COMPLETE_3DS = 'COMPLETE_3DS';
 
/**
* 3D Secure ACTIONS END
  */

/**
 * WALLET ACTIONS START
 */
 export const SET_3D_SECURE_REDIRECT = 'SET_3D_SECURE_REDIRECT';
 
/**
* WALLET ACTIONS END
*/