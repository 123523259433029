import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody } from "mdbreact";
import PaymentHistoryTable from '../../components/UPS/UPSPaymentHistoryTable';

class PaymentHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonStyle: {
                transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
                opacity: "0"
            }
        };

    }


    render() {
        const { intl } = this.props;
        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.payment-history-breadcrumb-dashboard'} /></Link><span
                                                        aria-hidden="true"> &gt; </span></li>
                                                <li className="ups-breadcrumb-item"><FormattedMessage id={'payment-history.title'} /></li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>

                                <h1 className={"has-breadcrumb"}>
                                    <FormattedMessage id={'payment-history.title'} />
                                </h1>
                                <PaymentHistoryTable caption= "payment-history.title" key={intl.locale} colSize={"12"} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}






function mapStateToProps(state, ownProps) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentHistory));
