import React from 'react';
import classNames from "classnames";
import CardActions from '../CardActions';
import {useIntl} from 'react-intl';
export default ({
    editHandler, removeHandler, makeDefaultHandler, showCardActions, extraCssClass,
    paymentMethod: {
        cardNickName, default: isPrimary,
        email
    },
    permissions
}) =>{
    const intl = useIntl();
return <React.Fragment>
    <div className={classNames("card-container card-paypal", extraCssClass)} role="img" aria-label="PayPal">
        <p className="pm-info card-nickname">{cardNickName}</p>
        <p>{email}</p>
    </div>
    {showCardActions &&
        <CardActions
            editHandler={editHandler}
            removeHandler={removeHandler}
            makeDefaultHandler={makeDefaultHandler}
            isPrimary={isPrimary}
            permissions={permissions}
            cardNickName={cardNickName}
            pmLabel={intl.formatMessage({id: `payment.method.PayPal.having.email.btn.label`},{email: email})}
        />
    }
</React.Fragment>
};
