import React, { Component } from 'react'
import { connect } from "react-redux";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import { PLAN, ACCOUNT, INVOICE_DATE, INVOICE_DUE_DATE, SCHEDULED_PAYMENT, CHANNEL_CODE_EXT, AUTO_PAY, VIEW_CHARGEBACK_ACCOUNT_MULTI } from "../../../../constants/payment-history";
import { stripAccountNumber, getKeyByBU } from "../../../../utils/ups-utils"
import { getInvoiceTypeMessage } from '../../../../utils/invoice-utils'
import { extractAccountNumberFromInvoices, returnInvoiceNumber, returnInvoiceRelatedDates, formatPaymentMethod } from '../../../../utils/payment-history-util'
import ChargebackHistoryTable from "../../ChargebackHistoryTable/ChargebackHistoryTable"

class ViewChargebackHistoryModal extends Component {

    render() {
        const { isOpen, toggleModal, backdrop, data, intl, invoiceMetadata, modalName, businessUnit, businessUnitKeys } = this.props;
        if (!Array.isArray(data) || data.length === 0) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="view-chargeback-history">
                    <MDBModal id="view-chargeback-history-modal" isOpen={isOpen} toggle={() => toggleModal(modalName)} size={"l"} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false}>
                        <MDBRow>
                            <MDBCol size={"12"} lg="12" md={"12"}>
                                    <strong><center>{intl.formatMessage({ id: "datatable.sZeroRecords" })}</center></strong>
                            </MDBCol>
                        </MDBRow>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol size="12" className="modal-button-col">
                                    <MDBBtn color="primary" onClick={() => toggleModal(modalName)}><FormattedMessage id={"btn-close"} /></MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal >
            )
        }
        const chargeBackRecord = data[0];
        let accountNumberAndPlanNumber = '';
        let accountType = '';
        let localInvoice = '';
        if (!chargeBackRecord.isMulti || chargeBackRecord.isPlan) {
            localInvoice = JSON.parse(JSON.stringify(chargeBackRecord.invoices[0]));
            if (chargeBackRecord.isPlan) {
                delete localInvoice.accountNumber;
            }
            accountNumberAndPlanNumber = extractAccountNumberFromInvoices(localInvoice)
            if (accountNumberAndPlanNumber.accountNumber && accountNumberAndPlanNumber.planNumber) {
                accountType = ACCOUNT;
            } else if (accountNumberAndPlanNumber.accountNumber) {
                accountType = ACCOUNT;
            }
            else if (accountNumberAndPlanNumber.planNumber) {
                accountType = PLAN;
            }
        }
        
        if (isOpen) {
            const modalHeading = `${intl.formatMessage({ id: "chargeback.history.title.label" })} ${intl.formatMessage({ id: 'number.symbol' })} ${chargeBackRecord.parentReferenceId}`;
            const multipleLinkLabel = intl.formatMessage({id: businessUnitKeys('moreActionsLabel')}, {id: chargeBackRecord?.referenceId});
           
            return (
                <ReactPortalModal isOpen={isOpen} an_label="view-chargeback-history">
                    <MDBModal id="view-chargeback-history-modal" isOpen={isOpen} toggle={() => toggleModal(modalName)} size={"xxl"} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={modalHeading}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: modalHeading })} toggle={() => toggleModal(modalName)}>{modalHeading}</MDBModalHeader>
                        <MDBModalBody>
                            <div className={"drilldown-summary-container"}>
                                <MDBRow>
                                    {chargeBackRecord?.isMulti &&
                                        <MDBCol size={"6"} lg="3" md={"4"}>
                                            <span className="drilldown-summary-item">
                                                <strong> {intl.formatMessage({ id: `payment-history.history-table.plan_or_account_number.${businessUnit}` })}</strong><br />
                                            </span>
                                            <MDBBtn aria-label={multipleLinkLabel} color={"datatable-text-link"} onClick={() => {
                                                toggleModal(modalName);
                                                toggleModal('viewPayment', data, VIEW_CHARGEBACK_ACCOUNT_MULTI)
                                            }}>
                                                {intl.formatMessage({ id: "payment-history.multiple" })}
                                            </MDBBtn>
                                        </MDBCol>
                                    }
                                    {(accountType === PLAN || (!!accountNumberAndPlanNumber.accountNumber && !!accountNumberAndPlanNumber.planNumber))
                                        && (!chargeBackRecord.isMulti) &&
                                        <MDBCol size={"6"} lg="2" md={"4"}>
                                            <span className="drilldown-summary-item">
                                                <strong> {intl.formatMessage({ id: "view-payment-modal.plan_number" })}</strong><br />{stripAccountNumber(
                                                    !!accountNumberAndPlanNumber.planNumber ? accountNumberAndPlanNumber.planNumber : accountNumberAndPlanNumber.accountNumber,
                                                    accountNumberAndPlanNumber.paymentTypeCode)}
                                            </span>
                                        </MDBCol>}
                                    {accountType === ACCOUNT && !chargeBackRecord.isMulti &&
                                        <MDBCol size={"6"} lg="2" md={"4"}>
                                            <span className="drilldown-summary-item">
                                                <strong>{intl.formatMessage({ id: "view-payment-modal.account_number" })}</strong><br />
                                                {stripAccountNumber(accountNumberAndPlanNumber.accountNumber, accountNumberAndPlanNumber.paymentTypeCode)}
                                            </span>
                                        </MDBCol>}
                                    {
                                        !chargeBackRecord.isMulti && <>
                                        <MDBCol size={"6"} lg="2" md={"4"}>
                                            <span className="drilldown-summary-item"><strong>{intl.formatMessage({ id: `payment-history.history-table.invoice_number.${businessUnit}` })}</strong><br />{returnInvoiceNumber(localInvoice)}</span>
                                        </MDBCol>
                                        <MDBCol size={"6"} lg="2" md={"4"}>
                                            <span className="drilldown-summary-item"><strong>{intl.formatMessage({ id: "view-payment-modal.invoice_type" })}</strong><br />{getInvoiceTypeMessage(intl, invoiceMetadata, localInvoice.invoiceType)}</span>
                                        </MDBCol>
                                        <MDBCol size={"6"} lg="2" md={"4"}>
                                            <span className="drilldown-summary-item"><strong>{intl.formatMessage({ id: `view-payment-modal.invoice_date.${businessUnit}` })}</strong><br />{returnInvoiceRelatedDates(intl, chargeBackRecord, INVOICE_DATE)}</span>
                                        </MDBCol>
                                        <MDBCol size={"6"} lg="2" md={"4"}>
                                            <span className="drilldown-summary-item"><strong>{intl.formatMessage({ id: `view-payment-modal.invoice_due_date.${businessUnit}` })}</strong><br />{returnInvoiceRelatedDates(intl, chargeBackRecord, INVOICE_DUE_DATE)}</span>
                                        </MDBCol>
                                        </>
                                    }
                                    <MDBCol size={"6"} lg={ !chargeBackRecord.isMulti ? "2" : "3"} md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({ id: "payment-history.history-table.schedule" })}</strong><br />{chargeBackRecord.channel === SCHEDULED_PAYMENT || (chargeBackRecord.channel === CHANNEL_CODE_EXT && chargeBackRecord.executionTypeCode === AUTO_PAY) ? intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' }) : intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })}</span>
                                    </MDBCol>
                                    <MDBCol size={"6"} lg={ !chargeBackRecord.isMulti ? "2" : "3"} md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({ id: "payment-history.history-table.payment_method" })}</strong><br />{formatPaymentMethod(chargeBackRecord.paymentMethod, intl, chargeBackRecord.channel)}</span>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <MDBRow>
                                <MDBCol size={"12"} className={"mt-4"}>
                                    <ChargebackHistoryTable caption= "chargeback.history.title.label" key={"chargeback-history"} paymentList={data} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol size="12" className="modal-button-col">
                                    <MDBBtn color="primary" onClick={() => toggleModal(modalName)}><FormattedMessage id={"btn-close"} /></MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        businessUnit: state.auth.user.selectedPaymentType,
        businessUnitKeys: getKeyByBU('chargebackTable')(state.auth.user.selectedPaymentType)
    }
}


export default injectIntl(connect(
    mapStateToProps
)(ViewChargebackHistoryModal));

