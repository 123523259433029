import React, {useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import { MDBCard, MDBCardBody, MDBCol, MDBCollapse, MDBCollapseHeader, MDBIcon, MDBRow } from "mdbreact";
import { getLabel, getTranslatedOption } from "../../../../utils/invoice-utils";
import * as invoiceApi from "../../../../api/invoice-api";
import DateRangePicker from "./components/DateRangePicker";
import {
  DropdownOptionV2,
  MultiSelectOptionV2,
  TypeAheadOptionV2,
  TextInputOption,
} from "../../../../components/InvoiceDetails/FilterOptions/index";
import {useSelector} from "react-redux";
import { get as lodashGet, filter as lodashFilter, reject as lodashReject } from "lodash";
import { stripAccountNumber } from "../../../../utils/ups-utils";

const getInvoiceLiterals = ({apiUrlPrefix, apiToken}, locale, setInvoiceLiterals, setLoaded) => {
  const language = (locale || "").toUpperCase().split(/[_-]+/)[0]
  invoiceApi.getInvoiceLiterals(
      language,
      apiUrlPrefix,
      apiToken
  ).then(
      ({parsedBody: invoiceLiterals})=>setInvoiceLiterals(invoiceLiterals ?? {})
  ).finally(
      ()=>setLoaded(true)
  )
}

const filterTypes = () => ({
  dropdown: (filter, searchType, dateFilterPosition, data)=><DropdownOptionV2 key={`${filter.field}.${searchType}`} {...data} />,
  "multi-select": (filter, searchType, dateFilterPosition, data)=><MultiSelectOptionV2 key={`${filter.field}.${searchType}`} {...data} />,
  text: (filter, searchType, dateFilterPosition, data)=><TextInputOption key={`${filter.field}.${searchType}`} {...data} />,
  "type-ahead": (filter, searchType, dateFilterPosition, data)=><TypeAheadOptionV2 key={`${filter.field}.${searchType}`} {...data} />,
  date: (filter, searchType, dateFilterPosition, data)=><MDBCol key={`${filter.field}.${searchType}`} className={`col-12 col-md-4 col-lg-3 dateFilterPosition-${dateFilterPosition}`}>
          <DateRangePicker key={`${filter.field}.${searchType}`} {...data} />
        </MDBCol>
})

const renderFilters = (intl, props, selectedPaymentType) => {
  const {
    filters,
    searchType,
    filtersCache,
    setIsFiltersClean,
    isFiltersClean,
    invoiceLiterals,
    queryParams
  } = props;
  const existingFilters = filtersCache ? (filtersCache[searchType] ? filtersCache[searchType] : {}) : {};
  const mapFilterType = filterTypes()
  const paramsCopy = { ...queryParams };
  if (Array.isArray(paramsCopy?.accountNumber) && paramsCopy?.accountNumber.length > 0){
    const businessUnit = selectedPaymentType ? selectedPaymentType : "EBS"
    paramsCopy.accountNumber = paramsCopy.accountNumber.map(({accountNumber}) => stripAccountNumber(accountNumber, businessUnit));
  }
  const returnOptions = (filter, index) => {
    let data = {
      ...filter,
      onUpdateFilters: onUpdateFilters(props),
      intl,
      invoiceLiterals,
      searchType,
      existingFilters,
      isFiltersClean,
      setIsFiltersClean,
      queryParams: paramsCopy || {},
      formatLabel: getLabel.bind({ intl, invoiceLiterals }),
      formatOption: getTranslatedOption.bind({ intl, invoiceLiterals }),
      colClassName: "col-12 col-md-4 col-lg-3",
    };
    const dateFilterPosition = (index + 1) % 4;
    return mapFilterType[filter.type](filter, searchType, dateFilterPosition, data)
  };
  const hasOverflowOptions = (filter) => !filter.overflow;
  let nonOverflowOptions = lodashFilter(filters, hasOverflowOptions).map((filter, index) =>
      returnOptions(filter, index)
  );
  let overflowOptions = lodashReject(filters, hasOverflowOptions).map((filter, index) =>
      returnOptions(filter, index)
  );

  return {nonOverflowOptions, overflowOptions}
}

const onUpdateFilters = (props)=>(field, value, filter) => {
  const { updateQueryParams, queryParams, originalAccountsList } = props;
  const updateExistingFilters = { ...queryParams };
  if (Array.isArray(value) && Array.isArray(value[0])) {
    updateExistingFilters[field] = value[0];
  } else if (lodashGet(filter, "type") === "date") {
    updateExistingFilters[`${field}Begin`] = value.startDate;
    updateExistingFilters[`${field}End`] = value.endDate;
  } else if (lodashGet(filter, "hasValueCode")) {
    updateExistingFilters[field] = value[0];
  } else if (lodashGet(filter, "delimiter")) {
    updateExistingFilters[field] = value.split(lodashGet(filter, "delimiter"));
  } else {
    if (field === "accountNumber"){
      if (value){
        updateExistingFilters[field] = originalAccountsList.filter(acc => acc.accountNumber === value[0]);
      } else {
        updateExistingFilters[field] = value;
      }
    } else {
      updateExistingFilters[field] = value;
    }
  }

  updateQueryParams(updateExistingFilters)
};

const FilterList = (props) => {
  const {setInvoiceLiterals, invoiceLiterals, searchType, setLoaded} = props;
  const intl = useIntl()
  const envProps = useSelector((s) => s.envProps);
  const {locale, selectedPaymentType} = useSelector((s) => s.auth.user);
  const [collapse, setCollapse] = useState(false)

  useEffect(()=>{
    getInvoiceLiterals(envProps, locale, setInvoiceLiterals, setLoaded)
  },[])

  const {nonOverflowOptions, overflowOptions} = invoiceLiterals ? renderFilters(intl, props, selectedPaymentType) : {}

    return (
      <React.Fragment>
        {invoiceLiterals && (
          <div id="advancedSearch">
            <MDBRow>{nonOverflowOptions}</MDBRow>
            {searchType === "invoice.common-search.detail" && (
              <MDBRow>
                <MDBCol size={"12"}>
                  <div className="md-accordion">
                    <MDBCard className="mb-3">
                      <label aria-live="polite" className="sr-only">
                        {intl.formatMessage({ id: `ups.show-more-options.${collapse ? "collapsed" : "expanded"}`})}
                      </label>
                      <MDBCollapseHeader tabIndex={0} onClick={()=>setCollapse(!collapse)}>
                        <FormattedMessage id={"invoice.search-shoe-more-options"} />
                        <MDBIcon icon={collapse ? "chevron-up" : "chevron-down"} />
                      </MDBCollapseHeader>
                      <MDBCollapse id="collapse1" isOpen={collapse}>
                        <MDBCardBody className={"px-0"}>
                          <MDBRow>{overflowOptions}</MDBRow>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
          </div>
        )}
      </React.Fragment>
    )
}

export default FilterList;
