import React from 'react';
import {MDBRow, MDBCol} from 'mdbreact';
import {useIntl} from 'react-intl';

import Details from '../../pages/UPSPayInvoice/Details';
import SepaMandatePreview from './SepaMandatePreview';
import CheckboxWithValidation from '../CheckboxWithValidation';

export default function SepaMandate({payment, schedule, onSepaMandateLoad, onChange, onNext, onBack, onError}) {
    const intl = useIntl();

    const acceptedSepaMandate = payment?.sepaMandate?.authorizeSepa
                              || schedule?.sepaMandate?.authorizeSepa
                              || false;

    return (
        <Details
            onSubmit={onNext}
            onCancel={onBack}
            submitLabel='make-payment.btn.continue'
            cancelLabel='ups.btn.back.label'
            canProceed={acceptedSepaMandate}
        >
            <MDBRow className='sepa-mandate-container'>
                <SepaMandatePreview payment={payment}
                                    schedule={schedule}
                                    onSepaMandateLoad={onSepaMandateLoad}
                                    onError={onError}
                />

                <MDBCol size='12'>
                    <CheckboxWithValidation
                        id='authorizeSepa'
                        name='authorizeSepa'
                        label={intl.formatMessage({
                            id: 'sepa-mandate.sign.otp.approval'
                        })}
                        value={acceptedSepaMandate}
                        onChange={({target: {checked}}) => onChange(checked)}
                        validations={[
                            ['required', 'sepa-mandate.sign.required']
                        ]}
                    />
                </MDBCol>
            </MDBRow>
        </Details>
    );
}
