import React from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow} from "mdbreact";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

export default ({form, isModal, onSubmit, onCancel, extraFooterCssClass, canProceed = true}) =>
    <React.Fragment>
        <section id="pmDetailsSection" className={classNames({"ups-enroll-subsection mb-4": !isModal})}>
            <MDBCard>
                <MDBCardBody className="p-0">{form}</MDBCardBody>
            </MDBCard>
        </section>
        <section className={classNames({"ups-section ups-btn-section": true, "mb-4": !isModal}, extraFooterCssClass)}>
            <MDBRow className="mb-1">
                <MDBCol size="12" className="mt-3 mt-sm-0 text-center">
                    <MDBBtn 
                        color="primary" 
                        id="pm-details-btn-submit" 
                        disabled={!canProceed}
                        onClick={canProceed ? onSubmit : null}>
                        <FormattedMessage id="ups.btn.submit.label"/>
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
            <MDBRow className="mb-1">
                <MDBCol size="12" className="mt-3 mt-sm-0 text-center">
                    <MDBBtn id="pm-details-btn-cancel" flat className={classNames({"btn-flat-link": true})}
                            onClick={onCancel}>
                        <FormattedMessage id="ups.btn.cancel.label"/>
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        </section>
    </React.Fragment>;
