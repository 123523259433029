import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {MDBModal, MDBModalBody, MDBModalHeader, MDBBtn, MDBModalFooter, MDBRow, MDBCol, MDBTable, MDBTableBody} from "mdbreact";
import ReactPortalModal from "../../../ReactPortalModal";
import InputFieldIntl from '../../../../components/InputFieldIntl';
import * as disputeActions from '../../../../actions/dispute-action';
import * as validationActions from "../../../../actions/validation-action";
import attachValidator from "../../../../utils/validation/attach-validator";
import {stripAccountNumber} from "../../../../utils/ups-utils";
import {getStatusCodeLabel, DISPUTE_DENIED, renderRefundAmountTableRow, mapErrCode} from "../../../../utils/dispute-utils";
import {zip as lodashZip, get as lodashGet} from "lodash";
import supportLinks from "../../../../constants/support-links";
const path = require('path');


class RequestServiceRefundModal extends React.Component {

    constructor(props) {
        super(props);
        const countryCode = lodashGet(this.props,'user.selectedCountry','US');
        
        this.state = {
            trackingNumber: '',
            dispute: null,
            submitError:false,
            errorMsgId:'',
            upsTariffUrl: '',
            isInternational : !(countryCode === 'US' || countryCode === 'CA')
        };

        attachValidator.call(this);
    }

    hideModal = () => this.props.toggleModal('requestServiceRefund');

    onSubmit = () => {
        const { user, disputeActions } = this.props;
        const { trackingNumber } = this.state;

        const { messages } = this.validator.validateAll();
        if (messages.length > 0) return;

        const businessUnit = user.selectedPaymentType;
        const reasonCode = ["US","CA","PR"].includes(user.selectedCountry) ? "631" : "5G";
        const disputeData = {
            businessUnit,
            trackingNbr: trackingNumber,
            accountNbr: user.accounts[0].accountNumber,
            applicationId: 'UBP',
            reasonCode,
            countryCode: user.selectedCountry, 
        };

        disputeActions.disputeFreeForm(businessUnit, [disputeData], this.handleResponse);
    }

    onBlur = () => {
        this.validator.validateAll();
    }

    handleResponse = (response) => {
        const {intl, onSuccess} = this.props;
        if(response.status && response.status!==200){
            const errorMsgId = lodashGet(response,'completeError.message',`${intl.formatMessage({ id: 'dispute.error.request-service-fund' })}` ) ;
            this.setState({
                submitError:true,
                errorMsgId
            }, this.focusHeaderElement)
        }else{
            const {responseItems, disputes} = response;
            const dispute = lodashGet(responseItems, '[0]', {});
            this.setState({ dispute }, () => {
                this.focusHeaderElement(); 
                onSuccess();
            });
        }
    };

    //set focus on top for proper keyboard navigation
    focusHeaderElement = () => {
        document.getElementsByClassName("close")[0]?.focus();
    }

    getTariffUrl(){
        const {user, userInfo, countryLocale} = this.props;
        const {locale, selectedCountry} = user || userInfo || {};

        let language = locale ? locale.split("-")[0] : null;
        let country = selectedCountry ? selectedCountry.toLowerCase() : null;

        if(countryLocale){
            let arr = countryLocale.split("-");
            language = arr[0];
            country = arr[1].toLowerCase();
        }

        if(language && country){
            const {upsBaseUrl, upsTariffResource} = supportLinks;
            const tariffUrl = upsBaseUrl + path.join(country, language, upsTariffResource);
            this.setState({
                upsTariffUrl : tariffUrl
            });
        }
    }

    componentDidMount(){
        this.getTariffUrl();
    }

    render() {
        const { intl, isOpen,invoiceMetadata:{disputeRules:{errorCodes}}, merchant } = this.props;
        const { dispute, trackingNumber, submitError,errorMsgId, upsTariffUrl,isInternational} = this.state;
        const disputeErrMessageArr = dispute && mapErrCode({ errorCodes: dispute?.disputeMsgCode, errorMsg: dispute?.disputeMsg }, errorCodes, intl)?.split(";");
        const modalHeading = "invoice.dispute.request-service-refund.title";

        return (
            <ReactPortalModal isOpen={isOpen} an_label="request-service-refund">
                <MDBModal isOpen={isOpen} toggle={this.hideModal} size="lg" backdrop={true} centered disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={this.hideModal}><FormattedMessage id={modalHeading} /></MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                {dispute === null && !submitError &&
                                    <React.Fragment>
                                        <FormattedMessage
                                            tagName={'p'} 
                                            id={'invoice.dispute.request-service-refund.help-msg-1.text'} 
                                            values={{
                                                textlink: msg => (
                                                    <a role='link' title={intl.formatMessage({id:"ups.new-tab.link.label"})} href={upsTariffUrl} aria-label={intl.formatMessage({id: "ups.refund-criteria.learn-more.aria-label"})} target={"_blank"} className={"text-blue p-0"}>{msg} <span className="material-icons font-size-body align-middle">open_in_new</span></a>
                                                )
                                            }} 
                                        />
                                        <FormattedMessage tagName={'p'} id={'invoice.dispute.request-service-refund.help-msg-2.text'} />
                                        <FormattedMessage
                                            tagName={'p'}
                                            id={'invoice.dispute.request-service-refund.help-msg-3.text'}
                                            values={{
                                                style: msg => (
                                                    <strong>{msg}</strong>
                                                ),
                                                sup: msg => (
                                                    <sup>{msg}</sup>
                                                )
                                            }}
                                        />
                                        <p className="ups-note-1 mb-0 pb-2 font-italic"><FormattedMessage id={'invoice.dispute.request-service-refund.help-msg-4.text'} /></p>
                                            <InputFieldIntl
                                                label={<FormattedMessage id={"invoice.dispute.request-service-refund.tracking-number.label"} />}
                                                name="trackingNumber"
                                                id={"request-service-refund-tracking-umber"}
                                                hint='invoice.dispute.request-service-refund.tracking-number.label'
                                                value={trackingNumber}
                                                maxLength={18}
                                                onChange={(e) => this.setState({ trackingNumber: e.target.value })}
                                                required
                                                validations={[
                                                    ['required', 'field.required'],
                                                    ['regexp', 'dispute.trackingNumber.invalid', { regexps: /\b1Z[a-zA-Z0-9]{16}\b|\b[0-9]{11}\b/ }]
                                                ]}
                                                onBlur={this.onBlur}
                                                containerClass={"mt-4"}
                                            />

                                        <FormattedMessage tagName={'p'} id={'invoice.dispute.request-service-refund.help-msg-5.text'} />
                                    </React.Fragment>
                                }
                                {dispute && !submitError &&
                                    <React.Fragment>
                                        <FormattedMessage
                                            tagName={'p'} 
                                            id="invoice.dispute.request-service-refund.success.msg.text" 
                                            values={{
                                                textlink: msg => (
                                                    <a role='link' href="/ups/billing/dispute" target={"_blank"} className={"text-blue p-0"}>{msg}</a>
                                                )
                                            }} 
                                        />
                                        <MDBTable className={"table-two-col"}>
                                            <MDBTableBody>
                                                <tr>
                                                    <th>{intl.formatMessage({ id: 'invoice.disputes.submission.label'})}</th>
                                                    <td>
                                                        <p className="mb-0">{dispute.disputeId}</p>
                                                    </td>
                                                </tr>
                                                { dispute.reqStatus !== DISPUTE_DENIED &&
                                                    <tr>
                                                        <th>{intl.formatMessage({ id: 'invoice.disputes.refund-method.label' })}</th>
                                                        <td>{
                                                            isInternational ?
                                                            intl.formatMessage({ id: "invoice.disputes.issue-credit-note.label" }) :
                                                            intl.formatMessage({ id: "invoice.disputes.credit-next-invoice.label" })}
                                                        </td>
                                                    </tr>
                                                }
                                                { dispute.reqStatus !== DISPUTE_DENIED && renderRefundAmountTableRow(intl, dispute.refundAmount, dispute.refundCurrency, merchant.currencyDisplay)}
                                                <tr>
                                                    <th>{intl.formatMessage({ id: 'invoice.disputes.response-status.label' })}</th>
                                                    <td>{getStatusCodeLabel(intl)[dispute.reqStatus]}</td>
                                                </tr>
                                                {dispute.disputeMsgCode && dispute.disputeMsg &&
                                                    <tr>
                                                        <th>{intl.formatMessage({ id: 'invoice.disputes.response-message.label' })}</th>
                                                        <td className={"text-left"}>
                                                            <ul role="alert" className="dispute-msg-error">
                                                                {
                                                                    disputeErrMessageArr?.map(msg=>{
                                                                        return <li>{msg}</li> 
                                                                    })
                                                                }
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                    </React.Fragment>
                                }
                                {submitError &&
                                    <div role="alert">
                                        <p>
                                            <FormattedMessage id="invoice.disputes.problem-submiting-dispute" />
                                        </p>
                                        <p className="dispute-msg-error">
                                        {intl.formatMessage({ id: errorMsgId })}
                                        </p>
                                    </div>
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {!dispute && !submitError &&
                            <React.Fragment>
                                <MDBBtn color="primary" onClick={this.onSubmit}><FormattedMessage id="invoice.dispute.request-service-refund.btn-submit.label" /></MDBBtn>
                                <MDBBtn color="secondary" onClick={this.hideModal}><FormattedMessage id="invoice.dispute.request-service-refund.btn-cancel.label" /></MDBBtn>
                            </React.Fragment>
                        }
                        {dispute && !submitError &&
                            <MDBBtn color="secondary" onClick={this.hideModal}><FormattedMessage id="invoice.dispute.request-service-refund.btn-close.label" /></MDBBtn>
                        }
                        {submitError &&
                            <MDBBtn color="secondary" onClick={this.hideModal}><FormattedMessage id="invoice.dispute.request-service-refund.btn-close.label" /></MDBBtn>
                        }
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        invoiceMetadata: state.invoice.invoiceMetadata,
        userInfo: state.auth.userInfo,
        countryLocale :  state.settings.countryLocale,
        merchant: state.config.sessionSelection.merchant
    }
}


function mapDispatchToProps(dispatch) {
    return {
        validationActions: bindActionCreators(validationActions, dispatch),
        disputeActions: bindActionCreators(disputeActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RequestServiceRefundModal));