import React,  { Component } from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol  } from 'mdbreact';
import { FormattedMessage, FormattedDate, injectIntl  } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";

class SaveSearchSettingsModal extends Component {

    // handleInvoiceSearch = () => {
    //     const { invoiceActions, toggleModal, didSave } = this.props;
    //     invoiceActions.getInvoiceListByIdentity("12345");
    //     toggleModal('invoiceSearchCriteria')
    // };

    render() {
        const { isOpen, toggleModal, backdrop, intl } = this.props;
        const modalHeading = "invoice.search-settings.title";

        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="save-search-settings">
                    <MDBModal isOpen={isOpen} toggle={() => toggleModal('saveSearchSettings')} size="lg" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('saveSearchSettings')}><FormattedMessage id={modalHeading} /></MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <p><FormattedMessage id="invoice.search-settings.message-line-1" /></p>
                                    <p><FormattedMessage id="invoice.search-settings.message-line-2" /></p>
                                    <p><FormattedMessage id="invoice.search-settings.message-line-3" /></p>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            {/*<MDBBtn color="primary" onClick={this.handleDispute}>Dispute</MDBBtn>*/}
                            <MDBBtn color="primary" onClick={() => toggleModal('saveSearchSettings')}><FormattedMessage id="invoice.search-settings.btn-save.label" /></MDBBtn>
                            <MDBBtn color="secondary" onClick={() => toggleModal('saveSearchSettings')}><FormattedMessage id="invoice.search-settings.btn-skip.label" /></MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

export default injectIntl(SaveSearchSettingsModal);
