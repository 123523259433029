import React, { Component } from 'react';
import { getReferenceNumber } from '../../../utils/payment-history-util'
import { injectIntl } from 'react-intl';
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import memoize from "memoize-one";
import {columnParsers} from "./PaymentAccountHistoryTable-utils";
import {SORTED_DSC} from "../../DataTableV3/DataTableV3-utils";

class PaymentAccountHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            tableState: INITIAL_TABLE_STATE
        };
    }

    hasMultiInvoicePaymentInList = (paymentListKeys) => {
        let isMultiInvoicePayment = paymentListKeys.find(payment => payment.isMulti === true);
        return !!isMultiInvoicePayment;
    }

    getColumns = memoize(() => {
        const {intl} = this.props;
        const parsers = columnParsers(intl);
        return [
            {
                field: 'confirmationNumber',
                label: intl.formatMessage({ id: 'payment-history.history-table.confirmation_number' }),
                className: 'datatable-relative-col',
                display: (rowData) => (rowData?.isMulti) ? <span>{`${getReferenceNumber(rowData)} *`}</span> : getReferenceNumber(rowData),
                serialize: (rowData)=>`${getReferenceNumber(rowData)}`,
                sortFunc: (rowData)=>`${getReferenceNumber(rowData)}`
            },
            {
                field: 'schedule',
                label: intl.formatMessage({ id: 'payment-history.history-table.schedule' }),
                display: parsers.schedule,
                serialize: parsers.schedule,
                sortFunc: parsers.schedule
            },
            {
                field: 'paymentAmount',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_amount' }),
                display: parsers.paymentAmount,
                serialize: parsers.paymentAmount,
                sortFunc: ({totalAmount=''})=>totalAmount,
                thClassName: 'text-right',
                tdClassName: 'text-right'
            },
            {
                field: 'paymentMethod',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_method' }),
                display: parsers.paymentMethod,
                serialize: parsers.paymentMethod,
                sortFunc: parsers.paymentMethod,
                thClassName: 'text-right',
                tdClassName: 'text-right'
            },
            {
                field: 'paymentStatus',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_status' }),
                display: parsers.paymentStatus,
                serialize: parsers.paymentStatus,
                sortFunc: parsers.paymentStatus,
                mobileHidden: true
            },
            {
                field: 'paymentDate',
                label: intl.formatMessage({ id: 'payment-history.history-table.payment_date' }),
                display: parsers.paymentDate,
                serialize: parsers.paymentDate,
                sortFunc:({paymentDate=''})=>paymentDate,
                mobileCardPrimary: true,
                mobileDisplay: (rowData)=>`${parsers.paymentStatus(rowData)} – ${parsers.paymentDate(rowData)}`
            }
        ];
    })

    render() {
        const { tableState } = this.state;
        const { intl, paymentList, caption } = this.props;
        const columns = this.getColumns(intl.locale)

        return <>
            <FilteredDataTableV3
            caption={caption}
                data={paymentList}
                columns={columns}
                searchable
                mobileCard
                tableState={tableState}
                tableStateAction={(action)=>this.setState(action)}
                itemsPerPage={[5,10,20,50,100]}
                defaultSorting={'paymentDate'}
                defaultSortDirection={SORTED_DSC}
                rowHeaderField={'confirmationNumber'}
            />
            {(this.hasMultiInvoicePaymentInList(paymentList)) &&
                <span class="ups-payment-account-history-multipay">
                    <span class="font-italic">{`* ${intl.formatMessage({ id: 'ups.payment.account.history.multipay'})}`}</span>
                </span>
            }
        </>
    }

}

export default injectIntl(PaymentAccountHistoryTable);
