import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../../actions/invoice-action";
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane } from "mdbreact";
import ScreenBuilder from '../ScreenBuilder';
import {getMessageByBU} from "../../../utils/ups-utils";

class TabsScreen extends React.Component {

    constructor(props) {
        super();

        const selectedTab =  props.screenConfig.tabs[0].name

        this.state = {
            selectedTab,
            loadedTabs: {
                [selectedTab]: false
            },
        };
    }

    componentDidMount(){
        const {isTopLevel} = this.props;
        if(isTopLevel){
            this.setPageTitle()
        }
    }

    setPageTitle(){
        const {selectedTab} = this.state
        const {invoice, intl, screenConfig, businessUnitMessages} = this.props
        const {tabs} = screenConfig
        const currentTab = tabs.find((tab)=>(tab.name === selectedTab))
        document.title = `${businessUnitMessages('pageTitle')} - ${invoice.invoiceNumber} - ${intl.formatMessage({id:currentTab.msgId})}`
    }

    handleKeyPress(el, index) {
        const { screenConfig: { tabs } } = this.props;
        let tabName = "";
        
        if (el.keyCode === 39) {
            if(!tabs[index + 1]){
                index  = -1;
            }
            tabName = tabs[index + 1].name;
            (document.getElementById(`tab-${tabName}`)).focus();
            this.switchTab(tabName);
        }
        if (el.keyCode === 37) {
            if(!tabs[index - 1]){
                index  = tabs.length;
            }

            tabName = tabs[index - 1].name;
            (document.getElementById(`tab-${tabName}`)).focus();
            this.switchTab(tabName);
        }
    }

    switchTab = (tabName) => {
        this.setState(prevState => {
            return {
                selectedTab: tabName,
                loadedTabs: {
                    ...prevState.loadedTabs,
                    [tabName]: true
                }
            };
        }, () => {
            if(this.props.isTopLevel) this.setPageTitle()
        });
    }

    isTabActive = (tabName) => this.state.selectedTab === tabName;

    isTabLoaded = (tabName) => this.state.loadedTabs[tabName] === true;

    render() {
        const { screenConfig, invoiceMetadata, invoice, invoiceLiterals } = this.props;
        const { selectedTab } = this.state;
        const { tabs } = screenConfig;

        return (
            <div className={'nav-tabs-container'}>
                <div className={"nav-tabs"}>
                    <MDBNav classicTabs color={'primary'}>
                        {tabs.map((tab,i) => (
                            <MDBNavItem key={`MdbNavItem-${tab.name}`}>
                                <MDBNavLink
                                    id={`tab-${tab.name}`}
                                    link
                                    to='#'
                                    role={'tab'}
                                    aria-selected={this.isTabActive(tab.name)}
                                    active={this.isTabActive(tab.name)}
                                    onClick={e => { e.preventDefault(); this.switchTab(tab.name); }}
                                    tabIndex={this.isTabActive(tab.name) ? "0" : "-1"}
                                    onKeyDown={el => this.handleKeyPress(el,i)}
                                >
                                    <FormattedMessage id={tab.msgId} />
                                </MDBNavLink>
                            </MDBNavItem>
                        ))}
                    </MDBNav>
                </div>
                <MDBTabContent 
                    className={'invoice-tab-content border-top'}
                    activeItem={selectedTab}
                >
                    {tabs && tabs.map(tab => (
                        <MDBTabPane key={`MDBTabPane-${tab.name}`} tabId={tab.name}>
                            {this.isTabActive(tab.name) &&
                                <ScreenBuilder key={`screen-builder-${tab.screen}`} invoice={invoice} screen={tab.screen} screenConfig={invoiceMetadata.screens[tab.screen]} invoiceLiterals={invoiceLiterals} />
                            }
                        </MDBTabPane>
                    ))}
                </MDBTabContent>
            </div>
        );
    }
}

function mapStateToProps(state, {intl}) {
    return {
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        businessUnitMessages: getMessageByBU('invoiceDetails')(intl)(state.auth.user.selectedPaymentType)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TabsScreen));