import React, { Component } from 'react'
import { connect } from "react-redux";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import {PLAN} from "../../../../constants/payment-history";
import {stripAccountNumber} from "../../../../utils/ups-utils"
import { formatCurrency, formatInvoiceDate, getInvoiceTypeMessage } from '../../../../utils/invoice-utils'
import { isEmpty } from 'lodash';
import classNames from "classnames";
import PaymentAccountHistoryTable from "../../PaymentAccountHistoryTable/PaymentAccountHistoryTable"

class ViewAccountPaymentHistoryModal extends Component {

    render() {
        const { isOpen, toggleModal, backdrop, data, intl, invoiceMetadata, selectedPaymentType, merchant } = this.props;
        const modalHeading = `${intl.formatMessage({ id: "invoice.payment-activity.title.label" })} ${data.invoiceType === PLAN ? intl.formatMessage({ id: 'payment-history.PLAN' }) : intl.formatMessage({ id: 'invoice.payment-activity.header.invoice.label' })} ${intl.formatMessage({ id: 'number.symbol' })}${data.invoiceNumber}`;
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="view-account-payment-history">
                    <MDBModal id="view-account-payment-history-modal" isOpen={isOpen} toggle={() => toggleModal('viewAccountPaymentHistory')} size={"xxl"} backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={modalHeading}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: modalHeading })} toggle={() => toggleModal('viewAccountPaymentHistory')}>{modalHeading}</MDBModalHeader>
                        <MDBModalBody>
                            <div className={"drilldown-summary-container"}>
                                <MDBRow>
                                    <MDBCol size={"6"} lg="2" md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({id:"invoice.search-field.account-number.label"})}</strong><br />{stripAccountNumber(data.accountNumber, selectedPaymentType)}</span>
                                    </MDBCol>
                                    <MDBCol size={"6"} lg="2" md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({id:"invoice.summary-date.label"})}</strong><br />{formatInvoiceDate(intl, data.invoiceDate)}</span>
                                    </MDBCol>
                                    <MDBCol size={"6"} lg="2" md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({id:"invoice.summary-amount-due.label"})}</strong><br />{formatCurrency(intl, data.outstandingAmount, data.currencyCode, merchant.currencyDisplay)}</span>
                                    </MDBCol>
                                    <MDBCol size={"6"} lg="2" md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({id:"invoice.search-field.due-date.label"})}</strong><br />{formatInvoiceDate(intl, data.dueDate)}</span>
                                    </MDBCol>
                                    <MDBCol size={"6"} lg="2" md={"4"}>
                                        <span className="drilldown-summary-item"><strong>{intl.formatMessage({id:"invoice.search-field.invoice-type.label"})}</strong><br />{getInvoiceTypeMessage(intl, invoiceMetadata, data.invoiceType) }</span>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <MDBRow>
                                <MDBCol size={"12"} className={"mt-4"}>
                                    <PaymentAccountHistoryTable caption= "invoice.payment-activity.title.label" key={"payment-account-history"} paymentList={data.history} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol size="12" className="modal-button-col">
                                    <MDBBtn color="primary" onClick={() => toggleModal('viewAccountPaymentHistory')}><FormattedMessage id={"btn-close"} /></MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata,
        selectedPaymentType: state.auth.user.selectedPaymentType,
        merchant: state.config.sessionSelection.merchant
    }
}


export default injectIntl(connect(
    mapStateToProps
)(ViewAccountPaymentHistoryModal));

