/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/6/19 12:23 PM
 * Created by kxian
 *
 */

import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as accountApi from '../api/account-api';
import * as accountActions from '../actions/account-action';
import * as identityActions from '../actions/identity-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';
import * as errorActions from '../actions/error-action';
import {countryCodeToMerchantId} from "../utils/utils";
import * as settingActions from "../actions/setting-action";
import {checkPlanEnrolled} from "../utils/ups-utils";

const getEnvProps = (state) => state.envProps;
const getConfig = (state) => state.config;
const getUser = (state) => state.auth.user;
const getAccounts = (state) => state.accounts;


function* validateAccountEligibility(action) {
    try {
        yield put(errorActions.clearErrors());
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const {apiUrlPrefix, apiToken, simulateApi} = envProps;
        const {data} = action;
        const response = yield call(accountApi.validateAccountEligibility, data, apiUrlPrefix, simulateApi);
        const {ok, parsedBody} = response;

        if (ok && parsedBody) {
            const {validationStatus, hid} = parsedBody;
            if (validationStatus === 'PASSED') {
                data.account.hid = hid;

                if (parsedBody.data && parsedBody.data.billingCenterEligibility === '1') {
                    yield put(accountActions.aiaRequired(data));
                    return;
                }
                yield put(accountActions.validateAccountEligibilitySuccess(data));
            } else {
                yield put(errorActions.setErrors([parsedBody.data.errorCode]));
            }
        }

        yield put(settingActions.setSessionWidgetState());
    } catch (error) {
        console.error('validateAccountEligibility failed', error);
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* validateInvoice(action) {
    try {
        yield put(errorActions.clearErrors());
        yield put(layoutActions.showSpinner());
        const {data} = action;
        const envProps = yield select(getEnvProps);
        const {apiUrlPrefix, simulateApi} = envProps;
        const response = yield call(accountApi.validateInvoice, data, apiUrlPrefix, simulateApi);
        const {ok, parsedBody} = response;
        if (ok && parsedBody) {
            const {hid} = parsedBody;
            if (parsedBody.validationStatus === 'PASSED') {
                yield put(accountActions.validateInvoiceSuccess({data, hid, invoice: data.invoice}));
            } else {
                yield put(errorActions.setErrors([parsedBody.data.errorCode]));
            }
        }

    } catch (error) {
        console.error(error);
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* addAccount(action) {
    try {
        yield put(errorActions.clearErrors());
        yield put(layoutActions.showSpinner());
        const {data} = action;

        let fullData = {
            account: {...data.account},
            invoice: data.invoice,
        };

        let response;
        if (data.account.accountType === 'PLAN') {
            if (data.invoice) {
                fullData = {
                    ...fullData,
                    planNumber: data.invoice.planNumber,
                    planTotalInvoice: data.invoice.planTotalInvoice

                }
            }

            response = yield call(accountApi.addPlan, fullData);
        } else if (data.account.accountType === 'ACCOUNT') {
            response = yield call(accountApi.addAccount, fullData);
        }
        const {parsedBody} = response;
        if (parsedBody) {

            if (parsedBody.validationStatus === 'FAILED') {
                yield put(errorActions.setErrors([parsedBody.data.errorCode]));
                return;
            }

            if (parsedBody.data && parsedBody.data.billingCenterEligibility === '1') {
                yield put(accountActions.aiaRequired(data));
                return;
            }

            // update "accounts" state with added account
            yield put(accountActions.addAccountSuccess(data));
            if (data.account.accountType === 'PLAN') {
                yield put (identityActions.setIdentityPlanEnrolled(true));

            }

            // update merchants list on addition of account/plan
            yield put(identityActions.getAccessibleMerchants());
        }

    } catch (error) {
        console.error('addAccount failed', error);
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* getAccountsList(action) {
    try {
        const response = yield call(accountApi.getAccountList);

        const {callback} = action;

        const {parsedBody} = response;
        if (parsedBody) {
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(accountActions.getAccountListSuccess(parsedBody));
        } else {
            yield put(accountActions.getAccountListFailure(parsedBody));
        }

    } catch (error) {
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function * getPlansList(action) {
    try {
        const response = yield call(accountApi.getPlansList);

        const {callback} = action;

        const {parsedBody} = response;
        if (parsedBody) {
            if (callback) {
                yield call(callback, parsedBody);
            }
            yield put(accountActions.getPlanListSuccess(parsedBody));
        }
         else {
            yield put(accountActions.getPlanListFailure(parsedBody));
        }

    } catch (error) {
        yield put(errorActions.setErrors(['page.unexpectedError']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* addAccountToCompany(action) {
    try {
        const envProps = yield select(getEnvProps);
        const {apiUrlPrefix, apiToken, simulateApi} = envProps;

        const user = yield select(getUser);
        const {companyId, id} = user;

        const {data, callback} = action;
        //const {data} = action;
        const response = yield call(accountApi.addAccountToCompany, data, apiUrlPrefix, companyId, apiToken, false); // TODO: leave simulateflag false as we want to call actual endpoint to add account
        const {ok, parsedBody} = response;

        if (ok && parsedBody) {
            yield put(accountActions.addAccountToCompanySuccess({accounts: parsedBody}));
            if (callback) {
                yield call(callback, parsedBody);
            }
        }

    } catch (error) {
        yield put(accountActions.addAccountToCompanyFailure(error));
    } finally {
        yield put(layoutActions.hideSpinner());

    }
}

// should they have separate actions??? or combine them?
function* addAccountToIdentity(action) {
    try {
        const envProps = yield select(getEnvProps);
        const {apiUrlPrefix, apiToken, simulateApi} = envProps;

        const user = yield select(getUser);
        const {id} = user;

        const {data} = action;
        const response = yield call(accountApi.addAccountToIdentity, data, apiUrlPrefix, id, apiToken, false); // TODO: leave simulateflag false as we want to call actual endpoint to add account
        const {ok, parsedBody} = response;

        if (ok && parsedBody) {
            yield put(accountActions.addAccountToIdentitySuccess());
        }
    } catch (error) {
        yield put(accountActions.addAccountToIdentityFailure(error));
    } finally {
        yield put(layoutActions.hideSpinner());

    }
}

function* removeAccounts(action) {
    try {
        yield put(layoutActions.showSpinner());

        const {data} = action;
        const response = yield call(accountApi.removeAccounts, data);
        const {parsedBody} = response;

        if (parsedBody) {
            const {accountRemoved, accounts, partialAccountsRemoved} = parsedBody;
            if (accountRemoved) {
                yield put(accountActions.removeAccountSuccess(accounts));
                // If successfully removed accounts then check if accounts list has plans
                const planEnrolled = checkPlanEnrolled(accounts);
                yield put (identityActions.setIdentityPlanEnrolled(planEnrolled));
            } else if(partialAccountsRemoved){
                yield put(accountActions.removeAccountSuccess(accounts));
                yield put(accountActions.removeAccountFailure({removeAccounts:parsedBody.errorAccounts}));
                // If successfully removed accounts then check if accounts list has plans
                const planEnrolled = checkPlanEnrolled(accounts);
                yield put (identityActions.setIdentityPlanEnrolled(planEnrolled));
            } else{
                yield put(errorActions.setErrors([parsedBody.data.errorCode]));
            }

            // update merchants list on removal of accounts
            if(accountRemoved | partialAccountsRemoved){
                yield put(identityActions.getAccessibleMerchants());
            }
        }

    } catch (error) {
        yield put(errorActions.setErrors(['page.unexpectedErrorpage']));
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getXmlEnrollment(action) {
    try {
        yield put(layoutActions.showSpinner());
        const {data} = action;
        const response = yield call(accountApi.getXmlEnrollment, data);
        const {parsedBody} = response;
        if (parsedBody) {
            const {mediaFormatStatus} = parsedBody;
            mediaFormatStatus === "N" ?
            yield put(accountActions.getXmlUnEnrollmentSuccess()):
            yield put(accountActions.getXmlEnrollmentSuccess());
        }
    } catch (error) {
        yield put(accountActions.getXmlEnrollmentFailure())
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* enrollXml(action) {
    try {
        yield put(layoutActions.showSpinner());
        const {data} = action;
        const response = yield call(accountApi.enrollXml, data);
        const {parsedBody} = response;
        if (parsedBody) {
            yield put(accountActions.enrollXmlSuccess(parsedBody));
        }
    } catch (error) {
        yield put(accountActions.enrollXmlFailure(error))
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* unEnrollXml(action) {

    try {
        yield put(layoutActions.showSpinner());
        const {data} = action;
        const response = yield call(accountApi.unenrollXml, data);
        const {parsedBody} = response;
        if (parsedBody) {
            yield put(accountActions.unenrollXmlSuccess());
        }
    } catch (error) {
        yield put(accountActions.unenrollXmlFailure(error))
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

export function* validateInvoiceSaga() {
    yield takeEvery(actionTypes.VALIDATE_INVOICE, validateInvoice);
}

export function* getAccountsListSaga() {
    yield takeEvery(actionTypes.GET_ACCOUNT_LIST, getAccountsList);
}

export function* getPlansListSaga() {
    yield takeEvery(actionTypes.GET_PLAN_LIST, getPlansList);
}

export function* validateAccountEligibilitySaga() {
    yield takeEvery(actionTypes.VALIDATE_ACCOUNT_ELIGIBILITY, validateAccountEligibility);
}

export function* addAccountSaga() {
    yield takeEvery(actionTypes.ADD_ACCOUNT, addAccount);
}

export function* addAccountToCompanySaga() {
    yield takeEvery(actionTypes.ADD_ACCOUNT_TO_COMPANY, addAccountToCompany);
}

export function* addAccountToIdentitySaga() {
    yield takeEvery(actionTypes.ADD_ACCOUNT_TO_IDENTITY, addAccountToIdentity);
}

export function* removeAccountSaga() {
    yield takeEvery(actionTypes.REMOVE_ACCOUNTS, removeAccounts);
}
export function* getXmlEnrollmentSaga() {
    yield takeEvery(actionTypes.GET_XML_ENROLLMENT, getXmlEnrollment);
}

export function* enrollXmlSaga() {
    yield takeEvery(actionTypes.ENROLL_XML, enrollXml);
}

export function* unEnrollXmlSaga() {
    yield takeEvery(actionTypes.UNENROLL_XML, unEnrollXml);
}
export default function* accountSaga() {
    yield all([
        fork(validateInvoiceSaga),
        fork(getAccountsListSaga),
        fork(getPlansListSaga),
        fork(validateAccountEligibilitySaga),
        fork(addAccountSaga),
        fork(addAccountToCompanySaga),
        fork(addAccountToIdentitySaga),
        fork(removeAccountSaga),
        fork(getXmlEnrollmentSaga),
        fork(enrollXmlSaga),
        fork(unEnrollXmlSaga)
    ]);
}