import React,  { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBSelect  } from 'mdbreact';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import * as identityActions from "../../../../actions/identity-action";
import { getStatusCodeLabel, DISPUTE_STATUS_CODES } from "../../../../utils/dispute-utils";
import { get as lodashGet, isEmpty as lodashIsEmpty } from 'lodash';
import {createLookupTable} from "../../../../utils/utils";
import MDBSelectWrapper from  '../../../MDBFix/MDBSelectWrapper';
import ReactSelectAllGrouped from '../../../ReactSelectAllGrouped';

const getAllOption = (intl) => { 
    return {
        value: "*", 
        msgId: "filter.multi.option.dropdown.all.selector", 
        label: intl.formatMessage({id:"filter.multi.option.dropdown.all.selector"})
    }
};

const selectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    closeMenuOnSelect:true,
    hideSelectedOptions:false,
    isSearchable:false,
    allowSelectAll: false,
    backspaceRemovesValue:false,
    intlLabel: true,
    placeholder:<FormattedMessage id={"filter-options.dropdown.default"}/>
}

const multiSelectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    isMulti:true,
    closeMenuOnSelect:false,
    hideSelectedOptions:false,
    allowSelectAll:true,
    isSearchable:false,
    backspaceRemovesValue:false,
    placeholder:<FormattedMessage id={"react-select.select-your-filters"}/>
};
const allOptionValue = "*";
class DisputeSearchCriteriaModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            reasonCodes: [],
            statusCodes: [],
            users: [],
            searchCriteria: {
                ...props.searchCriteria
            }
        };
    }

    componentDidMount = () => {
        const { intl, identityActions } = this.props;
        const { invoiceMetadata, searchCriteria } = this.props;

        const reasonCodesMap = invoiceMetadata?.disputeRules?.reasonCodes;

        identityActions.getIdentityListByCompanyId((identities) => {
            const reasons = lodashGet(searchCriteria, 'reasonCode', []);
            const statuses = lodashGet(searchCriteria, 'statusCode', []);
            let reasonCodes = [];
            if (reasonCodesMap){
                reasonCodes = Object.keys(reasonCodesMap).map(reasonCode => ({
                    checked: lodashIsEmpty(reasons) ? true : reasons.includes(reasonCode),
                    value: reasonCode,
                    text: intl.formatMessage({ id: reasonCodesMap[reasonCode].msgId }),
                    msgId: reasonCodesMap[reasonCode].msgId,
                    label: intl.formatMessage({ id: reasonCodesMap[reasonCode].msgId })
                }));
                reasonCodes.unshift(getAllOption(intl));
            }
            const allReasons = reasonCodes.map(reasonCode=>({...reasonCode, checked: true}))

            let statusCodes = DISPUTE_STATUS_CODES.map(statusCode => ({
                checked: lodashIsEmpty(statuses) ? true : statuses.includes(statusCode),
                value: statusCode,
                text: getStatusCodeLabel(intl)[statusCode],
                msgId: getStatusCodeLabel(intl)[statusCode],
                label: getStatusCodeLabel(intl)[statusCode]
            }));
            statusCodes.unshift(getAllOption(intl));
            const allStatuses = statusCodes.map(statusCode=>({...statusCode, checked: true}))

            const users = identities?.map(identity => identity.loginId)
            let _users = users.flatMap(user => user?({ 
                checked: lodashGet(searchCriteria, 'createdBy', []).includes(user),
                value: user, 
                text: user,
                msgId: user,
                label: user
            }):[]);
            let allOption = getAllOption(intl);
            _users.unshift(allOption);
            this.setState({
                reasonCodes: [{
                    msgId: "react-select.select-your-filters",
                    options: reasonCodes
                }],
                statusCodes: [{
                    msgId: "react-select.select-your-filters",
                    options: statusCodes
                }],
                allReasons,
                allStatuses,
                loaded: true,
                users: [{
                    msgId: "react-select.select-your-filters",
                    options: _users
                }]
            });
        });
    }

    checkIfAllSelected = (selection, allSelection) => {
        let allSelected = false;
        if(allSelection){
            const lookup = createLookupTable(selection)
            allSelected = allSelection.reduce((acc, option)=>{
                acc &= lookup[option.value]
                return acc
            }, true)
        }
        return allSelected;
    }

    onUpdateDisputeFilters = (fieldName, value) => {
        const { searchCriteria } = this.state;

        this.setState({
            searchCriteria: {
                ...searchCriteria,
                [fieldName]: value
            }
        });
    }

    onApplySearchCriteriaClicked = () => {
        const { applyFilter, toggleModal } = this.props;
        const { searchCriteria, allReasons, allStatuses } = this.state;
        const { reasonCode, statusCode, createdBy } = searchCriteria;
        const allReasonCodesSelected = this.checkIfAllSelected(reasonCode, allReasons)
        const allStatusCodesSelected = this.checkIfAllSelected(statusCode, allStatuses)

        const parsedFilter = {
            ...searchCriteria,
            createdBy: createdBy == allOptionValue ? undefined : createdBy,
            reasonCode: allReasonCodesSelected ? undefined : reasonCode,
            statusCode: allStatusCodesSelected ? undefined : statusCode
        }

        applyFilter(parsedFilter);
        toggleModal();
    }

    onClearSearchCriteriaClicked = () => {
        const { clearFilter, toggleModal } = this.props;
        clearFilter();
        toggleModal();
    }

    render() {
        const { isOpen, toggleModal, backdrop, intl } = this.props;
        const { reasonCodes, statusCodes, users, loaded, searchCriteria } = this.state;

        if (!isOpen) return null;

        const createdBy = searchCriteria?.createdBy ?searchCriteria?.createdBy : allOptionValue;
        const createdByFilter = users[0]?.options?.filter(option => option.value === createdBy);
        const statusCodeFilters = searchCriteria?.statusCode ? searchCriteria?.statusCode.map(val => ({value: val})) : statusCodes[0]?.options.map(val => ({value: val.value}));
        const reasonCodeFilters = searchCriteria?.reasonCode ? searchCriteria?.reasonCode.map(val => ({value: val})) : reasonCodes[0]?.options.map(val => ({value: val.value}));
        
        const components = [];

        components.push(
            <MDBCol size={"12"} key={"field-created-by"}>
                <ReactSelectAllGrouped
                    label={intl.formatMessage({id:'invoice.dispute.created-by.label'})}
                    inputId={"createdBy"}
                    options={users}
                    field="createdBy"
                    onChange={(selected) => {
                        this.onUpdateDisputeFilters('createdBy', selected.value)
                    }}
                    value={createdByFilter}
                    {...selectProps}
                />
            </MDBCol>
        );

        components.push(
            <MDBCol size={"12"} key={"field-dispute-status"}>
                <ReactSelectAllGrouped
                        label={intl.formatMessage({id:'invoice.dispute.status.label'})}
                        inputId={"statusCodeFilter"}
                        options={statusCodes}
                        field="statusCode"
                        onChange={(selected) => {
                            const val = selected.map(x => x.value);
                            this.onUpdateDisputeFilters("statusCode", val)} 
                        }
                        value={statusCodeFilters}
                        {...multiSelectProps}
                    />
            </MDBCol>
        );

        components.push(
            <MDBCol size={"12"} key={"field-reason-code"}>
                <ReactSelectAllGrouped
                        label={intl.formatMessage({id:'invoice.dispute.reason-code.label'})}
                        inputId={"reasonCodeFilter"}
                        options={reasonCodes}
                        field="reasonCode"
                        onChange={(selected) => {
                            const val = selected.map(x => x.value);
                            this.onUpdateDisputeFilters("reasonCode", val)} 
                        }
                        value={reasonCodeFilters}
                        {...multiSelectProps}
                    />
            </MDBCol>
        );

        const modalHeading = "invoice.dispute.search-criteria.title";
        return ( loaded &&
            <ReactPortalModal isOpen={isOpen} an_label="dispute-search-criteria">
                <MDBModal isOpen={isOpen} toggle={() => toggleModal()} size="md" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal()}>
                        <FormattedMessage id={modalHeading} />
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            {components}
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.onApplySearchCriteriaClicked}>
                            <FormattedMessage id="invoice.dispute.btn-submit.label" />
                        </MDBBtn>
                        <MDBBtn color="cancel" onClick={this.onClearSearchCriteriaClicked}>
                            <FormattedMessage id="invoice.dispute.btn-clear.label" />
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        invoiceMetadata: state.invoice.invoiceMetadata
    }
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DisputeSearchCriteriaModal));