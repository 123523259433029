import { all } from 'redux-saga/effects';
import authSaga from './auth-saga';
import settingSaga from './setting-saga';
//import themeSaga from './theme-saga';
import identitySaga from './identity-saga';
import accountSaga from './account-saga';
import configSaga from './config-saga';
import paymentSaga from './payment-saga';
// import otpSaga from './otp-saga';
import scheduleSaga from './schedule-saga';
import invoiceSaga from './invoice-saga';
import disputeSaga from './dispute-saga';
import enrollSaga from './enrollment-saga';
import paymentHistorySaga from './payment-history-saga';
import dashboardSaga from './dashboard-saga';
import chatbotSaga from './chatbot-saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        settingSaga(),
        //themeSaga(),
        identitySaga(),
        accountSaga(),
        configSaga(),
        paymentSaga(),
        // otpSaga(),
        scheduleSaga(),
        invoiceSaga(),
        disputeSaga(),
        enrollSaga(),
        paymentHistorySaga(),
        dashboardSaga(),
        chatbotSaga()
    ]);
}