import {MDBBtn} from "mdbreact";
import {stripAccountNumber, padAccountNumbers} from "../../../utils/ups-utils";
import * as invoiceStatuses from "../../../constants/invoice-statuses";
import React from "react";

import {
    checkPayableIr, displayPaymentStatus,
    formatCurrency,
    formatInvoiceDate,
    getInvoiceStatusMessage,
    getInvoiceTypeMessage, isFullInvoicingEnabled
} from "../../../utils/invoice-utils";
import * as R from "ramda";
import * as paymentHistoryConstants from "../../../constants/payment-history";
import {isEmpty as lodashIsEmpty} from "lodash";
import { getPaymentHistoryForInvoice } from '../../../api/payment-history-api';

export const getDefaultInvoiceColumns = (props, toggleModal) => {
    const { businessUnit } = props;
    switch (businessUnit) {
        case 'EBS': return invoiceTableColumnsEBS(props, toggleModal);
        case 'FRT': return invoiceTableColumnsFRT(props, toggleModal);
        case 'SCS': return invoiceTableColumnsSCS(props, toggleModal);
    }
}

const invoiceCommonColumns = (props, hasFullInvoicing, toggleModal)=>{
    const {intl, businessUnitKeys, invoiceMetadata, countryCode, businessUnitConfigs, businessUnit, permissions, sessionSelection} = props;
    return ({
        invoiceNumber:{
            field: 'invoiceNumber',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceNumber')}),
            display: (rowData) => {
                if (hasFullInvoicing) return <MDBBtn color='datatable-text-link' onClick={() => viewInvoiceDetails(props, rowData)}>
                    {rowData.invoiceNumber}
                </MDBBtn>
                else return rowData.invoiceNumber
            },
            serialize: ({invoiceNumber})=>invoiceNumber,
            hideable: false,
            responsivePriority: 3
        },
        accountNumber: {
            field: 'accountNumber',
            label: intl.formatMessage({ id: businessUnitKeys('accountNumber') }),
            display: ({ accountNumber }) => stripAccountNumber(accountNumber, businessUnit),
            serialize: ({ accountNumber }) => stripAccountNumber(accountNumber, businessUnit),
            sortFunc: ({ accountNumber }) => stripAccountNumber(accountNumber, businessUnit),
            hideable: false
        },
        invoiceDate: {
            field: 'invoiceDate',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceDate') }),
            display: ({ invoiceDate }) => formatInvoiceDate(intl, invoiceDate),
            serialize: ({ invoiceDate }) => formatInvoiceDate(intl, invoiceDate),
            hideable: true
        },
        invoiceStatus: {
            field: 'invoiceStatus',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceStatus') }),
            display: (rowData) => {
                const {invoiceStatus} = rowData;
                const parsedInvoiceStatus = getInvoiceStatusMessage(intl, invoiceMetadata, invoiceStatus)
                const isPastDue = rowData['invoiceStatus'] === invoiceStatuses.PAST_DUE;
                let payableIrText = undefined;
                if(businessUnitConfigs('hasPayableIr')){
                    const isPayableIr = checkPayableIr({...rowData, parent: props.invoice})
                    payableIrText = !isPayableIr ? <div className="not-payable-msg"><strong>{intl.formatMessage({id:'invoice.not-payable-online'})}</strong></div>:undefined
                }
                return <>
                    {isPastDue
                        ? <div className="alert alert-past-due mb-0 p-0"><span className="status-desc">{parsedInvoiceStatus}</span></div>
                        : <div>{parsedInvoiceStatus}</div>
                    }
                    {payableIrText}
                </>
            },
            serialize: (rowData)=>{
                const {invoiceStatus} = rowData;
                let payableIrText = ''
                if(businessUnitConfigs('hasPayableIr')){
                    const isPayableIr = checkPayableIr({...rowData, parent: props.invoice})
                    payableIrText = !isPayableIr ? intl.formatMessage({id:'invoice.not-payable-online'}) : ''
                }
                return `${getInvoiceStatusMessage(intl, invoiceMetadata, invoiceStatus)} ${payableIrText}`
            },
            sortFunc: ({invoiceStatus})=>getInvoiceStatusMessage(intl, invoiceMetadata, invoiceStatus),
            hideable: true
        },
        paymentStatus: {
            field: 'paymentStatus',
            label: intl.formatMessage({ id: 'invoice.invoice-table.payment-status' }),
            display: (rowData) => {
                const activityLabel = intl.formatMessage({id: "more-actions.payment-activity.label"}, {id: rowData.invoiceNumber});

                return <>
                    {permissions?.payment_search && (
                        <MDBBtn
                            className="payment-status-activity-link" color={"datatable-text-link"} aria-label={activityLabel}
                            onClick={() => getPaymentActivityForInvoice(props, toggleModal, rowData, 'viewAccountPaymentHistory')}
                        >
                            {intl.formatMessage({id: "invoice.invoice-table.payment_activity"})}
                        </MDBBtn>
                    )}
                    <div>{renderPendingPaymentStatus (intl) (rowData)}</div>
                </>
            },
            serialize: (rowData) => renderPendingPaymentStatus(intl) (rowData),
            sortFunc: (rowData) => renderPendingPaymentStatus(intl) (rowData),
            hideable: true
        },
        invoiceAmount: {
            field: 'invoiceAmount',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceAmount') }),
            thClassName: 'text-right',
            tdClassName: 'text-right',
            display: ({ invoiceAmount, currencyCode }) => formatCurrency(intl, invoiceAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            serialize: ({ invoiceAmount, currencyCode }) => formatCurrency(intl, invoiceAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            hideable: true
        },
        outstandingAmount: {
            field: 'outstandingAmount',
            label: intl.formatMessage({ id: businessUnitKeys('outstandingAmount') }),
            thClassName: 'text-right',
            tdClassName: 'text-right',
            display: ({ outstandingAmount, currencyCode }) => formatCurrency(intl, outstandingAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            serialize: ({ outstandingAmount, currencyCode }) => formatCurrency(intl, outstandingAmount, currencyCode, sessionSelection.merchant.currencyDisplay),
            hideable: true
        },
        dueDate: {
            field: 'dueDate',
            label: intl.formatMessage({ id: businessUnitKeys('dueDate')}),
            display: ({ dueDate }) => formatInvoiceDate(intl, dueDate),
            serialize: ({ dueDate }) => formatInvoiceDate(intl, dueDate),
            hideable: true
        },
        invoiceType: {
            field: 'invoiceType',
            label: intl.formatMessage({ id: businessUnitKeys('invoiceType') }),
            display: ({ invoiceType }) => getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType, countryCode),
            serialize: ({ invoiceType }) => getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType, countryCode),
            sortFunc: ({ invoiceType }) => getInvoiceTypeMessage(intl, invoiceMetadata, invoiceType, countryCode),
            hideable: true
        }
    })
}

const invoiceTableColumnsEBS = (props, toggleModal) => {
    const { sessionSelection } = props;

    const hasFullInvoicing = isFullInvoicingEnabled(sessionSelection.merchant);
    const {
        invoiceNumber,
        accountNumber,
        invoiceDate,
        invoiceStatus,
        paymentStatus,
        invoiceAmount,
        outstandingAmount,
        dueDate,
        invoiceType
    } = invoiceCommonColumns(props, hasFullInvoicing, toggleModal)

    // this seemingly redundant mapping
    // is done in case we want to change the default order of the columns for this business unit

    return {
        invoiceNumber,
        accountNumber,
        invoiceDate,
        invoiceStatus,
        paymentStatus,
        invoiceAmount,
        outstandingAmount,
        dueDate,
        invoiceType
    }
};

const invoiceTableColumnsFRT = (props, toggleModal) => {
    const { sessionSelection, intl } = props;

    const hasFullInvoicing = isFullInvoicingEnabled(sessionSelection.merchant);
    const {
        invoiceNumber,
        accountNumber,
        invoiceDate,
        invoiceStatus,
        paymentStatus,
        invoiceAmount,
        outstandingAmount,
        dueDate,
    } = invoiceCommonColumns(props, hasFullInvoicing, toggleModal)

    return {
        invoiceDate,
        accountNumber,
        invoiceNumber,
        bol: {
            field: 'bol',
            label: intl.formatMessage({ id: 'invoice.freight-table.bol' }),
            hideable: true
        },
        po: {
            field: 'po',
            label: intl.formatMessage({ id: 'invoice.freight-table.po' }),
            hideable: true
        },
        freightTerms: {
            field: 'freightTerms',
            label: intl.formatMessage({ id: 'invoice.freight-table.freight-terms' }),
            hideable: true
        },
        invoiceStatus,
        dueDate,
        invoiceAmount,
        outstandingAmount,
        paymentStatus
    }
};

const invoiceTableColumnsSCS = (props, toggleModal) => {
    const {
        invoiceNumber,
        accountNumber,
        invoiceDate,
        invoiceStatus,
        paymentStatus,
        invoiceAmount,
        outstandingAmount,
        dueDate,
        invoiceType
    } = invoiceCommonColumns(props, false, toggleModal)

    // this seemingly redundant mapping
    // is done in case we want to change the default order of the columns for this business unit

    return {
        invoiceNumber,
        accountNumber,
        invoiceDate,
        invoiceStatus,
        paymentStatus,
        invoiceAmount,
        outstandingAmount,
        dueDate,
        invoiceType
    }
};

const renderPendingPaymentStatus = intl =>  R.ifElse
( R.hasPath(['paymentData', 'paymentStatus'])
    , R.compose
    ( s => displayPaymentStatus(intl) (s)
        , R.path(['paymentData', 'paymentStatus'])
    )
    , R.always(null)
);

const pickInvoiceForResourceControl = R.pick(['accountNumber', 'recordType', 'id', 'planNumber']);
const pickInvoiceForPaymentHistory = R.pick(['accountNumber', 'recordType', 'planNumber']);

const getPaymentActivityForInvoice = async (props, toggleModal, data, modalName) => {
    // const { paymentActivityActions } = props;
    const { invoiceNumber, planInvoiceNumber, recordType } = data;
    const accounts = [pickInvoiceForPaymentHistory(data)];

    const result = await getPaymentHistoryForInvoice ({invoiceNumber, recordType, accounts});

    const history = result.map(item => {
        const {sessionSelection} = props;
        let invoiceType = null;
        if (!!invoiceNumber && !!planInvoiceNumber) {
            invoiceType = paymentHistoryConstants.PLAN;
        } else {
            if (!!planInvoiceNumber) invoiceType = paymentHistoryConstants.PLAN;
            if (!!invoiceNumber) invoiceType = paymentHistoryConstants.ACCOUNT;
        }
        return {
            ...item,
            invoiceType,
            currencyDisplay: sessionSelection.merchant.currencyDisplay,
            totalAmount: item.totalAmount
        };
    });

    toggleModal(modalName, {...data, history});

    // if (!!invoiceNumber) payload.invoiceNumber = invoiceNumber
    // if (!!planInvoiceNumber) payload.planInvoiceNumber = planInvoiceNumber;

    // paymentActivityActions.getPaymentRecordsByParams(payload, (response) => {
    //     const history = response.map(item => {
    //         let invoiceType = null;
    //         if (!!invoiceNumber && !!planInvoiceNumber) {
    //             invoiceType = paymentHistoryConstants.PLAN;
    //         } else {
    //             if (!!planInvoiceNumber) invoiceType = paymentHistoryConstants.PLAN;
    //             if (!!invoiceNumber) invoiceType = paymentHistoryConstants.ACCOUNT;
    //         }
    //         return {
    //             ...item,
    //             invoiceType,
    //             totalAmount: item.totalAmount
    //         }
    //     })
    //     toggleModal(modalName, {...data, history});
    // })
}

const viewInvoiceDetails = (props, invoice, toggleModal) => {
    const { history, location, isDrillMode, businessUnitConfigs, invoice: planInvoice, invoiceActions: { getInvoiceDetails } } = props;
    const accountDetails = pickInvoiceForResourceControl(invoice)
    getInvoiceDetails({invoice, searchCriteria: { detailType: 'SUMMARY' }}, (data) => {
        if (lodashIsEmpty(data) && toggleModal) {
            toggleModal("showInvoiceError")
        } else {
            const path = (isDrillMode && businessUnitConfigs('hasPlans'))
                ? `/ups/billing/planaccountinvoice/${invoice.id}/${planInvoice.id}/details`
                : `/ups/billing/invoice/${invoice.id}/details`
            history.push({
                pathname: path,
                state: {
                    prevPath: location.pathname,
                    invoice,
                    planInvoice,
                    accountDetails
                }
            });
        }
    })
};

export const savedColumnCompatibility = (name) => {
    //fix old naming of columns
    if(name==='leadBol') return 'bol'
    if(name==='leadPo') return 'po'
    return name
}

export const formatAccountNumberFilter = (accountNumberFilter, businessUnit) => {
    if (accountNumberFilter && !Array.isArray(accountNumberFilter)){
        accountNumberFilter = [accountNumberFilter]
    }
    if (businessUnit === "EBS" && accountNumberFilter && Array.isArray(accountNumberFilter )){
        accountNumberFilter = padAccountNumbers(accountNumberFilter)
    }
    return accountNumberFilter
}