import {get as lodashGet, isEmpty as lodashIsEmpty, isUndefined as lodashIsUndefined} from "lodash";
import {formatCurrency, formatNumber, interpolateTemplate, isDisputesEnabled, openTab} from '../../../utils/invoice-utils';
import React from "react";
import $ from 'jquery';
import {onEnterPress} from "../../../utils/utils";
import {MDBDropdownItem} from "mdbreact";
import moment from "moment";
import {getDisputeDetails} from "../../../api/dispute-api";
// the argument "expandRow" is used to distinguish if the invoice details page or the advanced search page are using these functions, only slight difference in data location between the two.

export const customColumns = { //definitions of columns that can be used in user-configurable tables
    currency: (intl, columnDef, formatValue, invoiceLiterals, hasFields, currencyDisplay)=>{
        const parser = columnParsers.currency(intl, columnDef, !!hasFields, currencyDisplay)
        return {
            display: parser,
            serialize: parser,
            thClassName: 'text-right',
            tdClassName: 'text-right',
        }
    },
    date: (intl, columnDef, formatValue, invoiceLiterals, hasFields)=>({
        display: (data)=>formatValue(hasFields ? data.fields : data, columnDef),
        serialize: (data)=>formatValue(hasFields ? data.fields : data, columnDef)
    }),
    link: (intl, columnDef, formatValue, invoiceLiterals, hasFields)=>({
        display: (data) => {
            const url = interpolateTemplate(columnDef.template, data);
            return <div tabIndex={0} className="trackingNumber" onKeyPress={(e)=>{
                onEnterPress(() => {
                    localStorage.setItem('advancedSearchDetails', JSON.stringify(data)); //TODO: change hardcoded localstorage
                    openTab(url)
                })(e)
            }}>
                <span className="ups-link"
                      onClick={() => {
                        if(hasFields){
                            openTab(columnDef.url, getFieldValue(data, columnDef.field, true))
                        } else {
                          localStorage.setItem('advancedSearchDetails', JSON.stringify(data)); //TODO: change hardcoded localstorage
                          openTab(url)
                        }
                      }}>{
                    getFieldValue(data, columnDef.field, !!hasFields)??''
                }</span>
             </div>
        }
    }),
    literal: (intl, columnDef, formatValue, invoiceLiterals, hasFields)=>{
        const parser = columnParsers.literal(columnDef, invoiceLiterals, !!hasFields)
        return {
            display: parser,
            serialize: parser,
            sortFunc: parser
        }
    },
    template: (intl, columnDef, formatValue, invoiceLiterals, hasFields)=>({
        display: (data) => translateFormattedTemplate(columnDef, data, formatValue, !!hasFields),
        serialize: (data) => translateFormattedTemplate(columnDef, data, formatValue, !!hasFields),
        sortFunc: (data) => translateFormattedTemplate(columnDef, data, formatValue, !!hasFields)
    }),
    numeric: (intl, {field},  formatValue, invoiceLiterals, hasFields)=>({
        display: (data) => (getFieldValue(data, field, !!hasFields) ? formatNumber(intl, getFieldValue(data, field, !!hasFields)) : ''),
        serialize: (data)=>(getFieldValue(data, field, !!hasFields) ? formatNumber(intl, getFieldValue(data, field, !!hasFields)) : ''),
        sortFunc: (data)=>(getFieldValue(data, field, !!hasFields) ?? '0'),
        thClassName: 'text-right',
        tdClassName: 'text-right',
    }),
    invoiceType: (intl, columnDef, formatValue)=>({
        display: (data)=>formatValue(data, columnDef),
        serialize: (data)=>formatValue(data, columnDef),
        sortFunc: (data)=>formatValue(data, columnDef)
    }),
    invoiceStatus: (intl, columnDef, formatValue)=>({
        display: (data)=>formatValue(data, columnDef),
        serialize: (data)=>formatValue(data, columnDef),
        sortFunc: (data)=>formatValue(data, columnDef)
    }),
    "slideout-link": (intl, columnDef, formatValue, invoiceLiterals, expandRow) => ({
        display: (data) => (
            <div className="trackingNumber">
                <span className="ups-link" onClick={(e) => {
                    e.persist();
                    expandRow(e, data);
                }}>
                    {getFieldValue(data, columnDef.field, !!expandRow, '')}
                </span>
                {columnDef.field ==='trackingNumber' && data.inDispute &&  <span className="in-dispute">
                    {intl.formatMessage({id: "invoice.disputes.in-dispute.label"})}
                </span>}
            </div>
        )
    }),
    array: (intl, columnDef)=>({
        display: columnParsers.array(columnDef),
        serialize: columnParsers.array(columnDef, 'string'),
        sortFunc: columnParsers.array(columnDef, 'string')
    })
}

export const columnParsers = {
    currency: (intl, {field}, useFields, currencyDisplay)=>(data) => {
        const amount = getFieldValue(data, field, useFields)
        const currencyCode = lodashGet(data, 'invoiceHeader.currencyCode') || lodashGet(data, 'currencyCode');
        return !lodashIsUndefined(amount) ? formatCurrency(intl, amount, currencyCode, currencyDisplay) : ''
    },
    literal: ({field}, invoiceLiterals, useFields) => (data) => {
        const literalRef = getFieldValue(data, field, useFields, '');
        return lodashGet(invoiceLiterals, literalRef, literalRef)
    },
    array: (columnDef, displayType) => (data)=> {
        if (!columnDef.format) return ''
        
        const formattedArray =  columnDef.format.map((propertyQueryString, index)=>{
            const fieldValue = lodashGet(data, propertyQueryString, false)
            const displayFormat = displayType === 'string' ? fieldValue : <div key={index}>{fieldValue}</div>
            return fieldValue ? displayFormat : null
        }).filter(value=>!!value)

        return displayType === 'string' ? formattedArray.join() : formattedArray
    }
}

const getFieldValue = (data, field, useFields, defaultVal) => lodashGet(useFields ? data.fields : data, field, defaultVal)

const translateFormattedTemplate = (columnDef, data, formatValue, useFields) => {
    const translatedObj = {...(useFields ? data.fields : data)};
    if (columnDef.format) {
        columnDef.format.forEach(field => {
            translatedObj[field.field] = formatValue(useFields ? data.fields : data, field);
        });
    }
    return interpolateTemplate(columnDef.template, translatedObj);
};

export const defaultColumnProps = (intl, column, useFields) => {
    const {field, msgId, sort} = column;
    return { // props that are definitions of all custom columns by default
        field,
        label: intl.formatMessage({ id: msgId }),
        sortable: !!sort,
        display: (data)=>getFieldValue(data, field, useFields, ''),
        serialize: (data)=>getFieldValue(data, field, useFields, ''),
        sortFunc: (data)=>getFieldValue(data, field, useFields, '')
    }
}

export const customActions = {
    "-": ({props}, id, rowData, actionList) => {
        if(actionList.length > 0) return <MDBDropdownItem divider key={"dropdown-divider-"+id} />
    },
    "in-dispute": ({props, toggleModal}, id, rowData) => {
        const {intl} = props;
        if (!rowData.inDispute) return
        return (
            <MDBDropdownItem
                key={"dropdown-in-dispute-"+id}
                onClick={() => toggleModal('disputes', rowData)}
            >
            {intl.formatMessage({id: "invoice.disputes.in-dispute.label"})}                
            </MDBDropdownItem>
        )

    },
    "dispute": ({props, toggleModal}, id, rowData) => {
        const {invoice, sessionSelection, permissions, intl,envProps ,user: { adUserId }} = props;
        const {merchant} = sessionSelection;
        const isCreditNote = invoice.invoiceType === "101";
        const { fields, shipmentCharge = [] } = rowData;
        const isElegibleForDispute =  (fields && fields.netCharge > 0) || (!fields.hasOwnProperty('netCharge') && shipmentCharge.some((sc) => sc.netAmt > 0));
        if (!permissions['dispute_invoice'] || isCreditNote || !isDisputesEnabled(merchant) || rowData.noLongerDisputable) return
        if ((rowData.fields && !isElegibleForDispute) || (!rowData.inDispute && adUserId)) return
        return (
            <MDBDropdownItem
                key={"dropdown-dispute-"+id}
                onClick={async () => {
                    const { apiUrlPrefix, apiToken } = envProps;
                    const { fields: { trackingNumber}, invoiceId } = rowData;
                    if (rowData.inDispute) {
                        let response = await getDisputeDetails({ trackingNumber, invoiceId }, apiUrlPrefix, apiToken);
                        let data = response?.parsedBody &&  response?.parsedBody?.length ? response.parsedBody[0] : {};
                        return toggleModal('disputeDetails', data);
                    } else {
                        return toggleModal('disputes', rowData);
                    }
                }
                }
            >
                {rowData.inDispute ?
                    <React.Fragment>
                     {intl.formatMessage({id: "invoice.dispute.view-dispute.label"})}
                    </React.Fragment> :
                    intl.formatMessage({id: "invoice.disputes.dispute.label"})}
            </MDBDropdownItem>
        )

    },
    "track": ({props}, id, rowData) => {
        const trackingNumber = lodashGet(rowData, 'fields.trackingNumber') || lodashGet(rowData, 'fields.trackingNumber1');
        if(!lodashIsEmpty(trackingNumber)){
            const {intl} = props;
            const {locale} = props.settings;
            return (
                <MDBDropdownItem
                    key={"dropdown-track-" + id}
                    onClick={() =>
                        openTab(`https://wwwapps.ups.com/WebTracking/processInputRequest?loc=${locale.replace('-', '_')}&tracknum=${trackingNumber}&requestorID=billing`)
                    }>
                    {intl.formatMessage({ id: "invoice.track.label" })}
                </MDBDropdownItem>
            )
        }
    },

}

export const constructDateFilter = (dateOptions) => {
    const {calendarOptionType, startDate, endDate, dateType} = dateOptions;
    let filterStartDate = null;
    let filterEndDate = null;

    if (calendarOptionType === 'all') {
        return {
            dateField: undefined,
            startDate: undefined,
            endDate: undefined
        };
    } else if (calendarOptionType === 'custom') {
        filterStartDate = moment(startDate);
        filterEndDate = moment(endDate);
    } else if (calendarOptionType === 'today') {
        filterStartDate = moment();
        filterEndDate = moment();
    } else {
        const splitCalendarOption = calendarOptionType.split(', ');
        const numVal = parseInt(splitCalendarOption[0]);
        if (numVal > 0) {
            filterStartDate = moment();
            filterEndDate = moment().add(numVal, splitCalendarOption[1]);
        } else {
            filterStartDate = moment().add(numVal, splitCalendarOption[1]);
            filterEndDate = moment();
        }
    }

    return {
        dateField: dateType,
        startDate: filterStartDate.startOf('day').format('YYYY-MM-DD'),
        endDate: filterEndDate.endOf('day').format('YYYY-MM-DD')
    };
}