import React, { useState }               from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader }   from 'mdbreact';
import ReactPortalModal                             from "../../ReactPortalModal";
import { MDBRow, MDBCol, MDBBtn }                   from 'mdbreact';
import { FormattedMessage, useIntl }                         from 'react-intl';

export default function PreferencesPromptModal({ isOpen, closeModal, callback }) {
    const [hideNotification, setNotificationState] = useState(false);
    const intl = useIntl();
    
    const closeWindow = (e) => {
        closeModal({value: e, hideNotification : hideNotification});
    }

    const primaryAction = (e) => {
        if(!!callback) callback()
        closeWindow(e)
    }
    const modalHeading = "notification-modal.title.notice";

    return (
        <ReactPortalModal isOpen={isOpen} an_label="preferences-prompt">
            <MDBModal isOpen={isOpen} size="md" disableBackdrop={true} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => closeWindow("cancel")}>
                    <FormattedMessage id={modalHeading} />
                </MDBModalHeader>                
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12">
                            <FormattedMessage id="ups.preferences.prompt-modal.title1"/>
                        </MDBCol>
                        <MDBCol size="12">
                            <FormattedMessage id="ups.preferences.prompt-modal.title2"/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                            <MDBBtn color="secondary"  className={"mr-3 m-0"} onClick={() => primaryAction('no')}>
                                <FormattedMessage  id={"ups.preferences.btn.dont-save"} />
                            </MDBBtn>
                            <MDBBtn size="12" color="primary"  className={"m-0"} onClick={() => primaryAction('yes')}>
                                <FormattedMessage  id={"ups.preferences.notifications.btn-save"} />
                            </MDBBtn>
                        </MDBCol>
                        <MDBCol size="12" className="text-center mt-3 d-flex d-md-block flex-column">
                            <MDBBtn color={"text-link"} className={"m-0"} onClick={() => closeWindow('cancel')}>
                                <FormattedMessage  id={"cancel.label"} />
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </ReactPortalModal>
    );
}