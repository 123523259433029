import React, {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {MDBRow, MDBCol, MDBBtn} from "mdbreact";
import * as R from "ramda";

import Details from "../";
import CheckboxWithValidation from "../../../CheckboxWithValidation";
import InputFieldIntl from "../../../../components/InputFieldIntl";
import DisplayOnlyField from "../../../../components/DisplayOnlyField";

import {InputRestrictors} from '../../../../utils/validation/standard-validations';
import {produce} from "immer";

const trim = value => value?.replace(/\D/g, '');
const buildLocalPM = ({cardNickName, accountNumber, routingNumber, cardHolderName, address, tokenPending})=>(
    {cardNickName, accountNumber, routingNumber, cardHolderName, address: address ?? {}, tokenPending}
)


export default function BacsDetails({
    isModal, pmCategory, paymentMethod, submitAction, handleCancel,
    hideNickField, controlled, country
}) {
    const intl = useIntl();
    const [localPaymentMethod, setLocalPaymentMethod] = useState(buildLocalPM(paymentMethod));
    const {
        cardNickName, accountNumber, routingNumber, cardHolderName, tokenPending, address
    } = localPaymentMethod;
    const {line1, city, zipCode} = address ?? {};

    useEffect(() => {
        if (controlled) validatePM();
    }, [localPaymentMethod]);

    const handleInput = e => {
        const {name, value, checked, type} = e.target;
        switch (type) {
            case 'text': 
                setLocalPaymentMethod({...localPaymentMethod, [name]: value});
                break;
            case 'checkbox': 
                setLocalPaymentMethod({...localPaymentMethod, [name]: checked});
                break;
            default: break;
        }
    };

    const handleAddressInput = ({target: {value, name}}) => {
        setLocalPaymentMethod(produce(newLocalPaymentMethod=>{
            newLocalPaymentMethod.address[name] = value
        })(localPaymentMethod))
    }

    const validatePM = () => {
        const data = {
            methodType: pmCategory,
            categoryCode: pmCategory,
            accountNumber,
            routingNumber,
            cardHolderName,
            cardNickName,
            address: {
                line1,
                city,
                zipCode,
                country
            }
        };
        if (paymentMethod) data.id = paymentMethod.id;
        return submitAction?.(data);
    }

    const form = <React.Fragment>
        <MDBRow>
            {controlled
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="accountNumber" id="accountNumber"
                        label={intl.formatMessage({id: "payment-method.echeck.bank-account-number"})}
                        value={accountNumber}
                        onChange={handleInput}
                        hint="payment-method.echeck.bank-account-number.placeholder"
                        maxLength={20}
                        required
                        restrict={InputRestrictors.numeric}
                        validations={[
                            ["required", "field.required"],
                            ["validBankAccount", "field.invalid"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({id: "payment-method.echeck.bank-account-number-readonly"})}
                        value={accountNumber}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="routingNumber" id="routingNumber"
                        label={intl.formatMessage({id: "payment-method.bacs.sort-code"})}
                        value={routingNumber}
                        onChange={handleInput}
                        hint="payment-method.bacs.sort-code.placeholder"
                        maxLength={6}
                        required
                        restrict={InputRestrictors.numeric}
                        validations={[
                            ["required", "field.required"],
                            ["minlength", "field.invalid", {min: 6}]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({id: "payment-method.bacs.sort-code-readonly"})}
                        value={routingNumber}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="cardHolderName" id="cardHolderName"
                        label={intl.formatMessage({id: "payment-method.echeck.account-holder-name"})}
                        value={cardHolderName}
                        onChange={handleInput}
                        hint="payment-method.echeck.account-holder-name.placeholder"
                        maxLength={256}
                        required
                        validations={[
                            ["required", "field.required"],
                            ["alphaNumericAndPunctuation", "field.alphaNumericAndPunctuation"],
                            ['notCreditCard', "field.notCreditCard"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({id: "payment-method.echeck.account-holder-name"})}
                        value={cardHolderName}
                        wrapperMode
                    />
                </MDBCol>
            }

            {!hideNickField &&
                (!tokenPending ?
                    <MDBCol size="12" md="6">
                        <InputFieldIntl name="cardNickName" id="cardNickName"
                            label={intl.formatMessage({id: "payment-method.card-nickname"})}
                            value={cardNickName}
                            onChange={handleInput}
                            onBlur={e => {
                                e.target.value = e.target.value.trim();
                                handleInput(e);
                            }}
                            hint="payment-method.card-nickname"
                            maxLength={32}
                            validations={[value => trim(value)?.includes(trim(accountNumber))
                                || trim(value)?.includes(trim(routingNumber))
                                    ? "field.notCreditCard"
                                    : ""
                            ]}
                        />
                    </MDBCol>
                    : <MDBCol size="12" md="6">
                        <DisplayOnlyField
                            label={intl.formatMessage({id: "payment-method.card-nickname"})}
                            value={cardNickName}
                            wrapperMode
                        />
                    </MDBCol>
                )
            }

            {!tokenPending &&
            (controlled ? 
                <MDBCol size="12" md="6">
                    <InputFieldIntl key="line1" name="line1" id="line1"
                        label={<FormattedMessage id="payment-method.billing-address.line1"/>}
                        value={line1}
                        onChange={handleAddressInput}
                        hint="payment-method.billing-address.line1.placeholder"
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['notCreditCard', "field.notCreditCard"],
                            ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.billing-address.line1"/>}
                        value={line1}
                        wrapperMode
                    />
                </MDBCol>
            )}

            {!tokenPending  &&
            (controlled ? 
                <MDBCol size="12" md="6">
                    <InputFieldIntl key="city" name="city" id="city"
                        label={<FormattedMessage id="payment-method.billing-address.city"/>}
                        value={city}
                        onChange={handleAddressInput}
                        hint="payment-method.billing-address.city.placeholder"
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['notCreditCard', "field.notCreditCard"],
                            ['alphaAndPunctuation', "field.alphaAndPunctuation"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.billing-address.city"/>}
                        value={city}
                        wrapperMode
                    />
                </MDBCol>
            )}

            {!tokenPending  &&
            (controlled ? 
                <MDBCol md="4">
                    <InputFieldIntl key="zipCode" name="zipCode" id="zipCode"
                        label={<FormattedMessage id="payment-method.billing-address.zip-code"/>}
                        value={zipCode}
                        onChange={handleAddressInput}
                        hint="payment-method.billing-address.zip-code.placeholder"
                        maxLength={10}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['zipCode', "field.zipCode", {selectedCountry: country}]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={<FormattedMessage id="payment-method.billing-address.zip-code"/>}
                    value={zipCode}
                    wrapperMode
                />
            </MDBCol>
            )}
        </MDBRow>
    </React.Fragment>;

    if (controlled) return form;

    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}
