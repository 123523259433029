import {useEffect, useLayoutEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import * as layoutActions from "../actions/layout-action"

export default function Page({title, children}) {
    const {formatMessage: f, locale} = useIntl();
    const redux = useDispatch();
    const {basicModalOpen} = useSelector(s=>s.layout)

    useEffect(() => {
        // Escape switch for no-op & delegate to children
        if (title === null) return;

        // Else force set specified title or a default
        document.title = title
            ? f({id: title})
            : f({id: "ups.billing-center.label"});
    }, [title, locale]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useLayoutEffect(() => {
        return () => {
            //hide basic modal if shown (never want them to persist between page renders)
            if(basicModalOpen) redux(layoutActions.closeBasicModal())
        }
    }, [])

    return children;
};
