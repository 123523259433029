//To put it simply, DatatableV3 is the table itself (including pagination buttons) and can be used when below features aren't needed.
//Use this component that wraps DatatableV3 if you want more advanced table features like filtering, search querying, and row checkboxes/select all added to the table

import DataTableV3 from "./DataTableV3";
import * as R from 'ramda';
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useMemo, useRef } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import classNames from 'classnames';
import CheckboxIntl from "../CheckboxIntl";
import produce from "immer";
import MediaQuery from "react-responsive";
import PropTypes from "prop-types";
import DatatableV3ToPrint from "./DataTableV3ToPrint";

export const INITIAL_TABLE_STATE = {
    filteredData: [],
    selection: new Map(),
    disableSelectRows: new Map(),
    searchQuery: ''
}

const getId = (dataRow, idField) => {
    if (typeof idField === 'function') {
        return idField(dataRow)
    } else return dataRow[idField]
}

const reducer = (tableStateAction) => ({ type, ...actionData }) => {
    tableStateAction(produce(({ tableState: newState }) => {
        switch (type) {
            case 'filterDataAndClearSelection': {
                newState.selection = new Map()
            }
            case 'filterData': {
                const { data, filterFunc, columns, searchable, searchQuery, selectEnabled, checkSelectable, idField } = actionData;
                const { filteredData, excludedData } = filterData(data, filterFunc, columns, searchable, searchQuery)
                newState.filteredData = filteredData
                newState.firstFilterDone = true // since we always run this filter action on component mount, we use this variable to decide when to render the child table to reduce rerenders
                if (selectEnabled) {
                    //store which rows should hide their row checkboxes if they're currently disabled
                    if (checkSelectable) newState.disableSelectRows = filteredData.reduce(
                        (acc, cur) => (!checkSelectable(cur) ? acc.set(getId(cur, idField), true) : acc), new Map()
                    )
                    //deselect items that have been filtered out
                    if (excludedData.length > 0 && newState.selection.size > 0) excludedData.forEach((item) => newState.selection.delete(getId(item, idField)))
                }
                break;
            }
            case 'setSearch': {
                newState.searchQuery = actionData.searchQuery
                break;
            }
            case 'clearFilter': {
                newState.filter = {}
                newState.searchQuery = ''
                break
            }
            case 'selectItem': selectItem(actionData, newState); break;
            case 'selectAll': selectAll(actionData, newState); break;
            case 'storeSortingState': {
                newState.sortedField = actionData.sortedField
                newState.sortedDirection = actionData.sortedDirection
                newState.sortFunc = actionData.sortFunc
                break;
            }
        }
    }))
}

const filterData = (data, filterFunc, columns, searchable, searchQuery) => {
    const emptySearch = R.isEmpty(searchQuery)
    const lowerSearchQuery = searchQuery.toLowerCase()
    const results = data.reduce((acc, item) => {
        const matchFilter = filterFunc ? filterFunc(item, columns) : true
        const matchSearch = (searchable && !emptySearch) ? checkSearchMatch(columns, item, lowerSearchQuery) : true
        acc.push(matchFilter && matchSearch)
        return acc
    }, [])
    return {
        filteredData: data.filter((item, index) => results[index]),
        excludedData: data.filter((item, index) => !results[index])
    }
}

const checkSearchMatch = (columns, item, searchQuery) => {
    return columns.some(({ serialize, field }) => {
        const serializedField = serialize ? serialize(item) : item[field]
        return serializedField?.toLowerCase().includes(searchQuery)
    })
}

const selectItem = ({ item, idField, disableSelectRows }, newState) => {
    const itemId = getId(item, idField)
    let { selection } = newState;
    if (!selection) selection = new Map()
    if (itemId && !disableSelectRows?.has(itemId)) {
        if (!selection.has(itemId)) selection.set(itemId, item)
        else selection.delete(itemId)
    }
}

const selectAll = ({ filteredData, idField, disableSelectRows }, newState) => {
    let { selection } = newState;
    if (!selection) selection = new Map()
    if (selection.size >= (filteredData.length - disableSelectRows.size)) {
        newState.selection = new Map()
    } else {
        filteredData.forEach((item) => {
            const itemId = getId(item, idField)
            if (itemId && !disableSelectRows?.has(itemId)) selection.set(itemId, item)
        })
    }
}

export const SearchInput = ({ name,searchInputAriaLabel, columns, searchQuery, setState, rowCount, intl }) => {
    let ariaLabel = searchInputAriaLabel ?? intl.formatMessage({ id: 'datatable.search.arialabel' });

    if (columns) {
        let searchableFields = getSearchableFields(columns);
        ariaLabel = intl.formatMessage({ id: 'datatable.search.arialabel.fields' }, { fields: searchableFields.join(', ') });
    }
    return <div id={`${name}_search`} className="dataTables_filter">
        <input
            id={`${name}_searchInput`}
            className={'datatable_searchInput'}
            role='search'
            type='search'
            value={searchQuery}
            onChange={(e) => setState({ type: 'setSearch', searchQuery: e.target.value })}
            onBlur={(e) => setState({ type: 'setSearch', searchQuery: e.target.value })}
            aria-label={ariaLabel}
        />
        <label className={searchQuery && 'filled'} htmlFor={`${name}_searchInput`}>
            {intl.formatMessage({id: "ups.search.label"})}
        </label>
        <div className="sr-only" aria-live={'polite'}><FormattedMessage id={'datatable.search.resultCount'} values={{ count: rowCount }} /></div>
    </div>
}
const getSearchableFields = (columns) => {
    let fields = [];
    columns.some(({ field, label }) => {
        const searchableField = field!='actions' ? label : undefined;
        if (searchableField) fields.push(searchableField);
    })
    return fields;
}

const FilterIndicator = ({filterType, filterActive }) => {
    const msgId = filterType === "EMAIL" ? "email.filter.applied" : "filter.applied";
    
    return <div id={'datatable-filter-status'} className="datatable-custom-filter">
        {filterActive && (<><i className="fa fa-check-circle" /> <FormattedMessage id={msgId} /></>)}
    </div>
}

const hasFilter = (filter, data, filteredData) => {
    if (filter && !R.isEmpty(filter)) {
        const all = Object.values(filter).reduce((acc, filterProp) => {
            const type = typeof filterProp;
            switch (type) {
                case 'string':
                    return acc &= (filterProp === '*');
                case 'object':
                    return acc &= (filterProp[0]?.value === '*');
            }
        }, true)
        return !all && (data.length > filteredData.length)
    } else return false
}

const commonBtnProps = ({ action, title, otherProps }) => ({
    type: 'button',
    onClick: action,
    tabIndex: 0,
    title,
    ...otherProps
})

const btnClass = 'dt-button btn';

const buttonTypes = {
    primary: (btnProps) => <button className={classNames(btnClass, 'btn-primary', btnProps.className)} {...commonBtnProps(btnProps)}>{btnProps.text}</button>,
    secondary: (btnProps) => <button className={classNames(btnClass, 'btn-secondary', btnProps.className)} {...commonBtnProps(btnProps)}>{btnProps.text}</button>,
    iconButton: (btnProps) => <button className={classNames(btnClass, 'btn-icon', btnProps.className)} {...commonBtnProps(btnProps)}>{btnProps.icon}</button>,
    filter: (btnProps, fullProps, { filterActive, intl }) =>
        <button
            className={classNames(btnClass, 'btn-icon', 'btn-filter', btnProps.className)}
            {...commonBtnProps(btnProps)}
            title={intl.formatMessage({ id: 'invoice.datatable.btn-filter.tooltip' })}
        >
            <span>{<i className="fa fa-filter" />}</span>
            <span>{filterActive && <i className="fa fa-check-circle filter-active" />}</span>
        </button>
    ,
    print: (btnProps, fullProps, { printAction, intl }) =>
        <button
            className={classNames(btnClass, 'btn-icon', 'btn-print', btnProps.className)}
            {...commonBtnProps(btnProps)}
            onClick={printAction}
            title={intl.formatMessage({ id: 'invoice.datatable.btn-print.tooltip' })}
        >
            <span>{<i className="fa fa-print" />}</span>
        </button>
}

const renderButtons = (fullProps, otherProps) => {
    return <div className='dt-buttons'>{
        fullProps.buttons.map((buttonDef, index) => {
            if (buttonDef.hide) return;
            let button = null
            if (typeof buttonDef === 'object') button = buttonTypes[buttonDef.type]?.(buttonDef, fullProps, otherProps) ?? buttonTypes.primary(buttonDef)
            else button = buttonDef(fullProps, otherProps)
            return <React.Fragment key={index}>{button}</React.Fragment>
        })
    }</div>
}

export const RowCheckBox = ({ tableName, id, isSelected, action, disableSelectRows = new Map(), label, containerClass, ...rest }) => {
    if (!disableSelectRows.has(id)) {
        return (
            <CheckboxIntl
                key={`${tableName}-${id}`}
                name={`${tableName}-${id}-isSelected`}
                id={`${tableName}-${id}-isSelected`}
                containerClass={containerClass ?? "form-check-inline col-4 mr-0 mb-2"}
                labelClass="mr-0"
                value={!!isSelected()}
                onChange={action}
                label={label ? label : <span>&nbsp;</span>}
                {...rest}
            />
        )
    } else return null
}

const renderMobileCell = (column, item) => column.mobileDisplay?.(item) ?? column.display?.(item) ?? item?.[column.field]

export const MobileCard = ({ name, id, columns, item, selection, selectAction, disableSelectRows }) => {
    const mobileCardPrimaryColumn = columns.find((column) => !!column.mobileCardPrimary)
    const actionColumn = columns.find((column) => (column.field === 'actions'))
    return <MediaQuery maxWidth={767}>
        <div className={classNames('table-card-mobile-container', { 'has-selection-input': selectAction })}>
            {selectAction &&
                <RowCheckBox
                    tableName={name} id={id}
                    isSelected={() => selection.has(id)}
                    action={selectAction}
                    disableSelectRows={disableSelectRows}
                    label={
                        <React.Fragment>
                            <span className="table-card-mobile-hide">&nbsp;</span>
                            {mobileCardPrimaryColumn &&
                                <span className="table-card-mobile-only-label">
                                    {renderMobileCell(mobileCardPrimaryColumn, item)}
                                </span>
                            }
                        </React.Fragment>
                    }
                    containerClass="form-check form-check-inline m-0"
                />
            }
            {mobileCardPrimaryColumn && (!selectAction || disableSelectRows.has(id)) &&
                <div className="table-card-important-info">
                    {renderMobileCell(mobileCardPrimaryColumn, item)}
                </div>
            }
            {actionColumn &&
                <div className="table-card-mobile-actions">
                    {actionColumn.display(item)}
                </div>
            }
            <table className="table table-card-mobile-view">
                <tbody>
                    {renderMobileCardRows(item, columns)}
                </tbody>
            </table>
        </div>
    </MediaQuery>
}

const renderMobileCardRows = (item, columns) => {
    return columns.map((column) => {
        if (column.mobileCardPrimary ?? column.mobileHidden ?? ['checkBox', 'mobileCard', 'actions'].includes(column.field)) return <React.Fragment key={column.field} />
        else {
            return <tr key={column.field}>
                <th>{column.label}</th>
                <td>{renderMobileCell(column, item)}</td>
            </tr>
        }
    })
}

const getSelectAction = (onSelect, defaultSelectAction, item) => {
    return onSelect ? () => onSelect(item, defaultSelectAction) : defaultSelectAction
}

const makeSelectColumn = ({ name, idField, selectAll, onSelect, rowCheckBoxProps }, tableState, setState, intl) => ({
    field: 'checkBox',
    thClassName: 'datatable-selection-col',
    display: (item) => {
        const { disableSelectRows, selection } = tableState.current;
        return <RowCheckBox
            tableName={name} id={getId(item, idField)}
            isSelected={() => selection.has(getId(item, idField))}
            action={getSelectAction(onSelect, () => setState({ type: 'selectItem', item, idField, disableSelectRows }), item)}
            disableSelectRows={disableSelectRows}
            {...rowCheckBoxProps?.(item)}
        />
    },
    sortable: false,
    label: selectAll ? () => {
        const { disableSelectRows, selection, filteredData } = tableState.current;
        return <RowCheckBox
            tableName={name} id={'allSelection'}
            isSelected={() => ((selection.size >= (filteredData.length - disableSelectRows.size)) && ((filteredData.length - disableSelectRows.size) !== 0))}
            action={() => setState({ type: 'selectAll', filteredData, idField, disableSelectRows })}
            disableSelectRows={disableSelectRows}
            aria-label={intl.formatMessage({ id: "ups.table.select-all.label" })}
        />
    } : '',
    responsivePriority: 1
})

const makeMobileCardColumn = ({ name, idField, columns, selectEnabled, onSelect }, tableState, setState) => ({
    label: "",
    field: "mobileCard",
    sortable: false,
    thClassName: selectEnabled
        ? "datatable-selection-col datatable-card no-export"
        : "datatable-card no-export d-none",
    tdClassName: selectEnabled
        ? "datatable-selection-col datatable-card no-export"
        : "datatable-card no-export d-none",
    display: item => {
        const { selection, disableSelectRows } = tableState.current;
        return <MobileCard
            name={name}
            id={getId(item, idField)}
            columns={columns}
            item={item}
            selection={selection}
            selectAction={selectEnabled ? getSelectAction(onSelect, () => setState({ type: 'selectItem', item, idField, disableSelectRows }), item) : null}
            disableSelectRows={disableSelectRows}
        />
    },
    responsivePriority: 0
})

const getFullColumns = (props, tableState, setState, intl) => {
    const { columns, selectEnabled, mobileCard } = props;
    if (selectEnabled || mobileCard) {
        const extraColumns = []
        if (mobileCard) extraColumns.push(makeMobileCardColumn(props, tableState, setState))
        if (selectEnabled) extraColumns.push(makeSelectColumn(props, tableState, setState, intl))
        return extraColumns.concat(columns)
    }
    else return columns
}

function FilteredDataTableV3(props) {
    const {data, idField, name, selectEnabled, selectAction, searchable, buttons, filterFunc, searchRight, delayedSearch,
        searchAction, checkSelectable, columns, tableState, tableStateAction, clearSelectionOnDataChange, searchInputAriaLabel,
        className, mobileCard, printable, wrapperClassName, selectAll, externalFilterActive, onClearFilter, filterType, ...tableProps } = props;
    const setState = reducer(tableStateAction)
    const { filteredData, filter, searchQuery } = tableState;
    const filterActive = externalFilterActive || hasFilter(filter, data, filteredData)
    const printRef = useRef()
    const searchTimer = useRef(0)
    const printAction = printRef.current;
    const intl = useIntl()
    const doFilter = (type) => setState({ type: type, data, selectEnabled, checkSelectable, idField, filterFunc, columns, searchable, searchQuery })

    const firstRender = useRef(true) //performance optimization to avoid multiple re-renders on first mount

    const currentTableState = useRef() //this is similar to this.state, the point of it is to not have to recreate the list of columns every time a tableState value changes
    currentTableState.current = tableState //increases selection performance drastically when there are lots of rows of data

    const fullColumns = useMemo(() => getFullColumns(props, currentTableState, setState, intl), [columns])

    useEffect(() => {
        //re-apply filter if search query or filter change
        doFilter('filterData')
    }, [filter, intl.locale])

    useEffect(() => {
        if (!firstRender.current) {
            if (!delayedSearch) doFilter('filterData')
            else {
                clearTimeout(searchTimer.current)
                searchTimer.current = setTimeout(() => doFilter('filterData'), 500)
            }
        }
    }, [searchQuery])
    useEffect(() => {
        if (firstRender.current) firstRender.current = false; //we already applied the filter on the first render due to the above useEffect
        else {
            //re-apply filter if the raw data changes. In many cases this also means we want to clear the current selection if any.
            doFilter((selectEnabled && clearSelectionOnDataChange) ? 'filterDataAndClearSelection' : 'filterData')
        }
    }, [data])

    return <div data-test="table">
        {printable && <DatatableV3ToPrint {...props} data={filteredData} assignPrintAction={(action) => printRef.current = action} />}
        <div
            id={`${name}_wrapper`}
            className={classNames("dataTables_wrapper datatable-v3 datatable-v3-filterWrapper", wrapperClassName)}
        >
            <MDBRow className='dt-header-row'>
                <MDBCol size={"12"} md={"4"} className="dt-search-col">
                    {(searchable && !searchRight) && <SearchInput name={name} searchInputAriaLabel={searchInputAriaLabel} searchQuery={searchQuery} setState={setState} intl={intl} columns={columns} rowCount={filteredData.length} />}
                    {(filterFunc ?? externalFilterActive) && <FilterIndicator filterType={filterType} filterActive={filterActive} />}
                </MDBCol>
                <MDBCol size={"12"} md={"8"} className="dt-buttons-col">
                    {buttons && renderButtons(props, { filterActive, printAction, intl })}
                    {(searchable && searchRight) && <SearchInput name={name} searchInputAriaLabel={searchInputAriaLabel} searchQuery={searchQuery} setState={setState} intl={intl} rowCount={filteredData.length} />}
                </MDBCol>
            </MDBRow>
            {tableState.firstFilterDone && (
                <DataTableV3
                    {...tableProps}
                    className={classNames(className, 'table-sm', { 'selectAll': selectAll }, { 'table-card-mobile': mobileCard })}
                    data={filteredData}
                    columns={fullColumns}
                    hasWrapper
                    filterActive={externalFilterActive || (filteredData.length < data.length)}
                    clearFilter={onClearFilter ?? (() => setState({ type: 'clearFilter' }))}
                    totalRows={data.length}
                    storeSorting={(sortData) => { setState({ type: 'storeSortingState', ...sortData }) }}
                />
            )}
        </div>
    </div>
}

FilteredDataTableV3.defaultProps = {
    name: 'datatable',
    idField: 'id',
    delayedSearch: false,
    clearSelectionOnDataChange: true
}

FilteredDataTableV3.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string, //used to tag the datatable components with classes/ids
    idField: PropTypes.string, //which field in the raw data to use as a unique identifier (used for selection)
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            serialize: PropTypes.func, // use this func to represent each column's rowdata as a string. Required if you parse the raw data for this column in any way. Used for exporting, printing, filtering, searching.
            mobileCardPrimary: PropTypes.bool, // make this column's data show in the bolded header portion of the mobile card. Only one column per table can use this property.
            mobileDisplay: PropTypes.func, // optional render function for column cell data when in mobile card view, otherwise uses standard display function
            mobileHidden: PropTypes.bool, // hide this column's data from mobile view
            noExport: PropTypes.bool // prevent this column from being included in printed output or exported document output. (columns with a display function but no serialize function will also not be exported.)
        })
        //more column properties are described in the core datatablev3 code.
    ),
    className: PropTypes.string, // optional class style for table
    wrapperClassName: PropTypes.string, // optional class style for the table wrapper
    tableState: PropTypes.shape({
        filteredData: PropTypes.arrayOf(PropTypes.object).isRequired,
        filter: PropTypes.object, //current filter on the table
        selection: PropTypes.instanceOf(Map), //currently selected rows
        searchQuery: PropTypes.string //current search query on the table
    }).isRequired, //state for this table defined in parent state, so the parent has access to these properties
    tableStateAction: PropTypes.func.isRequired, //state update function passed from parent so both this and the parent component can read/modify table state
    buttons: PropTypes.arrayOf(PropTypes.oneOfType([ // assign action buttons to the top right of the table
        PropTypes.shape({ //buttons rendered in default manners
            type: PropTypes.string, //what button type to render. 'primary' by default, can be 'secondary', 'iconButton' or other default buttons defined in the button rendering function above.
            text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), //contents of text buttons
            icon: PropTypes.node, //pass the icon to be rendered as a react node for icon buttons.
            action: PropTypes.func, //action to take on button click
            hide: PropTypes.bool, //whether the button is hidden currently
        }),
        PropTypes.func //button rendered however way you want
    ])),
    searchable: PropTypes.bool, // whether the table features a search query field
    selectEnabled: PropTypes.bool, // turn on or off row checkboxes feature in the table
    clearSelectionOnDataChange: PropTypes.bool, // whether the list of currently selected rows is cleared every time the incoming table data changes (default: true)
    delayedSearch: PropTypes.bool, // search can be slow with large datasets, so we can make it happen on a timer instead of instantly as you type for better performance
    onSelect: PropTypes.func, // optional function to run when clicking a row select box
    checkSelectable: PropTypes.func, // used to check if a row is selectable (only needed if you want rows to sometimes have their checkbox disabled)
    selectAll: PropTypes.bool, // turn on or off select all checkbox feature in the table
    filterFunc: PropTypes.func, // optional function that will be used to filter the table based on the current filter
    externalFilterActive: PropTypes.bool, // whether a filter is currently set. This prop is only used if you are filtering outside of datatablev3, like on the backend.
    onClearFilter: PropTypes.func, // function that clears the active filter. This prop is only used if you are filtering outside of datatablev3, like on the backend.
    mobileCard: PropTypes.bool, // whether a mobile card view should be automatically generated for the table
    printable: PropTypes.bool, // whether the table will have a button to print it (must pass button somewhere in buttons prop)
    searchRight: PropTypes.bool, //search bar is rendered at the right side of the header
    rowCheckBoxProps: PropTypes.func, // optional function that returns additional props to pass into RowCheckBox(for eg. aria-label for checkbox)
    searchInputAriaLabel: PropTypes.string,// optional field for search input aria label.
    filterType: PropTypes.string // optional field to indicate the type of filters, currently it is either EMAIL or empty/undefined. Different message would be displayed for these 2 cases.
};

export default FilteredDataTableV3;
