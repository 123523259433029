import React, {Component} from "react";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from "mdbreact";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {FormattedMessage, injectIntl} from 'react-intl';
import InputFieldIntl from '../../../components/InputFieldIntl';
import DateField from '../../../components/DateField';
import CheckboxWithValidation from "../../CheckboxWithValidation";
import EnrollmentInfoMessage from "../EnrollmentInfoMessage";
import attachValidator from "../../../utils/validation/attach-validator.js";
import { padAccountNumber, formatAmount, countryUPSType } from '../../../utils/ups-utils';

import classNames from "classnames";
import {set as lodashSet} from 'lodash';

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import produce from "immer";
import PageError from "../../PageError";
import moment from "moment";
import * as validationActions from "../../../actions/validation-action";
import {
    InputRestrictors as restrict,
    StandardValidations as stdv
} from "../../../utils/validation/standard-validations.js";
import CurrencyFieldIntl from '../../../components/CurrencyFieldIntl';
import { COUNTRY_UPS_CODE } from '../../../constants/country-ups-type';
import { BUSINESS_UNIT } from '../../../constants/business-unit';
import MDBBtnWrapper from "../../MDBFix/MDBBtnWrapper";
import { MDBPopoverWrapper } from "../../MDBFix/MDBPopoverWrapper";
import supportLinks from '../../../constants/support-links';
import  path from 'path';
class PlansInvoiceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
        attachValidator.call(this)
    }

    render() {

        const {focused, invoiceNumber, invoiceAmount, invoiceDate, invoiceCharges, accountNumber, acceptPaymentTerms, postSubmit, invoiceControlId} = this.state;
        const { isModal, account, user, userInfo, intl, merchant, merchants } = this.props;
        const maxDays = countryUPSType(account.countryCode) == "Domestic" ? 45 : 45;

        let maxCurrencyDigits = merchant?.maxCurrencyDigits;
        if(!merchant && account){
            maxCurrencyDigits = merchants.find(x => x.countryCode === account.countryCode)?.maxCurrencyDigits;  
        }
        const { supportBaseUrl, termsResource  } = supportLinks;
        const {selectedCountry: countryCode, locale} = userInfo || user || {};
        const selectedCountryCode = countryCode?countryCode.toLowerCase():'';
        const language = locale? locale.split('-')[0].toLowerCase():''; 
        const urlPaymentusTerms = supportBaseUrl + path.join(selectedCountryCode, language, termsResource);
        
        return (
            <React.Fragment>
                <section id="enrollmentInvoiceDetailsSection" className={classNames({"ups-enroll-subsection": true, "mb-4 non-modal": !isModal})}>
                    <MDBCard>
                        {!isModal && <MDBCardHeader tag="h1" className={"p-0 mb-3"}><FormattedMessage
                            id="invoiceDetails.title"/></MDBCardHeader>}
                        <MDBCardBody className={"p-0"}>
                            {postSubmit && <PageError/>}
                            <EnrollmentInfoMessage enrollMode={!isModal} country={isModal ? account.countryCode : countryCode} language={language?.substr(0, 2)} accountType = {account.accountType}/>

                            <p className="ups-note-1">*<span className="font-italic"><FormattedMessage
                                id={"ups.required-field.note"}/></span></p>

                            {/* Order; invoice number, amount due, control id(only for US/CA and UPS Package), invoice date, */}
                            <CurrencyFieldIntl
                                    key={"invoiceAmount"}
                                    name={"invoiceAmount"}
                                    id={"invoiceAmount"}
                                    label={<FormattedMessage id={"invoice.amountDue.plan.label"}/>}
                                    value={invoiceAmount}
                                    onChange={this.handleInput}
                                    onBlur={this.validateInput}
                                    hint={`invoice.amountDue.plan.hint${Number.isNaN(parseInt(maxCurrencyDigits)) ? '': ("." + maxCurrencyDigits)}`}
                                    restrict={restrict.currency}
                                    maxLength={19}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['currency', "field.currency"]
                                    ]}
                                    maxCurrencyDigits={maxCurrencyDigits}
                                />

                                <DateField
                                    name={"invoiceDate"}
                                    label={<FormattedMessage
                                        id='invoice.date.label'/>}
                                    ariaLabel={intl.formatMessage({id:'invoice.date.label'})}
                                    date={invoiceDate}
                                    onDateChange={date => this.setState({postSubmit: false, invoiceDate: date})}
                                    focused={focused}
                                    onFocusChange={({focused}) => this.setState({focused})}
                                    onBlur={this.validateInput}
                                    range={day =>
                                        (moment().startOf('day').diff(moment(day, "YYYY-MM-DD").startOf('day'), 'days') > maxDays) ||
                                        (moment().startOf('day').diff(moment(day, "YYYY-MM-DD").startOf('day'), 'days') < 0)
                                    }
                                    id="invoiceDate"
                                    restrict={restrict.datemmddyyyy}
                                    required
                                    validations={[
                                        ['required', `invoiceDate.required${maxDays}`],
                                        ['daterange', `field.mmddyyyy.past${maxDays}`, {min: maxDays, max: 0, scale: 'days'}]
                                    ]}
                                />

                            {
                                (countryUPSType(account.countryCode) == COUNTRY_UPS_CODE.DOMESTIC && account.businessUnit == BUSINESS_UNIT.EBS) ?
                                    <>
                                        <InputFieldIntl
                                            key={"invoiceControlId"}
                                            name={"invoiceControlId"}
                                            id={"invoiceControlId"}
                                            label={<FormattedMessage id={"invoice.control.id.label"} />}
                                            value={invoiceControlId}
                                            onChange={this.handleInput}
                                            onBlur={this.validateInput}
                                            maxLength={4}
                                            restrict={stdv.alphanumeric}
                                            hint={"invoice.control.id.hint"}
                                            required
                                            validations={[
                                                ['alphanumeric', "controlId.alphanumeric"],
                                                (value) => ((value && value.length < 4 && value.length != 0) ? "controlId.minmaxlength4" : "")
                                            ]}
                                        />
                                        <p className="ups-control-id-note-1"><span className="font-italic"><FormattedMessage id={"ups.control.id.note"} /></span></p>
                                    </>
                                    : null
                            }

                                <InputFieldIntl
                                    key={"accountNumber"}
                                    name={"accountNumber"}
                                    id={"accountNumber"}
                                    label={<FormattedMessage id="invoice.number.plan.label"/>}
                                labelRightElement={<MDBPopoverWrapper
                                    id="invoiceNumber"
                                    buttonLabel={`${intl.formatMessage({ id: "invoice.number.plan.label" })} ${intl.formatMessage({ id: "ups.enroll.business.unit.label" })}`}
                                    content={<><FormattedMessage id="invoice.number.plan.tip" /></>} />}
                                    value={accountNumber}
                                    onChange={this.handleInput}
                                    hint={"invoice.number.plan.hint"}
                                    maxLength={6}
                                    restrict={stdv.alphanumeric}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['alphanumeric', "field.alphanumeric"],
                                        ['minmaxlength', "field.minmaxlength6", {min: 6, max: 13}]
                                    ]}
                                />

                                <CurrencyFieldIntl
                                    key={"invoiceCharges"}
                                    name={"invoiceCharges"}
                                    id={"invoiceCharges"}
                                    label={<FormattedMessage id="invoice.charges.label"/>}
                                labelRightElement={<MDBPopoverWrapper
                                    id="invoiceCharges"
                                    buttonLabel={`${intl.formatMessage({ id: "invoice.charges.label" })} ${intl.formatMessage({ id: "ups.enroll.business.unit.label" })}`}
                                    content={<><FormattedMessage id="invoice.charges.tip" /></>} />}
                                    value={invoiceCharges}
                                    onChange={this.handleInput}
                                    onBlur={this.validateInput}
                                    hint={`invoice.charges.hint${Number.isNaN(parseInt(maxCurrencyDigits)) ? '': ("." + maxCurrencyDigits)}`}
                                    restrict={restrict.currency}
                                    maxLength={19}
                                    required
                                    validations={[
                                        ['required', "field.plural.required"],
                                        ['currency', "field.currency"]
                                    ]}
                                    maxCurrencyDigits={maxCurrencyDigits}
                                />

                                {isModal &&
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <CheckboxWithValidation
                                            name="acceptPaymentTerms"
                                            id="acceptPaymentTerms"
                                            label={<FormattedMessage id="payment-authorization-terms.authorize.label"/>}
                                            containerClass={"py-0 pl-0 m-0 "}
                                            labelClass="mr-0 font-weight-bold"
                                            value={acceptPaymentTerms || false}
                                            onChange={this.handleInput}
                                            required
                                            validations={[
                                                ['required', "field.authorizePaymentTerms"]
                                            ]}
                                        >
                                            <a role='link' title={intl.formatMessage({id:"ups.new-tab.link.label"})} href={urlPaymentusTerms} target={"_blank"} className={"text-blue"}><FormattedMessage id={"payment-authorization-terms.label"} /></a>
                                        </CheckboxWithValidation>
                                    </MDBCol>
                                </MDBRow>
                                }
                        </MDBCardBody>
                    </MDBCard>
                </section>
                <section className={classNames({"ups-section ups-enroll-subsection ups-btn-section": true, "mb-4 non-modal": !isModal})}>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                        <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.back.label"})} color={"secondary"} id="enroll-invoice-details-btn-back" onClick={this.props.handleRestart}>
                            </MDBBtnWrapper>
                            <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.continue.label"})} color={"primary"} id="enroll-invoice-details-btn-next" onClick={this.validateAccount}>
                            </MDBBtnWrapper>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                        <MDBBtnWrapper label={intl.formatMessage({id: "ups.btn.enroll.cancel.label"})} id="enroll-invoice-details-btn-cancel" flat className={classNames({"btn-flat-link": true})}
                                    onClick={this.props.handleCancel}>
                            </MDBBtnWrapper>
                        </MDBCol>
                    </MDBRow>
                </section>
            </React.Fragment>

        );
    }

    handleInput = e => {
        const {name, value, checked, type} = e.target;
        switch (type) {
            case 'text':
                this.setState(
                    produce(draft => {
                        draft.postSubmit = false;
                        lodashSet(draft, name, value);
                    })
                );
                break;
            case 'checkbox':
                this.setState(
                    produce(draft => {
                        draft.postSubmit = false;
                        lodashSet(draft, name, checked);
                    })
                );
                break;
            default: break;
        }
    };

    validateInput = args => {
        const {name, value} = args;

        this.setState(produce(draft => {
            lodashSet(draft, name, value);
        }));
        this.validator.validate(name, value);
    };

    validateAccount = () => {
        this.setState({postSubmit: true});
        let result = this.validator.validateAll()
        if (result.messages.length > 0) return false;
        const {account} = this.props;
        const {invoiceAmount, invoiceDate, invoiceCharges, accountNumber, invoiceControlId} = this.state;
        const data = {
            account: {
                countryCode: account.countryCode,
                businessUnit: account.businessUnit,
                accountNumber: padAccountNumber(accountNumber.toUpperCase()),
                accountType: account.accountType,
                currencyCode: account.currencyCode,
                hid: account.hid
            },
            invoice: {
                //invoiceNumber: invoiceNumber ? invoiceNumber.padStart(15, '0') : null,
                invoiceNumber: '',
                invoiceAmount: invoiceCharges,
                invoiceDate: invoiceDate,
                // planNumber: account.accountNumber.toUpperCase(),
                planNumber: account.planNumber?.toUpperCase(),
                planTotalInvoice: invoiceAmount,
                ...(invoiceControlId ? { invoiceControlId } : {})

            }
        }
        this.props.submitAction(data);
    }
}

function mapStateToProps(state) {
    return {
        account: state.account.account,
        user: state.auth.user,
        userInfo: state.auth.userInfo,
        merchant: state.config?.sessionSelection?.merchant,
        merchants: state.config?.merchants,
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PlansInvoiceDetails));