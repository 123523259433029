import React                               from "react";
import * as R                              from 'ramda';
import {useIntl, FormattedMessage}         from "react-intl";
import classnames                          from 'classnames';
import MediaQuery                          from 'react-responsive';
import {MDBBtn}                            from 'mdbreact';
import {useSelector}                       from 'react-redux';

import {
    stripAccountNumber,
    getInvoiceNumLabel,
    getInvoiceDateLabel
}                                          from "../../utils/ups-utils";
import {formatInvoiceDate, formatCurrency} from "../../utils/invoice-utils";
import {accountSupportsDebitOnly}          from "../../utils/payment-utils";

import DataTableV3                         from "../../components/DataTableV3/DataTableV3";
import CheckboxIntl                        from "../../components/CheckboxIntl";

const f = intl => R.compose(
    args => intl.formatMessage(...args),
    (id, values) => [{id}, values]
);

const getTableColumns = (intl, businessUnit, onChange, onEdit, isOnlyCreditNotes) => [
    {
        label: "",
        field: "mobileCard",
        sortable: false,
        thClassName: onChange
            ? "datatable-selection-col datatable-card no-export"
            : "datatable-card no-export d-none",
        tdClassName: onChange
            ? "datatable-selection-col datatable-card no-export"
            : "datatable-card no-export d-none",
        display: inv => <MobileCardWithCheckbox
            invoice={inv}
            onChange={onChange}
            onEdit={onEdit}
            isOnlyCreditNotes={isOnlyCreditNotes}
            label={getInvoiceNumLabel(intl)(businessUnit)}
        />
    },
    {
        label: f(intl)("invoice.invoice-table.account-number"),
        field: "accountNumber",
        display: inv => <AccountCell invoice={inv}/>
    },
    {
        label: getInvoiceNumLabel(intl)(businessUnit),
        field: "invoiceNumber"
    },
    {
        label: getInvoiceDateLabel(intl)(businessUnit),
        field: "invoiceDate",
        display: ({invoiceDate}) => formatInvoiceDate(intl, invoiceDate)
    },
    {
        label: f(intl)("invoice.invoice-table.invoice-due-date"),
        field: "dueDate",
        display: ({dueDate}) => formatInvoiceDate(intl, dueDate)
    },
    {
        label: f(intl)("invoice.invoice-table.amount-due"),
        field: "outstandingAmount",
        thClassName: "text-right",
        tdClassName: "text-right",
        display: ({outstandingAmount, currencyCode, currencyDisplay}) => formatCurrency(intl, outstandingAmount, currencyCode, currencyDisplay)
    },
    {
        label: f(intl)("make-payment.payment-components-table.payment-amount"),
        field: "paymentAmount",
        sortable: !onEdit,
        thClassName: "payment-amount-col padding-right",
        tdClassName: "payment-amount-col text-right text-nowrap",
        display: inv => <PaymentAmountField invoice={inv} onEdit={onEdit} isOnlyCreditNotes={isOnlyCreditNotes}/>
    }
];

function AccountCell({invoice: inv}) {
    return <div>
        {inv.planNumber &&
            <div><FormattedMessage
                id="modal.view-schedule.plan.labeled"
                values={{plan: stripAccountNumber(inv.planNumber, inv.businessUnit)}}
            /></div>
        }
        {inv.accountNumber &&
            <div><FormattedMessage
                id="modal.view-schedule.account.labeled"
                values={{account: stripAccountNumber(inv.accountNumber, inv.businessUnit)}}
            /></div>
        }
        <span className="card-display-item display-accepted-payment-method">
            {accountSupportsDebitOnly(inv) &&
                <div>
                    <i className="fa fa-university mr-1"/>
                    <FormattedMessage id="invoice.payment-method-validity.label" />
                </div>
            }
        </span>
    </div>;
}

function EditBtn({ onClick, disabled }) {
    const intl = useIntl();
    return <MDBBtn aria-label={intl.formatMessage({id:"ups.btn.edit.label"})} color="datatable-text-link" className="btn-short-pay" onClick={onClick} disabled={disabled}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
            width="32" height="32" viewBox="0 0 32 32"
        >
            <title><FormattedMessage id="ups.btn.edit.label"/></title>
            <path d={
                `M31.75 8.75l-8-8.313c-0.25-0.313-0.438-0.313-0.688-0.438-0.313
                0-0.625 0.125-0.75 0.313l-18.938 18.875c-0.125 0.188-0.125 0.313-0.25
                0.438l-3.063 11.375c-0.125 0.25 0 0.688 0.313 1 0.125 0.125 0.438
                0.313 0.688 0.313 0.188 0 0.188 0 0.313 0l11.188-3.063c0.125 0
                0.313-0.188 0.438-0.313l18.75-18.75c0.438-0.438 0.438-1.188
                0-1.438zM4.563 21.938l6.25 5.688-8.438 2.313zM12.563
                26.5l-7.125-6.438 17.438-17.438 6.688 6.813z`
            }></path>
        </svg>
    </MDBBtn>;
}

function PaymentAmountField({invoice: inv, onEdit, isOnlyCreditNotes}) {
    const intl = useIntl();
    const {merchant} = useSelector(s=>s.config.sessionSelection);
    return <div>
        <div className={classnames('datatable-cell-pay-amount', {'p-0': !onEdit})}>
            {formatCurrency(intl, inv.paymentAmount, inv.currencyCode, merchant.currencyDisplay)}
        </div>
        {(isOnlyCreditNotes || (inv.paymentAmount > 0)) && onEdit &&
            <EditBtn
                onClick={() => onEdit(inv)}
                disabled={inv.deselected}
            />
        }
    </div>;
}

function MobileCardWithCheckbox({invoice: inv, onChange, onEdit, isOnlyCreditNotes, label}) {
    const intl = useIntl();
    const {selectedPaymentType: businessUnit} = useSelector(s => s.auth.user);
    const {merchant} = useSelector(s=>s.config.sessionSelection);

    return <div className={classnames('table-card-mobile-container', {'has-selection-input': onChange})}>
        {onChange && <CheckboxIntl
            name={`select-${inv.id}`}
            checked={!inv.deselected}
            onChange={({target: {checked}}) => onChange(
                inv.id,
                {...inv, deselected: !checked}
            )}
            containerClass="form-check form-check-inline m-0"
            label={<React.Fragment>
                <span className="table-card-mobile-hide">&nbsp;</span>
                <span className="table-card-mobile-only-label">
                    <AccountCell invoice={inv}/>
                </span>
            </React.Fragment>}
            aria-label={`${label} ${inv.invoiceNumber}`}
        />}

        <MediaQuery maxWidth={767}>
        {!onChange && <div className="table-card-important-info">
            <AccountCell invoice={inv}/>
        </div>}
        <table className="table table-card-mobile-view">
            <tbody>
                <tr>
                    <th>{getInvoiceNumLabel(intl)(businessUnit)}</th>
                    <td>{inv.invoiceNumber}</td>
                </tr>
                <tr>
                    <th>{getInvoiceDateLabel(intl)(businessUnit)}</th>
                    <td>{formatInvoiceDate(intl, inv.invoiceDate)}</td>
                </tr>
                <tr>
                    <th>{f(intl)("invoice.invoice-table.invoice-due-date")}</th>
                    <td>{formatInvoiceDate(intl, inv.dueDate)}</td>
                </tr>
                <tr>
                    <th>{f(intl)("invoice.invoice-table.amount-due")}</th>
                    <td>{formatCurrency(intl, inv.outstandingAmount, inv.currencyCode, merchant.currencyDisplay)}</td>
                </tr>
                <tr>
                    <th>{f(intl)("make-payment.payment-components-table.payment-amount")}</th>
                    <td className={classnames({'pt-0': onEdit})}>
                        <PaymentAmountField invoice={inv} onEdit={onEdit} isOnlyCreditNotes={isOnlyCreditNotes}/>
                    </td>
                </tr>
            </tbody>
        </table>
        </MediaQuery>
    </div>;
}

export default function PayInvoiceTable({invoices, onChange, onEdit, isOnlyCreditNotes, caption}) {
    const intl = useIntl();
    const {selectedPaymentType: businessUnit} = useSelector(s => s.auth.user);
    const {merchant} = useSelector(s=>s.config.sessionSelection);
    const data = [...invoices].map(p => {
        return {...p[1], currencyDisplay: merchant.currencyDisplay};
    });

    return <DataTableV3
    caption={caption}
        className="table-sm table-card-mobile allow-default-wrap"
        trClassName={R.compose(
            classnames,
            v => ({"disable-payment-row": v}),
            R.propOr(false, "deselected")
        )}
        data={data}
        columns={getTableColumns(intl, businessUnit, onChange, onEdit, isOnlyCreditNotes)}
        itemsPerPage={[10, 20, 50, 100]}
    />;
};
