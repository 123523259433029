import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as identityActions from "../../../../actions/identity-action";
import * as validationActions from "../../../../actions/validation-action";
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIntl from '../../../CheckboxIntl';
import { withRouter } from 'react-router-dom';
import OptOutPreferencesComponent from './OptOutPreferencesComponent';
import {PREF_SECTION} from '../../../../constants/preferences-constants';
import { countryUPSType } from '../../../../utils/ups-utils.js';
import { COUNTRY_UPS_CODE } from '../../../../constants/country-ups-type';
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';

class PaymentsSection extends OptOutPreferencesComponent {

    constructor(props) {
        super(props);
        const { preferences } = this.props;
        this.state = {
            onPastDue: preferences.notifications.emailSettings.triggers.onPastDue,
            notifyOnCardExpiry: preferences.notifications.emailSettings.triggers.notifyOnCardExpiry,
            onPaymentFailed: preferences.notifications.emailSettings.triggers.onPaymentFailed,
            onPaymentSubmitted: preferences.notifications.emailSettings.triggers.onPaymentSubmitted,
            onPaymentSuccessful: preferences.notifications.emailSettings.triggers.onPaymentSuccessful,
            onAutoPayBalanceExceeds: preferences.notifications.emailSettings.triggers.onAutoPayBalanceExceeds,
            onAutoPayCreated: preferences.notifications.emailSettings.triggers.onAutoPayCreated,
            onAutoPayDeleted: preferences.notifications.emailSettings.triggers.onAutoPayDeleted,
            notifyForAutoPayChange: preferences.notifications.emailSettings.triggers.notifyForAutoPayChange,
            propChangeCounter: 0,
            isDomesticCountry: true
        }
    }

    componentWillMount(){
        const {selectedCountry} = this.props.auth.user;
        const {isDomesticCountry} = this.state;
        (countryUPSType(selectedCountry) === COUNTRY_UPS_CODE.DOMESTIC) ? 
        this.setState({isDomesticCountry: true}) : this.setState({isDomesticCountry: false});
    }
    
    componentDidMount(){
        this.props.setRef(this);
    }

    validateTriggers = () => {        
        this.setState({
            propChangeCounter : 0
        }, () => {
            this.props.updateNotificationPreferences(PREF_SECTION.PAYMENTS);
        });        
    };

    togglePayments = () => {
        this.setState({
            onPastDue: false, 
            notifyOnCardExpiry: false,
            onPaymentFailed: false, 
            onPaymentSubmitted: false, 
            onPaymentSuccessful: false, 
            onAutoPayBalanceExceeds: false,
            onAutoPayCreated: false, 
            onAutoPayDeleted: false, 
            notifyForAutoPayChange: false
        }, () => {
            this.onPreferenceChange();
        });
    }

    render() {
        const { onPastDue, notifyOnCardExpiry, onPaymentFailed, onPaymentSubmitted, onPaymentSuccessful, 
                onAutoPayBalanceExceeds, onAutoPayCreated, onAutoPayDeleted, notifyForAutoPayChange, isDomesticCountry } = this.state;
        const { auth, intl } = this.props;

        return (
            <MDBRow className="col-md-6 mt-2">
                <MDBCol size="12" className="lg-6 mb-4">
                    <MDBCard className="card dashboard-widget-card min-mh-0 h-100  outline-card">
                        <MDBCardTitle tag="h2">
                            <FormattedMessage id="ups.preferences.payments.title" />
                            <MDBCardBody>
                                <MDBCol size="12">
                                    <fieldset className="notify-align mt-4">
                                        <legend>
                                            <FormattedMessage id="ups.preferences.notification.notifyWhen" />
                                        </legend>
                                        <MDBRow className="invoices-align">
                                            { isDomesticCountry &&
                                                <MDBCol size="12">
                                                    <CheckboxIntl
                                                        name={"onPastDue"}
                                                        type={"checkbox"}
                                                        label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-1" /></span>}
                                                        value={onPastDue}
                                                        containerClass="mb-0 mt-3 p-0"
                                                        onClick={() => this.setState({onPastDue: !onPastDue}, () => {this.onPreferenceChange("onPastDue")})}
                                                        checked={onPastDue}
                                                    />
                                                </MDBCol>
                                            }
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"notifyOnCardExpiry"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-2" /></span>}
                                                    value={notifyOnCardExpiry}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({notifyOnCardExpiry: !notifyOnCardExpiry}, () => {this.onPreferenceChange("notifyOnCardExpiry")})}
                                                    checked={notifyOnCardExpiry}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onPaymentFailed"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-3" /></span>}
                                                    value={onPaymentFailed}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onPaymentFailed: !onPaymentFailed}, () => {this.onPreferenceChange("onPaymentFailed")})}
                                                    checked={onPaymentFailed}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onPaymentSubmitted"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-4" /></span>}
                                                    value={onPaymentSubmitted}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onPaymentSubmitted: !onPaymentSubmitted}, () => {this.onPreferenceChange("onPaymentSubmitted")})}
                                                    checked={onPaymentSubmitted}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onPaymentSuccessful"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-5" /></span>}
                                                    value={onPaymentSuccessful}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onPaymentSuccessful: !onPaymentSuccessful}, () => {this.onPreferenceChange("onPaymentSuccessful")})}
                                                    checked={onPaymentSuccessful}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl 
                                                    name={"onAutoPayBalanceExceeds"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-6" /></span>}
                                                    value={onAutoPayBalanceExceeds}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onAutoPayBalanceExceeds: !onAutoPayBalanceExceeds}, () => {this.onPreferenceChange("onAutoPayBalanceExceeds")})}
                                                    checked={onAutoPayBalanceExceeds}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onAutoPayCreated"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-7" /></span>}
                                                    value={onAutoPayCreated}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onAutoPayCreated: !onAutoPayCreated}, () => {this.onPreferenceChange("onAutoPayCreated")})}
                                                    checked={onAutoPayCreated}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"onAutoPayDeleted"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-8" /></span>}
                                                    value={onAutoPayDeleted}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({onAutoPayDeleted: !onAutoPayDeleted}, () => {this.onPreferenceChange("onAutoPayDeleted")})}
                                                    checked={onAutoPayDeleted}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <CheckboxIntl
                                                    name={"notifyForAutoPayChange"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.notifications.payments.option-9" /></span>}
                                                    value={notifyForAutoPayChange}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={() => this.setState({notifyForAutoPayChange: !notifyForAutoPayChange}, () => {this.onPreferenceChange("notifyForAutoPayChange")})}
                                                    checked={notifyForAutoPayChange}
                                                />
                                            </MDBCol>
    
                                            <MDBCol size="12">
                                                <div className="section-separator my-4"></div>
                                            </MDBCol>
    
                                            <MDBCol size="12" className="section-align">
                                                <CheckboxIntl
                                                    name={"donotSendAnyPayments"}
                                                    type={"checkbox"}
                                                    label={<span><FormattedMessage id="ups.preferences.payment-btn" /></span>}
                                                    containerClass="mb-0 mt-3 p-0"
                                                    onClick={this.togglePayments}
                                                    checked={!(onPastDue || notifyOnCardExpiry ||
                                                            onPaymentSubmitted || onPaymentSuccessful || 
                                                            onPaymentFailed || onAutoPayBalanceExceeds || 
                                                            onAutoPayCreated || onAutoPayDeleted ||
                                                            notifyForAutoPayChange)}
                                                />
                                            </MDBCol>
    
                                            {auth.user.adUserId ? null :
                                                <MDBCol size="12" className="mt-3 d-flex d-md-block flex-column">
                                                    <MDBBtnWrapper label={intl.formatMessage({id: "ups.preferences.notifications.btn-save"})}  aria-label={intl.formatMessage({id: "ups.preferences.payments.save.button.label"})} color="primary" onClick={this.validateTriggers} >
                                                    </MDBBtnWrapper>
                                                </MDBCol>}
                                        </MDBRow>
                                    </fieldset>
                                </MDBCol>
                            </MDBCardBody>
                        </MDBCardTitle>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        auth: state.auth,
        merchants: state.auth.merchants,
        preferences: state.identity.preferences
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentsSection)));