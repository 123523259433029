import React, {Component} from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow} from "mdbreact";
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import { NavLink } from 'react-router-dom';
import * as identityActions from "../../../actions/identity-action";
import {bindActionCreators} from 'redux';
import {nameToString} from "../../../utils/utils";
import {countryUPSType} from "../../../utils/ups-utils";

class EnrollmentConfirmation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {user, countryCode} = this.props;
        const {firstName, lastName} = user ?? {};
        return (
            <React.Fragment>
                <section id="enrollmentConfirmationSection"
                         className={"ups-enroll-subsection mb-4 non-modal"}>
                    <MDBCard>
                        <MDBCardHeader tag="h1" className={"p-0 mb-3"}><FormattedMessage
                            id="enrollConfirmation.title"/></MDBCardHeader>
                        <MDBCardBody className={"p-0"}>
                            <p><strong><FormattedMessage id={"enrollConfirmation.welcome.label"}/>, {nameToString(firstName, lastName)}!</strong>
                            </p>
                            <p><FormattedMessage id={"enrollConfirmation.line1-1"}/>&nbsp;
                                {countryUPSType(countryCode) === "Domestic" ?
                                    <FormattedMessage id={"enrollConfirmation.line1-2"}/>
                                :''}
                            </p>
                            <NavLink to="/home" id={"link-visit-billing-center"}><FormattedMessage
                                id={"enrollConfirmation.line2a"}/></NavLink>&nbsp;
                            <FormattedMessage id={"enrollConfirmation.line2b"}/>:
                            <ul>
                                <li><FormattedMessage id={"enrollConfirmation.line3"}/></li>
                                <li><FormattedMessage id={"enrollConfirmation.line4"}/></li>
                                <li><FormattedMessage id={"enrollConfirmation.line5"}/></li>
                                <li><FormattedMessage id={"enrollConfirmation.line6"}/></li>
                            </ul>
                            <NavLink to="/support" id={"link-visit-support"}><FormattedMessage
                                id={"enrollConfirmation.line7-1"}/></NavLink>&nbsp;
                            <FormattedMessage id={"enrollConfirmation.line7-2"}/>
                        </MDBCardBody>

                    </MDBCard>
                </section>

                <section className={"ups-section ups-enroll-subsection ups-btn-section mb-4 non-modal"}>
                    <MDBRow className={"mb-1"}>
                        <MDBCol size={"12"} className={"mt-3 mt-sm-0 text-center"}>
                            <MDBBtn color="primary" onClick={this.gotoWallet}>
                                <FormattedMessage id="ups.btn.add-payment-info.label"/>
                            </MDBBtn>
                            <MDBBtn color="secondary" id="enroll-confirmation-btn-billing-center" onClick={this.gotoBillingCenter}>
                                <FormattedMessage id="close.label"/>
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </section>
            </React.Fragment>
        );
    }

    gotoBillingCenter = () => {
        this.props.history.push('/home');
    };

    gotoWallet = () => {
        this.props.history.push('/ups/billing/wallet');
    };

}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions : bindActionCreators(identityActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentConfirmation);

