import React, { useEffect, useState }                    from "react";
import { useDispatch, useSelector}      from "react-redux";
import {FormattedMessage}                   from "react-intl"; 
import {getQueryParams}                 from "../utils/utils";
import {csrCallback}                     from "../actions/auth-action";

export default function CSRCallback(props) {   
    const dispatch = useDispatch();
    const [isLoaded, setPageState] = useState(false);
    const { user } = useSelector(s => s.auth);
    const { merchants } = useSelector(s => s.config);
    const { errors } = useSelector(s => s.error);
    const accessibleMerchants = useSelector(s => s.auth.merchants);

    useEffect(() => {
        const query = getQueryParams(props.location.search);
        const {code } = query;

        if(code && !isLoaded){
            dispatch(csrCallback(code));
            setPageState(true);
        }
    });

    useEffect(() => {
        // wait until merchants are in the state before redirecting        
        if (merchants) {            
            if (user && accessibleMerchants) {
                props.history.push({pathname: '/home'});
            }
          
        }
    }, [merchants, user, accessibleMerchants]);
    if(errors && errors.length > 0){
        let errorKey = errors[0];
        if(errors.findIndex(element => (element === "xotp.user.action.failed" || element === "xotp.user.action.error")) > -1){
            errorKey = "login.missing.user";
        }

        return (
            <div className={"ups-landing-container"}>
                <div className="text-center mb-4 mt-5">
                    { errorKey ? <FormattedMessage id={errorKey} /> : <FormattedMessage id="internalError"/>}
                </div>
            </div>
        )
    }

    return (
        <div className={"ups-landing-container"}>
            <div className="text-center mb-4 mt-5">
                <FormattedMessage id="login.status" />
            </div>
        </div>
    );
}
