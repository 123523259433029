import React from "react";
import { MDBBtn } from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";
import MDBBtnWrapper from "../../../MDBFix/MDBBtnWrapper";

export default ({editHandler, removeHandler, makeDefaultHandler, isPrimary, permissions, pmLabel, tokenPending, intl=useIntl()}) => <div className="card-actions">
    {editHandler && (permissions && permissions["wallet_edit"]) && !tokenPending && <MDBBtnWrapper label={intl.formatMessage({ id: "ups.btn.edit.label" })} aria-label={`${intl.formatMessage({ id: "ups.btn.edit.label" })} ${pmLabel}` }  color={"text-link"} className="btn-minimal" onClick={editHandler}>
    </MDBBtnWrapper>}

    {removeHandler && (permissions && permissions["wallet_remove"]) && <MDBBtnWrapper label={intl.formatMessage({ id: "ups.btn.remove.label" })} aria-label={`${intl.formatMessage({ id: "ups.btn.remove.label" })} ${pmLabel}` } color={"text-link"} className="btn-minimal" onClick={removeHandler}>
    </MDBBtnWrapper>}

    {permissions && permissions["wallet_add"] && !tokenPending && (!makeDefaultHandler ? null : !isPrimary
        ? <MDBBtnWrapper label={intl.formatMessage({ id: "btn-make-default" })} aria-label={`${intl.formatMessage({ id: "btn-make-default" })} ${pmLabel}` } color={"text-link"} className="btn-minimal float-right" onClick={makeDefaultHandler}>
        </MDBBtnWrapper>
        : <div tabIndex={"0"} className={"is-default-pm-card"}> <label className="sr-only" > <FormattedMessage id="ups.default.aria-label" values={{pmLabel}}/> </label><span aria-hidden={true}><FormattedMessage id="ups.default.label"/></span></div>
    )}
</div>;
