/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

// import {fetchWrapper} from '../utils/utils';
import { omitBy, isNull } from 'lodash';

import sampleScheduleListByIdentity from "../static/json/response/schedule_list_by_identity_id.json";


export function getScheduleListByIdentity(identityId, apiUrlPrefix, apiToken) {
    return sampleScheduleListByIdentity;
    // export function updateIdentity(identity, apiUrlPrefix, apiToken) {
//     const body = {//
//     };
//     const url = `${apiUrlPrefix}/schedule/list​/byIdentity​/${identityId}`,
//         method = 'GET',
//         headers = {
//             'Content-Type': 'application/json',
//             'x-api-token': apiToken
//         };
//     return fetchWrapper(url, method, headers, body);
// }
}