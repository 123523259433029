const supportLinks  = {
    'supportBaseUrl' : window.config.s3HelpUrl,
    'supportResource': 'help-support.html',
    'upsBaseUrl': 'https://' + window.config.upsHostName + '/',
    'upsPrivacyNoticeResource': '/support/shipping-support/legal-terms-conditions/privacy-notice.page',
    'privacyPolicyResource': 'privacy-policy.html',
    'upsCaliforniaResource': '/support/shipping-support/legal-terms-conditions/california-privacy-rights.page',
    'californiaResource': 'privacy-policy.html#California_Residents',
    'cookieNoticeResorce': 'privacy-policy.html#Cookie_Notice',
    'upsServiceResource': '/support/shipping-support/legal-terms-conditions.page',
    'upsWebsiteResource': '/support/shipping-support/legal-terms-conditions/website-terms-of-use.page',
    'websiteConditionsResource': 'website-conditions-of-use.html',
    'termsResource' : 'terms-and-conditions-no-fee.html',
    'upsHomeResource':'/Home.page',
    'upsProtectResource':'/support/shipping-support/legal-terms-conditions/fight-fraud.page',
    'achResource':'/ach-agreement.html',
    'upsTariffResource': '/help-center/legal-terms-conditions/tariff.page'
};

export const adaVideoSupportedCountries = [
    'us',
    'ca'
]
export default supportLinks;