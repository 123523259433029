/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ Fragment,useState,useEffect } from "react";
import { FormattedMessage,useIntl } from "react-intl";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol } from "mdbreact";
import ReactPortalModal from "../../../ReactPortalModal";
import BatchReportsTable from "../../BatchReportsTable/BatchReportsTable";
import { useSelector } from "react-redux";
import * as invoiceApi from '../../../../api/invoice-api';
import { isEmpty } from "lodash";

const BatchSearchLinksModal = ({ merchantId }) => {
  const [isBatchReportTableOpen, setIsBatchReportTableOpen] = useState(false);
  const { selectedPaymentType: businessUnit } = useSelector((s) => s.auth.user);
  const { apiUrlPrefix, apiToken } = useSelector((s) => s.envProps);
  const [dataRows, setDataRows] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    const fetchList = async () => {
      let res = await invoiceApi.getAdvancedSearchBatchReportList(apiUrlPrefix, apiToken);
      res = await res.parsedBody;
      setDataRows(res);
    };
    fetchList();
  }, [merchantId]);

  const toggleModal = ()=>{
    setIsBatchReportTableOpen(!isBatchReportTableOpen)
  }
  if(isEmpty(dataRows)){
    return null
  }
  const modalHeading = "invoice.batchReports.title";
  return (
    <Fragment>
      <MDBCol size={"12"}>
      <h2>
        <strong>
          <FormattedMessage id="invoice.batchReports.title" />
        </strong>
      </h2>
      <a role='button' tabIndex={0} className="btn-text-link btn-minimal" onClick={() => setIsBatchReportTableOpen(true)}>
        <FormattedMessage id="invoice.batchReports.button" />
      </a>
    </MDBCol>
      {isBatchReportTableOpen && <ReactPortalModal isOpen={isBatchReportTableOpen} an_label="batch-search-links">
        <MDBModal id={"batch-search-links-modal-modal"} isOpen={isBatchReportTableOpen} toggle={toggleModal} size="lg" centered disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
          <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={toggleModal}>
            <FormattedMessage id={modalHeading} />
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size={"12"}>
                <BatchReportsTable
                caption={"invoice.batchReports.title"}
                  merchantId={merchantId}
                  dataRows={dataRows}
                  apiUrlPrefix={apiUrlPrefix}
                  apiToken={apiToken}
                  businessUnit={businessUnit}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn id={"batch-search-links-modal-btn-close"} color="primary" onClick={toggleModal}>
              <FormattedMessage id={"close.label"} />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </ReactPortalModal>
      }
    </Fragment>

  );
};

export default BatchSearchLinksModal;
