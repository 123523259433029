import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import * as R from "ramda";

import Details from "../.";
import InputFieldIntl from "../../../../components/InputFieldIntl";
import DisplayOnlyField from "../../../../components/DisplayOnlyField";
import RadioGroup from '../../../RadioGroup';

import { InputRestrictors } from '../../../../utils/validation/standard-validations';
import AchAgreementConfirmation from "../../../../components/UPS/AchAgreementConfirmation/index"

const ACCOUNT_TYPES = ["CHQ", "SAV"];
const SPLIT_ROUTING_NUMBER_COUNTRIES = new Set(['CA']);
const SPLIT_ROUTING_NUMBER_TLAS = new Set(['UPCA']);
const ACCOUNT_NUMBER_LENGTHS = {CA:12, default:17}
const trim = value => value?.replace(/\D/g, '');
const trimBankAccount = value => value?.replace(/[-\/]/g, '');
const buildLocalPMRamda = R.ifElse(
    R.isNil,
    R.always({
        methodType: ACCOUNT_TYPES[0]
    }),
    R.compose(
        R.when(
            s => !ACCOUNT_TYPES.includes(s.methodType),
            R.assoc('methodType', ACCOUNT_TYPES[0])
        ),
        R.pick([
            'fiName',
            'methodType',
            'cardNickName',
            'accountNumber',
            'routingNumber',
            'cardHolderName',
            'tlas'
        ])
    )
)
const buildLocalPM = (paymentMethod)=>{
    const {routingNumber} = paymentMethod ?? {}
    return {
        ...buildLocalPMRamda(paymentMethod),
        ...(routingNumber?.length === 8 && {
            bankId: routingNumber?.substring(0,3),
            bankTransitNumber: routingNumber?.slice(-5)
        })
    }

}

export default function DDDetails({
    isModal, country, pmCategory, paymentMethod, submitAction, handleCancel,
    hideNickField, controlled, isACHAuthRequired = false
}) {
    const intl = useIntl();
    
    const [localPaymentMethod, setLocalPaymentMethod] = useState(buildLocalPM(paymentMethod));
    const {
        fiName, methodType, cardNickName, accountNumber, routingNumber, cardHolderName, authorizeDD, bankId, bankTransitNumber, tlas
    } = localPaymentMethod;

    const splitRoutingNumber = SPLIT_ROUTING_NUMBER_TLAS.has(tlas?.[0]?.tla) || (!tlas && SPLIT_ROUTING_NUMBER_COUNTRIES.has(country));
    const accountNumberLength = ACCOUNT_NUMBER_LENGTHS[country] ?? ACCOUNT_NUMBER_LENGTHS.default

    useEffect(() => {
        if (controlled) validatePM();
    }, [localPaymentMethod]);

    const handleInput = e => {
        const { name, value, checked, type } = e.target;
        switch (type) {
            case 'radio':
            case 'text':
                setLocalPaymentMethod({ ...localPaymentMethod, [name]: value });
                break;
            case 'checkbox':
                setLocalPaymentMethod({ ...localPaymentMethod, [name]: checked });
                break;
            default: break;
        }
    };

    const validatePM = () => {
        const data = {
            fiName,
            methodType,
            categoryCode: pmCategory,
            accountNumber,
            routingNumber,
            cardHolderName,
            cardNickName,
            authorizeDD,
            bankId,
            bankTransitNumber
        };
        if (paymentMethod) data.id = paymentMethod.id;
        return submitAction?.(data);
    }

    const form = <React.Fragment>
        <MDBRow>
            <MDBCol size={"12"}>
                <fieldset required>
                    <legend><FormattedMessage id={`payment-method.account-type.label`} />*</legend>
                    <div className={"form-inline"}>
                        <RadioGroup name="methodType"
                            radioProps={ACCOUNT_TYPES.map(type => ({
                                id: `methodType_${type}`,
                                label: <FormattedMessage id={`payment-method.echeck.account-type.${type}`} />,
                                name: "methodType",
                                checkedValue: type,
                                labelClass: "mr-0",
                                containerClass: "pl-0 mb-3 pr-3",
                            }))}
                            value={methodType}
                            onChange={handleInput}
                            required
                            formInline={true}
                            validations={[
                                ['required', "field.required"]
                            ]}
                        />
                    </div>
                </fieldset>
            </MDBCol>

            {!splitRoutingNumber && (controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="routingNumber" id="routingNumber"
                        label={intl.formatMessage({ id: "payment-method.echeck.routing-number" })}
                        value={routingNumber}
                        onChange={handleInput}
                        hint="payment-method.echeck.routing-number.placeholder"
                        maxLength={9}
                        restrict={InputRestrictors.numeric}
                        required
                        validations={[
                            ["required", "field.required"],
                            ["validRoutingNumberUS", "field.invalid"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({ id: "payment-method.echeck.routing-number-readonly" })}
                        value={routingNumber}
                        wrapperMode
                    />
                </MDBCol>)
            }

            {splitRoutingNumber && (controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="bankId" id="bankId"
                        label={intl.formatMessage({ id: "payment-method.echeck.bank-id" })}
                        value={bankId}
                        onChange={handleInput}
                        hint="payment-method.echeck.bank-id.placeholder"
                        maxLength={3}
                        restrict={InputRestrictors.numeric}
                        required
                        validations={[
                            ["required", "field.required"],
                            ["minlength", "field.invalid", {min: 3}]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({ id: "payment-method.echeck.bank-id-readonly" })}
                        value={bankId}
                        wrapperMode
                    />
                </MDBCol>)
            }

            {splitRoutingNumber && (controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="bankTransitNumber" id="bankTransitNumber"
                        label={intl.formatMessage({ id: "payment-method.echeck.transit-number" })}
                        value={bankTransitNumber}
                        onChange={handleInput}
                        hint="payment-method.echeck.transit-number.placeholder"
                        maxLength={5}
                        restrict={InputRestrictors.numeric}
                        required
                        validations={[
                            ["required", "field.required"],
                            ["minlength", "field.invalid", {min: 5}]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({ id: "payment-method.echeck.transit-number-readonly" })}
                        value={bankTransitNumber}
                        wrapperMode
                    />
                </MDBCol>)
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="accountNumber" id="accountNumber"
                        label={intl.formatMessage({ id: "payment-method.echeck.bank-account-number" })}
                        value={accountNumber}
                        onChange={handleInput}
                        hint="payment-method.echeck.bank-account-number.placeholder"
                        maxLength={accountNumberLength}
                        restrict={InputRestrictors.achAccountNumber}
                        required
                        validations={[
                            ["required", "field.required"],
                            ["validAch", "field.invalid"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({ id: "payment-method.echeck.bank-account-number-readonly" })}
                        value={accountNumber}
                        wrapperMode={true}
                    />
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="fiName" id="fiName"
                        label={intl.formatMessage({ id: "payment-method.echeck.bank-name" })}
                        value={fiName}
                        onChange={handleInput}
                        hint="payment-method.echeck.bank-name.placeholder"
                        maxLength={32}
                        required
                        validations={[
                            ["required", "field.required"],
                            ["almostEverything", "field.invalid"],
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={intl.formatMessage({ id: "payment-method.echeck.bank-name-readonly" })}
                        value={fiName}
                        wrapperMode
                    />
                </MDBCol>
            }

            <MDBCol size="12" md="6">
                <InputFieldIntl name="cardHolderName" id="cardHolderName"
                    label={intl.formatMessage({ id: "payment-method.echeck.account-holder-name" })}
                    value={cardHolderName}
                    onChange={handleInput}
                    hint="payment-method.echeck.account-holder-name.placeholder"
                    maxLength={256}
                    required
                    validations={[
                        ["required", "field.required"],
                        ["almostEverything", "field.invalid"],
                        ['notCreditCard', "field.notCreditCard"]
                    ]}
                />
            </MDBCol>

            {!hideNickField &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="cardNickName" id="cardNickName"
                        label={intl.formatMessage({ id: "payment-method.card-nickname" })}
                        value={cardNickName}
                        onChange={handleInput}
                        onBlur={e => {
                            e.target.value = e.target.value.trim();
                            handleInput(e);
                        }}
                        hint="payment-method.card-nickname"
                        maxLength={32}
                        validations={[value => trim(value)?.includes(trimBankAccount(accountNumber))
                            || trim(value)?.includes(trim(routingNumber))
                            ? "field.notCreditCard"
                            : ""
                        ]}
                    />
                </MDBCol>
            }
        </MDBRow>
        <MDBRow>
            {isACHAuthRequired &&
                <AchAgreementConfirmation
                authorizeDD={authorizeDD}
                onAuthorizeACH={handleInput}
                />
            }
        </MDBRow>
    </React.Fragment>;
    if (controlled) return form;

    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}
