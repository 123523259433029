import * as actionTypes from '../constants/action-types';

export const getPaymentHistoryList = (filters, callback) => {
    return {
        type: actionTypes.GET_PAYMENT_HISTORY_LIST,
        filters,
        callback
    };
};

export const getPaymentHistoryListSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_HISTORY_LIST_SUCCESS,
        data
    };
};

export const getPaymentHistoryListFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_HISTORY_LIST_FAILURE,
        data
    };
};

export const getPaymentHistoryFilters = (filters, callback) => {
    return {
        type: actionTypes.GET_PAYMENT_HISTORY_FILTERS,
        filters,
        callback
    };
};

export const getPaymentHistoryFiltersSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_HISTORY_FILTERS_SUCCESS,
        data
    };
};

export const getPaymentHistoryFiltersFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_HISTORY_FILTERS_FAILURE,
        data
    };
};

export const getPaymentRecordsByParams = ({invoiceNumber, planInvoiceNumber, parentReferenceId}, callback) => {
    return {
        type: actionTypes.GET_PAYMENT_ACTIVITY_BY_PARAMS,
        invoiceNumber,
        planInvoiceNumber,
        parentReferenceId,
        callback
    };
};

export const getPaymentRecordsByParamsSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_ACTIVITY_BY_PARAMS_SUCCESS,
        data
    };
};

export const getPaymentRecordsByParamsFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_ACTIVITY_BY_PARAMS_FAILURE,
        data
    };
};

export const getChargebackRecordsByReference = (chargebackReqData, callback) => {
    return {
        type: actionTypes.GET_CHARGEBACK_ACTIVITY_BY_REFERENCE,
        chargebackReqData,
        callback
    };
};

export const getChargebackRecordsByReferenceSuccess = (data) => {
    return {
        type: actionTypes.GET_CHARGEBACK_ACTIVITY_BY_REFERENCE_SUCCESS,
        data
    };
};

export const getChargebackRecordsByReferenceFailure = (data) => {
    return {
        type: actionTypes.GET_CHARGEBACK_ACTIVITY_BY_REFERENCE_FAILURE,
        data
    };
};