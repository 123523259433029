import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {MDBCol, MDBRow, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn} from 'mdbreact';
import SelectFieldIntl from "../../../SelectFieldIntl";
import {FormattedMessage, injectIntl} from "react-intl";
import { bindActionCreators } from 'redux';
import produce from "immer";
import {get as lodashGet, set as lodashSet} from "lodash";
import classNames from "classnames";
import InputFieldIntl from "../../../InputFieldIntl";
import ReactPortalModal from "../../../ReactPortalModal";
import * as accountActions from "../../../../actions/account-action";
import * as validationActions from "../../../../actions/validation-action";
import attachValidator from "../../../../utils/validation/attach-validator";
import {stripAccountNumber, countryCodeToCountry, getFormattedAccountNumber} from "../../../../utils/ups-utils";
import DatatableV3 from '../../../DataTableV3/DataTableV3';
import {getCompanyAccountCount} from "../../../../api/account-api";
import {TYPE_ACCOUNT_TYPE_ACCOUNT} from "../../../../constants/paymentus-resources";
import Alert from "../../../Alert";

const CancelReasonOptionsAccount = [
    {"value": '', "label": "Select One", "msgId" : "cancel-account-reason.select-one"},
    {"value": "I prefer to receive paper invoices", "label": "I prefer to receive paper invoices", "msgId" : "cancel-account-reason.prefer-paper-invoices"},
    {"value": "I no longer use this account", "label": "I no longer use this account", "msgId": "cancel-account-reason.no-longer-used"},
    {"value": "Someone else manages this account", "label": "Someone else manages this account", "msgId" : "cancel-account-reason.managed-by-other-person"},
    {"value": "The Billing Center does not meet my account management needs", "label": "The Billing Center does not meet my account management needs", "msgId" : "cancel-account-reason.bcc-doesnot-satisfy-account-needs"},
    {"value": "My preferred payment method is not supported", "label": "My preferred payment method is not supported", "msgId" : "cancel-account-reason.payment-method-not-supported"},
    {"value": "Other", "label": "Other", "msgId" : "cancel-account-reason.other"}
]
const CancelReasonOptionsPlan = [
    {"value": '', "label": "Select One", "msgId" : "cancel-account-reason.select-one"},
    {"value": "I prefer to receive paper invoices", "label": "I prefer to receive paper invoices", "msgId" : "cancel-account-reason.prefer-paper-invoices"},
    {"value": "I no longer use this plan", "label": "I no longer use this plan", "msgId": "cancel-account-reason.no-longer-used_plan"},
    {"value": "Someone else manages this plan", "label": "Someone else manages this plan", "msgId" : "cancel-account-reason.managed-by-other-person_plan"},
    {"value": "The Billing Center does not meet my plan management needs", "label": "The Billing Center does not meet my plan management needs", "msgId" : "cancel-account-reason.bcc-doesnot-satisfy-account-needs_plan"},
    {"value": "My preferred payment method is not supported", "label": "My preferred payment method is not supported", "msgId" : "cancel-account-reason.payment-method-not-supported"},
    {"value": "Other", "label": "Other", "msgId" : "cancel-account-reason.other"}
]

class CancelAccountModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelReason: '',
            cancelReasonComments: '',
            hasError: false,
            loaded: false,
            datatableCols:[],
            data:[],
            removedAccountIds: [],
            accountsCount: 0,
            fetchCountFailed: true
        }
        attachValidator.call(this)
    }
    componentDidMount() {

        const {selectedAccounts} = this.props;

        if (selectedAccounts) {
            this.onInit();
        }

    }

    onInit = () => {
        const {intl, selectedAccount,selectedAccounts, user} = this.props;
        if(selectedAccount){
            let tableRow = { 
                accountNumber: stripAccountNumber(selectedAccount.accountNumber,selectedAccount.paymentType),
                country : intl.formatMessage({ id: `country-item.${selectedAccount.merchant.countryCode}` }),
                businessUnit : intl.formatMessage({ id: `business-unit.${selectedAccount.paymentType}` },
                                                    {
                                                        sup: msg => (
                                                            <sup>{msg}</sup>
                                                        )
                                                    }
                                                )
            }
            this.setState({
                loaded: true,
                data: [selectedAccount],
                datatableCols: this.createDatatableCols(),
            });
        }else if(selectedAccounts?.size > 0){
            let tableRows = [];
            selectedAccounts.forEach((element, index) => {
                tableRows.push(this.createDatatableRow(element, index));
            });

            this.setState({
                loaded: true,
                data: [...selectedAccounts.values()],
                datatableCols: this.createDatatableCols(),
            });
        }

        // Get company active accounts count
        getCompanyAccountCount(user.companyId)
        .then((resp)=> {
            const count = resp.parsedBody.count;
            this.setState({
                accountsCount: count
            })            
        })
        .catch((err) => {
            this.setState({
                fetchCountFailed: false
            })
        });

    }

    createDatatableRow = (data, index) => {
        const {intl } = this.props;
        let rowDataFlat = {
            accountNumber: stripAccountNumber(lodashGet(data, 'accountNumber', ''), lodashGet(data, 'paymentType', '')) ,
            country: intl.formatMessage({ id: `country-item.${data.merchant.countryCode}` }),
            businessUnit: intl.formatMessage({ id: `business-unit.${data.paymentType}` },
                                                {
                                                    sup: msg => (
                                                        <sup>{msg}</sup>
                                                    )
                                                })
        };
        return rowDataFlat;
    }

    removeAccounts = () => {
        let result = this.validator.validateAll()
        if(result.messages.length > 0) return;
        let {user, selectedAccount, selectedAccounts, accountTypeOverride} = this.props;
        const body = {
            // accountId: selectedAccount.id,
            // accountNumber: selectedAccount.accountNumber,
            // paymentType: selectedAccount.paymentType,
            // merchantId: selectedAccount.merchantId,
            externalId: user.externalId,
            // identityId: user.id     
            accountIds: [],            
            cancelReason: this.state.cancelReason,
            cancelReasonComments: this.state.cancelReasonComments,
            accountType: accountTypeOverride
        };
        if (selectedAccount) {
            body.accountNumber = selectedAccount.accountNumber;
            body.paymentType = selectedAccount.paymentType;
            body.merchantId = selectedAccount.merchantId;
            // data.accountId = selectedAccount.id;
            body.accountIds.push(selectedAccount.id)
        }else if (selectedAccounts.size > 0 ) {
            body.accountIds = [...selectedAccounts.values()].map(acc => acc.id)
        }
        this.setState({removedAccountIds: body.accountIds});
        this.props.accountActions.removeAccounts(body);
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            })
        );
    };

    handleComplete = (modalStep) => {
        this.props.accountActions.removeAccountComplete();
        if(modalStep === 2){
            this.props.updateAccounts(this.state.removedAccountIds);
            this.props.toggleModal()
        }else{
            this.handleClose();
        }
        
    }

    handleClose = () =>{
        this.props.closeModal();
    }
    
    createDatatableCols = () => {
        const { intl, accountTypeOverride } = this.props;
        const prefix = accountTypeOverride.toLowerCase()
        const getFormattedAccount = ({ accountNumber, paymentType, parentAccount }) => getFormattedAccountNumber(accountNumber, paymentType, parentAccount, intl)
        const getCountryName = ({ merchant}) => merchant ? intl.formatMessage({ id: `country-item.${merchant.countryCode}` }) : "N/A"
        const getAccountType = ({ accountType }) => intl.formatMessage({ id: `plan.plan-type.${accountType.toLowerCase()}` })
        const getPaymentTypeMessage = ({ paymentType }) => intl.formatMessage({ id: `account.payment-type.${paymentType.toLowerCase()}` })
        const cols = [
            {field: 'accountNumber', label: intl.formatMessage({id:`cancel-${prefix}-table-${prefix}-number.label`}), display: getFormattedAccount},
            {field: 'country', label: intl.formatMessage({id:`cancel-${prefix}-table-country.label`}), scope: 'col', display: getCountryName},
            accountTypeOverride === TYPE_ACCOUNT_TYPE_ACCOUNT
                ? {field: 'businessUnit', label: intl.formatMessage({id:'cancel-account-table-business-unit.label'}), data: 'businessUnit', display: getPaymentTypeMessage}
                : {field: 'planType', label: intl.formatMessage({id:'cancel-plan-table-plan-type.label'}), data: 'businessUnit', display: getAccountType}
        ];

        return cols;
    }

    renderDatatable = (datatableCols, data) => {
       const {intl, caption} = this.props;
        return <DatatableV3
        caption={caption}
                key={'account-table'}
                itemsPerPage={[4]}
                hidePaging={data.length <= 4}
                sortable={false}
                className={`table table-bordered-simple table-sm table-hover`}
                columns={datatableCols}
                data={data}
            />
    }

    render() {
        const { isOpen, toggleModal, selectedAccount, removeFinished, backdrop, errorRemovedAccounts, selectedAccounts, accountTypeOverride, intl } = this.props;
        const { cancelReason, cancelReasonComments, hasError, datatableCols, data, loaded, accountsCount, fetchCountFailed } = this.state;
        let modalStep = 1;
        if (removeFinished) {
            modalStep = 2;
        }
        let errors = [];
        let successfullyDeleteAccounts = [];
        // Note theres two ways of triggering the warning depending on which way user is removing accounts
        // If user doing bulk delete, then selectedAccounts list will be populated 
        // If user doing individual delete without checkbox selection, then selectedAccount object will be populated
        // check which ever one is set
        let showRemoveLastAcctWarning = false;
        if (selectedAccount && accountsCount == 1) {
            showRemoveLastAcctWarning = true;
        }
        else if (selectedAccounts.size > 0 && (selectedAccounts.size == accountsCount)) {
            showRemoveLastAcctWarning = true;
        }
        if(errorRemovedAccounts && errorRemovedAccounts.hasOwnProperty('removeAccounts')){
            if(!selectedAccount){
                selectedAccounts.forEach(account => {
                    let notAnError = true;
                    errorRemovedAccounts.removeAccounts.forEach(errorAccount => {
                        if(account.id === errorAccount.accountId){
                            notAnError = false;
                            errors.push(account);
                        }
                    })
                    if(notAnError){
                        successfullyDeleteAccounts.push(account);
                    }
                })
            }else{
                errors.push(selectedAccount);
            }   
        }
        const modalHeading = (selectedAccount || selectedAccounts.length === 1) ? `ups.btn.cancel-${accountTypeOverride.toLowerCase()}.label` : `ups.btn.cancel-${accountTypeOverride.toLowerCase()}s.label`;
        const modalLabel = `cancel-${accountTypeOverride.toLowerCase()}`;
        return(
            <ReactPortalModal isOpen={isOpen} an_label={modalLabel}>
                <MDBModal isOpen={isOpen} toggle={toggleModal} size="lg" backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => this.handleComplete(modalStep)}><FormattedMessage id={modalHeading} /></MDBModalHeader>
                    <MDBModalBody aria-live="assertive">
                        {(modalStep === 1 && !hasError) ?
                        <React.Fragment>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    {(selectedAccount || selectedAccounts.length === 1) && <p><FormattedMessage id={`ups.cancel-${accountTypeOverride.toLowerCase()}.line1`} /></p>}
                                    {!selectedAccount && selectedAccounts.length > 1  && <p><FormattedMessage id={`ups.cancel-${accountTypeOverride.toLowerCase()}s.line1`} /></p>}
                                    <h3> <FormattedMessage id={`cancel-${accountTypeOverride.toLowerCase()}-remove.${ !selectedAccount && selectedAccounts.length > 1 ? 'multiple' : 'single' }`} /> </h3>
                                    {loaded && this.renderDatatable(datatableCols, data)}

                                    {(showRemoveLastAcctWarning && fetchCountFailed) &&
                                        <MDBCol size="12" className="mb-3">
                                            <Alert type="warning" label={`ups.cancel-account.remove.last.${selectedAccounts.size > 1 ? "accounts" : "account"}`}/>
                                        </MDBCol>                                    
                                    }

                                    {/* <p>{selectedAccount && stripAccountNumber(selectedAccount.accountNumber, selectedAccount.paymentType)}</p> */}
                                    <p className="ups-note-1">*<span className="font-italic"><FormattedMessage id={"ups.required-field.note"}/></span></p>
                                    <SelectFieldIntl
                                        key={"cancelReason"}
                                        name={"cancelReason"}
                                        id={"cancelReason"}
                                        value={cancelReason}
                                        selectOptions={accountTypeOverride === TYPE_ACCOUNT_TYPE_ACCOUNT
                                            ?CancelReasonOptionsAccount:CancelReasonOptionsPlan}
                                        label={<FormattedMessage id="ups.cancel.reason.label" />}
                                        setLabelBefore={true}
                                        onChange={this.handleInput}
                                        disabled={null}
                                        required
                                        validations={[
                                            ["required", "field.required"]
                                        ]}
                                    />

                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <InputFieldIntl
                                        key={"cancelReasonComments"}
                                        name={"cancelReasonComments"}
                                        id={"cancelReasonComments"}
                                        className={'cancelReasonComments'}
                                        type={"textarea"}
                                        label={<FormattedMessage id="ups.cancel.comment.label" />}
                                        value={cancelReasonComments}
                                        onChange={this.handleInput}
                                        maxLength={250}
                                        disabled={null}
                                        hint={""}
                                        rows="5"
                                        labelClass="active"
                                    />
                                </MDBCol>
                            </MDBRow>
                        </React.Fragment>
                        : null}

                        {(modalStep === 2 && !hasError) ?
                            <React.Fragment>
                                <p><FormattedMessage id={`cancel-${accountTypeOverride.toLowerCase()}-success.${!selectedAccount && selectedAccounts.length > 1 ? 'multiple' : 'single'}`}/></p>
                                <h3><FormattedMessage id={`cancel-${accountTypeOverride.toLowerCase()}-success-title.${!selectedAccount && selectedAccounts.length > 1 ? 'multiple' : 'single'}`}/></h3>
                                {/* successfullyDeleteAccounts */}
                                {loaded && this.renderDatatable(datatableCols, data)}

                                {errors.length > 0 && <h3><FormattedMessage id="ups.cancel-account.fail" /></h3>}
                                {errors.length > 0 && <ul>{ errors.map((item) => <li key={item.accountNumber}>{stripAccountNumber(item.accountNumber, item.paymentType)}</li>)}</ul>}
                            </React.Fragment>
                        : null}

                        {hasError ?
                            <React.Fragment>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <p><FormattedMessage id={"ups.cancel.init.error"} /></p>
                                    </MDBCol>
                                </MDBRow>
                            </React.Fragment>
                        : null}
                    </MDBModalBody>
                    <MDBModalFooter  aria-live="polite">
                        {(modalStep === 1 && !hasError) ?
                            <React.Fragment>
                                <MDBRow>
                                    <MDBCol size={"12"} className={classNames({"mt-3 mt-sm-0": true , "text-center": true})}>
                                        <MDBBtn id="cancel-account-modal-btn-cancel-account" color={"primary"} onClick={this.removeAccounts}>
                                            <FormattedMessage id={"ups.btn.submit.label"} />
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol  className={classNames({"mt-3 mt-sm-0": true , "text-center": true})}>
                                        <MDBBtn flat id="cancel-account-modal-btn-cancel" className={classNames({"btn-flat-link-without-fw": true})}
                                                onClick={this.handleClose}>
                                            <FormattedMessage id={"ups.btn.enroll.cancel.label"} />
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </React.Fragment>
                        : null}
                        {(modalStep == 2 || hasError) ?
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <MDBBtn color="primary" id="cancel-account-modal-btn-close" onClick={() => this.handleComplete(modalStep)}><FormattedMessage id={"close.label"} /></MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        : null}
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        removeFinished: state.account.removeFinished,
        errorRemovedAccounts: state.accounts.error,
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CancelAccountModal)));