import React from "react";
import DisplayOnlyField from "../../../DisplayOnlyField";
import {FormattedMessage, useIntl} from "react-intl";
import {MDBCol, MDBRow} from "mdbreact";
import ddLogo from '../../../../assets/images/logo/dd_logo_landscape.png';

export default function BacsAgreement({paymentMethod, referenceNumber, showAgreementResult = false}){
    const {cardHolderName, address, routingNumber, accountNumber} = paymentMethod ?? {}
    const {line1, city, zipCode} = address ?? {}
    const intl = useIntl()
    return <>
            <MDBRow className="mt-4" >
            <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={intl.formatMessage({id: "payment-method.echeck.account-holder-name"})}
                    value={accountNumber}
                />
            </MDBCol>
            <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={intl.formatMessage({id: "payment-method.echeck.bank-account-number-readonly"})}
                    value={cardHolderName}
                />
            </MDBCol>
            <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={intl.formatMessage({id: "payment-method.bacs.sort-code-readonly"})}
                    value={routingNumber}
                />
            </MDBCol>
            <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={intl.formatMessage({id: "payment-method.address.label"})}
                    value={`${line1} ${city} ${zipCode}`}
                />
            </MDBCol>
            <MDBCol className="mt-4" size="12" md="12">
            <div>
                <FormattedMessage id={'bacs.agreement.line1'}/>
            </div>
            <br />
            </MDBCol>
            <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={intl.formatMessage({id:"bacs.agreement.service-user-number.label"})}
                    value={'298288' /*hardcoded value from adyen*/}
                />
            </MDBCol>
            <MDBCol size="12" md="6">
                <DisplayOnlyField
                    label={intl.formatMessage({id:'reference.number.label'})}
                    value={showAgreementResult ? referenceNumber : <FormattedMessage id={"bacs.agreement.referenceNumber.placeholder"}/>}
                />
            </MDBCol>
            <MDBCol size="12" md="12" className="text-center">
                <div>
                <br />
                <h5 className="mb-4">
                    <FormattedMessage id={'bacs.agreement.direct-debit-guarantee'}/>
                    <img className="ml-4" src={ddLogo} width={128} alt={intl.formatMessage({id:"bacs.agreeement.direct-debit-logo-alt"})}/>
                </h5>
                </div>
            </MDBCol>
            <MDBCol size="12" md="12">
            <div>
                <div><FormattedMessage id={"bacs.agreement.line2"}/></div>
                <br />
                <div><FormattedMessage id={"bacs.agreement.line3"}/></div>
                <br />
                <div><FormattedMessage id={"bacs.agreement.line4"}/></div>
                <br />
                <div className="ml-2"><FormattedMessage id={"bacs.agreement.line5"}/></div>
                <br />
                <div><FormattedMessage id={"bacs.agreement.line6"}/></div>
                <br />
                {showAgreementResult && <>
                    {/*authorizeDD && */<span>✓  </span> /*assume they agreed if displaying payment history agreement result, since they have to agree to be able to get to a payment being made*/}
                    <span><FormattedMessage id={"sepa-mandate.sign.otp.approval"}/></span>
                </>}
            </div>
            </MDBCol>
            </MDBRow>
    </>
}