import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { MDBSpinner } from 'mdbreact';
import Error from '../../../components/Error';
import braintree from 'braintree-web';
import { MDBRow, MDBCol } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import Details from '.';
import InputFieldIntl from '../../InputFieldIntl';
import { getClientToken } from '../../../api/config-api';
import { countryCodeToMerchantId } from '../../../utils/utils';
import { isWallet, notEmpty } from '../../../utils/payment-utils';
import * as R from 'ramda';

const getMerchantCurrencyCode = countryCode => R.compose (R.prop ('currencyCode'), R.find (R.propEq ('countryCode', countryCode)));

const initBrainTree = ({payPalAccepted, setError, currencyCode, locale}) => async (clientToken) => {
    const clientInstance = await braintree.client.create({authorization: clientToken});
    const paypalCheckoutInstance = await braintree.paypalCheckout.create({ client: clientInstance });
    await paypalCheckoutInstance.loadPayPalSDK({ vault: true, currency: currencyCode, intent: 'tokenize'});

    [ window.paypal.FUNDING.PAYPAL
    , window.paypal.FUNDING.PAYLATER].forEach(funding => {
    // R.values(window.paypal.FUNDING).forEach(funding => {
        const button = window.paypal.Buttons({
            fundingSource: funding,
            locale,
            style: {
                shape: 'pill',
                size: 'responsive',
                tagline: false,
            },
            createBillingAgreement: () => paypalCheckoutInstance.createPayment({
                    flow: 'vault',
                    enableShippingAddress: false,
                    intent: 'tokenize',
                    // offerCredit: true, // Need to understand how and when we use this.
                }),
    
            onApprove: (data, actions) => 
                paypalCheckoutInstance.tokenizePayment(data)
                    .then(payPalAccepted)
                    .catch(err => {
                        console.error('Failed to tokenize', err);
                        setError('paypal.error');
                    }),

            onCancel: function (data) {
                console.log('Paypal payment cancelled', data);
            },
    
            onError: function (err) {
                console.error('Paypal error', err);
                setError('paypal.error');
            },
        });

        if (button.isEligible()) {
            button.render(`#paypal-buttons-${funding}`);
        }
    });
}

const payPalInitialState = {
    initializing: true,
    cardNickName: '',
    nonce: null,
    profile: {},
    error: ''
}

function payPalReducer (state, action) {
    switch (action.type) {
        case 'PAYPAL_ACCEPTED': return {
            ...state,
            nonce: action.nonce,
            profile: action.details,
            initializing: false,
            error: ''
        }
        case 'SET_ERROR': return {
            ...state,
            initializing: false,
            error: action.error
        }
        case 'SET_NICK': return {
            ...state,
            cardNickName: action.cardNickName
        }
        case 'INITIALIZED': return {
            ...state,
            initializing: false
        }
        case 'WALLET_EDIT': return {
            ...state,
            cardNickName: action.cardNickName,
            initializing: false,
            profile: {
                payerId: action.accountNumber
            },
            nonce: 'X'
        }
        default: return state;
    }
}

export default function PayPalAccountDetails({ 
    isModal, paymentMethod, submitAction, pmCategory,
    handleCancel, hideNickField, variationSize, controlled,
    setCanProceed = () => null }) {
    const [payPalState, dispatch] = useReducer(payPalReducer, payPalInitialState);

    const { user: { selectedCountry }} = useSelector(s => s.auth);
    const { merchants }= useSelector(s => s.config);
    const { locale } = useSelector(s => s.settings);

    const payPalAccepted = R.compose(dispatch, R.assoc('type', 'PAYPAL_ACCEPTED'));
    const setError = error => dispatch({type: 'SET_ERROR', error});

    const { methodType } = paymentMethod || {methodType: 'PAYPAL_ACCOUNT'};

    useEffect(() => {
        let closed = false;
        const fetchClientToken = async () => {
            const { ok, parsedBody: {clientToken} } = 
                await getClientToken(
                    countryCodeToMerchantId(merchants, selectedCountry),
                    methodType);
            if (!closed) {
                if (ok && clientToken) {
                    await initBrainTree ({
                        payPalAccepted, 
                        setError, 
                        currencyCode: getMerchantCurrencyCode(selectedCountry) (merchants),
                        locale
                    }) (clientToken);
                    dispatch({type: 'INITIALIZED'});
                } else {
                    console.error('no braintree token returned');
                    setError('braintree.init.failure');
                }
            }
        }
        if (isWallet(paymentMethod)) {
            dispatch({
                type: 'WALLET_EDIT', 
                cardNickName: paymentMethod.cardNickName, 
                accountNumber: paymentMethod.accountNumber
            });
        } else {
            fetchClientToken().catch(err => {
                    console.error('Failed to initialize braintree', err);
                    setError('braintree.init.failure');
                });
        }
        return () => closed = true;
    }, []);

    // This submit is when included in the OTP payment flow.
    useEffect(() => {
        if (payPalState.nonce && controlled) {
            submitAction({
                nonce: payPalState.nonce, 
                methodType, 
                id: 'PAYPAL_ACCOUNT', 
                email: payPalState.profile.email,
                accountNumber: payPalState.profile.payerId,
                categoryCode: pmCategory
            });
            setCanProceed(true);
        }
    }, [payPalState.nonce, controlled]);

    const setNickName = event => {
        const { target: {value} } = event;
        dispatch({type: 'SET_NICK', cardNickName: value});
    };

    const form = (
        <React.Fragment>
            <MDBRow>
                {!hideNickField && (
                    <MDBCol md={variationSize ? '12' : '6'}>
                        <InputFieldIntl
                            key='cardNickName'
                            name='cardNickName'
                            id='cardNickName'
                            label={<FormattedMessage id='payment-method.card-nickname' />}
                            value={payPalState.cardNickName}
                            onChange={setNickName}
                            hint='payment-method.card-nickname'
                            maxLength={32}
                            // required
                            // validations={[
                            //     ['required', 'field.required']
                            // ]}
                        />
                    </MDBCol>
                )}
                <MDBCol>
                    <div id='paypal-buttons'>
                        <div id='paypal-buttons-paypal' />
                        <div id='paypal-buttons-paylater' />
                        <div id='paypal-buttons-card' />
                        {payPalState.initializing && <MDBSpinner />}
                        {payPalState.error.length > 0 && <Error errors={[payPalState.error]}/>}
                    </div>
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    );

    if (controlled) return form;

    // This is when included in the Wallet Add/Update Flow.
    return (
        <Details
            form={form}
            isModal={isModal}
            onSubmit={() => 
                submitAction({ 
                    nonce: payPalState.nonce, 
                    cardNickName: payPalState.cardNickName,
                    accountNumber: payPalState.profile.payerId,
                    methodType: 'PAYPAL_ACCOUNT',
                    ...(isWallet(paymentMethod) ? {id: paymentMethod._id }: {}),
                    email: payPalState.profile.email,
                    categoryCode: pmCategory, 
                })
            }
            onCancel={handleCancel}
            extraFooterCssClass='mt-3'
            sectionTitle='payment-method.title.credit-card'
            canProceed={(payPalState.nonce !== null ) || isWallet(paymentMethod)}
        />
    );
}
