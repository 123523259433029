import React, {useMemo, useRef, useState} from 'react';
import { MDBBtn, MDBCol, MDBSelect,MDBInput, MDBAutocompleteV5, MDBPopover, MDBPopoverBody  } from "mdbreact";
import { FormattedMessage } from 'react-intl';
import {get as lodashGet,isEmpty,isEqual} from "lodash";
import { useEffect } from 'react';
import MDBAutocompleteV5Wrapper from '../../MDBFix/MDBAutocompleteV5Wrapper';
import { MDBPopoverWrapper } from '../../MDBFix/MDBPopoverWrapper';
import ReactSelectAllGrouped from '../../ReactSelectAllGrouped';
import classNames from "classnames";

const getAllOption = (intl) => { 
  return {
      value: "*", 
      msgId: "filter.multi.option.dropdown.all.selector", 
      label: intl.formatMessage({id:"filter.multi.option.dropdown.all.selector"})
  }
};

const selectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    closeMenuOnSelect:true,
    hideSelectedOptions:false,
    isSearchable:false,
    allowSelectAll: false,
    backspaceRemovesValue:false,
    placeholder:<FormattedMessage id={"filter-options.dropdown.default"}/>
}

const multiSelectProps = {
  className:"react-select-container",
  classNamePrefix:"react-select",
  isMulti:true,
  closeMenuOnSelect:false,
  hideSelectedOptions:false,
  allowSelectAll:true,
  isSearchable:false,
  backspaceRemovesValue:false,
  startWithAll: false,
  placeholder:<FormattedMessage id={"filter-options.dropdown.default"}/>
};

const sort = (filterOptions) => filterOptions.slice().sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

const DropdownOption = (data) => {
    const { field, filter, type, onUpdateFilters, existingFilters, getCheckedFilter,
            intl, aggregateData, invoiceLiterals, colClassName, formatLabel, formatOption} = data;
    const { options, aggregate } = filter;
    const [value, setValue] = useState("");

    let optionList =[{
      msgId: "react-select.select-your-filters",
      options : []
    }];

    const filterOptions = aggregate ? aggregateData : options;
    const checkedFilter = getCheckedFilter(existingFilters, field, filterOptions);
    const className = colClassName || "col-md-6 col-12";
    const title = formatLabel({}, data);

    if (filterOptions) {
        sort(filterOptions);
        for (const filterOption of filterOptions) {
            const { value: resultValue, text } = formatOption(filterOption, type, invoiceLiterals);
            optionList[0].options.push({
                checked: checkedFilter === resultValue,
                disabled: false,
                value: resultValue,
                text: text,
                label: text,
                msgId: text
            });
        }
    }

    const valueDefault = optionList[0].options?.find(x => x.checked === true);
    useEffect(() => {
      setValue(valueDefault);
    }, []);

    return (
        <MDBCol className={className} key={field}>
            <ReactSelectAllGrouped
                label={title}
                inputId={`${field}Filter`}
                options={optionList}
                field={field}
                onChange={(selected) => {
                    onUpdateFilters(field, [selected.value]);
                    setValue(selected)
                  } 
                }
                value={[value]}
                {...selectProps}
            />
        </MDBCol>
    );
};

function compare( a, b ) {
    if ( a.text < b.text ){
        return -1;
    }
    if ( a.text > b.text ){
        return 1;
    }
    return 0;
}

// Hide Advance page by tag.
const MultiSelectOption = (data) => {
    const { field, type, filter, onUpdateFilters, existingFilters, intl, aggregateData,
            invoiceLiterals, colClassName, formatLabel, formatOption } = data;
    const { options, aggregate } = filter;
    const [value, setValue] = useState([]);
    const [used, setUsed] = useState(false)
    let optionList = [{
      msgId: "react-select.select-your-filters",
      options : []
    }];
    const checkedFilters = existingFilters && existingFilters[field] ? existingFilters[field] : null;
    const filterOptions = aggregate ? aggregateData : options;    
    const className = colClassName || "col-md-6 col-12";
    const title = formatLabel({}, data);

    if (filterOptions) {
        for (const filterOption of filterOptions) {
            const { value, text } = formatOption(filterOption, type, invoiceLiterals);
            let checked = checkedFilters ? false : true;

            if (checkedFilters && checkedFilters.includes(value)) {
                checked = true;
            }
            optionList[0].options.push({
              checked,
              disabled: false,
              value: value,
              text: text,
              label: text,
              msgId: text
            });
        }
        optionList[0].options.sort(compare)
        if(multiSelectProps.allowSelectAll){
            const allOption = {
                checked: (value.length >= optionList[0].options.length) || (isEmpty(existingFilters?.[field]) && !used),
                value: "*",
                label: intl.formatMessage({id:"filter.multi.option.dropdown.all.selector"}),
                msgId:"filter.multi.option.dropdown.all.selector"
            }
            optionList[0].options.unshift(allOption)
        }
    }

    const valueDefault = optionList[0].options?.filter(x => x.checked === true) ?? [];
    
    useEffect(() => {
      setValue(valueDefault);
    }, []);

    return (
        <MDBCol className={className} key={field}>
            <ReactSelectAllGrouped
                className={'react-select-container'}
                label={title}
                inputId={`${field}Filter`}
                options={optionList}
                field={field}
                onChange={(selected) => {
                    const filteredValues = selected.filter(x => x.value !== "*").map(y => y.value);
                    onUpdateFilters(field, filteredValues);
                    setUsed()
                    setValue(selected);
                  } 
                }
                value={value}
                {...multiSelectProps}
            />
        </MDBCol>
    );
};

const TypeAheadOption = (data) => {
    const { field, filter, onUpdateFilters, existingFilters, intl, aggregateData, formatLabel } = data;
    const { options, aggregate } = filter;
    const currentFilter = existingFilters && existingFilters[field] ? existingFilters[field] : '';
    const filterOptions = aggregate ? aggregateData : options;
    const title = formatLabel({}, data);

    const popoverId = `${field}-popper`;
    const popoverHelpMsg = `${intl.formatMessage({id: 'filter-option.type-ahead.popover.help.msg1'})} ${title} ${intl.formatMessage({id: 'filter-option.type-ahead.popover.help.msg2'})}`
        + `${title.toLowerCase()} ${intl.formatMessage({id: 'filter-option.type-ahead.popover.help.msg3'})}`;
    const noSuggestionMsg = `${title} ${intl.formatMessage({id: 'filter-options.type-ahead.not-found'})}`;

    if (filterOptions) {
        sort(filterOptions);
    }
    return (
        <MDBCol md={"12"} size={"12"} key={field}>
            <div className={"autocomplete-form"}>
                <label className="autocomplete-default-label" htmlFor={field}>
            <FormattedMessage id={title} /> &nbsp;&nbsp;
            <MDBPopoverWrapper
              id={popoverId}
              buttonLabel={title}
              content={popoverHelpMsg}
            />
                </label>
                <MDBAutocompleteV5Wrapper
                    name={field}
                    data={filterOptions}
                    clear
                    clearlabel={`${intl.formatmessage({id:'ups.remove.btn.label'})} ${title}`}                        
                    clearClass={field}
                    id={field}
                    heightItem={24}
                    maxLength={10}
                    noSuggestion={[noSuggestionMsg]}
                    getValue={v => onUpdateFilters(field, v)}
                    valueDefault={currentFilter}
                    placeholder={title}
                />
            </div>
        </MDBCol>
    );
};

const TextInputOption = (data) => {
  const {
    field, filter, isFiltersClean, onUpdateFilters, colClassName, formatLabel, existingFilters, searchType
  } = data;
  const className = colClassName || "col-md-6 col-12";
  const [value, setValue] = useState(undefined);
  const title = formatLabel({}, data);
  const valueDefault = lodashGet(existingFilters, field, "");
  useEffect(() => {
      setValue(valueDefault);
  }, [valueDefault]);
  useEffect(()=>{
      if (isFiltersClean === true) setValue(valueDefault)
  }, [isFiltersClean])
  return (
    <MDBCol className={className} key={field}>
      <div className={"md-form"}>
        <label htmlFor={`${field}.${searchType}`} className={"multi-select-label"}>{title}</label>
        <MDBInput
          id={`${field}.${searchType}`}
          value={value}
          getValue={(value) => {
            onUpdateFilters(field, value, filter);
            setValue(value);
          }}
          hint={title}
        />
      </div>
    </MDBCol>
  );
};

const DropdownOptionV2 = (data) => {
  const {
    field, filter, type, onUpdateFilters, queryParams, intl, invoiceLiterals, colClassName, formatLabel, formatOption, definedValue, isFiltersClean
  } = data;
  const { options } = filter;
  let optionList = [{
    msgId: "react-select.select-your-filters",
    options : []
  }];
  const [value, setValue] = useState("");
  const checkedFilter = lodashGet(queryParams, field, [definedValue?.value[0]]);
  const className = colClassName || "col-md-6 col-12";
  const title = formatLabel({}, data);

  if (options) {
    sort(options);
    for (const filterOption of options) {
      const { value, text } = formatOption(filterOption, type, invoiceLiterals);
      optionList[0].options.push({
        checked: JSON.stringify(checkedFilter) === JSON.stringify(value),
        disabled: false,
        value: value,
        text: text,
        label: text,
        msgId: text
      });
    }
  }

  useEffect(()=>{
    if(definedValue) {
      const valueDefault = optionList?.[0]?.options.find(x => x.checked === true);
      onUpdateFilters(field, [definedValue?.value[0]], filter);
      setValue(valueDefault);
    }
  },[definedValue])

  useEffect(()=>{
    if (isFiltersClean === true && value !== "") {
      setValue("");
    }
  }, [isFiltersClean])
  
  return (
    <MDBCol className={classNames("drop-down",className)} key={field}>
        <ReactSelectAllGrouped
            label={title}
            inputId={`${field}Filter`}
            options={optionList}
            field={field}
            onChange={(selected) => {
                onUpdateFilters(field, selected.value, filter);
                setValue(selected);
              } 
            }
            value={[value]}
            {...selectProps}
        />
    </MDBCol>
  );
};

const MultiSelectOptionV2 = (data) => {
  const { field, type, filter, onUpdateFilters, intl, aggregateData, invoiceLiterals, colClassName, formatLabel, formatOption, queryParams, isFiltersClean } = data;
  const { options, aggregate } = filter;
  const [value, setValue] = useState([]);
  let optionList = [{
    msgId: "react-select.select-your-filters",
    options : []
  }];
  const checkedFilters = queryParams && queryParams[field] ? queryParams[field] : null;
  const filterOptions = aggregate ? aggregateData : options;
  const className = colClassName || "col-md-6 col-12";
  const title = formatLabel({}, data);

  if (filterOptions) {
    sort(filterOptions);
    optionList[0].options.push(getAllOption(intl));
    for (const filterOption of filterOptions) {
      const { value, text } = formatOption(filterOption, type, invoiceLiterals);
      let checked = !isEmpty(checkedFilters) ? checkedFilters.includes(value) : false;

        if (checkedFilters && checkedFilters.includes(value)) {
            checked = true;
        }
        optionList[0].options.push({
          checked,
          disabled: false,
          value: value,
          text: text,
          label: text,
          msgId: text
        });
    }

    /* if count of checked items is greater than 0 and equivalent to the available no. of items, then check "All" option by default.*/
    const checkedItems = optionList[0].options.filter(x => x.checked).length;
    if(checkedItems > 0 && checkedItems ===  filterOptions.length){
      optionList[0].options[0].checked = true;
    }
  }

  const valueDefault = optionList[0].options?.filter(x => x.checked === true) ?? [];

  useEffect(() => {
    setValue(valueDefault);
  }, []);

  useEffect(()=>{
    if (isFiltersClean === true && value.length > 0) {
      setValue([]);
    }
  }, [isFiltersClean])
  
  return (
    <MDBCol className={classNames("multi-select", className)} key={field}>
      <ReactSelectAllGrouped
          label={title}
          inputId={`${field}Filter`}
          options={optionList}
          field={field}
          onChange={(selected) => {
              const filteredValues = selected.filter(x => x.value !== "*").map(y => y.value);
              onUpdateFilters(field, filteredValues, filter);
              setValue(selected);
            } 
          }
          value={value}
          {...multiSelectProps}
      />
    </MDBCol>
  );
};

const getValue = (queryParams, field) => {
    let value = lodashGet(queryParams, field, "")
    if(Array.isArray(value)) value = value[0]?.length === 10 ? value[0]?.substring(4) : value[0]
    return value
}

const TypeAheadOptionV2 = (props) => {
  const {
    intl, onUpdateFilters, field, filter, searchType, colClassName, formatLabel, queryParams, isFiltersClean
  } = props;
  const [optionValue, setOptionValue] = useState(getValue(queryParams, field))
  const className = colClassName || "col-md-6 col-12";
  const title = formatLabel({}, props);
  const {options} = filter

  useEffect(()=>{
      const {valueLabel} = labelData
      onUpdateFilters(field, valueLabel[optionValue] ? [valueLabel[optionValue]] : undefined, filter)
  },[optionValue])

  const labelData = useMemo(()=>{
      let labelDataTemp = []
      let valueLabel = {}
      if (options) {
          options.forEach((option) => {
              const trimmedOption = option?.length === 10 ? option?.substring(4) : option
              valueLabel[trimmedOption] = option;
              labelDataTemp.push(trimmedOption);
          });
      }
      const labelDataFull = {labelData: labelDataTemp, valueLabel}
      return labelDataFull
  },[options])
  return (
    <MDBCol className={className} key={`${field}-${searchType}`}>
      <div className={"md-form"}>
        <label htmlFor={`accountNumber-${field}-${searchType}`} className={"multi-select-label"}>{title}</label>
        <MDBAutocompleteV5Wrapper
          isFiltersClean={isFiltersClean}
          name={`accountNumber-${field}-${searchType}`}
          data={labelData.labelData}
          clear
          clearlabel={"ups.remove.account-number.btn.label"}                        
          clearClass={`accountNumber-${field}-${searchType}`}
          id={`accountNumber-${field}-${searchType}`}
          heightItem={24}
          maxLength={10}
          noSuggestion={[intl.formatMessage({ id: `invoice-search-field.account-number.not-found.label` })]}
          value={optionValue}
          getValue={(optionValue) => setOptionValue(optionValue)}
          placeholder={intl.formatMessage({ id: `invoice.search-field.account-number.placeholder` })}
        />
      </div>
    </MDBCol>
  );
};

export {
    DropdownOption,
    MultiSelectOption,
    TypeAheadOption,
    TextInputOption,
    DropdownOptionV2,
    TypeAheadOptionV2,
    MultiSelectOptionV2
};