import * as actionTypes from '../constants/action-types';

export function showSpinner(message) {
    return {
        type: actionTypes.SHOW_SPINNER,
        data: message
    }
}

export function hideSpinner() {
    return {
        type: actionTypes.HIDE_SPINNER
    }
}

export function openBasicModal({title, message, buttons, callback}) {
    return {
        type: actionTypes.OPEN_BASIC_MODAL,
        title,
        message,
        buttons,
        callback
    }
}

export function closeBasicModal() {
    return {
        type: actionTypes.CLOSE_BASIC_MODAL
    }
}