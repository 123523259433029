import React from "react";
import {MDBCol, MDBRow} from "mdbreact";
import {FormattedMessage} from "react-intl";

import AccountPlanSelectionCardTable from "../../AccountPlanSelectionCardTable";
import {useSelector} from "react-redux";
import {getConfigByBU, getKeyByBU} from "../../../../utils/ups-utils";

export default function AccountSelection({accountList, schedule, onChange}) {
    const businessUnit = useSelector(s=>s.auth?.user.selectedPaymentType)
    const hasPlans = getConfigByBU(businessUnit)('hasPlans')
    return <MDBRow>
        <MDBCol size="12">
            <h3 className="mb-4">
                <FormattedMessage id="schedule.account-plan-selection.title"/>
            </h3>
            <p className="ups-note-1 my-0">*<span className="font-italic">
                <FormattedMessage id="ups.required-field.note"/>
            </span></p>
        </MDBCol>

        <MDBCol size="12">
            <div  className="datatable-blend-container-autopay account-selection">
                <div className="datatable-blend-inner">
                    <p><FormattedMessage id={getKeyByBU('autoPayFlow')(businessUnit)('accountSelectionInstructions')}/>*</p>
                </div>
            </div>

            <AccountPlanSelectionCardTable
                accountList={accountList}
                caption= "schedule.account-plan-selection.title"
                name="schedule-account-list"
                source="schedule-account-list"
                pageLength={20}
                selectedAccount={schedule.account}
                onChange={onChange}
                hidePlanAccountFilterOptions={!hasPlans}
                validations={[
                    ['required', 'schedule.account-plan-selection.instructions-1']
                ]}
            />
        </MDBCol>
    </MDBRow>
}
