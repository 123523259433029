import * as actionTypes from '../constants/action-types';
import produce from "immer";

export default (state = {}, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.LOGIN_SUCCESS: {
                draft.user = action.data;
                return;
            }
            case actionTypes.LOGIN_NEW_USER: {
                draft.enroll = true;
                draft.userInfo = action.data;
                return;
            }
            case actionTypes.LOGIN_FAILURE: {
                return;
            }
            case actionTypes.EXTEND_SESSION: {
                return;
            }
            case actionTypes.GET_ACCOUNT_LIST_SUCCESS:
                draft.user.accounts = action.data;
                return;
            case actionTypes.REMOVE_ACCOUNT_SUCCESS:
                draft.user.accounts = action.data;
                return;
            case actionTypes.ENROLLMENT_SUCCESS: {
                delete draft.enroll;
                delete draft.userInfo;
                draft.user = action.data.user;
                return;
            }
            case actionTypes.GET_ACCESSIBLE_MERCHANTS_SUCCESS:
                draft.merchants = action.data;
                return;
            case actionTypes.SET_SELECTED_COUNTRY:
                if(draft.userInfo){
                    draft.userInfo.locale = action.data.locale; 
                    draft.userInfo.selectedCountry = action.data.country;
                    draft.userInfo.selectedMerchantId = action.data.selectedMerchantId;
                }
                else{
                    draft.user.selectedCountry = action.data.country;
                    draft.user.locale = action.data.locale;
                    draft.user.selectedMerchantId = action.data?.selectedMerchantId ?? state.user.selectedMerchantId;
                    draft.user.tla = action.data?.tla ?? state.user.tla;
                }
                break;
            case actionTypes.SET_SELECTED_PAYMENT_TYPE:
                draft.user.selectedPaymentType = action.data;
                break;
            case actionTypes.INIT: {
                delete draft.user;
                delete draft.merchants;
                return;
            }
            case actionTypes.CSRF_TOKEN: {
              draft.csrfToken = action.token;
              return;
            }
            case actionTypes.SET_USER_COUNTRY_SETTINGS: {
                draft.user.selectedCountry = action.data.country;
                draft.user.locale = action.data.locale;
                draft.user.selectedMerchantId = action.data?.selectedMerchantId ?? state.user.selectedMerchantId;
                draft.user.tla = action.data?.tla ?? state.user.tla;
                draft.user.selectedPaymentType = action.data?.selectedPaymentType ?? state.user.selectedPaymentType;
                return;
            }
            case actionTypes.SAVE_LAST_LOGIN_SUCCESS:
                draft.user.lastLogin = action.data;
                return;
        }
    });