import * as actionTypes from '../constants/action-types';


export const init = () => {
    return {
        type: actionTypes.INIT
    }
};

export const login = (data) => {

    return {
        type: actionTypes.LOGIN,
        data:data        
    }
};

export const ssoCallback = (data) => {
    return {
        type: actionTypes.SSO_CALLBACK,
        data: data
    }
};

export const loginSuccess = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        data: data
    }
};

export const loginNewUser = (data) => {
    return {
        type: actionTypes.LOGIN_NEW_USER,
        data: data
    }
};

export const loginFailure = (data) => {
    return {
        type: actionTypes.LOGIN_FAILURE,
        data: data
    }
};


export const logout = () => {
    return {
        type: actionTypes.LOGOUT
        //data: data
    }
};

export const sessionTimeOut = () => {
    return {
        type: actionTypes.SESSION_TIMEOUT
    }
}

export const expireSessionCookie = (isSessionTimedOut) => {
    return {
        type: actionTypes.EXPIRE_COOKIE,
        data:{isSessionTimedOut}
    }
};
export const redirectUser = (data) => {
    return {
        type: actionTypes.REDIRECT_USER,
        data: data
    }
};

export const extendSession = () => {
    return {
        type: actionTypes.EXTEND_SESSION,
    }
};

export const csrLogin = (data) => {
    return {
        type: actionTypes.CSR_LOGIN,
        data: data
    }
};

export const csrCallback = (data) => {
    return {
        type: actionTypes.CSR_CALLBACK,
        data: data
    }
};
