import React, { Component } from "react";
import "react-dates/initialize";
import { DateRangePicker, DateRangePickerInputController } from "react-dates";
import defaultPhrases from 'react-dates/lib/defaultPhrases';
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay.js";
import MediaQuery from 'react-responsive'
import "react-dates/lib/css/_datepicker.css";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import {AutoFocus} from "../../UtilComponents/AutoFocus";
// this custom single input example taken from https://gist.github.com/osifo/984cd60dce5d6abb49b6923ffa580638
// otherwise default would have 2 inputs

const START_DATE = "startDate";
const END_DATE = "endDate";

function phraseOverrides(intl) {
    return {
        keyboardShortcuts: intl.formatMessage({ id: `input.shortcut.keys.keyboardShortcuts`}),
        showKeyboardShortcutsPanel: intl.formatMessage({ id: `calendar.phrases.showKeyboardShortcutsPanel`}),
        hideKeyboardShortcutsPanel: intl.formatMessage({ id: `calendar.phrases.hideKeyboardShortcutsPanel`}),
        moveFocusByOneMonth: intl.formatMessage({ id: `input.shortcut.keys.moveFocusByOneMonth`}),
        selectFocusedDate: intl.formatMessage({ id: `input.shortcut.keys.selectFocusedDate`}),
        moveFocusByOneWeek: intl.formatMessage({ id: `input.shortcut.keys.moveFocusByOneWeek`}),
        moveFocusByOneDay: intl.formatMessage({ id: `input.shortcut.keys.moveFocusByOneDay`}),
        moveFocustoStartAndEndOfWeek: intl.formatMessage({ id: `input.shortcut.keys.moveFocustoStartAndEndOfWeek`}),
        returnFocusToInput: intl.formatMessage({ id: `input.shortcut.keys.returnFocusToInput`}),
        openThisPanel: intl.formatMessage({ id: `input.shortcut.keys.openThisPanel`}),
        chooseAvailableStartDate: ({ date }) => `${date}`,
        chooseAvailableEndDate: ({ date }) => `${date}`,
        jumpToPrevMonth: intl.formatMessage({ id:'calendar.phrases.previousMonth'}),
        jumpToNextMonth: intl.formatMessage({ id:'calendar.phrases.nextMonth'})
    }
}

const hoveredStyles = {
    background: "#ff9497",
    border: "1px solid #ff9497",
    color: "#fff"
};

const selectedStyles = {
    background: "#ff5a5f",
    border: "1px solid #ff5a5f",
    color: "#fff",

    hover: {
        background: "#bc6e70",
        border: "1px solid #bc6e70",
        color: "#555"
    }
};

const selectedSpanStyles = {
    background: "#ff5a5f",
    border: "1px solid #ff5a5f",
    color: "#fff",

    hover: {
        background: "#bc6e70",
        border: "1px solid #bc6e70",
        color: "#fff"
    }
};

const customDayStyles = {
    selectedStartStyles: selectedStyles,
    selectedEndStyles: selectedStyles,
    hoveredSpanStyles: hoveredStyles,
    selectedSpanStyles,
    selectedStyles: Object.assign({}, selectedStyles, {
        background: "#ff9497",
        border: "1px solid #ff9497"
    })
};

//this is the component i'm using in the rest of my app
class CustomDateRangePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
            startDate: props.startDate,
            endDate: props.endDate
        };
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.props.handleDateChange({ startDate, endDate });
    };

    onFocusChange = focusedInput => {
        this.setState({
            // Force the focusedInput to always be truthy so that dates are always selectable
            focusedInput: !focusedInput ? START_DATE : focusedInput
        });
    };

    clearDateSelection = () => {
        this.setState({
            startDate: null,
            endDate: null
        });
    };

    setDateSelection = () => {
        this.setState({
            showDatePicker: false
        });
    };

    renderControls = () => {
        return (
            <section className="calendar_controls">
                <span onClick={this.clearDateSelection}>
                    <FormattedMessage id="calendar.btn-clear" />
                </span>
                <span onClick={this.setDateSelection}>
                    <FormattedMessage id="calendar.btn-apply" />
                </span>
            </section>
        );
    };

    render() {
        const { showInputs, dateRangeString, intl, focusText } = this.props;
        const { focusedInput } = this.state;
        const { showDatePickerTest, showDatePicker, startDate, endDate, minDate } = this.props;

        // temporarily until I find a better way
        let dateRangeText = document.getElementById("datatable-date-selection");
        if (dateRangeText) {
            dateRangeText.innerHTML = dateRangeString;
        }

        // as norway correct language-code is "nb-NO" but we are passing "no-NO"
       // needs to pass correct language code "nb-NO" so that calendar gets translated
        let currLocale = intl.locale;
        if(currLocale == "no-NO"){
            currLocale = "nb-NO";
        }

        return (
            <React.Fragment>
                {showDatePickerTest ?
                    <React.Fragment>
                    <AutoFocus requirement={(showDatePickerTest===true)} observe={[showDatePickerTest]}>
                        <div tabIndex={-1} id={"calendarFocus"} className={"screenreader-text"}>
                            {focusText
                                ? <FormattedMessage id={'calendar.focusTextWithLabel'} values={{label:focusText}}/>
                                : <FormattedMessage id={'calendar.focusText'}/>
                            }
                        </div>
                    </AutoFocus>
                    <MediaQuery minWidth={600}>
                        <DateRangePicker
                            onFocusChange={this.onFocusChange}
                            onDatesChange={({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })}
                            focusedInput={focusedInput}
                            startDate={startDate}
                            endDate={endDate}
                            keepOpenOnDateSelect={false}
                            enableOutsideDays={false}
                            numberOfMonths={2}
                            calendarInfoPosition={"after"}
                            renderCalendarInfo={this.props.renderCalendarInfo}
                            isOutsideRange={() => false}
                            phrases={phraseOverrides(intl)}
                            minDate={minDate}
                            renderMonthElement={({ month }) => moment(month).locale(currLocale).format(intl.formatMessage({id: `calendar-format` }))}
                            startDateId={'startDate'}
                            endDateId={'endDate'}
                        />
                    </MediaQuery>
                    <MediaQuery maxWidth={599}>
                        <DateRangePicker
                            onFocusChange={this.onFocusChange}
                            onDatesChange={({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })}
                            focusedInput={focusedInput}
                            startDate={startDate}
                            endDate={endDate}
                            keepOpenOnDateSelect={false}
                            enableOutsideDays={false}
                            numberOfMonths={1}
                            calendarInfoPosition={"bottom"}
                            renderCalendarInfo={this.props.renderCalendarInfo}
                            isOutsideRange={() => false}
                            phrases={phraseOverrides(intl)}
                            minDate={minDate}
                            renderMonthElement={({ month }) => moment(month).locale(currLocale).format(intl.formatMessage({id: `calendar-format` }))}
                            startDateId={'startDate'}
                            endDateId={'endDate'}
                        />
                    </MediaQuery>
                    </React.Fragment>
                : null}
            </React.Fragment>
        );
    }
}

export default injectIntl(CustomDateRangePicker);