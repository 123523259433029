import React, { Component } from 'react';
import {injectIntl} from 'react-intl';
import {bindActionCreators} from "redux";
import classNames from "classnames";
import {connect} from "react-redux";
import ValidationErrors from "../ValidationErrors";
import attachValidator from "../../utils/validation/attach-validator.js"
import * as validationActions from "../../actions/validation-action";
import * as errorActions from "../../actions/error-action";
import InputFieldIntl from '../InputFieldIntl';
import {MDBCol} from "mdbreact";

//Create a group of radio inputs with validation for the overall group.
class RadioGroup extends Component{
    constructor(props){
        super(props)
        this.onChange = this.onChange.bind(this)
        if(this.props.validations) attachValidator.call(this)
    }
    componentDidMount(){
        let {name, validations} = this.props;
        if(validations) this.validator.register(name)
    }
    componentWillUnmount(){
        let {name, validations, errorActions, id} = this.props;
        if(validations) this.validator.deregister(name)
        //clear backend error
        errorActions.clearFieldError({id})
    }
    onChange(e){
        let {onChange, validations, name, id, errorActions} = this.props;
        let val = e.target.value
        if(validations) this.validator.validate(name, val)
        //clear backend error
        errorActions.clearFieldError({id})
        if(onChange) onChange(e)
    }
    render(){
        const { name, radioProps, id, label, value, className, intl, labelClass, errors, ownVState, required, formInline} = this.props;
        const error = errors && errors.find(e => e.split('.')[0] === name );
        let messages = [];
        //backend error
        if(error) messages.push(error)
        //frontend validation errors
        if(ownVState && ownVState.messages) messages = messages.concat(ownVState.messages)
        // if type is radio or checkbox, please ensure they each have a unique ID
        return (
            <React.Fragment>
                {label && <label tabindex="0" className={classNames("active radioGroupLabel", labelClass)}>{<span><span >{label}</span><span>{required&&"*"}</span></span>}</label>}
                {radioProps.map((props)=>{
                    let {checkedValue, ...rest} = props;
                    if (formInline) {
                        return (
                                <InputFieldIntl key={'formInline'+props.id} type="radio" {...rest} checked={checkedValue === value} value={checkedValue} onChange={this.onChange} aria-required={!!required}/>
                        )
                    } else {
                        return (
                            <MDBCol key={props.id} md={"12"} sm={"12"}>
                                <InputFieldIntl type="radio" {...rest} checked={checkedValue === value} value={checkedValue} onChange={this.onChange} aria-required={!!required}/>
                            </MDBCol>
                        )
                    }

                })}
                <ValidationErrors name={name} messages={messages} values={{label}} intl={intl}/>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState:state.validation.vState[ownprops.name]
        }: {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RadioGroup));
