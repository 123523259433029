import {default as _, get as lodashGet, isEmpty as lodashIsEmpty, isUndefined as lodashIsUndefined} from "lodash";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as actionTypes from "../../../constants/action-types";
import {evaluateConditionals, isDisputesEnabled, isEmptyTemplatingString} from "../../../utils/invoice-utils";
import {useIntl} from "react-intl";
import {columnParsers} from "./TableScreen-utils";
import { getDisputeDetails } from "../../../api/dispute-api";
export function TableScreenExpandedRow (props) {
    const {rowData, expandedData: invoiceDetail, setExpandedData, columns, toggleModal, renderedRow, screenConfig, formatValue, formatLabel, invoice, envProps } = props
    const {countryCode, invoiceType} = invoice
    const reduxDispatch = useDispatch();

    useEffect(()=>{
        if(!invoiceDetail){
            const {recordType, accountNumber, planNumber} = invoice;
            const invoicePayload = {
                invoice: {
                    ...rowData,
                    recordType,
                    accountNumber,
                    planNumber,
                    id: rowData.invoiceId
                },
                searchCriteria: {
                    detailType: 'SHIPMENT'
                    , _id: rowData.id
                }
            }

            const callback = (invoiceDetail) => {
                const invoiceDetailWithCountryCode = {...invoiceDetail, countryCode}
                setExpandedData(invoiceDetailWithCountryCode)
            }

            reduxDispatch({
                type: actionTypes.GET_INVOICE_DETAILS,
                callback,
                ...invoicePayload
            })
        }
    }, [])

    return <>
        <tr className={"custom-row p-0 pt-2"}>
            <td className={"custom-row p-0 pt-2"} colSpan={"100%"}>
                {lodashIsEmpty(invoiceDetail)
                    ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"/>
                        </div>
                    )
                    : <CardDeck
                        invoiceDetail={invoiceDetail[0]}
                        screenConfig={screenConfig}
                        formatValue={formatValue}
                        formatLabel={formatLabel}
                        toggleModal={toggleModal}
                        invoiceType={invoiceType}
                        rowData={rowData}
                        envProps={envProps}
                    />
                }
            </td>
        </tr>
    </>
}



const CardDeck = ({invoiceDetail, screenConfig, formatValue, formatLabel, toggleModal, invoiceType, rowData, envProps}) => {
    return screenConfig.table.slideout.map((cards, index) =>
        <div key={`idcardrow-${index}`} className="row">
            {cards.map((card, index2) => (
                <Card key={`idcard-${index2}`}
                    invoiceDetail={invoiceDetail}
                    cardDefinition={card}
                    totalCards={cards.length}
                    formatValue={formatValue}
                    formatLabel={formatLabel}
                    toggleModal={toggleModal}
                    invoiceType={invoiceType}
                    rowData={rowData}
                    envProps={envProps}
                />
            ))}
        </div>
    )
}

const Card = ({invoiceDetail, cardDefinition, totalCards, formatValue, formatLabel, toggleModal, invoiceType , rowData,envProps}) => {

    const { msgId, button,button2, fields } = cardDefinition;

    return <div className={`card ${totalCards === 1 ? 'card-12' : 'card-6'} p-0 pt-3 p-sm-4 mb-4`} style={{boxShadow: "3px 3px 3px 3px lightgray !important"}}>
        <h4 className="card-title text-teal pl-3 pl-sm-0">{formatLabel(null, { msgId })}</h4>
        <CardButton invoiceDetail={invoiceDetail} button={button} toggleModal={toggleModal} invoiceType={invoiceType} formatLabel={formatLabel} rowData={rowData} envProps={envProps}/>
        <CardButton invoiceDetail={invoiceDetail} button={button2} toggleModal={toggleModal} invoiceType={invoiceType} formatLabel={formatLabel} rowData={rowData} envProps={envProps}/>
        <div className="card-body">
            <div className="row">
                {(cardDefinition.type === 'card') && (mapDisplayFields(invoiceDetail, fields, formatValue, formatLabel) || [])
                    .filter(({ value, conditionals, template, displayEmptyValue }) => !lodashIsUndefined(isEmptyTemplatingString(value, template, displayEmptyValue)) && evaluateConditionals(conditionals, invoiceDetail))
                    .map(({ label, value }, index) => createInvoiceField(label, value, index))
                }
                {(cardDefinition.type === 'simple-table') && (
                    <SimpleTable invoiceDetail={invoiceDetail} cardDefinition={cardDefinition} formatValue={formatValue}/>
                )}
            </div>
        </div>
    </div>

}
const onToggleModal = async (modal, invoiceDetail, rowData, envProps, toggleModal) => {
    if (modal === 'disputeDetails') {
        const { fields: { trackingNumber }, invoiceId} = rowData;
        const { apiUrlPrefix, apiToken } = envProps;
        let response = await getDisputeDetails({ trackingNumber, invoiceId }, apiUrlPrefix, apiToken);
        let data = response?.parsedBody &&  response?.parsedBody.length ? response?.parsedBody[0] : {};
        toggleModal(modal, data);
    } else {
        toggleModal(modal, invoiceDetail)
    }
}
const isInvoiceEligibleforDispute = (rowData, button) => {
    const { fields, shipmentCharge = [] } = rowData;
    const isInvoiceDisputable = (fields && fields.netCharge > 0) || (!fields.hasOwnProperty('netCharge') && shipmentCharge.some((sc) => sc.netAmt > 0));
    if (button.id === 'btn-dispute' && rowData.inDispute) {
        return false
    } else if (button.id === 'btn-in-dispute' && !rowData.inDispute) {
        return false
    } else if (button.id === 'btn-dispute' && !rowData.inDispute && isInvoiceDisputable) {
        return true
    } else if (button.id === 'btn-dispute' && !rowData.inDispute && !isInvoiceDisputable) {
        return false;
    }
    else {
        return true
    }
}

const CardButton = ({invoiceDetail, button, toggleModal, invoiceType, formatLabel,rowData,envProps}) => {
    const reduxSelector = useSelector(s=>s);
    const {adUserId} = useSelector(s=>s.auth.user);
    const {user} = reduxSelector?.auth
    const {merchant} = reduxSelector.config?.sessionSelection;
    const isCreditNote = invoiceType === "101";
    const disputesEnabled = isDisputesEnabled(merchant);
    const isViewOnlyUser = user.role === 'View Only' || user.role === 'VIEW_ONLY';
    if (!disputesEnabled || isCreditNote || invoiceDetail.noLongerDisputable || isViewOnlyUser) return '';
    if (!button || !button.id || (adUserId && button && button.modal !== 'disputeDetails') || !isInvoiceEligibleforDispute(invoiceDetail,button) || (button.conditionals && !evaluateConditionals(button.conditionals, invoiceDetail.fields))) return '';
    return <button
        id={`${button.id}_${invoiceDetail.id}`}
        type="button"
        className="btn btn-secondary ml-0 btn-mini invoice-detail-dispute-btn"
        onClick={()=>onToggleModal(button.modal,invoiceDetail,rowData,envProps,toggleModal)}
    >
        {formatLabel(null, button)}
    </button>
};

const mapDisplayFields = (invoiceDetail, fieldDefinitions, formatValue, formatLabel) => {
    const pairs = [];
    if (!fieldDefinitions) return [];

    for (const fieldDefinition of fieldDefinitions) {
        const { field: fieldName, conditionals = [], template, displayEmptyValue = false } = fieldDefinition;

        if (typeof fieldName === 'object') {
            pairs.push(
                ...lodashGet(invoiceDetail, fieldName.ref, [])
                    .map(ref => {
                        const label = formatLabel(ref, fieldDefinition);
                        let value = ''
                        if (fieldDefinition.type !== 'array'){
                            value = formatValue(ref, fieldDefinition);
                        } else {
                            value = columnParsers.array(fieldDefinition)(ref)
                        }
                        return { label, value, conditionals, template, displayEmptyValue };
                    })
            );
        } else {
            const label = formatLabel(invoiceDetail, fieldDefinition);
            const value = formatValue(invoiceDetail, fieldDefinition);

            pairs.push({ label, value, conditionals, template, displayEmptyValue });
        }
    }
    return pairs;
};

const createInvoiceField = (label, value, key) => (
    <div key={`invoiceField-${key}`} className="col-md-12 col-lg-6 p-0">
        <div className="row">
            <div className="col-6">
                <strong>{label}</strong>
            </div>
            <div className="col-6 text">
                {value}
            </div>
        </div>
    </div>
)

const SimpleTable = ({invoiceDetail, cardDefinition, formatValue}) => {

    const {fields: fieldDefinitions, ref, sortBy = ''} = cardDefinition;

    const tableRows = lodashGet(invoiceDetail, ref, []);
    const groupedFields = _.groupBy(fieldDefinitions,'field');
    const { invoiceHeader } = invoiceDetail;
    const intl = useIntl();

    return <table className="table table-bordered-simple table-sm">
        <thead>
            <tr>
            {
                fieldDefinitions
                    .filter(({hidden})=>{
                        return !hidden
                    })
                    .map((fieldDefinition) =>
                        <th key={`simpleTableHeader-${fieldDefinition.msgId}`} className={fieldDefinition.class ?? ''} >
                            {intl.formatMessage({ id: fieldDefinition.msgId })}
                        </th>
                    )
            }
            </tr>
        </thead>
        <tbody>
            {
            _.chain(tableRows)
                .sortBy(sortBy)
                .map((row) => {
                    return {
                        ...row,
                        ...(lodashIsEmpty(row?.currencyCode)
                            ? { currencyCode: invoiceHeader.currencyCode }
                            : { currencyCode: row?.currencyCode }),
                    };
                })
                .map((row,index) =>
                    <tr key={`simpleTableRow-${index}`}>
                        {
                            _.chain(fieldDefinitions)
                                .filter(({hidden})=>!hidden)
                                .map(fieldDefinition => {
                                    let fieldDefToApply = fieldDefinition;
                                    for (const [key, groupDefinition] of Object.entries(groupedFields)) {
                                        if (groupDefinition.length > 1) {
                                            groupDefinition.forEach((fieldDef) => {
                                                if (evaluateConditionals(fieldDef.conditionals, row) && fieldDef.field === fieldDefinition.field) {
                                                    fieldDefToApply = fieldDef;
                                                } else {
                                                    fieldDefToApply = fieldDefinition;
                                                }
                                            });
                                        }
                                    }
                                    return  <td key={fieldDefinition.msgId} className={fieldDefinition.class ?? ''}>
                                        <div className="custom-table-cell">
                                            {fieldDefinition.msgId && (
                                                <span className="custom-table-heading" id="charge-item-${index}-${invoiceDetail.id}">
                                                    {intl.formatMessage({ id: fieldDefinition.msgId })}
                                                </span>
                                            )}
                                            <span className="custom-table-value" aria-labelledby="charge-item-${index}-${invoiceDetail.id}">
                                                {formatValue(row, fieldDefToApply)}
                                            </span>
                                        </div>
                                    </td>
                                })
                                .value()
                        }
                    </tr>
                )
                .value()
            }
        </tbody>
    </table>

};