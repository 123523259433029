import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as invoiceActions from "../../actions/invoice-action";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBCard, MDBCardBody, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane } from "mdbreact";
import InvoiceTable from "../../components/UPS/InvoiceTable/InvoiceTable.js";
import {getMessageByBU, stripAccountNumber, getKeyByBU, decodeToken} from '../../utils/ups-utils';
import { buildFilters, buildCalendarOption, formatCurrency } from "../../utils/invoice-utils";
import { get as lodashGet } from 'lodash';
import InvoicesDownloadLinksModal from '../../components/UPS/Modal/InvoicesDownloadLinksModal';
import memoize from "memoize-one";
import { UseEffect } from "../../components/ClassUseEffect/ClassUseEffect";
import { getQueryParams } from "../../utils/utils";
import {FormattedNumber} from "../../components/IntlFix/FormattedNumber";
import { PAST_DUE_INVOICES } from '../../constants/invoice-constants';

class UPSInvoice extends React.Component {
    shouldRenderTable = false;
    constructor(props) {
        super(props);

        this.state = {
            isDrillMode: lodashGet(props, 'location.state.isDrillMode', false),
            invoice: lodashGet(props, 'location.state.invoice', null),
            URLFilters: buildFilters(props),
            calendarOption: buildCalendarOption(props),
        };
    }

    componentDidMount() {
        const { location, history, businessUnit } = this.props;
        const params = getQueryParams(location.search, false);

        if (params?.["at"] === "plan") {
            history.push({ pathname: '/ups/billing/plan', search: location.search });
        }
        else{
            this.shouldRenderTable = true;
            
            const filterType = lodashGet(this.props, 'match.params.filterType');
            if (params?.['payable'] === 'true') {
                // this section can be used in future for other filterTypes like dueSoon, closed etc
                let invoiceType = "";

                if(filterType === PAST_DUE_INVOICES){
                    invoiceType = "pastDue";
                }

                this.setState({
                    filterPayable: true,
                    invoiceType
                })
            }

            if (params?.["token"]) {
                const decodedToken = decodeToken(params["token"]);
                if (decodedToken) this.setState({ URLFilters: { ...this.state.URLFilters, invoiceFilters: buildFilters(decodedToken), businessUnit } });
            }
        }
        
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.shouldRenderTable = false;
    }

    setDocumentTitle = memoize(() => {
        const { intl } = this.props;
        document.title = intl.formatMessage({ id: 'document.title' }, { pageTitle: this.getPageHeaderTitle() });
    })

    getPastDueInvoices = () => {
        const { invoiceActions } = this.props;
        const { isDrillMode, invoice } = this.state;

        if (isDrillMode) {
            invoiceActions.getPastDuePlanInvoices(isDrillMode, {
                ...(lodashGet(invoice, 'planNumber', null) ? { planNumber: invoice.planNumber } : {}),
                ...(lodashGet(invoice, 'planInvoiceNumber', null) ? { planInvoiceNumber: invoice.planInvoiceNumber } : {}),
            });
        } else {
            invoiceActions.getPastDueInvoices();
        }
    }

    getPageHeaderTitle = () => {
        const { planEnrolled, businessUnit, businessUnitMessages, intl } = this.props;
        const { isDrillMode } = this.state;

        if (isDrillMode) return businessUnitMessages('drilldownPageTitle')
        else {
            if (planEnrolled && businessUnit === 'EBS') return intl.formatMessage({ id: 'invoice.my-account-invoices.title' })
            else return businessUnitMessages('pageTitle')
        }
    }
   
    renderPreviousPageButton = () => {
        const { history, location, businessUnitMessages } = this.props;

        return (
            <MDBBtn color={"secondary"} className={"ml-0 btn-mini"} onClick={() => history.push(location.state.prevPath)}>
                {businessUnitMessages('backButton')}
            </MDBBtn>
        );
    }

    renderBreadCrumb = (link, textKey) => {
        return <li className="ups-breadcrumb-item"><Link to={link} activeclassname="ups-link"><FormattedMessage id={textKey} /></Link><span aria-hidden="true"> &gt; </span></li>
    }

    render() {
        const { hasPastDueInvoices, businessUnit, businessUnitMessages, intl, businessUnitKeys, selectedMerchant } = this.props;
        const { invoice, isDrillMode, URLFilters, calendarOption, filterPayable, invoiceType } = this.state;
        this.setDocumentTitle(intl.locale);
        
        return (
            <MDBContainer role="main" fluid>
                <UseEffect observe={[intl.locale]} update={this.getPastDueInvoices}/>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                {this.renderBreadCrumb('/home', 'ups.title.dashboard')}
                                                {(isDrillMode && businessUnit === 'EBS') && this.renderBreadCrumb('/ups/billing/plan', 'invoice.my-plan-invoices.title')}
                                                {(isDrillMode && (businessUnit === 'FRT' || businessUnit == 'SCS')) && this.renderBreadCrumb('/ups/billing/plan', 'invoice.my-statements.title')}
                                                <li className="ups-breadcrumb-item">{this.getPageHeaderTitle()}</li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}>{this.getPageHeaderTitle()}</h1>
                                {isDrillMode && invoice && <>
                                    <div className={'invoice-summary-container'}>
                                        <MDBRow className={'pb-3'}>
                                            {businessUnit === 'EBS' &&
                                                <React.Fragment>
                                                    <MDBCol size={"12"} md={"3"} lg={"4"} className={"py-2"}>
                                                        <p className={'mb-0'}><strong><FormattedMessage id={'invoice.plan-number.label'} /></strong></p>
                                                        {stripAccountNumber(invoice.planNumber, businessUnit)}
                                                    </MDBCol>
                                                    <MDBCol size={"12"} md={"3"} lg={"4"} className={"py-2"}>
                                                        <p className={'mb-0'}><strong><FormattedMessage id={'invoice.amount-due.label'} /></strong></p>
                                                        {/* <FormattedNumber value={invoice.outstandingAmount} style={'currency'} currency={invoice.currencyCode || 'USD'} /> */}
                                                        {formatCurrency(intl, invoice.outstandingAmount, invoice.currencyCode || 'USD', selectedMerchant?.currencyDisplay)}
                                                    </MDBCol>
                                                </React.Fragment>
                                            }
                                            {(businessUnit === 'FRT' || businessUnit === 'SCS') &&
                                                <React.Fragment>
                                                    <MDBCol size={"12"} md={"3"} className={"py-2"}>
                                                        <p className={'mb-0'}><strong><FormattedMessage id={'invoice.statement-number.label'} /></strong></p>
                                                        {invoice.planInvoiceNumber}
                                                    </MDBCol>
                                                    <MDBCol size={"12"} md={"3"} className={"py-2"}>
                                                        <p className={'mb-0'}><strong><FormattedMessage id={'invoice.account-number.label'} /></strong></p>
                                                        {stripAccountNumber(invoice.accountNumber, businessUnit)}
                                                    </MDBCol>
                                                    <MDBCol size={"12"} md={"3"} className={"py-2"}>
                                                        <p className={'mb-0'}><strong><FormattedMessage id={'invoice.original-billed-amount.label'} /></strong></p>
                                                        {/* <FormattedNumber value={invoice.invoiceAmount} style={'currency'} currency={invoice.currencyCode || 'USD'} /> */}
                                                        {formatCurrency(intl, invoice.invoiceAmount, invoice.currencyCode || 'USD', selectedMerchant?.currencyDisplay)}
                                                    </MDBCol>
                                                </React.Fragment>
                                            }
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol size={"12"}>
                                                <div className={"invoice-summary-item-cta max-w-100 mt-0"}>
                                                    {this.renderPreviousPageButton()}
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </>}

                                <div className="alert alert-past-due" role="alert">
                                    {hasPastDueInvoices &&
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                                            <title><FormattedMessage id="alert.label" /></title>
                                            <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                                        </svg>
                                    }
                                    <span className="alert-msg">
                                        {`${hasPastDueInvoices ? (businessUnitMessages('pastDueAlert') + ' ') : ''}${intl.formatMessage({ id: 'invoice.alert-message.default' })}`}
                                    </span>
                                </div>
                                {this.shouldRenderTable &&
                                    <> 
                                        <InvoicesDownloadLinksModal />
                                        <InvoiceTable filterPayable={filterPayable} invoiceType={invoiceType} caption= {businessUnitKeys('pageTitle')} key={`invoice-search-results-v2-${businessUnit}`} colSize={"12"} isDrillMode={isDrillMode} invoice={invoice} filters={URLFilters} calendarOption={calendarOption} />
                                    </>
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { intl } = ownProps;
    return {
        preferences: state.identity.preferences,
        planEnrolled: state.identity.planEnrolled,
        hasPastDueInvoices: state.invoice.hasPastDueInvoices,
        countryLocale: state.settings.countryLocale,
        businessUnit: state.auth.user.selectedPaymentType,
        businessUnitMessages: getMessageByBU('invoiceTable')(intl)(state.auth.user.selectedPaymentType),
        businessUnitKeys: getKeyByBU('invoiceTable')(state.auth.user.selectedPaymentType),
        selectedMerchant: state.config.sessionSelection?.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSInvoice));


