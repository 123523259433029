import React, { Component } from "react";
import InputFieldIntl from "../../InputFieldIntl";
import { FormattedMessage } from "react-intl";
import { MDBBtn, MDBCol, MDBRow } from "mdbreact";
import CheckboxIntl from "../../CheckboxIntl";
import {doFocus} from "../../../utils/utils";

class PaymentHistoryCalendarInfoPanel extends Component {

    constructor(props) {
        super(props);

        const { filters, calendarOptionType } = this.props;

        this.state = {
            calendarOptionType: (filters && filters.calendarOptionType) || calendarOptionType,
            dateType: (filters && filters.dateType) || '1',
        };
    }

    handleInput = e => {
        const { handleCalendarChange } = this.props;
        const inputName = e.target.name;
        const inputValue = e.target.value;

        if (inputName == 'dateType') {
            handleCalendarChange({ dateType: inputValue });
            this.setState({
                dateType: inputValue,
            });
        } else {
            handleCalendarChange({ calendarOptionType: inputValue });
        }
    };

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    };

    submitDateFilter = () => {
        const { handleSearch } = this.props;
        handleSearch(this.state);
    }

    onClose = (action) => {
        const {closeFocusSelector='.btn-calendar'} = this.props;
        doFocus(closeFocusSelector);
        action();
    }

    render() {
        const { dateType } = this.state;
        const { showDatePicker, calendarOptionType, isDrillMode } = this.props;

        let calendarInvoiceOptionList = [];

        if (dateType === "1") {
            calendarInvoiceOptionList = ["TODAY", "LAST_7_DAYS", "LAST_30_DAYS", "LAST_60_DAYS", "LAST_90_DAYS", "AVAILABLE", "CUSTOM"];
        } else {
            calendarInvoiceOptionList = ["TODAY", "NEXT_7_DAYS", "NEXT_30_DAYS", "NEXT_60_DAYS", "NEXT_90_DAYS", "CUSTOM"];
        }

        const calendarRadioComponents = [];


        for (var calendarOption of calendarInvoiceOptionList) {
            calendarRadioComponents.push(
                <InputFieldIntl
                    key={calendarOption}
                    label={<FormattedMessage id={"calendar.option-range." + calendarOption} />}
                    type="radio"
                    name="calendarOptionType"
                    id={"calendarOptionType_" + calendarOption}
                    labelClass={"mr-0"}
                    value={calendarOption}
                    checked={calendarOptionType === calendarOption}
                    onChange={this.handleInput}
                    containerClass={"form-check mx-0 mb-3 col-6 col-md-12"}
                />
            )
        }
        return (
            <React.Fragment>
                <div className={"calendar-settings-panel"}>
                    <MDBRow>
                        <MDBCol size={"12"}>
                            <fieldset>
                                <legend>
                                    <FormattedMessage id={"payment-history.search-field.date-range-filter.label"} />
                                </legend>
                                <MDBRow>
                                    {calendarRadioComponents}
                                </MDBRow>
                            </fieldset>
                        </MDBCol>
                    </MDBRow>

                    {!isDrillMode &&
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <CheckboxIntl
                                    key={"saveSearchCriteria"}
                                    name={"saveSearchCriteria"}
                                    id={"saveSearchCriteria"}
                                    containerClass="mx-0 my-3 pl-0"
                                    labelClass="mr-0"
                                    onChange={({ target }) => { this.setState({ saveSearchCriteria: target.checked }) }}
                                    label={<FormattedMessage id={"invoice.search-field.save-date-range-filter.label"} />}
                                />
                            </MDBCol>
                        </MDBRow>
                    }

                    <div className={"calendar-settings-panel-actions"}>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <MDBBtn id={'invoice-search-date-btn-apply'} color={"primary"} className={"ml-0 my-0"} onClick={()=>this.onClose(this.submitDateFilter)}><FormattedMessage id={'invoice.search-field.btn-apply.label'} /></MDBBtn>
                                <MDBBtn id={'invoice-search-date-btn-cancel'} color={"text-link"} onClick={()=>this.onClose(showDatePicker)}><FormattedMessage id={'invoice.search-field.btn-cancel.label'} /></MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentHistoryCalendarInfoPanel;