import React from "react";
import {FormattedMessage} from "react-intl";
import {MDBTable, MDBTableBody} from "mdbreact";

export default function DisplayTable({rows}) {
    return <MDBTable className="table-two-col">
        <MDBTableBody>
            {rows.map((r) => {
                if (r.hide) return null;
                return <tr key={r.label} className={r.className}>
                    <th><FormattedMessage id={r.label}/></th>
                    <td>{r.value}</td>
                </tr>;
            })}
        </MDBTableBody>
    </MDBTable>;
}
