import React from 'react';
import {FormattedMessage} from 'react-intl';

const ErrorMessages = ({name, messages, values}) => {
    if (messages.length <= 0) return null;

    return (<div id={name + 'Error'} className="invalid-status" role="alert">
        {messages.map(
            (errorId, index) => (
                <div key={errorId + index}>
                    <FormattedMessage id={errorId} values={values}/>
                </div>
            )
        )}
    </div>);
}

export default ErrorMessages;
