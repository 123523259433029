import React, {useState, useEffect} from "react";
import {MDBCard, MDBCardBody, MDBRow, MDBCol} from "mdbreact";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import * as R from "ramda";
import moment from "moment";

import {ON_DUE, BEFORE_DUE, WEEKLY, MAX_DAYS_BEFORE_DUE} from "../../../../constants/autopay";
import {InputRestrictors as restrict} from "../../../../utils/validation/standard-validations";
import {useValidator} from "../../../../utils/validation";

import ValidationErrors from "../../../ValidationErrors";
import InputFieldIntl from "../../../InputFieldIntl";
import SelectFieldIntl from "../../../SelectFieldIntl";
import CurrencyFieldIntl from "../../../CurrencyFieldIntl";
import DateField from "../../../DateField";
import CheckboxWithValidation from "../../../CheckboxWithValidation";
import SchedulePreview from "./SchedulePreview";

function FreqRadio({frequency, schedule, onChange}) {
    const {type, payBeforeDueDate, scheduleDay} = schedule;
    const intl = useIntl();
    const validator = useValidator();
    const {vState} = useSelector(R.prop("validation"));
    let label = null;
    let ariaLabel = null;

    // Validated input errors must be cleared when radio swaps off them
    useEffect(() => {
        if (type === BEFORE_DUE) return;
        validator.validateGetValue("payBeforeDueDate");
    }, [type]);

    switch (frequency) {
        case ON_DUE:
            label = <FormattedMessage id={`schedule.frequency.${frequency}`}/>;
            ariaLabel = intl.formatMessage({id: `schedule.frequency.${frequency}`});
            break;

        case BEFORE_DUE:
            const inputName = "payBeforeDueDate";
            label = <React.Fragment>
                <label htmlFor={inputName} className="sr-only"><FormattedMessage id="modal.view-schedule.pay-before-due-date"/></label>
                <InputFieldIntl id={inputName} name={inputName}
                    containerClass="frequency-before-liability-container"
                    onChange={e => {
                        const {value} = e.target;
                        onChange({...schedule, type: frequency, payBeforeDueDate: value});
                    }}
                    value={payBeforeDueDate}
                    maxLength={2}
                    restrict={restrict.numeric}
                    validations={type === frequency ? [
                        ["required", "daysBefore.required"],
                        ["minMaxValue", `daysBefore.1to${MAX_DAYS_BEFORE_DUE}`, {
                            min: 1,
                            max: MAX_DAYS_BEFORE_DUE
                        }]
                    ] : []}
                    hideErrorMessages
                />
                <div className="days-before-text">
                    <FormattedMessage id="modal.view-schedule.pay-before-due-date"/>
                </div>
                <div className="frequency-before-liability-error">
                    <ValidationErrors name={inputName} messages={vState[inputName]?.messages || []}/>
                </div>
            </React.Fragment>;
            ariaLabel = payBeforeDueDate + ' ' + intl.formatMessage({id: "modal.view-schedule.pay-before-due-date"});
            break;

        case WEEKLY:
            label = <React.Fragment>
                <label htmlFor={"scheduleDay"} className="sr-only"><FormattedMessage id="modal.view-schedule.selected.day.label"/></label>
                <SelectFieldIntl name="scheduleDay" id="scheduleDay" setLabelBefore
                    extraContainerClasses="weekly-before-liability-container"
                    value={scheduleDay}
                    selectOptions={[2, 3, 4, 5, 6, 7, 1].map(id => ({
                        value: id.toString(),
                        label: intl.formatMessage({id: `select.week.day.${id}`})
                    }))}
                    onChange={e => {
                        const {value} = e.target;
                        onChange({...schedule, type: frequency, scheduleDay: value});
                    }}
                />
                <div className="weekday-before-text">
                    <FormattedMessage id="modal.view-schedule.pay-weekly"/>
                </div>
            </React.Fragment>;
            ariaLabel = intl.formatMessage({id: `select.week.day.${scheduleDay}`}) + ' ' + intl.formatMessage({id: "modal.view-schedule.pay-weekly"});
            break;

        default:
            break;
    }

    return <MDBCol size="12" className={frequency === ON_DUE ? "mb-1" : ""}>
        <InputFieldIntl id={`type_${frequency}`} name="type" type="radio"
            label={frequency === ON_DUE && label}
            onClick={() => onChange({...schedule, type: frequency})}
            checked={type === frequency}
            aria-label={ariaLabel}
        />
        {frequency !== ON_DUE && label}
    </MDBCol>;
}

function LimitControl({schedule, onChange, maxCurrencyDigits}) {
    const {amount} = schedule;
    const intl = useIntl();
    const validator = useValidator();
    const [limited, setLimited] = useState(!R.isNil(amount));

    useEffect(() => {
        if (limited) return;
        validator.validateGetValue("amount");
    }, [limited]);

    return <React.Fragment>
        <MDBCol className="mb-3" size="12">
            <InputFieldIntl id="unlimited" name="limited" type="radio"
                onClick={() => {
                    setLimited(false);
                    onChange({...schedule, amount: null});
                }}
                checked={!limited}
                label={intl.formatMessage({id: "modal.view-schedule.total-amount-charged"})}
            />
        </MDBCol>

        <MDBCol className="mb-3" size="12">
            <InputFieldIntl id="limited" name="limited" type="radio"
                onClick={() => setLimited(true)}
                checked={limited}
                label={intl.formatMessage({id: "modal.view-schedule.total-amount-limit"})}
                aria-label={`${intl.formatMessage({id: "modal.view-schedule.total-amount-limit"})} ${amount ? amount : ''} `}
            />
            <React.Fragment>
                <label htmlFor="amount" className="sr-only">{intl.formatMessage({id: "modal.view-schedule.payment-amount.aria-label"})}</label>
                <CurrencyFieldIntl id="amount" name="amount"
                    containerClass="amount-due-limit-container"
                    hint={`invoice.amountDue.account.hint${Number.isNaN(parseInt(maxCurrencyDigits)) ? '': ("." + maxCurrencyDigits)}`}
                    onChange={e => {
                        const {value} = e.target;
                        setLimited(true);
                        onChange({...schedule, amount: value});
                    }}
                    value={amount ?? ""}
                    maxLength={10}
                    restrict={restrict.currencyLimited(7)}
                    validations={limited ? [
                        ["required", "field.currency"],
                        ["currency", "field.currency"]
                    ] : []}
                    maxCurrencyDigits={maxCurrencyDigits}
                />
            </React.Fragment>
            <p className="amount-limit-note mb-0">
                <FormattedMessage id="modal.view-schedule.total-amount-limit-note"/>
            </p>
        </MDBCol>
    </React.Fragment>;
}

function ExpiryControl({schedule, onChange}) {
    const {expiryDate} = schedule;
    const intl = useIntl();
    const validator = useValidator();
    const [expires, setExpires] = useState(!R.isNil(expiryDate));
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (expires) return;
        validator.validateGetValue("expiryDate");
    }, [expires]);

    return <React.Fragment>
        <MDBCol className="mb-3" size="12">
            <InputFieldIntl id="endless" name="expires" type="radio"
                onClick={() => {
                    setExpires(false);
                    onChange({...schedule, expiryDate: null});
                }}
                checked={!expires}
                label={intl.formatMessage({id: "modal.view-schedule.endless"})}
            />
        </MDBCol>

        <MDBCol className="mb-3" size="12">
            <InputFieldIntl id="expires" name="expires" type="radio"
                onClick={() => {
                    setExpires(true);
                    setFocused(true);
                }}
                checked={expires}
                label={intl.formatMessage({id: "modal.view-schedule.ends-on"})}
            />
            <DateField id="expiryDate" name="expiryDate"
                containerClass="md-form mt-0 until-date-container"
                date={expiryDate} onDateChange={() => {}}
                onBlur={d => {
                    if (!d?.value) return;
                    setExpires(true);
                    onChange({...schedule, expiryDate: d.value});
                }}
                focused={focused}
                onFocusChange={({focused}) => setFocused(focused)}
                restrict={restrict.datemmddyyyy}
                validations={expires ? [
                    ["required", "endDate.required"],
                    ["daterange", "endDate.future", {
                        min: -1,
                        max: Number.NEGATIVE_INFINITY,
                        scale: "days"
                    }]
                ] : []}
                range={d => !d.isAfter(moment(), "day")}
                ariaLabel={intl.formatMessage({id: "modal.view-schedule.ends-on"})}
            />
        </MDBCol>
    </React.Fragment>;
}

function AuthCheckbox({schedule, onChange}) {
    const {authorizeSchedule} = schedule;
    const intl = useIntl();

    return <MDBCol size="12">
        <CheckboxWithValidation id="authorizeSchedule" name="authorizeSchedule"
            label={intl.formatMessage({id: "schedule.authorize"})}
            value={authorizeSchedule || false}
            onChange={e => {
                const {checked} = e.target;
                onChange({...schedule, authorizeSchedule: checked});
            }}
            validations={[["required", "field.authorizeSchedule"]]}
        />
    </MDBCol>;
}

export default function PaymentSettings({schedule, onChange, maxCurrencyDigits}) {
    return <MDBRow>
        <MDBCol size="12">
            <h3 className="mb-4">
                <FormattedMessage id="modal.view-schedule.subtitle.schedule-information"/>
            </h3>
        </MDBCol>

        <MDBCol size="12">
            <SchedulePreview schedule={schedule}/>
        </MDBCol>

        <MDBCol size="12">
            <p className="ups-note-1 my-0">*<span className="font-italic">
                <FormattedMessage id="ups.required-field.note"/>
            </span></p>
        </MDBCol>

        <MDBCol size="12">
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="12" md={"6"}>
                            <fieldset required>
                                <legend>
                                    <FormattedMessage id="modal.view-schedule.frequency"/>*
                                </legend>
                                <MDBRow>
                                    <FreqRadio frequency={ON_DUE} schedule={schedule} onChange={onChange}/>
                                    <FreqRadio frequency={BEFORE_DUE} schedule={schedule} onChange={onChange}/>
                                    <FreqRadio frequency={WEEKLY} schedule={schedule} onChange={onChange}/>
                                </MDBRow>
                            </fieldset>
                        </MDBCol>

                        <MDBCol size="12" md={"6"}>
                            <fieldset required>
                                <legend>
                                    <FormattedMessage id="modal.view-schedule.payment-amount"/>*
                                </legend>
                                <MDBRow>
                                    <LimitControl schedule={schedule} onChange={onChange} maxCurrencyDigits={maxCurrencyDigits} />
                                </MDBRow>
                            </fieldset>
                        </MDBCol>

                        <MDBCol size="12" md={"6"} className={"mt-2 mt-md-0"}>
                            <fieldset required>
                                <legend>
                                    <FormattedMessage id="modal.view-schedule.end-date"/>*
                                </legend>
                                <MDBRow>
                                    <ExpiryControl schedule={schedule} onChange={onChange}/>
                                </MDBRow>
                            </fieldset>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>

        <AuthCheckbox schedule={schedule} onChange={onChange}/>
    </MDBRow>;
}
