import React, { Component } from 'react'
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import CheckboxIntl from '../../../components/CheckboxIntl';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as invoiceActions from "../../../actions/invoice-action";
import * as identityActions from "../../../actions/identity-action";
import * as invoiceApi from "../../../api/invoice-api";
import * as identityApi from "../../../api/identity-api";
import SaveSearchSettingsModal from '../../../components/UPS/Modal/SaveSearchSettingsModal';
import { FormattedMessage, injectIntl } from 'react-intl';
import { countryCodeToMerchantId } from "../../../utils/utils"
import {padAccountNumbers, stripAccountNumber} from "../../../utils/ups-utils";
import {getMultiSelectList} from "../../../utils/invoice-utils";
import { MDBPopoverWrapper } from "../../MDBFix/MDBPopoverWrapper";
import ReactSelectAllGrouped from '../../ReactSelectAllGrouped';
import { isEmpty } from 'lodash';

const allOption = { value: "*", msgId: "filter.multi.option.dropdown.all.selector"};

const multiSelectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    isMulti:true,
    closeMenuOnSelect:false,
    hideSelectedOptions:false,
    allowSelectAll:true,
    isSearchable:false,
    intlLabel: true,
    backspaceRemovesValue:false,
    placeholder:<FormattedMessage id={"react-select.select-your-filters"}/>
};

class UPSPlanSearchCriteria extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saveSearchCriteria: false,
            loaded: false,
            invoiceStatusList: [],
            invoiceTypeList: [],
        };
    }

    onInit = async () => {
        const { planInvoiceFilters, envProps, identityActions, onUpdatePlanFilters } = this.props;
        const { businessUnit } = this.props;
        const { apiUrlPrefix, apiToken } = envProps;
        
        try {
            identityApi.getIdentityValidationAccountsList(apiUrlPrefix, apiToken).then(async (res) => {
                const accounts = res?.parsedBody;
                if (!accounts || isEmpty(accounts)) return;
                // Restrict dropdowns to statuses and types which have invoices
                const planNumberList = 
                    accounts
                    .filter(a => {
                        if (businessUnit === 'EBS') 
                            return a.isParent && a.paymentType === businessUnit;
                        else 
                            return a.paymentType === businessUnit;
                    })
                    .map(({planNumber, accountNumber}) => {
                        const planOrAccountNumber = (businessUnit === 'SCS') ? accountNumber : planNumber
                        return stripAccountNumber(planOrAccountNumber, businessUnit)
                    });

                const key = (businessUnit === 'EBS') ? 'planNumber' : 'accountNumber';
                const invoiceAggregate = (await invoiceApi.getInvoiceAggregate(
                    {
                        [key]: businessUnit === "EBS" ? padAccountNumbers(planNumberList) : planNumberList,
                        isPlanPage: true,
                    },
                    apiUrlPrefix, apiToken
                )).parsedBody;


                const is = getMultiSelectList(invoiceAggregate.invoiceStatuses, planInvoiceFilters.invoiceStatus, 'invoiceStatuses', this.props);
                is.unshift(allOption);
                this.allSelectionInvoiceStatus = is.map((option)=>({...option, checked: true}));
               
                const it = getMultiSelectList(invoiceAggregate.invoiceTypes, planInvoiceFilters.invoiceType, 'invoiceTypes', this.props);
                it.unshift(allOption);
                this.allSelectionInvoiceTypes = it.map((option)=>({...option, checked: true}));
                const isInvoiceTypeFiltered = planInvoiceFilters?.invoiceTypeNotIn ? it.find(obj=>planInvoiceFilters.invoiceTypeNotIn.includes(obj.value) ) : false;
                if(isInvoiceTypeFiltered) {
                    const selectedInvoiceType =  it.filter(function (obj) {return (!planInvoiceFilters.invoiceTypeNotIn.includes(obj.value) && obj.value != '*')}).map(({value})=> value);
                    onUpdatePlanFilters("invoiceType", selectedInvoiceType, this.allSelectionInvoiceTypes)
                }

                const planNumbersMap = planNumberList.sort().reduce((acc,curr)=> (acc[curr]='',acc),{})
                const selected = (businessUnit === 'EBS') ? (Array.isArray(planInvoiceFilters?.planNumber) && planInvoiceFilters?.planNumber.length > 0 ? planInvoiceFilters.planNumber?.map(( accountNumber ) => stripAccountNumber(accountNumber, businessUnit)) : null) : (Array.isArray(planInvoiceFilters?.planNumber) && planInvoiceFilters?.planNumber.length > 0 ? planInvoiceFilters.accountNumber?.map(( accountNumber ) => stripAccountNumber(accountNumber, businessUnit)) : null);
                let pn = getMultiSelectList(planNumbersMap, selected, 'planNumber', this.props, false)
                pn.unshift(allOption);
                this.allSelectionPlanNumbers = pn.map((option)=>({...option, checked: true}))

                this.setState({
                    loaded: true,
                    planNumbersList: [{
                        msgId: "react-select.select-your-filters",
                        options: pn
                    }],
                    invoiceStatusList: [{
                        msgId: "react-select.select-your-filters",
                        options: is
                    }],
                    invoiceStatusList: [{
                        msgId: "react-select.select-your-filters",
                        options: is
                    }],
                    invoiceTypeList: [{
                        msgId: "react-select.select-your-filters",
                        options: it
                    }]
                });
            });
        } catch (err) {
            console.error(err);
        }
    }

    componentDidMount() {
        this.onInit();
    }

    toggleModal = (modalName, dataIndex = 0) => {
        this.setState({
            [`${modalName}Modal`]: !this.state[`${modalName}Modal`]
        });
    };

    renderPlanInvoiceNumberFilter = () => {
        const { planInvoiceFilters, intl, businessUnit, onUpdatePlanFilters } = this.props;
        const { planNumbersList } = this.state;

        let filters;
        if ((businessUnit === 'EBS')){
            filters = planInvoiceFilters?.planNumber ? planInvoiceFilters?.planNumber.map(val => ({value: val})) : this.allSelectionPlanNumbers.map(val => ({value: val.value}));
        } else {
            filters = planInvoiceFilters?.accountNumber ? planInvoiceFilters?.accountNumber.map(val => ({value: val})) : this.allSelectionPlanNumbers.map(val => ({value: val.value}));
        }
        
        const key = (businessUnit === 'EBS') ? 'planNumber' : 'accountNumber';

        const msgIdType = (businessUnit === 'EBS') ? 'plan' : 'account';
        const size = '12';

        return (
            <MDBCol md={size} key={"field-plan-number"}>
                <div className={"autocomplete-form"}>
                    <label className="autocomplete-default-label" htmlFor="planNumber">
                        <FormattedMessage id={`invoice.search-field.${msgIdType}-number.label`} />&nbsp;&nbsp;
                        <MDBPopoverWrapper
                            id="planNumber"
                            buttonLabel={`${intl.formatMessage({ id: `invoice.search-field.${msgIdType}-number.alt` })}`}
                            content={<><FormattedMessage id={`invoice.search-field.${msgIdType}-number.help`} /></>}
                        />
                    </label>
                   <ReactSelectAllGrouped
                        inputId={"planNumber"}
                        options={planNumbersList}
                        field="planNumber"
                        onChange={(selected) => {
                            const val = selected.map(x => x.value);
                            onUpdatePlanFilters(key, val, this.allSelectionPlanNumbers)} 
                        }
                        value={filters}
                        {...multiSelectProps}
                    />
                </div>
            </MDBCol>
        );
    }

    renderPlanInvoiceStatusFilter = () => {
        const { onUpdatePlanFilters, businessUnit, intl, planInvoiceFilters } = this.props;
        const { invoiceStatusList } = this.state;

        if (businessUnit !== 'EBS') return null;
        const filters = planInvoiceFilters?.invoiceStatus ? planInvoiceFilters?.invoiceStatus.map(val => ({value: val})) : this.allSelectionInvoiceStatus.map(val => ({value: val.value}));
        
        const size = '6';

        return (
            <MDBCol md={size} key={"field-invoice-status"}>
                <ReactSelectAllGrouped
                    label={intl.formatMessage({id:"invoice.search-field.invoice-status.label"})}
                    inputId={"invoiceStatusFilter"}
                    options={invoiceStatusList}
                    field="invoiceStatus"
                    onChange={(selected) => {
                        const val = selected.map(x => x.value);
                        onUpdatePlanFilters("invoiceStatus", val, this.allSelectionInvoiceStatus)} 
                    }
                    value={filters}
                    {...multiSelectProps}
                />
            </MDBCol>
        );
    }

    renderPlanInvoiceTypeFilter = () => {
        const { onUpdatePlanFilters, businessUnit, intl, planInvoiceFilters } = this.props;
        const { invoiceTypeList } = this.state;

        if(businessUnit === 'SCS') return null;
        const msgIdType = 'invoice';
        const size = (businessUnit === 'EBS') ? '6' : '12';
        const filters = planInvoiceFilters?.invoiceType ? planInvoiceFilters?.invoiceType.map(val => ({value: stripAccountNumber(val, businessUnit)})) : this.allSelectionInvoiceTypes.map(val => ({value: val.value}));
        
        return (
            <MDBCol md={size} key={"field-invoice-type"}>
                <ReactSelectAllGrouped
                    label={intl.formatMessage({id:`invoice.search-field.${msgIdType}-type.label`})}
                    inputId={"invoiceTypeFilter"}
                    options={invoiceTypeList}
                    field="invoiceType"
                    onChange={(selected) => {
                        const val = selected.map(x => x.value);
                        onUpdatePlanFilters("invoiceType", val, this.allSelectionInvoiceTypes)} 
                    }
                    value={filters}
                    {...multiSelectProps}
                />
            </MDBCol>
        );
    }

    render() {
        const { modalMode, planInvoiceFilters, onUpdatePlanFilters, saveSearchCriteria } = this.props;
        const {  loaded } = this.state;
        const { saveSearchSettingsModal } = planInvoiceFilters;
        const components = [];

        if (!loaded) return null;

        components.push(this.renderPlanInvoiceNumberFilter());
        components.push(this.renderPlanInvoiceStatusFilter());
        components.push(this.renderPlanInvoiceTypeFilter());

        const modalComponents = [];
        if (saveSearchSettingsModal) {
            modalComponents.push(
                <SaveSearchSettingsModal
                    key={'save-search-criteria-modal'}
                    isOpen={saveSearchSettingsModal}
                    toggleModal={this.toggleModal}
                />
            );
        }

        return(
            <React.Fragment>
                <MDBRow>
                    {components}
                </MDBRow>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <CheckboxIntl
                            key={"rememberPlanSearchCriteria"}
                            name={"rememberPlanSearchCriteria"}
                            id={"rememberPlanSearchCriteria"}
                            containerClass="mx-0 my-3 pl-0"
                            labelClass="mr-0"
                            checked={saveSearchCriteria}
                            onClick={({ target }) => onUpdatePlanFilters('saveSearchCriteria', target.checked)}
                            label={<FormattedMessage id={"invoice.search-field.invoice-save-search-settings"} />}
                        />
                    </MDBCol>
                </MDBRow>
                {!modalMode ?
                <MDBRow className={"mt-3"}>
                    <MDBCol size={"12"}>
                        <MDBBtn color="secondary" className="m-0 mr-3"><FormattedMessage id={"reset.label"}/></MDBBtn>
                        <MDBBtn color="primary" className="m-0" onClick={this.handlePlanSearch(saveSearchCriteria)}><FormattedMessage id={"search.label"}/></MDBBtn>
                    </MDBCol>
                </MDBRow> : null}
                {modalComponents}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, settings, envProps, config } = state;
    const { invoiceMetadata } = state.invoice;
    const { merchants } = config;
    const { user } = auth;

    let merchantId;
    if (merchants && user && user.selectedCountry) {
        const countryCode = user.selectedCountry;
        merchantId = countryCodeToMerchantId(config.merchants, countryCode);
    }

    return {
        invoiceMetadata,
        merchantId,
        settings,
        envProps,
        user,
        businessUnit: user.selectedPaymentType,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSPlanSearchCriteria));