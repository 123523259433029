/*
 * Copyright (C) 2020 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 6/27/20, 11:56 AM
 * Created by kxian
 *
 */

import {fetchWrapper} from "../utils/utils";

export function enroll(data, apiUrlPrefix, simulateApi) {
    const { userId, ...rest } = data.userInfo;
    const body = {
        account: {
            accountNumber: data.account.accountNumber,
            planNumber: data.account.planNumber,
            accountType: data.account.accountType,
            paymentType: data.account.businessUnit,
            merchantId: data.account.merchantId,
            invoiceNumber: data.account.invoiceNumber,
            countryCode: data.account.countryCode,
            hid: data.account.hid
        },
        accountType: data.account.accountType,
        userInfo: rest,
        companyName: data.companyName
    };

    const url = `${apiUrlPrefix}/accounts/enroll`,
        method = 'POST',
        headers = {
            'Content-Type': 'application/json'
        };
    return fetchWrapper(url, method, headers, body);
}



