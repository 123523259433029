export const countryDialCodeData = [
        {countryCode: "CA", description: "+1", code: "1"},
        {countryCode: "US", description: "+1", code: "1"},
        {countryCode: "AT", description: "+43", code: "43"},
        {countryCode: "AU", description: "+61", code: "61"},
        {countryCode: "BE", description: "+32", code: "32"},
        {countryCode: "CZ", description: "+420", code: "420"},
        {countryCode: "DK", description: "+45", code: "45"},
        {countryCode: "FI", description: "+358", code: "358"},
        {countryCode: "FR", description: "+33", code: "33"},
        {countryCode: "DE", description: "+49", code: "49"},
        {countryCode: "HK", description: "+852", code: "852"},
        {countryCode: "HU", description: "+36", code: "36"},
        {countryCode: "IE", description: "+353", code: "353"},
        {countryCode: "IT", description: "+39", code: "39"},
        {countryCode: "LU", description: "+352", code: "352"},
        {countryCode: "MX", description: "+52", code: "52"},
        {countryCode: "NL", description: "+31", code: "31"},
        {countryCode: "NO", description: "+47", code: "47"},
        {countryCode: "PL", description: "+48", code: "48"},
        {countryCode: "PR", description: "+1", code: "1"},
        {countryCode: "SG", description: "+65", code: "65"},
        {countryCode: "ES", description: "+34", code: "34"},
        {countryCode: "SE", description: "+46", code: "46"},
        {countryCode: "CH", description: "+41", code: "41"},
        {countryCode: "GB", description: "+44", code: "44"},
        {countryCode: "CN", description: "+86", code: "86"}
];