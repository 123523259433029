import {formatCurrency, formatDate, formatToLocalDate} from "../../../utils/ups-utils";
import {formatPaymentMethod} from "../../../utils/payment-history-util";
import {AUTO_PAY, CHANNEL_CODE_EXT, SCHEDULED_PAYMENT} from "../../../constants/payment-history";

export const columnParsers = (intl) => {
    return {
        schedule: ({channel, executionTypeCode}) => {
            if (channel === SCHEDULED_PAYMENT || (channel === CHANNEL_CODE_EXT && executionTypeCode === AUTO_PAY)) {
                return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.automatic' })
            } else {
                return intl.formatMessage({ id: 'payment-history.search-field.schedule.option.one_time' })
            }
        },
        paymentAmount: ({totalAmount = '', currencyCode = '', currencyDisplay = 'symbol'}) => formatCurrency(intl, totalAmount, currencyCode, currencyDisplay),
        paymentMethod: ({paymentMethod=''})=>formatPaymentMethod(paymentMethod, intl),
        paymentStatus: ({paymentStatus})=>intl.formatMessage({ id: `payment-history.search-field.status.option.${paymentStatus}` }),
        paymentDate: ({channel, paymentDate}) => (channel === CHANNEL_CODE_EXT) ? formatDate(intl, paymentDate || "") : formatToLocalDate(intl, paymentDate || "")
    }
}