import React from "react";

class OptOutPreferencesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.isDirty = this.isDirty.bind(this);
    }

    isDirty() {
        return this.state.propChangeCounter > 0;
    }

    getPreferences() { 
        let preferences = {};
        const objKeys = Object.keys(this.state);
        objKeys.forEach((key) => {
            if(this.state[key] !== undefined && typeof this.state[key] !== 'function' && key !== "propChangeCounter"){
                preferences[key] = this.state[key];
            }
        });

        return preferences;
    }

    // return count of properties updated in a section
    onPreferenceChange(field) {
        let { propChangeCounter } = this.state;
        const { triggers } = this.props.preferences?.notifications?.emailSettings;
       
        // case where user chooses not to send any notifications for a section
        if(!field){
            propChangeCounter = 0;
            Object.keys(this.state).forEach(field => {
                // check if property which is being set in state is even present in user's object in DB, if not, then ignore that property and do not update propChangeCounter.
                if(triggers[field] !== undefined && field !== "propChangeCounter" && typeof this.state[field] !== 'function'){
                    if(triggers[field] != this.state[field]) {
                        ++propChangeCounter;
                    }
                }
            });
        }
        else{
            // not using strict equal operator due to a requirement in child section to ignore data type 
            if(triggers[field] == this.state[field]) {
                --propChangeCounter;
            }
            else{
                ++propChangeCounter;
            }
        }

        this.setState({
            propChangeCounter:  propChangeCounter
        }, () => {
            this.props.onSectionChange();
        });
    }
}


export default OptOutPreferencesComponent;