import React                                        from 'react';
import { FormattedMessage, FormattedDate, useIntl }                 from "react-intl";
import { MDBModal, MDBModalBody, MDBModalHeader
       , MDBBtn, MDBCol,  MDBRow, MDBModalFooter }  from 'mdbreact';
import * as R                                       from 'ramda';
import classNames                                   from "classnames";

import ReactPortalModal                             from '../../ReactPortalModal';
import DataTableV3                                  from '../../DataTableV3/DataTableV3';
import Alert from "../../Alert";
import {getMessageByBU, formatCurrency} from "../../../utils/ups-utils";
import {FormattedNumber} from "../../IntlFix/FormattedNumber";


const f = intl => R.compose(
    args => intl.formatMessage(...args),
    (id, values) => [{id}, values]
);

const isPlan = R.compose
  ( R.allPass
      ([ R.has ('planNumber')
      , R.complement(R.has ('accountNumber'))
      ])
  , R.prop('invoices')
  );

const getAccountNumber = R.compose
  ( R.ifElse
      ( isPlan
      , R.prop('planNumber')
      , R.prop('accountNumber')
      )
  , R.prop('invoices')
  );

const getInvoiceNumber = R.compose
  ( R.ifElse
      ( isPlan
      , R.prop('planInvoiceNumber')
      , R.prop('invoiceNumber')
      )
  , R.prop('invoices')
  );

const getInvoiceDueDate = R.path(['invoices', 'dueDate']);
const getInvoiceDate = R.path(['invoices', 'invoiceDate']);
const getAmountDue = R.path(['invoices', 'outstandingAmount']);
const getCurrency = R.path(['invoices', 'currencyCode']);
const getLastPaymentAmount = R.path(['invoices', 'amount']);

const getTableColumns = (intl, businessUnit, merchant) =>
  [
    { label: ""
    , field: 'mobileCard'
    , sortable: false
    , thClassName: "datatable-card no-export d-none"
    , tdClassName: "datatable-card no-export d-none"
    , display: invoice =>
        <MobileCard invoice={invoice} intl={intl} businessUnit={businessUnit} merchant={merchant} />
    }
  , {
      label: f(intl)(`payment-history.history-table.plan_or_account_number.${businessUnit}`)
      , field: 'accountNumber'
  }
  , { label: getMessageByBU('paymentFlowTable')(intl)(businessUnit)('invoiceDateLabel')
    , field: 'invoiceDate'
    , display: ({invoiceDate}) =>
        <FormattedDate
            value={invoiceDate}
            year="numeric"
            month="short"
            day="2-digit"
            timeZone="utc"
        />
    }
  , { label: getMessageByBU('paymentFlowTable')(intl)(businessUnit)('invoiceNumLabel')
    , field: 'invoiceNumber'
    }
  , { label: f(intl)("invoice.invoice-table.amount-due")
    , field: 'amountDue'
    , thClassName: "text-right"
    , tdClassName: "text-right"
    , display: ({amountDue, currency}) =>
      formatCurrency(intl, amountDue, currency, merchant?.currencyDisplay)
    }
  , { label: f(intl)("invoice.recent-payments.alert-last-payment-amount")
    , field: 'lastPayAmount'
    , thClassName: "text-right"
    , tdClassName: "text-right"
    , display: ({lastPayAmount, currency}) =>
        formatCurrency(intl, lastPayAmount, currency, merchant?.currencyDisplay)
    }
  , { label: f(intl)("invoice.recent-payments.alert-last-payment-due-date")
    , field: 'lastPayDueDate'
    , display: ({lastPayDueDate}) =>
        <FormattedDate
            value={lastPayDueDate}
            year="numeric"
            month="short"
            day="2-digit"
            timeZone="utc"
        />
    }
  ];

const flattenLastPayments = R.map
    (invoice => ({ accountNumber: getAccountNumber (invoice)
                 , invoiceDate: getInvoiceDate (invoice)
                 , invoiceNumber: getInvoiceNumber (invoice)
                 , amountDue: getAmountDue (invoice)
                 , lastPayAmount: getLastPaymentAmount (invoice)
                 , lastPayDueDate: getInvoiceDueDate (invoice)
                 , currency: getCurrency(invoice)
                 })
    );

function MobileCard({invoice,  intl, businessUnit, merchant}) {
    return <div className="table-card-mobile-container pt-2">
        <table className="table table-card-mobile-view no-selection mt-0">
            <tbody>
                <tr>
                    <th>{f(intl)(`payment-history.history-table.plan_or_account_number.${businessUnit}`)}</th>
                    <td>{invoice.accountNumber}</td>
                </tr>
                <tr>
                    <th>{getMessageByBU('paymentFlowTable')(intl)(businessUnit)('invoiceDateLabel')}</th>
                    <td><FormattedDate
                        value={invoice.invoiceDate}
                        year="numeric"
                        month="short"
                        day="2-digit"
                        timeZone="utc"
                    /></td>
                </tr>
                <tr>
                    <th>{getMessageByBU('paymentFlowTable')(intl)(businessUnit)('invoiceNumLabel')}</th>
                    <td>{invoice.invoiceNumber}</td>
                </tr>
                <tr>
                    <th>{f(intl)("invoice.invoice-table.amount-due")}</th>
                    <td>{formatCurrency(intl, invoice.amountDue, invoice.currency, merchant?.currencyDisplay)}</td>
                </tr>
                <tr>
                    <th>{f(intl)("invoice.recent-payments.alert-last-payment-amount")}</th>
                    <td>{formatCurrency(intl, invoice.lastPayAmount, invoice.currency, merchant?.currencyDisplay)}</td>
                </tr>
                <tr>
                    <th>{f(intl)("invoice.recent-payments.alert-last-payment-due-date")}</th>
                    <td><FormattedDate
                        value={invoice.lastPayDueDate}
                        year="numeric"
                        month="short"
                        day="2-digit"
                        timeZone="utc"
                    /></td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default function RecentPaymentsModal ({toggleModal, isOpen, invoices, acceptRepayment, businessUnit, paymentBlocked, flowConsts, merchant}) {
    const intl = useIntl();
    const modalHeading = "invoice.recent-payments.alert-title";
    return (
      <ReactPortalModal isOpen={isOpen} an_label="recent-payments">
        <MDBModal
          isOpen={isOpen}
          toggle={toggleModal}
          size={"xxl"}
          disableBackdrop
          centered
          disableFocusTrap={false}
          labelledBy={intl.formatMessage({ id: modalHeading })}
        >
          <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} className="alert-red" toggle={toggleModal}>
            <FormattedMessage id={modalHeading} />
          </MDBModalHeader>
          <MDBModalBody>
              <MDBRow>
                  <MDBCol size={"12"}>
                    <p>
                      <FormattedMessage id={flowConsts.getKey('recentPaymentModalDesc')} />
                    </p>
                  </MDBCol>
              </MDBRow>
              <MDBRow>
                  <MDBCol size={"12"}>
                      <DataTableV3
                      caption={modalHeading}
                          className="table-sm table-card-mobile allow-default-wrap"
                        data={flattenLastPayments(invoices)}
                        columns={getTableColumns (intl, businessUnit, merchant)}
                        pagination={true}
                        itemsPerPage={[10, 20, 50, 100]}
                      />
                  </MDBCol>
              </MDBRow>
              <MDBRow>
                  <Alert className='center-alert'
                         label={paymentBlocked ? flowConsts.getKey('recentPaymentModalBlockedWarning') : flowConsts.getKey('recentPaymentModalWarning')}
                         type={'warning'}
                  />
              </MDBRow>
          </MDBModalBody>
            <MDBModalFooter>
                <MDBRow>
                    <MDBCol size="12" className="modal-button-col">
                        <MDBBtn
                            color="primary"
                            id="details-btn-submit"
                            onClick={acceptRepayment}
                            disabled={paymentBlocked}
                        >
                            <FormattedMessage id="make-payment.btn.continue" />
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol size="12" className="modal-button-col">
                        <MDBBtn
                            id="details-btn-cancel"
                            flat
                            className={classNames({ "btn-flat-link": true })}
                            onClick={toggleModal}
                        >
                            <FormattedMessage id="modal.payment.btn.close" />
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBModalFooter>
        </MDBModal>
      </ReactPortalModal>
    );
}
