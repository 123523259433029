import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBBtn, MDBModalFooter, MDBRow, MDBCol } from "mdbreact";
import ReactPortalModal from "../../../../components/ReactPortalModal/index";
import { useDispatch } from "react-redux";

const BatchRequestModal = ({ isOpen, toggleModal, fetchBatchReportProps,maxResult }) => {
  const [isBatchSubmitted, setIsBatchSubmitted] = useState(false);
  const [isBatchSuccess, setIsBatchSuccess] = useState(undefined);
  const dispatch = useDispatch();
  const callback = (data, res) => {
    setIsBatchSuccess(res.status === 200);
    fetchBatchReportProps.callback(data);
  };
  useEffect(() => {
    if (isBatchSubmitted) {
      setIsBatchSuccess(false);
      dispatch({ ...fetchBatchReportProps, callback });
    }
  }, [isBatchSubmitted]);

  const modalHeading = "invoice.batchReportRequest.title";
  const intl = useIntl();
  return (
    <ReactPortalModal isOpen={isOpen} an_label="batch-request">
      <MDBModal isOpen={isOpen} toggle={toggleModal} size="lg" backdrop={true} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
        <MDBModalHeader aria-live="assertive" tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={toggleModal}>
          <FormattedMessage id={modalHeading} />
        </MDBModalHeader>
        <MDBModalBody aria-live="assertive">
          <MDBRow>
            <MDBCol size={"12"}>
              {!isBatchSubmitted && (
                <FormattedHTMLMessage tagName={"p"} id={"invoice.batchReportRequest.description"} values={{maxResult}}/>
              )}
              {isBatchSuccess === true ? (
                <FormattedHTMLMessage tagName={"p"} id="invoice.batchReportRequest.success" />
              ) : (
                isBatchSuccess === false && (
                  <FormattedHTMLMessage tagName={"p"} id="invoice.batchReportRequest.failed" />
                )
              )}
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter aria-live="polite">
          {!isBatchSubmitted && (
            <React.Fragment>
              <MDBBtn color="primary" onClick={() => setIsBatchSubmitted(!isBatchSubmitted)}>
                <FormattedMessage id="invoice.dispute.request-service-refund.btn-submit.label" />
              </MDBBtn>
              <MDBBtn color="secondary" onClick={toggleModal}>
                <FormattedMessage id="invoice.dispute.request-service-refund.btn-cancel.label" />
              </MDBBtn>
            </React.Fragment>
          )}
          {isBatchSubmitted && (
            <MDBBtn color="secondary" onClick={toggleModal}>
              <FormattedMessage id="invoice.dispute.request-service-refund.btn-close.label" />
            </MDBBtn>
          )}
        </MDBModalFooter>
      </MDBModal>
    </ReactPortalModal>
  );
};

export default BatchRequestModal;
