import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {walletClassOptionsData} from "../../constants/static-demo-data";
import {bindActionCreators} from "redux";
import classNames from "classnames";
import * as validationActions from "../../actions/validation-action";
import * as errorActions from "../../actions/error-action";
import {connect} from "react-redux";
import attachValidator from "../../utils/validation/attach-validator";
import {MDBInput} from "mdbreact";
import ValidationErrors from "../ValidationErrors";
class SelectFieldIntl extends Component{
    constructor(props){
        super(props)
        this.onChange = this.onChange.bind(this)
        if(this.props.validations) attachValidator.call(this)
    }
    componentDidMount(){
        let {name, validations} = this.props;
        if(validations) this.validator.register(name)
    }
    componentWillUnmount(){
        let {name, validations, errorActions, id} = this.props;
        if(validations) this.validator.deregister(name)
        //clear backend error
        errorActions.clearFieldError({id})
    }
    onChange(e){
        let {name, id, onChange, validations, errorActions} = this.props;
        let val = e.target.value.trim() === '' ? null : e.target.value
        if(validations) this.validator.validate(name, val)
        //clear backend error
        errorActions.clearFieldError({id})
        if(onChange) onChange(e);
    }
    render(){
        const { name, id,  label, value, onChange,  extraSelectClasses, intl, srOnly, extraContainerClasses, extraLabelClasses, selectOptions, setLabelBefore, customIconComponent, errors, vFields, vState, errorActions, validationActions, ownVState, required, ...rest } = this.props;
        const error = errors && errors.find(e => e.split('.')[0] === name );
        let messages = [];
        //backend error
        if(error) messages.push(error)
        //frontend validation errors
        if(ownVState && ownVState.messages) messages = messages.concat(ownVState.messages)
        // if type is radio or checkbox, please ensure they each have a unique ID
        return (
                <div key={id} className={classNames("md-form md-browser-default-form browser-default-select", {"has-sr-only": srOnly}, extraContainerClasses)}>
                    {setLabelBefore === true ? <label className={classNames("browser-default-label label-first", {"sr-only": srOnly}, extraLabelClasses)} htmlFor={id || name}>
                        {<span><span>{label}</span><span>{required && (typeof label !== 'undefined') &&"*"}</span></span>}
                    </label> : null}
                    <select
                        name={name}
                        id={id || name}
                        className={classNames("browser-default custom-select", extraSelectClasses)}
                        onChange={this.onChange}
                        value={value || ''}
                        aria-required={!!required}
                        {...rest}
                    >
                        {Object.keys(selectOptions).map((index) => {
                            return (
                                <option key={selectOptions[index].value} value={selectOptions[index].value}>
                                    {selectOptions[index].msgId ? intl.formatMessage({ id: selectOptions[index].msgId }) : selectOptions[index].label}
                                </option>
                            )
                        })}
                    </select>
                    {!setLabelBefore ? <label className={classNames("browser-default-label label-last", {"sr-only": srOnly}, extraLabelClasses)} htmlFor={id || name}>
                        {<span><span>{label}</span><span>{required&&"*"}</span></span>}
                    </label> : null}
                    <ValidationErrors name={name} messages={messages} values={{label}} intl={intl}/>
                </div>
        )
    }
}

function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState:state.validation.vState[ownprops.name]
        }: {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectFieldIntl));