/**
 * This file for text declarations as a placeholder until a proper implementation of action messages
 */

/**
 * PAGE TITLES
 */
export const TITLE_PAGE_APP = 'IPN API';
export const TITLE_PAGE_APP_WITH_DIVIDER = 'IPN API | ';
export const TITLE_PAGE_DASHBOARD = 'IPN API | Dashboard';
export const TITLE_PAGE_DASHBOARD_CUSTOMER = 'IPN API | CustomerFilter Dashboard';
export const TITLE_PAGE_IDENTITY_CREATE = 'IPN API | Create Identity';
export const TITLE_PAGE_IDENTITY_CREATE_RECEIPT = 'IPN API | Identity Created';
export const TITLE_PAGE_IDENTITY_FIND= 'IPN API | Find Identity';
export const TITLE_PAGE_IDENTITY_MANAGE_ACCOUNT= 'IPN API | Manage Accounts';

export const TITLE_PAGE_PAYMENT = 'IPN API | Make a Payment';
export const TITLE_PAGE_PAYMENT_RECEIPT = 'IPN API | Payment Receipt';
export const TITLE_PAGE_PAYMENT_FIND = 'IPN API | Find Payment';
export const TITLE_PAGE_PAYMENT_HISTORY = 'IPN API | Find Payment';
export const TITLE_PAGE_SCHEDULE_PAYMENT = 'IPN API | Schedule a Payment';
export const TITLE_PAGE_SCHEDULE_FIND = 'IPN API | Find a Schedule';
export const TITLE_PAGE_SCHEDULE_LIST = 'IPN API | My Schedules';
export const TITLE_PAGE_MERCHANTS_CREATE = 'IPN API | Create Merchant';
export const TITLE_PAGE_MERCHANTS_EDIT = 'IPN API | Edit Merchant';
export const TITLE_PAGE_MERCHANTS_PAYMENTUS = 'IPN API | Merchants (Paymentus)';
export const TITLE_PAGE_MERCHANTS_RPPS = 'IPN API | Merchants (RPPS)';
export const TITLE_PAGE_EBILL_HISTORY = 'IPN API | eBill History';

export const TITLE_PAGE_WALLET = 'IPN API | Wallet';

/**
 * HEADER BAR TITLES
 */
export const TITLE_HEADER_DASHBOARD = 'Dashboard';
export const TITLE_HEADER_DASHBOARD_CUSTOMER = 'CustomerFilter Dashboard';
export const TITLE_HEADER_IDENTITY_CREATE = 'Create Identity';
export const TITLE_HEADER_IDENTITY_CREATE_RECEIPT= 'Identity Created';
export const TITLE_HEADER_IDENTITY_FIND= 'Find Identity';
export const TITLE_HEADER_IDENTITY_MANAGE_ACCOUNT= 'Manage Accounts';
export const TITLE_HEADER_PAYMENT = 'Make a Payment';
export const TITLE_HEADER_PAYMENT_FIND = 'Find Payment';
export const TITLE_HEADER_PAYMENT_HISTORY = 'Payment History';
export const TITLE_HEADER_SCHEDULE_PAYMENT = 'Schedule a Payment';
export const TITLE_HEADER_SCHEDULE_FIND = 'Find a Schedule';
export const TITLE_HEADER_SCHEDULE_LIST = 'My Schedules';
export const TITLE_HEADER_MERCHANTS_CREATE = 'Create Merchant';
export const TITLE_HEADER_MERCHANTS_EDIT = 'Edit Merchant';
export const TITLE_HEADER_MERCHANTS_PAYMENTUS = 'Merchants (Paymentus)';
export const TITLE_HEADER_MERCHANTS_RPPS = 'Merchants (RPPS)';
export const TITLE_HEADER_WALLET = 'WalletPayAnyone';
export const TITLE_HEADER_EBILL_HISTORY = 'eBill History';

/**
 * MAIN SIDE LEFT NAV
 */

export const LABEL_SIDENAV_DASHBOARD = 'Dashboard';
export const LABEL_SIDENAV_DASHBOARD_CUSTOMER = 'CustomerFilter Dashboard';
export const LABEL_SIDENAV_MERCHANT= 'Merchant Management';
    export const LABEL_SIDENAV_MERCHANT_CREATE= 'Create Merchant';
    export const LABEL_SIDENAV_MERCHANT_PAYMENTUS= 'Paymentus';
    export const LABEL_SIDENAV_MERCHANT_STAGING= 'Staging';
    export const LABEL_SIDENAV_MERCHANT_RPPS= 'RPPS';
    export const LABEL_SIDENAV_MERCHANT_PUBLISHED= 'Published';
export const LABEL_SIDENAV_IDENTITY= 'Identity Management';
    export const LABEL_SIDENAV_IDENTITY_CREATE_SINGLE= 'Create Identity (Single)';
    export const LABEL_SIDENAV_IDENTITY_CREATE_MULTI= 'Create Identity (Multi)';
    export const LABEL_SIDENAV_IDENTITY_FIND= 'Find an Identity';
    export const LABEL_SIDENAV_IDENTITY_MANAGE_ACCOUNT= 'Manage Accounts';
export const LABEL_SIDENAV_PAYMENT= 'Payment';
    export const LABEL_SIDENAV_PAYMENT_MAKE_PAYMENT_SINGLE= 'Make a Payment (Single)';
    export const LABEL_SIDENAV_PAYMENT_MAKE_PAYMENT_MULTI= 'Make a Payment (Multi)';
    export const LABEL_SIDENAV_PAYMENT_MAKE_PAYMENT_ULTIMATE= 'Make a Payment (Ultimate)';
    export const LABEL_SIDENAV_PAYMENT_HISTORY= 'Payment History';
export const LABEL_SIDENAV_SCHEDULE= 'Schedule';
    export const LABEL_SIDENAV_SCHEDULE_PAYMENT= 'Schedule a Payment';
    export const LABEL_SIDENAV_SCHEDULE_FIND= 'Find a Schedule';
    export const LABEL_SIDENAV_SCHEDULE_LIST= 'My Schedules';
export const LABEL_SIDENAV_EBILL= 'eBill Management';
    export const LABEL_SIDENAV_EBILL_POST= 'Post an eBill';
    export const LABEL_SIDENAV_EBILL_HISTORY= 'eBill Hisotry';
export const LABEL_SIDENAV_WALLET= 'WalletPayAnyone';
export const LABEL_SIDENAV_TOOLS= 'Tools';
export const LABEL_SIDENAV_TOOLS_FEE_CALCULATOR= 'Fee Calculator';


/**
 * SECTION TITLES (usually used in dark gray bars)
 */
export const TITLE_SECTION_IDENTITY_INFORMATION= 'Identity Information';
export const TITLE_SECTION_IDENTITY_SEARCH= 'Search Identities';
export const TITLE_SECTION_NEW_IDENTITY_INFORMATION= 'New Identity Information';
export const TITLE_SECTION_SEARCH_MERCHANTS = 'Search Merchants';
export const TITLE_SECTION_SEARCH_CUSTOMER = 'Search Customers';
export const TITLE_SECTION_ADD_ACCOUNT = 'Add Account from Selected Merchants';

export const TITLE_SECTION_ACCOUNTS = 'Accounts';
export const TITLE_SECTION_ACCOUNT_INFORMATION = 'Account Information';
export const TITLE_SECTION_SCHEDULE_INFORMATION = 'Schedule Information';
export const TITLE_SECTION_SCHEDULE_SEARCH = 'Search Schedules';
export const TITLE_SECTION_PAYMENT_SEARCH = 'Search Payments';
export const TITLE_SECTION_EBILL_SEARCH = 'Search eBills';
export const TITLE_SECTION_PAYMENT_METHOD_INFORMATION = 'Payment Method Information';
export const TITLE_SECTION_CONTACT_INFORMATION = 'Contact Information';
export const TITLE_SECTION_PAYMENT_INFORMATION = 'Payment Information';
export const TITLE_SECTION_REVIEW_PAYMENT = 'Review Payment';
export const TITLE_SECTION_REVIEW_ACCOUNT_CHANGES = 'Review Account Changes';
export const TITLE_SECTION_REVIEW_IDENTITY_INFORMATION = 'Review Identity Information';
export const TITLE_SECTION_REVIEW_SCHEDULE = 'Review Schedule';
export const TITLE_SECTION_REVIEW_SCHEDULE_CHANGES = 'Review Schedule Changes';
export const TITLE_SECTION_MERCHANTS = 'Merchants';
export const TITLE_SECTION_MERCHANTS_SEARCH = 'Search Merchants';
export const TITLE_SECTION_WALLET = 'WalletPayAnyone';



/**
 * SUBSECTION TITLES (usually used in light grey bars)
 */
export const TITLE_SUB_SECTION_IDENTITY = 'identity';
export const TITLE_SUB_SECTION_SEARCH_RESULTS = 'Search Results';
export const TITLE_SUB_SECTION_MERCHANT_SEARCH_RESULTS = 'Merchant Search Results';
export const TITLE_SUB_SECTION_SELECT_MERCHANT = 'Select a Merchant';
export const TITLE_SUB_SECTION_SELECT_ACCOUNT = 'Select an Account';
export const TITLE_SUB_SECTION_PAYMENT_LIST = 'Payment List';
export const TITLE_SUB_SECTION_PAYMENT_COMPONENTS = 'Payment Components';
export const TITLE_SUB_SECTION_SCHEDULE_LIST = 'Schedule List';
export const TITLE_SUB_SECTION_SCHEDULE_COMPONENTS = 'Schedule Components';
export const TITLE_SUB_SECTION_SCHEDULE_FORECAST = 'Schedule Forecast';
export const TITLE_SUB_SECTION_SCHEDULE_CURRENT = 'Current Schedule';
export const TITLE_SUB_SECTION_SCHEDULE_NEW = 'New Schedule';
export const TITLE_SUB_SECTION_ACCOUNT_LIST = 'Account List';
export const TITLE_SUB_SECTION_ACCOUNT_COMPONENTS = 'Account Components';
export const TITLE_SUB_SECTION_PAYMENT_METHOD_INFORMATION="Payment Method Information";



/**
 * BUTTONS
 */

export const LABEL_BUTTON_LOGIN = 'PayAnyoneLanding';
export const LABEL_BUTTON_REGISTER = 'Register';
export const LABEL_BUTTON_REGISTER_NOW = 'Register Now';
export const LABEL_BUTTON_CONTINUE = 'Continue';
export const LABEL_BUTTON_START_OVER = 'Start Over';
export const LABEL_BUTTON_CANCEL = 'Cancel';
export const LABEL_BUTTON_CLOSE = 'Close';
export const LABEL_BUTTON_SEARCH = 'Search';
export const LABEL_BUTTON_SAVE = 'Save';
export const LABEL_BUTTON_SAVE_CHANGES = 'Save Changes';
export const LABEL_BUTTON_APPLY = 'Apply';
export const LABEL_BUTTON_APPLY_CHANGE = 'Apply Change';
export const LABEL_BUTTON_APPLY_CHANGES = 'Apply Changes';
export const LABEL_BUTTON_VIEW_MERCHANT_DETAILS = 'View Merchant Details';
export const LABEL_BUTTON_VALIDATE_ADD_ACCOUNT = 'Validate and Add Account';
export const LABEL_BUTTON_VALIDATE_ADD_ACCOUNTS = 'Validate and Add All Accounts';
export const LABEL_BUTTON_DESLECT_MERCHANT = 'Deselect Merchant';
export const LABEL_BUTTON_ADD_ANOTHER = 'Add another';
export const LABEL_BUTTON_ADD_ANOTHER_ACCOUNT = 'Add another account from';
export const LABEL_BUTTON_ADD_ACCOUNT = 'Add Account';
export const LABEL_BUTTON_ADD_ACCOUNT_NEW_MERCHANT = 'Add Account from a New Merchant';
export const LABEL_BUTTON_ADD_MERCHANT = 'Add Merchant';
export const LABEL_BUTTON_REMOVE_ACCOUNT = 'Remove Account';
export const LABEL_BUTTON_REMOVE_MERCHANT = 'Remove Merchant';
export const LABEL_BUTTON_VIEW_IDENTITY = 'View Identity';
export const LABEL_BUTTON_VIEW_MERCHANT = 'View Merchant';
export const LABEL_BUTTON_EDIT_MERCHANT = 'Edit Merchant';
export const LABEL_BUTTON_VIEW_PAYMENTS = 'View Payments';
export const LABEL_BUTTON_VIEW_SCHEDULES = 'View Schedules';
export const LABEL_BUTTON_VIEW_BILLS = 'View Bills';
export const LABEL_BUTTON_CREATE_SCHEDULE = 'Create Schedule';
export const LABEL_BUTTON_PRINT = 'Print This Page';
export const LABEL_BUTTON_BACK_TO_SCHEDULES = 'Back to Schedules';
export const LABEL_BUTTON_CREATE_IDENTITY = 'Create Identity';
export const LABEL_BUTTON_CREATE_ANOTHER_IDENTITY = 'Create Another Identity';
export const LABEL_BUTTON_BACK_TO_IDENTITIES = 'Back to Identities';
export const LABEL_BUTTON_MAKE_A_PAYMENT = 'Make a Payment';
export const LABEL_BUTTON_MAKE_ANOTHER_PAYMENT = 'Make Another Payment';
export const LABEL_BUTTON_BACK_TO_PAYMENTS = 'Back to Payments';
export const LABEL_BUTTON_SCHEDULE_A_PAYMENT = 'Schedule a Payment';
export const LABEL_BUTTON_SCHEDULE_ANOTHER_PAYMENT = 'Schedule Another Payment';

export const LABEL_BUTTON_CREATE_ANOTHER_MERCHANT = 'Create Another Merchant';
export const LABEL_BUTTON_MANAGE_ANOTHER_MERCHANT = 'Manage Another Merchant';
export const LABEL_BUTTON_BACK_TO_MERCHANTS = 'Back to Merchants';

export const LABEL_BUTTON_ADD_PAYMENT_TYPE = 'Add Payment TYpe';
export const LABEL_BUTTON_ADD_ANOTHER_PAYMENT_TYPE = 'Add Another Payment Type';
export const LABEL_BUTTON_ADD_ADDRESS = 'Add Address';
export const LABEL_BUTTON_ADD_ANOTHER_ADDRESS = 'Add Another Address';
export const LABEL_BUTTON_ADD_MASK = 'Add Mask';
export const LABEL_BUTTON_ADD_ANOTHER_MASK = 'Add Another Mask';
export const LABEL_BUTTON_ADD_AKA = 'Add AKA';
export const LABEL_BUTTON_ADD_ANOTHER_AKA = 'Add Another AKA';
export const LABEL_BUTTON_ADD_CONTACT = 'Add Contact';
export const LABEL_BUTTON_ADD_ANOTHER_CONTACT = 'Add Another Contact';
export const LABEL_BUTTON_ADD_ANOTHER_PHONE = 'Add Another Phone Number';
export const LABEL_BUTTON_ADD_SERVICE_AREA = 'Add Service Area';
export const LABEL_BUTTON_ADD_ANOTHER_SERVICE_AREA = 'Add Another Service Area';

export const LABEL_BUTTON_EDIT_ADDRESS = 'Edit Address';
export const LABEL_BUTTON_DELETE_ADDRESS = 'Delete Address';
export const LABEL_BUTTON_EDIT_MASK = 'Edit Mask';
export const LABEL_BUTTON_DELETE_MASK = 'Delete Mask';
export const LABEL_BUTTON_EDIT_AKA = 'Edit AKA';
export const LABEL_BUTTON_DELETE_AKA = 'Delete AKA';
export const LABEL_BUTTON_EDIT_CONTACT = 'Edit Contact';
export const LABEL_BUTTON_DELETE_CONTACT = 'Delete Contact';
export const LABEL_BUTTON_EDIT_SERVICE_AREA = 'Edit Service Area';
export const LABEL_BUTTON_DELETE_SERVICE_AREA = 'Delete Service Area';

/**
 * GENERIC or...trying to be
 */

export const LABEL_SOURCE = 'Source';
export const LABEL_MERCHANT = 'Merchant';
export const LABEL_PAYMENT_TYPE = 'Payment Type';
export const LABEL_ACCOUNT_NUMBER = 'Account Number';
export const LABEL_AUTH_TOKEN1 = 'ZIP Code';
export const LABEL_AUTH_TOKEN2 = 'Last 4 SSN';
export const LABEL_AUTH_TOKEN3 = 'House #';
export const LABEL_ACCOUNT_CHANGE = 'Account Change';

export const LABEL_EMAIL = 'Email';
export const LABEL_NAME = 'Name';
export const LABEL_FIRST_NAME = 'First Name';
export const LABEL_LAST_NAME = 'Last Name';
export const LABEL_DAY_PHONE = 'Day Phone';
export const LABEL_EXT = 'Ext.';
export const LABEL_LANGUAGE = 'Language';
export const LABEL_ADDRESS = 'Address';
export const LABEL_SUSPENDED = 'Suspended';
export const LABEL_ACTIONS = 'Actions';

export const LABEL_AMOUNT_DUE = 'Amount Due';
export const LABEL_DUE_DATE = 'Due Date';
export const LABEL_PAYMENT_AMOUNT = 'Payment Amount';
export const LABEL_PAYMENT_DATE = 'Payment Date';

export const LABEL_VIEW_IDENTITY = 'View Identity';
export const LABEL_VIEW_MERCHANT = 'View Merchant';
export const LABEL_VIEW_PAYMENTS = 'View Payments';
export const LABEL_VIEW_SCHEDULES = 'View Schedules';
export const LABEL_VIEW_BILLS = 'View Bills';


export const LABEL_MANAGE_IDENTITY = 'Manage Identity';
export const LABEL_MANAGE_MERCHANT = 'Manage Merchant';
export const LABEL_MANAGE_ACCOUNT = 'Manage Account';
export const LABEL_MANAGE_PAYMENTS = 'Manage Payments';
export const LABEL_MANAGE_SCHEDULES = 'Manage Schedules';
export const LABEL_MANAGE_BILLS = 'Manage Bills';

export const LABEL_SEARCH_CUSTOMERS_BY = 'Search Customers By';
export const LABEL_SEARCH_MERCHANT_BY = 'Search Merchants By';

export const LABEL_YES = 'Yes';
export const LABEL_NO = 'No';

export const LABEL_DIVIDER = '|';
export const LABEL_POWERED_BY = 'Powered By';

/**
 * VIEW and EDIT MERCHANT / RPPS INFO
 */
export const TITLE_MODAL_VIEW_MERCHANT_INFO = 'View Merchant Details';
export const TITLE_MODAL_VIEW_MERCHANT_INFO_RPPS = 'View Merchant RPPS Details';
export const TITLE_MODAL_EDIT_MERCHANT_INFO = 'Edit Merchant Details';
export const TITLE_MODAL_EDIT_MERCHANT_INFO_RPPS = 'Edit Merchant RPPS Details';
export const TITLE_MODAL_ADD_MERCHANT_ADDRESS = 'Add Merchant Address';
export const TITLE_MODAL_ADD_MERCHANT_MASK = 'Add Merchant Mask';
export const TITLE_MODAL_ADD_MERCHANT_AKA = 'Add Merchant AKA';
export const TITLE_MODAL_ADD_MERCHANT_CONTACT = 'Add Merchant Contact';
export const TITLE_MODAL_ADD_MERCHANT_SERVICE_AREA = 'Add Merchant Service Area';
export const TITLE_MODAL_EDIT_MERCHANT_ADDRESS = 'Edit Merchant Address';
export const TITLE_MODAL_EDIT_MERCHANT_MASK = 'Edit Merchant Mask';
export const TITLE_MODAL_EDIT_MERCHANT_AKA = 'Edit Merchant AKA';
export const TITLE_MODAL_EDIT_MERCHANT_CONTACT = 'Edit Merchant Contact';
export const TITLE_MODAL_EDIT_MERCHANT_SERVICE_AREA = 'Edit Merchant Service Area';
export const TITLE_MODAL_DELETE_MERCHANT_ADDRESS = 'Delete Merchant Address';
export const TITLE_MODAL_DELETE_MERCHANT_MASK = 'Delete Merchant Mask';
export const TITLE_MODAL_DELETE_MERCHANT_AKA = 'Delete Merchant AKA';
export const TITLE_MODAL_DELETE_MERCHANT_CONTACT = 'Delete Merchant Contact';
export const TITLE_MODAL_DELETE_MERCHANT_SERVICE_AREA = 'Delete Merchant Service Area';
export const LABEL_MERCHANT_TAB_OVERVIEW = 'Overview';
export const LABEL_MERCHANT_TAB_ADDRESSES = 'Addresses';
export const LABEL_MERCHANT_TAB_MASKS = 'Masks';
export const LABEL_MERCHANT_TAB_AKAS = 'AKAs';
export const LABEL_MERCHANT_TAB_CONTACTS = 'Contacts';
export const LABEL_MERCHANT_TAB_SERVICE_AREA = 'Service Area';
export const TITLE_SUB_SECTION_MERCHANT_DETAILS = 'Merchant Details';
export const TITLE_SUB_SECTION_MERCHANT_DETAILS_CONTINUED = 'Merchant Details Continued';
export const TITLE_SUB_SECTION_MERCHANT_ADDITIONAL_INFORMATION = 'Additional Information';
export const TITLE_SUB_SECTION_MERCHANT_TRANSACTION_PREFERENCES = 'Transaction Preferences';
export const TITLE_SUB_SECTION_MERCHANT_PRENOTE_PREFERENCES = 'Prenote Preferences';
export const TITLE_SUB_SECTION_MERCHANT_RETURN_PREFERENCES = 'Return Preferences';
export const TITLE_SUB_SECTION_MERCHANT_TOTALS= 'Totals';
export const TITLE_SUB_SECTION_MERCHANT_PAYMENT_METHODS = 'Payment Methods';
export const TITLE_SUB_SECTION_MERCHANT_BILLER_NOTE = 'Biller Note';
export const TITLE_SUB_SECTION_MERCHANT_ADDRESSES = 'Addresses';
export const TITLE_SUB_SECTION_MERCHANT_MASKS = 'Masks';
export const TITLE_SUB_SECTION_MERCHANT_MASKS_DESCRIPTIONS = 'Mask Descriptions';
export const TITLE_SUB_SECTION_MERCHANT_AKAS = 'Akas';
export const TITLE_SUB_SECTION_MERCHANT_CONTACTS = 'Contacts';
export const TITLE_SUB_SECTION_MERCHANT_SERVICE_AREA = 'Service Area';
export const LABEL_MERCHANT_LIVE_DATE = 'Live Date';
export const LABEL_MERCHANT_RECORD_DATE = 'Record Effective Date';
export const LABEL_MERCHANT_ID = 'Merchant ID';
export const LABEL_MERCHANT_SOURCE = 'Merchant Source';
export const LABEL_MERCHANT_NAME = 'Merchant Name';
export const LABEL_MERCHANT_PREV_MERCHANT_NAME = 'Previous Merchant Name';
export const LABEL_MERCHANT_TLA = 'Merchant TLA';
export const LABEL_MERCHANT_TYPE = 'Merchant Type';
export const LABEL_MERCHANT_INDUSTRY = 'Merchant Industry';
export const LABEL_MERCHANT_LINE_OF_BUSINESS = 'Line of Business';
export const LABEL_MERCHANT_CURRENCY = 'Currency';
export const LABEL_MERCHANT_COUNTRY_CODE = 'Country Code';
export const LABEL_MERCHANT_STATE_PROVINCE = 'State/Province Code';
export const LABEL_MERCHANT_TERRITORY_CODE = 'Territory Code';
export const LABEL_MERCHANT_TRANSIT_ROUTING_NUMBER = 'Transit Routing Number/ABA';
export const LABEL_MERCHANT_FILE_FORMAT = 'File Format';
export const LABEL_MERCHANT_CHECK_DIGIT = 'Check Digit Routine?';
export const LABEL_MERCHANT_SAME_DAY = 'Same Day Payment Deadline Cycle';
export const LABEL_MERCHANT_AVG_RESPONSE_TIME = 'Average Response Time (Hours)';
export const LABEL_MERCHANT_ACCEPT_PRENOTES = 'Accept Prenotes?';
export const LABEL_MERCHANT_ACCEPT_GURANTEED_PAYMENTS = 'Accept Guranteed Payments?';
export const LABEL_MERCHANT_ACCEPT_EXCEPTION_PAYMENTS = 'Accept Exception Payments?';
export const LABEL_MERCHANT_ACCEPT_DMP_PAYMENTS = 'Accepts DMP Payments Only?';
export const LABEL_MERCHANT_REQUIRE_ADDENDA_REVERSALS = 'Require Addenda with Reversals?';
export const LABEL_MERCHANT_PRENOTE_DMP = 'DMP';
export const LABEL_MERCHANT_PRENOTE_CDP = 'CDP';
export const LABEL_MERCHANT_PRENOTE_CDV = 'CDV';
export const LABEL_MERCHANT_PRENOTE_CDD = 'CDD';
export const LABEL_MERCHANT_PRENOTE_CDF = 'CDF';
export const LABEL_MERCHANT_PRENOTE_CDN = 'CDN';
export const LABEL_MERCHANT_PRENOTE_FBD = 'FBD';
export const LABEL_MERCHANT_PRENOTE_FBC = 'FBC';
export const LABEL_MERCHANT_RETURN_CDR = 'CDR';
export const LABEL_MERCHANT_RETURN_CDT = 'CDT';
export const LABEL_MERCHANT_RETURN_CDA = 'CDA';
export const LABEL_MERCHANT_RETURN_CDV = 'CDV';
export const LABEL_MERCHANT_RETURN_CDC = 'CDC';
export const LABEL_MERCHANT_RETURN_CDM = 'CDM';
export const LABEL_MERCHANT_ADDRESSES = 'Addresses';
export const LABEL_MERCHANT_MASKS = 'Masks';
export const LABEL_MERCHANT_AKAS = 'AKAs';
export const LABEL_MERCHANT_CONTACTS = 'Contacts';
export const LABEL_MERCHANT_PAYMENT_METHOD_CODE = 'Code';
export const LABEL_MERCHANT_ADDRESS_EFFECTIVE_DATE = 'Effective Date';
export const LABEL_MERCHANT_ADDRESS_TYPE = 'Type';
export const LABEL_MERCHANT_ADDRESS_CITY = 'City';
export const LABEL_MERCHANT_ADDRESS_STATE = 'State / Province / Region';
export const LABEL_MERCHANT_ADDRESS_COUNTRY = 'Country';
export const LABEL_MERCHANT_ADDRESS_ZIP_CODE = 'ZIP Code';
export const LABEL_MERCHANT_ADDRESS = 'Address';

export const LABEL_MERCHANT_AKA_KEY = 'Key';
export const LABEL_MERCHANT_AKA_EFFECTIVE_DATE = 'Effective Date';
export const LABEL_MERCHANT_AKA_NAME = 'Name';
export const LABEL_MERCHANT_MASK_KEY = 'Key';
export const LABEL_MERCHANT_MASK_EFFECTIVE_DATE = 'Effective Date';
export const LABEL_MERCHANT_MASK_DESCRIPTION = 'Mask Description';
export const LABEL_MERCHANT_MASK_LENGTH = 'Mask Length';
export const LABEL_MERCHANT_MASK_FORMAT = 'Mask Format';
export const LABEL_MERCHANT_MASK_EXCEPTION = 'Exception Mask';

export const LABEL_MERCHANT_CONTACT_EFFECTIVE_DATE = 'Effective Date';
export const LABEL_MERCHANT_CONTACT_INFO = 'Contact Info';
export const LABEL_MERCHANT_CONTACT_ADDRESS = 'Address';
export const LABEL_MERCHANT_CONTACT_NUMBERS = 'Phone Numbers';

export const LABEL_MERCHANT_SERVICE_AREA_COUNTRY = 'Country';
export const LABEL_MERCHANT_SERVICE_AREA_STATE = 'State';
export const LABEL_MERCHANT_SERVICE_AREA_CITY = 'City';
export const LABEL_MERCHANT_SERVICE_AREA_ZIPCODE = 'ZIP Code';



/**
 * SCHEDULES
 */

export const LABEL_SCHEDULE_NUM_ACTIVE = '# of Active Schedules';
export const LABEL_SCHEDULE_ACTIVE = 'Active Schedule';
export const LABEL_SCHEDULE_FREQUENCY = 'Frequency';
export const LABEL_SCHEDULE_DAY = 'Payment Day ';
export const LABEL_SCHEDULE_START_DATE = 'Start Date';
export const LABEL_SCHEDULE_END_DATE = 'End Date';
export const LABEL_SCHEDULE_AMOUNT = 'Amount';
export const LABEL_SCHEDULE_NEXT_PAYMENT = 'Next Payment Date';
export const LABEL_SCHEDULE_PAYMENT_METHOD = 'Payment Method';

export const LABEL_SCHEDULE_BIMONTHLY = 'Bi-Monthly';
export const LABEL_SCHEDULE_BIWEEKLY = 'Bi-Weekly';
export const LABEL_SCHEDULE_LIABILITY = 'On the due date';
export const LABEL_SCHEDULE_BEFORE_DUE_DATE_BILL_AMOUNT = '';
export const LABEL_SCHEDULE_MONTHLY = 'Monthly (Fixed Amount)';
export const LABEL_SCHEDULE_SEMIMONTHLY = 'Semi-Monthly';
export const LABEL_SCHEDULE_WEEKLY = 'Weekly';
export const LABEL_SCHEDULE_MONTHLY_BILL_AMOUNT = 'Monthly (Bill Amount)';
export const LABEL_SCHEDULE_INS_QUARTERLY = 'Quarterly';
export const LABEL_SCHEDULE_INS_SEMI_ANNUAl = 'Semi-annually';
export const LABEL_SCHEDULE_INS_ANNUAL = 'Annually';


export const TEXT_SCHEDULE_BIMONTHLY = "Please note that the first Payment will be scheduled to be processed in 1-2 months, " +
    "if schedule day is greater than the last day of the schedule month then the last day of the month is used for the schedule day.";
export const TEXT_SCHEDULE_BIWEEKLY="Please note that the first Payment will be scheduled to be processed in 1-2 weeks.";
    export const TEXT_SCHEDULE_LIABILITY="Please note that a Payment for the amount on the bill will be processed each month on the due date up to the maximum amount.";
    export const TEXT_SCHEDULE_BEFORE_DUE_DATE_BILL_AMOUNT="Please note that a Payment for the amount on the bill will be processed each month prior to the due date up to the maximum amount.";
    export const TEXT_SCHEDULE_MONTHLY="Please note that a Payment scheduled for the current calendar day will be processed next month, \
    if schedule day is greater than the last day of the schedule month then the last day of the month \
    is used for the schedule day.";
    export const TEXT_SCHEDULE_SEMIMONTHLY="Please note that a Payment scheduled for the current calendar day will be processed next month, \
    if schedule day is greater than the last day of the schedule month then the last day of the month \
    is used for the schedule day.";
    export const TEXT_SCHEDULE_WEEKLY="Please note that a Payment scheduled for the current calendar day will be processed next week.";
    export const TEXT_SCHEDULE_MONTHLY_BILL_AMOUNT="Please note that a Payment for the amount on the bill will be processed each month on \
	the specified schedule day, if schedule day is greater than the last day of the schedule month then the last day of the month is used for the schedule day";
export const TEXT_SCHEDULE_INS_QUARTERLY="Please note that a Payment for the amount on the bill will be processed each quarter on the specified schedule day, \
    if schedule day is greater than the last day of the schedule month then the last day of the month is used for the schedule day";
export const TEXT_SCHEDULE_INS_SEMI_ANNUAL="Please note that a Payment for the amount on the bill will be processed semi-annually on the specified schedule day, \
    if schedule day is greater than the last day of the schedule month then the last day of the month is used for the schedule day";
export const TEXT_SCHEDULE_INS_ANNUAL="Please note that a Payment for the amount on the bill will be processed annually on the specified schedule day, \
    if schedule day is greater than the last day of the schedule month then the last day of the month is used for the schedule day";
/**
 * PAYMENT INFORMATION
 */


/**
 * REVIEW
 */

export const LABEL_REVIEW_PAYMENT_AMOUNT = 'Payment Amount';
export const LABEL_REVIEW_PAYMENT_FEE = 'Fee';
export const LABEL_REVIEW_TOTAL_PAYMENT_AMOUNT = 'Total Payment Amount';

/**
 * RECEIPT
 */
export const LABEL_RECEIPT_CONFIRMATION_NUMBER = 'Confirmation Number';
export const LABEL_RECEIPT_PAYMENT_DATE = 'Payment Date';
export const LABEL_RECEIPT_PAYMENT_LIST_DETAILS = 'Payment List Details';
export const LABEL_RECEIPT_PAYMENT_METHOD = 'Payment Method';
export const LABEL_RECEIPT_CARD_NUMBER = 'Card Number';
export const LABEL_RECEIPT_PAYMENT_AMOUNT = 'Payment Amount';
export const LABEL_RECEIPT_PAYMENT_FEE = 'Payment Fee';
export const LABEL_RECEIPT_TOTAL_AMOUNT_CHARGED = 'Total Amount Charged';
export const LABEL_RECEIPT_COMBINED_PAYMENT_AMOUNT = 'Combined Payment Amount';
export const LABEL_RECEIPT_COMBINED_PAYMENT_FEE = 'Combined Payment Fee';

/**
 * PAYMENT METHODS
 */
export const LABEL_PAYMENT_METHOD_VISA = 'Visa';
export const LABEL_PAYMENT_METHOD_VISA_DEBIT = 'Visa (Debit)';
export const LABEL_PAYMENT_METHOD_MC = 'MasterCard';
export const LABEL_PAYMENT_METHOD_MC_DEBIT = 'MasterCard (Debit)';
export const LABEL_PAYMENT_METHOD_AMEX = 'American Express';
export const LABEL_PAYMENT_METHOD_AMEX_DEBIT = 'American Express (Debit)';
export const LABEL_PAYMENT_METHOD_DISCOVER = 'Discover';
export const LABEL_PAYMENT_METHOD_DISCOVER_DEBIT = 'Discover (Debit)';
export const LABEL_PAYMENT_METHOD_ATM = 'ATM';
export const LABEL_PAYMENT_METHOD_PAYPAL = 'PayPal';
export const LABEL_PAYMENT_METHOD_PAYPAL_CREDIT = 'PayPal Credit';
export const LABEL_PAYMENT_METHOD_VENMO = 'PayPal Credit';
export const LABEL_PAYMENT_METHOD_AMAZON_PAY = 'Amazon Pay';
export const LABEL_PAYMENT_METHOD_GOOGLE_PAY = 'Google Pay';
export const LABEL_PAYMENT_METHOD_APPLE_PAY = 'Apple Pay';
export const LABEL_PAYMENT_METHOD_IONLINE = 'Interac Online';
export const LABEL_PAYMENT_METHOD_ECHECK = 'E-Check';
export const LABEL_PAYMENT_METHOD_CHQ = 'Checking';
export const LABEL_PAYMENT_METHOD_SAV = 'Savings';
export const LABEL_PAYMENT_METHOD_BUS_CHQ = 'Business Checking';
export const LABEL_PAYMENT_METHOD_BUS_SAV = 'Business Savings';
export const LABEL_PAYMENT_METHOD_STAR = 'STAR';
export const LABEL_PAYMENT_METHOD_NYCE = 'NYCE';
export const LABEL_PAYMENT_METHOD_PULSE = 'Pulse';
export const LABEL_PAYMENT_METHOD_ACCEL = 'Accel';

/**
 * ACCOUNT TYPES
 */
export const TYPE_ACCOUNT_TYPE_ACCOUNT = 'ACCOUNT';
export const TYPE_ACCOUNT_TYPE_PLAN = 'PLAN';
