import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {connect,} from "react-redux";
import {bindActionCreators} from "redux";
import Exception from "../../Exception";
import {MDBRow, MDBCol} from "mdbreact";
import { get as lodashGet } from 'lodash';
import * as invoiceActions from '../../../actions/invoice-action';
import * as invoiceApi from '../../../api/invoice-api';
import TableScreen from '../TableScreen/TableScreen';
import { pick as lodashPick, isEmpty as lodashIsEmpty, orderBy as lodashOrderBy, sumBy as lodashSumBy, isUndefined as lodashIsUndefined, some as lodashSome } from 'lodash';
import {getFormattedValue, interpolateTemplate, formatCurrency, getLabel} from '../../../utils/invoice-utils';
import ReactSelectAllGrouped from '../../ReactSelectAllGrouped';

class TableDropdown extends React.Component {

    constructor(props) {
        super();

        const { intl, invoiceLiterals, merchant } = props;
        const currencyDisplay = merchant.currencyDisplay;

        this.formatValue = getFormattedValue.bind({ intl, invoiceLiterals, currencyDisplay });
        this.formatLabel = getLabel.bind({ intl, invoiceLiterals });

        this.state = {
            selectedTable: null,
            data: [],
            tableKey: ''
        };
    }

    componentDidMount() {
        this.onInit();
    }

    getTableSelectionOptions = async () => {
        const { envProps, invoice, screenConfig } = this.props;
        const { apiUrlPrefix, apiToken } = envProps;
        const { table, tableDropdown } = screenConfig;

        if (!screenConfig) return [];

        const url = interpolateTemplate(tableDropdown.api, invoice);
        const conditionals = lodashGet(table, 'conditionals', []);
        const invoiceAccount = {
            ...(invoice.accountNumber ? { accountNumber: invoice.accountNumber } : {}),
            ...(invoice.planNumber ? { planNumber: invoice.planNumber } : {}),
            ...(invoice.recordType ? { recordType: invoice.recordType } : {}),
        }
        const res = await invoiceApi.getQuickFilterAggregate(url, conditionals, apiUrlPrefix, apiToken, invoiceAccount);

        if (lodashIsEmpty(res.parsedBody)) return [];

        const options = res.parsedBody.map(option => {
            const value = lodashPick(option, (tableDropdown.value && tableDropdown.value.length > 0) ? tableDropdown.value : option);

            const formattedValues = {};
            if (tableDropdown.format) {
                tableDropdown.format.forEach(field => {
                    formattedValues[field.field] = this.formatValue({
                        ...option,
                        ...(lodashGet(invoice, 'currencyCode') ? {currencyCode: invoice.currencyCode} : {})
                    }, field);
                });
            }

            const { total } = option;

            const text = tableDropdown.template
                ? interpolateTemplate(tableDropdown.template, formattedValues)
                : lodashGet(option, tableDropdown.field, option);

            return {
                text,
                value,
                ...(!lodashIsUndefined(total) ? { total } : {}),
            };
        });

        return lodashOrderBy(options, ['text']);
    }

    fetchInvoiceDetails = async (state, selectedTable) => {
        const { invoice, screenConfig, invoiceActions } = this.props;
        const { table } = screenConfig;

        invoiceActions.getInvoiceDetails({invoice, searchCriteria: selectedTable, conditionals: table.conditionals}, (invoiceDetails) => {
            this.setState({
                ...state,
                selectedTable,
                data: invoiceDetails,
            });
        });
    }

    onInit = async () => {
        const tableSelectionOptions = await this.getTableSelectionOptions();
        if (!lodashIsEmpty(tableSelectionOptions)) {
            const selectedTable = tableSelectionOptions[0].value;

            await this.fetchInvoiceDetails({
                loaded: true,
                tableSelectionOptions
            }, selectedTable);
        } else {
            this.setState({ loaded: true });
        }
    }

    onSelect = async (value) => {
        const { selectedTable } = this.state;

        if (lodashIsEmpty(value)) {
            this.setState({ selectedTable });
            return;
        }

        if (selectedTable === value[0]) return;

        await this.fetchInvoiceDetails({
            tableKey: JSON.stringify(value[0])
        }, value[0]);
    }

    getOptions = () => {
        const { selectedTable, tableSelectionOptions } = this.state;

        return [{
            msgId: "react-select.select-your-filters",
            options : (tableSelectionOptions || []).map(({ value, text }) => ({
                checked: value === selectedTable,
                disabled: false,
                value,
                text,
                label: text,
                msgId: text
            }))
          }];
    }

    hasTotal = () => lodashSome(this.state.tableSelectionOptions, option => !lodashIsUndefined(option.total));

    getTotal = () => {
        const { tableSelectionOptions } = this.state;

        return lodashSumBy(tableSelectionOptions, 'total');
    }

    render() {
        const { loaded, error, data, tableKey, selectedTable } = this.state;
        const { intl, invoice, invoiceLiterals, screenConfig, screen, merchant } = this.props;
        const { tableDropdown } = screenConfig;
        const optionsList = this.getOptions();

        if (!screenConfig) return null;

        if (!loaded) return null;
        if (error instanceof TypeError) return <Exception error={error} />

        if (optionsList[0].options.length === 0) {
            return (
                <MDBCol size={'12'} role="alert" className={'border mx-auto py-4 text-center'}><FormattedMessage id={'datatable.sEmptyTable'} /></MDBCol>
            );
        }

        const filters = optionsList[0].options.find(x => JSON.stringify(x.value) === JSON.stringify(selectedTable));

        return (
            <React.Fragment>
                <MDBRow className='p-0'>
                    <MDBCol size={'12'} md={'4'} key={"field-invoice-status"}>
                        <ReactSelectAllGrouped
                            field={"field-invoice-status"}
                            label={intl.formatMessage({id:tableDropdown.msgId})}
                            allowSelectAll={false}
                            onChange={(val) => this.onSelect([val.value])}
                            options={optionsList}
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            backspaceRemovesValue={false}
                            value={filters}
                            inputId="field-invoice-status-filter"
                        />
                    </MDBCol>
                    {this.hasTotal() &&
                        <MDBCol size={'12'} md={'4'} className={'pb-4 py-md-2'}>
                            <p className={'mb-0'}>
                                <strong><FormattedMessage id={'invoice.details.total.label'} /></strong>
                            </p>
                            {formatCurrency(intl, this.getTotal(), invoice.currencyCode, merchant.currencyDisplay)}
                        </MDBCol>
                    }
                </MDBRow>
                <TableScreen
                    caption={screen}
                    key={tableKey}
                    screen={screen}
                    screenConfig={screenConfig}
                    data={data}
                    invoice={invoice}
                    invoiceLiterals={invoiceLiterals}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        envProps: state.envProps,
        user: state.auth.user,
        merchant: state.config.sessionSelection.merchant
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TableDropdown));