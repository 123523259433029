import { MDBCol, MDBRow } from 'mdbreact';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from "react-router-dom";
import {getQueryParams} from "../../utils/utils";
import {routes} from "../../routes";
import storage from 'redux-persist/lib/storage';

class SessionTimeOut extends Component {
    constructor(props) {
        super(props);

        this.redirectURL = '/home';

        const query = getQueryParams(this.props.location.search);
        const {state} = query;

        storage.removeItem('persist:root');
        storage.removeItem('csr_token');
        storage.removeItem('tokenStatus');

        const routePaths = routes.map(({subRoutes}) => subRoutes.map(({path}) => {if(!path.includes(":") && !path.includes("?")){return path}}));
        const internalRoutes = routePaths.flat(1);
        const validInternalRoutes = internalRoutes.filter(val => val);

        if(state != undefined && validInternalRoutes.includes(state)){
            this.redirectURL= state;
        }
    }

    render() {
        return (
            <div className="session-align">
                <div className="card">
                    <div className="card-body p-0">
                        <MDBRow>
                            <MDBCol size="12" className="h1-align">
                                <FormattedMessage id='ups.timeout.message.1' />
                            </MDBCol>
                            <MDBCol size="12" className="timeoutalign">
                                <p>
                                    <FormattedMessage id='ups.timeout.message.2' />
                                    <br></br>
                                    <a role='link' href={this.redirectURL} className="text-blue">
                                        <FormattedMessage id='ups.timeout.message.3' />
                                    </a>
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SessionTimeOut);