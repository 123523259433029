import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as identityActions from "../../../actions/identity-action";
import * as validationActions from "../../../actions/validation-action";
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBCardBody, MDBBtn} from "mdbreact";
import {isPBTSupportedCountry} from '../../../utils/payByText-utils.js';
import {isPBTConfigEnabled} from "../../../utils/config-utils";
import MDBBtnWrapper from '../../MDBFix/MDBBtnWrapper';

class UPSPreferenceView extends Component {
    constructor(props) {
        super(props);
        let showPayByTextOption = false;
        if(isPBTSupportedCountry(props.auth.user?.selectedCountry || '', props.auth.user?.selectedPaymentType)){
            showPayByTextOption = true;
        }

        this.state = {
            showPayByTextOption
        };
    }

    componentDidUpdate(prevProps){
        const {auth, identity} = this.props; 
        const currCountry = auth.user?.selectedCountry || ''; 
        const selectedPaymentType = auth.user?.selectedPaymentType || '';
        const prevCountry = prevProps.auth.user?.selectedCountry || '';
       
        if(currCountry != prevCountry || selectedPaymentType != prevProps.auth.user?.selectedPaymentType || identity?.preferences?.payByTextSettings?.enabled != prevProps.identity?.preferences?.payByTextSettings?.enabled){
            if(isPBTSupportedCountry(currCountry, auth.user?.selectedPaymentType)){
                this.setState({
                    showPayByTextOption : true
                });
            }
            else {
                this.setState({
                    showPayByTextOption : false
                });
            }
        }
    }

    handleNotif = () => {
        const { history } = this.props;
        history.push("/ups/billing/profile/notifications");
    }

    handlePDF = () => {
        const { history } = this.props;
        history.push("/ups/billing/profile/pdf");
    }

    handlePBT = () => {
        const { history } = this.props;
        history.push("/ups/billing/profile/paybytext");
    }

    render() {
        const { auth, preferences, merchant, intl } = this.props;
        const { permissions = {} } = auth.user;
        const { showPayByTextOption } = this.state;
        const clientConfigPBTEnabled = isPBTConfigEnabled(merchant);
        const showPBTTile = !!clientConfigPBTEnabled && showPayByTextOption && ( permissions && permissions['otp_payment'] );
        const pdfinvoiceButtonModeType = preferences?.invoiceSettings?.pdf?.secure ? "ups.preferences.edit" : "ups.preferences.setup";
        const pdfinvoiceButtonLabel = preferences?.invoiceSettings?.pdf?.secure ? "ups.preferences.pdfinvoice.aria-label-edit" : "ups.preferences.pdfinvoice.aria-label-setup";
        const payByTextButtonModeType = preferences?.payByTextSettings?.enabled ? "ups.preferences.edit" : "ups.preferences.setup";
        const payByTextButtonLabel = preferences?.payByTextSettings?.enabled ? "ups.preferences.pbt.aria-label-edit" : "ups.preferences.pbt.aria-label-setup";
        return (
            <React.Fragment>
                <div className="ups-breadcrumb preferences">

                    <MDBRow>
                        <MDBCol size={"12"}>
                            <div className="mt-3 mb-3 display-md-form">
                                <span className="display-label"><FormattedMessage id="ups.preferences.notifications.name" /></span>
                                <span className="display-plaintext">{auth.user.firstName} {auth.user.lastName}</span>
                            </div>
                        </MDBCol>
                        <MDBCol size={"12"}>
                            <div className="mt-3 mb-3 display-md-form">
                                <span className="display-label"><FormattedMessage id="ups.preferences.notifications.userId" /></span>
                                <span className="display-plaintext">{auth.user.loginId}</span>
                            </div>
                        </MDBCol>

                        <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-6"}>
                            <MDBCard className={"preferences-widget-card"}>
                                <MDBCardTitle tag="h2">
                                        <FormattedMessage id="ups.preferences.notifications.header" />
                                </MDBCardTitle>
                                <MDBCardBody>
                                    <p> <FormattedMessage id="ups.preferences.overview.note" /></p>
                                </MDBCardBody>
                                <div className={"card-footer"}>
                                    <MDBBtnWrapper label={intl.formatMessage({id: 'ups.preferences.edit'})}  aria-label={intl.formatMessage({id: 'ups.preferences.notifications.button.aria.label'})} color={"text-link"} className={"btn-pref"}
                                        onClick={this.handleNotif}></MDBBtnWrapper>

                                </div>
                            </MDBCard>

                        </MDBCol>

                        <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-6"}>
                            <MDBCard className={"preferences-widget-card"}>
                                <MDBCardTitle tag="h2">
                                        <FormattedMessage id="ups.preferences.pdfinvoice.title" />
                                </MDBCardTitle>
                                <MDBCardBody>
                                    <p>
                                    <FormattedMessage id="ups.preferences.pdfinvoice.note" /></p>
                                </MDBCardBody>
                                <div className={"card-footer"}>
                                <MDBBtnWrapper label={intl.formatMessage({id: pdfinvoiceButtonModeType})}  aria-label={intl.formatMessage({id: pdfinvoiceButtonLabel})} color={"text-link"} className={"btn-pref"}
                                        onClick={this.handlePDF}></MDBBtnWrapper>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        
                        {showPBTTile && 
                            <MDBCol size={"12"} lg={"4"} md={"6"} className={"mb-4"}>
                                <MDBCard className={"preferences-widget-card"}>
                                    <MDBCardTitle tag="h2">
                                            <FormattedMessage id="ups.title.payByText" />
                                    </MDBCardTitle>
                                    <MDBCardBody>
                                        <p><FormattedMessage id="ups.preferences.pbt.note" /></p>
                                    </MDBCardBody>
                                    <div className={"card-footer"}>
                                    <MDBBtnWrapper label={intl.formatMessage({id: payByTextButtonModeType})}  aria-label={intl.formatMessage({id: payByTextButtonLabel})}
                                     color={"text-link"} className={"btn-pref"} onClick={this.handlePBT}> </MDBBtnWrapper>
                                    </div>
                                </MDBCard>
                            </MDBCol>
                        }
                    </MDBRow>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const {config, identity} = state;
    return {
        identity,
        merchants: state.auth.merchants,
        preferences: state.identity.preferences,
        auth: state.auth,
        allMerchants: state.config.merchants,
        userRole: state.auth.user.role,
        merchant: config?.sessionSelection?.merchant
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(UPSPreferenceView)));