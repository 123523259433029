/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 10/6/19 12:57 PM
 * Created by kxian
 *
 */

import produce from "immer"
import * as actionTypes from '../constants/action-types';

export default (state = { accounts: []}, action) =>
    produce(state, draft => {
    switch (action.type) {
        case actionTypes.SET_ACCOUNT: {
            draft.account = action.account;
            draft.accountIndex = action.accountIndex;
            return;
        }
        case actionTypes.CLEAR_ACCOUNT: {
            delete draft.account;
            delete draft.eligibility;
            delete draft.accountValid;
            delete draft.invoice;
            delete draft.accountIndex;
            return;
        }
        case actionTypes.CHANGE_ACCOUNT: {
            draft.accountValidated = false;
            return;
        }
        case actionTypes.GET_ACCOUNT_LIST_SUCCESS: {
            draft.accounts = action.data;  // #UPDATE
            return;
        }
        case actionTypes.REMOVE_ACCOUNT_SUCCESS: // #UPDATE
            draft.accounts = action.data;
            return;
        case actionTypes.GET_ACCOUNT_LIST_FAILURE: {
            draft.error = action.data;
            return;
        }
        case actionTypes.REMOVE_ACCOUNT_FAILURE: {
            draft.error = action.data;
            return;
        }
        case actionTypes.REMOVE_ACCOUNT_COMPLETE:
            delete draft.error;
            return; 
    }
});