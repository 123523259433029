const findColumn = (name, columns)=>columns?.find((column)=>column.field === name)

export function FilterFuncMultiSelect (filter, name, data, columns, intl, parseFilter, parseData) {
    let shouldShow = true
    let columnName = name?.column ?? name
    let filterName = name?.filterName ?? name
    if(filter?.[filterName]?.length > 0){
        let column = findColumn(columnName, columns)
        let rowVal = column?.serialize(data) ?? parseData?.(data) ?? data[name]
        let match = false
        if(filter[filterName][0].value !== '*'){
            filter[filterName].forEach((option)=>{
                let val = option.value
                match |= ((parseFilter?.(val) ?? intl?.formatMessage({id: val}) ?? val) === rowVal)
            })
            shouldShow &= match
        }
    }
    return shouldShow
}

export function FilterFuncSelect(filter, name, data, columns, intl, parseFilter, parseData){
    let shouldShow = true
    let columnName = name?.column ?? name
    let filterName = name?.filterName ?? name
    if(filter?.[filterName]){
        let column = findColumn(columnName, columns)
        let val = filter[filterName]
        let rowVal = column?.serialize(data) ?? parseData?.(data) ?? data[name]
        shouldShow &= ((parseFilter?.(val) ?? intl?.formatMessage({id: val}) ?? val) === rowVal)
    }
    return shouldShow
}
