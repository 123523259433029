import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MDBRow, MDBCol } from 'mdbreact';
import supportLinks from '../../../constants/support-links';
import {connect} from 'react-redux';
import { countryData } from "../../../constants/country-data";
import HelpSupportModal from '../Modal/HelpSupportModal';
import { withRouter } from 'react-router-dom';
import UPSPrivacyPolicy from '../../../pages/UPSPrivacyPolicy';
import UPSServiceTerms from '../../../pages/UPSServiceTerms';
import {onEnterPress} from "../../../utils/utils";
import { upsHelpUrlFormatter } from '../../../utils/utils';
const path = require('path');

class UPSFooter extends Component {

    constructor(props){
        super(props);
        this.state = {  
            upsHomeUrl: '',
            urlFraudProtect: '',
            openModal: false,
			modalComp: ''
        };
    }

    getFraudProtectedUrl(){
        const {user, userInfo, countryLocale} = this.props;
        const {locale, selectedCountry} = user || userInfo || {};

        let language = locale ? locale.split("-")[0] : null;
        let country = selectedCountry ? selectedCountry.toLowerCase() : null;
        
        if(countryLocale){
            let arr = countryLocale.split("-");
            language = arr[0];
            country = arr[1].toLowerCase();
        }

        if(language && country){
            const {upsBaseUrl, upsHomeResource, upsProtectResource, cookieNoticeResorce, californiaResource, supportBaseUrl} = supportLinks;
            const homeUrl = upsBaseUrl + path.join(country, language, upsHomeResource);
            const fraudProtectUrl = upsBaseUrl + path.join(upsHelpUrlFormatter(country, language), upsProtectResource);
            const cookieNoticeUrl = supportBaseUrl + path.join(country, language, cookieNoticeResorce);
            const californiaPrivacyUrl = supportBaseUrl + path.join(country, language, californiaResource);
            this.setState({
                urlFraudProtect: fraudProtectUrl,
                upsHomeUrl : homeUrl,
                cookieNoticeUrl,
                californiaPrivacyUrl,
                country
            });
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.user !== this.props.user || prevProps.userInfo !== this.props.userInfo || prevProps.countryLocale !== this.props.countryLocale){
            this.getFraudProtectedUrl();
        }
    }

    componentDidMount(){
        this.getFraudProtectedUrl();
    }

    showPrivacyLinkModal = () => {
        const {user, userInfo} = this.props;
        if(user){
            this.props.history.push("/ups/billing/privacy");
        }
        else if(userInfo){
            this.setState({
				openModal: true,
				modalComp: <UPSPrivacyPolicy/>				
            });
        }        
    };

    showServiceTermsModal = () => {
        const {user, userInfo} = this.props;
        if(user){
            this.props.history.push("/ups/billing/terms");
        }
        else if(userInfo){
            this.setState({
				openModal: true,
                modalComp: <UPSServiceTerms/>
            });
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false
        });
    }

    redirectToHome = () => {
        const { history } = this.props;
        history.push("/home");
    }

    showCookieConsentForm = () => {
        window.utag.gdpr.showConsentPreferences();
        window.scrollTo(0,0);
    }

    render() {
        const { showLegalLinks, user, intl } = this.props;
        const currentYear = (new Date()).getFullYear();
        const {urlFraudProtect, upsHomeUrl, openModal, modalComp, californiaPrivacyUrl, cookieNoticeUrl, country} = this.state;
        return (
            <React.Fragment>
                <footer className={"ups-footer-wrapper"} role="none">
                    <div className={"ups-footer"}>
                        <div className={"ups-footer_legal"} role="contentinfo">
                            <div className={"ups-footer_links"}>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        {showLegalLinks ?
                                            <ul className="ups-footer_expand" id="ups-footer_legalLinks">
                                                {user ? <li><a role="link" tabIndex="0" onKeyPress={onEnterPress(() => this.redirectToHome())} onClick={this.redirectToHome}><FormattedMessage id="ups.home" /></a></li> : null}
                                                <li><a role="link" href={upsHomeUrl}><FormattedMessage id="ups.footer.url" /></a></li>
                                                <li><a role="link" tabIndex="0" onKeyPress={onEnterPress(() => this.showServiceTermsModal())} onClick={this.showServiceTermsModal}><FormattedMessage id="ups.footer.service-terms" /></a></li>
                                                <li><a role="link" tabIndex="0" onKeyPress={onEnterPress(() => this.showPrivacyLinkModal())} onClick={this.showPrivacyLinkModal}><FormattedMessage id="ups.footer.privacy-policy" />
                                                </a></li>
                                                <li><a title={intl.formatMessage({id:"ups.new-tab.link.label"})} role="link" href={urlFraudProtect} target="_blank" ><FormattedMessage id="ups.footer.protect" /></a></li>
                                                <li><a title={intl.formatMessage({id:"ups.new-tab.link.label"})} role="link" href={cookieNoticeUrl} target="_blank" ><FormattedMessage id="ups.footer.cookie-notice" /></a></li>
                                                { country === "us" && <li><a title={intl.formatMessage({id:"ups.new-tab.link.label"})} role="link" href={californiaPrivacyUrl} target="_blank" ><FormattedMessage id="ups.footer.privacy-notice-california" /></a></li>}
                                                <li><a role="link" tabIndex="0" onKeyPress={onEnterPress(() => this.showCookieConsentForm())} onClick={this.showCookieConsentForm} className="ups-analytics"><FormattedMessage id="ups.footer.cookie-settings" /></a></li>
                                            </ul> : null}
                                        <p className={"ups-footer_disclaimer"}><FormattedMessage
                                            id="ups.footer.copyright"
                                            values={{
                                                year: currentYear
                                            }}
                                        /></p>
                                </MDBCol>
                            </MDBRow>
                            {openModal ? 
                                <MDBRow>
                                    <MDBCol>
                                    <HelpSupportModal
                                            key={'support-modal'}
                                            isOpen={openModal}
                                            closeModal={this.closeModal}
                                            component={modalComp}
                                        /> 
                                    </MDBCol>
                                </MDBRow>
                             :
                             null
                             }
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {    
    return {
        user: state.auth.user,
        userInfo: state.auth.userInfo,
        countryLocale :  state.settings.countryLocale
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(UPSFooter)));
