/*
 * Copyright (C) 2020 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on 6/18/20, 1:38 PM
 * Created by kxian
 *
 */

import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import * as errorActions from "../../actions/error-action";
import {bindActionCreators} from "redux";

class PageError extends Component {
    componentWillUnmount() {
        const {errors, errorActions} = this.props;
        if (errors && errors.length) errorActions.clearErrors();
    }

    render() {
        const {errors, vFields, vState} = this.props;

        const serverAlerts = errors.map(id => ({
            field: "server",
            id,
            values: {}
        }));

        const clientAlerts = [];
        Object.keys(vFields).forEach(field => {
            const {messages} = vState[field];
            if (!messages) return;
            clientAlerts.push(...messages.map(id => ({
                field,
                id,
                values: vFields[field].getContext()
            })));
        });

        const alerts = serverAlerts.concat(clientAlerts);
        if (!alerts.length) return null;

        // TODO implement warning messages when we need to
        // class for warning "warning-status component-level"

        return (
            <div id="pageError" className="invalid-status component-level" role="alert">
                {alerts.map(a => <div key={`${a.field}.${a.id}`}>
                    <i className="fa fa-exclamation-circle"></i>{" "}
                    <FormattedMessage id={a.id} values={a.values}/>
                </div>)}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // Server errors
        errors: state.error.errors || [],
        // Client errors
        vFields: state.validation.vFields || {},
        vState: state.validation.vState || {}
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageError);
