import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MDBBtn } from 'mdbreact';

export default function CommonModalData({ dataId, actions, messageType }) {
    return (
        <React.Fragment>
            {/* pay by text save popup message */}
            {dataId === 'paybytext' &&
                <FormattedMessage
                    tagName={'p'}
                    id={'ups.preferences.paybytext.save.message'}
                    values={{
                        style: msg => (
                            <strong>{msg}</strong>
                        ),
                        button: msg => (
                            <MDBBtn color={"text-link"} className="paybytextbtn" onClick={actions.goToPayByText}>{msg}</MDBBtn>
                        )
                    }}
                />
            }
            {/* secure pdf save popup message */}
            {dataId === 'securepdf' && messageType && <FormattedMessage tagName={'p'} id={`ups.preferences.pdf-invoice.save.message.${messageType}`} />}
            {(dataId === 'securepdf' && !messageType) ? 
            <> <FormattedMessage tagName={'p'} id="ups.preferences.pdf-invoice.save.message" />
            <span className="font-weight"><FormattedMessage id="ups.preferences.reminder" /></span> <FormattedMessage id="ups.preferences.reminder.message" />
            </> : null}
            {/* common save popup message */}
            {(!dataId && !messageType) ? <FormattedMessage tagName={'p'} id="ups.preferences.save.message" /> : null}
        </React.Fragment>
    );
}