export const GA_CATEGORY = Object.freeze({
    NAVIGATION: "navigation",
    API: "API call",
})

export const BILLING_CENTER_LABEL = 'billing-center'

export function sendGoogleAnalyticsEvent(category, action, value, label = BILLING_CENTER_LABEL) {
    if (category === GA_CATEGORY.API && !window.config.apiGoogleAnalyticsEnabled) return
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        event_value: value,
    });
}

export function sendGoogleAnalyticsTiming(category, variable, value, label = BILLING_CENTER_LABEL) {
    if (category === GA_CATEGORY.API && !window.config.apiGoogleAnalyticsEnabled) return
    window.gtag("event", 'timing_complete', {
        event_category: category,
        name: variable,
        value: value,
    });
}
