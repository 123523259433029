import React, {useEffect, useRef} from 'react';

export default function ThreeDSForm ({httpmethod, hostedurl, pareq, md, termurl, submit = false}) {
  const formEl = useRef();

  useEffect(() => {
    if (submit) {
      formEl.current.submit();
    }
  }, [submit]);

  return (
    <form ref={formEl} method={httpmethod} action={hostedurl} >
      <input type="hidden" name="PaReq" value={pareq} />
      <input type="hidden" name="MD" value={md} />
      <input type="hidden" name="TermUrl" value={termurl} />
    </form>
  )
}