import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { ERROR_TYPE } from '../constants/error-type';
import queryString from 'query-string';
import { init } from "../actions/auth-action";

export default function ErrorModal(props) {

    const dispatch = useDispatch();
    let redirectMsgId = "";
    let redirectPath = props.location.redirectPath ? props.location.redirectPath : '';
    let errorMsgId = "page.unexpectedError";
    let errorType = props.location.errorType;

    if (props.match.params.errorCode) {
        errorType = props.match.params.errorCode;
        const params = queryString.parse(props.location.search);
        if (params.redirect) {
            redirectPath = params.redirect;
        }
    }

    if (redirectPath) {
        redirectMsgId = "redirect." + redirectPath;
    }

    switch (errorType) {
        case ERROR_TYPE.ROUTE:
            errorMsgId = "error.type.route";
            break;
        case ERROR_TYPE.INVALID_SESSION:
            errorMsgId = "error.type.invalid-session";
            break;
        case ERROR_TYPE.PBT_NOT_SUPPORTED:
            errorMsgId = "error.type.pbt-not-supported";
            break;
       case ERROR_TYPE.PBT_NOT_ENABLED:
            errorMsgId = "error.type.pbt-not-supported";
            break;
    }

    useEffect(() => {
        if (errorType === ERROR_TYPE.INVALID_SESSION) {
            // clear store for invalid session
            dispatch(init());
        }
    }, []);

    return (
        <React.Fragment>
            <div className="error-page">
                <p><FormattedMessage id={errorMsgId} /></p>
                {redirectPath ? <p><a role='link' href={'/' + redirectPath}><FormattedMessage id={redirectMsgId} /></a></p> : null}
            </div>
        </React.Fragment>
    );
}
