/* eslint-disable import/first */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import closest from 'element-closest'; closest(window); // mdbreact polyfill

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './utils/services/serviceWorker';

import 'moment/min/locales';
import {enableMapSet} from "immer";

import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './assets/css/style.css';
import './assets/css/style-v2.css';

import { PersistGate } from 'redux-persist/integration/react';

/* Main Layout */
import MainLayout from './layouts';

/* Redux */
import { Provider } from 'react-redux';
import configStore from './store/config-store';
import TabReload from './components/TabReload';

const {store, persistor} = configStore();
//store.dispatch(onInit());
export default store;

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <TabReload/>
            <MainLayout />
        </PersistGate>
    </Provider>
    , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
window.store = store; // can use in chrome console window.store.getState();
enableMapSet();

serviceWorker.unregister();
