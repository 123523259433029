import React, { useEffect, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  MDBCard, MDBCardBody, MDBCol
  , MDBContainer, MDBRow
} from "mdbreact";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { handleGetPayByTextByIdentityId } from "../../api/pay-by-text-api";
import PayByTextTable from "./PayByTextTable";
import Error from '../../components/Error';
import Spinner from '../../components/Spinner';
import AddPayByTextModal from "../../components/UPS/Modal/AddPayByTextModal";
import CancelPayByTextModal from "../../components/UPS/Modal/CancelPayByTextModal";
import { useCookieState } from "../../utils/cookie-utils";
import { has, isEmpty } from "ramda";
import {isPBTSupportedCountry, isPBTPreferencesEnabled, isPBTAccountLimitReached} from "../../utils/payByText-utils";
import {ERROR_TYPE} from "../../../src/constants/error-type";
import {INITIAL_TABLE_STATE} from "../../components/DataTableV3/DataTableV3FilterWrapper";
import Alert from "../../components/Alert";

const is3dSecureFlow = has('hostedCheckout');

const DT_SRC = "pay-by-text";
const INITIAL_STATE = {
  payByText: {}, // Focused payByText
  payByTextList: [],
  fetching: true,
  createModal: false,
  editModal: false,
  viewModal: false,
  cancelModal: false,
  error: '',
  in3dsFlow: false
};

const initState = () => ({...INITIAL_STATE, tableState: {...INITIAL_TABLE_STATE}})

function reducer(state, { type, payByTextList, payByText, error, tableAction }) {
  switch (type) {
    case 'fetch':
      return {
        ...state,
        fetching: true
      }
    case "fetched":
      return {
        ...state,
        payByTextList: payByTextList ?? state.payByTextList,
        fetching: false
      };
    case 'error':
      return {
        ...state,
        error,
        fetching: false
      };
    case "addPayByText":
      return {
        ...state,
        createModal: !state.createModal,
        fetching: state.createModal
      };
    case "editPayByText":
      return {
        ...state,
        payByText: state.editModal ? {} : payByText,
        editModal: !state.editModal,
        fetching: state.editModal
      }
    case "cancelPayByText":
      return {
        ...state,
        payByText: state.cancelModal ? {} : payByText,
        cancelModal: !state.cancelModal,
        fetching: state.cancelModal
      };
    case "3dsFlowStart":
      return {
        ...state,
        in3dsFlow: true
      }
    case 'tableAction': return tableAction(state)
    default:
      return state;
  }
}

export default function UPSPayByText() {
  const history = useHistory();
  const intl = useIntl();
  const [state3dSecure, setState3dSecure] = useCookieState('c3dSecure');
  const [state, dispatch] = useReducer(reducer, null, initState);
  const identity = useSelector(s => s.identity);
  const { id: identityId, selectedCountry, selectedPaymentType } = useSelector(s => s.auth.user);
  const { merchants } = useSelector(s => s.config);

  useEffect(() => {
    if(!isPBTSupportedCountry(selectedCountry, selectedPaymentType) ) {
      history.push({
        pathname: '/error',
        errorType: ERROR_TYPE.PBT_NOT_SUPPORTED,
        redirectPath : 'home'
      })
    }else if(!isPBTPreferencesEnabled(identity)){
      history.push({
        pathname: '/error',
        errorType: ERROR_TYPE.PBT_NOT_ENABLED,
        redirectPath : 'home'
      })
    }
  }, [selectedCountry, selectedPaymentType])

  useEffect(() => {
    dispatch({ type: 'fetch' });
  }, [selectedCountry]);

  useEffect(() => {
    //auto-open add or edit modal if we are coming back from 3d secure flow
    if (!isEmpty(state3dSecure) && !state.fetching) {
      if (state3dSecure.scheduleId) {
        const payByText = state.payByText.find(s => s.id === (state3dSecure.scheduleId))
        if (payByText) toggleModal("editPayByText", payByText)
      }
      else toggleModal("addPayByText")
    }
  }, [state.fetching])

  useEffect(() => {
    let stale = false;
    if (state.fetching) {
      handleGetPayByTextByIdentityId()
        .then(payByTextList => {
          if (!stale)
            dispatch({ type: "fetched", payByTextList })
        })
        .catch(({ errorCode }) => { if (!stale) dispatch({ type: 'error', error: errorCode }) });
    }
    return () => stale = true;
  }, [state.fetching]);

  const toggleModal = (modalId, payByText) => dispatch({ type: modalId, payByText });
  const isAccountLimitReached = state.payByTextList?.length ? isPBTAccountLimitReached(state.payByTextList, merchants, selectedCountry) : false;
  const countryLabel = intl.formatMessage({id: `country-item.${selectedCountry}`});

  return (
    <MDBContainer role="main" fluid>
      <MDBRow>
        {state.fetching && <Spinner isSpinning={true} />}
        {state.error.length > 0 && <Error errors={[state.error]} />}
        <MDBCol size="12">
          <MDBCard>
            <MDBCardBody className="payByText-section">
              <MDBRow>
                <MDBCol size="12">
                  <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                    <ol className="ups-wrap_inner">
                      <li className="ups-breadcrumb-item">
                        <Link to="#" className="ups-link">
                          <FormattedMessage id="ups.title.admin" />
                        </Link>
                        <span aria-hidden="true"> &gt; </span>
                      </li>
                      <li className="ups-breadcrumb-item">
                        <FormattedMessage id="ups.title.payByText" />
                      </li>
                    </ol>
                  </nav>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12">
                  <h1 className="has-breadcrumb">
                    <FormattedMessage id="ups.title.payByText" />
                  </h1>
                </MDBCol>
              </MDBRow>

              { isAccountLimitReached && <Alert className="alert pl-0" type="warning" label={'pay-by-text.max.accounts.warning'} values={{countryLabel}}/> }

              <MDBRow>
                <MDBCol size="12">
                  {!state.fetching && <PayByTextTable
                    caption="ups.title.payByText"
                    source={DT_SRC}
                    payByTextList={state.payByTextList}
                    handleAction={toggleModal}
                    tableState={state.tableState}
                    tableStateAction={(tableAction)=>dispatch({type:'tableAction', tableAction})}
                    enablePBTAddBtn={!isAccountLimitReached}
                  />}
                </MDBCol>
              </MDBRow>

              {(state.createModal || state.editModal) &&
                <AddPayByTextModal
                  modalId={state.createModal ? 'addPayByText' : 'editPayByText'}
                  isOpen={true}
                  toggleModal={toggleModal}
                  initialPayByText={state.payByText}
                  preSelectedAccount={state3dSecure?.accountId}
                  preSelectedWallet={state3dSecure?.referenceId}
                  clearPreSelection={() => setState3dSecure({})}
                  submit3DSForm={state.in3dsFlow}
                  merchants={merchants}
                  addWalletHandler={(pm, accountId, scheduleId) => {
                    if (is3dSecureFlow(pm)) {
                      dispatch({ type: '3dsFlowStart' })
                      setState3dSecure({ referenceId: pm._id, accountId, scheduleId, redirect: '/autopay' });
                    }
                  }}
                />
              }

              {state.cancelModal && <CancelPayByTextModal
                modalId="cancelPayByText"
                isOpen={state.cancelModal}
                toggleModal={toggleModal}
                payByText={state.payByText}
                merchants={merchants}
              />}

            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
