import React, {useState, useEffect} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {MDBRow, MDBCol, MDBBtn} from "mdbreact";

import Details from ".";

import { getAlternatePmIssuerList } from '../../../api/otp-api';

import SelectFieldIntl from "../../../components/SelectFieldIntl";
import DisplayOnlyField from "../../../components/DisplayOnlyField";

const AlternatePmWithIssuersDetails = ({
    isModal, pmCategory, paymentMethod, submitAction, handleCancel,
    controlled, merchantId, onError
}) => {
    const [issuerList, setIssuerList] = useState([])
    const [fiCode, setFiCode] = useState(null)
    const intl = useIntl()

    useEffect(() => {
        getAlternatePmIssuerList(pmCategory, merchantId)
            .then(({ issuer = [] }) => setIssuerList(issuer.map(i => ({value:i.name, label:i.value }))))
            .catch(() =>  onError('payment-method.altPm.issuer'))
    }, [pmCategory, merchantId])
    
    useEffect(() => {
        if (!controlled) return;
        validatePM();
    }, [fiCode]);

    const validatePM = () => {
        const data = {
            methodType: pmCategory,
            categoryCode: pmCategory,
            'fiCode': fiCode
        };
        if (paymentMethod) data.id = paymentMethod.id;

        return submitAction?.(data);
    }

    const issuerOptions = [
        { value: '', label: '' },
        ...issuerList
    ]
    const form =
        <>
            <MDBRow>
                {controlled || !paymentMethod
                    ? <MDBCol size="12" md="6">
                        <SelectFieldIntl key="issuer-list" name="issuer" id="issuer"
                            value={fiCode}
                            selectOptions={issuerOptions}
                            label={intl.formatMessage({id: "input.altPm.issuer"})}
                            setLabelBefore required
                            onChange={e => setFiCode(e.target.value)}
                            validations={[["required"]]}
                        />
                    </MDBCol>
                    : <MDBCol size="12" md="6">
                        <DisplayOnlyField
                            label={<FormattedMessage id="input.altPm.issuer"/>}
                            value={paymentMethod.fiCode}
                            wrapperMode
                        />
                    </MDBCol>
                }
            </MDBRow>
        </>
    if (controlled) return form
    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}

export default AlternatePmWithIssuersDetails