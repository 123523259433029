import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../actions/invoice-action";
import {FormattedMessage, injectIntl} from 'react-intl';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody } from "mdbreact";
import PlanInvoiceTable from '../../components/UPS/PlanInvoiceTable/PlanInvoiceTable';
import {Link} from "react-router-dom";
import { buildFilters, buildCalendarOption } from "../../utils/invoice-utils";
import InvoicesDownloadLinksModal from '../../components/UPS/Modal/InvoicesDownloadLinksModal/index';
import {getMessageByBU, getKeyByBU, decodeToken} from "../../utils/ups-utils";
import memoize from "memoize-one";
import { UseEffect } from "../../components/ClassUseEffect/ClassUseEffect";
import { getQueryParams } from "../../utils/utils";
import { PAST_DUE_INVOICES } from '../../constants/invoice-constants';

class UPSPlanInvoice extends React.Component {
    shouldRenderTable = false;
    constructor(props) {
        super(props);
        this.URLFilters = buildFilters(props, true) //only supports 
        this.calendarOption= buildCalendarOption(props)

        this.state = {
            filterPayable: false
        };
    }

    componentDidMount() {
        const { location, businessUnit } = this.props;
        const params = getQueryParams(location.search, false);
        
        if (params?.["token"]) {
            const decodedToken = decodeToken(params["token"]);
            if (decodedToken){ 
                this.URLFilters = { ...this.URLFilters, invoiceFilters: buildFilters(decodedToken), businessUnit };
            } 
        }

        const filterType = this.props.match?.params?.["filterType"];

        if (params?.['payable'] === 'true') {
            // this section can be used in future for other filterTypes like dueSoon, closed etc
            let invoiceType = "";

            if(filterType === PAST_DUE_INVOICES){
                invoiceType = "pastDue";
            }

            this.setState({
                filterPayable: true,
                invoiceType
            })
        }

        this.shouldRenderTable = true;
    }

    componentWillUnmount() {
        this.shouldRenderTable = false;
    }

    getPastDuePlanInvoices = () => {
        this.props.invoiceActions.getPastDuePlanInvoices();
    }

    setDocumentTitle = memoize(() => {
        const { intl, businessUnitMessages } = this.props;
        document.title = intl.formatMessage({ id: 'document.title' }, {pageTitle: businessUnitMessages('pageTitle')});
    })

    render() {
        const { hasPastDueInvoices, intl, businessUnitMessages, businessUnitKeys } = this.props;
        const { filterPayable, invoiceType } = this.state;
        
        this.setDocumentTitle(intl.locale)
        return (
            <MDBContainer role="main" fluid>
                <UseEffect observe={[intl.locale]} update={this.getPastDuePlanInvoices}/>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                                <li className="ups-breadcrumb-item">
                                                    <Link to={'/home'} activeclassname="ups-link"><FormattedMessage id={'ups.title.dashboard'} /></Link><span
                                                        aria-hidden="true"> &gt; </span></li>
                                                <li className="ups-breadcrumb-item">{businessUnitMessages('pageTitle')}</li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}>
                                    {businessUnitMessages('pageTitle')}
                                </h1>
                                <div><p>{businessUnitMessages('pageSubHeader')}</p></div>
                                <InvoicesDownloadLinksModal isPlanInvoice />
                                <div className="alert alert-past-due" role="alert">
                                    {hasPastDueInvoices &&
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                                            <title><FormattedMessage id="alert.label" /></title>
                                            <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                                        </svg>
                                    }
                                    <span className="alert-msg">
                                            {`${(hasPastDueInvoices) ? (businessUnitMessages('pastDueAlert')+' ') : ''}${intl.formatMessage({id:'invoice.alert-message.default'})}`}
                                    </span>
                                </div>
                                {this.shouldRenderTable && <PlanInvoiceTable filterPayable={filterPayable} invoiceType={invoiceType} caption={businessUnitKeys('pageTitle')} colSize={"12"} filters={this.URLFilters}  calendarOption={this.calendarOption}/>}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        invoice: state.invoice,
        preferences: state.identity.preferences,
        hasPastDueInvoices: state.invoice.hasPastDueInvoices,
        countryLocale: state.settings.countryLocale,
        businessUnit: state.auth.user.selectedPaymentType,
        businessUnitMessages: getMessageByBU('planInvoiceTable')(ownProps.intl)(state.auth.user.selectedPaymentType),
        businessUnitKeys: getKeyByBU('planInvoiceTable')(state.auth.user.selectedPaymentType)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UPSPlanInvoice));


